import React from "react";
import objectPath from "object-path";

import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import ImageIcon from "@material-ui/icons/Image";

import * as colors from "../../../theme/colors";
import { makeStyles } from "@material-ui/core/styles";
import FileUpload from './FileUpload';
import { allowedImageTypes } from "../../../constants/fileConstants";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  paper: {
    maxWidth: 755,
    display: "flex",
    margin: "50px auto;"
  },
  imagePreview: {
    display: "block",
    position: "relative",
    "&:hover": {
      opacity: "1"
    }
  },
  cardImage: {
    cursor: "pointer",
    color: '#565656',
    opacity: '0.8',
    "&:hover": {
      opacity: "1"
    }
  },
  cardUploader: {
    display: "none"
  },
  showImage: {
    display: "block",
    margin: "0 auto"
  },
  removeImage: {
    backgroundColor: "#f0f0f0",
    borderRadius: 20,
    bottom: 20,
    boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
    color: "black",
    cursor: "pointer",
    fontWeight: "bold",
    height: 36,
    left: "30%",
    lineHeight: "36px",
    opacity: 0,
    padding: "0 20px",
    position: "absolute",
    right: "30%",
    textAlign: "center",
    transition: "opacity 130ms",
    "&:hover": {
      opacity: "1"
    }
  },
  uploader: {
    width: "90%"
  },
  submitButton: {
    backgroundColor: colors.tealA700,
    color: "white",
    width: "15",
    borderRadius: "0 10px 10px 0",
    fontWeight: "bold",
    verticalAlign: "bottom",
    "&:hover": {
      backgroundColor: colors.teal500
    }
  }
});

const CommentInput = ({
  field,
  form,
  form: { errors, touched },
  label,
  helperText,
  uploadedFile,
  onChange,
  ...props
}) => {
  let error = errors[field.name];
  let isTouched = touched[field.name];
  const classes = useStyles();

  if (field.name.indexOf("[") !== -1 || field.name.indexOf(".") !== -1) {
    const path = field.name
      .replace("[", ".")
      .replace("]", "")
      .split(".");
    error = objectPath.get(errors, path);
    isTouched = objectPath.get(touched, path);
  }

  const acceptedTypes = allowedImageTypes.join(',');

  return (
    <TextField
      {...props}
      name={field.name}
      onChange={value => {
        form.setFieldValue(field.name, value.target.value);
      }}
      error={!!error && isTouched}
      fullWidth
      label={label}
      helperText={error && isTouched ? error : helperText}
      variant="outlined"
      value={field.value}
      inputProps={{
        accept: acceptedTypes,
      }}
      InputProps={{
        placeholder: label,
        endAdornment: 
          <InputAdornment position="start">
            <FileUpload 
              hasFile={!!uploadedFile}
              labelClass={classes.imageLabel}
              onChange={onChange}>
              <ImageIcon className={classes.cardImage} />
            </FileUpload>
          </InputAdornment>
      }}
    />
  );
};

export default CommentInput;
