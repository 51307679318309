import React from 'react';
import objectPath from 'object-path';

import EmailIcon from '@material-ui/icons/EmailOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { Box } from "@material-ui/core";

import { TooltipWrapper } from "./tooltip/tooltip";
import { formInavlidTooltipMsg } from '../utils/userUtils';
import { StyledFormHelper } from "./loginSignup/customFormHelper";

const EmailInput = ({ field, form: { errors, touched }, label, helperText, showTooltip, ...props }) => {
  let error = errors[field.name];
  let isTouched = touched[field.name];

  if (field.name.indexOf('[') !== -1 || field.name.indexOf('.') !== -1) {
    const path = field.name.replace('[', '.').replace(']', '').split('.');
    error = objectPath.get(errors, path);
    isTouched = objectPath.get(touched, path);
  }

  return (
    <>
      <TextField
        {...props}
        name={field.name}
        onChange={field.onChange}
        error={!!error && isTouched}
        fullWidth
        label={label}
        helperText={!showTooltip && (error && isTouched ? error : helperText)}
        variant="outlined"
        value={field.value}
        InputProps={{
          placeholder: label,
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),
        }}
      />
      {error && isTouched && showTooltip &&
        <Box component="span">
          <TooltipWrapper title={formInavlidTooltipMsg}>
            <Box component="span">
              <StyledFormHelper helperText={error} isHtml={true} isError={true} />
            </Box>
          </TooltipWrapper>
        </Box>
      }
    </>
  );
}

export default EmailInput;
