import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { push as pushToRoute } from "connected-react-router";

import { connectTo } from "../../../utils/generic";
import * as authActions from "../../../actions/auth";
import * as globalActions from "../../../actions/global";

import Box from "@material-ui/core/Box";

const useStyles = makeStyles({
  container: {
    height: "100%",
    width: 310,
    margin: "15px auto",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
  },
});

const MenuContainer = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.wrapper}>
        <Box>
          {props.children}
        </Box>
      </Box>
    </Box>
  );
};

export default connectTo(
  (state) => {
    return {
      auth: state.auth,
      contentful: state.contentful,
      router: state.router,
    };
  },
  {
    push: pushToRoute,
    ...authActions,
    ...globalActions,
  },
  MenuContainer
);
