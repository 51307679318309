import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";

import { Modal } from "./modal";
import * as colors from "../../theme/colors";
import { ColorTheme } from '../../utils/colorTheme';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: '16px 24px 16px',
    [theme.breakpoints.up('sm')]: {
      minWidth: 350,
    },
  },
  title: {
    color: ColorTheme.blue4.b,
    fontSize: 24,
    fontWeight: (props) => props.fontWeight || 500,
    margin: "22px 5px 20px 15px",
    textAlign: (props) => props.textAlign || "center",
    textTransform: (props) => props.case || "initial",
    maxWidth: (props) => props.maxWidth || "auto",
    width: "100%",
    wordBreak: 'break-word',
    [theme.breakpoints.down('xs')]: {
      "& span": {
        display: 'none'
      }
    }
  },
  closeBtn: {
    height: 40,
    width: 40,
    marginRight: 10
  },
  titleBox: {
    alignItems: 'center',
    display: 'flex',
    background: colors.lightGradient,
    justifyContent: "space-between"
  },
}));

export const HQModal = (props) => {
  const {
    title,
    isOpen,
    onClose,
    children,
    titleOptions,
    maxWidth,
    withoutAutofocus,
  } = props;
  let titleStyle = {};
  if (!!titleOptions) {
    if (titleOptions.isUpperCase) {
      titleStyle.case = "uppercase";
    }
    if (titleOptions.isBold) {
      titleStyle.fontWeight = 700;
    }
    if (titleOptions.maxWidth) {
      titleStyle.maxWidth = titleOptions.maxWidth;
    }
    if (titleOptions.textAlign) {
      titleStyle.textAlign = titleOptions.textAlign;
    }
  }
  const classes = useStyles(titleStyle);

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxWidth={maxWidth}>
      <Box className={classes.titleBox}>
        <Typography id="dialog-title" variant="h4" className={classes.title}>
          {title}
        </Typography>
        <IconButton
          size="small"
          className={classes.closeBtn}
          color="inherit"
          onClick={onClose}
          aria-label="close"
          autoFocus={!withoutAutofocus}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
    </Modal>
  );
};