import client from "../../../apolloClient";
import { gql } from "apollo-boost";
import obj2arg from "graphql-obj2arg";

import { applyFilterFlags } from '../../../utils/projectGalleryHelper';

export const getActiveGalleryProjectFilters = ({searchKey, filter}) => {
  const graphQlArguments = {
    filter: {
      ...filter,
      onlyApproved: true,
    },
    searchKey
  };

  if (!searchKey) {
    delete graphQlArguments.searchKey;
  }

  graphQlArguments.filter = applyFilterFlags(graphQlArguments.filter);

  return client.query({
    fetchPolicy: "no-cache",
    query: gql`{
      GetActiveGalleryProjectFilters(${obj2arg(graphQlArguments, {
        noOuterBraces: true
      })}) {
        countries,
        tags,
        programTypeIds,
        curriculums,
        years,
        flags
      }
    }`
  });
}

export function getGalleryProjectsTotalScore({searchKey, filter}) {
  const graphQlArguments = {
    filter : {...filter},
    searchKey
  };

  if (!searchKey) {
    delete graphQlArguments.searchKey;
  }

  graphQlArguments.filter = applyFilterFlags(graphQlArguments.filter);

  return client.query({
    query: gql`{
      GetGalleryProjectsTotalScore(${obj2arg(graphQlArguments, {
        noOuterBraces: true
      })})
    }`
  });
}
