import React, { useEffect, useState, useRef } from "react";

import * as contentfulActions from "../../actions/contentful";

import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import { connectTo } from "../../utils/generic";

import { Box } from "@material-ui/core/";
import { useTitle } from "../../utils/pageTitles";
import Footer from "../../containers/Footer/footer";
import TextCustomButton from "../../components/text-button/text-button";
import useDeviceDetect from "../../utils/deviceDetect";

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: 20,
    maxWidth: 900,
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: "calc(100vh - 135px)",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  linkContainer: {
    marginTop: 20,
  },
  iframe: {
    minWidth: '100%',
    borderWidth: '0px !important',
    maxWidth: 700,
  },
  surveyContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  survey: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const GWCChallenge = ({ contentful, loadHomePageContent }) => {
  useTitle("gwc-challenges");

  const classes = useStyles();

  const [formSection, setFormSection] = useState(null);
  const [formBanner, setFormBanner] = useState(null);

  const iframeRef = useRef(null);

  const isMobile = useDeviceDetect();

  useEffect(() => {
    // Function to adjust iframe height when a message is received from the iframe
    const handleIframeResize = (event) => {
      // Ensure the message is coming from the correct domain (optional but recommended for security)
      if (event.origin === 'https://survey.alchemer.com') {
        // const newHeight = isMobile ? 900 : event.data.height;
        const newHeight = event.data.height;
        if (isMobile) {
          // For mobile, set the height to be a maximum of 80% of the viewport height
          const maxMobileHeight = Math.min(newHeight, window.innerHeight * 0.8);
          iframeRef.current.style.scrolling = 'yes'
          iframeRef.current.style.height = `${maxMobileHeight}px`;
          // iframeRef.current.style.overflowY = 'auto';
        } else {
          if (iframeRef.current && newHeight) {
            // Adjust the iframe height dynamically
            iframeRef.current.style.height = `${newHeight}px`;
          }
        }
      }
    };

  // Add the event listener to listen for messages from the iframe
  window.addEventListener('message', handleIframeResize);

  // Cleanup event listener when the component unmounts
  return () => {
    window.removeEventListener('message', handleIframeResize);
  };
}, []);

  useEffect(() => {
    if (!contentful.HomePageQuery) {
      loadHomePageContent();
      return;
    }

    const {
      HomePageQuery: { gwcChallenges, gwcCahellengesBanner },
    } = contentful;

    setFormBanner(gwcCahellengesBanner);
    setFormSection(gwcChallenges);
  }, [contentful]);

  return (
    <>
      <Container className={classes.root}>
        {formBanner && <img
            alt="GWC Challenges"
            style={{ width: '100%' }}
            src={formBanner.src}
          />}
        {formSection && (<Box className={classes.surveyContainer}>
        <Container className={classes.survey} xs={6} md={6}>
          <iframe src={formSection.surveyUrl} title={formSection.surveyName} className={classes.iframe}
          ref={iframeRef}
          style={{ height: 'auto', overflowY: 'hidden' }}
          scrolling={isMobile ? "yes" : "no"}
          />
          {formSection.ctaText && <Box className={classes.linkContainer}>
            <TextCustomButton
              mode={"primary"}
              isArrowWave={true}
              onClick={() => {
                window.open(formSection.surveyUrl, '_blank');
              }}
              label={formSection.ctaText}
              sel="new-window"
            />
            </Box>}
        </Container>
        </Box>)}
      </Container>
      <Footer />
    </>
  );
};

export default connectTo(
  (state) => {
    return {
      contentful: state.contentful,
    };
  },
  { ...contentfulActions },
  GWCChallenge,
);
