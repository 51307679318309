import React, { useEffect, useState} from "react";
import {
  Box,
  Typography,
  CardContent,
  Card,
  CardMedia,
  Grid
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";

import { Link } from "react-router-dom";

import SkeletonCards from "../../project-gallery/skeletonCards/index";
import TextCustomButton from "../../../components/text-button/text-button";

const useStyles = makeStyles(() => ({
  galleryCard: {
    borderBottom: "6px solid $grey-dark",
    boxSizing: "border-box",
    cursor: "pointer",
    margin: 12,
    padding: 24,
    transition: "transform 150ms linear",
    height: "fit-content",
    width: "-webkit-fill-available",
    "&:hover": {
      transform: "scale(1.05, 1.05)",
    },
    "&:focus": {
      outline: "none",
      transform: "scale(1.05, 1.05)",
    },
  },
  cardСover: {
    height: 140,
  },
  cardTitle: {
    overflow: "hidden",
    fontWeight: 600,
    fontSize: 16,
    height: 48,
    boxOrient: 'vertical',
    lineClamp: 2,
    display: '-webkit-box'
  },
  cardContent: {
    padding: "16px 0 0 !important",
  },
  projectLink: {
    textDecoration: "none",
  },
  viewMoreBox: {
    display: "flex",
    justifyContent: "flex-end"
  }
}));

const UserProjectCard = (props) => {
  const { project, classes } = props;
  const coverImage = project.primaryImage || "";

  return (
    <Grid key={project.id} item xs={12} sm={6} md={6} lg={4}>
      <Link
        to={{
          pathname: `/project-gallery/${project.id}`,
          prevUrl: window.location.search,
          state: {
            prevPath: window.location.pathname,
            label: "project gallery",
          },
        }}
        className={classes.projectLink}
      >
        <Card
          title={project.name}
          className={classes.galleryCard}
          tabIndex="0"
        >
          <CardMedia image={coverImage} className={classes.cardСover} />
          <CardContent className={classes.cardContent}>
            <Typography
              align="left"
              component="h3"
              className={classes.cardTitle}
            >{project.name}</Typography>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
}

const PublishedUserProjects = ({
  userProjects,
  isLoadingProjects
}) => {
  const [projectsViewMore, setProjectsViewMore] = useState(false);
  const [visibleProjects, setVisibleProjects] = useState([]);

  useEffect(() => {
    if (!userProjects) return;

    setVisibleProjects(projectsViewMore ? userProjects : userProjects.slice(0, 3));
  }, [userProjects, projectsViewMore]);

  const classes = useStyles();
  
  return (
    <React.Fragment>
      <Grid container className="item">
        <Grid item sm={0} md={4} className="item-container"></Grid>
        <Grid item sm={12} md={8} className="item-container">
          <Box className="profile-section">
            <Typography className="profile-section-title" variant="body1">
              My Published Projects
            </Typography>
            
            <Grid container>
              {isLoadingProjects
                ? <SkeletonCards count={3}/>
                : visibleProjects.map((project, ind) => 
                    <UserProjectCard 
                      key={ind}
                      classes={classes}
                      project={project}
                    />)
              }
            </Grid>

            {userProjects.length > 3 &&
              <Box className={classes.viewMoreBox}>
                <TextCustomButton
                  isArrowWave={false}
                  label={projectsViewMore ? "View Less" : "View More"}
                  onClick={() => setProjectsViewMore(!projectsViewMore)}
                />
              </Box>
            }
            </Box>
        </Grid>
      </Grid>     
    </React.Fragment>
  );
};
export default PublishedUserProjects;