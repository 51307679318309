import React from "react";

import { Field } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TagChipSelector from "../components/TagChipSelector";
import * as colors from "../../.././theme/colors";

const useStyles = makeStyles((theme) => ({
  subTitle: {
    color: colors.darkThemeBlueGray,
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 10,
    [theme.breakpoints.down("xs")]: {
      padding: "0 16px",
    },
  },
  chipSelector: {
    fontSize: 14,
    "& .MuiSelect-outlined": {
      color: colors.lightGrey,
      fontSize: 14,
    },
    "& .MuiInputLabel-outlined": {
      backgroundColor: colors.white,
    },
    margin: "16px 0 20px 0",
    '& .MuiInputLabel-outlined': {
      backgroundColor: colors.white,
    }
  },
  clubChallengeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textareaShrink: {
    position: "relative",
    marginBottom: -16,
  },
  thirdStepWrapper: {
    padding: '0 24px',
    [theme.breakpoints.down("xs")]: {
      padding: '0 14px',
    },
  },
  dropdownStyle: {
    '& .MuiMenuItem-root': {
      fontSize: 14,
    },
    '& .MuiTypography-body1': {
      fontSize: 14,
    },
  }
}));

const ThirdStep = ({
  projectTypeTags,
  validateProjectTags,
  languageTags,
  topicTags,
  labels,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.thirdStepWrapper}>
      <Box>
        <Typography className={classes.subTitle}>
          {"Step 3: Project Tags"}
        </Typography>
      </Box>
        <Field
          component={TagChipSelector}
          name="projectTypes"
          placeholder="Select one or more"
          label={labels.submissionFormProjectTypeLabel || ""}
          data={projectTypeTags}
          validate={validateProjectTags}
          className={classes.chipSelector}
          isRequired={true}
          dropDownclassName={classes.dropdownStyle}
        />
        <Field
          component={TagChipSelector}
          name="languages"
          placeholder="Select one or more"
          label={labels.submissionFormLanguageLabel || ""}
          data={languageTags}
          className={classes.chipSelector}
          dropDownclassName={classes.dropdownStyle}
        />
        <Field
          component={TagChipSelector}
          name="topics"
          data={topicTags}
          placeholder="Select one or more"
          label={labels.submissionFormTopicLabel || ""}
          className={classes.chipSelector}
          dropDownclassName={classes.dropdownStyle}
        />
    </Box>
  );
};

export default ThirdStep;
