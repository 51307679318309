import React  from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, CircularProgress } from "@material-ui/core";
import * as colors from "../../../theme/colors";

import { connectTo } from "../../../utils/generic";
import GWC_HQ_6_12_grade from "../../../images/GWC_HQ_6_12_grade.svg";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: 'center',
  },
  typography: {
    display: "flex",
  },
  progressItem: {
    zIndex: 1,
    height: 45,
    width: 45,
    transform: 'rotate(90deg) !important',
    color: (props) => props.mainColor,
  },
  boxWrapper: {
    borderRadius: 25,
    backgroundColor: (props) => props.backgroundColor,
  },
  icon: {
    height: 28,
    width: 29,
  },
});

const styleTypes = {
  primary: {
    mainColor: colors.orange450,
    backgroundColor: colors.orange75,
  },
  secondary: {
    mainColor: colors.tealA700,
    backgroundColor: colors.teal50,
  },
};

const ProgressCircleIcon = ({ value, icon }) => {
  const classes = useStyles( value !== 100 ? styleTypes.primary : styleTypes.secondary );

  return (
    <div className={classes.root}>
      <Box position="relative" display="inline-flex">
      <CircularProgress variant="static" value={value} size={'medium'} className={classes.progressItem} />
        <Box
          className={classes.boxWrapper}
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img src={icon ? icon : GWC_HQ_6_12_grade} className={classes.icon} alt="icon" />
        </Box>
      </Box>
    </div>
  );
};

export default connectTo(
  (state) => {
    return {
      curriculum: state.contentful.curriculums,
      auth: state.auth,
      contentful: state.contentful,
      userActivitiesInfo: state.userActivitiesInfo,
    };
  },
  {},
  ProgressCircleIcon
);
