import client from "../../../apolloClient";
import { gql } from "apollo-boost";
import obj2arg from 'graphql-obj2arg';

export const getAttendanceAttributes = () => {
  return `
    id
    programId
    userId
    comment
    parentId
    status
    date
    nextUserId
    lastModification
    programs {
      sfProgramId
      code
    }
    user {
      firstName
      lastName
      email
    }
  `;
};

export function updateAttendance({attendance}) {
  return client
    .mutate({
      mutation: gql`
      mutation {
        updateAttendance(attendance: ${obj2arg(attendance)} ) {
          ${getAttendanceAttributes()}
        }
      }`
    });
};
