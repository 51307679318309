export const createBouncedUsersNotificationText = (bouncedUsers) => {
  let notificationText = "";

  if (!bouncedUsers || !bouncedUsers.length) return;

  if (bouncedUsers.length === 1) {
    notificationText = `Your email could not be delivered to ${bouncedUsers[0].email} for the following reason(s): ${bouncedUsers[0].comment}`;
  } else {
    // all unique domains to display them with number of users
    const blockedDomains = [...new Set(bouncedUsers.map(user => !!user.domain && user.domain))];
    notificationText =
      "Your emails could not be delivered for the following reason(s):";

    blockedDomains.map(domain => {
      const usersForDomain = bouncedUsers.filter(user => domain === user.domain);
      if (usersForDomain.length) {
        notificationText += `
        ${usersForDomain[0].comment} - domain ${domain} for ${usersForDomain.length} user(s).`;
      }
    });
      
    bouncedUsers.forEach(({ comment, email, domain }) => {
      if (comment && !domain) {
        // add a line break and a comment
        notificationText += `
        ${email} - ${comment}`;
      }
    });
  }

  return notificationText;
};
