import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Box,
  TextareaAutosize,
  Typography,
  CardContent,
  Card,
  Grid,
} from "@material-ui/core/";

import Link from "../../../components/Link";
import TextCustomButton from "../../../components/text-button/text-button";
import AvatarCrop from "../user-profile-content/avatarCrop";

import debounce from "debounce";
import * as colors from "../../../theme/colors";

const useStyles = makeStyles({
  card: {
    width: "100%",

    "& .MuiTypography-colorTextSecondary": {
      color: "#003046",
    },

    "& .MuiTypography-body2": {
      fontFamily: "'Roboto Mono',monospace",
    },
  },
  title: {
    fontSize: "30px",
    marginLeft: "16px",
  },
  label: {
    fontSize: 50,
  },
  gridHeader: {
    justifyContent: "space-between",
  },
  list: {
    width: "100%",
  },
  itemContainer: {
    marginTop: "15px",
    marginBottom: "15px",
    display: "flex",
    justifyContent: "center",
  },
  item: {
    marginTop: "15px",
    marginBottom: "15px",
    wordWrap: "break-word",
  },
  editIcon: {
    marginTop: 8,
  },
  editContainer: {
    backgroundColor: "transparent",
    border: "1px solid #565656",
    borderRadius: "50%",
    height: "36px",
    lineHeight: "37px",
    textAlign: "center",
    cursor: "pointer",
    width: "36px",
  },
  divider: {
    marginBottom: "15px",
    marginTop: "15px",
    backgroundColor: "black",
  },
  profileIcon: {
    height: 35,
    width: 35,
  },
  bioSection: {
    minHeight: 200,
    maxHeight: 250,
    width: "calc(100% - 50px)",
    maxWidth: "calc(100% - 50px)",
    color: colors.darkThemeBlueGray,
    lineHeight: '20px',
    fontSize: 14,
  },
  bioLabel: {
    color: colors.darkThemeBlueGray,
    fontWeight: "bold",
  },
  bioBox: {
    width: "100%",
  },
  limit: {
    color: colors.lightGrey,
    fontSize: 12,
  },
  profileLink: {
    marginTop: 5,
    display: "block",
    "&:hover": {
      textDecoration: "none",
    }
  }
});

const bioMaxLength = 2000;

export const PublicProfile = ({
  user,
  labels,
  updateUserAvatarImage,
  updateUserBio,
  onAvatarChange
}) => {
  const classes = useStyles();
  const [bio, setBio] = useState(user.bio);
  const bioInputChangeDebounced = debounce((value) => {
    setBio(value);
    updateUserBio(value);
  }, 1000);

  const remainingLength = bio && bioMaxLength ? bioMaxLength - bio.length : bioMaxLength;

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <CardContent>
          <Grid className={classes.gridHeader} container>
            <div className="personal-info-header">
              {labels && (
                <img
                  className={classes.profileIcon}
                  alt=""
                  src={labels.publicProfileIcon.src}
                />
              )}
              <Typography
                variant="h5"
                className={classes.title}
                component="h1"
                sel="account-info"
              >
                Public Profile
              </Typography>
            </div>
            <div>
              <Link className={classes.profileLink} href={`/profile/${user.id}`}>
                <TextCustomButton
                  isArrowWave={true}
                  label={"View Profile"}
                />
              </Link>
            </div>
          </Grid>

          <Divider className={classes.divider} />
          <Grid container className={classes.item}>
            {user.firstName && (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                className={classes.itemContainer}
              >
                <AvatarCrop
                  user={user}
                  updateUserAvatarImage={updateUserAvatarImage}
                  onAvatarChange={onAvatarChange} 
                />
              </Grid>
            )}

            <Grid item xs={12} sm={6} md={6} className={classes.itemContainer}>
              <Box className={classes.bioBox}>
                <Typography
                  className={classes.bioLabel}
                >{`About me`}</Typography>
                <TextareaAutosize
                  className={classes.bioSection}
                  rowsMin={20}
                  rowsMax={40}
                  maxLength={bioMaxLength}
                  aria-label="About me"
                  placeholder="Introduce yourself to others. What should your Girls Who Code Community know about you?"
                  defaultValue={user.bio || ""}
                  onChange={(event) => {
                    bioInputChangeDebounced(event.target.value);
                  }}
                />
                <Typography className={classes.limit} aria-live="polite">{`Characters remaining: ${remainingLength}`}</Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default PublicProfile;
