import * as FormFields from './FormFields';
import Messaging from './Messaging';

const InviteMessaging = `
  name
  navbarInviteCtaLabel
  inviteFormHeading
  inviteFormHeadingCollegeLoops
  inviteFormAddressPlaceholder
  inviteFormCounterSingular
  inviteFormCounterPlural
  inviteFormEmailInvalidMessage
  inviteFormCloseCtaLabel
  inviteFormInviteCtaLabel
  inviteFormCommunityPartnerSpreadWorld
  inviteInternationalCommunityPartnerSpreadWorld
  inviteFormEmailField {
    ${FormFields.commonFieldAttributes}
    ${FormFields.formField}
  }
  inviteFormInvitedPersonNameField {
    ${FormFields.commonFieldAttributes}
    ${FormFields.formField}
  }
  inviteFormInvitedPersonLastNameField {
    ${FormFields.commonFieldAttributes}
    ${FormFields.formField}
  }
  inviteFormErrorMessages {
    ${Messaging}
  }
`;

export default InviteMessaging;
