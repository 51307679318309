import { call, put } from 'redux-saga/effects';

import { getDecisionMakerById } from '../api/decisionMakers';
import { decisionMakerLoaded } from '../actions/decisionMakers';

export function* loadDecisionMaker({ payload }) {
  try {
    let { data } = yield call(getDecisionMakerById, payload);
    yield put(decisionMakerLoaded(data));
  } catch (e) {
    console.log('TODO: handle error here');
    console.log(e);
  }
}

