import { put } from "redux-saga/effects";

import { arrayToObject } from "../utils/generic";
import {
  receivedUserCertificates,
  receivedAllUserCertificates,
  receivedUserCertificatesByProgram,
  receiveCertificateGenerationStatus,
  loadCertificateGenerationStatus as loadStatus,
  loadUserCertificatesByProgram as loadCertificates,
  receiveCertificateNameChangeStatus,
} from "../actions/certificates";
import * as certificatesApi from "../api/certificates";
import { showErrorMessage, showSuccessMessage } from "../actions/notification";

const errorNoTemplate = 'There was a problem updating your certificate(s). Certificate template cannot be found. Please reach out to hq@girlswhocode.com for further assistance.';
const errorRegenerateCertificate = 'There was a problem updating your certificate(s). Please try again later.';
const successRegenerateCertificate = 'Your certificate(s) update request have been successfully started. This may take a few minutes to complete.';

export function* loadAllUserCertificates({ payload }) {
  try {
    const { userId } = payload;
    const {
      data
    } = yield certificatesApi.getAllUserCertificatesByUserId(payload);

    yield put(
      receivedAllUserCertificates({
        userId,
        data,
      })
    );
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* loadUserCertificates({ payload }) {
  try {
    const { userId } = payload;
    const {
      data
    } = yield certificatesApi.getUserProgramCertificatesByUserId(payload);

    yield put(
      receivedUserCertificates({
        userId,
        data,
      })
    );
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* loadUserCertificatesByProgram({ payload }) {
  try {
    const { programId } = payload;

    const {
      data: {
        certificates,
        status,
        students,
      }
    } = yield certificatesApi.getUserCertificatesByProgram(payload);

    const groupedCertificates = arrayToObject(certificates, "userId");

    yield put(
      receivedUserCertificatesByProgram({
        programId,
        students,
        certificates: groupedCertificates,
        status,
      })
    );
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* loadCertificateGenerationStatus({ payload }) {
  try {
    const { programId, prevStatus } = payload;
    const {
      data
    } = yield certificatesApi.getCertificateGenerationStatus(programId);

    if (!!prevStatus && !data.isRunning) {
      yield put(loadCertificates({ programId }));
    }

    yield put(receiveCertificateGenerationStatus({ ...data, programId }));
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* startCertificatesGeneration({ payload }) {
  try {
    const { programId, forceStatusCheck } = payload;
    const {
      data
    } = yield certificatesApi.generateCertificates(payload);

    if (forceStatusCheck) {
      yield put(loadStatus({ programId }));
    }

    yield put(receiveCertificateGenerationStatus({ ...data, programId }));
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* loadCertificateNameChangeStatus({ payload }) {
  try {
    const {
      data
    } = yield certificatesApi.getCertificateNameChangeStatus(payload);

    yield put(receiveCertificateNameChangeStatus({ certificateId: payload, isRunning: data }));
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* startCertificateNameChangeGeneration({ payload }) {
  try {
    const { data } = yield certificatesApi.generateRenamedCertificates(payload);
    const { isRunning, certificatesWithNoTemplate, certificateIds } = data;
    const { memberId, newFirstName, newLastName } = payload;

    if (certificateIds.length) {
      for (var certificateId of certificateIds) {
        yield put(receiveCertificateNameChangeStatus({ certificateId, isRunning, memberId, newFirstName, newLastName }));
      }
    }

    if (!certificatesWithNoTemplate.length && isRunning) {
      yield put(showSuccessMessage(successRegenerateCertificate));
    }
    else if (certificatesWithNoTemplate.length) {
      yield put(showErrorMessage(errorNoTemplate));
    }
    else {
      yield put(showErrorMessage(errorRegenerateCertificate));
    }
  } catch (e) {
    // TODO: throw generic error
    yield put(showErrorMessage(errorRegenerateCertificate));
    console.log("TODO: handle error here");
    console.log(e);
  }
}
