import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Box,
  Typography,
  CardContent,
  Card,
  Grid,
} from "@material-ui/core/";
import Checkbox from "../../../components/CheckboxSimple";

import debounce from "debounce";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%"
  },
  title: {
    fontSize: "30px",
    marginLeft: "16px",
  },
  gridHeader: {
    justifyContent: "space-between",
  },
  divider: {
    marginBottom: "15px",
    marginTop: "15px",
    backgroundColor: "black",
  },
  profileIcon: {
    height: 35,
    width: 35,
  },
  contentBox: {
    margin: '20px 0 10px',
    [theme.breakpoints.up("md")]: {
      marginLeft: 50
    },
  },
  checkboxBox: {
    marginTop: 10,
  },
}));

export const NotificationSettings = ({
  user,
  labels,
  updateSmsConsent
}) => {
  const classes = useStyles();

  const [smsConsent, setSmsConsent] = useState(!!user.smsConsent || false);
  const consentCbxChangeDebounced = debounce((isChecked) => {
    setSmsConsent(isChecked);
    updateSmsConsent({isSmsConsent: isChecked});
  }, 300);

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <CardContent>
          <Grid className={classes.gridHeader} container>
            <div className="personal-info-header">
              {labels && (
                <img
                  className={classes.profileIcon}
                  alt=""
                  src={labels.notificationsIcon.src}
                />
              )}
              <Typography
                variant="h5"
                className={classes.title}
                component="h1"
                sel="account-noitifcations"
              >
                Notification Settings
              </Typography>
            </div>
          </Grid>

          <Divider className={classes.divider} />
          <Box className={classes.contentBox}>
            <Typography>Girls Who Code may occasionally send updates and confirmation requests via text message.</Typography>
            <Checkbox
              name="isSmsConsent" 
              sel="user-sms-consent"
              className={classes.checkboxBox}
              checked={smsConsent}
              label="I consent to receiving text messages from Girls Who Code."
              onChange={(event) => {
                consentCbxChangeDebounced(event.target.checked);
              }}/>
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default NotificationSettings;
