import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import * as colors from "../../../theme/colors";

import CustomButton from "../../../components/text-button/text-button";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";

import IncentivesReqs from './IncentivesReqs';
import { PROGRAM_TYPES } from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "8px 8px 12px 8px",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  media: {
    height: 0,
    paddingTop: "56.25%",
  },
  cardHeader: {
    padding: 0,

    [theme.breakpoints.down("xs")]: {
      wordBreak: "break-all",
    },
    "& .MuiCardHeader-action": {
      margin: 0,
      "align-self": "auto",
    },
  },
  startTitle: {
    fontWeight: "bold",
    marginRight: 5,
  },
  subTitle: {
    display: "flex",
  },
  membersCount: {
    color: (props) => props.countColor,
    fontSize: 70,
    fontWeight: "bold",
    textAlign: "center",
  },
  boxContent: {
    display: "flex",
    justifyContent: "flex-end",
  },
  content: {
    padding: "0 0 0 4px",
    "&:last-child": {
      paddingBottom: 0,

      [theme.breakpoints.down("xs")]: {
        wordBreak: "break-all",
      },
    },
  },
  viewMemberLabel: {
    '&:hover': {
      color: colors.lightThemeGreen,
    },
  }
}));

const memberOptions = [
  { minVal: 1, maxVal: 5, label: "1-5" },
  { minVal: 5, maxVal: 10, label: "5-10" },
  { minVal: 10, maxVal: 20, label: "10-20" },
  { minVal: 20, label: "Over 20" },
];

const getColor = (number, projectedNumberOfStudents) => {
  const item = memberOptions.find(e => e.label === projectedNumberOfStudents);

  if (!number || !item || !projectedNumberOfStudents || !projectedNumberOfStudents.trim()) {
    return colors.blue2;
  }

  return number < item.minVal ? colors.orange450 : colors.darkThemeGreen;
};

export const RenderCardContent = ({ item, filteredStudentsCount, index, push, isEditable, labels, rolePermissions }) => {
  const countColor = getColor(filteredStudentsCount, item.projectedNumberOfStudents);

  const showEligibleReqs = rolePermissions?.showClubIncentives && item.programType === PROGRAM_TYPES.CLUB && filteredStudentsCount < 3;

  const classes = useStyles({ countColor });
  return (
    <Box key={index} className={classes.contentBox}>
      <Box>
        <Typography className={classes.membersCount}>{filteredStudentsCount}</Typography>
        {isEditable && (
          <Box className={classes.boxContent}>
            <CustomButton
              className={classes.viewMemberLabel}
              onClick={() => {
                push(`/members/${item.code}`);
              }}
            >
              {`${filteredStudentsCount === 0 ? 'Register' : 'Manage'} members`}
            </CustomButton>
          </Box>
        )}
        {showEligibleReqs &&
          <IncentivesReqs labels={labels} />
        }
      </Box>
    </Box>
  );
};

const MembersCard = ({ club, filteredStudentsCount, push, isEditable, labels, rolePermissions }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        title={<Typography className={classes.title}>Members</Typography>}
        className={classes.cardHeader}
        action={
          <div className={classes.subTitle}>
            {" "}
            <Typography className={classes.startTitle}>Projected:</Typography>
            <Typography>{club.projectedNumberOfStudents}</Typography>
          </div>
        }
      />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <RenderCardContent
            item={club}
            filteredStudentsCount={filteredStudentsCount}
            push={push}
            isEditable={isEditable}
            labels={labels}
            rolePermissions={rolePermissions}
            />
        </CardContent>
      </div>
    </Card>
  );
};

export default MembersCard;
