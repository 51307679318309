import { createReducer } from "redux-act";

import * as a from "../actions/clubProfile";

const getDefaultState = () => ({
  isLoading: true,
  isEditable: false,
  program: null,
  isUpdatingPreferences: false,
  programByCode: null,
  roleLabels: [],
  isLoadingRoleLabels: false,
  isLoadingUserRoleLabels: false,
  isUpdatingUserRoleLabels: false,
  userRoleLabels: [],
});

export default () =>
  createReducer(
    {
      [a.getClubInfo]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [a.updateClubInfo]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [a.updatePreferences]: (state) => ({
        ...state,
        isUpdatingPreferences: true
      }),
      [a.updateSocialMedia]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [a.receivedPreferences]: (state, program) => {
        return {
          ...state,
          isUpdatingPreferences: false,
          program: {
            ...state.program,
            ...program,
          }
        };
      },
      [a.receivedClubInfo]: (state, program) => {
        return {
          ...state,
          isLoading: false,
          program: {
            ...state.program,
            ...program,
            students: program.students || state.program?.students,
            facilitators: program.facilitators || state.program?.facilitators,
            viewers: program.viewers || state.program?.viewers,
          },
        };
      },
      [a.receivedSocialMedia]: (state, program) => {        
        return {
          ...state,
          program: {
            ...state.program,
            ...program,
          },
          isLoading: false,
        };
      },
      [a.receivedCommunityPartnership]: (state, communityPartnership) => {        
        return {
          ...state,
          program: {
            ...state.program,
            communityPartnership,
          },
          isLoading: false,
        };
      },
      [a.onError]: (state) => {        
        return {
          ...state,
          isLoading: false,
          isUpdatingPreferences: false,
          isLoadingRoleLabels: false,
          isLoadingUserRoleLabels: false,
        };
      },
      [a.getClubInfoByCode]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [a.receivedClubInfoByCode]: (state, program) => {
        return {
          ...state,
          isLoading: false,
          programByCode: program,
        };
      },
      [a.cleanedClubInfoByCode]: (state) => {
        return {
          ...state,
          isLoading: false,
          programByCode: null,
        };
      },
      [a.getRoleLabels]: (state) => ({
        ...state,
        isLoadingRoleLabels: true,
      }),
      [a.receivedRoleLabels]: (state, roleLabels) => {
        return {
          ...state,
          isLoadingRoleLabels: false,
          roleLabels,
        };
      },
      [a.createUserRoleLabel]: state => ({
        ...state,
        isLoadingUserRoleLabels: true,
        isUpdatingUserRoleLabels: true,
      }),
      [a.deleteUserRoleLabel]: state => ({
        ...state,
        isLoadingUserRoleLabels: true,
        isUpdatingUserRoleLabels: true,
      }),
      [a.getUserRoleLabels]: (state) => ({
        ...state,
        isLoadingUserRoleLabels: true,
      }),
      [a.receivedUserRoleLabels]: (state, userRoleLabels) => {
        return {
          ...state,
          isLoadingUserRoleLabels: false,
          isUpdatingUserRoleLabels: false,
          userRoleLabels,
        };
      },
      [a.receivedDeletedUserRoleLabel]: (state, payloadId) => {
        const newUserRoleLabels = state.userRoleLabels.filter(e => e.id !== payloadId);
          return {
            ...state,
            isLoadingUserRoleLabels: false,
            isUpdatingUserRoleLabels: false,
            userRoleLabels: newUserRoleLabels
          };
      },
      [a.receivedChangedUserRoleLabel]: (state, userRoleLabel) => {
        const currentUserRoleLabel = state.userRoleLabels.find(label => label.id === userRoleLabel.id);
        const newUserRoleLabels = state.userRoleLabels;

        if (currentUserRoleLabel && userRoleLabel.roleLabelId) {
          currentUserRoleLabel.roleLabelId = userRoleLabel.roleLabelId;

          return {
            ...state,
            isUpdatingUserRoleLabels: false,
            isLoadingUserRoleLabels: false,
            userRoleLabels: newUserRoleLabels,
          };
        }

        newUserRoleLabels.push(userRoleLabel);

        return {
          ...state,
          isLoadingUserRoleLabels: false,
          isUpdatingUserRoleLabels: false,
          userRoleLabels: newUserRoleLabels,
        };
      },
    },
    getDefaultState()
  );
