import React from "react";
import * as moment from "moment";

import "./personal-info.scss";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import CustomButton from "../../../components/customButton/customButton";
import EditIcon from "@material-ui/icons/Edit";
import * as accountOriginConstants from "../../../constants/userConstants";
import { getCountryFormat } from '../../../utils/generic';
import { DOMESTIC_ID } from '../../../utils/countriesDict';

const useStyles = makeStyles({
  card: {
    width: "100%",

    "& .MuiTypography-colorTextSecondary": {
      color: "#003046",
    },

    "& .MuiTypography-body2": {
      fontFamily: "'Roboto Mono',monospace",
    },
  },
  title: {
    fontSize: "30px",
    marginLeft: "16px",
  },
  label: {
    fontSize: 50,
  },
  gridHeader: {
    justifyContent: "space-between",
  },
  list: {
    width: "100%",
  },
  itemContainer: {
    marginTop: "15px",
    marginBottom: "15px",
  },
  item: {
    marginTop: "15px",
    marginBottom: "15px",
    wordWrap: "break-word",
  },
  editIcon: {
    marginTop: 8,
  },
  editContainer: {
    backgroundColor: "transparent",
    border: "1px solid #565656",
    borderRadius: "50%",
    height: "36px",
    lineHeight: "37px",
    textAlign: "center",
    cursor: "pointer",
    width: "36px",
  },
  divider: {
    marginBottom: "15px",
    marginTop: "15px",
    backgroundColor: "black",
  },
  profileIcon: {
    height: 35,
    width: 35,
  },
});

export const PersonalInfo = (props) => {
  const classes = useStyles();
  const { user, labels } = props;
  
  const allowEdit = user.accountOrigin !== accountOriginConstants.ADDED_BY_FACILITATOR;
  const birthDate = user?.birthDate
  ? moment
      .parseZone(user.birthDate)
      .format(getCountryFormat(user.countryId, DOMESTIC_ID).toUpperCase())
  : "";

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <CardContent>
          <Grid className={classes.gridHeader} container>
            <div className="personal-info-header">
              <img
                className={classes.profileIcon}
                alt=""
                src={labels.personalInfoIcon.src}
              />
              <Typography
                variant="h5"
                className={classes.title}
                component="h1"
                sel="account-info"
              >
                Account Info
              </Typography>
            </div>
            <div>
              {allowEdit && <CustomButton
                mode="secondary"
                label={<EditIcon />}
                onClick={() => props.openEditForm()}
                sel="edit-account-info"
                aria-label="edit account info"
              />}
            </div>
          </Grid>

          <Divider className={classes.divider} />
          <Grid container className={classes.item}>
            {user.firstName && (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className={classes.itemContainer}
              >
                <ListItemText
                  primary="First name"
                  className={classes.item}
                  classes={{
                    body1: classes.label,
                    label: classes.label, // here
                  }}
                  secondary={user.firstName}
                  sel="first-name"
                />
              </Grid>
            )}

            {user.lastName && (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className={classes.itemContainer}
              >
                <ListItemText
                  primary="Last name"
                  className={classes.item}
                  secondary={user.lastName}
                  sel="last-name"
                />
              </Grid>
            )}

            {user.email && (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className={classes.itemContainer}
              >
                <ListItemText
                  primary="Email"
                  className={classes.item}
                  secondary={user.email}
                  sel="email"
                />
              </Grid>
            )}

            {user.username && (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className={classes.itemContainer}
              >
                <ListItemText
                  primary="Username"
                  className={classes.item}
                  secondary={user.username}
                  sel="username"
                />
              </Grid>
            )}

            {birthDate && (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className={classes.itemContainer}
              >
                <ListItemText
                  primary="Birthdate"
                  className={classes.item}
                  secondary={birthDate}
                  sel="birthdate"
                />
              </Grid>
            )}
            {user.currentSchool && (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className={classes.itemContainer}
              >
                <ListItemText
                  primary="Your current school"
                  className={classes.item}
                  secondary={user.currentSchool || "--"}
                />
              </Grid>
            )}
            {user.phoneNumber && (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className={classes.itemContainer}
              >
                <ListItemText
                  primary="Phone number"
                  className={classes.item}
                  secondary={user.phoneNumber || "--"}
                />
              </Grid>
            )}
            {user.graduationYear && (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className={classes.itemContainer}
              >
                <ListItemText
                  primary="Expected high school graduation year"
                  className={classes.item}
                  secondary={user.graduationYear || "--"}
                />
              </Grid>
            )}
            {user.timezone && (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className={classes.itemContainer}
              >
                <ListItemText
                  primary="Timezone"
                  className={classes.item}
                  secondary={user.timezone || "--"}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default PersonalInfo;
