import React, { useEffect, useState } from "react";
import { push as pushToRoute } from "connected-react-router";
import qs from "query-string";
import { Formik, Field, Form } from "formik";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { formatEstimatedMinutes } from "../../utils/generic";
import {
  Box,
  Typography
} from "@material-ui/core";
import Selector from "../../components/selector/Selector";

import { connectTo, getUserName, arrayToObject } from "../../utils/generic";
import * as authActions from "../../actions/auth";
import * as membersActions from '../../actions/members';
import * as ratingsActions from "../../actions/rating";
import * as contentfulActions from "../../actions/contentful";
import * as userActivitiesInfoActions from "../../actions/userActivitiesInfo";
import * as trackingActions from "../../actions/tracking";
import * as certificatesActions from "../../actions/certificates";
import * as openTextActions from "../../actions/openText";
import * as answerGroupActions from "../../actions/answerGroups"
import Tabs from "../UserProgress/Tabs/";
import ProgressTable from "./progressTable";
import AllProgramProjects from './allProgramProjects'
import NavBar from "../HorizontalNavigation";
import Footer from "../../containers/Footer/footer";
import moment from "moment";

import withPageLabels from "../../hocs/withPageLabels";
import { PROGRESS_VIEW_PROGRAM } from "../../constants/trackingEvents";

import UserProgress from "../UserProgress/Tabs/userProgress";
import UserProjects from "../UserProgress/Tabs/userProjects";
import UserAnswers from "../UserProgress/answers/answers";
import * as helpers from "../UserProgress/helpers";
import { isActivityPendingGrading, isActivityNotIncomplete } from "../UserProgress/Table";
import { getUserProjectsByProgram as getUserProjectsByProgramAction, getProjectsByProgram as getProjectsByProgramAction, getIsUserProjectsByProgram as getIsUserProjectsByProgramAction, getUsersProjectsByProgram as getUsersProjectsByProgramAction } from "../../actions/projectGallery";

import PageTitle from "../../components/page-title/pageTitle";
import CertificatesTable from "./certificates/certificatesTable";

import { useTitle } from "../../utils/pageTitles";
import Answers from "./answers/answers";

const config = JSON.parse(`{
  "columns": [
      {
        "id": "name",
        "align": "left",
        "label": "Name",
        "styleType": "main",
        "type": "name"
      },
      {
        "id": "setProgress",
        "label": "Set",
        "align": "center",
        "type": "linear"
      },
      {
        "id": "latestActivity",
        "align": "left",
        "label": "Latest Activity"
      },
      {
        "id": "detail",
        "align": "left",
        "label": "Detail",
        "type": "detail"
      },
      {
        "id": "date",
        "align": "left",
        "label": "Date",
        "styleType": "main"
      },
      {
        "id": "expand",
        "align": "center",
        "type": "expand",
        "label": ""
      }
  ]
}`);

const tabs =  {
  answers: "Answers",
  activities: "Activities",
  certificates: "Certificates",
  projects: "Projects",
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
  },
  main: {
    width: "100%",
    margin: "auto auto",
    height: "100%",
    minHeight: 'calc(100vh - 132px)',
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    paddingTop: 50,
    [theme.breakpoints.down('md')]: {
      paddingTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 100,
    },
  },
  divider: {
    margin: "20px 20px",
    [theme.breakpoints.up("md")]: {
      margin: "40px 25px",
    },
  },
  header: {
    margin: "15px auto",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between"
  },
  contentBox: {
    width: "95%",
    margin: "auto",
  },
  tabsSection: {
    margin: '-10px auto 15px -25px',
  },
  filteringContainer: {
    display: "flex",
  },
  filteringTitle: {
    display: "flex",
    flexWrap: "wrap",
    alignContent: "space-around",
    textTransform: "uppercase",
    marginRight: 10,
  },
  programSelectBox: {
    width: 200,
  },
  dropdown: {
    width: 300,
  },
}));

const DEFAULT_PAGE_SIZE = 90;

const getActivity = (item, activityList, parentNode) => {
  if (item.type === "activity" || item.nodeType === "activity") {
    activityList.push({ activity: item, parentNode });
  } else {
    const plainNodes = Object.values(item.nodes);
    for (let i = 0; i < plainNodes.length; i++) {
      const n = plainNodes[i];

      if (n.type === "activity" || n.nodeType === "activity") {
        activityList.push({ activity: n, parentNode: item });
      } else {
        getActivity(n, activityList, item);
      }
    }
  }
};

const getActivitiesList = (nodes) => {
  const activities = [];
  getActivity(nodes, activities, null);
  return activities;
};

const getOldActivityLog = (item) => {
  const activityLogs = item.activitiesInfo
    ? item.activitiesInfo.filter((e) => e.isViewed || e.isDone)
    : null;
  const lastLog = activityLogs[activityLogs.length - 1];

  return {
    ...item,
    name: getUserName(item),
    latestActivity: lastLog ? lastLog.title : "No Activity Yet",
    sectionProgress: "",
    sectionName: "",
    detail: false,
    date: "",
    step: "",
    setProgress: -1,
  };
};

const getLatestActivityLog = (logs) => {
  const withContentSections = logs.filter((e) => e.contentSection);

  const max = Math.max.apply(
    Math,
    withContentSections.map(function (o) {
      return o.id;
    })
  );
  return logs.find((i) => i.id === max);
};

const calcSetDetails = (set, logs, lasActivitySlug, userAnswers) => {
  if (!set || !set.activities) {
    return { setProgress: -1 };
  }

  // Get all logs related to current set
  const setLogs = logs.filter((e) => e.activitySetSlug == set.slug);
  const setWithoutOptionalActivities = set.activities.filter(a => !a.isOptional);

  const activityStats = setWithoutOptionalActivities.map((i) => {
    // Get all logs related to  activity in set
    const activitiesLogs = setLogs.filter((s) => s.title === i.slug);
    // Get log that means this activity is done
    const activityDoneLog = activitiesLogs.find(
      (s) => s.title === i.slug && s.isDone
    );

    const studentsSections = i.contentSections
      ? i.contentSections.filter((e) => !e.onlyVisibleToFacilitators)
      : [];

    // Get list of unique content sections were logged for currect activity
    const uniqueContentSections = activitiesLogs
      .filter(
        (x) =>
          x.contentSection &&
          studentsSections.find((e) => e.id === x.contentSection)
      )
      .filter(
        (v, i, a) =>
          a.findIndex((t) => t.contentSection === v.contentSection) === i
      );
    // Get list of content sections logs to calculate time spent
    const sectionLogs = activitiesLogs.filter((e) => e.contentSection);
    const seconds = sectionLogs.reduce((a, b) => a + (b["duration"] || 0), 0);

    let time = "";

    if (seconds > 0 || uniqueContentSections.length > 0) {
      // Calculate time for content section
      const minutes = Math.floor(seconds / 60);
      if (minutes > 60) {
        time = formatEstimatedMinutes(minutes);
      } else {
        time = minutes > 0 ? `${minutes} mins` : "< 1 min";
      }
    }
    const contentSectionIndex = getHighterContentSectionIndex(
      uniqueContentSections,
      studentsSections
    );

    const { userId } = !!logs.length && logs[0];
    let isPendingGrade = false;
    let isIncomplete = false;
    i.contentSections.some((section) => {
      const path = `${set.pathWithFolder || set.path}/${i.slug}-${section.id}`;
      const answers = userAnswers?.[path]?.[userId];
      if (answers) {
        const isSectionPendingGrade = answers.some((answer) =>
          isActivityPendingGrading({ contentSection: section, answer })
        );
        const isSectionIncomplete = !answers.some((answer) =>
          isActivityNotIncomplete(answer)
        );
        if (isSectionIncomplete) {
          isIncomplete = true;
        }
        if (isSectionPendingGrade) {
          isPendingGrade = true;
        }
      }
    });

    return {
      name: i.name,
      activitySlug: i.slug,
      isLastActivity: i.slug === lasActivitySlug,
      isDone: !!activityDoneLog,
      activityStatus: `${isIncomplete ? contentSectionIndex : contentSectionIndex + 1} of ${
        studentsSections.length
      }`,
      time,
      isPendingGrade,
      isIncomplete,
      notStarted: contentSectionIndex === -1,
    };
  });

  return {
    setProgress:
      (activityStats.filter((e) => e.isDone && !e.isIncomplete).length * 100) /
      setWithoutOptionalActivities.length,
    setName: set.name,
    icon: set.icon && set.icon.src ? set.icon.src : "",
    activityStats,
  };
};

const getHighterContentSectionIndex = (logs, contentSections) => {
  let sectionIndex = -1;

  contentSections
    .filter((r) => !r.onlyVisibleToFacilitators)
    .map((el, index) => {
      const isExist = logs.find((e) => e.contentSection === el.id);
      if (isExist) {
        sectionIndex = index;
      }
    });
  return sectionIndex;
};

const createDataRow = (item, activities, userAnswers) => {
  const { activitiesInfo } = item;
  const lastActivityLog = getLatestActivityLog(activitiesInfo);

  if (!lastActivityLog) {
    // get old user activity log result before log structute was updated
    return getOldActivityLog(item);
  }

  const activityNode =
    lastActivityLog && activities
      ? activities.find(
          (e) =>
            e.activity.slug === lastActivityLog.title &&
            (!lastActivityLog.path ||
              `/gwc${e.activity.path}` === lastActivityLog.path)
        )
      : {};

  const activity = activityNode ? activityNode.activity : null;
  const parentNode = activityNode ? activityNode.parentNode : null;

  const studentsSections =
    activity && activity.contentSections
      ? activity.contentSections.filter((e) => !e.onlyVisibleToFacilitators)
      : [];

  // Get logs for current activity path
  const currentActivityLogs = activitiesInfo.filter(
    (s) => s.path === lastActivityLog.path
  );

  // Get list of unique content sections were logged for currect activity
  const uniqueContentSections = currentActivityLogs
    .filter(
      (x) =>
        x.contentSection &&
        studentsSections.find((e) => e.id === x.contentSection)
    )
    .filter(
      (v, i, a) =>
        a.findIndex((t) => t.contentSection === v.contentSection) === i
    );

  const { setProgress, setName, icon, activityStats } = calcSetDetails(
    parentNode,
    activitiesInfo,
    lastActivityLog ? lastActivityLog.title : "",
    userAnswers
  );

  const contentSections = activity ? activity.contentSections : [];
  const contentSectionIndex = getHighterContentSectionIndex(
    uniqueContentSections,
    contentSections
  );

  const isActivityContentSectionsExist =
    activity && studentsSections.length > 0 && uniqueContentSections.length > 0;

  const result = {
    ...item,
    name: getUserName(item),
    latestActivity: activity && lastActivityLog ? activity.name : "No Activity Yet",
    detail: isActivityContentSectionsExist,
    sectionName: isActivityContentSectionsExist
      ? activity.contentSections.filter((r) => !r.onlyVisibleToFacilitators)[
          contentSectionIndex
        ].heading
      : "",
    sectionProgress: isActivityContentSectionsExist
      ? `${contentSectionIndex + 1} of ${
          activity.contentSections.filter((r) => !r.onlyVisibleToFacilitators)
            .length
        }`
      : "",
    setName,
    setProgress,
    date:
      lastActivityLog && activity && moment(lastActivityLog.date).isValid()
        ? moment(lastActivityLog.date).format("MM/DD/YYYY")
        : "",
    setInfo: activityStats,
    setIcon: icon,
  };

  return result;
};

const allMembersLabel = 'ALL MEMBERS';
export const FilterBlock = ({ classes, setSelectedGroupUsers, usersGroupsList, selectedGroupUsers }) => {
  return (<Box className={classes.filteringContainer}>
    <Box className={classes.filteringTitle}>
      <Typography className={classes.subTitle}>Filter by:</Typography>
    </Box>
    <Formik
      initialValues={{
        groups: selectedGroupUsers,
      }}
      validate={(values) => {
        let errors = {};
        if (values) {
          errors.groups = 'Group name is not selected';
        }

        return errors;
      }}
    >
      <Form>
        <Field
          name="groups"
          component={Selector}
          label="GROUP"
          shrink={false}
          dropDownclassName={classes.dropdown}
          className={classes.programSelectBox}
          options={usersGroupsList}
          onValueChange={(values) => {
            setSelectedGroupUsers(values);
          }}
        />
      </Form>
    </Formik>
  </Box>);

}

const Progress = ({
  auth: { currentRole, user },
  changeRole,
  contentful: { studentCurriculums, hqConfig, GlobalComponentQuery, programTypes },
  userActivitiesInfo: {
    userActivitiesInfo,
    isLoadingProgress,
    userProgressByProgram,
    isLoadingUserActivitiesInfo,
    isLoadingAdditionalProgress,
  },
  match: {
    params: { clubCode, tab, id, progressInputParam, userId },
  },
  history: {
    location: { search, state: previosState },
  },
  push,
  getUserActivitesByProgramCode,
  pageLabels,
  getRatingsByProgram,
  rating: { programRatings },
  createTrackingLog,
  // user progress
  getUserActivitiesByProgram,
  deleteMyActivityProgress,
  getUserProjectsByProgram,
  getIsUserProjectsByProgram,
  isUserProjects,
  getUsersProjectsByProgram,
  usersProjectsByProgram,
  isLoadingProjects,
  galleryProjects,
  getProjectsByProgram,
  allGalleryProjects,
  loadUserCertificatesByProgram,
  loadCertificateGenerationStatus,
  startCertificatesGeneration,
  programMemberCertificates,
  openTextAnswers,
  getAllAnswersForProgram,
  curriculum,
  getCommentsByUser,
  getCommentsByAnswer,
  getCommentsForAnswerByUser,
  getUserAnswersByProgram,
  addComment,
  updateStatus,
  updateAnswerGroupsStatus,
  deleteComment,
  getUsersGroups,
  members: {
    usersGroups,
    certificateId,
    membersCount,
  },
  studentView,
  answerGroups,
  getAnswerGroupsByProgram,
  getUserAnswerGroupsByProgram,
  addAnswerGroupComment,
  deleteAnswerGroupComment,
  history,
  getProgramCertificateId,
  updateComment,
  updateAnswerGroupComment,
  getAdditionalUserActivitesByProgramCode,
}) => {
  useTitle("progress");
  
  const classes = useStyles();
  const [nodes, setNodes] = useState(null);
  const [userLogs, setUserLogs] = useState([]);
  const [labels, setLabels] = useState(null);

  // user progress
  const [userProgressId, setUserProgressId] = useState(!!userId ? parseInt(userId, 10) : null);
  const [nextUserProgressId, setNextUserProgressId] = useState(null);
  const [currentTab, setCurrentTab] = useState(previosState?.activeTab || 'Activities');
  const [isLoading, setIsLoading] = useState(true);
  const [showAnswers, setShowAnswers] = useState(false);
  const [showUserAnswers, setShowUserAnswers] = useState(false);
  const [selectedGroupUsers, setSelectedGroupUsers] = useState([]);
  const projectsOfCurrentUsers = [];
  const [projectsToRender, setProjectsTorender] = useState(null);
  const [editedAnswer, setEditedAnswer] = useState(null);
  const [editedAnswerUserId, setEditedAnswerUserId] = useState(null);
  const [userFullName, setUserFullName] = useState(null);

  const usersGroupsListWithoutAll = Object.keys(usersGroups).map(groupName => ({ value: !!usersGroups[groupName]?.users.length ? usersGroups[groupName]?.users : 'empty', label: groupName })).sort((a, b) => a.label.localeCompare(b.label));

  const usersGroupsList = usersGroupsListWithoutAll.length > 0 ? [{value: allMembersLabel, label: allMembersLabel}].concat(usersGroupsListWithoutAll) : [];
  const usersGroupFilter = (user, selectedGroupUsersList) => selectedGroupUsersList.includes(user?.userId) ||
   selectedGroupUsersList.includes(allMembersLabel) || !selectedGroupUsersList.length;

  useEffect(() => {
    if (!user) return;

    const { roles } = user;

    if (!clubCode) {
      push("/home");
      return;
    }

    const selectedRole = roles.find((r) => {
      return r.program && r.program.code === clubCode;
    });

    // if it's not a facilitator or summer teacher or if it's a default club
    if (
      !selectedRole ||
      selectedRole.roleId !== 1 ||
      selectedRole.programId === parseInt(hqConfig.defaultProgram, 10)
    ) {
      push("/gwc"); // redirect home
      return;
    }

    if (currentRole.programId !== selectedRole.programId) {
      changeRole({ programId: selectedRole.programId });
    }
    getUserActivitesByProgramCode({ programId: selectedRole.programId, code: clubCode, pageNumber: 0, pageSize: DEFAULT_PAGE_SIZE });
    getRatingsByProgram({ programId: selectedRole.programId });
    getUsersProjectsByProgram({ programId: selectedRole.programId });
    getAllAnswersForProgram({ programId: selectedRole.programId });
    getAnswerGroupsByProgram({ programId: selectedRole.programId });

    if (currentRole?.permissions?.viewMembers && !usersGroups.length) {
      getUsersGroups({ programId: currentRole.programId });
    }

    createTrackingLog({
      event: PROGRESS_VIEW_PROGRAM,
      data: JSON.stringify({ programId: selectedRole.programId }),
    });
  }, [user]);

  useEffect(() => {
    const currentCurriculum = currentRole
      ? currentRole.program
        ? studentCurriculums[currentRole.program.curriculum.code]
        : null
      : null;

    if (currentCurriculum && userActivitiesInfo) {
      const activitiesList = getActivitiesList(currentCurriculum);
      const programLogs = userActivitiesInfo[clubCode];
      if (programLogs && programLogs.length > 0) {
        const data = programLogs.map((e) => createDataRow(e, activitiesList, openTextAnswers.programAnswers || openTextAnswers));
        data.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : 0
        );
        setUserLogs(data || []);
      }
      setIsLoading(isLoadingProgress || false);
      setNodes(activitiesList);
    }
  }, [studentCurriculums, currentRole, userActivitiesInfo]);

  useEffect(() => {
    if (!isLoadingProgress && !!userActivitiesInfo && !!userActivitiesInfo[clubCode] && !userActivitiesInfo[clubCode].length) {
      setIsLoading(false);
      setUserLogs([]);
    }
  }, [isLoadingProgress, userActivitiesInfo])

  useEffect(() => {
    if (!userProgressId || !currentRole?.programId || !openTextAnswers?.userAnswers || !studentCurriculums || !studentCurriculums[currentRole.program.curriculum.code]) return;

    const curriculum = studentCurriculums[currentRole.program.curriculum.code];
    const { userAnswers } = openTextAnswers;
    const isUserAnswersExists = helpers.getIsUserAnswersExists(curriculum, userAnswers);
    setShowUserAnswers(isUserAnswersExists);
  }, [studentCurriculums, openTextAnswers, userProgressId]);

  useEffect(() => {
    if (!userProgressId || !userLogs.length) return;

    const currentUser = userLogs.find(user => user.userId === userProgressId);
    const userFullName = getUserName(currentUser);
    setUserFullName(userFullName);
  }, [userProgressId, userLogs])

  useEffect(() => {
    if (!user) {
      return;
    }

    const { roles } = user;
    const { programAnswers } = openTextAnswers;

    const selectedRole = roles.find((r) => {
      return r.program && r.program.code === clubCode;
    });

    if (!selectedRole) {
      push("/home");
      return;
    }
    
    const usersGroupsMembersAnwers = Object.keys(programAnswers || {}).filter((activity) => {
      const programActivityAnswers = Object.entries(programAnswers[activity] || {}).map(([name,item]) => {
        if (item[0].answerGroupId) return;
        return parseInt(name);
      }).filter(Boolean);

      if (!programActivityAnswers.length) return false;
      return programActivityAnswers.filter(userId => usersGroupFilter({userId}, selectedGroupUsers)).length;
    });

    const usersGroupsMembersAnwerGroups = Object.values(answerGroups.answerGroups || []).filter((activity) => {
      return activity.filter(userId => usersGroupFilter({userId}, selectedGroupUsers)).length;
    });

    const answerGroupsExists = !!Object.keys(answerGroups.answerGroups || {}).length;

    setShowAnswers(!!usersGroupsMembersAnwers.length || answerGroupsExists);
    if (currentTab === 'Answers' && !usersGroupsMembersAnwers.length && !usersGroupsMembersAnwerGroups.length || !!studentView) {
      setCurrentTab('Activities');
    }

  }, [openTextAnswers, selectedGroupUsers, studentView, answerGroups]);

  useEffect(() => {
    const parsed = qs.parse(search);

    const setTab = !!parsed?.tab
        ? parsed.tab
        : !!tab
            ? tab
            : null;

    const setAnswerId = !!parsed?.editedAnswer
        ? parsed.editedAnswer
        : !!id
            ? id
            : null;

    if (setTab && !!tabs[setTab]) {
      setCurrentTab(tabs[setTab]);

      if (setTab === tabs.answers.toLowerCase()) {
         setEditedAnswer(setAnswerId);
        if (!!progressInputParam) {
          setEditedAnswerUserId(parseInt(progressInputParam, 10));
        }
      }
    }
  }, [showAnswers]);

  useEffect(() => {
    if (!pageLabels || !pageLabels.modules) {
      return;
    }

    setLabels(pageLabels.modules[0]);
  }, [pageLabels]);

  useEffect(() => {
    if (currentRole?.program?.id) {
      getProgramCertificateId(currentRole.program.id);
    }
  }, [currentRole]);

  useEffect(() => {
    currentRole?.programId && getProjectsByProgram({ programId: currentRole.programId });
  }, [currentRole?.programId]);

  useEffect(() => {
    if (allGalleryProjects && userLogs?.length) {
      const usersGroupLogs = userLogs.filter(user => usersGroupFilter(user, selectedGroupUsers))
      const usersItem = arrayToObject(usersGroupLogs, 'userId');

      allGalleryProjects.forEach((project) => {
        if (usersItem[project.submitterId]) {
          projectsOfCurrentUsers.push(project);
        }
      });
      const item = arrayToObject(projectsOfCurrentUsers, 'submitter.firstName', 'submitter.lastName');

      setProjectsTorender(item);
    }
  }, [allGalleryProjects, userLogs, selectedGroupUsers]);

  useEffect(() => {
    if (!currentRole || !userProgressId) {
      return null;
    }
    getUserAnswersByProgram({ programId: currentRole.programId, userId: userProgressId });
    getUserAnswerGroupsByProgram({ programId: currentRole.programId, userId: userProgressId })
  }, [userProgressId]);
  
  const openUserProgress = (userId) => {
    const selectedUsersGroupsMembers = userLogs.filter(user => usersGroupFilter(user, selectedGroupUsers));
    const index = selectedUsersGroupsMembers.findIndex((e) => e.userId === userId);
    const nextUserId =
      selectedUsersGroupsMembers.length - 1 > index ? selectedUsersGroupsMembers[index + 1].userId : null;

    setNextUserProgressId(nextUserId);
    setUserProgressId(userId);

    push(`/progress/${clubCode}/user/${userId}/view/${currentTab.toLowerCase()}`);
  };

  const projectsOfUsersInProjectCurrently = (!!usersProjectsByProgram && usersProjectsByProgram.map(item => {
    const user = userLogs.filter(user => usersGroupFilter(user, selectedGroupUsers)).find(o => o.userId === item);
    return { ...user };
  }).filter((user) => { return user.userId })) || [];

  const certificateRibbonIcon = GlobalComponentQuery
  ? GlobalComponentQuery.setPageLabels.certificateRibbonIcon
  : null;

  const projectIcon = GlobalComponentQuery
  ? GlobalComponentQuery.setPageLabels.projectIcon
  : null;

  const setActiveTab = (value) => {
    const tabKey = value.toLowerCase();

    if (!!userProgressId) {
      push(`/progress/${clubCode}/user/${userProgressId}/view/${tabKey}`);
    } else {
      push(`/progress/${clubCode}/view/${tabKey}`);
    }

    if (tabKey !== tabs.answers.toLowerCase()) {
      setEditedAnswer(null);
    }
    setCurrentTab(value);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <NavBar />
        <main className={classes.main}>
        <Box className={classes.contentBox}>
          {currentTab === 'Activities' && (!userProgressId ? (
            <Box className={classes.contentBox}>
              <Box className={classes.header}>
                <PageTitle title="Progress" isLoading={isLoading || isLoadingAdditionalProgress} />
                {!!usersGroupsList.length && !!userLogs.length && <FilterBlock
                  classes={classes}
                  setSelectedGroupUsers={setSelectedGroupUsers}
                  usersGroupsList={usersGroupsList}
                  selectedGroupUsers={selectedGroupUsers}
                />}
              </Box>
              <div className={classes.tabsSection}>
                <Tabs
                  value={currentTab}
                  showProjectTab={projectsOfUsersInProjectCurrently.length}
                  showCertificateTab={!!certificateId}
                  showAnswers={showAnswers && !studentView}
                  onChange={(evt, newValue) => {
                    setActiveTab(newValue);
                  }}
                />
              </div>
              <ProgressTable
                headCells={config.columns}
                progress={isLoading ? null : userLogs}
                activities={nodes}
                isLoading={isLoading || isLoadingAdditionalProgress}
                labels={labels}
                clubCode={clubCode}
                pushToRoute={openUserProgress}
                ratings={programRatings}
                createTrackingLog={createTrackingLog}
                programId={currentRole && currentRole.programId}
                setCurrentTab={setActiveTab}
                currentTab={currentTab}
                selectedGroupUsers={selectedGroupUsers}
                setSelectedGroupUsers={setSelectedGroupUsers}
                usersGroupsList={usersGroupsList}
                originClasses={classes}
                usersGroupFilter={usersGroupFilter}
                membersCount={membersCount && membersCount[currentRole?.program.id]}
                defaultPageSize={DEFAULT_PAGE_SIZE}
                getAdditionalUserActivitesByProgramCode={getAdditionalUserActivitesByProgramCode}
              />
            </Box>
          ) : (
            <UserProgress
              user={user}
              clubCode={clubCode}
              userId={userProgressId}
              userProgressByProgram={userProgressByProgram}
              getUserActivitiesByProgram={getUserActivitiesByProgram}
              deleteMyActivityProgress={deleteMyActivityProgress}
              getUserProjectsByProgram={getUserProjectsByProgram}
              getIsUserProjectsByProgram={getIsUserProjectsByProgram}
              programRatings={programRatings}
              getRatingsByProgram={getRatingsByProgram}
              createTrackingLog={createTrackingLog}
              curriculums={studentCurriculums}
              navigateBack={openUserProgress}
              isLoading={isLoadingUserActivitiesInfo}
              nextUserProgressId={nextUserProgressId}
              galleryProjects={galleryProjects}
              isUserProjects={isUserProjects}
              push={push}
              setCurrentTab={setActiveTab}
              usersProjectsByProgram={projectsOfUsersInProjectCurrently}
              currentTab={currentTab}
              isLoadingProjects={isLoadingProjects}
              userAnswers={openTextAnswers && openTextAnswers.userAnswers}
              certificateRibbonIcon={certificateRibbonIcon}
              programId={currentRole && currentRole.programId}
              showAnswers={showUserAnswers && !studentView}
              userFullName={userFullName}
            />
          ))}
          {currentTab === 'Answers' && (!userProgressId ?
            <Answers
              user={user}
              isLoadingProjects={isLoadingProjects}
              program={currentRole && currentRole.program}
              setCurrentTab={setActiveTab}
              currentTab={currentTab}
              showCertificateTab={!!certificateId}
              showProjectTab={projectsOfUsersInProjectCurrently.length}
              openTextAnswers={openTextAnswers}
              curriculum={curriculum}
              getCommentsByUser={getCommentsByUser}
              addComment={addComment}
              updateStatus={updateStatus}
              updateAnswerGroupsStatus={updateAnswerGroupsStatus}
              deleteComment={deleteComment}
              selectedGroupUsers={selectedGroupUsers}
              selectedUserId={editedAnswerUserId}
              setSelectedGroupUsers={setSelectedGroupUsers}
              usersGroupsList={usersGroupsList}
              originClasses={classes}
              usersGroupFilter={usersGroupFilter}
              answerGroups={answerGroups}
              addAnswerGroupComment={addAnswerGroupComment}
              updateComment={updateComment}
              updateAnswerGroupComment={updateAnswerGroupComment}
              deleteAnswerGroupComment={deleteAnswerGroupComment}
              history={history}
              editedAnswer={editedAnswer}
              setEditedAnswer={setEditedAnswer}
              push={push}
            /> :
              <UserAnswers
                user={user}
                curriculums={studentCurriculums}
                currentProgram={currentRole && currentRole.program}
                setCurrentTab={setActiveTab}
                currentTab={currentTab}
                openTextAnswers={openTextAnswers}
                getCommentsByAnswer={getCommentsByAnswer}
                getCommentsForAnswerByUser={getCommentsForAnswerByUser}
                usersProjectsByProgram={projectsOfUsersInProjectCurrently}
                userId={userProgressId}
                navigateBack={openUserProgress}
                nextUserProgressId={nextUserProgressId}
                isUserProjects={isUserProjects}
                isLoading={isLoadingUserActivitiesInfo}
                answerGroups={answerGroups}
                isFacilitator
                push={push}
                editedAnswer={editedAnswer}
                userFullName={userFullName}
                programTypes={programTypes}
              />)
          }
          {currentTab === 'Projects' && !userProgressId &&
            <AllProgramProjects
              isLoadingProjects={isLoadingProjects}
              galleryProjects={galleryProjects}
              setCurrentTab={setActiveTab}
              usersProjectsByProgram={usersProjectsByProgram}
              currentTab={currentTab}
              showCertificateTab={!!certificateId}
              showAnswers={showAnswers && !studentView}
              selectedGroupUsers={selectedGroupUsers}
              setSelectedGroupUsers={setSelectedGroupUsers}
              usersGroupsList={usersGroupsList}
              originClasses={classes}
              projectsToRender={projectsToRender}
              projectIcon={projectIcon}
            />}
          {currentTab === 'Projects' && userProgressId &&
            <UserProjects
              isLoadingProjects={isLoadingProjects}
              user={user}
              galleryProjects={galleryProjects}
              programId={currentRole && currentRole.programId}
              userId={userProgressId}
              navigateBack={openUserProgress}
              getUserProjectsByProgram={getUserProjectsByProgram}
              isUserProjects={usersProjectsByProgram}
              push={push}
              nextUserProgressId={nextUserProgressId}
              setCurrentTab={setActiveTab}
              currentTab={currentTab}
              showCertificateTab={!!certificateId}
              showAnswers={showUserAnswers && !studentView}
              userAnswers={openTextAnswers && openTextAnswers.userAnswers}
              projectIcon={projectIcon}
              userFullName={userFullName}
            />}
          {currentTab === "Certificates" && (
            <CertificatesTable
              setCurrentTab={setActiveTab}
              currentTab={currentTab}
              loadCertificateGenerationStatus={loadCertificateGenerationStatus}
              loadUserCertificatesByProgram={loadUserCertificatesByProgram}
              usersProjectsByProgram={projectsOfUsersInProjectCurrently}
              startCertificatesGeneration={startCertificatesGeneration}
              programMemberCertificates={programMemberCertificates}
              programId={currentRole && currentRole.programId}
              labels={labels}
              push={push}
              showAnswers={showAnswers && !studentView}
              selectedGroupUsers={selectedGroupUsers}
              setSelectedGroupUsers={setSelectedGroupUsers}
              usersGroupsList={usersGroupsList}
              originClasses={classes}
              usersGroupFilter={usersGroupFilter}
            />
          )}
        </Box>
      </main>
      <Footer />
    </div>
  );
};

export default connectTo(
  (state) => {
    return {
      curriculum: state.contentful.curriculums,
      auth: state.auth,
      contentful: state.contentful,
      userActivitiesInfo: state.userActivitiesInfo,
      rating: state.rating,
      isUserProjects: state.projectGallery.isUserProjectsByProgram,
      usersProjectsByProgram: state.projectGallery.usersProjectsByProgram,
      galleryProjects: state.projectGallery.projectsByProgram,
      allGalleryProjects: state.projectGallery.allProjectsByProgram,
      isLoadingProjects: state.projectGallery.isLoadingProjects,
      programMemberCertificates: state.certificates.programMemberCertificates,
      openTextAnswers: state.openTextAnswers,
      members: state.members,
      studentView: state.global.studentView,
      answerGroups: state.answerGroups,
    };
  },
  {
    ...authActions,
    ...contentfulActions,
    ...userActivitiesInfoActions,
    ...ratingsActions,
    ...trackingActions,
    ...certificatesActions,
    ...openTextActions,
    ...answerGroupActions,
    ...membersActions,
    push: pushToRoute,
    getUserActivitiesByProgram:
      userActivitiesInfoActions.getUserActivitiesByProgram,
    deleteMyActivityProgress:
      userActivitiesInfoActions.deleteMyActivityProgress,
    getUserProjectsByProgram: getUserProjectsByProgramAction,
    getProjectsByProgram: getProjectsByProgramAction,
    getIsUserProjectsByProgram: getIsUserProjectsByProgramAction,
    getUsersProjectsByProgram: getUsersProjectsByProgramAction,
  },
  withPageLabels({ slug: "/facilitators-progress" }, Progress)
);
