import React, {useEffect} from "react";

import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { Modal } from "../modal";
import * as colors from "../../../theme/colors";
import { EventSchedulerContent } from "./eventContent";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: 20,
  },
  marginTop30: {
    marginTop: 30,
  },
  subContent: {
    marginTop: 30,
    marginBottom: 10,
  },
  sectionItem: {
    marginRight: 10,
  },
  title: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    fontSize: 22,
    fontWeight: "bold",
    margin: "25px auto 15px",
    maxWidth: 330,
  },
  titleBox: {
    background: colors.lightGradient,
    display: 'flex',
  },
  buttonGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none",
  },
  button: {
    borderRadius: 5,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: colors.grey400,
    color: "white",
    "&:hover": {
      backgroundColor: colors.grey500,
    },
  },
  boxSection: {
    display: "inline-flex",
    width: "100%",
  },
  submitButton: {
    borderRadius: 5,
    backgroundColor: colors.tealA700,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: colors.teal500,
    },
  },
  boxWrapper: {
    display: "flex",
    alignItems: "center",
  },
  boxIcon: {
    marginRight: 10,
  },
  closeBtn: {
    height: 40,
    marginTop: 15,
    width: 40,
  },
}));

export const EventSchedulerModal = ({
  programs,
  isOpen,
  createEvent,
  timezone,
  onCloseModal,
  loadProgramMembers,
  programMembers,
  isLoadingProgramMembers,
  setCloseCreateEventModal,
  isClosedCreateEventModal,
  setEscapeConfirmDialog,
  countryId
}) => {
  const classes = useStyles();

  const [isInProgress, setIsInProgress] = React.useState(false);

  useEffect(() => {
    if (isOpen === false) {
      setIsInProgress(false);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal}>
      <Box className={classes.titleBox}>
        <Typography variant="h4" className={classes.title}>
          Create Event
        </Typography>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          className={classes.closeBtn}
          onClick={onCloseModal}
          disabled={isInProgress}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <DialogContent className={classes.dialogContent}>
        <EventSchedulerContent
          programs={programs}
          timezone={timezone}
          onCloseModal={onCloseModal}
          createEvent={(values) => {
            setIsInProgress(true);
            createEvent(values);
          }}
          loadProgramMembers={loadProgramMembers}
          programMembers={programMembers}
          isLoadingProgramMembers={isLoadingProgramMembers}
          setCloseCreateEventModal={setCloseCreateEventModal}
          isClosedCreateEventModal={isClosedCreateEventModal}
          setEscapeConfirmDialog={setEscapeConfirmDialog}
          countryId={countryId}
          isInProgress={isInProgress}
        />
      </DialogContent>
    </Modal>
  );
};
