import React, { useState } from 'react';
import debounce from "debounce";

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { getTheme } from "../../../utils/colorTheme";
import * as colors from '../../../theme/colors';

import TextCustomButton from "../../../components/text-button/text-button";
import { formatEstimatedMinutes } from "../../../utils/generic";
import { TooltipWrapper } from "../../../components/tooltip/tooltip";

import CardNode from "./index";
import { Filters, EmptyFilterSection } from "../Filters";

import { applyFilters, getFiltersData } from "../curriculumHelper";

const displayItemsCount = 3;

const styles = makeStyles({
  root: {
    display: 'grid',
  },
  grid: {
    marginTop: 15,
  },
  headerBox: {
    width: '100%',
    padding: '12px 12px 0px 12px',
    margin: '15px 0px -20px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  certificateWrapper: {
    paddingLeft: 10,
    marginTop: 5,
  },
  certificateIcon: {
    height: 30,
  },
  viewMoreBtn: {
    height: 42,
    color: colors.darkThemeGreen,
  },
  labelText: {
    fontSize: 24,
    fontWeight: 'bold',
    color: colors.darkThemeBlueGray,
    lineHeight: '32px',
    verticalAlign: 'middle',
    textTransform: 'uppercase',
  },
  filters: {
    margin: 10,
  }
});

const searchProperties = ['shortDescription', 'name', 'tags'];

const Folder = (props) => {
  const {
    item,
    defaultActivityIcon,
    onNodeClick,
    onRedoClick,
    onResumeClick,
    onStartClick,
    userActivitiesInfo,
    isFacilitator,
    communityPartnershipLink,
    labelBackground,
    labelFontColor,
    programRoleLabel,
    clearProgress,
    classes,
    isAllNodesFacilitatorsOnly,
    curriculumLabels,
    studentView,
    certificateRibbonIcon,
    openTextAnswers,
    programId,
  } = props;

  const nodeTheme = null;
  const tooltipMsg = 'Earn a certificate by completing required activities';

  const defaultFilter = {
    setType: [], estimatedTimeInMinutes: [], easeOfFacilitation: [], searchKey: "",
  };

  const [filters, setFilters] = useState(defaultFilter);
  const [items, setItems] = useState(defaultFilter);
  const [currentNodes, setCurrentNodes] = useState([]);
  const [anyFilterSelected, setAnyFilterSelected] = useState(false);

  const cardTheme = getTheme(nodeTheme);
  const cardClasses = styles({ backColor: cardTheme.b, fontColor: cardTheme.f, accentColor: cardTheme.a });

  React.useEffect(() => {
    const keys = Object.getOwnPropertyNames(filters);
    const isAnyFilterSelected = !!keys.filter(key => !!filters[key].length).length;

    const itemNodes = Object.values(item.nodes ? item.nodes : []);
    const childNodes = itemNodes.map(e => {
      return Object.values(e.nodes ? e.nodes : []);
    });

    let mergedNodes = [].concat.apply([], childNodes);
    const filteredChildNodes = applyFilters(mergedNodes, filters, searchProperties);

    const newNodes = itemNodes.map(node => {
      let subNodes = Array.isArray(node.nodes) ? node.nodes : Object.values(node ? node.nodes : []);

      let filteredSubNodes = subNodes.filter(e => !!filteredChildNodes.find(x => x.id === e.id));
      return !filteredSubNodes.length ? null : { ...node, nodes: filteredSubNodes };
    })
      .filter(e => !!e);

    setCurrentNodes(newNodes);

    if (anyFilterSelected !== isAnyFilterSelected) {
      setAnyFilterSelected(isAnyFilterSelected);
    }

    const filtersData = getFiltersData(filteredChildNodes, searchProperties, isFacilitator, formatEstimatedMinutes, studentView);

    let newFilters = filtersData;
    if (isAnyFilterSelected) {
      keys.forEach(key => {
        if (typeof items[key] !== 'object') {
          newFilters[key] = items[key];
          return;
        }
        newFilters[key] = items[key].map(item => {
          let disabled = !filtersData[key].find(e => e.label === item.label);
          return { ...item, disabled };
        })
      });
    }

    setItems({
      ...defaultFilter,
      ...newFilters,
    });
  }, [filters, item]);

  const searchInputChangeDebounced = debounce((searchKey) => {
    searchKey = !!searchKey.trim ? searchKey.trim() : "";
    setFilters({ ...filters, searchKey });
  }, 1000);

  const clearAllFilters = () => {
    setFilters(defaultFilter);
  };

  const handleFilterChange = (name, value, resetFilters) => () => {
    const newFilters = { ...filters };
    const isItemExist = newFilters[name].includes(value);

    if (isItemExist) {
      newFilters[name] = newFilters[name].filter((e) => e != value);
    } else {
      newFilters[name].push(value);
    }

    if (resetFilters && !newFilters[name].length) {
      resetFilters.map((e) => {
        newFilters[e] = defaultFilter[e];
      });
    }

    setFilters(newFilters);
  };

  const isAnyItemsToRender = !currentNodes.length;


  return (
    <Box className={cardClasses.root}>
      <Filters
        className={cardClasses.filters}
        items={items}
        filters={filters}
        handleFilterChange={handleFilterChange}
        onSearchInput={searchInputChangeDebounced}
        onReset={() => searchInputChangeDebounced("")}
        studentView={studentView}
        clearAllFilters={clearAllFilters}
      />
      {anyFilterSelected && isAnyItemsToRender && <EmptyFilterSection curriculumLabels={curriculumLabels} />}
      {!!item && !!item.content.length && !!currentNodes && currentNodes.map((node) => {
        const showMoreBtn = node.nodes.length > displayItemsCount;
        const activityNodes = anyFilterSelected ? node.nodes : [...node.nodes].splice(0, displayItemsCount);
        const isAllCurrentNodesFacilitatorsOnly = !!activityNodes.length
          && activityNodes.length === activityNodes.filter(e => e.permissionLevel === 'Facilitators/Teachers only').length;

        return (
          <Grid container spacing={3} key={`card-folder-${node.id}`}>
            {!!activityNodes.length && <Box className={cardClasses.headerBox}>
              <Box className={cardClasses.headerContainer}>
                <Typography className={cardClasses.labelText}>{node.name}</Typography>
                {certificateRibbonIcon && node.endsInCertificate && <TooltipWrapper title={tooltipMsg}>
                  <Box className={cardClasses.certificateWrapper}>
                    <img className={cardClasses.certificateIcon} src={certificateRibbonIcon.src} alt={certificateRibbonIcon.alt} />
                  </Box>
                </TooltipWrapper>}
              </Box>
              {showMoreBtn && !anyFilterSelected && <TextCustomButton
                style={{ marginRight: 10 }}
                className={cardClasses.viewMoreBtn}
                mode="secondary"
                onClick={() => {
                  const isFacilitatorOnlyActivity = node.permissionLevel === "Facilitators/Teachers only";
                  onNodeClick(node, isFacilitatorOnlyActivity ? true : false);
                }}
                label={"VIEW MORE"}
              />}
            </Box>}

            {activityNodes.map((childNode) => {
              return (
                <CardNode
                  classes={classes}
                  clearProgress={clearProgress}
                  isFacilitator={isFacilitator}
                  item={node}
                  node={childNode}
                  onNodeClick={onNodeClick}
                  onRedoClick={onRedoClick}
                  onResumeClick={onResumeClick}
                  onStartClick={onStartClick}
                  key={`card-${childNode.id}`}
                  defaultActivityIcon={defaultActivityIcon}
                  userActivitiesInfo={userActivitiesInfo}
                  communityPartnershipLink={communityPartnershipLink}
                  labelBackground={labelBackground}
                  labelFontColor={labelFontColor}
                  programRoleLabel={programRoleLabel}
                  isAllNodesFacilitatorsOnly={isAllNodesFacilitatorsOnly || isAllCurrentNodesFacilitatorsOnly}
                  openTextAnswers={openTextAnswers}
                  programId={programId}
                />
              );
            })}
          </Grid>);
      })}
    </Box>
  );
};

export default Folder;