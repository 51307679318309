import React, { useEffect, useState } from 'react'

import * as moment from "moment";
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import clsx from 'clsx';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import CalendarIcon from '@material-ui/icons/CalendarTodayOutlined';
import { DATE_FORMAT } from '../../utils/constants';
import { getCountryFormat } from '../../utils/generic';
import CustomToolbar from './CustomDateToolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  icon: {
    margin: 0,
    padding: 0,
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
  },
  datePickerButton: {
    color: theme.palette.primary.dark,
    padding: 0,
  },
}));

const DateInput = ({ field: { name, onBlur, value }, onValueChange, form, label, domesticId, countryId, maxDate, minDate, required, ariaLabel, disabled, displayDateFormat, standardDateFormat, dateFormat, ...props }) => {
  const classes = useStyles();
  const [selectedDate, handleDateChange] = useState(value || null);

  let countryFormat = getCountryFormat(countryId, domesticId);

  useEffect(() => {
    if (!!dateFormat) {
      value = moment(value).isValid() ? moment(value).format(dateFormat) : '';
    }
    handleDateChange(value);
  }, []);

  const formatDate = (date) => {
    return moment.parseZone(date).format(!standardDateFormat && DATE_FORMAT);
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        name={name}
        id={name}
        autoOk
        error={!!form.errors[name] && form.touched[name]}
        helperText={!!form.errors[name] && form.touched[name] ? form.errors[name] : null}
        placeholder={displayDateFormat ? countryFormat.toUpperCase() : label}
        className={clsx(classes.root, props.className)}
        variant="inline"
        inputVariant="outlined"
        label={label}
        format={countryFormat}
        value={selectedDate}
        required={required}
        maxDate={maxDate}
        minDate={minDate}
        sel="date"
        role={props.elementRole || "region"}
        aria-label={label}
        ToolbarComponent={(props) => <CustomToolbar {...props} />}
        KeyboardButtonProps={{
          id: `${name}-control`,
          className: classes.datePickerButton,
          "aria-label": ariaLabel || "Date picker",
        }}
        keyboardIcon={<CalendarIcon />}
        InputAdornmentProps={{ position: "start",  classes: { expandIcon: classes.icon } }}
        onChange={(date) => {
          const val = moment(date).isValid() ? formatDate(date) : '';
          if (onValueChange) {
            onValueChange(val, form.setFieldValue, form.values, form.touched, form.setFieldTouched);
          }

          form.setFieldValue(name, val);
          handleDateChange(date);
        }}
        onAccept={(date) => {
          form.setFieldValue(name, formatDate(date));
        }}
        onBlur={onBlur}
        disabled={disabled}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateInput;
