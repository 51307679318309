import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from 'clsx';

import * as colors from "../../theme/colors";
import { handleKeyPress } from '../../utils/accessibility';

const useStyles = makeStyles(() => ({
  wrapper: {
    overflowWrap: "break-word",
  },
  copyBtn: {
    fontSize: 12,
    color: colors.darkThemeGreen,
    cursor: "pointer",
    whiteSpace: "nowrap",
  },
  link: {
    marginRight: "5px",
  },
}));

const CopyLinkButton = ({ link, textClass }, ...props) => {
  const [isCopied, setIsCopied] = React.useState();
  const classes = useStyles();

  const handleClick = (e) => {
    navigator.clipboard.writeText(link);
    e.target.focus();
    setIsCopied(true);
  };

  return (
    <span {...props} className={classes.wrapper}>
      <span className={clsx(classes.link, textClass)}>{link}</span>
      <span role="button" aria-live="polite" onClick={handleClick} onKeyDown={handleKeyPress(handleClick)} tabIndex={0} className={classes.copyBtn}>{isCopied ? "copied" : "copy"}</span>
    </span>
  );
};

export default CopyLinkButton;
