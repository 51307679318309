import React, { useState, useEffect } from "react";

import SignatureCanvas from "react-signature-canvas";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import { Typography, Box } from "@material-ui/core";

import * as colors from "../../theme/colors";

const useStyles = makeStyles({
  clearBtn: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  signSeparator: {
    borderTop: `1px dashed ${colors.lightGrey}`,
    width: "95%",
    margin: "auto",
    textAlign: "center",
    "& > p": {
      color: colors.lightGrey,
    },
  },
  fieldSet: {
    maxWidth: '100%'
  }
});

const Signature = ({ field, form }) => {
  const classes = useStyles();

  const [sigPad, setSigPad] = useState({});
  const [base64Signature, setBase64Signature] = useState("");

  useEffect(() => {
    if (field.value && field.value === "") {
      form.setFieldValue(field.name, "");
    }
  }, []);

  useEffect(() => {
    if (field.value && field.value !== "") {
      setBase64Signature(field.value);
    }
  }, [field.value]);

  useEffect(() => {
    if (base64Signature && sigPad) {
      loadImage(base64Signature);
    }
  }, [base64Signature, sigPad]);

  const loadImage = (image) => {
    const img = new Image();
    img.src = image;

    img.onload = () => {
      sigPad.clear();
      const canvas = sigPad.getCanvas();
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
    };
  }

  const clear = () => {
    sigPad.clear();
    form.setFieldValue(field.name, "");
    setBase64Signature("");
  };

  return (
    <FormControl component="fieldset" className={classes.fieldSet}>
      <React.Fragment>
        <SignatureCanvas
          ref={(ref) => {
            setSigPad(ref);
          }}
          onEnd={() => {
            form.setFieldValue(field.name, sigPad.toDataURL("image/png"));
          }}
          velocityFilterWeight={1}
          penColor="black"
          throttle={24}
          canvasProps={{ width: 400, height: 100, className: "sigCanvas" }}
        />
        <button
          onClick={(e) => {
            clear();
            e.stopPropagation();
            e.preventDefault();
          }}
          className={classes.clearBtn}
        >
          Clear
        </button>
        <Box className={classes.signSeparator}>
          <Typography>Sign name using mouse or touch pad</Typography>
        </Box>
      </React.Fragment>
      {/* {field.value
                ? <img
                    src={field.value} />
                : null} */}
    </FormControl>
  );
};

export default Signature;
