import React from "react";

import PersonalInfo from "../personal-info/personal-info";
import ProfileCard from "../../../components/profile-card/profile-card";
import ParentsInfo from "../parents-info/parents-info";
import PublicProfile from "../publicProfile/publicProfile";
import UserAddress from "../userAddress/userAddressProfile";
import NotificationSettings from "../notification-settings/notificationSettings";

import Typography from "@material-ui/core/Typography";

export const UserProfileContent = (props) => {
  const {
    user,
    parentSectionAllow,
    labels,
    countries,
    updateUserAvatarImage,
    updateUserBio,
    updateSmsConsent,
    isFacilitator,
    isCommunityPartner,
    isViewer,
    onAvatarChange
  } = props;

  const renderAddressSection =
    user &&
    (!!user.countryId ||
      !!user.zipcode ||
      !!user.state ||
      !!user.streetAddress ||
      !!user.city);

  const renderNotificationSection = !!user && !!user.sfUserId && user.sfUserId.length > 0 && (isFacilitator || isCommunityPartner || isViewer);
  const renderSecurity = !!user && (!!user.username || !!user.email);

  return (
    <React.Fragment>
      {user && (
        <PublicProfile
          user={user}
          updateUserAvatarImage={updateUserAvatarImage}
          updateUserBio={updateUserBio}
          labels={labels}
          onAvatarChange={onAvatarChange} 
        />
      )}
      {user && (
        <PersonalInfo
          user={user}
          countries={countries}
          openEditForm={() => props.onUserProfileEdit()}
          labels={labels}
        />
      )}
      {renderAddressSection && (
        <UserAddress
          user={user}
          countries={countries}
          openEditForm={() => props.onUserAddressEdit()}
          labels={labels}
        />
      )}
      {parentSectionAllow && (
        <ParentsInfo
          parents={user.parents}
          labels={labels}
        />
      )}
      {renderNotificationSection && (
        <NotificationSettings
          user={user}
          updateSmsConsent={updateSmsConsent}
          labels={labels}
        />
      )}
      {renderSecurity && (
        <ProfileCard
          title="Security"
          icon={labels.securityIcon}
          isEditable={true}
          openEditForm={() => props.onChangePassword()}
          content={
            <React.Fragment>
              <Typography>Password</Typography>
              <Typography>*******************</Typography>
            </React.Fragment>
          }
        />
      )}
    </React.Fragment>
  );
};

export default UserProfileContent;
