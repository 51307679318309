import { createReducer } from 'redux-act'

import * as a from '../actions/forgotPassword'

const getDefaultState = () => ({
  email: '',
  error: false,
  loading: false,
  showResultScreen: false,
  disableResend: false,
})

export default () =>
  createReducer(
    {
      [a.goBack]: (state) => ({
        ...state,
        loading: false,
        showResultScreen: false,
        error: false,
        email: '',
      }),
      [a.sendRequest]: (state, payload) => {
        const disableResend = payload.resend ? payload.resend : false;

        return {
          ...state,
          email: payload.email,
          error: false,
          loading: true,
          disableResend,
        };
      },
      [a.requestSent]: (state) => ({
        ...state,
        loading: false,
        showResultScreen: true,
        error: false,
      }),
    },
    getDefaultState()
  )
