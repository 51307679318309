import React, { useState, useEffect } from "react";

import {
  Box,
  Card,
  Grid,
} from "@material-ui/core/";

import { makeStyles } from "@material-ui/core/styles";
import TextCustomButton from "../../../components/text-button/text-button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SimpleQuestion from "./question";
import { GroupAnswers } from './groupAnswer';
import { AnswerCardHeader } from './header';

const useStyles = makeStyles((theme) => ({
  contentBox: {
    width: "95%",
    margin: "auto",
  },
  backBtnBox: {
    display: 'flex',
    justifyContent: 'end',
  },
  cardContent: {
    padding: "16px 0 0 !important",
    display: "flex",
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: "block",
    },
  },
  backBtn: {
    display: "flex",
    alignItems: "center",
    marginLeft: 25,
  },
  card: {
    borderBottom: "6px solid $grey-dark",
    boxSizing: "border-box",
    margin: "12px 12px 12px 0",
    padding: 24,
    height: "fit-content",
    width: "100%",
  },
  cardContent: {
    padding: "16px 0 0 !important",
    display: "flex",
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: "block",
    },
  },
}));

const ViewAnswer = ({
  currentQuestion,
  items,
  onBackClick,
  createAnswer,
  isLoadingComments,
  isFinishedProject,
  push,
  disableResubmit,
  isLoadingAnswer,
  history,
  showBackBtn,
  redirectUrl,
}) => {
  const { currentNode, answerGroup } = currentQuestion;

  const classes = useStyles();
  const [isReSubmit, setIsReSubmit] = useState(false);
  const [currentReSubmitAnswer, setCurrentReSubmitAnswer] = useState({});
  const [openCompleted, setOpenCompleted] = React.useState(0);
  const [currentGroupQuestionDescription, setCurrentGroupQuestionDescription] = React.useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentNode]);

  const isCompleted = items && !!items.length && items[0].status;

  const backToActivityView = () => {
    push(redirectUrl);
  }

  return (
    <Grid container>
      <Box className={classes.contentBox}>
        <Box className={classes.backBtnBox}>
          {currentNode && Array.isArray(currentNode) ?
            (<AnswerCardHeader
              heading={answerGroup?.name}
              items={answerGroup?.openTextAnswers}
              requiredGrading={currentNode[0]?.contentSection?.contentSectionGroup?.requiredGrading}
              answerGroup={answerGroup}
              onBackClick={onBackClick}
            />) : (
              <TextCustomButton
                label={<Box className={classes.backBtn}>
                  <ArrowBackIcon />
                  <Box>Back to all answers</Box>
                </Box>}
                onClick={() => onBackClick()}
              />
            )
          }

        </Box>
        <Box>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card
              className={classes.card}
              tabIndex="0"
            >
              {currentNode && Array.isArray(currentNode) ?
                (<GroupAnswers
                  currentNode={currentNode}
                  isReSubmit={isReSubmit}
                  isLoadingComments={isLoadingComments}
                  items={answerGroup?.openTextAnswers}
                  answerGroup={answerGroup}
                  createAnswer={createAnswer}
                  setIsReSubmit={setIsReSubmit}
                  isCompleted={isCompleted}
                  setOpenCompleted={setOpenCompleted}
                  openCompleted={openCompleted}
                  setCurrentReSubmitAnswer={setCurrentReSubmitAnswer}
                  currentReSubmitAnswer={currentReSubmitAnswer}
                  currentGroupQuestionDescription={currentGroupQuestionDescription}
                  setCurrentGroupQuestionDescription={setCurrentGroupQuestionDescription}
                  disableResubmit={disableResubmit || isFinishedProject}
                  isLoadingAnswer={isLoadingAnswer}
                  history={history}
                />) :
                (<SimpleQuestion
                  currentNode={currentNode}
                  isReSubmit={isReSubmit}
                  isLoadingComments={isLoadingComments}
                  items={items}
                  createAnswer={createAnswer}
                  setIsReSubmit={setIsReSubmit}
                  isCompleted={isCompleted}
                  history={history}
                  disableResubmit={disableResubmit || isFinishedProject}
                  onBack={backToActivityView}
                  showBackBtn={showBackBtn}
                />)}
            </Card>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};

export default ViewAnswer;