import React from "react";
import {
  Box,
  Typography,
  CardContent,
  Card,
  CardMedia,
  Chip,
  Grid
} from "@material-ui/core/";
import clsx from 'clsx';

import { Link } from "react-router-dom";

export const checkProjectStatus = (project) => {
    switch (true) {
      case project.isPublished:
        return 'PUBLISHED';
      case project.isArchived:
        return 'ARCHIVED';
      default:
        return 'PENDING';
    }
  };

export const UserProjectCard = (props) => {
    const { project, classes, currentTab } = props;
    const coverImage = project.primaryImage || "";
    const isPublished = project.isPublished;
  
    return (
      <Grid key={project.id} xs={12} sm={6} md={4} lg={3} xl={3}>
        <Link
          to={{
            pathname: `/project-gallery/${project.id}`,
            prevUrl: window.location.search,
            state: {
              prevPath: window.location.pathname,
              label: "project gallery",
              activeTab: currentTab,
            },
          }}
          className={classes.projectLink}
        >
          <Card
            title={project.name}
            className={classes.galleryCard}
            tabIndex="0"
          >
            <CardMedia
              image={coverImage}
              className={isPublished ? classes.cardСover : clsx(classes.cardСover, classes.unPublishedCard)}
            />
            <CardContent className={classes.cardContent}>
              <Typography
                align="left"
                component="h3"
                className={isPublished ? clsx(classes.cardTitle, classes.publishedCardTitle) : clsx(classes.cardTitle, classes.notPublishedCardTitle)}
              >
                {project.name}
              </Typography>
              <Box className={classes.tag}>
                <Chip 
                  size="small" 
                  label={checkProjectStatus(project)}
                  className={isPublished ? clsx(classes.chip, classes.publishedChip) : clsx(classes.chip, classes.notPublishedChip)}
                />
              </Box>
            </CardContent>
          </Card>
        </Link>
      </Grid>
    );
  };
export default UserProjectCard;
