import React from "react";

import * as moment from "moment";

import {checkIsClubsDomain, createMarkup} from "../../../utils/generic";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";

import TextInput from "../../../components/TextInput";
import { Typography } from "@material-ui/core";

import { programValidationMsgs, validateProgram } from "../../../utils/programHelper";
import { enrollmentTrackingEvent } from "../constants";
import CustomButton from "../../../components/customButton/customButton";
import * as colors from "../../../theme/colors";
import { getProgramByCode } from '../../../api/programs';
import FocusError from "../../LoginSignUp/Register/FocusError";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
    "&:focus": {
      outline: `2px dotted ${colors.grey400}`,
    },
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  container: {
    width: "100%",
    padding: '0 24px',
  },
  linksContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 32,
  },
  clubCodeBox: {
    maxWidth: 400,
    margin: "0 auto",
    '& .MuiOutlinedInput-input': {
      fontSize: 14,
    }
  },
  additionalMessageBox: {
    margin: "20px 0",
  },
  additionalMessage: {
    fontSize: 14,
    lineHeight: 1.8,
    margin: "0 auto",
    maxWidth: "400px",
    textAlign: "left",
    color: colors.darkThemeBlueGray,
    fontWeight: 400,
  },
  password: {
    margin: "30px auto 0 auto",
  },
  submitContainer: {
    marginTop: 25,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  submitButton: {
    width: 150,
    cursor: "pointer",
  },
  navLinkBox: {
    marginTop: 10,
    textAlign: "center",
  },
  navLink: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  submitBtn: {
    width: 150,
    height: 42,
    "&:focus": {
      outline: `2px auto ${colors.grey400}`,
    },
    fontSize: 16,
    color: colors.darkBlueGrey,
  },
  firstStepAdditionalInstruction: {
    color: colors.grey600,
  },
  footerMessage: {
    margin: "20px 24px",
    fontSize: 12,
    fontWeight: 400,
    color: colors.lightGrey,
  },
}));

const validationSchema = Yup.object({
  programCode: Yup.string().required("Required"),
});

let values = null;

const FirstStep = ({
  user,
  child,
  programCode,
  locale,
  onSubmit,
  createTrackingLog,
  pageLabels,
  setDataChanged,
  renderChildHeader,
  childID,
  hqDefaultProgram,
  allProgramTypes,
  userAlreadyInProgram,
  setUserAlreadyInProgram,
  programInactive,
  setProgramInactive,
}) => {

  React.useEffect(() => {
    return () => {
      if (!window.location.pathname.includes("join")) {
        createTrackingLog({
          event: enrollmentTrackingEvent.ENROLLMENT_FIRST_STEP_LEAVE,
          data: JSON.stringify(values),
        });
      }
    };
  }, []);

  const classes = useStyles();
  const selfMinAge = locale ? locale.selfSignUpMinAge : null;
  const userAge = user ? moment().diff(user.birthDate, "years") : null;
  const isClubsDomain = checkIsClubsDomain();

  let programIds = [];
  if (child) {
    programIds = child.roles
      ? child.roles.map(
          (e) => e.programId || e.cpOrganizationId
        )
      : null;
  } else {
    programIds =
      user && user.roles
        ? user.roles.map(
            (e) => e.programId || e.cpOrganizationId
          )
        : null;
  }

  const validateCode = (value) => {
    if (!value) {
      return;
    }

    if (userAlreadyInProgram) {
      return programValidationMsgs.alreadyJoined;
    }

    if (programInactive) {
      return programValidationMsgs.inActive;
    }

    const result = getProgramByCode({ programCode: value }).then((e) => {
      const { data: program } = e;

      const errorMsg = validateProgram(selfMinAge, userAge, program, hqDefaultProgram, true, child !== null, allProgramTypes);
      if (errorMsg) {
        return errorMsg;
      }

      if (program && program.id && programIds) {
        const programExist = programIds.find(
          (e) => e === parseInt(program.id, 10)
        );
        if (programExist) {
          return programValidationMsgs.alreadyJoined;
        }

        if (
          program.countryId &&
          parseInt(program.countryId, 10) !== user.countryId
        ) {
          return programValidationMsgs.countryNotValid;
        }
      }

      return program ? null : programValidationMsgs.doesntExist;
    });
    return result;
  };

  return (
    <Formik
      enableReinitialize={true}
      innerRef={(input) => {
        if (input) {
          values = input.values;
        }
      }}
      validateOnChange={true}
      validateOnMount={!!userAlreadyInProgram || programInactive}
      validate={(data) => {
        if (data.programCode !== programCode) {
          setUserAlreadyInProgram(false);
          setProgramInactive(false);
          return;
        }
        if ((userAlreadyInProgram || programInactive) && programCode) {
          validateCode(programCode);
        }
        setDataChanged(data.programCode.length > 0);
        return;
      }}
      initialValues={{
        programCode: programCode || "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setDataChanged(true);
        onSubmit(values);
      }}
    >
      {(formik) => {
        return (
          <Form
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  formik.handleSubmit();
                  e.preventDefault();
                  return false;
                }
              }}
          >
            <Container className={classes.container}>
              {childID && renderChildHeader()}  
              <Box className={classes.additionalMessageBox}>
                <Typography className={classes.additionalMessage}>
                  {pageLabels ? (isClubsDomain ? pageLabels.firstStepInstructionClub : pageLabels.firstStepInstruction ) : ""}
                </Typography>
              </Box>
              <Box className={classes.clubCodeBox}>
                <Field
                  name="programCode"
                  component={TextInput}
                  required
                  label="Program Code"
                  validate={validateCode}
                  errorIsHtml={true}
                  InputLabelProps={{ shrink: true }}
                  setTouchedOnChange={formik.values.programCode === programCode}
                />
              </Box>
            </Container>
            <Box className={classes.submitContainer}>
              <CustomButton
                form="form1"
                className={classes.submitBtn}
                onClick={formik.handleSubmit}
                type="submit"
                mode={"primary"}
                label={"Continue"}
              />
            </Box>
            <Box className={classes.additionalMessageBox}>
              <Typography
                className={`${classes.footerMessage} ${classes.firstStepAdditionalInstruction}`}
                dangerouslySetInnerHTML={createMarkup(pageLabels
                  ? pageLabels.firstStepAdditionalInstruction
                  : ""
                )}
              />
            </Box>
            <FocusError />
          </Form>
        )
      }}
    </Formik>
  );
};

export default FirstStep;
