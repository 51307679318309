import { createAction } from 'redux-act';

export const getProjectGalleries = createAction();
export const receivedProjectGalleries = createAction();

export const getFiltersData = createAction();
export const receivedFiltersData = createAction();

export const getUserProjectsByProgram = createAction();
export const receivedMyProjectsByProgram = createAction();

export const getIsUserProjectsByProgram = createAction();
export const receivedIsUserProjectsByProgram = createAction();

export const getUsersProjectsByProgram = createAction();
export const receivedUsersProjectsByProgram = createAction();

export const getProjectsByProgram = createAction();
export const receivedProjectsByProgram = createAction();
