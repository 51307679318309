import { getAuthHeader } from '../tokenStorage';
import { axiosInstance } from './axiosInstance';

export const getMessages = async ({ programId }) => {
  const id = parseInt(programId, 10);
  return axiosInstance.get(`/blapi/messages/getAllByProgram/${id}`, {
    headers: getAuthHeader(),
  });
};

export const getSingleMessage = async ({ postId }) => {
  const id = parseInt(postId, 10);
  return axiosInstance.get(`/blapi/messages/getOne/${id}`, {
    headers: getAuthHeader(),
  });
};

export const createMessage = async (payload) =>  {
  return axiosInstance.post(
    '/blapi/messages/create',
    payload,
    {
      headers: getAuthHeader(),
    }
  );
};

export const updateMessage = async (payload) => {
  return axiosInstance.post(
    '/blapi/messages/update',
    payload,
    {
      headers: getAuthHeader(),
    }
  );
};

export const deleteMessage = async (payload) => {
  return axiosInstance.post(
    '/blapi/messages/delete',
    payload,
    {
      headers: getAuthHeader(),
    }
  );
};

export const reportMessage = async (payload) => {
  return axiosInstance.post(
    '/blapi/messages/report',
    payload,
    {
      headers: getAuthHeader(),
    }
  );
};