import { getAuthHeader } from "../tokenStorage";
import { axiosInstance } from './axiosInstance';

export const getUserEvents = async ({ startDate, pageNumber, filters }) => {
  return axiosInstance.post(
    `/blapi/calendar/getAllEvents`,
    { startDate, pageNumber, ...filters },
    {
      headers: getAuthHeader(),
    }
  );
};

export const updateEvent = async ({ event, sendEmail }) => {
  return axiosInstance.post(
    `/blapi/calendar/updateEvent`,
    { event, sendEmail },
    {
      headers: getAuthHeader(),
    }
  );
};

export const createEvent = async ({ event }) => {
  return axiosInstance.post(
    `/blapi/calendar/createEvent`,
    { event },
    {
      headers: getAuthHeader(),
    }
  );
};

export const deleteEvent = async (eventId) => {
  return axiosInstance.delete(
    `/blapi/calendar/deleteEvent/${eventId}`,
    {
      headers: getAuthHeader(),
    }
  );
};

export const getCalendarUrl = async () => {
  return axiosInstance.get(`/blapi/calendar/getUrl`, {
    headers: getAuthHeader(),
  });
};

export const getEventProgramsAndAttendees = async (id) => {
  return axiosInstance.get(`/blapi/calendar/getEventAdditionalInfo?id=${id}`, {
    headers: getAuthHeader(),
  });
};