import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const FocusError = () => {
  const { errors, isSubmitting, isValidating } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating) {

      let keys = Object.keys(errors);
      if (keys.length > 0) {


        //Control for element, example - button to open calendar calendar
        //Element using id, example - comboboxes
        //Input using name - example - textbox
        const selectors = [
          `#${keys[0]}-control`,
          `#${keys[0]}`,
          `[name=${keys[0]}]`,
        ];

        for (const selector of selectors) {
          const errorElement = document.querySelector(selector);
          if (errorElement) {
            errorElement.focus();
            break;
          }
        }
      }
    }
  }, [errors, isSubmitting, isValidating]);

  return null;
};

export default FocusError;