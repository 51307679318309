import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { HQModal } from "../HQModal";
import CustomButton from "../../../components/customButton/customButton";
import { getUserName } from "../../../utils/generic";
import CircularProgress from "@material-ui/core/CircularProgress";
import MultipleGroupedSelector from "../members/multipleGroupedSelector";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import {
  Box,
  DialogContent,
} from "@material-ui/core/";

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: 22,
    fontWeight: "bold",
    margin: "25px auto 15px",
    maxWidth: "90%",
    textAlign: "center"
  },
  dialogContent: {
    padding: 30,
    minWidth: 300,
  },
  buttonGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none",
    fontSize: 16,
  },
  studentField: {
    marginLeft: 10,
    maxWidth: 250,
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
      marginLeft: 0,
    },
    '& .MuiTypography-body1': {
      lineHeight: 'inherit',
    }
  },
  boxSection: {
    display: "flex",
    justifyContent: "center",
  },
  totalItemsLabel: {
    marginTop: 10,
    color: theme.palette.primary.main,
    lineHeight: 1.75,
  },
  infoText: {
    color: theme.palette.primary.main,
    lineHeight: 1.75,
    textAlign: "center",
  },
}));

export const GenerateCertificateModal = ({ labels, isOpen, onClose, items, isLoading, generate }) => {
  const classes = useStyles();

  const [studentsList, setStudentsList] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);

  useEffect(() => {
    const students = items
      .map((el) => {
        return {
          id: el.userId,
          label: getUserName(el),
        };
      })
      .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
    setStudentsList(students);
  }, [items]);

  return (
    <HQModal title="GENERATE CERTIFICATES" isOpen={isOpen} onClose={onClose}>
      <div className={classes.infoText}>
        {!!labels && labels.generateCertificatesModalInfoText}
      </div>
      <DialogContent className={classes.dialogContent}>
        <Formik
          validationSchema={Yup.object({
            studentIds: Yup.array().required("Required"),
          })}
          initialValues={{
            studentIds: [],
          }}
          validate={(values) => {
            let errors = {};
            setSelectedCount(values.studentIds.length);
            return errors;
          }}
          onSubmit={(values) => {
            generate(values.studentIds);
            onClose();
          }}
        >
          <Form>
            <Box className={classes.boxSection}>
              <Field
                name={`studentIds`}
                className={classes.studentField}
                component={MultipleGroupedSelector}
                title='Members'
                label="People"
                options={studentsList}
              //isLoading={isLoadingProgramMembers}
              />
            </Box>
            <div className={classes.totalItemsLabel}>{`Creating (${selectedCount}) certificates`}</div>
            <div className={classes.buttonGroup}>
              <CustomButton
                mode="textButton"
                onClick={onClose}
                label={"Cancel"}
              />
              <CustomButton
                className={classes.addBtn}
                mode={"primary"}
                disabled={!selectedCount}
                type="submit"
                label={"Generate"}>
                {isLoading && (
                  <CircularProgress
                    size={20}
                    className={classes.progressIcon}
                  />
                )}
              </CustomButton>
            </div>
          </Form>
        </Formik>
      </DialogContent>
    </HQModal>
  );
};
