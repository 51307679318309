import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core/';
import CircularProgress from '@material-ui/core/CircularProgress';

import { createMarkup } from '../../../utils/generic';
import CustomButton from '../../customButton/customButton';
import * as colors from '../../../theme/colors';

const useStyles = makeStyles(() => ({
  subTitle: {
    marginBottom: 10,
    '& a': {
      color: colors.darkThemeGreen,
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 35,
  },
  confirmBtn: {
    fontSize: 16,
    padding: '9px 22px',
  },
  progressIcon: {
    width: 20,
    height: 20,
    marginLeft: 5,
  },
}));

export const FacilitatorsConsentModal = ({
  onSubmit,
  labels,
  programName,
  isLoading,
}) => {
  const classes = useStyles();

  if (!labels) return null;

  return (
    <Box>
      <Typography
        className={classes.subTitle}
        dangerouslySetInnerHTML={createMarkup(
          !!labels ? labels.replace('{{programName}}', programName) : ''
        )}
      ></Typography>
      <Box className={classes.btnContainer}>
        <CustomButton
          className={classes.confirmBtn}
          mode="primary"
          type="submit"
          onClick={onSubmit}
          label="Confirm"
          disabled={isLoading}
        >
          {isLoading && (
            <CircularProgress size={20} className={classes.progressIcon} />
          )}
        </CustomButton>
      </Box>
    </Box>
  );
};
