import { createReducer } from 'redux-act'

import * as a from '../actions/signUp'
import { takeIfExists } from '../utils/local-storage'

const getDefaultState = () => ({
  token: takeIfExists('token'),
  refreshToken: takeIfExists('refreshToken'),
  isClubCodeValid: null,
  checkingByCodeResult: null,
  isClubCodeValidating: false,
})

export default () =>
  createReducer(
    {
      [a.receivedSignUp]: (state, { token, refreshToken }) => ({
        ...state,
        refreshToken,
        token,
      }),
      [a.checkClubByCode]: (state, ) => {
        return ({
          ...state,
          isClubCodeValidating: true,
        });
      },
      [a.receivedCheckClubByCode]: (state, response) => {
        return ({
          ...state,
          checkingByCodeResult: response,
          isClubCodeValidating: false,
        });
      },
    },
    getDefaultState()
  )
