import React, { useEffect, useRef } from 'react';
import { useSnackbar } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { connectTo } from '../../utils/generic';
import * as actions from '../../actions/announcements';
import { addDot } from '../../utils/addDot';
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';

const Notification = ({ message, options }) => {
  const snackBar = useSnackbar();
  const keyRef = useRef(null);

  useEffect(() => {
    if (!message) return;
    const { text, type } = message;
    showMessage(addDot(capitalizeFirstLetter(text)), type);
  }, [message]);

  const closeMessage = () => {
    snackBar.closeSnackbar(keyRef.current);
  };

  const showMessage = (errorMsg, variant) => {
    const key = snackBar.enqueueSnackbar(errorMsg, {
      variant,
      action: (
        <IconButton aria-label="close" color="inherit" onClick={closeMessage}>
          <CloseIcon fontSize="small" />
        </IconButton>
      ),
      ...options
    });
    keyRef.current = key;
  };

  return <React.Fragment></React.Fragment>;
};

export default connectTo(
  (state) => {
    return {
      ...state.notification,
    };
  },
  actions,
  Notification
);
