import React from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import { createPopper } from '@popperjs/core';

import { RatingModal } from "../../components/modals/rating/ratingModal";

import { connectTo } from "../../utils/generic";
import { getTheme } from "../../utils/colorTheme";
import * as curriculumActions from "../../actions/curriculum";
import * as authActions from "../../actions/auth";
import * as globalActions from "../../actions/global";
import * as userActivitiesInfoActions from '../../actions/userActivitiesInfo';

import NavBar from "../HorizontalNavigation";
import Footer from "../../containers/Footer/footer";
import Activity from "./Activity";
import CardsGrid from "./CardsGrid";

import * as trackingActions from "../../actions/tracking";
import * as contentfulActions from "../../actions/contentful";
import * as ratingActions from "../../actions/rating";
import * as openTextActions from "../../actions/openText";
import * as notificationActions from "../../actions/notification";
import * as quizesAnswers from "../../actions/quizesAnswers";

import {
  ACTIVITY_DONE_BUTTON_CLICKED,
} from "../../constants/trackingEvents";

import { useTitle } from "../../utils/pageTitles";
import { defaultProgramRoleNames, defaultProgramColors } from "../../utils/programRoleLabels";

import { Roles } from "../../utils/permissions";
import { isFinishedHardClosureProgram } from '../../utils/checkFinishedHardClosureProgram'

import { getCurrentNode, getStudentsSections } from "./curriculumHelper";

import CurriculumHeader from "./CurriculumHeader";

import { getNodeIds } from "../../utils/getNodeIds";
import { handleKeyPress } from '../../utils/accessibility';
import { get } from 'lodash';
import './tooltip.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
  },
  main: {
    width: "100%",
    margin: "auto auto",
    height: "100%",
    minHeight: 'calc(100vh - 132px)',
    paddingBottom: 15,
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    paddingTop: 50,
    [theme.breakpoints.down('md')]: {
      paddingTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 100,
    },
  },
}));


var startViewTime = new Date();
var startContentSectionTime = new Date();
var currentActivityInfo;

const Curriculum = (props) => {
  const {
    auth: { currentRole, user },
    contentful,
    curriculum,
    history: { push },
    location,
    loadGlobalContent,
    changeRole,
    setActivityLog,
    userActivitiesInfo,
    createTrackingLog,
    createUsageLog,
    switchMobileMenu,
    leftMenuVisible,
    isMobileView,
    deleteMyActivityProgress,
    getUserProgramLogs,
    saveRating,
    csItems,
    createOrUpdateCSItem,
    openTextAnswers,
    receivedAnswer,
    getMyAnswersByProgram,
    getAnswersForActivity,
    modalUserLogs,
    createModalUserLog,
    studentView,
    showInfoMessage,
    userQuizesAnswers,
    updateCurrentStateofQuizesAnswers,
    history,
  } = props;

  const enforcePreview = location.state && location.state.enforcePreview ? location.state.enforcePreview : false;
  const enforcePresenter = location.state && location.state.enforcePresenter ? location.state.enforcePresenter : false;

  const [isFacilitator, setIsFacilitator] = React.useState(false);
  const [showGoals, setShowGoals] = React.useState(false);
  const [presentationMode, setIsPresentationMode] = React.useState(enforcePresenter);
  const [activityStep, setActivityStep] = React.useState(0);
  const [activity, setActivity] = React.useState(
    curriculum && curriculum.currentActivity ? curriculum.currentActivity : null
  );
  const [studentsSections, setStudentsSections] = React.useState(null);

  const [current, setCurrent] = React.useState({});

  const [isRatingOpen, setIsRatingOpen] = React.useState(false);
  const [nextPath, setNextPath] = React.useState(null);

  const [disabledNext, setDisabledNext] = React.useState(false);
  const [answeredQuestionsState, setAnsweredQuestionsState] = React.useState({});
  const [isOpenQuestionAnswerUpdated, setOpenQuestionAnswerUpdated] = React.useState(false);
  const [activityUnfinishedSections, setActivityUnfinishedSection] = React.useState([]);
  const [programTypeDetails, setProgramTypeDetails] = React.useState(null);
  const [isFinishedProject, setIsFinishedProject] = React.useState(false);

  React.useEffect(() => {
    // dont load contentfull if it was already loaded
    if (!contentful || !!contentful.hqConfig) {
      return;
    }
    loadGlobalContent();
  }, []);

  const classes = useStyles();

  const { hqConfig } = contentful;

  const {
    chain,
    current: currentNode,
    next,
    parentNode,
    previous,
    parts,
  } = current;

  const currentCurriculum = currentRole
  ? currentRole.program
    ? contentful.curriculums[currentRole.program.curriculum.code]
    : null
  : null;

  const title = !!currentNode ? currentNode.name : "";
  const isFacilitatorOnlyAvailable = !!currentNode && currentNode.permissionLevel === "Facilitators/Teachers only";

  
  
  useTitle(title, !!title);

  React.useEffect(() => {
    const currentCurriculum = currentRole
      ? currentRole.program
        ? contentful.curriculums[currentRole.program.curriculum.code]
        : null
      : null;

    if (!currentCurriculum) {
      return;
    }

    const currentProgramTypeDetails = currentRole.program?.programTypeDetails;
    setProgramTypeDetails(currentProgramTypeDetails);

    const isFinishedHardClosuredProgram = currentRole ? isFinishedHardClosureProgram(currentProgramTypeDetails, currentRole.program.projectEndDate, currentRole.program.hardClosureEndDate) : false;
    setIsFinishedProject(isFinishedHardClosuredProgram);

    const data = getCurrentNode(currentCurriculum, location.pathname);
    setCurrent(data);
  }, [contentful, currentRole, location]);

  React.useEffect(() => {
    setShowGoals(false);
    if (!currentNode) return;

    if (
      currentNode.type === "activity" ||
      currentNode.nodeType === "activity"
    ) {
      // determining current step if has was provided
      if (currentNode.contentSections && location.hash && location.hash.length > 1) {
        const isStudent = currentRole.roleId === Roles.Student;
        const cSections = isStudent || studentView || enforcePresenter || presentationMode ?
          currentNode.contentSections.filter(r => !r.onlyVisibleToFacilitators) :
          currentNode.contentSections;
        const step = cSections.findIndex(r => r.id === location.hash.replace('#', ''))
        const isBeforeLastStep = !cSections || step >= cSections.length - 1;

        if (isStudent && isBeforeLastStep) {
          const { numberOfAnswered, optionalSections } = getAnsweredQuestions(openTextAnswers, cSections, chain, userQuizesAnswers?.quizesAnswers);
          setDisabledNext((numberOfAnswered.length + optionalSections.length) < cSections?.length);
        } else {
          setDisabledNext(false);
        }
        setActivityStep(step !== -1 ? step : 0);
      } else {
        setActivityStep(0);
      }
      startContentSectionTime = new Date();

      const existingActivity = curriculum.activities[currentNode.slug];

      if (
        !existingActivity ||
        (!!currentRole && currentRole.roleId !== existingActivity.roleId)
      ) {
        if (!!currentRole) {
          currentRole.roleId === Roles.Student && props.getQuizesAnswers({
            programId: currentRole.programId,
          });
          props.loadActivity({
            slug: currentNode.slug,
            permissionContext: {
              roleId: currentRole.roleId,
            },
            noCache: true,
          });
        }
      } else {
        props.setCurrentActivity(existingActivity);
      }

      if (currentRole) {
        const isStudent = currentRole.roleId === 2 || studentView;
        const isViewer = currentRole.roleId === Roles.Viewer;

        setIsFacilitator(!isStudent && !isViewer);

        let pMode = isStudent;
        if (enforcePresenter) {
          pMode = true;
        }
        if (enforcePreview) {
          pMode = false;
        }

        setIsPresentationMode(pMode);
        switchMobileMenu({ leftMenuVisible: !pMode });
      }
    } else {
      setIsPresentationMode(false);
      switchMobileMenu({ leftMenuVisible: true });
      props.setCurrentActivity(null);
    }
  }, [currentNode, currentRole, studentView]);

  React.useEffect(() => {
    if (!currentNode) return;

    if (Object.keys(userQuizesAnswers?.quizesAnswers).length === 0 && currentRole.roleId === Roles.Student) {
      props.getQuizesAnswers({
        programId: currentRole.programId,
      })
    }
  }, [currentRole, currentNode])

  React.useEffect(() => {
    if (!currentNode) return;

    setActivity(curriculum.currentActivity);

    const studentsSections = getStudentsSections(curriculum.currentActivity);

    setStudentsSections(studentsSections);
  }, [curriculum.currentActivity, currentNode]);

  const sendActivityLog = () => {
    if (!currentNode || !curriculum.currentActivity || !presentationMode) return;

    if (studentsSections && studentsSections.length > 0) {
      const currentActivity = {
        userId: user.id.toString(),
        programId: currentRole.programId.toString(),
        contentSection: studentsSections[activityStep || 0].id,
        isViewed: true,
        title: curriculum.currentActivity.slug,
        path: location.pathname,
      };

      setActivityLog({
        ...currentActivity,
        activitySetSlug: parentNode && parentNode.type === 'set' || parentNode.nodeType === 'set' ? parentNode.slug : null,
        duration: null,
      });
      currentActivityInfo = {
        currentActivity,
        parentNode
      };
      startContentSectionTime = new Date();
    } else {
      currentActivityInfo = null;
    }
  }

  React.useEffect(() => {
    return () => {
      if (!currentActivityInfo) {
        return;
      }

      const { parentNode, currentActivity } = currentActivityInfo;
      // Save log for content section when user close/navigate to another page from presentation mode
      setActivityLog({
        ...currentActivity,
        activitySetSlug: parentNode && parentNode.type === 'set' || parentNode.nodeType === 'set' ? parentNode.slug : null,
        duration: (new Date() - startContentSectionTime) * 0.001,
      });
    }
  }, []);

  React.useEffect(() => {
    if (!currentRole) {
      return;
    }

    if (currentRole.program && currentRole.program.id && [Roles.Student, Roles.Facilitator, Roles.Viewer].includes(currentRole.roleId)) {
      getUserProgramLogs({ programId: currentRole.program.id });
    }
  }, [currentRole]);

  const doesntExistActiveProgram =
    user && user.roles
      ? user.roles.find((e) => e.current && !e.partnership)
      : null;
  const activeRoles =
    user && user.roles ? user.roles.filter((e) => e.current) : [];

  if (
    !currentNode &&
    hqConfig.defaultProgram &&
    currentRole &&
    ((parts && parts.length > 0 && currentCurriculum) ||
      !doesntExistActiveProgram ||
      activeRoles.length > 1)
  ) {
    const defaultProgramId = parseInt(hqConfig.defaultProgram, 10);
    const isDefaultClubExist =
      user && user.roles
        ? user.roles.find((e) => e.programId === defaultProgramId)
        : null;

    if (
      (currentRole &&
        currentRole.program &&
        currentRole.program.id === defaultProgramId) ||
      !isDefaultClubExist
    ) {
      push(`/home`);
    }

    if (isDefaultClubExist) {
      changeRole({ programId: defaultProgramId });
    }
  }

  const exitActivity = () => {
    const pathParts = location.pathname.split('/');
    const newLocation = pathParts.slice(0, pathParts.length - 1).join('/');
    push(newLocation);
  };

  const activityDoneClick = (next) => {
    if (!userActivitiesInfo) {
      return;
    }

    const items = userActivitiesInfo.filter((r) => r.title === activity.slug);

    if (items.length > 0 && items[0].isDone) {
      if (next && next.path) {
        push(`/gwc${next.path}`, { enforcePresenter });
      }
    }

    const existingItem =
      items.length > 0
        ? {
          userId: items[0].userId.toString(),
          programId: items[0].programId
            ? items[0].programId.toString()
            : null,
          isViewed: items[0].isViewed,
          title: items[0].title,
          isViewed: presentationMode,
          isDone: true,
          duration: (new Date() - startViewTime) * 0.001,
          path: location.pathname,
          activitySetSlug: parentNode && parentNode.type === 'set' || parentNode.nodeType === 'set' ? parentNode.slug : null,
        }
        : {
          userId: user.id.toString(),
          programId: currentRole.program
            ? currentRole.programId.toString()
            : null,
          title: curriculum.currentActivity.slug,
          isDone: true,
          isViewed: presentationMode,
          duration: (new Date() - startViewTime) * 0.001,
          path: location.pathname,
          activitySetSlug: parentNode && parentNode.type === 'set' || parentNode.nodeType === 'set' ? parentNode.slug : null,
        };

    delete existingItem.id;
    currentActivityInfo = null;

    setActivityLog(existingItem);
    startViewTime = new Date();

    createTrackingLog({
      event: ACTIVITY_DONE_BUTTON_CLICKED,
      data: JSON.stringify({ data: window.location.pathname }),
    });

    const isParentSet = parentNode.type === 'set' || parentNode.nodeType === 'set';
    const isCollection = parentNode.__typename === 'Collection';
    const isLastActivity = parentNode.activities
      && parentNode.activities.findIndex(el => el.id === currentNode.id) === parentNode.activities.length - 1;

    if (isCollection) {
      setNextPath(null);
      if (!isFacilitator) {
        setNextPath(`/gwc${parentNode.path}`);
        setIsRatingOpen(true);
      } else {
        push(`/gwc${parentNode.path}`);
      }
    }
    else if (isParentSet && isLastActivity) {
      // move user to parent of current set
      setNextPath(null);
      const parentPath = parentNode.path;
      const parentPathForSet = parentPath.substring(0, parentPath.lastIndexOf('/'));
      if (!isFacilitator) {
        setNextPath(`/gwc${parentPathForSet}`);
        setIsRatingOpen(true);
      } else {
        push(`/gwc${parentPathForSet}`);
      }
    }
    else {
      if (!isFacilitator) {
        if (next && next.path) {
          setNextPath(`/gwc${next.path}`);
        }
        else {
          setNextPath(null);
        }

        setIsRatingOpen(true);
      }
      else {
        if (next && next.path) {
          push(`/gwc${next.path}`, { enforcePresenter });
        }
      }
    }
  };

  const setCurrentActivityInfo = (activity) => {
    currentActivityInfo = activity;
  }

  const setStartContentSectionTime = (item) => {
    startContentSectionTime = item;
  }

  React.useEffect(() => {
    if (!currentNode?.contentSections) return;
    const isStudent = currentRole?.roleId === Roles.Student;
    const cSections = isStudent || studentView || 
      enforcePresenter || presentationMode 
        ? currentNode?.contentSections.filter(r => !r.onlyVisibleToFacilitators) 
        : currentNode?.contentSections;
    const isBeforeLastStep = !cSections || activityStep >= cSections.length - 1;
    const { numberOfAnswered, unfinishedSections, optionalAnsweredSections, optionalSections } = getAnsweredQuestions(openTextAnswers, cSections, chain, userQuizesAnswers?.quizesAnswers);
    setAnsweredQuestionsState({numberOfAnswered, totalQuestions: cSections.length, optionalAnsweredSectionsTotal: optionalAnsweredSections.length, optionalSectionsTotal: optionalSections.length});
    if (isStudent && isBeforeLastStep) {
      setDisabledNext((numberOfAnswered.length + optionalSections.length) < cSections?.length);
      setActivityUnfinishedSection(unfinishedSections);
    } else {
      setDisabledNext(false);
    }
  }, [currentNode, openTextAnswers, activityStep, userQuizesAnswers, presentationMode]);

  React.useEffect(() => {
    if (!currentRole) {
      return;
    }
    const programId = currentRole?.program.id;
    if (!openTextAnswers.userAnswers?.length && currentRole.roleId === Roles.Student) {
      getMyAnswersByProgram({ programId });
    }
  }, []);

  const iframes = document.getElementsByTagName('iframe');

  React.useEffect(() => {
      if (iframes.length > 0) {
        let index = 0;
        for ( const iframe of iframes ) {
          if ( !iframe.src.includes("trinket")) continue;
          iframe.onload = () => {
            document.activeElement.blur();
          }
          const containerId = `skip-container-${index}`;
          const popup = document.createElement('div');
          const popupItemId = `iframe-tooltip-iframe-${index}`;
          popup.innerHTML = 
          `<div id="${popupItemId}" class="MuiTooltip-tooltip MuiTooltip-tooltipPlacementTop iframe-tooltip">Skip to Content (Press Enter)</div>`;
          popup.setAttribute('aria-label', 'Skip to Content (Press Enter)');
          popup.setAttribute('id', containerId)
          popup.setAttribute('tabIndex', '0');
          popup.setAttribute('role', 'application');
          popup.onkeydown = handleKeyPress(() => {
            iframe.setAttribute('tabIndex', '-1');
            document.getElementById(popupItemId).classList.remove("active");
          })
          popup.onfocus = () => {
            document.getElementById(popupItemId).classList.add("active");
          }
          popup.onblur = () => {
            document.getElementById(popupItemId).classList.remove("active");
          }
          iframe.parentNode.insertBefore(popup, iframe);
          const container = document.querySelector(`#${containerId}`);
          const tooltip = document.querySelector(`#${popupItemId}`);
          createPopper(container, tooltip, {
            placement: 'top',
          });
          index++;
        }
      }
  }, [iframes.length]);

  const getAnsweredQuestions = (openTextAnswers, contentSections, chains, quizesAnswers) => {
    const { url } = Array.isArray(chains) && getNodeIds(chains);

    const unfinishedSections = [];
    const optionalAnsweredSections = [];
    const optionalSections = [];

    const numberOfAnswered = Array.isArray(contentSections) &&
      contentSections.filter((contentSection) => {
        const itemKey = `${url}-${contentSection.id}`;
        const sectionType = get(contentSection, ['__typename']);

        const quizAnswer = get(quizesAnswers, [`${contentSection.id}`]);
        if (!contentSection.isOptionalQuiz && sectionType === 'QuizQuestion' && Array.isArray(quizAnswer) && !!quizAnswer[0].isCorrect) {
          return true;
        }

        const currentAnswers = get(openTextAnswers.userAnswers, [`${itemKey}`], []);
        if (currentAnswers.length) {
          /*Condition due to ticket HQ-5775 (updated in scope of HQ-6828)
            ‘I’m done’ button should be disabled until the student has met the following criteria for a given activity:
            Quiz Question - If the quiz question has been answered correctly
            Open Text With Required Grading - If an answer has been submitted (doesn’t need to be graded)
            Open Text (Grading not required) - If an answer has been submitted
          */

          if (!contentSection.isOptionalTextAnswer && (contentSection?.contentSectionGroup?.requiredGrading || contentSection.requiredGrading) &&
            currentAnswers[0].status === 1 || currentAnswers[0].status === null) {
            return true;
          }
        }
        if (!contentSection.isOptionalTextAnswer && contentSection.isOpenTextAnswer && !contentSection.requiredGrading && !!currentAnswers.length) {
          return true;
        }
        const isOptionalSection = (contentSection.isOptionalQuiz || contentSection.isOptionalTextAnswer);
        if (isOptionalSection) {
          optionalSections.push(contentSection);
          if ((contentSection.isOpenTextAnswer && !!currentAnswers.length) || (contentSection.isOptionalQuiz && Array.isArray(quizAnswer) && !!quizAnswer[0].isCorrect)) {
            optionalAnsweredSections.push(contentSection);
          }
        }
        const isSimpleSection = (sectionType !== 'QuizQuestion' && !contentSection.isOpenTextAnswer);
        if (isSimpleSection) {
          return true;
        }
        unfinishedSections.push(contentSection); 
        return false;
      }
    )

    return { numberOfAnswered, unfinishedSections, optionalAnsweredSections, optionalSections  };
  }

  const onSectionClick = (contentSectionIndex) => {
    window.scrollTo(0,0);
    setActivityStep(contentSectionIndex);
  }

  const onNextClick = () => {
    if (!parentNode) return null;

    if (!currentNode) return null;

    if (
      currentNode.type !== "activity" &&
      currentNode.nodeType !== "activity"
    ) {
      return null;
    }

    window.scrollTo(0,0);

    const studentsSections = activity.contentSections
      && activity.contentSections.filter(e => !e.onlyVisibleToFacilitators);

    const isLastStep = !studentsSections || activityStep === studentsSections.length - 1;
 
    setActivityLog({
      userId: user.id.toString(),
      programId: currentRole.program ? currentRole.programId.toString() : null,
      contentSection: studentsSections && studentsSections.length > 0 ? studentsSections[activityStep].id : null,
      activitySetSlug: parentNode && parentNode.type === 'set' || parentNode.nodeType === 'set' ? parentNode.slug : null,
      isViewed: true,
      title: activity.slug,
      duration: (new Date() - startContentSectionTime) * 0.001,
      path: location.pathname,
    });

    startContentSectionTime = new Date();

    if (isLastStep) {
      const isActivity =
        next && (next.type === "activity" ||
          next.nodeType === "activity");
      if (isFacilitator && !studentView && !isActivity)
        setIsPresentationMode(false);
        activityDoneClick(next);
    } else {
      currentActivityInfo = {
        currentActivity: {
          userId: user.id.toString(),
          programId: currentRole.program ? currentRole.programId.toString() : null,
          contentSection: studentsSections[activityStep + 1].id,
          isViewed: true,
          title: activity.slug,
          path: location.pathname,
        },
        parentNode
      };
      setActivityStep(activityStep + 1);
    }
  }

  window.onscroll = function () {
    if (
      currentNode &&
      (currentNode.type === "activity" || currentNode.nodeType === "activity")
    ) {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        if (!activity) return;

        const items =
          activity && userActivitiesInfo
            ? userActivitiesInfo.filter((r) => r.title === activity.slug)
            : [];

        if (items.length > 0 && items[0].isViewed) return;

        const existingItem =
          items.length > 0
            ? {
              ...items[0],
              isDone: true,
            }
            : {
              userId: user.id.toString(),
              programId: currentRole.program ? currentRole.programId.toString() : null,
              title: activity.slug,
              isDone: false,
              isViewed: true,
            };

        delete existingItem.id;

        if (existingItem.title === null) return;

        existingItem.programId = existingItem.programId ? existingItem.programId.toString() : null;
        existingItem.userId = existingItem.userId.toString();

        setActivityLog(existingItem);
      }
    }
  };

  let nodeTheme = getTheme(
    activity && activity.activityType
      ? activity.activityType.colorTheme
      : null);

  if (currentNode && currentNode.setType && currentNode.setType.colorTheme) {
    nodeTheme = getTheme(currentNode.setType.colorTheme);
  }

  const labelBackground = programTypeDetails?.backgroundColor || defaultProgramColors.backgroundColor;
  const labelFontColor = programTypeDetails?.fontColor || defaultProgramColors.fontColor;
  const programRoleLabel = `${programTypeDetails?.facilitatorRoleName || defaultProgramRoleNames[currentRole?.roleId]} content`;

  const certificateRibbonIcon = contentful.GlobalComponentQuery
  ? contentful.GlobalComponentQuery.setPageLabels.certificateRibbonIcon
  : null;

  const isActivity = !!currentNode && (currentNode.type === "activity" || currentNode.nodeType === "activity");

  return (
    <div className={classes.root}>
      <CssBaseline />
      <NavBar />
      <main className={classes.main}>
        <CurriculumHeader
          user={user}
          currentRole={currentRole}
          curriculum={curriculum}
          contentful={contentful}
          currentNode={currentNode}
          parentNode={parentNode}
          nodeTheme={nodeTheme}
          chain={chain}
          labelBackground={labelBackground}
          labelFontColor={labelFontColor}
          showGoals={showGoals}
          setShowGoals={setShowGoals}
          isFacilitatorOnlyAvailable={isFacilitatorOnlyAvailable}
          studentView={studentView}
          presentationMode={presentationMode}
          activityStep={activityStep}
          activity={activity}
          createUsageLog={createUsageLog}
          setCurrentActivityInfo={setCurrentActivityInfo}
          setStartContentSectionTime={setStartContentSectionTime}
          setIsPresentationMode={setIsPresentationMode}
          switchMobileMenu={switchMobileMenu}
          disabledNext={disabledNext}
          onNextClick={onNextClick}
          next={next}
          setActivityStep={setActivityStep}
          push={push}
          location={location}
          programRoleLabel={programRoleLabel}
          certificateRibbonIcon={certificateRibbonIcon}
          studentsSections={studentsSections}
          isOpenQuestionAnswerUpdated={isOpenQuestionAnswerUpdated}
          setOpenQuestionAnswerUpdated={setOpenQuestionAnswerUpdated}
          setDisabledNext={setDisabledNext}
          answeredQuestionsState={answeredQuestionsState}
          activityUnfinishedSections={activityUnfinishedSections}
          onSectionClick={onSectionClick}
          isFacilitator={currentRole && currentRole.roleId === Roles.Facilitator}
        />
        {isRatingOpen &&
          <RatingModal
            isOpen={isRatingOpen}
            programId={currentRole && currentRole.program ? currentRole.programId : null}
            activitySlug={currentNode ? currentNode.slug : null}
            parentNode={parentNode}
            onSave={saveRating}
            onCloseModal={() => {
              setIsRatingOpen(false);
              if (nextPath) {
                push(nextPath, { enforcePresenter });
              }
            }}
          />
        }
        {isActivity ?
          <Activity
            roleId={currentRole && currentRole.roleId}
            programCode={currentRole && currentRole.program && currentRole.program.code}
            program={currentRole && currentRole.program}
            item={activity}
            leftMenuVisible={leftMenuVisible}
            isMobileView={isMobileView}
            next={next}
            pageLabels={
              contentful.GlobalComponentQuery
                ? contentful.GlobalComponentQuery.activityPageLabels
                : null
            }
            parentNode={parentNode}
            previous={previous}
            createUsageLog={createUsageLog}
            setDone={activityDoneClick}
            activityStep={activityStep}
            presentationMode={presentationMode}
            sendActivityLog={sendActivityLog}
            onExit={exitActivity}
            chain={chain}
            csItems={csItems}
            createOrUpdateCSItem={createOrUpdateCSItem}
            openTextAnswers={openTextAnswers}
            receivedAnswer={receivedAnswer}
            onNextClick={onNextClick}
            getMyAnswersByProgram={getMyAnswersByProgram}
            getAnswersForActivity={getAnswersForActivity}
            isFacilitator={isFacilitator}
            push={push}
            user={user}
            setDisabledNext={setDisabledNext}
            studentView={studentView}
            showInfoMessage={showInfoMessage}
            isFinishedProject={isFinishedProject}
            setOpenQuestionAnswerUpdated={setOpenQuestionAnswerUpdated}
            answeredQuestionsState={answeredQuestionsState}
            updateCurrentStateofQuizesAnswers={updateCurrentStateofQuizesAnswers}
            history={history}
            shouldDisableSubmit={disabledNext}
          />
          :
          <CardsGrid
            item={currentNode}
            pageLabels={
              contentful.GlobalComponentQuery
                ? contentful.GlobalComponentQuery.activityPageLabels
                : null
            }
            defaultActivityIcon={
              contentful.GlobalComponentQuery
                ? contentful.GlobalComponentQuery.defaultActivityIcon
                : null
            }
            curriculumLabels={
              contentful.GlobalComponentQuery
                ? contentful.GlobalComponentQuery.setPageLabels
                : null
            }
            previous={previous}
            parentNode={parentNode}
            createUsageLog={createUsageLog}
            userActivitiesInfo={userActivitiesInfo}
            isFacilitator={currentRole && currentRole.roleId === Roles.Facilitator}
            program={currentRole && currentRole.program}
            createModalUserLog={createModalUserLog}
            modalUserLogs={modalUserLogs}
            labelBackground={labelBackground}
            labelFontColor={labelFontColor}
            push={push}
            studentView={studentView}
            currentRole={currentRole}
            deleteMyActivityProgress={deleteMyActivityProgress}
            programRoleLabel={programRoleLabel}
            certificateRibbonIcon={certificateRibbonIcon}
            openTextAnswers={openTextAnswers}
            userQuizesAnswers={userQuizesAnswers}
          />
        }
      </main>
      <Footer />
    </div>
  );
};

export default connectTo(
  (state) => ({
    curriculum: state.curriculum,
    auth: state.auth,
    contentful: state.contentful,
    userActivitiesInfo: state.userActivitiesInfo.myProgress,
    csItems: state.userActivitiesInfo.csItems,
    modalUserLogs: state.userActivitiesInfo.modalUserLogs,
    leftMenuVisible: state.global.leftMenuVisible,
    isMobileView: state.global.isMobileView,
    openTextAnswers: state.openTextAnswers,
    studentView: state.global.studentView,
    userQuizesAnswers: state.quizesAnswers,
  }),
  {
    ...curriculumActions,
    ...trackingActions,
    ...globalActions,
    ...authActions,
    ...contentfulActions,
    ...userActivitiesInfoActions,
    ...ratingActions,
    ...openTextActions,
    ...notificationActions,
    ...quizesAnswers,
  },
  Curriculum
);
