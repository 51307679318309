import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import LocationIcon from '@material-ui/icons/LocationOnOutlined';
import TextField from '@material-ui/core/TextField';
import { StyledFormHelper } from '../../../components/loginSignup/customFormHelper';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

// const countryToFlag = (isoCode) => {
//   return typeof String.fromCodePoint !== 'undefined'
//     ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
//     : isoCode;
// }

const CountrySelector = ({ countries, field, form, label, checkCountryAge, elementRole = null }) => {
  const classes = useStyles();
  const meta = form.getFieldMeta(field.name);
  let selectedValue = null;
  if (form.values.country){
    const filteredCountry = countries.filter((o) => o.id === form.values.country);
    if (filteredCountry && filteredCountry.length > 0) selectedValue = filteredCountry[0];
  }

  let error = meta.error || false;
  let isTouched = meta.touched || false;

  return (
    <React.Fragment>
      <Autocomplete
        sel="country"
        role={elementRole}
        aria-label={label}
        autoSelect
        autoHighlight
        autoComplete
        className={classes.root}
        clearOnEscape
        options={countries}
        value={selectedValue}
        getOptionLabel={option => option.name}
        filterOptions={(options, { inputValue }) => {
          const byAbbreviation = options.filter((o) => o.abbreviation.toLowerCase() === inputValue.toLowerCase());

          let uk = [];
          let usa = [];

          // exception for United Kingdom
          if (inputValue.toLowerCase() === 'uk') {
            uk = options.filter((o) => o.abbreviation.toLowerCase() === 'gb');
          }
          // exception for USA
          if (inputValue.toLowerCase() === 'usa') {
            usa = options.filter((o) => o.abbreviation.toLowerCase() === 'us');
          }

          const byName = options.filter((o) => o.name.toLowerCase().indexOf(inputValue.toLowerCase()) === 0);

          return Object.values([
            ...uk,
            ...usa,
            ...byAbbreviation,
            ...byName,
          ].reduce((a, c) => {
            a[c.id] = c;
            return a
          }, {}));
        }}
        onChange={(evt, val) => {
          const countryId = val ? val.id : '';
          form.setFieldValue(field.name, countryId);
          checkCountryAge(countryId, form.values.birthDate);
        }}
        renderInput={(params) => {
          const newParams = {
            ...params,
          };

          newParams.InputProps.startAdornment = (
            <InputAdornment position="start">
              <LocationIcon />
            </InputAdornment>
          );

          //To follow accessibility requirements
          newParams.inputProps.role = "combobox";
          newParams.inputProps["aria-expanded"] = "false";
          newParams.InputLabelProps.role = "region";
          newParams.InputLabelProps["aria-label"] = label;

          return (
            <React.Fragment>
            <TextField
              label={label}
              placeholder={label}
              margin="normal"
              variant="outlined"
              required
              fullWidth
              error={!!error && isTouched}
              {...newParams}
            />
            {isTouched && error &&
            (<StyledFormHelper helperText={error} isError={true} />)}
            </React.Fragment>
          );
        }}
        renderOption={option => (
          <React.Fragment>
            {/* <span>{countryToFlag(option.abbreviation)}</span> */}
            {option.name}
          </React.Fragment>
        )}
      />
    </React.Fragment>
  );
};

export default CountrySelector;
