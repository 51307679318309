import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import Box from "@material-ui/core/Box";
import ImageIcon from "@material-ui/icons/Image";

import CommentInput from "./CommentInput";
import FileUpload from './FileUpload';
import CustomButton from "../../../components/customButton/customButton";
import Editor from '../../../components/textEditor';
import { createEmptyEditor, exportEditor } from '../../../components/textEditor/utils';
import MessageImage from './MessageImage';

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  paper: {
    maxWidth: 755,
    margin: "30px auto"
  },
  imagePreview: {
    display: "block",
    position: "relative",
    "&:hover": {
      opacity: "1"
    }
  },
  cardImage: {
    cursor: "pointer",
    color: "#565656",
    opacity: "0.8",
    "&:hover": {
      opacity: "1"
    },
    position: "absolute",
    right: 10,
    marginTop: "-40px",
    zIndex: '8',
  },
  inputContainer: {
    position: "relative",
    width: "100%",
  },
  cardUploader: {
    display: "none"
  },
  showImage: {
    display: "block",
    margin: "0 auto"
  },
  removeImage: {
    backgroundColor: "#f0f0f0",
    borderRadius: 20,
    bottom: 20,
    boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
    color: "black",
    cursor: "pointer",
    fontWeight: "bold",
    height: 36,
    left: "30%",
    lineHeight: "36px",
    opacity: 0,
    padding: "0 20px",
    position: "absolute",
    right: "30%",
    textAlign: "center",
    transition: "opacity 130ms",
    "&:hover": {
      opacity: "1"
    }
  },
  uploader: {
    width: "90%"
  },
  rowContainer: {
    display: "flex"
  },
  submitButton: {
    height: 56,
    width: 100,
  }
});

const PostComment = ({ parentMessage, onSave }) => {
  const classes = useStyles();

  const [uploadedFile, setUploadedFile] = React.useState(null);
  const removeImage = () => {
    setUploadedFile(null);
  };

  return (
    <Box>
      <Box className={classes.paper}>
        <Formik
          initialValues={{
            body: createEmptyEditor(),
            fileName: null
          }}
          validate={values => {
            let errors = {};
            if ((!values.body || !values.body.getCurrentContent().getPlainText().trim().length) && !uploadedFile) {
              errors.body = "Comment should have at least text or image";
            }
            return errors;
          }}
          onSubmit={(values, { resetForm }) => {
            const editorToHtml = exportEditor(values.body);
            const comment = {
              body: editorToHtml,
              parentId: parentMessage.id,
              fileName: uploadedFile
            };
            onSave(comment);
            resetForm({});
            setUploadedFile(null);
          }}
        >
          {({ handleSubmit }) => (
            <Form
              onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }}}
            >
            <div className={classes.rowContainer}>
              <div className={classes.inputContainer}>
                <Editor
                  name={`body`}
                  component={CommentInput}
                  placeholder="Add a comment"
                  className={classes.editorInput}
                  sel="addComment"
                  maxLength={2000}
                  showToolbar={false}
                  handleReturn={true}
                  removeScroll={true}
                  maxLengthStartingFrom={1800}
                />
                <div className={classes.cardImage}>
                  <FileUpload
                    hasFile={!!uploadedFile}
                    labelClass={classes.imageLabel}
                    onChange={setUploadedFile}
                  >
                    <ImageIcon />
                  </FileUpload>
                </div>
              </div>
              <span>
                <CustomButton
                  className={classes.submitButton}
                  type="submit"
                  mode={"secondary"}
                  label={"Send"}
                />
              </span>
            </div>
            <MessageImage
              value={uploadedFile}
              onRemove={removeImage}
            />
          </Form>)}
        </Formik>
      </Box>
    </Box>
  );
};

export default PostComment;
