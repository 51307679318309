import React from "react";

import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  avatar: {
    color: '#003046!important',
    backgroundColor: "white"
  }
});

const HqAvatar = props => {
  const classes = useStyles();
  const {
    user,
    isFacilitator,
    onClick,
    src,
    alt,
    label
  } = props;

  const backgroundColors = [
    'D7F9F4', '43D6B9',
    '0D9C90', 'E5F8FF',
    'FEEFEE', 'F37C6F',
    'FFF3E8', 'F9B88C',
    'FA7815', 'FCE688'
  ];

  const firstNameInitial = !!user.firstName && user.firstName.length > 0 ? user.firstName[0] : "";
  const lastNameInitial = !!user.lastName && user.lastName.length > 0 ? user.lastName[0] : "";

  const initials = `${firstNameInitial}${lastNameInitial}`.toUpperCase() || label || '';

  return (!!src
    ? <Avatar
        onClick={onClick}
        className={classes.avatar}
        src={src}
        alt={alt || ""}
        />
    : <Avatar
        onClick={onClick}
        className={classes.avatar}
        style={isFacilitator
          ? {background: 'linear-gradient(90deg,#d7f9f4,#fff9e2,#fff3e8,#e5f8ff,#d7f9f4)'}
          : {backgroundColor: `#${backgroundColors[(user?.id ?? user?.userId) % 10]}`}}
        >{initials}</Avatar>
  );
};

export default HqAvatar;
