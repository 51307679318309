const FacilitatorProgressPage = `
  title
  emptyTableHeading
  emptyTableMainMessage
  certificateImageDescription
  certificateTabImage {
    src
    alt
  }
  generateCertificatesModalInfoText
  `;

export default FacilitatorProgressPage;
