import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";

import { PasswordInputAnonymousUser } from "./PasswordInputAnonymousUser";
import { validatePasswordRules, filterFieldsByKey, filterFieldsWithKey } from '../../utils/generic';
import * as colors from "../../theme/colors";
import CustomButton from "../../components/customButton/customButton";
import userTypes from "../../constants/userTypeConstants";
import { Modal } from "./Modal";
import { createMarkup } from "../../utils/generic";

const useStyles = makeStyles(theme => ({
  parentFormTitle: {
    fontSize: 12,
    color: theme.palette.primary.main,
    marginBottom: 10,
    marginTop: 30
  },
  subTitle: {
    color: colors.dartThemeBlueGray,
    fontSize: 14,
    textTransform: "uppercase",
  },
  buttonGroup: {
    marginTop: "30px",
    display: "flex",
    boxShadow: "none",
    marginLeft: 40,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      marginLeft: 0,
    },
  },
  error: {
    color: '#ff0000',
    fontSize: 14,
    paddingTop: 15
  },
  changePasswordBtn: {
    padding: 10,
  },
  securityLabel: {
    color: colors.dartThemeBlueGray,
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: 1.5,
  }
}));

const securityLabelCode = "securityPasswordUpdateLabel";

export const ChangePasswordModal = ({ isOpen, onClose, onSave, passwordChanged, changePasswordContent, user, }) => {
  const classes = useStyles();
  const [valid, setValid] = useState(false);
  const [validConfirmation, setValidConfirmation] = useState(false);

  const resetFields = filterFieldsWithKey(changePasswordContent.resetStepFields, "id");
  const newPasswordContent = resetFields.newPassword;
  const newErrors = filterFieldsByKey(newPasswordContent.validationErrors, "messageCode", "message");
  const confirmErrors = filterFieldsByKey(resetFields.retypePassword.validationErrors, "messageCode", "message");

  const { firstName, username, email } = user;
  const isAnonymous = user.userType === userTypes.Anonymous && user.createdAt === user.updatedAt;

  const validateNewPassword = value => {
    const validateResult = validatePasswordRules(value, email, username, firstName);
    if (Object.keys(validateResult).length > 0) {
      setValid(false);
      return newErrors.weakPassword;
    }
    else {
      setValid(true);
    }
  }

  const validatePasswordConfirm = value => {
    const validateResult = validatePasswordRules(value, email, username, firstName);
    if (Object.keys(validateResult).length > 0) {
      setValidConfirmation(false);
      return newErrors.weakPassword;
    }
    else {
      setValidConfirmation(true);
    }
  }

  if (passwordChanged && passwordChanged.saved) {
    onClose();
  }
  
  const securityMessage = newPasswordContent && newPasswordContent.tooltipMessages.find(e => e.messageCode == securityLabelCode);

  return (
    <Modal title='CHANGE YOUR PASSWORD' isOpen={isOpen} onClose={onClose}>
      <DialogContent className={classes.dialogContent}>
        {!isAnonymous && securityMessage
          && <div
            className={classes.securityLabel}
            dangerouslySetInnerHTML={createMarkup(securityMessage && securityMessage.message)}
          />}
        <Formik
          initialValues={{
            newPassword: "",
            confirmPassword: ""
          }}
          validationSchema={Yup.object({
            newPassword: Yup.string()
              .required(newErrors.requiredField),
            confirmPassword: Yup.string()
              .required(confirmErrors.requiredField)
              .test("match", confirmErrors.differentPasswords, function (
                confirmPassword
              ) {
                return confirmPassword === this.parent.newPassword;
              })
          })}
          onSubmit={values => {
            onSave(values);
          }}
        >
          <Form>
            <Typography className={classes.subTitle}>
              New password
            </Typography>
            <Field
              name={`newPassword`}
              component={PasswordInputAnonymousUser}
              label="New password"
              className={classes.input}
              validate={validateNewPassword}
              content={newPasswordContent}
              username={username}
              firstName={firstName}
              email={email}
              fromModal={true}
            />
            <Typography className={classes.subTitle}>
              Confirm new password
            </Typography>
            <Field
              name={`confirmPassword`}
              component={PasswordInputAnonymousUser}
              label="Retype password"
              className={classes.input}
              validate={validatePasswordConfirm}
            />

            {passwordChanged && !passwordChanged.saved && passwordChanged.error &&
              <div>
                <Typography variant="h4" className={classes.error}>
                  {passwordChanged.error}
                </Typography>
              </div>
            }

            <div className={classes.buttonGroup}>
              <CustomButton
                className={classes.changePasswordBtn}
                disabled={!valid || !validConfirmation}
                mode="primary"
                type="submit"
                label={"Change password"}
              />
            </div>
          </Form>
        </Formik>
      </DialogContent>
    </Modal>
  );
};
