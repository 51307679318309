import * as React from 'react';
import { withStyles, makeStyles } from '@material-ui/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { createMarkup } from '../../utils/generic';
import * as colors from '../../theme/colors';

const useStyles = makeStyles(() => ({
  error: {
    color: colors.redA400,

    "& > p": {
      marginBottom: 0,
      marginLeft: 14,
      marginTop: 3,
    },
    '& a': {
      color: colors.darkThemeGreen,
    },
  },
}));

export const StyledFormHelper = withStyles({
  root: {
    "& > p": {
      marginBottom: 0
    }
  }
})(props => {
  let {helperText, isError, isHtml, errorMessageId} = props;
  const classes = useStyles();
  const label = helperText || "helper";

  return isHtml 
  ? <FormHelperText id={errorMessageId} role="region" aria-label={label} aria-live="polite" dangerouslySetInnerHTML={createMarkup(helperText)} className={isError ? classes.error : ""} />
  : <FormHelperText id={errorMessageId} role="region" aria-label={label} aria-live="polite" className={isError ? classes.error : ""}>{helperText}</FormHelperText>
}
);