import React from "react";

import {
  createTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import * as colors from "../../theme/colors";

const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: ".875em",
        borderRadius: 5,
        padding: '5px 8px',
        backgroundColor: colors.darkBlue,
        color: '#fff',
        visibility: 'visible',
        opacity: 1,
      },
    },
  },
});

export const MobileTooltip = (props) => {
  const {open, title, onOpen, onClose, children} = props;
  return (
    <MuiThemeProvider theme={theme}>
      <Tooltip
        open={open}
        title={title}
        onOpen={onOpen}
        onClose={onClose}
      >
        {children}
      </Tooltip>
    </MuiThemeProvider>
  );
};
