import React, { useEffect, useState } from "react";
import { push as pushToRoute } from "connected-react-router";
import * as momentTimezone from "moment-timezone";
import * as moment from "moment";
import qs from "query-string";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import EventDataGrid from "./eventsGrid/EventDataGrid";
import CalendarSection from "./calendarSection/index";
import * as userProfileActions from "../../actions/userProfile";
import * as globalActions from "../../actions/global";
import { EventSchedulerModal } from "../../components/modals/eventScheduler/eventSchedulerModal";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Box } from "@material-ui/core";
import CustomButton from "../../components/customButton/customButton";
import CalendarToday from "@material-ui/icons/CalendarToday";
import SkeletonCalendar from "./eventsGrid/skeletonCalendar";

import { connectTo } from "../../utils/generic";
import * as authActions from "../../actions/auth";
import * as calendarActions from "../../actions/calendar";
import LeftNavigationMenu from "./leftMenu/leftNavMenu";
import PageTitle from "../../components/page-title/pageTitle";
import * as programsApi from "../../api/programs";

import { useTitle } from "../../utils/pageTitles";
import { PageLeaveModal } from '../../components/modals/pageLeaveWarning';
import Roles from "../../utils/permissions";
import { DATE_FORMAT } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  main: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  contentBox: {
    display: "block",
    margin: "auto",
    justifyContent: "center",
  },
  titleBox: {
    display: "inline-flex",
    alignItems: "center",
    width: "100%",
    margin: "15px 0",
  },
  mobileEventSection: {
    width: "100%",
    maxWidth: 1200,
    margin: "0 auto",
    padding: "0 16px",
  },
  eventSection: {
    minWidth: "90%",
    margin: "0 auto",
    [theme.breakpoints.up("md")]: {
      minWidth: "50%",
    },
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 44,
    margin: "15px 0",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  calendarSectionWrapper: {
    width: "min-content",
    margin: "0 auto",
  },
  scheduleBtn: {
    minWidth: "max-content",
    [theme.breakpoints.down("md")]: {
      "& .MuiButton-endIcon": {
        margin: "0 5px",
      },
    },
  },
  loadMoreBtn: {
    marginTop: 10,
  }
}));

// TO DO
const programColors = [
  "#7A7A78",
  "#0D9C90",
  "#0D38D3",
  "#EA3850",
  "#FA7815",
  "#FDD946",
  "#D7F9F4",
  "#0169E1",
  "#F37C6F",
  "#F9B88C",
  "#A31746",
  "#0C6B68",
  "#003046",
];

const getCurrDate = (dateObj) => {
  var month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  var date = ("0" + dateObj.getDate()).slice(-2);
  var year = dateObj.getFullYear();
  return year + "-" + month + "-" + date;
};

const Calendar = ({
  auth: { user },
  contentful,
  loadCalendarsData,
  updateEventData,
  createEventData,
  changeUserInfo,
  deleteEvent,
  getEventDetailsInfo,
  isLoadingSingleEventDetails,
  pageNumber,
  loadMore,
  events,
  calendarLabels,
  isLoading,
  isLoaded,
  isMobile,
  history: {
    location: { search },
  },
  push,
}) => {
  useTitle("calendar");
  
  const classes = useStyles();
  const theme = useTheme();
  const url = qs.parse(search);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [userTimeZone, setUserTimeZone] = useState(momentTimezone.tz.guess());
  const [hamburgerMenuVisible, setHamburgerMenuVisible] = React.useState(null);
  const [eventScheduleModal, setEventScheduleModal] = useState(false);
  const [canSchedule, setCanSchedule] = useState(false);
  const [filters, setFilters] = useState({
    programIds: url && url.programIds ? [parseInt(url.programIds, 10)] : [],
    showPublic: url && url.programIds ? false : true,
  });
  const [programs, setPrograms] = useState([]);
  const [programsFacilitatorOrViewer, setProgramsFacilitatorOrViewer] = useState([]);
  const [programMembers, setProgramMembers] = useState([]);
  const [isLoadingProgramMembers, setIsLoadingProgramMembers] = useState(false);
  const [isEscapeConfirmDialog, setEscapeConfirmDialog] = useState(false);
  const [isClosedCreateEventModal, setCloseCreateEventModal] = useState(false);

  const [eventViewModal, setEventViewModal] = useState(false);
  const [currentItemIndex, setCurrentItemIndex] = useState(-1);

  useEffect(() => {
    if (isLoaded === true) {
      setEscapeConfirmDialog(false);
      setCloseCreateEventModal(false);
      setEventScheduleModal(false);
      setEventViewModal(false);
      setCurrentItemIndex(-1);
    }
  }, [isLoaded]);

  useEffect(() => {
    if ((!user || !contentful.hqConfig) && !events.length) return;
    const {
      hqConfig: { defaultProgram },
    } = contentful;

    const defaultProgramId = parseInt(defaultProgram);
    const userProgramRoles = user.roles.filter(
      (role) =>
        role.programId && role.programId !== defaultProgramId && !!role.program
    );

    const userProgramsList = userProgramRoles
        .filter((e) => e.program && !e.program.completed && e.program.active);

    const userPrograms = userProgramsList.map((e) => {
      return {...e.program, roleId: e.roleId}
    });

    setPrograms(userPrograms);

    const programsFacilitatorOrViewer = userPrograms
        .filter((e) => {
          return [Roles.Facilitator, Roles.Viewer].includes(e.roleId);
        });

    setProgramsFacilitatorOrViewer(programsFacilitatorOrViewer);

    const userProgramIds = userPrograms ? userPrograms.map(e => e.id) : [];
    loadCalendarsData({
      startDate: getCurrDate(selectedDate), pageNumber: 1, filters: {
        showPublic: true,
        programIds: url && url.programIds ? [parseInt(url.programIds, 10)] : userProgramIds,
      }
    });
    const isFacilitatorOrViewer = userProgramRoles.find(
      (e) => e.roleId === 1 || e.roleId === 12 || e.roleId === 3
    )
      ? true
      : false;

    setCanSchedule(isFacilitatorOrViewer);

    if (user.timezone) {
      setUserTimeZone(user.timezone);
    }
  }, [user, contentful]);

  const changedDate = (date) => {
    setSelectedDate(date);
    loadCalendarsData({ startDate: getCurrDate(date), pageNumber: 1, filters });
  };

  const changeFilters = (filters) => {
    setFilters(filters);
    loadCalendarsData({ startDate: getCurrDate(selectedDate), pageNumber: 1, filters });
  };

  const normalMode = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    setHamburgerMenuVisible(isMobile || !normalMode);
  }, [normalMode, isMobile]);

  useEffect(() => {
    if (!programs) return;
    const programIds = programs.map((e) => e.id);
    if (!url || !url.programIds) {
      setFilters({
        ...filters,
        programIds,
      });
    }
  }, [programs]);

  const loadEventAdditionalInfo = (item) => {
    getEventDetailsInfo(item);
  };

  /* eslint-disable */
  const loadProgramMembers = (programIds, eventId) => {
    setIsLoadingProgramMembers(true);
    Promise.all(programIds.map((id) => {
      return programsApi
        .getProgramMembers({ id: parseInt(id) });
    })).then(values => {
      const programMembersList = { ...programMembers };

      values.map(e => {
        if (!!e.data) {
          programMembersList[e.data[0].programId] = e.data;
        }
      });
      setProgramMembers(programMembersList);
      setIsLoadingProgramMembers(false);
    })
      .catch(error => {
        setIsLoadingProgramMembers(false);
      });
  };

  const updateTimezone = ({ timezone }) => {
    const birthDate =
      user.birthDate && !isNaN(new Date(user.birthDate).getDate())
        ? moment.parseZone(user.birthDate).format(DATE_FORMAT)
        : user.birthDate;
    changeUserInfo({
      data: {
        ...user,
        birthDate,
        timezone,
      },
    });
    setUserTimeZone(timezone);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Box className={classes.main}>
        <Box className={classes.contentBox}>
          <Box style={{ display: "flex", margin: "auto" }}>
            {hamburgerMenuVisible ? (
              <LeftNavigationMenu>
                <CalendarSection
                  programs={programs}
                  selectedDate={selectedDate}
                  setSelectedDate={changedDate}
                  filters={filters}
                  setFilters={changeFilters}
                  programColors={programColors}
                  timezone={userTimeZone}
                  updateTimezone={updateTimezone}
                  labels={calendarLabels}
                  isLoading={isLoading}
                />
              </LeftNavigationMenu>
            ) : (
              <>
                <Box className={classes.calendarSectionWrapper}>
                  <Box className={classes.titleBox}>
                    <PageTitle title="My Calendar" isLoading={isLoading} />
                  </Box>
                  <Box>
                    <CalendarSection
                      programs={programs}
                      selectedDate={selectedDate}
                      setSelectedDate={changedDate}
                      filters={filters}
                      setFilters={changeFilters}
                      programColors={programColors}
                      timezone={userTimeZone}
                      updateTimezone={updateTimezone}
                      labels={calendarLabels}
                      isLoading={isLoading}
                    />
                  </Box>
                </Box>
              </>
            )}

            <Box
              className={
                isMobile ? classes.mobileEventSection : classes.eventSection
              }
            >
              <Box className={classes.titleWrapper}>
                {!normalMode && (
                  <PageTitle title="My Calendar" isLoading={isLoading} />
                )}
                <Box>
                  {canSchedule && (
                    <CustomButton
                      mode="primary"
                      type="submit"
                      label={normalMode ? "Schedule event" : ""}
                      className={classes.scheduleBtn}
                      endIcon={<CalendarToday />}
                      onClick={() => {
                        setEventScheduleModal(true);
                      }}
                    />
                  )}
                </Box>
              </Box>
              {(isLoading && !events?.length) ?
              <><SkeletonCalendar count={4}/></>
              : 
              <EventDataGrid
                user={user}
                userTimeZone={userTimeZone}
                events={events}
                selectedDate={selectedDate}
                programs={programs}
                programColors={programColors}
                timezone={userTimeZone}
                filters={filters}
                updateEventData={updateEventData}
                createEventData={createEventData}
                deleteEvent={deleteEvent}
                labels={calendarLabels}
                loadEventAdditionalInfo={loadEventAdditionalInfo}
                isLoadingSingleEventDetails={isLoadingSingleEventDetails}
                loadProgramMembers={loadProgramMembers}
                programMembers={programMembers}
                isLoadingProgramMembers={isLoadingProgramMembers}
                push={push}
                isFacilitator={canSchedule}
                setCloseCreateEventModal={setCloseCreateEventModal}
                isClosedCreateEventModal={isClosedCreateEventModal}
                setEscapeConfirmDialog={setEscapeConfirmDialog}
                setEventViewModal={setEventViewModal}
                eventViewModal={eventViewModal}
                setCurrentItemIndex={setCurrentItemIndex}
                currentItemIndex={currentItemIndex}
              />
              }
              {loadMore && (
                  <CustomButton
                    mode="primary"
                    label={"Load more"}
                    className={classes.loadMoreBtn}
                    disabled={isLoading}
                    onClick={() => {
                      loadCalendarsData({
                        startDate: getCurrDate(selectedDate),
                        pageNumber: pageNumber + 1,
                        filters,
                      });
                    }}
                  />
                )}
            </Box>
          </Box>
          <EventSchedulerModal
            programs={programsFacilitatorOrViewer}
            isOpen={eventScheduleModal}
            timezone={userTimeZone}
            createEvent={(data) => {
              createEventData(data);
            }}
            onCloseModal={(event) => {
              if (event?.key === "Escape" && isClosedCreateEventModal ||
              isClosedCreateEventModal) {
                setEscapeConfirmDialog(true);
                return;
              }

              setEventScheduleModal(false);
            }}
            loadProgramMembers={loadProgramMembers}
            programMembers={programMembers}
            isLoadingProgramMembers={isLoadingProgramMembers}
            setCloseCreateEventModal={setCloseCreateEventModal}
            isClosedCreateEventModal={isClosedCreateEventModal}
            setEscapeConfirmDialog={setEscapeConfirmDialog}
            countryId={user?.countryId}
          />
          <PageLeaveModal
            onCancel={() => { setEscapeConfirmDialog(false) }}
            onConfirm={() => {
              setCloseCreateEventModal(false);
              setEventScheduleModal(false);
              setEscapeConfirmDialog(false);

              setEventViewModal(false);
              setCurrentItemIndex(-1);
            }}
            open={(isEscapeConfirmDialog && !isLoading)}
          />
        </Box>
      </Box>
    </div>
  );
};

export default connectTo(
  (state) => {
    return {
      auth: state.auth,
      isMobile: state.global.isMobile,
      contentful: state.contentful,
      ...state.calendar,
    };
  },
  {
    ...authActions,
    ...calendarActions,
    ...globalActions,
    ...userProfileActions,
    push: pushToRoute,
  },
  Calendar
);
