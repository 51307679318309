import React from 'react';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import CodeIcon from '@material-ui/icons/Code';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import Typography from '@material-ui/core/Typography';
import { Editor, RichUtils, EditorState, Modifier, getDefaultKeyBinding } from 'draft-js';
import { getEntityRange, getSelectionEntity } from "draftjs-utils";
import editorStyles from '../styles/editorStyles';
import LinkPopper from './LinkPopper';
import {
  getLinksInsideBlock,
  updateSelection,
  getUrlFromEntity,
  getFirstLinkInRange,
  isMultiLineSelection,
  insertText,
  linkRegex,
} from '../utils';
import 'draft-js/dist/Draft.css';
import {
  linkPopperInitialValues,
  ENTITY_TYPES,
  EDITOR_BLOCK_TYPES,
  EDITOR_INLINE_STYLES,
  EDITOR_KEYBOARD_STATUS,
} from '../constants';

class DraftEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      showPopper: false,
      linkInitialValues: linkPopperInitialValues,
      removeScroll: this.props.removeScroll,
      ref: React.createRef(),
      hasFocus: false,
    };
  }

  handleBeforeInput = (text) => {
    if (this.props.maxLength === -1) return EDITOR_KEYBOARD_STATUS.NotHandled;
    const totalLength = this.props.value.getCurrentContent().getPlainText().length + text.length;

    return totalLength <= this.props.maxLength
      ? EDITOR_KEYBOARD_STATUS.NotHandled
      : EDITOR_KEYBOARD_STATUS.Handled;
  };

  handlePastedText = (text) => {
    const totalLength = this.props.value.getCurrentContent().getPlainText().length + text.length;

    if (this.props.maxLength === -1 || totalLength <= this.props.maxLength) {
      this.props.onChange(insertText(this.props.value, text));
    }

    return EDITOR_KEYBOARD_STATUS.Handled;
  };


  customKeyBinding = (e) => {
    // keyCode 9 === 'Tab'
    if (e.keyCode === 9) {
      this.onTab(e);
      return null;
    }
    return getDefaultKeyBinding(e);
  };

  onTab = (event) => this.props.onChange(RichUtils.onTab(event, this.props.value, 1));

  handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.props.onChange(newState);
      return EDITOR_KEYBOARD_STATUS.Handled;
    }

    return EDITOR_KEYBOARD_STATUS.NotHandled;
  };

  handleReturn(e) {
    e.preventDefault();
    return true;
  }

  onBoldClick = (e) => {
    e.preventDefault();
    this.props.onChange(RichUtils.toggleInlineStyle(this.props.value, EDITOR_INLINE_STYLES.Bold));
  };

  onItalicClick = (e) => {
    e.preventDefault();
    this.props.onChange(RichUtils.toggleInlineStyle(this.props.value, EDITOR_INLINE_STYLES.Italic));
  };

  onUnderlineClick = (e) => {
    e.preventDefault();
    this.props.onChange(RichUtils.toggleInlineStyle(this.props.value, EDITOR_INLINE_STYLES.Underline));
  };

  onCodeClick = (e) => {
    e.preventDefault();
    this.props.onChange(RichUtils.toggleInlineStyle(this.props.value, EDITOR_INLINE_STYLES.Code));
  };

  onUnorderedListClick = (e) => {
    e.preventDefault();
    this.props.onChange(RichUtils.toggleBlockType(this.props.value, EDITOR_BLOCK_TYPES.UnorderedList));
  };

  onOrderedListClick = (e) => {
    e.preventDefault();
    this.props.onChange(RichUtils.toggleBlockType(this.props.value, EDITOR_BLOCK_TYPES.OrderedList));
  };

  onLinkClicked = (e) => {
    e.preventDefault();
    const { currentTarget } = e;
    const editorState = this.props.value;
    const selectionState = editorState.getSelection();

    let url = '';
    let text = '';

    const anchorKey = selectionState.getAnchorKey();
    const focusKey = selectionState.getFocusKey();
    let anchorOffset = selectionState.getAnchorOffset();
    let focusOffset = selectionState.getFocusOffset();

    const currentContent = editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);

    const selectedEntityKey = currentContentBlock.getEntityAt(anchorOffset);
    const linksInsideBlock = getLinksInsideBlock(currentContent, currentContentBlock);

    if (anchorKey === focusKey) {
      if (!selectionState.isCollapsed()) {
        if (anchorOffset > focusOffset) {
          [anchorOffset, focusOffset] = [focusOffset, anchorOffset];
        }

        const intersection = getFirstLinkInRange({ start: anchorOffset, end: focusOffset }, linksInsideBlock);
        text = currentContentBlock.getText().substring(anchorOffset, focusOffset);

        if (intersection.key) {
          url = getUrlFromEntity(currentContent, intersection.key);
          let selection = editorState.getSelection();

          const entityRange = getEntityRange(
            editorState,
            getSelectionEntity(editorState)
          );

          if (entityRange && entityRange.start && entityRange.end) {
            const isBackward = selection.getIsBackward();
            if (isBackward) {
              selection = selection.merge({
                anchorOffset: entityRange.end,
                focusOffset: entityRange.start
              });
            } else {
              selection = selection.merge({
                anchorOffset: entityRange.start,
                focusOffset: entityRange.end
              });
            }
          }

          const selectedState = updateSelection(editorState, selection);

          text = currentContentBlock.getText().substring(intersection.selection.start, intersection.selection.end);

          this.props.onChange(selectedState);
        }
      } else {
        const blockPosition = linksInsideBlock[selectedEntityKey];

        if (selectedEntityKey) {
          url = getUrlFromEntity(currentContent, selectedEntityKey);
          text = currentContentBlock.getText().substring(blockPosition.start, blockPosition.end);

          const selectedState = updateSelection(editorState, {
            anchorOffset: blockPosition.start,
            focusOffset: blockPosition.end,
          });

          this.props.onChange(selectedState);
        }
      }
    }

    if (text && text.match(linkRegex) && url === '') {
      url = text;
    }

    this.setState(state => ({
      anchorEl: currentTarget,
      showPopper: !state.showPopper,
      linkInitialValues: { text, link: url },
    }));
  };

  onRemoveLinkClicked = (e) => {
    e.preventDefault();
    const selection = this.props.value.getSelection();
    this.props.onChange(RichUtils.toggleLink(this.props.value, selection, null));
  };

  isBold = () => this.props.value.getCurrentInlineStyle().has(EDITOR_INLINE_STYLES.Bold);

  isItalic = () => this.props.value.getCurrentInlineStyle().has(EDITOR_INLINE_STYLES.Italic);

  isUnderline = () => this.props.value.getCurrentInlineStyle().has(EDITOR_INLINE_STYLES.Underline);

  isCode = () => this.props.value.getCurrentInlineStyle().has(EDITOR_INLINE_STYLES.Code);

  isBlockType = (type) => this.props.value.getCurrentInlineStyle().has(type);

  setBlockType = (type) => this.props.onChange(RichUtils.toggleBlockType(this.props.value, type));

  insertLink = (text, link) => {
    this.setState({
      anchorEl: null,
      showPopper: false,
    });

    this.state.ref.current.focus();
    const editorState = this.props.value;

    const currentContent = editorState.getCurrentContent();
    let contentStateWithEntity = currentContent.createEntity(ENTITY_TYPES.LINK, 'MUTABLE', { url: link, text });

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    // replaces selected text with link entity and text
    let selection = editorState.getSelection();
    contentStateWithEntity = Modifier.replaceText(
      currentContent,
      selection,
      text,
      editorState.getCurrentInlineStyle(),
      entityKey
    );

    let newEditorState = EditorState.push(editorState, contentStateWithEntity, "insert-characters");
    const newEditorStateSelection = newEditorState.getSelection();
    const anchorOffset = newEditorStateSelection.getAnchorOffset();
    const focusOffset = newEditorStateSelection.getFocusOffset();
    const targetOffset = anchorOffset > focusOffset ? focusOffset : anchorOffset;

    newEditorState = updateSelection(newEditorState, {
      anchorOffset: targetOffset,
      focusOffset: targetOffset,
    });

    this.props.onChange(newEditorState);
  };

  render() {
    const { classes, error, borderLess, isOpenText, showToolbar, readOnly } = this.props;
    const { removeScroll, hasFocus } = this.state;

    const classList = [];

    classList.push(classNames({ [classes.editor]: !borderLess }, this.props.className,
      isOpenText ? classes.opentTextEditor :
      showToolbar ? classes.editorRoot : classes.editorCommentRoot));

    if (error) {
      classList.push(classes.error);
    }

    if (removeScroll) {
      classList.push(classes.removeScroll);
    }
    if(!showToolbar) {
      classList.push(classes.commentInput);
    }
    if(!!isOpenText) {
      classList.push(classes.openTextInput);
    }

    const currentContent = this.props.value.getCurrentContent();

    if(!!hasFocus && !!isOpenText) {
      classList.push(classes.opentTextGreenBorder);
    }

    // check to show placeholder text in intput
    // additional check added because in Draft empty editor state is not empty, it contains span block with 'unstyled' type
    // hidePlaceHolder class contains display 'none' for Draft Editor Placeholder
    if (!currentContent.hasText() && currentContent.getBlockMap().first().getType() !== 'unstyled') {
      classList.push(classes.hidePlaceHolder);
    }

    const styleMap = {
      CODE: {
        backgroundColor: "#eeeeee",
        padding: 2,
      },
    };

    return (
      <div className={classList.join(' ')} name={this.props.name} id="editor-root">
        {!readOnly && showToolbar && (
          <>
            <Tooltip title="Bold">
              <IconButton
                disableRipple
                onMouseDown={e => this.onBoldClick(e)}
                classes={{ root: this.isBold() ? classes.enabled : classes.editorIcon }}>
                <FormatBoldIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Italic">
              <IconButton
                disableRipple
                onMouseDown={e => this.onItalicClick(e)}
                classes={{ root: this.isItalic() ? classes.enabled : classes.editorIcon }}>
                <FormatItalicIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Underline">
              <IconButton
                disableRipple
                onMouseDown={e => this.onUnderlineClick(e)}
                classes={{ root: this.isUnderline() ? classes.enabled : classes.editorIcon }}>
                <FormatUnderlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Code">
              <IconButton
                disableRipple
                onMouseDown={e => this.onCodeClick(e)}
                classes={{ root: this.isCode() ? classes.enabled : classes.editorIcon }}>
                <CodeIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Unordered List">
              <IconButton
                disableRipple
                onMouseDown={e => this.onUnorderedListClick(e)}
                classes={{ root: classes.editorIcon }}>
                <FormatListBulletedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Ordered List">
              <IconButton
                disableRipple
                onMouseDown={e => this.onOrderedListClick(e)}
                classes={{ root: classes.editorIcon }}>
                <FormatListNumberedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Insert Link">
              <IconButton
                disableRipple
                onMouseDown={e => this.onLinkClicked(e)}
                disabled={isMultiLineSelection(this.props.value)}
                classes={{ root: classes.editorIcon }}>
                <LinkIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
        <Editor
          ref={this.state.ref}
          onBlur={() => this.setState({ hasFocus: false })}
          onFocus={() => this.setState({ hasFocus: true })}
          keyBindingFn={this.customKeyBinding}
          handleKeyCommand={this.handleKeyCommand}
          editorState={this.props.value}
          onChange={this.props.onChange}
          handleBeforeInput={this.handleBeforeInput}
          handlePastedText={this.handlePastedText}
          readOnly={this.props.readOnly}
          handleDrop={() => EDITOR_KEYBOARD_STATUS.NotHandled}
          placeholder={this.props.placeholder}
          handleReturn={this.props.handleReturn ? this.handleReturn : null}
          customStyleMap={styleMap}
        />
        {showToolbar && this.props.value.getCurrentContent().getPlainText().length >= this.props.maxLengthStartingFrom && <Typography variant="caption">
          {`Characters remaining: ${this.props.maxLength - this.props.value.getCurrentContent().getPlainText().length}`}
        </Typography>}
        <LinkPopper
          initialValues={this.state.linkInitialValues}
          show={this.state.showPopper}
          anchorEl={this.state.anchorEl}
          submit={this.insertLink}
          removeLink={this.onRemoveLinkClicked}
          handleClose={() => {
            this.setState({
              showPopper: false,
              anchorEl: null,
            });
            this.state.ref.current.focus();
          }}/>
      </div>
    );
  }
}

export default withStyles(editorStyles)(DraftEditor);
