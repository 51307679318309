import image from '../../graphql/types/Image';

const membersPageMessaging = `
  name
  downloadGeneratedUsersDataText
  downloadGeneratedUsersDataLabel
  groupOfStudents {
    ${image}
  }
  consentAgreementModalText
`;

export default membersPageMessaging;
