import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';

import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Grid from "@material-ui/core/Grid";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import * as colors from "../../../../theme/colors";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "130px",
    margin: "0 10px",
    display: "flex",
    fontFamily: "'Roboto Mono',monospace",
    "&:hover": {
      // backgroundColor: colors.greenA350,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  labelButton: {
    backgroundColor: "transparent",
    color: colors.dartThemeBlueGray,
    display: "inline-flex",
    fontFamily: "'Roboto Mono',monospace",
    fontSize: 14,
    whiteSpace: "nowrap",
    fontWeight: 500,
    border: `1px solid #b8b8b8`,

    "&:hover": {
      border: `1px solid ${colors.greenA350}`,
      color: colors.darkThemeGreen,
    },
  },
  labelActive: {
    border: `1px solid ${colors.greenA350}`,
    color: colors.darkThemeGreen,
  },
  item: {
    backgroundColor: "white",
    fontFamily: "'Roboto Mono',monospace",
    fontSize: 14,
    fontWeight: 500,
    "& .MuiTypography-body1": {
      color: colors.dartThemeBlueGray,
      fontFamily: "'Roboto Mono',monospace",
      fontSize: 14,
      fontWeight: 500,
    },
  },
  icon: {
    color: colors.darkThemeGreen,
  },
  paperContainer: {
    zIndex: 11,
    backgroundColor: "#fff",
    borderRadius: 16,
    border: "1px solid #bbbbbf",
    alignItems: "center",
    "& div": {
      borderRadius: "inherit",
      padding: 1,
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: colors.greenA300,
    },
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const MultipleSelect = ({ label, values, filters, onSelectChange, className }) => {
  const classes = useStyles();
  const data = values.map((el) => {
    if (filters[el.filterName][el.attribute]) {
      return el.label;
    }
  });

  const [personName, setPersonName] = React.useState(data || []);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  React.useEffect(() => {
    if (!values || !filters) {
      return;
    }
    const data = values.map((el) => {
      if (filters[el.filterName][el.attribute]) {
        return el.label;
      }
    });
    setPersonName(data);
  }, [filters]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={clsx(classes.container, className)}
    >
      <Grid item xs={12}>
        <Button
          className={open ? clsx(classes.labelActive, classes.labelButton) : classes.labelButton}
          size="small"
          ref={anchorRef}
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <span>{label}</span>
          {open ? <ExpandLessIcon className={classes.icon} /> : <ExpandMoreIcon className={classes.icon} />}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          className={classes.paperContainer}
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {values.map((el) => (
                      <MenuItem
                        key={el.label}
                        value={el.label}
                        className={classes.item}
                        onClick={(event) => {
                          onSelectChange(el.filterName, el.attribute)(event);
                        }}
                      >
                        <Checkbox checked={personName.indexOf(el.label) > -1} />
                        <ListItemText primary={el.label} />
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};

export default MultipleSelect;
