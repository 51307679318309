import React, { useState, useEffect, useLayoutEffect, memo, forwardRef, useImperativeHandle } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from "yup";

import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Box from '@material-ui/core/Box';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Grid from "@material-ui/core/Grid";

import * as colors from '../../theme/colors';
import CustomButton from '../../components/customButton/customButton';
import PageTitle from "../../components/page-title/pageTitle";

import FirstStep from './applayFormSteps/firstStep';
import SecondStep from './applayFormSteps/secondStep';
import ThirdStep from './applayFormSteps/thirdStep';

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  mainApply: {
    width: "100%",
    margin: "auto",
    height: "100%",
    minHeight: 'calc(100vh - 135px)',
  },
  pageContainer: {
    margin: "auto",
    justifyContent: "center",
    maxWidth: 500,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 2,
    padding: '26px',
    background: colors.white,
    boxShadow: "0px 1px 4px 0px rgba(0,0,0,0.2)",
    marginBottom: 20,
  },
  form: {
    paddingTop: 20,
  },
  formGroup: {
    height: '90%',
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  btnContainer: {
    marginTop: 20,
  },
  stepWrapper: {
    '& .MuiPaper-root': {
      backgroundColor: 'inherit',
    },
    '& .MuiStepLabel-label.MuiStepLabel-active': {
      color: colors.darkThemeGreen,
    },
    '& .MuiStepIcon-active': {
      color: colors.darkThemeGreen,
    },
  },
  stepWrapperCompleted: {
    '& .MuiStepIcon-root': {
      color: colors.darkBlue100,
    },
    '& .MuiStepLabel-alternativeLabel': {
      color: colors.darkBlue100,
    },
  },
  submitButtonBox: {
    marginLeft: "auto",
  },
  submitButtonBoxCenter: {
    margin: "auto",
  },
  submitButton: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  headerThanks: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 8,
    '& h1': {
      fontSize: 32,
      textAlign: "center",
    },
  },
  headerApply: {
    marginBottom: 8,
  }
}));

const getCurrentPage = (
  page,
  user,
  program,
  setPage,
  affilationTypes,
  affilationType,
  facilitatorApplicationIcon,
  isSubmitStarted,
  isLoading,
  hostSiteAffilationTypes,
  domesticCountry,
  setProgram,
  setSubmitStarted,
  isLoadingProgram,
  cleanClubInfoByCode,
  defaultProgramId,
) => {
  switch (page) {
    case 0:
      return (
        <FirstStep
          user={user}
          program={program}
          isSubmitStarted={isSubmitStarted}
          isLoading={isLoadingProgram || isLoading}
          domesticCountry={domesticCountry}
          defaultProgramId={defaultProgramId}
        />
      );
    case 1:
      return (
        <SecondStep
          program={program}
          setPage={setPage}
          affilationTypes={affilationTypes}
          affilationType={affilationType}
          backgroundCheckCurrentInit={user.backgroundCheckCurrentInit}
          hostSiteAffilationTypes={hostSiteAffilationTypes}
          setProgram={setProgram}
          setSubmitStarted={setSubmitStarted}
          cleanClubInfoByCode={cleanClubInfoByCode}
        />);
    case 2:
      return (
        <ThirdStep
          icon={facilitatorApplicationIcon}
        />
      );
    default:
      return <></>;
  }
};

const ApplyForm = forwardRef((
  {
    user,
    push,
    applyToFacilitate,
    isLoadingApplicationToFacilitate,
    isAppliedToFacilitate,
    facilitatorApplicationIcon,
    getClubInfoByCode,
    searchProgram,
    domesticCountry,
    isLoadingProgram,
    cleanClubInfoByCode,
    defaultProgramId,
  },
  applyRef
) => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [program, setProgram] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [codeClub, setCodeClub] = useState();
  const [affilationType, setAffilationType] = useState('');
  const [isSubmitStarted, setSubmitStarted] = useState(false);

  const ref = React.useRef(null);
  useImperativeHandle(applyRef, () => ({ getCodeClub: () => codeClub && page !== 2 }), [ codeClub, page ]);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView(true);
    }
    setDisabledSubmit(page === 1 && !affilationType.length);
  }, [page]);

  useEffect(() => {
    if (Object.keys(searchProgram || {}).length) {
      setProgram(searchProgram);
      if (searchProgram.id !== defaultProgramId) {
        setPage(page + 1);
      }
    };

    return () => {
      setProgram({});
    }
  }, [searchProgram, isLoadingProgram]);
  
  const getProgramByClubCode = () => {
    if (!codeClub) return;
    getClubInfoByCode({ programCode: codeClub })
  };

  const applyForFacilitator = (affilationType, codeClub) => {
    setIsLoading(true);
    applyToFacilitate({
      programCode: codeClub,
      data: {
        hostSiteAffiliation: affilationType,
        programId: program.id,
      }
    });
    setIsLoading(false);
  };

  useEffect(() => {
    if (page === 1 && isAppliedToFacilitate && !isLoadingApplicationToFacilitate) {
      setPage(page + 1);
    }
  }, [page, isAppliedToFacilitate]);

  // scroll to top after page changing
  useLayoutEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  }, [page])


  const returnToHome = () => {
    setPage(0);
    push('/home');
  }

  const steps = [
    "Search",
    "Confirm",
  ];

  const hostSiteAffilationTypes = {
    "I am an employee": "Employee",
    "I am an external volunteer /not an employee": "Other"
  };
  const affilationTypes = Object.keys(hostSiteAffilationTypes);

  const initialValues = {
    clubCode: "",
    affilationType: "",
  };

  const handleSubmit = () => {
    if (page === 0) {
      setSubmitStarted(true);
      getProgramByClubCode();
    }
    if (page === 1) {
      applyForFacilitator(affilationType, codeClub);
    }
  };
  return (
    <>
      <main className={classes.mainApply}>
        <Grid
          item
          xs={12}
          md={7}
          className={classes.pageContainer}
        >
          <Box>
            <Box className={page === 2 ? classes.headerThanks : classes.headerApply }>
              <PageTitle title={page === 2 ? "Thank you for applying!" : "Apply to facilitate"} />
            </Box>
            <Box className={classes.wrapper}>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validateOnMount={false}
                validationSchema={Yup.object({
                  clubCode: Yup.string().required("Required"),
                })}
                validate={(values) => {
                  let errors = {};
                  setSubmitStarted(false);
                  if (values.clubCode?.length > 10) {
                    const msg = 'wrong length';
                    setDisabledSubmit(true);
                    errors.clubCode = msg;
                  } else {
                    setCodeClub(values.clubCode.trim());
                  }
                  setDisabledSubmit(false);
                  if (values.affilationType) {
                  setAffilationType(hostSiteAffilationTypes[values.affilationType]);
                  }
                  if (values.affilationType.length && page === 1) {
                    setDisabledSubmit(false);
                  }
                  return errors;
                }}
                onSubmit={handleSubmit}
              >{() => (
                <Form className={classes.form} ref={ref}>
                  <Box className={classes.formGroup}>
                    <Box className={classes.stepWrapper}>
                      <Stepper activeStep={page} alternativeLabel={true}>
                        {steps.map((label, index) => (
                            <Step key={label} completed={false}>
                              <StepLabel className={page > index && classes.stepWrapperCompleted}> {label} </StepLabel>
                            </Step>
                        ))}
                      </Stepper>
                    </Box>
                    {getCurrentPage(
                      page,
                      user,
                      program,
                      setPage,
                      affilationTypes,
                      affilationType,
                      facilitatorApplicationIcon,
                      isSubmitStarted,
                      isLoading,
                      hostSiteAffilationTypes,
                      domesticCountry,
                      setProgram,
                      setSubmitStarted,
                      isLoadingProgram,
                      cleanClubInfoByCode,
                      defaultProgramId,
                    )}
                  </Box>
                  <Box className={classes.btnContainer}>
                    <Box className={classes.btnWrapper}>
                      <Box className={page === 2 ? classes.submitButtonBoxCenter : classes.submitButtonBox}>
                        {page < 2 && (
                            <CustomButton
                              mode="primary"
                              disabled={isLoadingProgram || isLoading || disabledSubmit}
                              type="submit"
                              className={classes.submitButton}
                              label={page < 1 ? 'Continue' : 'Submit'}
                            />
                        )}
                        {page === 2 && (
                          <CustomButton
                            mode="primary"
                            onClick={returnToHome}
                            label='Back to my programs'
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Form>
              )}
              </Formik>
            </Box>
          </Box>
        </Grid>
      </main>
    </>
  );
});
export default memo(ApplyForm);
