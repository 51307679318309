import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography
} from "@material-ui/core/";

import { HQModal } from "../HQModal";
import TextCustomButton from "../../text-button/text-button";
import { InviteFacilitatorsListModal } from "./InviteFacilitatorsListModal";
import { InviteFacilitatorsFromProgramsModal } from "./inviteFacilitatorsFromProgramsModal";
import { ColorTheme } from '../../../utils/colorTheme';
import { PageLeaveModal } from "../pageLeaveWarning";

const useStyles = makeStyles({
  subTitle: {
    color: ColorTheme.blue4.b,
    fontSize: 18,
    marginBottom: 20,
    fontWeight: "bold",
  },
  sectionBtn: {
    padding: 0,
    textAlign: 'left'
  },
  sectionBox: {
    marginTop: 15,
  },
  sectionDesc: {
    fontSize: 14
  },
  inviteOptionsBox: {
    marginBottom: 20
  }
});

export const InviteFacilitatorsModal = ({
  isOpen,
  onCloseModal,
  programs,
  club,
  programMembers,
  isLoadingProgramMembers,
  loadProgramMembers,
  role,
  showSuccessMessage,
  showErrorMessage,
  sendUserInvitation,
  createBulkFacilitatorRoles,
  getMembers,
  currentClubMembers,
  user,
  getClubInfo,
  defaultProgram,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [isDataChanged, setDataChanged] = useState(false);
  const [isEscapeConfirmDialog, setEscapeConfirmDialog] = useState(false);

  const handleChangedDataFromChildModal = (value) => {
    setDataChanged(value);
  }

  return (
    <HQModal title="Add Facilitators" 
      titleOptions={{isUpperCase: true, isBold: true}}
      isOpen={isOpen} 
      onClose={() => isDataChanged ? setEscapeConfirmDialog(true) : onCloseModal()}>
      <>
        {page === 1 && (
          <InviteFacilitatorsListModal
            sendUserInvitation={sendUserInvitation}
            showSuccessMessage={showSuccessMessage}
            showErrorMessage={showErrorMessage}
            role={role}
            user={user}
            onBack={() => setPage(0)}
            onCloseModal={onCloseModal}
            onDataChange={handleChangedDataFromChildModal}
          />
        )}
        {page === 2 && (
          <InviteFacilitatorsFromProgramsModal
            programs={programs}
            programMembers={programMembers}
            club={club}
            loadProgramMembers={loadProgramMembers}
            isLoadingProgramMembers={isLoadingProgramMembers}
            createBulkFacilitatorRoles={createBulkFacilitatorRoles}
            getMembers={getMembers}
            onBack={() => setPage(0)}
            currentClubMembers={currentClubMembers}
            onCloseModal={onCloseModal}
            getClubInfo={getClubInfo}
            defaultProgram={defaultProgram}
            onDataChange={handleChangedDataFromChildModal}
          />
        )}
        {!page && (
          <Box className={classes.inviteOptionsBox}>
            <Typography id="dialog-description" className={classes.subTitle}>
              Choose how to add new Facilitators:
            </Typography>
            <Box className={classes.sectionBox}>
              <TextCustomButton
                className={classes.sectionBtn}
                isArrowWave={true}
                label={"Send Email Invites"}
                onClick={() => setPage(1)}
              />
              <Typography className={classes.sectionDesc}>
                This option is best for Facilitators that are new to Girls Who Code HQ.
              </Typography>
            </Box>
            {programs && programs.length > 0 && (
              <Box className={classes.sectionBox}>
                <TextCustomButton
                  className={classes.sectionBtn}
                  isArrowWave={true}
                  label={"Add From Another Program"}
                  onClick={() => setPage(2)}
                />
                <Typography className={classes.sectionDesc}>
                  Easily transfer returning Facilitators from previous Programs, as long as their Background Check is current.
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </>
      <PageLeaveModal
        onConfirm={() => { setDataChanged(false); setEscapeConfirmDialog(false); setPage(0); }}
        onCancel={() => { setEscapeConfirmDialog(false); }}
        open={isEscapeConfirmDialog}
      />
    </HQModal>
  );
};
