import React from 'react';

import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';

import { HQModal } from "../../components/modals/HQModal";
import { PageLeaveModal } from '../../components/modals/pageLeaveWarning';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import DialogContentText from '@material-ui/core/DialogContentText';

import { PasswordInput } from '../../components/PasswordInput';
import CustomButton from "../../components/customButton/customButton";

import { validatePasswordRules, filterFieldsByKey, filterFieldsWithKey } from '../../utils/generic';

const useStyles = makeStyles({
  alert: {
    marginTop: 28,
  },
  container: {
    padding: 0
  },
  form: {
    width: 440,
    maxWidth: '100%',
  },
  login: {
    margin: '33px auto 0px auto',
  },
  submitContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 22,
    marginTop: 52,
    height: 42,
    width: '100%',
    fontSize: 16,
  },
  button: {
    width: 100,
  },
  greenButton: {
    backgroundColor: '#6eceb2',
    '&:hover': {
      backgroundColor: '#3cb390',
    },
  },
});

const ResetPasswordDialog = (props) => {
  const {
    firstName,
    email,
    username,
    onClose,
    open,
    onSubmit,
    profileContent,
  } = props;

  const classes = useStyles();
  const [passwordContent, setPasswordContent] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [isEscapeConfirmDialog, setEscapeConfirmDialog] = React.useState(false);
  const [isDataChanged, setDataChanged] = React.useState(false);

  React.useEffect(() => {
    if (!profileContent || !profileContent.changePasswordForm) {
      return;
    }
    
    const resetFields = filterFieldsWithKey(profileContent?.changePasswordForm.resetStepFields, "id");
    const newPasswordContent = resetFields.newPassword;
    setPasswordContent(newPasswordContent);
    const newErrors = filterFieldsByKey(newPasswordContent.validationErrors, "messageCode", "message");
    setErrors(newErrors);

  }, [profileContent]);

  const validateNewPassword = value => {
    const validateResult = validatePasswordRules(value, email, username, firstName);
    if (Object.keys(validateResult).length > 0) {
      return errors.weakPassword;
    }
  }
  const title = `Reset ${firstName}'s Password`;

  const validationSchema = Yup.object({
    password: Yup.string()
      .required('Required')
      .min(10, 'Password is too short - should be 10 chars minimum.'),
    confirmPassword: Yup.mixed().required('Required').test('match', 'Passwords do not match', function (value) {
        const { password } = this.parent;
        return value === password;
      }),
  });

  const onCloseResetPasswordModal = () => {
    if (isDataChanged) {
      setEscapeConfirmDialog(true);
    } else {
      setDataChanged(false);
      setEscapeConfirmDialog(false);
      onClose();
    }
  };

  return (
    <HQModal title={title} 
      titleOptions={{maxWidth: 450}}
      isOpen={open} onClose={onCloseResetPasswordModal}>
      <>
        <DialogContentText>
          Ask {firstName} to type a new password
        </DialogContentText>
        <Formik
          initialValues={{
            password: '',
            confirmPassword: '',
          }}
          validate={() => {
            setDataChanged(true);
            return;
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form className={classes.form}>
            <Container className={classes.container}>
              <Box className={classes.login}>
                <Field
                  name="password"
                  component={PasswordInput}
                  label="New password"
                  fromModal={true}
                  content={passwordContent}
                  validate={validateNewPassword}
                  className={classes.marginTop30}
                  email={email}
                  username={username}
                  firstName={firstName}
                  helperText="Please remember to store your password in a safe place."
                />
              </Box>
              <Box className={classes.login}>
                <Field
                  name="confirmPassword"
                  component={PasswordInput}
                  label="Retype password"
                  className={classes.marginTop30}
                />
              </Box>
            </Container>
            <Box className={classes.submitContainer}>
              <CustomButton
                mode={"textButton"}
                onClick={() => isDataChanged ? setEscapeConfirmDialog(true) : onClose() }
                label={"Cancel"}
              />
              <CustomButton
                className={classes.button}
                mode={"primary"}
                type="submit"
                label={"SAVE"}
              />
            </Box>
          </Form>
        </Formik>
      </>
      <PageLeaveModal
        onConfirm={() => { setDataChanged(false); setEscapeConfirmDialog(false); onClose(); }}
        onCancel={() => { setEscapeConfirmDialog(false); }}
        open={isEscapeConfirmDialog}
      />
    </HQModal>
  );
};

export default ResetPasswordDialog;
