import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { createMarkup } from "../../utils/generic";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

import * as colors from "../../theme/colors";
import ProgressCircleIcon from "./ProgressCircle/progressCircleIcon";
import { TooltipWrapper } from "../../components/tooltip/tooltip";
import { RatingIcon } from "../../components/ratingIcon/ratingIcon";
import { Typography, Box, IconButton } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { PROGRESS_VIEW_MEMBER_EXPAND } from '../../constants/trackingEvents';

import SkeletonTable from "../../components/skeletonTable/skeletonTable";

function desc(a, b, orderBy) {
  if (orderBy === "date") {
    if (!b[orderBy] && !a[orderBy]) {
      return 0;
    }
    if (!a[orderBy]) {
      return 1;
    }
    if (!b[orderBy]) {
      return -1;
    }
    return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
  } else if (typeof a[orderBy] === "string") {
    if (b[orderBy] && b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
      return -1;
    }
    if (b[orderBy] && b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  rowHeader: {
    whiteSpace: "nowrap",
    backgroundColor: colors.greyLight2,
    fontSize: "1.1rem",
    fontWeight: "bold",
    color: colors.dartThemeBlueGray,
    lineHeight: "2.5rem",
    width: "20%",
    padding: 5,
    "&:hover": {
      color: colors.darkThemeGreen,
    },
  },
  sortLabel: {
    color: colors.dartThemeBlueGray,
    "&.MuiTableSortLabel-icon": {
      color: colors.dartThemeBlueGray,
      "&:hover": {
        color: colors.darkThemeGreen,
      },
    },
    "&:hover": {
      color: colors.darkThemeGreen,
    },
    "&:focus": {
      outline: `2px dotted ${colors.grey400}`,
    },
  },
  disabledSortLabel: {
    color: colors.dartThemeBlueGray,
    "&.MuiTableSortLabel-icon": {
      color: colors.dartThemeBlueGray,
    },
  },
  rowItem: {
    padding: "5px",
    verticalAlign: "center",
    whiteSpace: "nowrap",
    fontSize: 16,
    fontWeight: "bold",
  },
  blueRow: {
    padding: "5px",
    verticalAlign: "center",
    whiteSpace: "nowrap",
    color: colors.darkThemeBlue,
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "left",
  },
  tableBody: {
    //minHeight: "320px",
  },
  emptyRowsContainer: {
    display: "flex",
  },
  messageBox: {
    width: "80%",
    textAlign: "center",
    margin: "50px 0",
  },
  messageIcon: {
    margin: "50px 0",
    display: "flex",
  },
  messageTitle: {
    width: "85%",
    margin: "auto",
    fontSize: 30,
    fontWeight: 600,
    color: colors.dartThemeBlueGray,
  },
  messageContent: {
    width: "85%",
    margin: "auto",
    color: colors.dartThemeBlueGray,
    fontWeight: 600,
    "& a": {
      color: colors.darkThemeGreen,
      textDecoration: "none",
    },
  },
  nestedHeader: {
    fontWeight: 600,
    backgroundColor: colors.greyLight2,
    fontWeight: "bold",
    color: colors.dartThemeBlueGray,
    textAlign: "left",
  },
  nestedRow: {
    color: colors.dartThemeBlueGray,
    textAlign: "left",
  },
  detailBox: {
    display: "flex",
    justifyContent: "left",
  },
  sectionProgress: {
    fontFamily: "'Roboto Mono',monospace",
  },
  expandedRow: {
    // borderRight: `9px solid ${colors.darkThemeGreen}`,
  },
  lastActivityItem: {
    fontWeight: 600,
  },
  link: {
    color: colors.darkThemeGreen,
    textDecoration: "none",
    paddingLeft: 15,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
      color: colors.lightThemeGreen,
    },
  },
  pendingLabel: {
    color: colors.darkThemeGreen,
    fontWeight: "bold",
  },
  menuItem: {
    "&:focus": {
      outline: `2px dotted ${colors.grey400}`,
      outlineOffset: -2,
    },
  },
  selectMenu: {
    "&:focus": {
      outline: `2px dotted ${colors.grey400}`,
    },
  },
  infoCell: {
    paddingLeft: 0,
  },
  nestedInfoCell: {
    minWidth: 175,
    textAlign: "left",
    borderBottom: 0,
  },
  loader: {
    marginTop: 14,
    marginRight: 10,
  },
  paginationWrapper: {
    display: "flex",
    justifyContent: "right",
  },
}));

const StyledTableSortLabel = withStyles({
  root: {
    color: colors.dartThemeBlueGray,
  },
  active: {},
  icon: {
    color: "inherit !important",
  },
})((props) => <TableSortLabel {...props} />);

const checkActivityStatus = (activity) => {
  switch (true) {
    case activity.notStarted:
      return 'Not Started';
    case activity.isPendingGrade && activity.isDone:
      return 'Pending Grade';
    case activity.isDone && !activity.isIncomplete:
      return 'Complete';
    default:
      return 'In Progress';
  }
}

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    headCells,
    isLoading,
  } = props;
  const createSortHandler = (property) => (event) => {
    !isLoading && onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align ? headCell.align : "center"}
            className={classes.rowHeader}
            color="primary"
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label && <StyledTableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              className={!isLoading ? classes.sortLabel : classes.disabledSortLabel}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </StyledTableSortLabel>}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const getRowContent = (row, val, index, expanded, setExpanded, classes, clubCode, pushToRoute, ratings, createTrackingLog, programId) => {

  if (val.type === "linear") {
    if (row[val.id] == -1) {
      return "";
    }

    return (
      <TooltipWrapper title={row.setName}>
        <div>
          <ProgressCircleIcon icon={row.setIcon} value={row.setProgress} />
        </div>
      </TooltipWrapper>
    );
  }

  if (val.type === "expand") {
    if (!row.setInfo) {
      return "";
    }

    return (
      <IconButton
        aria-label="expand row"
        role="button"
        size="small"
        onClick={() => {
          const obj = { ...expanded };
          obj[index] = obj[index] ? false : true;
          setExpanded(obj);

          if (obj[index] === true) {
            createTrackingLog({
              event: PROGRESS_VIEW_MEMBER_EXPAND,
              data: JSON.stringify({ userId: row.userId, programId }),
            });
          }
        }}
      >
        {expanded[index] ? <ExpandLess /> : <ExpandMore />}
      </IconButton>
    );
  }

  if (val.type === "detail") {
    return (
      <Box className={classes.detailBox}>
        <Typography className={clsx(classes.blueRow, classes.sectionProgress)}>
          {row.sectionProgress}
        </Typography>
        <Typography className={classes.blueRow}>
          {row.sectionName}
        </Typography>
      </Box>
    );
  }

  if (val.type === 'name') {
    return (
      <Link
        className={classes.link}
        href="#"
        onClick={(e) => {
          e.preventDefault();
          pushToRoute(row.userId);
        }}
      >
        {row.name}
      </Link>
    )
  }

  return row[val.id];
};

export default function EnhancedTable({
  progress,
  isLoading,
  headCells,
  labels,
  clubCode,
  pushToRoute,
  ratings,
  createTrackingLog,
  programId,
  selectedGroupUsers,
  usersGroupFilter,
  membersCount,
  defaultPageSize,
  getAdditionalUserActivitesByProgramCode,
  }) {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultPageSize);
  const [expanded, setExpanded] = useState({});
  const [rows, setRows] = useState([]);
  const [additionalRequestPage, setAdditionalRequestPage] = useState(1);

  useEffect(() => {
    const groupMembers = !!progress ? progress.filter(user => usersGroupFilter(user, selectedGroupUsers)) : [];
    setRows(groupMembers);
  }, [progress, selectedGroupUsers]);

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";

    setExpanded({});
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (membersCount && progress?.length) {
      const batches = Math.ceil(membersCount / defaultPageSize);
      if (membersCount > progress.length && additionalRequestPage < batches) {
        getAdditionalUserActivitesByProgramCode({ programId, code: clubCode, pageNumber: additionalRequestPage, pageSize: defaultPageSize });
        setAdditionalRequestPage(additionalRequestPage + 1);
      }
    }
  }, [progress, membersCount]);

  let msgContent =
    labels && labels.emptyTableMainMessage.replace(/{clubCode}/gi, clubCode);
  return (
    <div>
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableBody}>
          <Table
            aria-labelledby="tableTitle"
            size="small"
            aria-label="Members' Progress"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
              isLoading={isLoading}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const rowIndex = index;
                  const isExpanded =
                    expanded[rowIndex] && !isLoading && row.setInfo && row.setInfo.length > 0;
                  return (
                    <>
                      <TableRow hover tabIndex={-1} key={index}>
                        {headCells.map((val, index) => {
                          return (
                            <TableCell
                              align={val.align ? val.align : "center"}
                              key={`${row[val.id]}${index}`}
                              className={classes.blueRow}
                            >
                              {getRowContent(
                                row,
                                val,
                                rowIndex,
                                expanded,
                                (key) => setExpanded(key),
                                classes,
                                clubCode,
                                pushToRoute,
                                ratings,
                                createTrackingLog,
                                programId,
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>

                      {isExpanded && (
                        <TableRow>
                          <TableCell
                            className={classes.nestedHeader}
                            align="center"
                          ></TableCell>
                          <TableCell
                            className={classes.nestedHeader}
                            align="center"
                          ></TableCell>
                          <TableCell
                            className={classes.nestedHeader}
                            align="center"
                          >
                            Activity Title
                          </TableCell>
                          <TableCell
                            className={clsx(classes.nestedHeader, classes.infoCell)}
                            align="center"
                          >
                            <TableCell
                              className={clsx(classes.nestedHeader, classes.nestedInfoCell)}
                              align="center"
                            >
                              Status
                            </TableCell>
                            <TableCell
                              className={clsx(classes.nestedHeader, classes.nestedInfoCell)}
                              align="center"
                            >
                              Count
                            </TableCell>
                            <TableCell
                              className={clsx(classes.nestedHeader, classes.nestedInfoCell)}
                              align="center"
                            >
                              Rating
                            </TableCell>
                          </TableCell>
                          <TableCell
                            className={classes.nestedHeader}
                            align="center"
                          >
                            Time
                          </TableCell>
                          <TableCell
                            className={clsx(
                              classes.expandedRow,
                              classes.nestedHeader
                            )}
                            align="center"
                          />
                        </TableRow>
                      )}

                      {isExpanded &&
                        row.setInfo &&
                        row.setInfo.map((e) => {
                          const rate = ratings
                            ? ratings.find(r => r.userId === row.userId && r.activity === e.activitySlug)
                            : null;

                          return (
                            <TableRow>
                              <TableCell
                                className={classes.nestedRow}
                                align={"center"}
                              ></TableCell>
                              <TableCell
                                className={classes.nestedRow}
                                align={"center"}
                              ></TableCell>
                              <TableCell
                                className={
                                  e.isLastActivity
                                    ? clsx(
                                        classes.nestedRow,
                                        classes.lastActivityItem
                                      )
                                    : classes.nestedRow
                                }
                                align={"center"}
                              >
                                {e.name}
                              </TableCell>
                              <TableCell
                                className={clsx(classes.nestedRow, classes.infoCell)}
                                align={"center"}
                              >
                                <TableCell
                                  className={clsx(classes.nestedInfoCell)}
                                  align={"center"}
                                >
                                  {checkActivityStatus(e)}
                                </TableCell>
                                <TableCell
                                  className={clsx(classes.nestedInfoCell)}
                                  align={"center"}
                                >
                                  {e.activityStatus}
                                </TableCell>
                                <TableCell
                                  className={clsx(classes.nestedInfoCell)}
                                  align={"center"}
                                >
                                  {e.isDone && !e.isIncomplete
                                    && (<RatingIcon rate={rate} />)}
                                </TableCell>
                              </TableCell>
                              <TableCell
                                className={classes.nestedRow}
                                align={"center"}
                              >
                                {e.time}
                              </TableCell>
                              <TableCell
                                className={clsx(
                                  classes.expandedRow,
                                  classes.nestedRow
                                )}
                                align={"center"}
                              ></TableCell>
                            </TableRow>
                          );
                        })}
                    </>
                  );
                })}
              {!progress && 
                <SkeletonTable 
                  rows={5} 
                  columns={5} 
                  showRowsOnly={true} 
                  colspan={{"5": 2}}/>
              }
            </TableBody>
          </Table>
        </TableContainer>
        {!!progress && !progress.length && !isLoading && labels && (
          <Box className={classes.emptyRowsContainer}>
            <Box className={classes.messageBox}>
              <Typography className={classes.messageTitle}>
                {labels.emptyTableHeading}
              </Typography>
              <div
                className={classes.messageContent}
                dangerouslySetInnerHTML={createMarkup(msgContent)}
              ></div>
            </Box>
            <Box className={classes.messageIcon}>
              <Box style={{ margin: "auto" }}>
                <ProgressCircleIcon value={35} />
              </Box>
            </Box>
          </Box>
        )}
        <Box className={classes.paginationWrapper}>
          <TablePagination
            classes={{ menuItem: classes.menuItem, select: classes.selectMenu }}
            rowsPerPageOptions={[10, 30, 90]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          {isLoading && <CircularProgress size={20} className={classes.loader} />}
        </Box>
      </Paper>
    </div>
  );
}
