import React from "react";
import { CSVLink } from 'react-csv';
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Tooltip,
  Divider,
} from "@material-ui/core/";
import * as moment from "moment";

import CustomButton from "../../../customButton/customButton";
import GetAppIcon from '@material-ui/icons/GetApp';

import TextCustomButton from "../../../../components/text-button/text-button";
import { ColorTheme } from '../../../../utils/colorTheme';

const useStyles = makeStyles({
  dialogContent: {},
  title: {
    color: ColorTheme.blue4.b,
    fontSize: 16,
    fontWeight: "bold",
    margin: "10px 0",
  },
  subTitle: {
    marginBottom: 10,
    fontSize: 14
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 25,
  },
  doneBtn: {
    fontSize: 16,
    padding: '0 25px',
    height: 40,
  },
  progressIcon: {
    width: 20,
    height: 20,
    marginLeft: 5,
  },
  downloadBtn: {
    textDecoration: "none",
  },
  downloadBtnContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 25,
    marginBottom: 25,
  }
});

export const DownloadMembersDataModal = ({
  onCloseModal,
  generatedMembersData,
  clubCode,
  membersLabels,
}) => {
  const classes = useStyles();

  const createUsersTable = (users) => {
    const data = users.map((user) =>
      createTableData({
        ...user,
      })
    );
    return data;
  };

  const createTableData = ({
    firstName,
    username,
    password
  }) => {
    return {
      firstName,
      username,
      password
    };
  };

  const headers = [
    {key: 'firstName', label: 'First Name'},
    {key: 'username', label: 'Username'},
    {key: 'password', label: 'Password'},
  ]

  return (
    <Box>
      <Typography className={classes.title}>{membersLabels.downloadGeneratedUsersDataLabel}</Typography>
      <Typography className={classes.subTitle}>
        {membersLabels.downloadGeneratedUsersDataText}
      </Typography>
      <Box className={classes.downloadBtnContainer}>
        <Tooltip title="Download table as CSV">
          <CSVLink
            data={createUsersTable(generatedMembersData)}
            filename={`${clubCode}.MembersAccount.${moment.parseZone(new Date()).format("YYYY-MM-DD")}.csv`}
            headers={headers}
            className={classes.downloadBtn}
          >
            <TextCustomButton
              mode="secondary"
              label={'DOWNLOAD AS CSV'}
              startIcon={<GetAppIcon />}
            />
          </CSVLink>
        </Tooltip>
      </Box>
      <Divider variant="middle" />
      <Box className={classes.btnContainer}>
        <CustomButton
          className={classes.doneBtn}
          mode={"primary"}
          onClick={() => onCloseModal()}
          label="Done">
        </CustomButton>
      </Box>
    </Box>
  );
};
