import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import { StyledFormHelper } from "../loginSignup/customFormHelper";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Input from "@material-ui/core/Input";
import ListItemText from "@material-ui/core/ListItemText";
import * as colors from "../../theme/colors";
import debounce from "debounce";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiSelect-selectMenu": {
      color: colors.darkThemeBlueGray,
      display: 'grid',
    },
    "& .Mui-disabled": {
      opacity: "0.5",
    },
  },
  checkBoxItem: {
    color: colors.greenA300,
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: colors.greenA300,
    },
    backgroundColor: "white !important",
  },
  errorLabel: {
    color: colors.redA400,
  },
  searchInput: {
    margin: "15px auto",
    width: "90%",
  },
  adortmentBorder: {
    marginRight: 10,
  },
  inputWrapper: {
    display: "flex",
    outlineWidth: "0",
    "& .MuiInput-underline:before": {
      borderBottomColor: colors.lightThemeGreen,
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: colors.themeGreen,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: colors.lightThemeGreen,
    },
  },
  labelStyle: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }
}));


const SearchSelector = ({
  className,
  field: { name, value },
  label,
  form: { errors, touched, setFieldValue },
  options,
  helperText,
  Icon,
  errorIsHtml,
  isLoading,
}) => {
  const classes = useStyles();
  const [searchKey, setSearchKey] = React.useState(null);

  if (!options) return null;
  let error = errors[name];
  let isTouched = touched[name];

  const searchInputChangeDebounced = debounce((searchKey) => {
    searchKey = !!searchKey.trim ? searchKey.trim() : "";
    setSearchKey(searchKey);
  }, 500);

  const filteredOptions = !!searchKey ? options.filter(e => e.label.toLowerCase().includes(searchKey.toLowerCase())
    || (!!e.code && e.code.toLowerCase().includes(searchKey.toLowerCase()))) : options;

  return (
    <>
      <FormControl
        variant="outlined"
        fullWidth
        className={className}
        helperText={helperText}
      >
        <Select
          id={name}
          name={name}
          className={classes.root}
          label={helperText}
          tabSelectsValue={false}
          disabled={isLoading}
          error={!!error && isTouched}
          helperText={
            error && isTouched ? (errorIsHtml ? "" : error) : helperText
          }
          value={value}
          MenuProps={{
            getContentAnchorEl: () => null,
          }}
          InputProps={{
            placeholder: label,
            startAdornment: Icon ? (
              <InputAdornment position="start">
                <Icon />
              </InputAdornment>
            ) : null,
          }}
          startAdornment={
            Icon ? (
              <div className={classes.adortmentBorder}>
                {" "}
                <Icon />
              </div>
            ) : null
          }
          displayEmpty
          onChange={(event) => {
            setFieldValue(name, event.target.value);
          }}
          onClose={() => {
            setSearchKey(null);
          }}
          renderValue={(selected) => {
            if (!selected || isNaN(selected)) {
              return label;
            }
            const itemToDisplay = options.find((e) => selected === e.id);
            return itemToDisplay ? (
              <Typography className={classes.labelStyle}>{itemToDisplay.label}</Typography>
            ) : (
              label
            );
          }}
        >
          <MenuItem
            className={classes.inputWrapper}
            style={{ backgroundColor: "transparent" }}
          >
            <>
              <Input
                key={"Search"}
                autoFocus={true}
                label={"Search"}
                placeholder={"Search"}
                className={classes.searchInput}
                style={{ backgroundColor: "transparent" }}
                onClickCapture={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                onChange={(event) => {
                  searchInputChangeDebounced(event.target.value);
                }}
              />
            </>
          </MenuItem>
          {filteredOptions
            .map(({ id, label }) => (
              <MenuItem
                className={classes.checkBoxItem}
                key={id}
                value={id}
              >
                <ListItemText primary={label} />
              </MenuItem>
            ))}
        </Select>
        {error &&
          isTouched &&
          (errorIsHtml ? (
            <StyledFormHelper helperText={error} isError={true} isHtml={true} />
          ) : (
            <FormHelperText className={classes.errorLabel}>
              {error}
            </FormHelperText>
          ))}
      </FormControl>
    </>
  );
};

export default SearchSelector;
