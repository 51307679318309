import React, { useEffect } from 'react';

import Notification from "../../components/notification/notification";

const Notifications = ({
  hqConfig,
  user,
  GlobalComponentQuery,
  updateAcceptedPrivacyPolicy
}) => {

  const [showCookieAgreement, setShowCookieAgreement] = React.useState(false);
  const [showPolicy, setShowPolicy] = React.useState(false);
  const [notifications, setNotifications] = React.useState(null);

  useEffect(() => {
    if (!GlobalComponentQuery) {
      return;
    }

    setNotifications(GlobalComponentQuery.notifications);
  }, [GlobalComponentQuery]);

  useEffect(() => {
    if (!user || !hqConfig || !notifications) {
      return;
    }

    const isDomestic = user.countryId === parseInt(hqConfig.domesticCountryId, 10);
    const publishedPolicyDate = isDomestic || !user.countryId
      ? hqConfig.lastUpdatePrivacyPolicyDomestic
      : hqConfig.lastUpdatePrivacyPolicyInternational;

    const shouldShowPolicy = !user.acceptedPolicyAt || user.acceptedPolicyAt < publishedPolicyDate;

    setShowPolicy(shouldShowPolicy);
    
    const cookieAgreement = localStorage.getItem("cookieAgreement");
    if (!cookieAgreement) {
      setShowCookieAgreement(true);
    }
  }, [user, hqConfig, notifications]);

  useEffect(() => {
    if (!notifications) {
      return;
    }

    const cookieAgreement = localStorage.getItem("cookieAgreement");
    if (!cookieAgreement) {
      setShowCookieAgreement(true);
    }
  }, [notifications]);

  const savePrivacyPolicyConfirmationDate = () => {
    updateAcceptedPrivacyPolicy(new Date());
  }

  /* eslint-disable */
  const closeNotification = (event, reason) => {
    if (reason === 'timeout' || reason === 'clickaway') {
      return;
    }

    localStorage.setItem("cookieAgreement", true);
    setShowCookieAgreement(false);
  };

  return (
    <>
      <Notification
        isShow={showPolicy}
        content={!!notifications && notifications.privacyPolicyMessage}
        itemKey="privacy-policy"
        btnLabel="I Agree"
        handleClose={() => savePrivacyPolicyConfirmationDate()} />
      <Notification
        isShow={showCookieAgreement}
        itemKey="cookie"
        btnLabel="Got it!"
        content={!!notifications && notifications.cookieAgreementMessage}
        handleClose={closeNotification}
      />
    </>
  );
};

export default Notifications;
