import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CustomButton from "../../components/customButton/customButton";
import UsersCard from "./UsersCard";

import Divider from "@material-ui/core/Divider";
import Link from '@material-ui/core/Link';
import SearchIcon from '@material-ui/icons/Search';
import { ColorTheme } from '../../utils/colorTheme';
import Roles from "../../utils/permissions";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: 15
  },
  csvContainer: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  csvBtn: {
    height: 45
  },
  subheaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 45
  },
  list: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  membersGrid: {
    marginTop: 20,
  },
  enrolledCounter: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  enrolledCounterSmall: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  noUsersInfo: {
    margin: '20px 0',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: '40px 20px',
  },
  searchBig: {
    color: ColorTheme.blue4.b,
    fontSize: 90,
  },
  noUsersInfoMessage: {
    color: ColorTheme.blue4.b,
    marginBottom: 10
  },
  divider: {
    margin: '30px 0',
    width: '70%'
  },
  title: {
    display: "flex",
  },
  inviteBtn: {
    marginLeft: 10,
    minWidth: 45,
    height: 45,
  },
  addMembersBtn: {
    height: 42
  },
  link: {
    color: ColorTheme.teal3.b,
    fontSize: 14,
    fontWeight: 700,
    textDecoration: "none",
    paddingLeft: 5,
    "&:hover": {
      textDecoration: "none",
    }
  },
  addMembersItem: {
    width: "100%",
    height: "100%",
    padding: "40px 0 40px 0",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    }
  }
}));

const sort = (a, b) => {
  const afName = a && a.firstName ? a.firstName : '';
  const bfName = b && b.firstName ? b.firstName : '';
  return afName > bfName ? 1 : -1
};

const Individuals = ({
  members,
  programTypeDetails,
  isLoading,
  removeUser,
  onChangePassword,
  rolePermissions,
  onInviteMembersClick,
  recruitmentResourceLink,
  profileContent,
}) => {
  const classes = useStyles();

  const participants = members.filter((r) => r.roleId === Roles.Student && !r.user.deletedAt);
  const hasParticipants = participants.length !== 0;

  return (
    <Box>
      {!isLoading && !hasParticipants && (
        <Paper className={classes.noUsersInfo}>
          <SearchIcon className={classes.searchBig} />
          <Typography
            variant="body2"
            component="p"
            className={classes.noUsersInfoMessage}>
            There are no members in your program yet.
          </Typography>
          {rolePermissions.addMembers && programTypeDetails?.addMembersEnabled && (
            <CustomButton
              mode="primary"
              sel="addMembers-btn"
              className={classes.addMembersBtn}
              onClick={() => onInviteMembersClick()}>
              + Add Members
            </CustomButton>
          )}
          {recruitmentResourceLink && <>
            <Divider className={classes.divider} />
            <Typography variant="body2"
              component="p"
              className={classes.noUsersInfoMessage}>Need Help?</Typography>
            <Typography variant="body2"
              component="p"
              className={classes.noUsersInfoMessage}>Check our
              <Link
                className={classes.link}
                target="_blank"
                color="secondary"
                href={recruitmentResourceLink}>Student Recruitment Resources Hub</Link>
            </Typography>
          </>
          }
        </Paper>
      )}

      {hasParticipants && (
        <Grid container spacing={1} className={classes.membersGrid}>
          {participants
            .sort((a, b) => sort(a.user, b.user))
            .map((m, index) => (
              <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                <UsersCard
                  member={m}
                  excludeUser={removeUser}
                  onChangePassword={onChangePassword}
                  hasContextMenu={rolePermissions.actionOnMembers}
                  profileContent={profileContent}
                />
              </Grid>
            ))}
          <Grid item xs={12} sm={6} md={4} lg={4} key={participants.length + 1}>
            {rolePermissions.addMembers && programTypeDetails?.addMembersEnabled && (
              <CustomButton
                mode="primary"
                label="+ ADD MORE MEMBERS"
                sel="invite-btn"
                className={classes.addMembersItem}
                onClick={() => onInviteMembersClick()}/>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Individuals;
