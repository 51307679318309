import React, { useState, useRef, useEffect,  }  from 'react';
import { useLocation } from 'react-router-dom';
import { Formik, Form } from "formik";
import { get } from 'lodash';

import { Box, CircularProgress } from '@material-ui/core';

import CustomButton from "../../../components/customButton/customButton";
import TextCustomButton from "../../../components/text-button/text-button";

import { ConfirmationModal } from '../../../components/modals/openTextAnswers/confirmationModal';
import { PageLeaveModal } from '../../../components/modals/pageLeaveWarning';

import { createAnswer } from '../../../api/openTextAnswers';
import ProgramEndedSection from '../ProgramEndedSection';
import { createEmptyEditor, exportEditor } from '../../../components/textEditor/utils';
import Editor from '../../../components/textEditor';

import Roles from "../../../utils/permissions";
import { TASK_STATUSES } from '../../../utils/constants';

  
const maxMsgLength = 1200;
  
const getStatus = (status) => {
    switch(status) {
      case TASK_STATUSES.review:
        return "Pending Grade";
      case TASK_STATUSES.incomplete:
        return "Incomplete";
      default:
        return "Complete";
    }
  };
const StudentView = ({ 
    classes, 
    item, 
    receivedAnswer, 
    onNextClick, 
    program, 
    isLoadingAnswer, 
    userAnswers, 
    getMyAnswersByProgram, 
    activityId, 
    setId, 
    collectionId, 
    url, 
    push, 
    user, 
    questionName, 
    studentView, 
    showInfoMessage,
    setOpenQuestionAnswerUpdated,
    isLastContentSection,
    answeredQuestionsState,
    presentationMode,
    history,
    isFinishedProject,
    setDisabledNext,
    folderId,
    roleId,
   }) => {
    const [showModal, setShowModal] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [disabledSubmit, setDisabledSubmit] = React.useState(true);
    const [isAnswerExist, setIsAnswerExist] = React.useState(false);
    const [editedAnswer, setEditedAnswer] = React.useState(null);
    const [isEscapeConfirmDialog, setEscapeConfirmDialog] = useState(false);
    const [isDataChanged, setDataChanged] = useState(false);
    const [nextLocation, setNextLocation] = useState(null);
    const location = useLocation();
    const answerGroupId = get(item, ['contentSectionGroup', 'id']);
    const questionGroupName = get(item, ['contentSectionGroup', 'name']);
    const groupRequiredGrading = get(item, ['contentSectionGroup', 'requiredGrading']);
    const totalQuestions = get(item, ['contentSectionGroup', 'questionsIds'], []).length;
    const isStudentRole = roleId === Roles.Student;
  
    React.useEffect(() => {
  
      if (!program) {
        return;
      }
  
      if (!userAnswers) {
        // Load answers for member by program id;
        getMyAnswersByProgram({ programId: program.id });
      }
  
    }, []);
  
    const unblockRef = useRef(null);
  
    useEffect(() => {
      unblockRef.current = history.block((location) => {
        setNextLocation(location);
        setEscapeConfirmDialog(true);
  
        return !isDataChanged;
      });
  
      return () => {
        unblockRef.current && unblockRef.current();
    };
    }, [isDataChanged, isEscapeConfirmDialog]);
  
    const validateContent = (value) => {
      if (!value || value.length == 0) {
        setOpenQuestionAnswerUpdated(false);
        setDisabledSubmit(true);
        return;
      }
  
      setDataChanged(true);
  
      if (value.length > maxMsgLength) {
        setDisabledSubmit(true);
        return `Answer should contain less than ${maxMsgLength} characters`;
      }
  
      setOpenQuestionAnswerUpdated(true);
      setDisabledSubmit(false);
      return null;
    };
  
    const saveAnswer = (payload) => {
      setIsSubmitting(true);
      const { question, path } = payload;
  
      return createAnswer(payload)
        .then(({ data }) => {
          setOpenQuestionAnswerUpdated(false);
          receivedAnswer({ question, path, answer: data });
  
          //Added checking for turning off modal with congratulation and available next button
          const requiredAnswered = answeredQuestionsState?.numberOfAnswered?.length || 0;
          const optionalAnswered = answeredQuestionsState?.optionalAnsweredSectionsTotal?.length || 0;
          const totalAnswered = requiredAnswered + optionalAnswered;
          if (presentationMode && ((isLastContentSection && 
            totalAnswered === (answeredQuestionsState?.totalQuestions - 1)) ||
              (!isLastContentSection && totalAnswered < answeredQuestionsState?.totalQuestions))) {
            setShowModal(true);
          }
    
          setIsSubmitting(false);
        })
        .catch(() => {
          setIsSubmitting(false);
        });
    }
  
    React.useEffect(() => {
      if (!program || !program.id) {
        return;
      }
      const itemKey = `${url}-${item.id}`;
      const currentAnswers = userAnswers && userAnswers[itemKey]
        ? userAnswers[itemKey]
        : [];
  
      const isExist = !!currentAnswers.length;
  
      setIsAnswerExist(isExist);
      setDisabledNext(!isExist && !isFinishedProject);
      setEditedAnswer(currentAnswers && currentAnswers[0]);
    }, [userAnswers]);
  
    return (
      <Box>
        <ConfirmationModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onViewClick={() => push(`/progress/${program.code}/${user.id}/answers`)}
          onNextClick={onNextClick}
        />
        {isLoadingAnswer ?
          <Box className={classes.loaderBox}>
            <CircularProgress size={20} />
          </Box>
          : <>
            {isAnswerExist
              ? <Box className={classes.buttonContainer}>
                <Box className={classes.buttonViewGroup}>
                  <span className={classes.submittedLabel}>
                  {`Submitted ${(item?.contentSectionGroup ? item?.contentSectionGroup?.requiredGrading :
                    item.requiredGrading) ? `| ${getStatus(editedAnswer.status)}` : ""}`}
                  </span>
                  <TextCustomButton
                    className={classes.viewBtn}
                    onClick={() => {
                      window.location.replace(`/progress/${program.code}/${user.id}?tab=answers&&editedAnswer=${editedAnswer.question}&backTo=${location.pathname}#${item.id}`)
                    }}
                    label={isFinishedProject ? "View Answers" : "View or Edit Answers"}
                  />
                </Box>
              </Box>
              : isFinishedProject
                ? <ProgramEndedSection /> 
                : <Box>
                <Formik
                  initialValues={{
                    content: createEmptyEditor(),
                  }}
                  validateOnChange={true}
                  validateOnBlur={false}
                  validate={(values) => {
                    let errors = {};
                    if (values.content.getCurrentContent().getPlainText().length > maxMsgLength) {
                      setOpenQuestionAnswerUpdated(false);
                      setDisabledSubmit(true);
                      setDataChanged(false);
                      errors.body = `Answer should contain less than ${maxMsgLength} characters`;
                    }
                    if (!values.content || !values.content.getCurrentContent().getPlainText().trim().length) {
                      setOpenQuestionAnswerUpdated(false);
                      setDisabledSubmit(true);
                      setDataChanged(false);
                      errors.body = 'Required';
                    } 
                    else {
                      setOpenQuestionAnswerUpdated(true);
                      setDisabledSubmit(false);
                      setDataChanged(true);
                    }
                    return errors;
                  }}
                  onSubmit={(values) => {
                    const editorToHtml = exportEditor(values.content);
                    const body = {
                      collection: collectionId,
                      question: item.id,
                      set: setId,
                      activity: activityId,
                      path: url,
                      content: editorToHtml,
                      programId: program.id,
                      questionName,
                      folderId,
                    };
                    if (answerGroupId) {
                      body.answersGroup = {
                      id: answerGroupId,
                      name: questionGroupName,
                      requiredGrading: !!groupRequiredGrading,
                      totalQuestions,
                    }};
                    const questionsIds = get(item, ['contentSectionGroup', 'questionsIds'], []);
                    const leftNotAnsweredQuestions = questionsIds.filter(questionId => !(Object.values(userAnswers).flat().some(answer => answer?.question === questionId)));
                    const isLastQuestionInGroup = leftNotAnsweredQuestions?.length === 1 && leftNotAnsweredQuestions[0] === item?.id;
                    if (!!answerGroupId && (isLastQuestionInGroup || !leftNotAnsweredQuestions.length)) {
                      body.answersGroup.done = true;
                    }
  
                    if (studentView) {
                      showInfoMessage('Facilitators can only preview open text answers in the Members view.');
                      return;
                    }
  
                    if (unblockRef) {
                      unblockRef.current();
                    }
                    setDataChanged(false);
  
                    saveAnswer(body);
                  }}
                >
                  {() => (<Form>
                    <Box className={classes.formContainer}>
                      <Box className={classes.formBox}>
                        <span className={classes.answerLabel}>Your Answer</span>
                      </Box>
                    </Box>
                    <Box className={classes.formContainer}>
                      <Box className={classes.formBox}>
                        <Editor
                          name={`content`}
                          showToolbar={true}
                          placeholder='Type your response here'
                          className={classes.messageArea}
                          maxLength={maxMsgLength}
                          maxLengthStartingFrom={1000}
                          maxLengthInfo="Characters remaining: ${value}"
                          aria-live="polite"
                          validate={validateContent}
                          isOpenText={true}
                        />
                      </Box>
                    </Box>
                    <Box className={classes.formContainer}>
                      <div className={classes.buttonGroup}>
                        <CustomButton
                          className={classes.submitButton}
                          type="submit"
                          mode={"primary"}
                          disabled={!isStudentRole || disabledSubmit || isSubmitting}
                          label={<Box className={classes.submitBox}>
                            <Box className={classes.submitLabel}>
                              Submit
                            </Box>
                            {isSubmitting && <CircularProgress className={classes.circularItem} size={18} />}
                          </Box>}
                        />
                      </div>
                    </Box>
                  </Form>)}
                </Formik>
              </Box>
            }
          </>}
        <PageLeaveModal
          onConfirm={() => {
            if (unblockRef) {
              unblockRef.current();
            }
            setDataChanged(false);
            history.push(nextLocation);
          }}
          onCancel={() => { setEscapeConfirmDialog(false); }}
          open={isEscapeConfirmDialog}
        />
      </Box>
    );
  }
  export default StudentView;