import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import PageTitle from "../../../components/page-title/pageTitle";
import TextCustomButton from "../../../components/text-button/text-button";
import CustomButton from "../../../components/customButton/customButton";
import Tabs from "../../UserProgress/Tabs/";
import { Link } from "react-router-dom";

import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { ViewCertificateModal } from "../../../components/modals/certificates/viewCertificateModal";
import { GenerateCertificateModal } from "../../../components/modals/certificates/generateCertificatesModal";
import * as certificatesApi from "../../../api/certificates";

import * as colors from "../../../theme/colors";
import { Typography, Box } from "@material-ui/core";
import { downloadFile } from "../../../utils/downloadFile";

import { downloadAllCertificates } from '../../../api/certificates';

import { monthNames } from "../../../utils/generic";
import { FilterBlock } from "../../../containers/Progress/index";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: 10,
  },
  tabsSection: {
    margin: '-10px auto 15px -25px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '-10px',
    },
  },
  contentBox: {
    width: "95%",
    margin: "auto",
  },
  header: {
    margin: "15px auto",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  main: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  blueRow: {
    padding: "5px",
    verticalAlign: "center",
    whiteSpace: "nowrap",
    color: colors.darkThemeBlue,
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "left",
    minWidth: "10%",
  },
  tableBody: {
    //minHeight: "320px",
  },
  emptyRowsContainer: {
    display: "flex",
  },
  blockTitle: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 1.375,
    textTransform: "uppercase",
    color: colors.dartThemeBlueGray
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 1.375,
    color: colors.dartThemeBlueGray
  },
  tableBox: {
    padding: "10px 10px 10px 40px",
  },
  btnSection: {
    margin: "20px 0px",
    display: "flex",
    justifyContent: "center",
  },
  btnContainer: {
    height: 60,
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0px 10px",
  },
  progressIcon: {
    width: 20,
    height: 20,
    marginLeft: 5,
  },
  generateBtn: {
    marginLeft: 10,
  },
  emptyStateTitle: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
  },
  imageContainer: {
    marginTop: 10,
    display: "flex",
    justifyContent: 'center',
  },
  certificateTemplate: {
    height: 200,
  },
  titleBox: {
    display: "flex",
    alignItems: "center",
  },
  titleIcon: {
    height: 50,
    marginRight: 10,
  },
  tableRow: {
    height: 51,
    "&:hover": {
      "& $rowBtn": {
        opacity: 1,
      }
    },
  },
  rowBtn: {
    opacity: 0,
  },
  link: {
    color: colors.darkThemeGreen,
    textDecoration: "none",
    paddingLeft: 15,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
      color: colors.lightThemeGreen,
    },
  },
}));

export default function EnhancedTable({
  usersProjectsByProgram,
  programMemberCertificates,
  startCertificatesGeneration,
  programId,
  loadCertificateGenerationStatus,
  loadUserCertificatesByProgram,
  setCurrentTab,
  currentTab,
  labels,
  push,
  showAnswers,
  selectedGroupUsers,
  setSelectedGroupUsers,
  usersGroupsList,
  originClasses,
  usersGroupFilter,
}) {
  const classes = useStyles();

  const [isOpenGenerateModal, setIsOpenGenerateModal] = useState(false);
  const [disableGenerate, setDisableGenerate] = useState(true);
  const [isGenerationRunning, setIsGenerationRunning] = useState(false);
  const [viewCertificateItem, setViewCertificateItem] = useState(null);
  const [templateImgBuffer, setTemplateImgBuffer] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    if (programMemberCertificates[programId]) {
      const { allowToGenerate, isRunning, certificates } = programMemberCertificates[programId];
      setDisableGenerate(!allowToGenerate);
      setIsGenerationRunning(isRunning);

      const isAnyCertificateExist = !!certificates
        ? Object.entries(certificates).length
        : false;

      if (!isAnyCertificateExist && !templateImgBuffer) {
        // load image with empty template
        loadCertificateImageTemplate(programId);
      }

      if (isRunning) {
        setTimeout(() => {
          loadCertificateGenerationStatus({ programId, prevStatus: isRunning });
        }, 60000); // check status each minute;
      }
    }
  }, [programMemberCertificates]);

  useEffect(() => {
    if (!programId) {
      return;
    }

    loadUserCertificatesByProgram({ programId });
  }, [programId]);

  async function loadCertificateImageTemplate(programId) {
    const { data: {
      imageTemplate
    }
    } = await certificatesApi.viewCertificateTemplate(programId);
    setTemplateImgBuffer(`data:image/png;base64,${Buffer.from(imageTemplate.data).toString("base64")}`);
  }

  async function loadCertificatePDFTemplate(programId) {
    setIsDownloading(true);
    const result = await certificatesApi.loadCertificatePDFTemplate(programId);

    const url = window.URL.createObjectURL(result.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'template');
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    setIsDownloading(false);
  }

  async function loadUserCertificatePDF(item) {
    setIsDownloading(true);
    await certificatesApi.downloadCertificate(item)
      .then((response) => {
        downloadFile(response.data, item.title, "application/pdf");
        setIsDownloading(false);
      });
  }

  const downloadAll = async (item) => {
    setIsDownloading(true);
    await downloadAllCertificates(item)
      .then((response) => {
        downloadFile(response.data, "certificates", "application/zip");
        setIsDownloading(false);
      });
  };

  const data = programMemberCertificates[programId];
  const isAnyCertificateExist = !!data?.certificates
    ? Object.entries(data?.certificates).length
    : false;
  const isAnyStudentExist = !!data?.students;
  const usersGroupMembers = isAnyStudentExist && data.students.filter(user => usersGroupFilter(user, selectedGroupUsers))

  const disableDownload = disableGenerate || isGenerationRunning || isDownloading;

  return (
    <Box className={classes.contentBox}>
      <main className={classes.main}>
        <Box>
          <Box className={classes.header}>
            <PageTitle title="Progress" />
            { !!usersGroupsList.length && isAnyStudentExist && <FilterBlock
              classes={originClasses}
              setSelectedGroupUsers={setSelectedGroupUsers}
              usersGroupsList={usersGroupsList}
              selectedGroupUsers={selectedGroupUsers}
            />}
          </Box>
          <div className={classes.tabsSection}>
            <Tabs
              value={currentTab}
              showProjectTab={usersProjectsByProgram.length}
              showCertificateTab={true}
              showAnswers={showAnswers}
              onChange={(evt, newValue) => {
                setCurrentTab(newValue);
              }}
            />
          </div>
          <div>
            <Paper className={classes.paper}>
              {!isAnyCertificateExist
                ? (
                  <>
                    <Box className={classes.emptyStateTitle}>
                      <Box className={classes.titleBox}>
                        <img
                          className={classes.titleIcon}
                          src={!!labels && labels.certificateTabImage.src}
                          alt="Certificate"
                        />
                        <Typography className={classes.title}>Your Members do not have Program Certificates, yet!</Typography>
                      </Box>
                    </Box>
                    <Box className={classes.imageContainer}>
                      {!!templateImgBuffer && <img
                        className={classes.certificateTemplate}
                        src={templateImgBuffer}
                        alt={!!labels && labels.certificateImageDescription}
                      />}
                    </Box>
                  </>
                )
                : (
                  <>
                    <Box className={classes.headerContainer}>
                      <Box className={classes.titleBox}>
                        <img
                          className={classes.titleIcon}
                          src={!!labels && labels.certificateTabImage.src}
                          alt={!!labels && labels.certificateImageDescription}
                        />
                        <Typography className={classes.blockTitle}>Program Certificates</Typography>
                      </Box>
                      <Box>
                        {/* TO DO: Temporary disabled*/}
                        <TextCustomButton
                          disabled={isDownloading}
                          onClick={() => {
                            if (!!isDownloading) {
                              return;
                            }
                            downloadAll(programId);
                          }}
                        >Download All
                          {isDownloading && (
                            <CircularProgress
                              size={20}
                              className={classes.progressIcon}
                            />)}
                        </TextCustomButton>
                      </Box>
                    </Box>
                    <Box className={classes.tableBox}>
                      <TableContainer className={classes.tableBody}>
                        <Table
                          aria-labelledby="tableTitle"
                          size="small"
                          aria-label="enhanced table"
                        >
                          <TableBody>
                            { usersGroupMembers
                              .map((row) => {
                                const certificate = !!data?.certificates[row.userId]?.length
                                  ? data?.certificates[row.userId][0]
                                  : null;
                                const date = !!certificate ? new Date(certificate.createdAt) : null;
                                return (
                                  <>
                                    <TableRow
                                      hover
                                      tabIndex={-1}
                                      key={row.userId}
                                      className={classes.tableRow}>
                                      <TableCell
                                        align="left"
                                        key={row.userId}
                                        width="30%"
                                        className={classes.blueRow}
                                      >
                                        <Link
                                          className={classes.link}
                                          href="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            push(`/profile/${row.userId}`);
                                          }}
                                        >
                                          {row.firstName} {row.lastName}
                                        </Link>
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        width="10%"
                                        key={row.userId}
                                        className={classes.blueRow}
                                      >
                                        {!!certificate ? "Created" : ""}
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        key={row.userId}
                                        width="30%"
                                        className={classes.blueRow}
                                      >
                                        {!!certificate && <div>
                                          {`${date.getDate()}
                                    ${monthNames[date.getMonth()]} ${date.getFullYear()}`}</div>}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        width="10%"
                                        key={row.userId}
                                        className={classes.blueRow}
                                      >
                                        {!!certificate &&
                                          <TextCustomButton
                                            className={classes.rowBtn}
                                            onClick={() => setViewCertificateItem(certificate)}
                                          >
                                            View
                                          </TextCustomButton>}
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        key={row.userId}
                                        width="10%"
                                        className={classes.blueRow}
                                      >
                                        {!!certificate && <TextCustomButton
                                          disabled={disableDownload}
                                          className={classes.rowBtn}
                                          onClick={() => {
                                            if (disableDownload) {
                                              return;
                                            }
                                            loadUserCertificatePDF(certificate);
                                          }}
                                        >
                                          Download
                                          {isDownloading && (
                                            <CircularProgress
                                              size={20}
                                              className={classes.progressIcon}
                                            />
                                          )}
                                        </TextCustomButton>}
                                      </TableCell>
                                    </TableRow>
                                  </>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </>)}
              <Box className={classes.btnSection}>
                <Box className={classes.btnContainer}>
                  <TextCustomButton
                    disabled={disableDownload}
                    onClick={async () => {
                      if (disableDownload) {
                        return;
                      }
                      await loadCertificatePDFTemplate(programId);
                    }}
                  >Download Template {isDownloading && (
                    <CircularProgress
                      size={20}
                      className={classes.progressIcon}
                    />
                  )}</TextCustomButton>

                  <CustomButton
                    className={classes.generateBtn}
                    mode={"primary"}
                    disabled={disableGenerate || isGenerationRunning}
                    label={"Generate Certificate"}
                    onClick={() => setIsOpenGenerateModal(true)}
                  >
                    {isGenerationRunning && (
                      <CircularProgress
                        size={20}
                        className={classes.progressIcon}
                      />
                    )}</CustomButton>
                </Box>
              </Box>
              {!!viewCertificateItem && <ViewCertificateModal
                isOpen={!!viewCertificateItem}
                item={viewCertificateItem}
                onClose={() => setViewCertificateItem(null)}
              />}
            </Paper>
            <GenerateCertificateModal
              labels={labels}
              isOpen={isOpenGenerateModal}
              items={isAnyStudentExist ? usersGroupMembers : []}
              generate={(studentIds) => startCertificatesGeneration({ studentIds, programId, forceStatusCheck: true })}
              onClose={() => setIsOpenGenerateModal(false)}
            />
          </div>
        </Box>
      </main>
    </Box>
  );
}
