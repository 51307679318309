import { createAction } from 'redux-act';

export const loadUserCertificates = createAction();
export const receivedUserCertificates = createAction();

export const loadAllUserCertificates = createAction();
export const receivedAllUserCertificates = createAction();

export const loadCertificateGenerationStatus = createAction(); 
export const receiveCertificateGenerationStatus = createAction(); 

export const loadUserCertificatesByProgram = createAction();
export const receivedUserCertificatesByProgram = createAction();

export const startCertificatesGeneration = createAction();

export const loadCertificateNameChangeStatus = createAction();
export const receiveCertificateNameChangeStatus = createAction();

export const startCertificateNameChangeGeneration = createAction();
