import React from "react";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import * as colors from "../../../theme/colors";
import CancelIcon from "@material-ui/icons/Cancel";
import { Typography } from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { StyledFormHelper } from "../../loginSignup/customFormHelper";
import { handleKeyPress } from '../../../utils/accessibility';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginRight: 20,
    width: "100%",
    maxWidth: "100%",
  },
  contentField: {
    padding: 0,
  },
  colorPrimaryAdd: {
    backgroundColor: "#e0e0e0",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    color: "#504e4e",
    padding: 5,
    verticalAlign: "top",
    marginTop: 15,

    "&:hover": {
      color: "#ffffff",
      backgroundColor: theme.palette.primary.main,
    },
  },
  iconBtn: {
    backgroundColor: (props) => props.backgroundColor,
    border: (props) => `2px solid ${props.borderColor}`,
    color: (props) => props.mainColor,
    padding: 3,
    fontSize: "inherit",
    fontWeight: 700,
    position: "relative",
    verticalAlign: "top",
    marginTop: 15,

    "&:hover": {
      borderColor: (props) => props.secondColor,
      backgroundColor: (props) => props.hoverBackgroundColor,
    },
  },
  chipItem: {
    margin: 5,
    "&:focus": {
      outline: `2px auto ${colors.grey400}`,
    },
  },
  cancelIcon: {
    '&:focus': {
      outline: 'thin solid black',
      outlineOffset: -2,
    }
  },
  notification: {
    marginTop: 5,
    fontSize: 14
  },
}));

const styleTypes = {
  primary: {
    mainColor: colors.dartThemeBlueGray,
    secondColor: colors.dartThemeBlueGray,
    backgroundColor: colors.dartThemeBlueGray,
    borderColor: colors.lightThemeGreen,
    hoverBackgroundColor: colors.lightThemeGreen,
    backgroundColor: colors.lightThemeGreen,
  },
  secondary: {
    mainColor: colors.dartThemeBlueGray,
    secondColor: colors.dartThemeBlueGray,
    borderColor: colors.lightThemeGreen,
    hoverBackgroundColor: colors.white,
    backgroundColor: colors.white,
  },
  disabled: {
    mainColor: colors.black,
    secondColor: colors.greyLight2,
    backgroundColor: colors.dartThemeBlueGray,
    borderColor: colors.greyLight2,
    hoverBackgroundColor: colors.greyLight2,
    backgroundColor: colors.greyLight2,
  },
};

const TextFieldGroup = (props) => {
  const {
    field: { name, value },
    form: { errors, setFieldValue, setFieldTouched, touched, values },
    label,
    mode = "secondary",
    originalValue,
    disabled,
    validateEmailList,
    countLabel,
    className,
  } = props;

  const classes = useStyles(disabled ? styleTypes.disabled : styleTypes[mode]);
  const emails = values[originalValue];

  let isTouched = touched[name];
  const error = errors[name];

  const handleDelete = (e, removedItem, items) => {
    e.preventDefault();
    const newValue = items.filter((item) => item != removedItem);
    setFieldValue(originalValue, newValue);
    setFieldValue(name, value.replace(removedItem, ""));
  };

  return (
    <Box>
      <Container className={classes.contentField}>
        <TextareaAutosize
          {...props}
          className={clsx(classes.textField, className)}
          fullWidth
          error={error && isTouched}
          value={value}
          helperText={error ? error : null}
          aria-labelledby="form-subtitle"
          label={label}
          variant="outlined"
          onChange={(event) => {
            const parsedValues = event.target.value
              .replaceAll("\n", " ")
              .split(" ")
              .map((e) => e.trim());
            const validatedEmails = parsedValues.filter((e) =>
              validateEmailList(e)
            );

            setFieldValue(originalValue, validatedEmails);
            setFieldValue(name, event.target.value);

            setFieldTouched(name, true, false);
          }}
        />
        {error && isTouched && (
          <StyledFormHelper helperText={error} isHtml={true} isError={true} />
        )}
      </Container>
      <Box role="application" aria-label="tags">
        {emails.map((item) => {
          return (
            <Chip
              className={classes.chipItem}
              label={item}
              aria-label={`chip ${item}`}
              clickable
              tabIndex={0}
              deleteIcon={
                <CancelIcon
                  role="button"
                  aria-label={`delete chip ${item}`}
                  tabIndex={0}
                  onKeyDown={handleKeyPress((e) => handleDelete(e, item, emails))}
                  className={classes.cancelIcon}
                />
              }
              onDelete={(e) => handleDelete(e, item, emails)}
              onClick={(e) => handleDelete(e, item, emails)}
            />
          );
        })}
      </Box>
      <Typography
        className={classes.notification}
      >{`${countLabel.replace("{count}", emails.length)}`
      }
      </Typography>
    </Box>
  );
};

export default TextFieldGroup;
