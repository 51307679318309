import { axiosInstance } from '../axiosInstance';

import { getAuthHeader } from '../../tokenStorage';

export const createRating = async (payload) =>  {
  return axiosInstance.post(
    `/blapi/ratings/create`,
    payload,
    {
      headers: getAuthHeader(),
    }
  );
};

export const updateRating = async (payload) => {
  return axiosInstance.post(
    `/blapi/ratings/update`,
    payload,
    {
      headers: getAuthHeader(),
    }
  );
};

export const getRatingsByProgram = async ({programId, userId}) => {
  const search = `${programId}${userId ? `/${userId}` : ""}`
  return axiosInstance.get(`/blapi/ratings/getAllByProgram/${search}`,
    {
      headers: getAuthHeader(),
    });
};