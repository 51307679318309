import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import { Typography, Chip, Button } from "@material-ui/core";
import CustomSelector from "../../../../components/customSelector/customSelector";
import MultipleSelect from "./customSelect";
import * as colors from "../../../../theme/colors";

const useStyles = makeStyles({
  root: {
    margin: "0 10px",
  },
  label: {
    marginRight: "15px",
    fontSize: "0.875rem",
    fontWeight: "400",
    width: "100px",
  },
  labelText: {
    fontSize: "0.875rem",
    fontWeight: "400",
  },
  controlLabel: {
    width: "100px",
  },
  filter: {
    marginLeft: 10,
    fontFamily: "'Roboto Mono',monospace",
  },
  multiContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: 10,
  },
  labelContainer: {
    fontWeight: 500,
    minWidth: "fit-content",
    margin: "0px 10px 0px 0px",
    color: "#7A7A78",
    fontSize: 12,
  },
  clearAllBtn: {
    color: colors.greenA300,
    fontFamily: "'Roboto',monospace",
    fontWeight: "bold",
    fontSize: 15,
  },
  statusFilterContainer: {
    marginLeft: 0,
  },
});

const DashboardFilters = ({
  filters,
  onChange,
  enableCurriculums,
  enableRenewals,
  yearOnChange,
  yearOptions,
  yearValue,
}) => {
  const classes = useStyles();
  const keys = Object.getOwnPropertyNames(filters);
  const isAnySelected =
    keys.filter((e) => Object.getOwnPropertyNames(filters[e]).length).length > 0;

  const handleFilterChange = (filterName, attribute) => () => {
    if (filters[filterName][attribute]) {
      delete filters[filterName][attribute];
    } else {
      filters[filterName][attribute] = true;
    }

    onChange(filters);
  };

  const clearAllFilters = () => {
    const keys = Object.getOwnPropertyNames(filters);
    keys.map((e) => {
      filters[e] = {};
    });
    onChange(filters);
  };

  const renderCheckboxFilter = (label, filterName, attribute) => {
    if (!filters[filterName][attribute]) {
      return;
    }

    return (
      <Chip
        className={classes.filter}
        clickable={true}
        label={label}
        size="medium"
        variant="outlined"
        onDelete={handleFilterChange(filterName, attribute)}
      />
    );
  };

  const grades = [
    { label: "3rd-5th", filterName: "curriculum", attribute: "3rd5th" },
    { label: "6th-12th", filterName: "curriculum", attribute: "6th12th" },
  ];

  const statuses = [
    { label: "Renewed", filterName: "renewal", attribute: "renewed" },
    { label: "New", filterName: "renewal", attribute: "new" },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.multiContainer}>
        <Typography className={classes.labelContainer}>FILTERS:</Typography>
        <CustomSelector
          className={classes.yearSelector}
          value={yearValue}
          onChange={yearOnChange}
          options={yearOptions}
          showAcademicYearFormat={true}
        />
        {enableCurriculums && (
          <MultipleSelect
            label={"CURRICULUM"}
            values={grades}
            filters={filters}
            onSelectChange={handleFilterChange}
          />
        )}
        {enableRenewals && (
          <MultipleSelect
            className={enableCurriculums ? classes.statusFilterContainer : ''}
            label={"STATUS"}
            values={statuses}
            filters={filters}
            onSelectChange={handleFilterChange}
          />
        )}
      </div>
      <div className={classes.multiContainer}>
        {isAnySelected && (
          <Button
            className={classes.clearAllBtn}
            onClick={() => clearAllFilters()}
          >
            Clear All
          </Button>
        )}
        {renderCheckboxFilter("3rd-5th", "curriculum", "3rd5th")}
        {renderCheckboxFilter("6th-12th", "curriculum", "6th12th")}
        {renderCheckboxFilter("Renewed", "renewal", "renewed")}
        {renderCheckboxFilter("New", "renewal", "new")}
      </div>
      {/* Disabled due to HQ-1887 request */}
      {/* {isDomestic && (
        <div className={classes.root}>
           <Typography className={classes.label}>Received Fund</Typography>
          {renderCheckboxFilter(
            "Yes",
            "fundReceived",
            "yes"
          )}
          {renderCheckboxFilter(
            "No",
            "fundReceived",
            "no"
          )}
        </div>
      )} */}
    </div>
  );
};

export default DashboardFilters;
