import React, { useState } from "react";

import { Field } from "formik";

import { makeStyles } from "@material-ui/core/styles";
import TextInput from "../components/TextInput";
import PhotosSelector from "../components/PhotosSelector";
import { Typography, Box } from "@material-ui/core";

import * as colors from "../../.././theme/colors";

const useStyles = makeStyles((theme) => ({
  subTitle: {
    color: colors.darkThemeBlueGray,
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 10,
    [theme.breakpoints.down("xs")]: {
      padding: "0 16px",
    },
  },
  firstStepWrapper: {
    padding: '0 24px',
    '& .MuiContainer-root': {
      padding: 0,
    },
    [theme.breakpoints.down("xs")]: {
      padding: '0 14px',
    },
  },
  fieldWrapper: {
    margin: "16px 0 20px 0",
    '& .MuiInputLabel-outlined': {
      backgroundColor: colors.white,
    },
    '& .MuiOutlinedInput-input': {
      fontSize: 14,
    }
  },
  requiredBox: {
    display: "flex",
  },
  starLabel: {
    color: colors.redA700,
    marginLeft: 1,
  },
}));

const FirstStep = ({ labels, validateTitle, showErrorMessage }) => {
  const classes = useStyles();
  const [isTouched, setIsTouched] = useState(false);

  return (
    <Box className={classes.firstStepWrapper}>
      <Box>
        <Typography className={classes.subTitle}>
          Step 1: Project Info
        </Typography>
      </Box>

      <Field
        component={PhotosSelector}
        name="images"
        className={classes.requiredBox}
        showErrorMessage={showErrorMessage}
        label={
          <Box className={classes.requiredBox}>
            <div>Must include at least one image</div>
            <Typography className={classes.starLabel}> *</Typography>
          </Box>
        }
      />
        <Field
          name="title"
          component={TextInput}
          validate={isTouched ? validateTitle : null}
          onKeyUp={setIsTouched}
          placeholder={labels.submissionFormProjectTitlePlaceholder || ""}
          label="Project title"
          className={classes.fieldWrapper}
          InputLabelProps={{ shrink: true }}
          required
        />
        <Field
          name="projectLink"
          component={TextInput}
          placeholder={labels.submissionFormProjectLinkPlaceholder || ""}
          label="Link to the project"
          className={classes.fieldWrapper}
          InputLabelProps={{ shrink: true }}
        />
        <Field
          name="gitHubLink"
          component={TextInput}
          placeholder={labels.submissionFormGitHubLinkPlaceholder || ""}
          label="Link to code"
          className={classes.fieldWrapper}
          InputLabelProps={{ shrink: true }}
        />
    </Box>
  );
};

export default FirstStep;
