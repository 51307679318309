import { createAction } from 'redux-act';

export const getUserActivitesByProgramCode = createAction();
export const receivedUserActivityInfo = createAction();

export const getAdditionalUserActivitesByProgramCode = createAction();
export const receivedAdditionalUserActivityInfo = createAction();

export const getMyUserActivitiesByProgram = createAction();
export const receivedMyActivitiesInfo = createAction();
export const deleteMyActivityProgress = createAction();
export const addUserActivitiesInfoItem = createAction();
export const getMyUserActivitiesByPartnership = createAction();
export const deleteMyActivityProgressInPartnership = createAction();

export const getUserProgramLogs = createAction();
export const receivedUserProgramLogs = createAction();

export const getUserActivitiesByProgram = createAction();
export const receivedUserActivitiesByProgram = createAction();

export const getAllCsItemStatuses = createAction();
export const receivedCSItemStatuses = createAction();

export const createOrUpdateCSItem = createAction();
export const receivedCSItem = createAction();

export const getModalUserLogs = createAction();
export const receivedModalUserLogs = createAction();

export const createModalUserLog = createAction();
export const receivedCreatedModaUserLog = createAction();