import React, { useEffect } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from "@material-ui/core/styles";

import * as actions from "../../actions/messages";
import * as clubProfileActions from "../../actions/clubProfile";
import { push as pushToRoute } from "connected-react-router";

import { connectTo } from "../../utils/generic";
import AnnouncementContent from "./AnnouncementContent";
import NavBar from "../HorizontalNavigation";
import Footer from "../../containers/Footer/footer";
import Roles from "../../utils/permissions";
import { PROGRAM_ROLE_IDS } from "../../utils/constants";
import { useTitle } from "../../utils/pageTitles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
  },
  main: {
    width: '100%',
    margin: "auto auto",
    height: "100%",
    minHeight: 'calc(100vh - 132px)',
    paddingTop: 50,
    [theme.breakpoints.down('md')]: {
      paddingTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 100,
    },
  }
}));

const Announcement = ({
  user,
  push,
  isLoading,
  posts,
  getPosts,
  reportMessage,
  createPost,
  createComment,
  updatePost,
  updateComment,
  deleteMessage,
  programCode,
  contentful,
  studentView,
  clubProfile: { userRoleLabels },
  getUserRoleLabels,
}) => {
  useTitle("messages");

  const classes = useStyles();
  const [loadedPosts, setLoadedPosts] = React.useState(0);
  const [role, setRole] = React.useState(0);
  const [code, setCode] = React.useState(null);
  const [loopLabels, setLoopLabels] = React.useState(null);
  const [program, setProgram] = React.useState(null);
  const [programTypeDetails, setProgramTypeDetails] = React.useState(null);

  useEffect(() => {
    if (!user) return;

    const currentRole = user.roles.find(e => e.current);
    if (currentRole && currentRole?.program?.isDefaultProgram) {
      push("/gwc"); // redirect home
      return;
    }

    if(currentRole !== role) {
      setLoadedPosts(null);
      getPosts({
        userId: user.id,
        programId: currentRole.programId.toString(),
        programCode,
        pageNumber: 0
      });
    }

    setRole(currentRole);
    if (currentRole.program) {
      setProgram(currentRole.program);
    }

    const currentProgramTypeDetails = currentRole.program.programTypeDetails;
    setProgramTypeDetails(currentProgramTypeDetails);

    if (programCode) {
      setCode(programCode);
    }
  });

  useEffect(() => {
    setLoadedPosts(posts);
  }, [posts]);

  useEffect(() => {
    if (!contentful.GlobalComponentQuery) {
      return;
    }
    const {
      GlobalComponentQuery: { loopDashboardLabels }
    } = contentful;
    setLoopLabels(loopDashboardLabels);
  }, [contentful]);

  useEffect(() => {
    if (!user || !!userRoleLabels.length) return;
    const currentRole = user.roles.find(e => e.current);
    getUserRoleLabels(currentRole.program.id);
  }, user);

  const createPostModel = (message) => {
    return {
      id: message.id || null,
      fileName: message.fileName,
      body: message.body,
      programId: role && role.programId ? role.programId : null,
      emailToAll: role && role.roleId == PROGRAM_ROLE_IDS.Facilitator && !studentView ? message.emailToAll : false,
      emailToFacilitators: role && role.roleId == PROGRAM_ROLE_IDS.Member || studentView ? message.emailToFacilitators : false
    };
  }

  const createCommentModel = (message) => {
    return {
      ...createPostModel(message),
      parentId: message.parentId      
    };
  }

  const addPost = message => {
    createPost(createPostModel(message));
  };

  const addComment = message => {
    createComment(createCommentModel(message));
  };

  const editPost = message => {
    const { isComment } = message;

    if (isComment) {
      updateComment(createCommentModel(message));
    } else {
      updatePost(createPostModel(message));
    }
  };

  const reportMessageInProgram = (id, isComment) => {
    reportMessage({ id, isComment, programId: role && role.programId ? role.programId : null });
  };

  const removeMessage = (id, isComment) => {
    deleteMessage({ id, isComment, programId: role && role.programId ? role.programId : null })
  };

    return (
    <div className={classes.root}>
      <CssBaseline />
      <NavBar />
      <main className={classes.main}>
        <AnnouncementContent
          removeMessage={removeMessage}
          addPost={addPost}
          posts={loadedPosts}
          editItem={editPost}
          addComment={addComment}
          userId={user ? user.id : null}
          isFacilitator={role && role.roleId == Roles.Facilitator}
          programCode={code}
          reportMessage={reportMessageInProgram}
          labels={loopLabels}
          isLoading={isLoading}
          push={push}
          studentView={studentView}
          userRoleLabels={userRoleLabels}
          program={program}
          programTypeDetails={programTypeDetails}
        />
      </main>
      <Footer />
    </div>
  );
};

export default connectTo(
  state => {
    return {
      user: state.auth.user,
      ...state.messages,
      contentful: state.contentful,
      studentView: state.global.studentView,
      clubProfile: state.clubProfile,
    };
  },
  {
    push: pushToRoute,
    ...actions,
    ...clubProfileActions,
  },
  Announcement
);
