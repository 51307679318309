import React from "react";
import { Formik, Field, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";

import { Modal } from "../../../../components/modals/modal";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";
import TextInput from "../../../../components/TextInput";
import * as colors from "../../../../theme/colors";
import Box from "@material-ui/core/Box";
import CustomButton from "../../../../components/customButton/customButton";

import { createMarkup } from "../../../../utils/generic";

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: "35px"
  },
  marginTop30: {
    marginTop: 30
  },
  title: {
    color: theme.palette.primary.main,
    textAlign: "center",
    margin: "25px 20px 0px"
  },
  buttonGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none"
  },
  button: {
    borderRadius: 5,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: colors.grey400,
    color: "white",
    "&:hover": {
      backgroundColor: colors.grey500
    },
    [theme.breakpoints.down("xs")]: {
      width: 75,
    },
  },
  submitButton: {
    borderRadius: 5,
    backgroundColor: colors.tealA700,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    marginLeft: 15,
    [theme.breakpoints.down("xs")]: {
      width: 70,
    },
    "&:hover": {
      backgroundColor: colors.teal500
    },
    previewContainer: {
      padding: 25,
    }
  },
  submitBtn: {
    marginLeft: 10,
    [theme.breakpoints.down("xs")]: {
      width: 70,
    },
  }
}));

const PreviewButton = ({ loadPreview, classes }) => {
  const { submitForm } = useFormikContext();

  return (
    <Button
      className={classes.button}
      onClick={() => {
        loadPreview(true);
        submitForm();
      }}
    >
      Preview
    </Button>
  );
};

const InviteStudentsModal = ({
  isOpen,
  onSave,
  onClose,
  previewEmail,
  emailTemplate
}) => {
  const classes = useStyles();
  const [showForm, setShowForm] = React.useState(true);
  const [isPreview, setIsPreview] = React.useState(false);
  const [data, setData] = React.useState({
    email: "",
    firstName: "",
    lastName: "",
    additionalMessage: ""
  });

  const loadPreview = (value) => {
    setIsPreview(value);
  };

  const onBack = () => {

    setIsPreview(false);
    setShowForm(true);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {showForm && (
        <React.Fragment>
          <Typography className={classes.title}>
            Invite others in your network to join the movement to bring free
            computer science opportunities to girls across your community!
          </Typography>
          <DialogContent className={classes.dialogContent}>
            <Formik
              initialValues={data}
              validationSchema={Yup.object({
                email: Yup.string().email("Invalid email address").required("Required"),
                firstName: Yup.string().required("Required"),
                lastName: Yup.string().required("Required"),
                additionalMessage: Yup.string()
                  .max(200)
              })}
              onSubmit={values => {
                if (isPreview) {
                  previewEmail(values);
                  setData(values);
                  setShowForm(false);
                  return;
                }

                onSave(values);
                onClose();
              }}
            >
              <Form>
                <Field
                  name="email"
                  type="text"
                  component={TextInput}
                  label="Email"
                  className={classes.marginTop30}
                />
                <Field
                  name="firstName"
                  type="text"
                  component={TextInput}
                  label="First Name"
                  className={classes.marginTop30}
                />
                <Field
                  name="lastName"
                  type="text"
                  component={TextInput}
                  label="Last Name"
                  className={classes.marginTop30}
                />
                <Field
                  name="additionalMessage"
                  type="text"
                  component={TextInput}
                  label="Additional Message (Max length 200 symbols)"
                  className={classes.marginTop30}
                />
                <div className={classes.buttonGroup}>
                  <CustomButton
                    mode="secondary"
                    onClick={onClose}
                    label={"Cancel"}
                    sel="cancel-button"
                  />
                  <div>
                    <PreviewButton classes={classes} loadPreview={loadPreview} sel="preview-button"/>
                    <CustomButton
                      className={classes.submitBtn}
                      mode="primary"
                      type="submit"
                      label={"Submit"}
                    />
                  </div>
                </div>
              </Form>
            </Formik>
          </DialogContent>
        </React.Fragment>
      )}

      {isPreview && emailTemplate && (
        <React.Fragment>
          <DialogContent className={classes.dialogContent}>
            <div className={classes.buttonGroup}>
              <Button onClick={onBack} className={classes.button}>
                Back
                  </Button>
            </div>
            <Box className={classes.previewContainer}>
              <div dangerouslySetInnerHTML={createMarkup(emailTemplate ? emailTemplate.htmlContent : "")}></div>
            </Box>
          </DialogContent>

        </React.Fragment>
      )}
    </Modal>
  );
};

export default InviteStudentsModal;
