import { call, put } from "redux-saga/effects";

import { receivedAttendances, receivedAttendance } from "../actions/attendance";

import * as attendanceApi from '../api/attendance';
import * as graphQlAttendanceMutationsApi from "../api/graphql/mutation/attendance.mutation";
import * as userRolesApi from "../api/userRoles";
import Roles from "../utils/permissions";

export function* getAttendances({ payload: { programId } }) {
  try {
    const {
      data: attendances,
    } = yield call(attendanceApi.getAttendanceByProgram, programId);

    const { data: members } = yield call(
      userRolesApi.getUserRolesByProgram,
      parseInt(programId, 10)
    );

    const students = members.filter((e) => {
      if (
        e.roleId === Roles.Student || e.roleId === Roles.SipStudent
      ) {
        return true;
      }
      return false;
    });

    yield put(
      receivedAttendances({
        attendances: attendances.filter((e) => e.user),
        students,
      })
    );
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* updateAttendance({ payload }) {
  try {
    const {
      data: { updateAttendance: attendance },
    } = yield graphQlAttendanceMutationsApi.updateAttendance(payload);
    yield put(receivedAttendance({ attendance }));
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}
