import { axiosInstance } from './axiosInstance';
import { getAuthHeader } from '../tokenStorage';

export const upsertCsItemStatus = async (payload) => {
  return axiosInstance.post(
    `/blapi/contentSectionCheckboxStatesController/create`,
    payload,
    {
      headers: getAuthHeader(),
    }
  );
};

export const getMyCsItemsStatuses = async () => {
  return axiosInstance.get(`/blapi/contentSectionCheckboxStatesController/getMyCsItemStatuses`,
    {
      headers: getAuthHeader(),
    });
}