import client from '../../../apolloClient';
import { gql } from 'apollo-boost';
import ContentSectionGroup from '../types/ContentSectionGroup';

export const getActivity = (slug, permissionContext, noCache) => {
  const desiredRole = permissionContext.roleId === 2 
    ? "clubMember"
    : permissionContext.roleId === 10
      ? "parent"
      : "clubFacilitator";
  const isFacilitator = [1,3].indexOf(permissionContext.roleId) !== -1 || false;
  return client
    .query({
      query: gql`
        {
          ActivityQuery(
            activitySlug: "${slug}", 
            permissionContext: {
              isFacilitator: ${isFacilitator}, 
              totalProgramsCount: 1, 
              desiredRole: "${desiredRole}"
            }) {
            id
            name
            goals
            slug
            materials
            estimatedTimeInMinutes
            externalUrl
            easyOfFacilitation
            permissionLevel
            tags
            isOptional
            activityType {
              name
              colorTheme
            }
            icon {
              name
              src
              alt
            }
            contentSections {
              id
              type
              ... on ContentSection {
                heading
                subheading
                content
                facilitatorNote
                visibleInPageNavigation
                onlyVisibleToFacilitators
                isOpenTextAnswer
                requiredGrading
                isOptionalTextAnswer
                contentSectionGroup {
                  ${ContentSectionGroup}
                }
              }
              ... on Surveys {
                surveyName
                surveyDescription
                surveyUrl
                salesforceId
                hqId
                programId
                userBasicData
                height
                ctaText
                isDomestic
                isEligibleForInternationalApplication
                hideLanguageSelector
              } 
              ... on QuizQuestion {
                name
                header
                description
                isOptionalQuiz
                answers {
                  ... on QuizAnswer {
                    name
                    answerTitle
                    answerBody
                    hint
                    isCorrect
                  }
                }
              } 
            }
          }
        }
      `,
      fetchPolicy: noCache ? 'no-cache' : 'cache-first'
    });
}
