import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { DialogContent } from "@material-ui/core/";
import { HQModal } from "../HQModal";
import CustomButton from "../../../components/customButton/customButton";
import * as certificatesApi from "../../../api/certificates";

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: 22,
    fontWeight: "bold",
    margin: "25px auto 15px",
    maxWidth: "90%",
    textAlign: "center"
  },
  buttonGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none",
    fontSize: 16,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 16,
    color: theme.palette.grey[500]
  },
  img: {
    width: "100%",
    minWidth: 300,
  },
}));

export const ViewCertificateModal = ({ isOpen, onClose, item }) => {
  const classes = useStyles();
  const [imgUrl, setImgUrl] = useState(null);

  useEffect(() => {
    fetchData();
  }, [item]);

  async function fetchData() {
    const url = await getImageUrl(item);
    setImgUrl(url);
  }

  const getImageUrl = async (item) => {
    const { data: { url } } = await certificatesApi.getSignedUrlCerfificate({ title: item.title, format: "png" });
    return url;
  };

  return (
    <HQModal title="View Certificate" isOpen={isOpen} onClose={onClose}>
      <DialogContent className={classes.dialogContent}>
        <img src={imgUrl} className={classes.img}  alt="Certificate"/>
        <div className={classes.buttonGroup}>
          <CustomButton
            mode="primary"
            type="submit"
            onClick={onClose}
            label={"Close"}
          />
        </div>
      </DialogContent>
    </HQModal>
  );
};
