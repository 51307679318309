import { createReducer } from 'redux-act';

import * as a from '../actions/tracking';

const getDefaultState = () => ({
    trackingId: null,
})

export default () =>
  createReducer(
    {
      [a.trackingIdLoaded]: (state, data) => {
        const { trackingId } = data;
        return {
          ...state,
          trackingId,
        };
      },
    },
    getDefaultState()
  )
