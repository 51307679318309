import React, { useEffect, useState } from "react";
import "date-fns";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Create from "@material-ui/icons/Create";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";

import * as themeColors from "../../theme/colors";
import { getUserName } from "../../utils/generic";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    maxWidth: "80%",
    marginTop: 25,
  },
  statusButton: {
    height: "-webkit-fill-available",
  },
  checkboxBtn: {
    borderRadius: "0%",
    border: "none",
    color: themeColors.themeGreen,
    "& span": {
      color: themeColors.themeGreen,
    },
  },
  commentLabel: {
    cursor: "pointer",
    border: "none",
    "&:hover": {
      // backgroundColor: colors.grey500,
    },
  },
  calendarIcon: {
    verticalAlign: "inherit",
    cursor: "pointer",
  },
  spinner: {
    width: "20px",
    height: "20px",
    verticalAlign: "middle",
    cursor: "pointer",
    color: themeColors.themeGreen,
    "& .MuiCircularProgress-colorPrimary": {
      color: themeColors.themeGreen,
      marginLeft: 9,
    },
  },
  cell: {
    border: "none",
    height: "100%",
    padding: 0,
  },
  studentContainer: {
    border: "none",
    height: "100%",
    padding: 0,
    paddingLeft: 16,
  },
  btn: {
    padding: 16,
    minWidth: 100,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#ebf4f0",
    },
  },
  commentContainer: {
    maxWidth: 500,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
    [theme.breakpoints.down('xs')]: {
      maxWidth: 200,
    }
  },
}));

const customStyles = makeStyles({
  statusContainer: {
    fontWeight: "bold",
    padding: 16,
    minWidth: 100,
    textTransform: "none",
    color: (props) => props.statusColor,
    "&:not(.statusDisabled):hover": {
      backgroundColor: "#ebf4f0",
    },
  },
  pencilContainer: {
    width: "40px",
    height: "20px",
    verticalAlign: "middle",
    cursor: "pointer",
    border: "none",
    color: (props) => props.pencilColor,
    "&:hover": {
      backgroundColor: "#ebf4f0",
    },
  },
});

const defaultStatus = {
  value: "DEFAULT",
  order: -1,
  color: "black",
};

const statuses = [
  {
    value: "PRESENT",
    order: 0,
    color: "#0D9C90",
  },
  {
    value: "ABSENT",
    order: 1,
    color: "#d46565",
  },
  {
    value: null,
    order: 2,
    color: "black",
  }
];

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const defaultColor = "grey";

const AttendanceRow = ({ row, openModal, onUpdate, isLoading, editable }) => {
  const [data, setData] = useState(row);

  const rowExist = row && row.id ? true : false;
  const isCleared = rowExist && !row.status;
  const pencilColor = rowExist ? themeColors.themeGreen : defaultColor;
  const classes = useStyles();

  const [colors, setColors] = useState({
    statusColor: themeColors.themeGreen,
    pencilColor,
  });

  const rowClasses = customStyles({ ...colors });

  useEffect(() => {
    if (!row) return;

    const currentStatus = statuses.find((e) => e.value === row.status);
    setupColorsByStatus(currentStatus);
    setData(row);
  }, [row]);

  const setupColorsByStatus = (status) => {
    if (!status) {
      setColors({
        pencilColor: themeColors.themeGreen,
        statusColor: defaultStatus.color,
      });
      return;
    }

    const { color } = status;
    setColors({
      pencilColor: themeColors.themeGreen,
      statusColor: color,
    });
  };

  const statusChange = (status) => {
    let newStatus;
    const currentStatus = statuses.find((e) => e.value === status);
    if (!currentStatus || currentStatus.order === 2) {
      newStatus = statuses.find((e) => e.order === 0);
    } else {
      newStatus = statuses.find((e) => e.order === currentStatus.order + 1);
    }

    setupColorsByStatus(newStatus);

    const newData = {
      ...data,
      status: newStatus.value,
    };
    setData(newData);
    onUpdate(newData);
  };

  const { user, status, comment } = data;
  const userName = data && user ? getUserName(data.user) : "";
  const commentView = rowExist && !comment ? "Insert a comment" : comment;

  return (
    <TableRow hover tabIndex={-1} key={row.id}>
      <TableCell className={classes.studentContainer}>{userName}</TableCell>
      <TableCell
        className={classes.cell}
        component={(props) => {
          if (!editable) {
            return (<div className={clsx(rowClasses.statusContainer, "statusDisabled")}>
              {data.id && status ? status : ""}
            </div>
          );}

          return (
            <Typography {...props}>
              <Button
                sel="status-button"
                onClick={() => statusChange(status)}
                className={rowClasses.statusContainer}
              >
                {data.id && status ? status : "?"}
              </Button>
            </Typography>
          );
        }}
      />
      <TableCell
        className={classes.cell}
        component={(props) => {
          return (
            <Typography {...props}>
              {rowExist && !isCleared && comment && (
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">{commentView}</Typography>
                    </React.Fragment>
                  }
                >
                  {editable
                    ? <Button
                      onClick={() => openModal({ ...data, commentMode: true })}
                      className={classes.btn}
                      sel="comment-button"
                    >
                      <Typography className={classes.commentContainer}>
                        {commentView}
                      </Typography>
                    </Button>
                    : <Typography className={classes.commentContainer}>
                      {commentView}
                    </Typography>
                  }
                </HtmlTooltip>
              )}
              {editable && rowExist && !isCleared && !comment && (
                <Button
                  onClick={() => openModal({ ...data, commentMode: true })}
                  className={classes.btn}
                  sel="comment-button"
                >
                  <Typography className={classes.commentContainer}>
                    {commentView}
                  </Typography>
                </Button>
              )}
            </Typography>
          );
        }}
      ></TableCell>
      {isLoading ? (
        <TableCell
          className={classes.spinner}
          component={(props) => {
            return (
              <div {...props}>
                <CircularProgress size={20} />
              </div>
            );
          }}
        ></TableCell>
      ) : (editable &&
        <TableCell
          className={rowClasses.pencilContainer}
          sel = "pencil-button"
          component={(props) => {
            if (!rowExist || isCleared) {
              return <span></span>;
            }
            return (
              <div {...props}>
                <Create
                  disabled={!rowExist}
                  className={rowClasses.pencilContainer}
                  onClick={() => {
                    if (!rowExist) {
                      return;
                    }
                    openModal(data);
                  }}
                />
              </div>
            );
          }}
        />
      )}
    </TableRow>
  );
};

export default AttendanceRow;
