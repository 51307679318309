
export const programTypes = {
  SIP: "SIP",
  CAMPUS: "CAMPUS",
  SPP: "SPP",
  COMMUNITY_PARTNERSHIP: "COMMUNITY_PARTNERSHIP",
  SPONSOR: "SPONSOR",
  CLUB: "CLUB",
  COLLEGE_LOOP: "COLLEGE_LOOP"
};

export const enrollmentTrackingEvent = {
  ENROLLMENT_FIRST_STEP_LEAVE: "ENROLLMENT_FIRST_STEP.LEAVE",
  ENROLLMENT_SECOND_STEP_LEAVE: "ENROLLMENT_SECOND_STEP.LEAVE",
  ENROLLMENT_THIRD_STEP_LEAVE: "ENROLLMENT_THIRD_STEP.LEAVE",
};

export const enrollmentCreatingChildTrackingEvent = {
  CHILD_ENROLLMENT_STEP_1_LEAVE: "CHILD_ENROLLMENT_STEP_1.LEAVE",
  CHILD_ENROLLMENT_STEP_2_LEAVE: "CHILD_ENROLLMENT_STEP_2.LEAVE",
  CHILD_ENROLLMENT_STEP_3_LEAVE: "CHILD_ENROLLMENT_STEP_3.LEAVE"
};
