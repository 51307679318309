import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core/";
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";

import * as colors from '../../theme/colors'
import { createMarkup } from "../../utils/generic";
import { handleKeyPress } from '../../utils/accessibility';

const useStyles = makeStyles({
  card: {
    width: 400,
    cursor: "pointer",
    paddingRight: 10,
    margin: "20px 25px 20px 0px",
  },
  cardTitle: {
    fontWeight: 500,
    fontSize: 18,
    fontWeight: "bold",
  },
  cardHeader: {
    paddingBottom: 0,
  },
  cardContent: {
    padding: 0,
    fontSize: 14,
    color: colors.lightThemeGrey,
    "& a": {
      color: colors.darkThemeGreen,
      textDecoration: "underline",
    },
    "& li": {
      position: 'relative',
      listStyle: 'none',
      paddingBottom: 5,
    },
    "& ul > li:before": {
      content: "'*'",
      position: 'absolute',
      top: '-1px',
      left: '-30px',
      '-moz-box-sizing': 'border-box',
      '-webkit-box-sizing': 'border-box',
      boxSizing: 'border-box',
      width: '2em',
      'font-size': 20,
      textAlign: 'center',
    }
  },
});

const ApplyPageCard = ({ section, onClick }) => {

  const classes = useStyles();

  return (
    <Card
      tabIndex="0"
      onKeyPress={handleKeyPress(() => onClick())}
      onClick={() => onClick()}
      className={classes.card}
      >
        <CardHeader
          title={
            <Typography className={classes.cardTitle}>{section.heading}</Typography>
          }
          className={classes.cardHeader}
        />
        <CardContent className={classes.cardContent}>
          <Box dangerouslySetInnerHTML={createMarkup(section.content)} />
        </CardContent>
    </Card>
  );
};

export default ApplyPageCard;

