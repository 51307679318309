export const EDITOR_BLOCK_TYPES = {
  UnorderedList: 'unordered-list-item',
  OrderedList: 'ordered-list-item',
  Paragraph: 'unstyled',
  HeadingOne: 'header-one',
  HeadingTwo: 'header-two',
  HeadingThree: 'header-three',
  HeadingFour: 'header-four',
  HeadingFive: 'header-five',
  HeadingSix: 'header-six',
};

export const linkPopperInitialValues = {
  text: '',
  link: '',
};

export const EDITOR_INLINE_STYLES = {
  Bold: 'BOLD',
  Italic: 'ITALIC',
  Underline: 'UNDERLINE',
  Code: 'CODE',
};

export const EDITOR_KEYBOARD_STATUS = {
  Handled: 'handled',
  NotHandled: 'not-handled',
};

export const ENTITY_TYPES = {
    LINK: 'LINK',
};

export const ERROR_MESSAGES = {
  FIELD_REQUIRED: 'Required',
  VALID_LINK: 'Please enter a valid url',
};
