import * as FormFields from "./FormFields";
import Image from './Image';
import Messaging from './Messaging';

const ChildrenMessaging = `
  name
  childInfoSectionBasic
  childKinshipInput {
    ${FormFields.commonFieldAttributes}
    ${FormFields.selectField}
  }
  childBirthDateInput {
    ${FormFields.commonFieldAttributes}
    ${FormFields.formField}
  }
  childInfoSectionOptional
  childEmailInput {
    ${FormFields.commonFieldAttributes}
    ${FormFields.formField}
  }
  childInfoFormConsentText
  childInfoFormSaveButton
  childInfoFormMessages {
    ${Messaging}
  }
  childrenTabName
  childrenTabEditProfileCtaLabel
  childrenTabJoinClubCtaLabel
  childrenTabDefaultAvatar {
    ${Image}
  }
  newChildDialogHeading
  newChildDialogFirstNameInput {
    ${FormFields.commonFieldAttributes}
    ${FormFields.formField}
  }
  newChildDialogLastNameInput {
    ${FormFields.commonFieldAttributes}
    ${FormFields.formField}
  }
  newChildDialogConfirmLabel
  newChildDialogCancelLabel
  selectChildDialogHeading
  selectChildDialogSubheading
  selectChildDialogCloseCtaLabel
`;

export default ChildrenMessaging;
