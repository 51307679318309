import React from "react";

import {
  Box,
} from "@material-ui/core/";

import linkifyHtml from "linkify-html";

import { makeStyles } from "@material-ui/core/styles";
import * as colors from "../../../theme/colors";
import Divider from "@material-ui/core/Divider";
import { createMarkup } from "../../../utils/generic";
import Comments from "./comments";
import AnswerStatusDetails from "../../../components/AnswerStatusDetails";

const useStyles = makeStyles(() => ({
  boxWrapper: {
    width: '100%',
    "&:not(:last-child)": {
      marginBottom: 50,
    },
  },  
  createdAtLabel: {
    fontSize: 12,
    color: colors.lightGrey,
  },
  cardContainer: {
    width: '100%',
    marginTop: 20,
  },
  answerBox: {
    color: colors.darkThemeBlueGray,
    minHeight: 100,
    padding: 20,
    "& a": {
      color: colors.darkThemeGreen,
    },
    fontSize: 18,
  },
  itemDivider: {
    marginTop: 40,
  },
  simpleQuestionAnswerBox: {
    backgroundColor: colors.greyLight2,
  },
  hideBox: {
    display: 'none',
  }
}));

export const AnswerCard = ({
  item,
  isLast,
  isVisible = true,
}) => {
  const classes = useStyles();
  return (
    <Box className={`${isVisible ? classes.boxWrapper : classes.hideBox}`}>
      <AnswerStatusDetails
          createdAt={item.createdAt}
          status={item.status}
          statusChangedAt={item.statusChangedAt}
          statusChanger={item.statusChanger}
      />
      <Box key={item.id} className={classes.cardContainer}>
        <Box className={`${classes.answerBox} ${classes.simpleQuestionAnswerBox}`} dangerouslySetInnerHTML={createMarkup(linkifyHtml(item.content, { target: "_blank" }))} />
        {Comments(item)}
      </Box>
      {!isLast && <Divider className={classes.itemDivider} />}
    </Box>
  );
}
