import menu from "../types/Menu";
import messaging from "../types/Messaging";
import { changePassword } from '../types/modules';
import client from "../../../apolloClient";
import { gql } from "apollo-boost";

export function ProfilePageQuery() {
  return client.query({
    query: gql`
          {
            ProfilePageQuery {
              name
              profileTabs {
                ${menu}
              }
              messages {
                ${messaging}
              }
              changePasswordForm {
                ${changePassword}
              }
            }
          }

        `
  });
}
