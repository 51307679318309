import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import { StyledFormHelper } from "../../loginSignup/customFormHelper";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import ListItemText from "@material-ui/core/ListItemText";
import * as colors from "../../../theme/colors";
import debounce from "debounce";
import { Box, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiSelect-selectMenu": {
      color: colors.darkThemeBlueGray,
    },
    "& .Mui-disabled": {
      opacity: "0.5",
    },
  },
  checkBoxItem: {
    color: colors.greenA300,
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: colors.greenA300,
    },
    backgroundColor: "white !important",
    "&.Mui-focusVisible": {
      outline: `2px dotted ${colors.grey400}`,
      outlineOffset: -2
    },
  },
  errorLabel: {
    color: colors.redA400,
  },
  subLabel: {
    fontSize: 18,
    marginLeft: 20,
  },
  searchInput: {
    margin: "15px auto",
    width: "90%",
  },
  label: {
    zIndex: 2,
    padding: "0 2px",
  },
  adortmentBorder: {
    marginRight: 10,
  },
  inputWrapper: {
    display: "flex",
    outlineWidth: "0",
    "& .MuiInput-underline:before": {
      borderBottomColor: colors.lightThemeGreen,
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: colors.themeGreen,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: colors.lightThemeGreen,
    },
  },
  boxLabel: {
    display: "flex",
  },
  circularItem: {
    marginLeft: 5,
  },
}));

const getGroupedItems = (array, flag, eventValue) => {
  const ids = array.map((e) => e.id);
  let cleanedItems = eventValue.filter(function (el) {
    return !ids.includes(el);
  });

  cleanedItems = cleanedItems.filter((e) => e > 0);
  return flag ? cleanedItems : [...cleanedItems, ...ids];
};

const getIsAllFlag = (array, value) => {
  const ids = array.map((e) => e.id);
  const cleanedItems = value.filter(function (el) {
    return ids.includes(el);
  });
  return cleanedItems.length === array.length;
};

const MultipleGroupedSelector = ({
  className,
  field: { name, value },
  label,
  form: { errors, touched, setFieldValue },
  options,
  helperText,
  onValueChange,
  Icon,
  errorIsHtml,
  isLoading,
  disabled,
}) => {
  const classes = useStyles();
  const [searchKey, setSearchKey] = React.useState(null);
  const [hasViewers, setHasViewers] = React.useState(false);

  useEffect(() => {
    if (!options || !options.viewers) return;
    setHasViewers(options.viewers && options.viewers.length > 0);
  }, [options]);

  useEffect(() => {
    if (!options) return;
    const { students, teachers, viewers } = options;
    const selectedProgramMembers = value.filter(id => {
      return students.length && students.some(student => student.id === id) ||
      teachers.length && teachers.some(teacher => teacher.id === id) ||
      viewers.length && viewers.some(viewer => viewer.id === id);
    });
    setFieldValue(name, selectedProgramMembers);
  }, [options]);

  if (!options) return null;
  let error = errors[name];
  let isTouched = touched[name];

  const searchInputChangeDebounced = debounce((searchKey) => {
    searchKey = !!searchKey.trim ? searchKey.trim() : "";
    setSearchKey(searchKey);
  }, 500);

  const sortItem = (a, b) => {
    return a.label > b.label ? 1 : -1;
  };

  const selectedAllTeachers = getIsAllFlag(options.teachers, value);
  const selectedAllStudents = getIsAllFlag(options.students, value);
  const selectedAllViewers = getIsAllFlag(options.viewers, value);

  return (
    <React.Fragment>
      <FormControl
        variant="outlined"
        fullWidth
        className={className}
        helperText={helperText}
      >
        <Select
          id={name}
          name={name}
          SelectDisplayProps={{role: 'listbox'}}
          className={classes.root}
          multiple
          label={helperText}
          tabSelectsValue={false}
          disabled={isLoading || disabled}
          error={!!error && isTouched}
          helperText={
            error && isTouched ? (errorIsHtml ? "" : error) : helperText
          }
          value={value}
          renderValue={(selected) => {
            return (
              <Box className={classes.boxLabel}>
                <span>{selected.length === 0 ? "Select" : selected.length} Attendees</span>
                {isLoading && (
                  <CircularProgress
                    className={classes.circularItem}
                    size={20}
                  />
                )}
              </Box>
            );
          }}
          MenuProps={{
            getContentAnchorEl: () => null,
          }}
          InputProps={{
            placeholder: label,
            startAdornment: Icon ? (
              <InputAdornment position="start">
                <Icon />
              </InputAdornment>
            ) : null,
          }}
          startAdornment={
            Icon ? (
              <div className={classes.adortmentBorder}>
                {" "}
                <Icon />
              </div>
            ) : null
          }
          displayEmpty
          onChange={(event) => {
            if (event.target.value.includes(-1)) {
              const items = getGroupedItems(
                options.teachers,
                selectedAllTeachers,
                event.target.value
              );
              setFieldValue(name, items);
              return;
            }
            if (event.target.value.includes(-2)) {
              const items = getGroupedItems(
                options.students,
                selectedAllStudents,
                event.target.value
              );
              setFieldValue(name, items);
              return;
            }
            if (event.target.value.includes(-3)) {
              const items = getGroupedItems(
                options.viewers,
                selectedAllViewers,
                event.target.value
              );
              setFieldValue(name, items);
              return;
            }
            const items = event.target.value.filter((e) => e > 0);
            if (onValueChange) {
              onValueChange(event.target.value);
            }

            setFieldValue(name, items);
          }}
          onClose={() => {
            setSearchKey(null);
          }}
        >
          <MenuItem
            className={classes.inputWrapper}
            style={{ backgroundColor: "transparent" }}
          >
            <Input
              key={"Search"}
              autoFocus={true}
              label={"Search"}
              tabIndex={0}
              placeholder={"Search"}
              className={classes.searchInput}
              style={{ backgroundColor: "transparent" }}
              onClickCapture={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              onChange={(event) => {
                searchInputChangeDebounced(event.target.value);
              }}
            />
          </MenuItem>

          <div className={classes.subLabel}>Facilitator / Teacher</div>
          {!searchKey && (
            <MenuItem className={classes.checkBoxItem} key={-1} value={-1}>
              <Checkbox checked={selectedAllTeachers} />
              <ListItemText primary="Select All" />
            </MenuItem>
          )}
          {options.teachers
            .filter(
              (e) =>
                !searchKey ||
                e.label.toLowerCase().includes(searchKey.toLowerCase())
            )
            .sort(sortItem)
            .map(({ id, label }) => (
              <MenuItem
                className={classes.checkBoxItem}
                selected={false}
                key={id}
                value={id}
              >
                <Checkbox checked={value.includes(id)} />
                <ListItemText primary={label} />
              </MenuItem>
            ))}

          <div className={classes.subLabel}>Members</div>
          {!searchKey && (
            <MenuItem className={classes.checkBoxItem} key={-2} value={-2}>
              <Checkbox checked={selectedAllStudents} />
              <ListItemText primary="Select All" />
            </MenuItem>
          )}
          {options.students
            .filter(
              (e) =>
                !searchKey ||
                e.label.toLowerCase().includes(searchKey.toLowerCase())
            )
            .sort(sortItem)
            .map(({ id, label }) => (
              <MenuItem
                className={classes.checkBoxItem}
                selected={false}
                key={id}
                value={id}
              >
                <Checkbox checked={value.includes(id)} />
                <ListItemText primary={label} />
              </MenuItem>
            ))}

          {hasViewers && <div className={classes.subLabel}>Partners</div>}
          {hasViewers && !searchKey && (
            <MenuItem className={classes.checkBoxItem} key={-3} value={-3}>
              <Checkbox checked={selectedAllViewers} />
              <ListItemText primary="Select All" />
            </MenuItem>
          )}
          {hasViewers &&
            options.viewers
              .filter(
                (e) =>
                  !searchKey ||
                  e.label.toLowerCase().includes(searchKey.toLowerCase())
              )
              .sort(sortItem)
              .map(({ id, label }) => (
                <MenuItem
                  className={classes.checkBoxItem}
                  selected={false}
                  key={id}
                  value={id}
                >
                  <Checkbox checked={value.includes(id)} />
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
        </Select>
        {error &&
          isTouched &&
          (errorIsHtml ? (
            <StyledFormHelper helperText={error} isError={true} isHtml={true} />
          ) : (
            <FormHelperText className={classes.errorLabel}>
              {error}
            </FormHelperText>
          ))}
      </FormControl>
    </React.Fragment>
  );
};

export default MultipleGroupedSelector;
