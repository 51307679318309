import React from "react";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles({
  chip: { margin: "0 5px", width: "fit-content" },
})

const ChipItem = ({
  className,
  field: { name, value },
  label,
  form: { errors, touched, setFieldValue },
  helperText,
  errorIsHtml,
  ...props
}) => {
  const classes = useStyles();

  let error = errors[name];
  let isTouched = touched[name];
  if (!value) {
    return null;
  }

  return (
    <React.Fragment>
      <FormControl
        variant="outlined"
        helperText={helperText}
      >
        <Chip
          {...props}
          id={name}
          clickable={true}
          name={name}
          className={className ? clsx(classes.chip, className) : classes.chip}
          label={label}
          error={!!error && isTouched}
          helperText={
            error && isTouched ? (errorIsHtml ? "" : error) : helperText
          }
          value={value}
          onDelete={() => {
            setFieldValue(name, !value);
          }}
        />
      </FormControl>
    </React.Fragment>
  );
};

export default ChipItem;
