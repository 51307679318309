import React from "react";
import { CSVLink } from 'react-csv';

import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  Tooltip,
} from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';

import TextCustomButton from "../../../../components/text-button/text-button";
import DasboardFilters from "../components/dashboardFilters";
import ClubCard from "../components/ClubCard";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    padding: "0 30px",
    width: "calc(100% - 60px)",

    [theme.breakpoints.down("md")]: {
      padding: "0 5px"
    },
    [theme.breakpoints.down("xs")]: {
      width: "calc(100% - 10px)",
    },
  },
  titleBox: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  filterContainer: {
    display: "flex",
    alignItems: "flex-start",
  },
  basicGrid: {
    width: "33%",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: 0,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginRight: 0,
    },
  },
  wrapper: {
    display: 'flex',
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  downloadBtn: {
    textDecoration: "none",
  }
}));

const ClubDetails = ({
  partnershipYears,
  selectedYear,
  onChangeYear,
  sortYears,
  isInternational,
  filters,
  enableCurriculums,
  enableRenewals,
  applyFilters,
  labels,
  clubs,
}) => {
  const classes = useStyles();

  const createClubsTable = (clubs) => {
    const data = clubs.map((club) =>
      createTableData({
        ...club,
        facilitatorsLength: club.facilitators.length,
        participantsCount: club.participantsCount >= 3 ? "Yes" : "No",
      })
    );
    return data;
  };

  const createTableData = ({
    code,
    name,
    year,
    sfCurriculumType,
    renewal,
    facilitatorsLength,
    participantsCount,
    detailedStatus,
    launchDate,
    address,
    state,
    applicationDate,
    decisionMaker,
    decisionMakerEmail,
    decisionMakerPhone,
    facilitatorFirstName,
    facilitatorLastName,
    facilitatorEmail,
    hostOrganizationName,
  }) => {
    return {
      code,
      name,
      year,
      sfCurriculumType,
      renewal,
      facilitatorsLength,
      participantsCount,
      detailedStatus,
      launchDate,
      address,
      state,
      applicationDate,
      decisionMaker,
      decisionMakerEmail,
      decisionMakerPhone,
      facilitatorFirstName,
      facilitatorLastName,
      facilitatorEmail,
      hostOrganizationName,
    };
  };

  const renderCsv = () => {
    if (labels) {
      const config = isInternational
        ? JSON.parse(labels.partnerDashboardInternationalClubsTableConfig)
        : JSON.parse(labels.partnerDashboardClubsTableConfig);

      const headers = config.columns.map(({ id, label }) => ({
        key: id,
        label,
      }));

      return (
        <Tooltip title="Download table as CSV">
          <CSVLink
            data={createClubsTable(clubs)}
            filename={`${config.title}.csv`}
            headers={headers}
            className={classes.downloadBtn}
          >
            <TextCustomButton
              mode="secondary"
              label={'DOWNLOAD AS CSV'}
              startIcon={<GetAppIcon />}
            >
            </TextCustomButton>
          </CSVLink>
        </Tooltip>
      )
    }
  }

  return (
    <div>
      <Box className={classes.wrapper}>
        {partnershipYears && selectedYear && <Box className={classes.filterContainer}>
          <DasboardFilters
            isDomestic={!isInternational}
            filters={filters}
            onChange={(filters) => applyFilters(filters)}
            enableRenewals={enableRenewals}
            enableCurriculums={enableCurriculums}
            yearOnChange={onChangeYear}
            yearOptions={partnershipYears.sort(sortYears)}
            yearValue={selectedYear}
          />
        </Box>}
        <Box>{renderCsv()}</Box>
      </Box>
      <Grid container>
        {clubs.map((club) => 
          <ClubCard
            club={club}
            labels={labels}
          />
        )}
      </Grid>
    </div>
  )
}

export default ClubDetails;