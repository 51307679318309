import { call, put } from "redux-saga/effects";

import {
  receivedMyActivitiesInfo,
  receivedUserActivitiesByProgram,
  receivedUserActivityInfo,
  receivedAdditionalUserActivityInfo,
  receivedCSItemStatuses,
  receivedCSItem,
  receivedModalUserLogs,
  receivedCreatedModaUserLog,
  receivedUserProgramLogs,
} from "../actions/userActivitiesInfo";

import * as userActivitiesInfoApi from "../api/userActivitiesInfo";
import * as csItemStatusesApi from "../api/contentSectionItemStatuses";

export function* getUserActivitesByProgramCode({ payload }) {
  try {
    const { programId, code } = payload;

    let { data } = yield call(
      userActivitiesInfoApi.getUserActivityInfoByProgramId,
      payload,
    );

    yield put(
      receivedUserActivityInfo({
        code,
        programId,
        userActivitiesInfo: data,
      })
    );
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}


export function* getAdditionalUserActivitesByProgramCode({ payload }) {
  try {
    const { programId, code } = payload;

    let { data } = yield call(
      userActivitiesInfoApi.getUserActivityInfoByProgramId,
      payload
    );

    yield put(
      receivedAdditionalUserActivityInfo({
        code,
        programId,
        userActivitiesInfo: data,
      })
    );
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* getMyUserActivitiesByProgram({ payload }) {
  try {
    const { id: programId } = payload;

    let { data } = yield call(userActivitiesInfoApi.getMyProgress, programId);

    yield put(
      receivedMyActivitiesInfo({
        data,
      })
    );
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* getUserProgramLogs({ payload }) {
  try {
    const { programId } = payload;

    let { data } = yield call(userActivitiesInfoApi.getMyProgress, programId);

    let { data: csStatuses } = yield call(csItemStatusesApi.getMyCsItemsStatuses);

    let { data: modalLogs } = yield call(userActivitiesInfoApi.getModalUserLogs, payload);
    yield put(receivedUserProgramLogs({
      data,
      csStatuses,
      modalLogs,
    }));
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* deleteMyActivityProgress({ payload: { programId, items } }) {
  try {
    for (let i = 0; i < items.length; i++) {
      yield call(userActivitiesInfoApi.removeMyActivityProgress, items[i]);
    }

    let { data } = yield call(userActivitiesInfoApi.getMyProgress, programId);

    yield put(
      receivedMyActivitiesInfo({
        data,
      })
    );
  } catch (e) {
    console.log(e);
  }
}

export function* getMyUserActivitiesByPartnership({ payload }) {
  try {
    const { id: partnershipId } = payload;

    let { data } = yield call(userActivitiesInfoApi.getMyPartnershipProgress, partnershipId);

    yield put(
      receivedMyActivitiesInfo({
        data,
      })
    );
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* deleteMyActivityProgressInPartnership({ payload: { partnershipId, items } }) {
  try {
    for (let i = 0; i < items.length; i++) {
      yield call(userActivitiesInfoApi.removeMyActivityProgress, items[i]);
    }

    let { data } = yield call(userActivitiesInfoApi.getMyPartnershipProgress, partnershipId);

    yield put(
      receivedMyActivitiesInfo({
        data,
      })
    );
  } catch (e) {
    console.log(e);
  }
}

export function* getUserActivitiesByProgram({ payload }) {
  try {
    const { programId, userId } = payload;

    let { data } = yield call(
      userActivitiesInfoApi.getUserProgress,
      programId,
      userId
    );

    yield put(
      receivedUserActivitiesByProgram({
        data,
      })
    );
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* getAllCsItemStatuses() {
  try {
    let { data } = yield call(csItemStatusesApi.getMyCsItemsStatuses);
    yield put(receivedCSItemStatuses(data));
  } catch (e) {
    console.log(e);
  }
}

export function* createOrUpdateCSItem({ payload }) {
  try {
    let { data } = yield call(csItemStatusesApi.upsertCsItemStatus, payload);
    yield put(receivedCSItem(data));
  } catch (e) {
    console.log(e);
  }
}

export function* getModalUserLogs({ payload }) {
  try {
    let { data } = yield call(userActivitiesInfoApi.getModalUserLogs, payload);
    yield put(receivedModalUserLogs(data));
  } catch (e) {
    // TODO: throw generic error
    console.log('TODO: handle error here');
    console.log(e);
  }
}

export function* createModalUserLog({ payload }) {
  try {
    let { data } = yield call(userActivitiesInfoApi.createModalUserLog, payload);
    yield put(receivedCreatedModaUserLog(data));
  } catch (e) {
    // TODO: throw generic error
    console.log('TODO: handle error here');
    console.log(e);
  }
}
