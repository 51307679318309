import React from "react";
import clsx from 'clsx';

import { makeStyles } from "@material-ui/core/styles";

import {
  Typography,
  Link,
  Grid,
  Box,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  CardHeader,
  ListItemText,
  Divider,
  Breadcrumbs,
  CardActionArea
} from "@material-ui/core";

import ChartCounter from "./components/chartCounter";

import * as colors from "../../../theme/colors";
import parse from "html-react-parser";

import TextCustomButton from "../../../components/text-button/text-button";
import CustomButton from "../../../components/customButton/customButton";
import CopyLinkButton from "../../../components/copyLinkButton/copyLinkButton";

import PageTitle from "../../../components/page-title/pageTitle";
import SpreadWorldModal from "../CommunityPartnershipContainer/modals/spreadWorld.modal";
import InviteStudentsModal from "../CommunityPartnershipContainer/modals/inviteStudents.modal";
import CommunityPartnerCard from "../CommunityPartnershipContainer/components/CommunityPartnerCard";
import ClubDetails from "../CommunityPartnershipContainer/ClubDetails"

import { windowOpen } from "../../../utils/generic";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    padding: "0 30px",
    width: "calc(100% - 60px)",

    [theme.breakpoints.down("md")]: {
      padding: "0 5px"
    },
    [theme.breakpoints.down("xs")]: {
      width: "calc(100% - 10px)",
    },
  },
  yearContainer: {
    display: "flex",
    borderTop: `2px solid ${colors.greyLight2}`,
    borderBottom: `2px solid ${colors.greyLight2}`,
    marginBottom: 15,

    "&:hover": {
      borderTop: `2px solid ${colors.greenA350}`,
      borderBottom: `2px solid ${colors.greenA350}`,
    },
  },
  yearLabel: {
    marginRight: "10px",
  },
  selectedYearLabel: {
    backgroundColor: colors.white,
    display: "inline-flex",
    fontFamily: "'Roboto Mono',monospace",
    border: `2px solid ${colors.greyLight2}`,

    "&:hover": {
      backgroundColor: colors.greenA350,
    },
  },
  filterCointainer: {
    maxWidth: 320,
    height: 150,
    margin: "0 20px 20px 0",
  },
  questionLinkContainer: {
    marginBottom: 11,
    minWidth: 270,
    textAlign: "end",
  },
  questionLink: {
    color: colors.darkThemeGreen,
    fontWeight: "bold",
    marginBottom: "10px",
    "&:hover": {
      textDecoration: "none",
    },
  },
  linkContainer: {
    margin: "20px 0",
  },
  emptyDashboardContainer: {
    padding: 10,
    "& a": {
      textDecoration: "none",
      color: colors.lightBlueA400,
    },
  },
  tableBox: {
    padding: 0,
  },
  yearBox: {
    margin: "10px 0",
  },
  titleBox: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  welcomeMsgWrapper: {
    margin: "15px 0 0 0",
    padding: "20px 16px 10px 10px"
  },
  welcomeMsgContainer: {
    padding: 0,
    "& .MuiTypography-h5": {
      fontWeight: 900,
    },
  },
  content: {
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.down("xs")]: {
      display: "block",
      marginTop: 15,
    },
  },
  imageContainer: {
    width: 100,
    height: 100,
    display: "flex",
    overflow: "hidden",
    position: "relative",
    fontSize: "1.25rem",
    alignItems: "center",
    flexShrink: 0,
    lineHeight: 1,
    borderRadius: "50%",
    justifyContent: "center",
    marginRight: 15,

    [theme.breakpoints.down("xs")]: {
      margin: "0 auto 15px",
      width: 65,
      height: 65,
    },
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 0,
  },
  inviteBtnContainer: {
    display: "flex",
    justifyContent: "center",
    padding: 0
  },
  containerAction: {
    padding: '0 10px 20px'
  },
  inviteBtn: {
    height: 42,
    minWidth: 150,
    padding: '6px 16px'
  },
  cardTitle: {
    fontSize: 16,
    textTransform: "uppercase",
    fontWeight: 700,
  },
  gridItemLeft: {
    paddingRight: 10,
  },
  cardContentCounters: {
    padding: '20px 0px 20px 0px',
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  cardContent: {
    paddingBottom: 20,
    paddingTop: 0,
  },
  cardContentResources: {
    paddingBottom: 20,
    textAlign: 'left',
    textTransform: "uppercase",
    fontWeight: 700,
  },
  cardRecentlyViewed: {
    color: colors.lightGrey,
    fontSize: 14,
  },
  divider: {
    height: 100,
  },
  copyBtn: {
    fontSize: 12,
    fontWeight: "normal",
    cursor: "pointer",
  },
  item: {
    display: "flex",
    "& .MuiTypography-body2": {
      margin: "auto 0",
      fontSize: '1rem',
      color: colors.dartThemeBlueGray,

      "& button": {
        textAlign: 'left'
      }
    },
  },
  exitBtn: {
    height: 42,
    color: colors.darkThemeGreen,
  },
  partnershipInfoContainer: {
    alignItems: "center",
    wordBreak: "break-word",
    fontSize: "1rem",
    fontWeight: 400,
    marginTop: "-15px",
    color: colors.dartThemeBlueGray,
    lineHeight: 1.4,
    paddingBottom: 20,
    "& a": {
      textDecoration: "none",
      color: colors.lightBlueA400,
    },
    "& .highlight": {
      fontWeight: 700,
      color: colors.dartThemeBlueGray,
      fontSize: "1.5rem",
      margin: 0
    },
  },
  emptyDashboardText: {
    marginTop: "-25px",
  },
  basicGrid: {
    width: "33%",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: 0,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginRight: 0,
    },
  },
  gridItemRight: {
    marginTop: 15,
    [theme.breakpoints.down("xs")]: {
      margin: "10px 0",
    },
  },
  gridItem: {
    marginTop: 15,
    marginRight: 15,
    [theme.breakpoints.down("xs")]: {
      margin: "10px 0",
    },
  },
  boxContainer: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  breadCrumbs: {
    fontSize: 12,
    marginTop: 20,
    marginBottom: 10,
    marginLeft: '-10px',
  },
  breadCrumbsLink: {
    color: "#03988C",
    marginLeft: 10,
    cursor: 'pointer',
  },
  breadCrumbsName: {
    color: colors.lightThemeGrey,
    fontSize: 12,
  },
  resourceContent: {
    backgroundColor: colors.darkThemeGreen,
    paddingBottom: 32
  },
  cardIcon: {
    maxHeight: 100,
    maxWidth: 100
  },
  cardIconBox: {
    alignItems: 'center',
    display: 'flex',
    height: 90,
    margin: '18px 10px 35px 10px',
    justifyContent: 'center',
    textAlign: 'center',
  },
  resourceCardTitle: {
    color: colors.white
  }
}));

const MESSAGES_KEY = "gwcPartnershipAnnouncements";

const CommunityPartnershipContainer = ({
  partnership,
  isInternational,
  partnershipYears,
  selectedYear,
  counters,
  onChangeYear,
  clubs,
  stats,
  filters,
  applyFilters,
  narrowFilter,
  labels,
  enableRenewals,
  enableCurriculums,
  previewEmail,
  sendPartnerInvitationEmail,
  contentful,
  user,
  push,
  partnerships,
  getMyUserActivitiesByPartnership,
  currentRole,
  isLoading,
}) => {
  const classes = useStyles();
  const [showAnnouncement, setShowAnnouncement] = React.useState(true);
  const [isSpreadOpen, setIsSpreadOpen] = React.useState(false);
  const [inviteStudentsLabels, setInviteStudentsLabels] = React.useState(null);
  const [isInviteStudentOpen, setIsInviteStudentOpen] = React.useState(false);
  const [clubDetails, setClubDetails] = React.useState(false);

  React.useEffect(() => {
    if (!currentRole || !currentRole) {
      return;
    }
    if (currentRole.partnership && currentRole.partnership.id) {
      getMyUserActivitiesByPartnership({ id: currentRole.partnership.id });
    }
  }, []);

  React.useEffect(() => {
    if (contentful.GlobalComponentQuery) {
      const {
        inviteStudentsLabels,
      } = contentful.GlobalComponentQuery;
      setInviteStudentsLabels(inviteStudentsLabels);
    }

  }, [contentful]);

  const replaceLinkTagWithButton = React.useCallback((label) => {
    return parse(label, {
      replace: ({ name, type, attribs }) => {
        if (name === "a" && type === "tag" && attribs?.href) {
          // The attribute contains a link like "mailto:{{email}}"
          return <CopyLinkButton link={attribs.href.split?.(":")[1]} />;
        }
      },
    });
  }, []);

  const PartnerDashboardInternationalBarHeader = React.useMemo(() => {
    if (!labels.partnerDashboardInternationalBarHeader || !isInternational)
      return;
    return replaceLinkTagWithButton(
      labels.partnerDashboardInternationalBarHeader
    );
  }, [labels, isInternational]);

  const PartnerDashboardNavigationBarHeader = React.useMemo(() => {
    if (!labels.partnerDashboardNavigationBarHeader || isInternational) return;
    return replaceLinkTagWithButton(labels.partnerDashboardNavigationBarHeader);
  }, [labels, isInternational]);


  const sortYears = (a, b) => {
    const aYear = parseInt(a, 10);
    const bYear = parseInt(b, 10);

    return aYear < bYear ? 1 : -1;
  };

  const hideAnnoucement = () => {
    localStorage.setItem(MESSAGES_KEY, 'true');
    setShowAnnouncement(false);
  };

  const triggerSpread = (value) => {
    setIsSpreadOpen(value);
  };

  const loadEmailPreview = (values) => {
    const { communityPartnership } = partnerships;
    previewEmail({
      ...values,
      user,
      cpName: communityPartnership.name,
      isInternational,
    });
  };

  const sendInvitationEmail = (values) => {
    const { communityPartnership } = partnerships;
    const { firstName, lastName, email, additionalMessage } = values;

    const data = {
      userId: user.id,
      invitedFirstName: firstName,
      invitedLastName: lastName,
      email,
      communityPartnershipId: parseInt(communityPartnership.id),
      additionalMessage,
    };
    sendPartnerInvitationEmail(data);
  };

  const triggerInviteStidentModal = () => {
    setIsSpreadOpen(false);
    setIsInviteStudentOpen(!isInviteStudentOpen);
  };

  const shouldRenderInformativeText = !stats.length;
  const isAnnouncementDismissed = localStorage.getItem(MESSAGES_KEY);

  const openClubDetails = (club) => {
    setClubDetails(club);
  };

  const { hqConfig } = contentful;
  const currentCurriculum = contentful.curriculums[hqConfig.communityPartnershipResourcesCurriculum]?.nodes['community-partner-resources'] || {};
  const { icon, name } = currentCurriculum;

  const activityNames = [];
  const getName = (nodes) => {
    if (!nodes) {
      return;
    };
    
    for (let value of Object.values(nodes)) {
      const {slug, name} = value
      const treeItem = {slug, name}
      activityNames.push(treeItem);
  }}

  getName(currentCurriculum?.nodes)
  currentCurriculum?.nodes && currentCurriculum?.nodes['cp-toolkit'] && getName(currentCurriculum?.nodes['cp-toolkit']?.nodes);

  const clubYear = selectedYear ? `${selectedYear}-${(parseInt(selectedYear) + 1).toString().substring(2)}` : null;

  return (
    <div className={classes.root}>
      {clubDetails ? (<Box>
        <Breadcrumbs
            className={classes.breadCrumbs}
            separator=" / "
            aria-label="breadcrumb"
          >
            <Link underline="hover" className={classes.breadCrumbsLink} onClick={() => { setClubDetails(false) }}>
              Dashboard
            </Link>
            <Typography className={classes.breadCrumbsName}>
              Club Details
            </Typography>
          </Breadcrumbs>
        <Box className={classes.titleBox}>
          <PageTitle title="CLUB DETAILS" isLoading={isLoading} />
        </Box>
      </Box>)
      : (<Box className={classes.titleBox}>
        <PageTitle title="DASHBOARD" isLoading={isLoading} />
        <Box className={classes.questionLinkContainer}>
          <Link
            href={
              !isInternational
                ? labels.partnerDashboardFaqCta.url
                : labels.partnerDashboardFaqCta.alternateText
            }
            className={classes.questionLink}
          >
            {labels.partnerDashboardFaqCta.text}
          </Link>
        </Box>
      </Box>)}
      {!isAnnouncementDismissed && showAnnouncement && !clubDetails && (
        <Card className={classes.welcomeMsgWrapper}>
          <CardContent className={classes.welcomeMsgContainer}>
            <div className={classes.content}>
              <CardMedia
                className={classes.imageContainer}
                image={
                  labels ? labels.partnerDashboardWelcomeMessage.icon.src : ""
                }
              />
              <Box className={classes.partnershipInfoContainer}>
              {Boolean(labels) &&
                  (isInternational
                    ? PartnerDashboardInternationalBarHeader
                    : PartnerDashboardNavigationBarHeader)}
              </Box>
            </div>
          </CardContent>
          <CardActions className={classes.btnContainer}>
            <TextCustomButton
              className={classes.exitBtn}
              mode="secondary"
              onClick={hideAnnoucement}
              label={"Dismiss"}
            />
          </CardActions>
        </Card>
      )}
      {!clubDetails && (<Box className={classes.boxContainer}>
        <Grid container direction="column" className={classes.basicGrid} >
          <Grid className={classes.gridItem} item xs={12} sm={12} md={12} lg={12} >
            <Card className={classes.card}>
              <CardHeader
                title={<Typography className={classes.cardTitle}>{clubYear} CLUBS DATA</Typography>}
                className={classes.cardHeader}
              />
              {!shouldRenderInformativeText ? (
                <>
                  <CardContent className={classes.cardContentCounters}>
                    <Box style={{ display: "flex" }}>
                      <ChartCounter
                        amount={counters && counters.clubs}
                        label="Clubs"
                      />
                      <Divider className={classes.divider} orientation="vertical" />
                      <ChartCounter
                        amount={counters && counters.facilitators}
                        label="Facilitators"
                      />
                      <Divider className={classes.divider} orientation="vertical" />
                      <ChartCounter
                        amount={counters && counters.participants}
                        label="Members"
                      />
                    </Box>
                  </CardContent>
                  <CardActions className={clsx(classes.btnContainer, classes.cardContent)}>
                    <TextCustomButton
                      style={{ marginRight: 10 }}
                      className={classes.exitBtn}
                      mode="secondary"
                      onClick={() => openClubDetails(true)}
                      label={"VIEW MORE"}
                    />
                  </CardActions>
                </>
              ) : ( <CardContent>
                  <Box className={classes.emptyDashboardText}>
                    {parse(labels.partnerDashboardEmptyDashboardText)}
                  </Box>
                </CardContent>
              )}
            </Card>
          </Grid>
        </Grid>

        <Grid container direction="column" className={classes.basicGrid} >
          <Grid className={classes.gridItem} item xs={12} sm={12} md={12} lg={12} >
              <Card className={classes.card}>
                <CardActionArea onClick={() => push('/community-partnership/resources/community-partner-resources')}>                
                  <Box className={classes.cardIconBox}>
                   <img src={icon?.src} alt={icon?.alt} className={classes.cardIcon} />
                  </Box>
                  <CardContent className={classes.resourceContent}>
                    <Typography className={clsx(classes.cardTitle, classes.resourceCardTitle)} style={{color: colors.white}}>{name}</Typography>          
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          <Grid className={classes.gridItem} item xs={12} sm={12} md={12} lg={12} >
            <Card>
              <CardHeader
                title={<Typography className={classes.cardTitle}>Spread the word</Typography>}
                className={classes.cardHeader}
              />
              <CardContent className={classes.cardContent}>
                <Box className={classes.partnershipInfoContainer}>
                  {parse(labels.spreadTheWordText.replaceAll('"', ""))}
                </Box>
                <CardActions className={classes.inviteBtnContainer}>
                  <CustomButton
                    className={classes.inviteBtn}
                    mode="primary"
                    onClick={() => triggerSpread(true)}
                    label={"INVITE"}
                    sel="spread-word"
                  />
                </CardActions>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

          <Grid container direction="column" className={classes.basicGrid} >
            <Grid className={classes.gridItemRight} item xs={12} sm={12} md={12} lg={12} >
              <Card className={classes.card}>
                <CardHeader
                  title={<Typography className={classes.cardTitle}>Community Partner Manager</Typography>}
                  className={classes.cardHeader}
                />
                <CardContent className={classes.cardContent}>
                  {!!partnership.cpManager?.email ? (
                    <>
                      <ListItemText
                        className={classes.item}
                        secondary={partnership.cpManager.name}
                      />
                      <ListItemText
                        className={classes.item}
                        secondary={<CopyLinkButton link={partnership.cpManager.email} />}
                      />
                    </>
                  ) : (
                    <Typography>Community Partnership has no Partner Manager yet</Typography>
                  )}
                </CardContent>
                {!!partnership.cpManager?.meetingLink && (
                  <CardActions className={clsx(classes.btnContainer, classes.containerAction)}>
                    <CustomButton
                      className={classes.inviteBtn}
                      mode="primary"
                      onClick={() => {windowOpen(partnership.cpManager.meetingLink);}}
                      label={"Schedule a Meeting"}
                    />
                  </CardActions>
                )}
              </Card>
            </Grid>
            <Grid className={classes.gridItemRight} item xs={12} sm={12} md={12} lg={12} >
              <CommunityPartnerCard partnership={partnership} push={push} className={classes.card} />
            </Grid>
          </Grid>
        </Box>
      )}
      {clubDetails && <ClubDetails
        partnershipYears={partnershipYears}
        selectedYear={selectedYear}
        onChangeYear={onChangeYear}
        sortYears={sortYears}
        isInternational={isInternational}
        filters={filters}
        applyFilters={applyFilters}
        enableRenewals={enableRenewals}
        enableCurriculums={enableCurriculums}
        shouldRenderInformativeText={shouldRenderInformativeText}
        counters={counters}
        labels={labels}
        narrowFilter={narrowFilter}
        clubs={clubs}
      />}

      <SpreadWorldModal
        isInternational={isInternational}
        labels={inviteStudentsLabels}
        isOpen={isSpreadOpen}
        onClose={() => triggerSpread(false)}
        onSpread={() => triggerInviteStidentModal()}
      />
      <InviteStudentsModal
        emailTemplate={partnerships.emailTemplate}
        previewEmail={loadEmailPreview}
        isOpen={isInviteStudentOpen}
        onSave={sendInvitationEmail}
        onClose={() => triggerInviteStidentModal()}
      />
    </div>
  );
};

export default CommunityPartnershipContainer;
