import React, { useEffect, useState } from "react";

import * as momentTimezone from "moment-timezone";
import "./user-profile.scss";

import { Grid } from "@material-ui/core";

import { connectTo, getUserName } from "../../utils/generic";
import { Roles } from "../../utils/permissions";
import { userIsAdult, userHasValidBirthday } from "../../utils/userUtils";

import NavBar from "../../components/nav-bar/navBar";

import * as authActions from "../../actions/auth";
import * as trackingActions from "../../actions/tracking";

import { PROFILE_PAGE_VIEW } from "../../constants/trackingEvents";

import UserProfileContent from "./user-profile-content/user-profile-content";

import { UserProfileModal } from "../../components/modals/userProfile/userProfileModal";
import { ChangePasswordModal } from "../../components/modals/userProfile/changePasswordModal";
import { UserAddressModal } from "../../components/modals/userProfile/userAddressModal";

import * as actions from "../../actions/userProfile";
import * as countryQueriesApi from "../../api/graphql/query/countries.queries";
import { checkIsClubsDomain } from '../../utils/generic';
import UserDOBModal from "../../components/modals/userProfile/userDOBModal";

const notificationPrograms = ["COLLEGE_LOOP", "CLUB"];

const UserProfile = ({
  user,
  loadStateProvinces,
  contentful,
  changeUserInfo,
  changeUserAddressInfo,
  changeUserPassword,
  setUserPassword,
  passwordChangedResult,
  statesProvinces,
  loadProfileContent,
  profileContent,
  createUsageLog,
  updateUserAvatarImage,
  updateUserBio,
  updateSmsConsent
}) => {
  const isClubsDomain = checkIsClubsDomain();

  const [userEditModal, setUserEditModal] = useState(false);
  const [userAddressModal, setUserAddressModal] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [userDOBModal, setUserDOBModal] = useState(false);
  const [parentSectionAllow, setParentSectionAllow] = useState(null);
  const [domesticCountry, setDomesticCountry] = useState(null);
  const [profileLabels, setProfileLabels] = useState(null);
  const [timezones, setTimezones] = useState([]);
  const [isFacilitator, setIsFacilitator] = useState(false);
  const [isCommunityPartner, setIsCommunityPartner] = useState(false);
  const [isViewer, setIsViewer] = useState(false);
  const [countries, setCountries] = useState([]);
  const [avatar, setAvatar] = useState(null);
  const [showPasswordChangeResult, setshowPasswordChangeResult] = useState(false);

  useEffect(() => {
    if (!user) return;

    const userParents = user.parents && user.parents.filter((e) => e);
    if (userParents && userParents.length > 0) {
      setParentSectionAllow(true);
    } else {
      setParentSectionAllow(false);
    }

    loadProfileContent();

    if (!countries.length) {
      loadCountries();
    }

  }, [user]);

  useEffect(() => {
    createUsageLog({
      action: PROFILE_PAGE_VIEW,
    });
  }, []);

  useEffect(() => {
    if (passwordChangedResult && passwordChangedResult.saved) {
      setChangePasswordModal(false);
    }
    if (passwordChangedResult?.error) {
      setshowPasswordChangeResult(true);
    }
  }, [passwordChangedResult])

  useEffect(() => {
    if (!contentful.hqConfig || !contentful.GlobalComponentQuery) {
      return;
    }

    const {
      hqConfig: { domesticCountryId, defaultProgram },
      GlobalComponentQuery: { profilePageLabels },
    } = contentful;

    setProfileLabels(profilePageLabels);

    setDomesticCountry(parseInt(domesticCountryId, 10));

    if (user) {
      // facilitator of COLLEGE_LOOP or CLUB (not default program)
      setIsFacilitator(user.roles.filter((role) =>
        role.programId &&
        role.programId !== parseInt(defaultProgram, 10) &&
        !!role.program && notificationPrograms.includes(role.program.programType) &&
        role.roleId === Roles.Facilitator).length > 0);

      setIsViewer(user.roles.find((role) =>
        role.programId !== parseInt(defaultProgram, 10) &&
        !!role.program && notificationPrograms.includes(role.program.programType) &&
        role.roleId === Roles.Viewer));

      setIsCommunityPartner(user.roles.filter((role) => role.cpOrganizationId && role.roleId === Roles.CommunityPartner).length > 0);
    }

    // get list with timezone names
    const tzData = momentTimezone.tz.names().map((e) => {
      return {
        value: e,
        label: e,
      };
    });
    setTimezones(tzData);
  }, [contentful]);

  const loadCountries = async () => {
    const { data: { GetAllCountries } } = await countryQueriesApi.GetCountries();
    const countriesList = !!GetAllCountries
      ? GetAllCountries.map((el) => {
        return {
          value: el.id,
          label: el.name,
        };
      })
      : [];

    setCountries(countriesList);
  }

  const updateUserProfile = (data) => {
    changeUserInfo({ userId: user.id, data });
  };

  const updateUserAddress = (data) => {
    let state = "";
    if (statesProvinces && data.state) {
      const stateObj = statesProvinces.find((s) => s.value === data.state);
      state = !!stateObj ? stateObj.label : "";
    }

    let country = "";
    if (countries && data.countryId) {
      const countryObj = countries.find((e) => e.value === data.countryId);
      country = !!countryObj ? countryObj.label : "";
    }

    changeUserAddressInfo({
      userId: user.id,
      data: { ...data, state, country },
    });
  };

  const updateUserPassword = (data) => {
    !user.passwordNotSet ? changeUserPassword({
      password: data.currentPassword,
      newPassword: data.confirmPassword,
    }) :
      setUserPassword({ newPassword: data.confirmPassword })
  };

  const handleAvatarChange = (newAvatar) => {
    setAvatar(newAvatar);
  };

  const renderUserProfileModal = (user, userEditModal, isClubChild) => {
    return (
      <UserProfileModal
        userEditModal={userEditModal}
        user={user}
        timezones={timezones}
        countries={countries}
        onClose={() => setUserEditModal(false)}
        onSave={updateUserProfile}
        domesticCountry={domesticCountry}
        isClubChild={isClubChild}
      />
    );
  };

  const renderUserAddressModal = (user, userEditModal, isClubChild) => {
    return (
      <UserAddressModal
        userEditModal={userEditModal}
        user={user}
        timezones={timezones}
        statesProvinces={statesProvinces}
        loadStateProvinces={loadStateProvinces}
        countries={countries}
        onClose={() => setUserAddressModal(false)}
        onSave={updateUserAddress}
        domesticCountry={domesticCountry}
        isClubChild={isClubChild}
      />
    );
  };

  const renderChangePasswordModal = (changePasswordModal) => {
    return (
      <ChangePasswordModal
        isOpen={changePasswordModal}
        onClose={() => {
          setshowPasswordChangeResult(false);
          setChangePasswordModal(false);
        }}
        onSave={updateUserPassword}
        changePasswordContent={profileContent.changePasswordForm}
        passwordChanged={passwordChangedResult}
        passwordNotSet={user.passwordNotSet}
        user={user}
        showPasswordChangeResult={showPasswordChangeResult}
      />
    );
  };

  const renderDOBModal = () => {
    return (
      <UserDOBModal   
        onClose={() => {
          setUserDOBModal(false);          
        }}
        userDOBModal={userDOBModal}
        callback={() => setUserAddressModal(true)}
      />
    );
  };

  const navTitle = user ? getUserName(user) : "";
  const isClubChild = isClubsDomain && !userIsAdult(user);
  const isValidDoB = userHasValidBirthday(user); 
  
  return (
    <React.Fragment>
      <NavBar
        title={navTitle}
        uploadedAvatar={avatar}
      ></NavBar>
      {user && userEditModal && renderUserProfileModal(user, userEditModal, isClubChild)}
      {user && userAddressModal && renderUserAddressModal(user, userAddressModal, isClubChild)}
      {user && userDOBModal && renderDOBModal()}
      {profileContent &&
        profileContent.changePasswordForm &&
        renderChangePasswordModal(changePasswordModal)}
      <Grid content lg={10} xl={8} className="project-page">
        {profileLabels &&
          <UserProfileContent
            user={user}
            parentSectionAllow={parentSectionAllow}
            countries={countries}
            onUserProfileEdit={() => setUserEditModal(true)}
            onUserAddressEdit={() => isValidDoB ? setUserAddressModal(true) : setUserDOBModal(true)}
            onChangePassword={() => setChangePasswordModal(true)}
            labels={profileLabels}
            updateUserAvatarImage={updateUserAvatarImage}
            updateUserBio={updateUserBio}
            updateSmsConsent={updateSmsConsent}
            isFacilitator={isFacilitator}
            isCommunityPartner={isCommunityPartner}
            isViewer={isViewer}
            onAvatarChange={handleAvatarChange} 
          />}
      </Grid>
    </React.Fragment>
  );
};

//export default UserProfile;
export default connectTo(
  (state) => {
    return {
      user: state.auth.user,
      contentful: state.contentful,
      ...state.userProfile,
    };
  },
  {
    ...actions,
    ...authActions,
    ...trackingActions,
  },
  UserProfile
);
