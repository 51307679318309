import React from "react";

import {
  makeStyles,
  createTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import * as colors from "../../theme/colors";

const useStyles = makeStyles({
  wrapper: {
    minWidth: "fit-content",
  },
  wrapperMaxContent: {
    maxWidth: "fit-content",
  },
});

const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: ".875em",
        borderRadius: 5,
        padding: '5px 8px',
        backgroundColor: colors.darkBlue,
        color: '#fff',
        visibility: 'visible',
        opacity: 1,
      },
    },
  },
});

export const TooltipWrapper = (props) => {
  const {children, title, interactive, placement, widthMaxContent, id, role} = props;
  const classes = useStyles();
  return (
    <div className={widthMaxContent ? classes.wrapperMaxContent : classes.wrapper}>
      {title ? (
        <MuiThemeProvider theme={theme}>
          <span aria-label="Tooltip :"></span>
          <Tooltip id={id} aria-label={title} title={title} interactive={interactive} placement={placement} role={role}>{children}</Tooltip>
        </MuiThemeProvider>
      ) : (
        children
      )}
    </div>
  );
};
