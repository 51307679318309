import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

import * as helpers from "../helpers";
import * as colors from "../../../theme/colors";

import Table from "../Table";
import Tabs from "./index";
import {
  PROGRESS_VIEW_SELF,
  PROGRESS_VIEW_MEMBER,
} from "../../../constants/trackingEvents";
import PageTitle from "../../../components/page-title/pageTitle";
import CustomButton from "../../../components/customButton/customButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingBottom: 20,
  },
  main: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  header: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    color: colors.grayTheme,
    fontSize: 32,
    fontWeight: "bold",
    textTransform: 'uppercase',
  },
  contentBox: {
    width: "95%",
    margin: "auto",
  },
  titleBox: {
    minHeight: 100,
    width: "100%",
    marginLeft: '-20px'
  },
  titleContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: 20,
    minHeight: 100,
  },
  title: {
    margin: '15px auto',
  },
  progressSubheader: {
    color: colors.dartThemeBlueGray,
    fontWeight: "bold",
    marginLeft: 17,
    padding: 15,
  },
  nextUserBtn: {
    cursor: "pointer",
    color: colors.grayTheme,
    fontWeight: "bold",
    fontSize: 18,
    marginRight: 10,
  },
  breadCrumbs: {
    fontSize: 12,
    marginTop: 20,
    marginLeft: '-5px',
  },
  breadCrumbsLink: {
    marginLeft: 10,
    cursor: 'pointer',
    color: colors.darkThemeGreen,
    fontWeight: "bold",
    fontSize: 14,
    textDecoration: "none",
    '&:hover': {
      color: colors.lightThemeGreen,
    },
    "&:focus": {
      outline: `2px dotted ${colors.grey400}`,
    },
  },
  breadCrumbsName: {
    color: colors.lightThemeGrey,
    fontWeight: "bold",
    fontSize: 14,
  },
  tabsSection: {
    margin: '-10px auto 15px -25px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '-10px',
    },
  },
  nextUserBtnIcon: {
    height: 40,
    width: 40,
  },
  nextUserBtnWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

const UserProgress = ({
  user,
  curriculums,
  deleteMyActivityProgress,
  getUserActivitiesByProgram,
  navigateBack,
  clubCode,
  userId,
  push,
  userProgressByProgram,
  programRatings,
  getRatingsByProgram,
  createTrackingLog,
  isLoading,
  nextUserProgressId,
  setCurrentTab,
  usersProjectsByProgram,
  currentTab,
  isUserProjects,
  showCertificates,
  showAnswers,
  userAnswers,
  certificateRibbonIcon,
  userFullName,
}) => {
  const classes = useStyles();

  const [tableData, setTableData] = useState(null);
  const [currentProgram, setCurrentProgram] = useState(null);
  const [isFacilitator, setIsFacilitator] = useState(false);

  const onRedoClick = (node) => {
    const path = node.pathWithFolder ? node.pathWithFolder : node.path;
    const itemsToDelete =
      node.activities && node.activities.length > 0
        ? node.activities.map((r) => {
            return {
              slug: r.slug,
              activitySetSlug: node.slug,
            };
          })
        : [
            {
              slug: node.slug,
              path: node.path ? `/gwc${path}` : node.logs.path,
            },
          ];

    deleteMyActivityProgress({
      items: itemsToDelete,
      programId: currentProgram.id,
    });

    if (!!node.externalUrl) {
      window.open(node.externalUrl);
    } else if (node.activities && node.activities.length > 0) {
      const p = node.path ? `/gwc${path}` : node.logs.path;
      push(`${p}/${node.activities[0].slug}`, { enforcePresenter: true });
    } else {
      const p = node.path ? `/gwc${path}` : node.logs.path;
      push(p, { enforcePresenter: true });
    }
  };

  useEffect(() => {
    if (!user) return;

    const { roles } = user;
    const role = roles.find((r) => r.program && r.program.code === clubCode); // searching for user role

    if (!role) return push("/home"); // redirecting if user doesn't have access to this program

    setCurrentProgram(role.program);

    // getting user activities for particular program and user
    getUserActivitiesByProgram({
      programId: role.program.id,
      userId: userId,
    });

    getRatingsByProgram({ programId: role.program.id, userId });
    const programRole = roles.find(
      (e) => e.programId === parseInt(role.program.id, 10)
    );
    const isFacilitator = programRole ? programRole.roleId === 1 : false;
    setIsFacilitator(isFacilitator);

    const isOwnProgress = user.id === parseInt(userId, 10);
    createTrackingLog({
      event: isOwnProgress ? PROGRESS_VIEW_SELF : PROGRESS_VIEW_MEMBER,
      data: JSON.stringify({ programId: role.program.id, userId }),
    });
  }, [user, userId]);

  useEffect(() => {
    if (
      !userProgressByProgram ||
      !curriculums ||
      !currentProgram ||
      !curriculums[currentProgram.curriculum.code]
    )
      return;

    const curriculum = curriculums[currentProgram.curriculum.code];
    const tableData = helpers.getTableData(curriculum, userProgressByProgram);
    setTableData(tableData);
  }, [userProgressByProgram, curriculums]);

  if (!user) return null;

  const showUserHeader = parseInt(userId, 10) !== user.id;

  const keyDown = (e) => {
    if (e.key === "Enter") {
      e.target.click();
    }
    return;
  }
  const showProjectTab = usersProjectsByProgram?.some(user => user.userId === userId);

  return (
    <main className={classes.main}>
      {showUserHeader && (
        <Box className={classes.contentBox}>
        <Breadcrumbs
          className={classes.breadCrumbs}
          separator=" / "
          aria-label="breadcrumb"
        >
          <Link
            onKeyDown={keyDown}
            tabIndex={0}
            underline="none"
            className={classes.breadCrumbsLink}
            onClick={() => { navigateBack(null) }}
          >
            Progress
          </Link>
          <Typography className={classes.breadCrumbsName}>
            {userFullName}
          </Typography>
        </Breadcrumbs>
        <Box className={classes.titleBox}>
          <Box className={classes.titleContent}>
            <Box className={classes.header}>
              <PageTitle title={userFullName} isLoading={isLoading} />
            </Box>
            <Box className={classes.nextUserBtnWrapper} >
              {nextUserProgressId && (
                <>
                <Typography
                  className={classes.nextUserBtn}
                  onClick={() => {
                    navigateBack(nextUserProgressId);
                  }}
                >
                  {"Next Member"}
                </Typography>
                <CustomButton
                  size="large"
                  mode="secondary"
                  aria-label="Next Member"
                  onClick={() => { navigateBack(nextUserProgressId) }}
                  className={classes.nextUserBtnIcon}
                >
                  <ArrowForwardIcon />
                </CustomButton>
                </>
              )}
            </Box>
          </Box>
        </Box>
        <div className={classes.tabsSection}>
          <Tabs
            value={currentTab}
            showProjectTab={showProjectTab}
            showAnswers={showAnswers}
            onChange={(evt, newValue) => {
              setCurrentTab(newValue);
            }}
          />
        </div>
        </Box>
      )}
      <Box className={classes.contentBox}>
        
      {!showUserHeader && (
        <>
          <Box className={clsx(classes.header, classes.title)}>
            <PageTitle title="Progress" isLoading={isLoading} />
          </Box>
          <div className={classes.tabsSection}>
            <Tabs
              value={currentTab}
              showCertificateTab={showCertificates}
              showProjectTab={isUserProjects}
              showAnswers={showAnswers}
              onChange={(evt, newValue) => {
                setCurrentTab(newValue);
              }}
            />
          </div>
        </>
      )}

        <Table
          data={tableData}
          ratings={programRatings}
          onPush={push}
          isMyProgress={!showUserHeader}
          clearProgress={onRedoClick}
          isFacilitator={isFacilitator}
          clubCode={clubCode}
          userAnswers={userAnswers}
          certificateRibbonIcon={certificateRibbonIcon}
          userId={parseInt(userId, 10)}
        />
      </Box>
    </main>
  );
};

export default UserProgress;
