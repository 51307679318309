export const getNodeIds = (chains) => {
    const folders = chains.filter((el) =>
    (el.__typename || '').toLowerCase() === 'CurriculumFolder'.toLowerCase());
    const sets = chains.filter((el) => el.nodeType === 'set');
    const activities = chains.filter((el) => el.nodeType === 'activity');
    const url = activities[0] ? activities[0].path : sets[0] ? sets[0].path : null;
    let collectionId = folders.length ?
      chains[1] ? chains[1].id : null :
       chains[0] ? chains[0].id : null;
    let setId = sets[0] ? sets[0].id : null;
    let activityId = activities[0] ? activities[0].id : null;
    if (!activityId && !setId) {
      activityId = collectionId;
      collectionId = null;
      setId = null;
    }
  
    if (!activityId && setId) {
      activityId = setId;
      setId = null;
    }
  
  
    const folderId = folders[0] ? folders[0].id : null;
  
    return { activityId, setId, collectionId, url, folderId };
  }