import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  border: {
    borderBottom: '1px solid black',
    opacity: 0.12,
    width: '100%',
  },
  content: {
    fontSize: 14,
    opacity: 0.65,
    padding: '0 10px 0 10px',
  },
});

const Divider = (props) => {
  const { children, className } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, className)}>
      <div className={classes.border} />
      <span className={classes.content} aria-hidden="true">
        {children}
      </span>
      <div className={classes.border} />
    </div>
  )
};

export default Divider;
