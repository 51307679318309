import React, { useState, useRef } from "react";

import { makeStyles, } from "@material-ui/core/styles";
import { OutlinedInput } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import Box from "@material-ui/core/Box";
import * as colors from "../../theme/colors";

import {
  InputAdornment,
} from "@material-ui/core/";
import { handleKeyPress } from '../../utils/accessibility';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: () => `2px solid ${colors.lightThemeGreen}`,
      },
    },
  },
  searchReset: {
    cursor: "pointer",
  },
  searchIcon: {
    color: colors.dartThemeBlueGray,
  },
  suggestions: {
    border: '1px solid #999',
    borderTopWidth: 0,
    listStyle: 'none',
    marginTop: 0,
    maxHeight: 250,
    overflowY: 'auto',
    paddingLeft: 0,
    width: '100%',
    zIndex: 100,
    position: 'absolute',
    "& > li": {
      backgroundColor: colors.white,
      minHeigth: 20,
      color: '#003046',
      width: 'auto',
      overflow: 'hidden',
      fontSize: '1rem',
      boxSizing: 'border- box',
      fontweight: 500,
      lineheight: '1.5',
      minHeight: 48,
      fontWeight: 400,
      cursor: 'pointer',
      padding: 10,
      '&:hover': {
        color: colors.darkThemeGreen,
        backgroundColor: '#f5f5f5',
      }
    }
  }
});

const SearchBar = ({ value, label, onChange, onReset, values, className }) => {
  const classes = useStyles();
  const [searchKey, setSearchKey] = useState(value);
  const [showAutofill, setShowAutofill] = useState(false);

  const dropdown = useRef(null)

  const closeOpenMenus = (e) => {
    if (dropdown.current && showAutofill && !dropdown.current.contains(e.target)) {
      setShowAutofill(false)
    }
  }

  const onSuggestionClick = (item) => {
    setSearchKey(item);
    onChange(item);
    setShowAutofill(false);
  }

  document.addEventListener('mousedown', closeOpenMenus);

  React.useEffect(() => {
    setSearchKey(value);
  }, [value]);

  const autoFillDropdown = () => {
    const filteredItems = !!searchKey.length
      ? values.filter(e => !!e.toLowerCase().includes(searchKey.toLowerCase().trim()))
      : [];

    return (
      <ul role="menu" className={classes.suggestions}>
        {filteredItems.map((item) => {
          let className;

          return (
            <li tabIndex={0} className={className} key={item} role="menuitem" onKeyDown={(event) => handleKeyPress(() => onSuggestionClick(item))(event)} onClick={() => onSuggestionClick(item)}>
              {item}
            </li>
          );
        })}
      </ul>
    )
  }

  return (
    <Box className={classes.root} ref={dropdown}>
      <OutlinedInput
        placeholder={label}
        className={className}
        onChange={(event) => {
          setSearchKey(event.target.value);
          onChange(event.target.value);
          setShowAutofill(true);
        }}
        value={searchKey}
        inputProps={{
          style: {
            padding: "8px 5px 8px 0px"
          }
        }}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon color="action" className={classes.searchIcon} />
          </InputAdornment>
        }
        endAdornment={
          <>
            {!!onReset && <InputAdornment
              onClick={() => {
                setSearchKey("");
                onReset()
              }}
              position="end"
            >
              <ClearIcon className={classes.searchReset} color="action" sel="clear" />
            </InputAdornment>}
          </>
        }
      />
      {showAutofill && autoFillDropdown()}
    </Box>
  );
};

export default SearchBar;
