import { EditorState, ContentState, SelectionState, RichUtils, Modifier } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';

import decorator from './decorator';
import { ERROR_MESSAGES, ENTITY_TYPES } from './constants';

// regex for link structure validation
// link must start with https followed by ://, must contain domain
export const linkRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_+.~#?&//=]*)/;

export const isStringEmpty = (value) => (value ? !value.trim() : true);

export const hasRangeIntersection = (rangeA, rangeB) => {
  let intersection = false;

  if (rangeA.start < rangeB.start && rangeA.end > rangeB.end) {
    intersection = true;
  }

  if (rangeA.start > rangeB.start && rangeA.start < rangeB.end) {
    intersection = true;
  }

  if (rangeA.end < rangeB.end && rangeA.end > rangeB.start) {
    intersection = true;
  }

  return intersection;
};

export const createEmptyEditor = () => EditorState.createEmpty(decorator);

export const getLinksInsideBlock = (contentState, contentBlock) => {
  const links = {};

  contentBlock.findEntityRanges(
    character => {
      const entityKey = character.getEntity();
      return entityKey !== null && contentState.getEntity(entityKey).getType() === ENTITY_TYPES.LINK;
    },
    (start, end) => {
      links[contentBlock.getEntityAt(start)] = { start, end };
    }
  );

  return links;
};

const getLinkPositions = (value, selection) => {
  const currentContent = value.getCurrentContent();
  const blockArray = Object.entries(currentContent.getBlockMap().toObject());
  const entities = {};

  const selectionState = selection || value.getSelection();
  const anchorKey = selectionState.getAnchorKey();
  const focusKey = selectionState.getFocusKey();

  let focus = false;

  blockArray.filter(([key]) => {
    let inside = false;

    // anchorKey block key to start searching from
    // focusKey block key until which to search
    if (key === anchorKey || key === focusKey) {
      if (focus) {
        inside = true;
        focus = false;
      } else {
        focus = true;
      }
    }

    if (focus) {
      inside = true;
    }

    return inside;
  })
    .forEach(([key, currentContentBlock]) => {
      entities[key] = Object.values(getLinksInsideBlock(currentContent, currentContentBlock));
    });

  return entities;
};

export const linksInsideSelection = (value, selection) => {
  const selectionState = selection || value.getSelection();

  const linkPositions = getLinkPositions(value, selectionState);
  let selectionStart = selectionState.getAnchorOffset();
  let selectionEnd = selectionState.getFocusOffset();
  let anchorKey = selectionState.getAnchorKey();
  let focusKey = selectionState.getFocusKey();

  if (anchorKey === focusKey) {
    if (selectionStart > selectionEnd) {
      [selectionStart, selectionEnd] = [selectionEnd, selectionStart];
    }

    return linkPositions[anchorKey].some(linkPosition =>
      hasRangeIntersection(linkPosition, { start: selectionStart, end: selectionEnd })
    );
  }

  const blockKeyList = Object.keys(linkPositions);
  if (blockKeyList.indexOf(anchorKey) > blockKeyList.indexOf(focusKey)) {
    [anchorKey, focusKey] = [focusKey, anchorKey];
    [selectionStart, selectionEnd] = [selectionEnd, selectionStart];
  }

  let present = false;

  Object.entries(linkPositions).forEach(([key, entities]) => {
    if (key === anchorKey) {
      entities.forEach(entity => {
        if (entity.start >= selectionStart) {
          present = true;
        }
      });
    } else if (key === focusKey) {
      entities.forEach(entity => {
        if (entity.end <= selectionEnd) {
          present = true;
        }
      });
    } else {
      present = true;
    }
  });

  return present;
};

const transformTextToLink = (editorState, url, selection) => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(ENTITY_TYPES.LINK, 'MUTABLE', { url });

  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });

  const linkSelection = selection || newEditorState.getSelection();
  const linkedState = RichUtils.toggleLink(newEditorState, linkSelection, entityKey);

  return linkedState;
};

const convertTextToLinks = (value) => {
  let finalState = value;

  const currentContent = value.getCurrentContent();
  const blockMap = currentContent.getBlockMap();
  const contentBlocks = Object.values(blockMap.toObject());

  contentBlocks.forEach(contentBlock => {
    const key = contentBlock.getKey();
    const text = contentBlock.getText();

    const urlMatches = Array.from(text.matchAll(`${linkRegex}/gm`) || []);
    urlMatches.forEach(match => {
      const matchIndex = match.index || 0;

      const locationProps = {
        anchorKey: key,
        focusKey: key,
        anchorOffset: matchIndex,
        focusOffset: matchIndex + match[0].length,
      };

      let linkLocation = SelectionState.createEmpty(key);
      linkLocation = linkLocation.merge(locationProps);

      if (!linksInsideSelection(finalState, linkLocation)) {
        finalState = transformTextToLink(finalState, match[0], linkLocation);
      }
    });
  });

  EditorState.forceSelection(finalState, value.getSelection());
  return finalState;
};

export const exportEditor = (content, preProcess = true) => {
  if (!content) return '';

  let finalContent = content;

  if (preProcess) {
    finalContent = convertTextToLinks(finalContent);
  }

  const options = {
    entityStyleFn: (entity) => {
      if (entity) {
        const entityType = entity.get('type').toLowerCase();
        if (entityType === 'link') {
          const data = entity.getData();
          return {
            element: 'a',
            attributes: {
              href: data.url,
              target:'_blank'
            },
          };
        }
      }
    }
  };

  return stateToHTML(finalContent.getCurrentContent(), options);
};

export const importEditor = (raw) => {
  try {
    const editorState = EditorState.createWithContent(stateFromHTML(raw), decorator);
    return editorState;
  } catch (error) {
    return EditorState.createWithContent(ContentState.createFromText(raw), decorator);
  }
};

export const validateLinkInsert = (values) => {
  const errors = {};

  if (isStringEmpty(values.text)) {
    errors.text = ERROR_MESSAGES.FIELD_REQUIRED;
  }

  if (isStringEmpty(values.link) || !linkRegex.test(values.link)) {
    errors.link = ERROR_MESSAGES.VALID_LINK;
  }

  return errors;
};

export const updateSelection = (editorState, selection = {}) => {
  const currentSelection = editorState.getSelection();
  return EditorState.forceSelection(editorState, currentSelection.merge(selection));
};

export const getUrlFromEntity = (contentState, entityKey, defaultValue = '') => {
  const urlEntity = contentState.getEntity(entityKey);
  let url = defaultValue;

  if (urlEntity.getType() === ENTITY_TYPES.LINK && urlEntity.getMutability() === 'MUTABLE') {
    ({ url } = urlEntity.getData());
  }

  return url;
};

export const getFirstLinkInRange = (range, linkPositions) => {
  let key;
  const positionList = [];
  const selection = { ...range };

  Object.keys(linkPositions).forEach(entityKey => {
    positionList.push({ ...linkPositions[entityKey], key: entityKey });
  });

  const sortedLinksPositions = positionList.sort((a, b) => a.start - b.start);

  const setKeyIfNull = (value) => {
    if (key) {
      return;
    }
    key = value;
  };

  sortedLinksPositions.forEach(linkPosition => {
    let overlap = false;

    if (linkPosition.start >= range.start && linkPosition.start <= range.end) {
      setKeyIfNull(linkPosition.key);
      overlap = true;
    } else if (linkPosition.end >= range.start && linkPosition.end <= range.end) {
      setKeyIfNull(linkPosition.key);
      overlap = true;
    } else if (linkPosition.start <= range.start && linkPosition.end >= range.end) {
      setKeyIfNull(linkPosition.key);
      overlap = true;
    }

    if (overlap) {
      if (selection.start > linkPosition.start) {
        selection.start = linkPosition.start;
      }

      if (selection.end < linkPosition.end) {
        selection.end = linkPosition.end;
      }
    }
  });

  return { key, selection };
};

export const isMultiLineSelection = (editorState) => {
  const selection = editorState.getSelection();
  return selection.getAnchorKey() !== selection.getFocusKey();
};

export const getLinkAtCursor = (editorState) => {
  const currentContent = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  let link = null;

  if (selection.isCollapsed()) {
    const anchorOffset = selection.getAnchorOffset();
    const focusOffset = selection.getFocusOffset();

    const start = anchorOffset > focusOffset ? focusOffset : anchorOffset;

    const currentContentBlock = currentContent.getBlockForKey(selection.getAnchorKey());
    const selectedEntityKey = currentContentBlock.getEntityAt(start);

    if (selectedEntityKey) {
      link = getUrlFromEntity(currentContent, selectedEntityKey, null);
    }
  }

  return link;
};

export const insertText = (editorState, text) => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();

  const pastedBlocks = ContentState.createFromText(text).getBlockMap();
  const newState = Modifier.replaceWithFragment(contentState, selectionState, pastedBlocks);

  return EditorState.push(editorState, newState, 'insert-fragment');
};
