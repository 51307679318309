import { call, put } from "redux-saga/effects";
import { push as pushToRoute } from "connected-react-router";
import {
  receivedProgramInfo,
  receivedEnrollmentData,
  receiveCurrentStep
} from "../actions/joinClub";
import {
  showErrorMessage,
} from "../actions/notification";
import {
  loadCurrentUser as loadCurrentUserAction,
} from "../actions/auth";

import { saveCurrentRole } from "../api/graphql/mutation/userRoles.mutation";
import * as graphqlUserMutationApi from "../api/graphql/mutation/userNext.mutation";
import * as graphqlEnrollmentQuery from "../api/graphql/query/enrollment.queries";
import * as enrollmentApi from '../api/bl-query/enrollment.query';

import * as programsApi from '../api/programs';
import * as globalApi from '../api/global';
import { createUsageLog } from "../actions/tracking";
import {
  JOINED_COMMUNITY_PARTNERSHIP,
  STUDENT_JOINED_CLUB,
  ENROLL_PROGRAM_STARTED,
  ENROLL_PROGRAM_ERROR,
} from "../constants/trackingEvents";

export function* getProgramByCode({ payload }) {
  try {
    
    const { step } = payload;
    let { data: program } = yield call(programsApi.getProgramByCode, payload);
    const { programType } = program;
    const data = { program: { ...program, programType }, step: step ?? (program.completed || !program.active ? 0 : 1) };
    yield put(receivedProgramInfo(data));
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    yield put(receivedProgramInfo({}));
    console.log(e);
  }
}

export function* getEnrollmentData({ payload }) {
  try {
    const { childId } = payload;

    const {
      data: { EnrollmentFormQuery }
    } = yield call(graphqlEnrollmentQuery.enrollmentFormQuery);

    const { data: { EnrollmentData } } = childId ?
      yield call(enrollmentApi.getEnrollmentFormData, {childId}) : { data: {EnrollmentData: {} }};

    const {
      data: { SearchCollegeMajor }
    } = yield call(graphqlEnrollmentQuery.GetCollegeMajors);

    let collegeMajors = SearchCollegeMajor.map(el => {
      return {
        value: el.id,
        label: el.name
      };
    });

    const newPayload = {
      ...payload,
      EnrollmentFormQuery,
      collegeMajors,
      enrollmentData: EnrollmentData || {}
    };
    yield put(receivedEnrollmentData(newPayload));
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* setCurrentStep({ payload }) {
  try {
    yield put(receiveCurrentStep(payload));
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* enrollUserInCommunityPartnership({ payload }) {
  try {
    const { program: { code } } = payload;

    const { data: { EnrollStatus, partnershipId }} = yield call(enrollmentApi.enrollInCP, { code });

    if (!!EnrollStatus && partnershipId) {
      yield call(saveCurrentRole, { cpOrganizationId: partnershipId });
      yield put(
        createUsageLog({
          action: JOINED_COMMUNITY_PARTNERSHIP,
          data: JSON.stringify({ organization: code })
        })
      );
      yield put(loadCurrentUserAction());
      yield put(pushToRoute('/home'));
      yield put(receivedProgramInfo({}));
    }
    else {
      yield put(showErrorMessage("Failed to add you to a Community Partnership. Please, reload the page and try again."));
    }
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* enrollUserInClub({ payload }) {
  try {
    const { childId, program, enrollmentData, invitation } = payload;
    const {
      data: programByCode
    } = yield call(programsApi.getProgramByCode, {
      programCode: program.code
    });

    let { data: {
      hqConfigControl,
    } } = yield call(globalApi.getGlobalInfo, payload);

    if (hqConfigControl.defaultProgram === programByCode.code) {
      throw( new Error('\n Club code belongs to default program'));
    }

    if (programByCode.completed || !programByCode.active) {
      throw(new Error('This program is no longer active'));
    }

    yield put(
      createUsageLog({
        action: ENROLL_PROGRAM_STARTED,
        data: JSON.stringify({ club: program.code, enrollmentData })
      })
    );

    let errorData = null;
    try {
      const {
        data: {error},
      } = yield call(enrollmentApi.enrollInProgram, {
        enrollmentForm: enrollmentData,
        programCode: program.code,
        childId
      });
      errorData = error;
    } catch (ex) {
      console.log("exception", ex);
    }

    if (errorData)  {
      throw new Error(errorData);
    }

    yield put(
      createUsageLog({
        action: STUDENT_JOINED_CLUB,
        data: JSON.stringify({ club: program.code })
      })
    );

    if (invitation) {
      const { id, invitationToken } = invitation;
      yield call(graphqlUserMutationApi.updateUserNextInvitation, 
        {invitationId: id, invitationToken});
    }

    yield put(loadCurrentUserAction());
    yield put(pushToRoute('/home'));
    yield put(receivedProgramInfo({}))
  } catch (e) {
    yield put(pushToRoute('/home'));
    yield put(
      createUsageLog({
        action: ENROLL_PROGRAM_ERROR,
      })
    );
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* getEnrollmentFormData() {
  try {
    const {
      data: { EnrollmentFormQuery }
    } = yield call(graphqlEnrollmentQuery.enrollmentFormQuery);

    yield put(receivedEnrollmentData({step: 0, EnrollmentFormQuery, enrollmentData: {}}));
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}
