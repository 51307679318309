import React, { useState, useEffect } from "react";

import {
  Box,
  CardContent,
  Card,
  useMediaQuery,
  IconButton,
  Popover,
} from "@material-ui/core/";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import ErrorIcon from '@material-ui/icons/ErrorRounded';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import GroupOutlined from '@material-ui/icons/GroupOutlined';
import CloseIcon from "@material-ui/icons/Close";

import * as colors from "../../../theme/colors";
import Typography from "@material-ui/core/Typography";

import CustomButton from "../../../components/customButton/customButton";
import TextCustomButton from "../../../components/text-button/text-button";
import UserTable from "./userTable/UserTable"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TeacherViewAnswerCard from "./teacherViewAnswerCard";
import { TASK_STATUSES } from '../../../utils/constants';
import { ColorTheme } from '../../../utils/colorTheme';
import { mapOrder } from '../../../utils/generic';
import { ReactComponent as GradeIcon } from '../../../images/grade.svg';
import TeacherViewAnswerGroupCard from './TeacherViewAnswerGroupCard';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: 50,
  },
  main: {
    display: "flex",
  },
  contentLabel: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: 160,
    color: colors.darkThemeBlue,
    fontSize: 14,
  },
  headerBox: {
    padding: 16,
    display: "flex",
    marginBottom: 15,
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down('xs')]: {
      display: "block",
    },
  },
  titleBox: {
    display: "flex",
  },
  backBtnBox: {
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'left',
    },
  },
  backBtn: {
    display: "flex",
  },
  commentBox: {
    display: "flex",
    justifyContent: "center",
  },
  label: {
    color: colors.darkThemeBlue,
    fontSize: 14,
  },
  chatBubbleIcon: {
    marginLeft: 10,
  },
  contentHeader: {
    color: colors.darkThemeBlue,
    fontSize: 18,
    fontWeight: "bold",
    paddingRight: 10,
  },
  assignmentIconBox: {
    marginRight: 10,
  },
  checkmarkIcon: {
    color: colors.lightThemeGreen,
    height: 22,
    marginLeft: 5,
  },
  statusBox: {
    display: "flex",
    alignItems: "center",
  },
  statusBtn: {
    minWidth: 100,
    borderRadius: 5,
    fontSize: 14,
    borderRadius: 20,
    border: "2px solid",
    padding: "5px 12px",
    fontWeight: "bold",
    borderColor: "#CECECE",
    "&:not(:last-child)": {
      marginRight: 10,
    },
    "& .MuiButton-label": {
      textTransform: "initial",
    },
    "&:hover": {
      borderColor: colors.lightThemeGreen,
      backgroundColor: "white",
    },
  },
  statusLabel: {
    marginRight: 12,
  },
  selectedItem: {
    borderColor: colors.teal,
    backgroundColor: colors.teal,
    "&:hover": {
      backgroundColor: colors.teal,
    },
  },
  viewBtn: {
    opacity: 0,
  },
  gradeIcon: {
    width: 20,
    height: 20,
  },
  gradeIconBig: {
    height: 80,
    width: 80,
  },
  notDoneIcon: {
    fill: ColorTheme.red4.b,
    color: ColorTheme.red4.b,
    fontSize: '1rem',
    marginLeft: 5,
    marginBottom: '-2px',
  },
  doneIcon: {
    fill: ColorTheme.teal2.b,
    color: ColorTheme.teal2.b,
    fontSize: '1rem',
    marginLeft: 5,
    marginBottom: '-2px',
  },
  teacherViewCard: {
    marginLeft: 10,
    width: "100%",
    height: "100%",
  },
  teacherViewAllDoneCard: {
    marginLeft: 10,
    width: "100%",
    height: "100%",
    justifyContent: 'center',
    textAlign: 'center',
    display: 'flex',
    padding: 15,
  },
  allDoneCardText: {
    paddingTop: 25,
    paddingBottom: 25,
  },
  showStudentsBtn: {
    minWidth: 45,
    height: 45,
  },
  closeIconWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeBtn: {
    marginRight: 5,
    marginBottom: '-25px',
    paddingTop: 17
  }
}));

const getFilteredKeys = (students, items, status, sortByDate) => {
  if (status === undefined) {
    return students;
  }
  const filteredUsers = students.filter(e => {
    const item = items[e.userId] ? items[e.userId][0] : null;

    return !item && status === TASK_STATUSES.notStarted || !!item && item.status === status;
  });
  if (sortByDate) {
    const answers = filteredUsers.map((student) => {
      return items[student.userId] && items[student.userId][0];
     }).filter(e => !!e);

    const sortedAnswers = Object.values(answers).sort(function(a,b){
      return new Date(a.updatedAt ? a.updatedAt : a.createdAt) - new Date(b.updatedAt ? b.updatedAt : b.createdAt);
    }).map(e => e.userId);

    return mapOrder(filteredUsers, sortedAnswers, 'userId');
  }

  return filteredUsers;
};

const UserAnswers = ({
  user,
  answers,
  students,
  onBackClick,
  getCommentsByUser,
  isLoadingCommentsByUser,
  addComment,
  updateStatus,
  updateAnswerGroupsStatus,
  deleteComment,
  isRemovingComment,
  isAddingComment,
  isUpdatingComment,
  requiredGrading,
  heading,
  isAnswerGroup,
  answerGroupQuestions,
  addAnswerGroupComment,
  updateComment,
  updateAnswerGroupComment,
  deleteAnswerGroupComment,
  question,
  history,
  program,
  selectedItem,
  selectedUserId,
  push
}) => {
  const classes = useStyles();
  const [answersIncompleteToRender, setAnswersIncompleteToRender] = useState([]);
  const [answersCompletedToRender, setAnswersCompletedToRender] = useState([]);
  const [answersToReviewToRender, setAnswersToReviewToRender] = useState([]);
  const [answersNotStartedToRender, setAnswersNotStartedToRender] = useState([]);
  const [viewUserKey, setViewUserKey] = useState(selectedUserId ? selectedUserId : null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortByDate, setSortByDate] = useState(false);

  useEffect(() => {
    if (!answers || !students.length)
      return;

    const notStartedKeys = getFilteredKeys(students, answers, TASK_STATUSES.notStarted, sortByDate);
    setAnswersNotStartedToRender(notStartedKeys);
    const incompleteKeys = getFilteredKeys(students, answers, TASK_STATUSES.incomplete, sortByDate);
    setAnswersIncompleteToRender(incompleteKeys);
    const completedKeys = getFilteredKeys(students, answers, TASK_STATUSES.complete, sortByDate);
    setAnswersCompletedToRender(completedKeys);
    if (requiredGrading) {
      const toReviewKeys = getFilteredKeys(students, answers, TASK_STATUSES.review, sortByDate);
      setAnswersToReviewToRender(toReviewKeys);
    } else {
      const completedKeysNotGraded = getFilteredKeys(students, answers, TASK_STATUSES.review, sortByDate);
      setAnswersCompletedToRender([...completedKeys, ...completedKeysNotGraded]);
    }
    if (!isUserInList(students, viewUserKey)) {
      setViewUserKey(null);
    }
  }, [answers, students, sortByDate]);

  useEffect(() => {
    const answerId = !!selectedItem.isAnswerGroup ? selectedItem.answerId : selectedItem.question.contentSection.id;
    const clubId = program?.code;

    if (!!clubId && !!answerId && !!viewUserKey) {
      push(`/progress/${clubId}/view/answers/${answerId}/${viewUserKey}`);
    }

  }, [viewUserKey]);

  useEffect(() => {
    const answersToView = answersToReviewToRender.length && requiredGrading
     ? answersToReviewToRender
     : !!answersCompletedToRender.length
      ? answersCompletedToRender
      : answersIncompleteToRender;

    if (!!answersToView.length && !viewUserKey && (answersToReviewToRender.length || !requiredGrading)) {
      window.scrollTo({top: 0, behavior: "smooth"  });
      setViewUserKey(answersToView[0].userId);
      const answer = answers[answersToView[0].userId][0];
      if (!answer?.comments && !isAnswerGroup) {
        getCommentsByUser({
          programId: answer.programId,
          userId: answer.userId,
          question: answer.question,
          fullPath: `${answer.path}-${answer.question}`,
        });
      }
    }
  }, [answersToReviewToRender, answersCompletedToRender, answersIncompleteToRender]);

  useEffect(() => {
    if (!viewUserKey || !selectedUserId) {
      return;
    }

    if (answers?.length === 0 || !answers[selectedUserId]?.length) return;
    const answer = answers[selectedUserId][0];

    if (!answer?.comments && !isAnswerGroup) {
      getCommentsByUser({
        programId: answer.programId,
        userId: answer.userId,
        question: answer.question,
        fullPath: `${answer.path}-${answer.question}`,
      });
    }
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onShowMembersClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onShowMembersClose = () => {
    setAnchorEl(null);
  };

  const isUserInList = (arr, selectedUserAnswerObj) => arr.some(student => student?.userId === selectedUserAnswerObj)
  return (
    <Box className={classes.root}>
      <Card className={classes.headerBox}>
        <Box className={classes.titleBox}>
          <Box className={classes.assignmentIconBox}>
            {requiredGrading ? <GradeIcon className={classes.gradeIcon} /> : <AssignmentOutlinedIcon />}
          </Box>
          <Typography className={classes.contentHeader}>{heading}</Typography>
        </Box>
        {requiredGrading ? <Typography>{`${answersToReviewToRender.length} To Review`}
            {!!answersToReviewToRender.length ? <ErrorIcon className={classes.notDoneIcon}/> :
              <CheckCircleIcon className={classes.doneIcon}/>}
          </Typography>
          : <Typography>Grading not required</Typography>
        }
        <Box className={classes.backBtnBox}>
        <TextCustomButton
          label={
            <Box className={classes.backBtn}>
              <ArrowBackIcon />
              <Box>Back to all answers</Box>
            </Box>
          }
          onClick={() => onBackClick()}
        />
        </Box>
      </Card>

      <Box className={classes.main}>
      {!isMobile ? 
        <UserTable
          answers={answers}
          setViewUserKey={setViewUserKey}
          viewUserKey={viewUserKey}
          requiredGrading={requiredGrading}
          answersToReviewToRender={answersToReviewToRender}
          answersCompletedToRender={answersCompletedToRender}
          answersIncompleteToRender={answersIncompleteToRender}
          answersNotStartedToRender={answersNotStartedToRender}
          onShowMembersClose={onShowMembersClose}
          getCommentsByUser={getCommentsByUser}
          isAnswerGroup={isAnswerGroup}
          setSortByDate={setSortByDate}
          sortByDate={sortByDate}
        />
        : <Box>
          <CustomButton
            mode="secondary"
            aria-label="Show students"
            className={classes.showStudentsBtn}
            onClick={(e) => onShowMembersClick(e)}>
          <GroupOutlined />
          </CustomButton> 
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={onShowMembersClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{ style: { maxHeight: 350 } }}
        >
          <Box className={classes.closeIconWrapper}>
            <IconButton
              size="medium"
              aria-label="close"
              color="inherit"
              className={classes.closeBtn}
              onClick={onShowMembersClose}>
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Box>
          <UserTable
            answers={answers}
            setViewUserKey={setViewUserKey}
            viewUserKey={viewUserKey}
            requiredGrading={requiredGrading}
            answersToReviewToRender={answersToReviewToRender}
            answersCompletedToRender={answersCompletedToRender}
            answersIncompleteToRender={answersIncompleteToRender}
            answersNotStartedToRender={answersNotStartedToRender}
            onShowMembersClose={onShowMembersClose}
            getCommentsByUser={getCommentsByUser}
            isAnswerGroup={isAnswerGroup}
            setSortByDate={setSortByDate}
            sortByDate={sortByDate}
          />
        </Popover>
      </Box>}

        {!answersToReviewToRender.length && requiredGrading && !viewUserKey ?
          <Card className={classes.teacherViewAllDoneCard}>
            <CardContent className={classes.dialogContent}>
              <GradeIcon className={classes.gradeIconBig} />
              <Typography className={classes.allDoneCardText}>
                  Nice job! You reviewed all submitted answers for this group.
                </Typography>
                <TextCustomButton 
                  label={
                    <Box className={classes.backBtn}>
                      <ArrowBackIcon />
                      <Box>Back to all answers</Box>
                    </Box>
                  }
                  onClick={() => onBackClick()}
                />
            </CardContent>
          </Card>
          : <Card className={classes.teacherViewCard}>
            {!!viewUserKey && isUserInList(students, viewUserKey) && (isAnswerGroup ? <TeacherViewAnswerGroupCard
              user={user}
              answerGroup={answers[viewUserKey][0]}
              students={students}
              headerContent={heading}
              isLoading={isLoadingCommentsByUser || isRemovingComment || isAddingComment || isUpdatingComment}
              addComment={addComment}
              updateAnswerGroupsStatus={updateAnswerGroupsStatus}
              deleteComment={deleteComment}
              requiredGrading={requiredGrading}
              setViewUserKey={setViewUserKey}
              answersToReviewToRender={answersToReviewToRender}
              answerGroupQuestions={answerGroupQuestions}
              isAnswerGroup={isAnswerGroup}
              addAnswerGroupComment={addAnswerGroupComment}
              updateComment={updateComment}
              updateAnswerGroupComment={updateAnswerGroupComment}
              deleteAnswerGroupComment={deleteAnswerGroupComment}
              history={history}
             /> : <TeacherViewAnswerCard
              user={user}
              answers={answers[viewUserKey]}
              students={students}
              headerContent={heading}
              isLoading={isLoadingCommentsByUser || isRemovingComment || isAddingComment || isUpdatingComment}
              addComment={addComment}
              updateStatus={updateStatus}
              deleteComment={deleteComment}
              updateComment={updateComment}
              updateAnswerGroupComment={updateAnswerGroupComment}
              requiredGrading={requiredGrading}
              setViewUserKey={setViewUserKey}
              answersToReviewToRender={answersToReviewToRender}
              isAnswerGroup={isAnswerGroup}
              question={question}
              history={history}
            />)}
          </Card>
        }
      </Box>
    </Box>
  );
};

export default UserAnswers;
