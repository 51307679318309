import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { HQModal } from "../HQModal";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import {
    Box,
    Typography,
} from "@material-ui/core/";
import CustomButton from "../../customButton/customButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextInput from "../../../components/TextInput";
import RadioButton from "../../../components/radioButton/radioButton";
import { StyledFormHelper } from '../../../components/loginSignup/customFormHelper';
import ConfirmDialog from "../../../components/ConfirmDialog";
import * as colors from "../../../theme/colors";

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 500,
    }
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: "bold",
    margin: "10px 0",
  },
  subTitle: {
    marginTop: 30,
    fontSize: 14
  },
  saveBtnContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 35,
  },
  progressIcon: {
    width: 20,
    height: 20,
    marginLeft: 5,
  },
  boxSection: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    marginBottom: 5,
    flexWrap: "wrap",
    [theme.breakpoints.down('xs')]: {
      display: "block",
    },
    },
  radioButtonGroup: {
    fontSize: 16,
    lineHeight: "1.5",
    color: colors.darkThemeGreen,
  },
}));

const renameOptions = [
  'Just This Certificate',
  'All Certificates',
]

const ChangeCerificateNameModal = ({
  isOpen,
  onCloseModal,
  firstName,
  lastName,
  currentCertificate,
  startCertificateNameChangeGeneration,
  memberId,
  loadCertificateNameChangeStatus,
  certificateGenerating,
  setIsCertificateGenerated,
  isCertificateGenerated,
  setIsGenerationRunning,
  isGenerationRunning,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (!currentCertificate && !currentCertificate.id) return;
    loadCertificateNameChangeStatus(currentCertificate.id);
  }, [currentCertificate]);

  useEffect(() => {
    if (!currentCertificate && !currentCertificate.id && !certificateGenerating[currentCertificate.id]) {
      return
    };

    if (!!certificateGenerating[currentCertificate.id]) {
      const isRunning = certificateGenerating[currentCertificate.id].isRunning;
      setIsGenerationRunning(isRunning);
      if (!isRunning && isCertificateGenerated) {
        setIsCertificateGenerated(false);
      }
    }
  }, [certificateGenerating]);

  const [showConfirmEditDialog, setShowConfirmEditDialog] = useState(false);

  if (isGenerationRunning) {
    setIsCertificateGenerated(true);
  }

  return (
    <HQModal title="Edit Certificate Name"
      titleOptions={{ isUpperCase: false, isBold: true }}
      maxWidth="lg"
      isOpen={isOpen}
      onClose={onCloseModal}
    >
      <Box className={classes.formWrapper}>
        <Formik
          validationSchema={Yup.object({
            newFirstName: Yup.string().required("Required"),
            newLastName: Yup.string().required("Required"),
          })}
          initialValues={{
            newFirstName: firstName ?? "",
            newLastName: lastName ?? "",
            renameAll: 'Just This Certificate',
          }}
          validateOnMount={false}
          enableReinitialize={true}
        >
          {({ errors, touched, values }) => <Form>
            <Box className={classes.boxSection}>
              <Box>
                <Typography className={classes.subTitle}>New First Name</Typography>
              </Box>
              <Field
                type="text"
                name="newFirstName"
                component={TextInput}
                label=""
                placeholder="New First Name"
                setTouchedOnChange={true}
                errorIsHtml={true}
              />
              <Box>
                <Typography className={classes.subTitle}>New Last Name</Typography>
              </Box>
              <Field
                type="text"
                name="newLastName"
                component={TextInput}
                label=""
                placeholder="New Last Name"
                setTouchedOnChange={true}
                errorIsHtml={true}
              />
            </Box>
            <Box>
              <Field
                name="renameAll"
                required
                options={renameOptions}
                className={classes.radioButtonGroup}
                shrink={false}
                component={RadioButton}
                errorIsHtml
                color={colors.darkThemeGreen}
              />
            </Box>
            {errors.notChanged && touched && <StyledFormHelper helperText={errors.notChanged} isError={true} isHtml={true} />}
            <Box className={classes.saveBtnContainer}>
              <CustomButton
                onClick={onCloseModal}
                mode="textButton"
              >
                CANCEL
              </CustomButton>
              <CustomButton
                className={classes.generateBtn}
                mode={"primary"}
                disabled={
                  isGenerationRunning || !!errors.notChanged || !!errors.newFirstName || !!errors.newLastName
                }
                label={"Update"}
                onClick={() => {
                  setShowConfirmEditDialog(true);
                }}
              >
                {isGenerationRunning && (
                  <CircularProgress
                    size={20}
                    className={classes.progressIcon}
                  />
                )}
              </CustomButton>
            </Box>
            <ConfirmDialog
              content={`Are you sure you want to change the name on ${values.renameAll === 'All Certificates' ? 'all certificates' : 'this certificate'} to ${values.newFirstName} ${values.newLastName}?`}
              onCancel={() => { setShowConfirmEditDialog(false) }}
              onConfirm={() => {
                setShowConfirmEditDialog(false);
                startCertificateNameChangeGeneration({
                  newFirstName: values.newFirstName,
                  certificateId: values.renameAll === 'All Certificates' ? null : currentCertificate.id,
                  newLastName: values.newLastName ? values.newLastName : '',
                  memberId: parseInt(memberId),
                });
                setIsCertificateGenerated(true);
                setIsGenerationRunning(true);
                onCloseModal();
              }}
              open={showConfirmEditDialog}
              title="Confirm Certificate Update"
              okTitle="Yes"
            />
          </Form>}
        </Formik>
      </Box>
    </HQModal>
  );
};

export default ChangeCerificateNameModal;
