import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as colors from "../../../theme/colors";

const ProgressBar = withStyles({
  root: {
    height: 4,
  },
  colorPrimary: {
    backgroundColor: "#EDEDED",
  },
  bar: {
    backgroundColor: colors.lightThemeGreen,
  },
})(LinearProgress);

export default ProgressBar;
