import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useGoogleLogin } from "@react-oauth/google";

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import GoogleIcon from './icon';
import { getGoogleSsoData } from '../../utils/googleSso';

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    alignItems: 'center',
    height: 32,
  },
  span: {
    opacity: 0.65,
  },
  root: {
    fontWeight: 'bold',
    height: 43,
    marginBottom: 20,
    width: '100%',
  },
}));

const GoogleButton = (props) => {
  const { className, disabled, labelText, onSuccess, onError } = props;
  const classes = useStyles();

  const [token, setToken] = useState(null);

  useEffect(() => {
    if (!token) return;
    getGoogleSsoData(token.access_token, onSuccess, onError);
  }, [token]);

  const login = useGoogleLogin({
    onSuccess: (res) => {
      setToken(res);
    },
    onError: () => onError(),
  });

  return (
    <Button
      onClick={() => login()}
      disabled={disabled}
      className={clsx(classes.root, className)}
      variant="outlined"
      disableElevation
      aria-label={labelText}
    >
      <Box className={classes.box}>
        <GoogleIcon />
        <span className={classes.span} aria-hidden="true">{labelText}</span>
      </Box>
    </Button>
  );
};

export default GoogleButton;
