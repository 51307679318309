export const permissions = {
  facilitatorOnly: 'Facilitators/Teachers only',
};

export const Roles =
{
  Facilitator: 1,
  Student: 2,
  Viewer: 3,
  CommunityPartner: 6,
  LoopParticipant: 11,
  Teacher: 12,
  SipStudent: 13,
}

export const permissionsByRole = {
  [Roles.Facilitator]: {
    name: "Facilitator",
    membersPage: true,
    attendancePage: true,
    progressPageTeacher: true,
    messagesPage: true,
    editAttendance: true,
    editProgram: true,
    viewMembers: true,
    actionOnMembers: true,
    viewInvitations: true,
    addMembers: true,
    facilitatorNotes: true,
    programSectionInfoLink: true,
    showViewerEmail: true,
    showClubIncentives: true,
  },
  [Roles.Student]: {
    name: "Student",
    progressPageStudent: true,
    messagesPage: true,
    showViewerEmail: false
  },
  [Roles.Viewer]: {
    name: "Viewer",
    membersPage: true,
    viewMembers: true,
    facilitatorNotes: false,
    showViewerEmail: true
  },
  [Roles.Teacher]: {
    name: "Teacher",
    membersPage: true,
    attendancePage: true,
    progressPageTeacher: true,
    messagesPage: true,
    editAttendance: true,
    editProgram: true,
    viewMembers: true,
    actionOnMembers: true,
    viewInvitations: true,
    addMembers: true,
    facilitatorNotes: true,
    programSectionInfoLink: true,
    showViewerEmail: true
  },
}

export default Roles;
