import React, { useEffect, useState } from "react";
import { Formik, Field, Form, useFormikContext } from "formik";

import * as moment from "moment";
import * as colors from "../../../theme/colors";
import { HQModal } from "../HQModal";
import TextInput from "../../TextInput";
import Selector from "../../selector/Selector";

import DialogContent from "@material-ui/core/DialogContent";

import Room from "@material-ui/icons/Room";
import MarkunreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox";
import EmojiFlagsIcon from "@material-ui/icons/EmojiFlags";
import { makeStyles } from "@material-ui/core/styles";

import CustomButton from "../../customButton/customButton";
import { PageLeaveModal } from '../../../components/modals/pageLeaveWarning';

const useStyles = makeStyles((theme) => ({
  addButtonExplanation: {
    color: theme.palette.primary.main,
    fontSize: 12,
  },
  marginTop30: {
    marginTop: "30px",
  },
  parentFormTitle: {
    fontSize: 12,
    color: theme.palette.primary.main,
    marginBottom: 10,
    marginTop: 30,
  },
  subTitle: {
    color: theme.palette.primary.main,
    fontSize: 16,
    lineHeight: 1.75,
    textAlign: "center",
  },
  buttonGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none",
  },
  button: {
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: colors.grey400,
    color: "white",
    "&:hover": {
      backgroundColor: colors.grey500,
    },
  },
  submitButton: {
    marginLeft: "20px",
    backgroundColor: colors.tealA700,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: colors.teal500,
    },
  },
}));

export const UserAddressModal = ({
  user,
  statesProvinces,
  loadStateProvinces,
  countries,
  userEditModal,
  onClose,
  onSave,
  isClubChild
}) => {
  const [userState, setUserState] = useState(null);
  const [userCountry, setUserCountry] = useState("");
  const [isEscapeConfirmDialog, setEscapeConfirmDialog] = useState(false);
  const [isDataChanged, setDataChanged] = useState(false);

  useEffect(() => {
    if (!user.countryId) {
      return;
    }

    if (!statesProvinces.length) {
      loadStateProvinces({ countryId: user.countryId, langId: 4 });
    }
    
    setUserCountry(user.countryId.toString());
  }, []);

  const UserStateUpdate = () => {
    const { values } = useFormikContext();

    useEffect(() => {
      if (user.state ) {
        const state = statesProvinces.find((s) => s.label === user.state && !isDataChanged);
        setUserState(state ? state.value : null);
        if (state?.value && !values.state) {
          values.state = state.value;
        }
      }

    }, [statesProvinces]);

    return null;
  };

  const classes = useStyles();

  const onCloseChangeAddressModal = () => {
    if (isDataChanged) {
        setEscapeConfirmDialog(true);
    } else {
        setDataChanged(false);
        setEscapeConfirmDialog(false);
        onClose();
    }
  };

  return (
    <HQModal title='Address' isOpen={userEditModal} onClose={onCloseChangeAddressModal}>
      <DialogContent className={classes.dialogContent}>
        <Formik
          initialValues={{
            city: user.city || "",
            countryId: userCountry || "",
            state: userState || "",
            zipcode: user.zipcode || "",
            streetAddress: user.streetAddress || "",
          }}
          validate={() => {
            setDataChanged(true);
            return ;
          }}
          onSubmit={(values) => {
            let date;
            if (values.birthDate) {
              date = moment.parseZone(values.birthDate).format();
            }

            const newValues = { ...values, birthDate: date };
            onSave(newValues);
            onClose();
          }}
        >
          <Form>
            {!isClubChild && <Field
              name={`streetAddress`}
              component={TextInput}
              label="Street address"
              className={classes.marginTop30}
              Icon={MarkunreadMailboxIcon}
            />}
            {!isClubChild && <Field
              name={`city`}
              component={TextInput}
              label="City"
              className={classes.marginTop30}
              Icon={Room}
            />}
            <UserStateUpdate/>
            {!isClubChild &&
            <Field
              name={`state`}
              component={Selector}
              label="State"
              ariaLabel="your state"
              className={classes.marginTop30}
              options={statesProvinces}
              Icon={EmojiFlagsIcon}
            />}
            {!isClubChild && <Field
              name="zipcode"
              component={TextInput}
              label="Postal code"
              className={classes.marginTop30}
            />}
            <Field
              name={`countryId`}
              component={Selector}
              label="Country"
              ariaLabel="your country"
              className={classes.marginTop30}
              options={countries}
              onValueChange={(value, setFieldValue) => {
                if (value) {
                  loadStateProvinces({ countryId: parseInt(value), langId: 4 });
                }
                setFieldValue("state", "");
                setUserCountry(value);
              }}
              Icon={EmojiFlagsIcon}
            />

            <div variant="contained" className={classes.buttonGroup}>
              <CustomButton
                mode="textButton"
                onClick={() => isDataChanged ? setEscapeConfirmDialog(true) : onClose()}
                label={"Cancel"}
              />
              <CustomButton
                mode="primary"
                type="submit"
                label={"Save"}
              />
            </div>
          </Form>
        </Formik>
      </DialogContent>
      <PageLeaveModal
        onConfirm={() => { setDataChanged(false); setEscapeConfirmDialog(false); onClose(); }}
        onCancel={() => { setEscapeConfirmDialog(false); }}
        open={isEscapeConfirmDialog}
      />
    </HQModal>
  );
};
