import { axiosInstance } from './axiosInstance';
import {getAuthHeader} from "../tokenStorage";

export const getGlobalInfo = async () => {
  return axiosInstance.get(`/blapi/global/getGlobalInfo`);
};

export const generateParentConsentPdf = async () => {
  return axiosInstance.get(`/blapi/global/generateParentConsentPdf`,
    {
      headers: getAuthHeader(),
      responseType: 'blob',
    });
};