import { call, put } from 'redux-saga/effects';

import { globalContentLoaded, curriculumLoaded, homePageContentLoaded } from '../actions/contentful';
import { decrementInitialLoaders } from '../actions/global';
import { getGlobalContent, getHomePageContent } from '../api/graphql/query/global.queries';
import { getCurriculum } from '../api/graphql/curriculum';
import * as globalApi from '../api/global';

const getNodes = (nodes, nodeType, parentPath, coverImage, studentView = false) => {
  const result = {};

  for (const item of nodes) {
    let nodes = {};
    const path = parentPath ? `${parentPath}/${item.slug}` : `/${item.slug}`;

    if (item.sets) {
      nodes = {
        ...nodes,
        ...getNodes(item.sets, 'set', path, null, studentView),
      };
    }

    if (item.collections) {
      nodes = {
        ...nodes,
        ...getNodes(item.collections, 'collection', path, null, studentView),
      };
    }

    if (item.activities) {
      nodes = {
        ...nodes,
        ...getNodes(item.activities, 'activity', path, null, studentView),
      };
    }

    if (item.content) {
      nodes = {
        ...nodes,
        ...getNodes(item.content, 'folder', path, item.coverImage, studentView),
      }
    }

    if (coverImage && !item.icon) {
      item.icon = {
        ...coverImage
      }
    }
    if (!studentView || item.permissionLevel === 'Public') {
      result[item.slug] = {
        ...item,
        nodeType,
        nodes,
        path,
      };
    }
  }
  
  return result;
};

const convertCurriculum = ({ code, welcomeModal, content }) => {
  return {
    code,
    welcomeModal,
    nodes: {
      curriculums: getNodes(content),
      studentCurriculums: getNodes(content, null, null, null, true)
    }
  }
};

export function* loadGlobalContent() {
  try {
    let { data } = yield call(getGlobalContent, (new Date()).getTime());
    //let { data: { HomePageQuery } } = yield call(getHomePageContent);

    const payload = { searchkey: '', options: { where: {} } };

    let { data: {
      hqConfigControl,
      languages,
      locales,
      programTypes,
    } } = yield call(globalApi.getGlobalInfo, payload);

    data = {
      ...data,
      hqConfig: hqConfigControl,
      languages,
      locales,
      programTypes,
    };
    yield put(globalContentLoaded(data));

    yield put(decrementInitialLoaders());
  } catch {
    yield put(decrementInitialLoaders());
  }
}

export function* loadHomePageContent() {
  try {
    let { data } = yield call(getHomePageContent);

    yield put(homePageContentLoaded(data));
  } catch (e) {
    // TODO: throw generic error
    console.log('TODO: handle error here');
    console.log(e);
  }
}

export function* loadCurriculum({ payload: { programId, partnershipId, studentView } }) {
  try {
    let { data } = yield call(getCurriculum, programId, partnershipId, studentView);

    const curriculum = convertCurriculum(data.CurriculumQuery)
    
    yield put(curriculumLoaded(curriculum));
  } catch (e) {
    // TODO: throw generic error
    console.log('TODO: handle error here');
    console.log(e);
  }
}

