import { createReducer } from "redux-act";

import * as a from "../actions/facilitatorApplication";

const getDefaultState = () => ({
  isLoadingApplicationToFacilitate: false,
  isAppliedToFacilitate: false,
});

export default () =>
  createReducer(
    {
      [a.applyToFacilitate]: (state) => {
        return {
          ...state,
          isLoadingApplicationToFacilitate: true,
        };
      },
      [a.receivedResponseOnApplyToFacilitate]: (state, status) => {
        return {
          ...state,
          isAppliedToFacilitate: status,
          isLoadingApplicationToFacilitate: false,
      }},
      [a.cleanApplyToFacilitate]: (state) => {
        return {
          ...state,
          isAppliedToFacilitate: false,
      }},
    },
    getDefaultState()
  );
