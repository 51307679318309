import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";

import { Modal } from "../modal";
import * as colors from "../../../theme/colors";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: '16px 24px 16px',
    background: colors.lightBlue,
    height: '100%',
    overflowY: 'auto',
    [theme.breakpoints.up('sm')]: {
      minWidth: 350,
    }
  },
  titleBox: {
    display: 'flex',
    background: colors.lightBlue,
    padding: '16px 36px'
  },
  closeBtn: {
    marginLeft: 'auto',
    height: 40,
    width: 40,
  },
}));

export const FullscreenModal = (props) => {
  const {
    isOpen,
    onClose,
    children,
  } = props;
  const classes = useStyles();

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={classes.modal} fullScreen>
      <Box className={classes.titleBox}>
        <IconButton
          className={classes.closeBtn}
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
    </Modal>
  );
};