import React  from 'react';
import { Box, CircularProgress, Divider } from '@material-ui/core';

import TextCustomButton from "../../../components/text-button/text-button";

const FacilitatorView = ({ classes, item, program, isLoading, programActivityAnswers, getAnswersForActivity, activityId, url, push }) => {
    const [answersCount, setAnswersCount] = React.useState(0);
  
    React.useEffect(() => {
      if (!program) {
        return;
      }
      const programId = program.id;
  
      if (!programActivityAnswers || !programActivityAnswers[activityId]) {
        // Load answers for program id and activity;
        getAnswersForActivity({ programId, activity: activityId });
      }
    }, []);
  
    React.useEffect(() => {
      if (!program || !program.id) {
        return;
      }

      const answersByActivity = programActivityAnswers && programActivityAnswers[activityId] ? programActivityAnswers[activityId] : [];
      const items = answersByActivity.filter(e => e.path === url && e.question === item.id);
  
      setAnswersCount(items.length);
    }, [programActivityAnswers]);
  
  
    return (
      <Box>
        <Divider variant="middle" />
        <Box>
          {isLoading
            ? <CircularProgress size={20} />
            :
            <Box className={classes.viewBox}>
              <Box className={classes.noAnswersBox}>
                {answersCount ? '' : 'No Answers Yet'}
              </Box>
              <Box>
                <TextCustomButton
                  className={answersCount ? classes.viewAnswersBtn : classes.viewBtnDisabled}
                  onClick={() => {
                    if (!answersCount) {
                      return;
                    }
                    push(`/progress/${program.code}?tab=answers`)
                  }}
                  disabled={!answersCount}
                  disabledSubmit={!answersCount}
                  label={"View Answers"}
                />
              </Box>
            </Box>
          }
        </Box>
      </Box>
    );
  }
  export default FacilitatorView;