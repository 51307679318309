import React from "react";
import axios from 'axios';

import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";

import { HQModal } from "../HQModal";

import { Typography, Box } from "@material-ui/core/";
import { PasswordInput } from "../../../components/PasswordInput";
import * as colors from "../../../theme/colors";
import CustomButton from "../../../components/customButton/customButton";

const useStyles = makeStyles(theme => ({
  addButtonExplanation: {
    color: theme.palette.primary.main,
    fontSize: 12
  },
  marginTop30: {
    marginTop: 30
  },
  parentFormTitle: {
    fontSize: 12,
    color: theme.palette.primary.main,
    marginBottom: 10,
    marginTop: 30
  },
  subTitle: {
    color: theme.palette.primary.main,
    fontSize: 16,
    lineHeight: 1.75,
    textAlign: "center"
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 22,
    fontWeight: "bold",
    margin: "25px auto 15px",
    maxWidth: 330,
    textAlign: "center"
  },
  buttonGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none"
  },
  button: {
    borderRadius: 5,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: colors.grey400,
    color: "white",
    "&:hover": {
      backgroundColor: colors.grey500
    }
  },
  submitButton: {
    borderRadius: 5,
    backgroundColor: colors.tealA700,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: colors.teal500
    }
  }
}));

const validationSchema = Yup.object({
  password: Yup.string().required("Required")
});

const validatePassword = async (value, parent) => {
  if (!value) {
    return;
  }

  return await axios({
    method: 'POST',
    url: `${window._env_.REACT_APP_API_URL}/auth/login`,
    data: {
      login: parent ? parent.email : '',
      password: value.password,
    },
  });
};


export const AuthorizeModal = ({ isOpen, onClose, onSave, parent }) => {
  const classes = useStyles();
  const [error, setError] = React.useState(null);

  return parent ?
    <HQModal title={parent.firstName} isOpen={isOpen} onClose={onClose}>
      <Box>
        <Typography variant="h4" className={classes.subTitle}>
          Please enter password
        </Typography>
        <Formik
          initialValues={{
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={values => {
            validatePassword(values, parent).then(() => {
              setError(null);
              onSave(values);
            }).catch(() => {
              setError('Password not valid!');
            });
          }}
        >
          <Form>
            <Field
              name="password"
              component={PasswordInput}
              label="Password"
              className={classes.marginTop30}
            />
            <Typography>{error}</Typography>
            <div className={classes.buttonGroup}>
              <CustomButton
                mode="textButton"
                onClick={onClose}
                label="Cancel"
              />
              <CustomButton
                mode="primary"
                type="submit"
                label="Submit"
              />
            </div>
          </Form>
        </Formik>
      </Box>
    </HQModal>
    : null;
};
