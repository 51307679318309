import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import * as colors from "../../../theme/colors";
import HqAvatar from "../../../components/avatar/avatar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextButton from "../../../components/text-button/text-button";

import CopyLinkButton from '../../../components/copyLinkButton/copyLinkButton';
import { TooltipWrapper } from '../../../components/tooltip/tooltip';
import RoleLabel from '../../../components/roleLabel/roleLabel';

const useStyles = makeStyles(() => ({
  contentWrapper: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    margin: "auto 10px auto 0",
  },
  contentLabel: {
    paddingRight: 8,
    wordBreak: "break-all",
    color: colors.darkThemeBlueGray,
    fontWeight: "normal",
  },
  contentBox: {
    marginBottom: 15,
  },
  labelBox: {
    marginRight: 10,
    color: colors.darkThemeGreen,
    fontWeight: "bold",
    cursor: "pointer",
    "&:hover": {
      color: colors.lightThemeGreen,
    },
    "&:focus": {
      outline: `2px dotted ${colors.grey400}`,
      outlineOffset: -2
    },
  },
  toolTipButton: {
    fontSize: 12,
    fontWeight: 400,
    textTransform: "lowercase",
    height: 15,
    color: colors.white,
    '&:hover': {
      color: colors.white,
    },
  },
}));

const FacilitatorCardItem = ({ item, index, push, roleLabels, hasFacilitatorPermissions, setEditedUserRoleLabel, club, userRoleLabels, programTypeDetails }) => {
  const classes = useStyles();

  const keyDown = (e) => {
    if (e.key === "Enter") {
      e.target.click();
    }
    return;
  }

  const fullName = `${item.firstName} ${item.lastName ? item.lastName : ''}`;

  const allowRoleLabelCustomization = hasFacilitatorPermissions && roleLabels?.length;
  const userRoleLabel = userRoleLabels?.length ? userRoleLabels.find(label => label.programId === club?.id && label.userId === item.id) : null;

  const renderFacilitatorItem = () => {
    return (
      <Typography sel="facilitator"
        tabIndex={0}
        className={classes.labelBox}
        onClick={() => {
          push(`/profile/${item.id}`);
        }}
        onKeyDown={keyDown}
      >
        {fullName}
      </Typography>
    )
  }

  return (
    <Box key={index} className={classes.contentBox}>
      <Box className={classes.contentWrapper}>
        <Box className={classes.avatar}>
          <HqAvatar user={item} src={item.avatarName && item.avatarUrl} alt="" />
        </Box>
        <Box className={classes.boxContent}>
          {allowRoleLabelCustomization ?
            !userRoleLabel ?
              <TooltipWrapper
                title={
                  <TextButton className={classes.toolTipButton}
                    onClick={() => {
                      setEditedUserRoleLabel({ id: item.id, userRoleLabel, fullName })
                    }}>
                  + add role
                </TextButton>}
                placement="right"
                interactive
                widthMaxContent
              >
                <Box className={classes.contentWrapper} component="span">
                  {renderFacilitatorItem()}
                </Box>
              </TooltipWrapper> :
              <Box className={classes.contentWrapper} component="span">
                {renderFacilitatorItem()}
                <TooltipWrapper
                  title={
                    <TextButton className={classes.toolTipButton}
                      onClick={() => {
                        setEditedUserRoleLabel({ id: item.id, userRoleLabel, fullName })
                      }}>
                      click to edit role
                    </TextButton>
                  }
                  placement="bottom"
                  interactive
                  widthMaxContent
                >
                  <Box component="span">
                    {userRoleLabel && <RoleLabel
                      programTypeDetails={programTypeDetails}
                      roleLabels={roleLabels}
                      userRoleLabel={userRoleLabel}
                    />}
                  </Box>
                </TooltipWrapper>
              </Box> :
              <Box className={classes.contentWrapper} component="span">
                {renderFacilitatorItem()}
                {userRoleLabel && <RoleLabel
                  programTypeDetails={club?.programTypeDetails}
                  roleLabels={roleLabels}
                  userRoleLabel={userRoleLabel}
                />}
              </Box>
          }
          <Box className={classes.boxContent}>
            {item.email && <CopyLinkButton textClass={classes.contentLabel} link={item.email} />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FacilitatorCardItem;
