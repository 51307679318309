import React from "react";

import {
  Box,
  CardContent,
  CardHeader,
  Card,
  Typography,
} from "@material-ui/core/";
import linkifyHtml from "linkify-html";

import { makeStyles } from "@material-ui/core/styles";
import HqAvatar from '../../../components/avatar/avatar';
import { getDateLabel, createMarkup } from "../../../utils/generic";
import * as colors from "../../../theme/colors";

const useStyles = makeStyles(() => ({
  commentContainer: {
    width: '100%',
    marginTop: 20,
    fontSize: 18,
  },
  cardHeaderContainer: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  cardHeader: {
    marginRight: 5,
    fontSize: 18,
  },
  cardContentContainer: {
    margin: "0 20px",
    padding: 0
  },
  commentContent: {
    color: colors.dartThemeBlueGray,
    '& ul': {
      "padding-left": 10,
    },
    '& li': {
      lineHeight: 1.8,
      paddingLeft: 10,
      "&::marker": {
        color: colors.darkThemeGreen,
      }
    },
    '& a': {
      color: colors.darkThemeGreen,
      fontWeight: "medium",
      textTransform: "initial",
    },
  },
}))

const Comments = (item) => {
  const classes = useStyles();
  return (<Box>
    {!!item.comments?.length && item.comments
      .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
      .map((comment) => {

      const commentDate = comment.updatedAt ? comment.updatedAt : comment.createdAt;

      return (
        <Card key={comment.id} className={classes.commentContainer}>
          <CardHeader
            avatar={
              <HqAvatar
                user={comment.owner}
                isFacilitator={true}
                src={comment?.owner?.avatarName && comment?.owner?.avatarUrl}
              />
            }
            title={
              <Box className={classes.cardHeaderContainer}>
                <Typography className={classes.cardHeader}
                >{`${comment?.owner?.firstName} ${comment?.owner?.lastName}`}
                </Typography>
                <Typography className={classes.createdAtLabel}>{getDateLabel(commentDate)}</Typography>
              </Box>
            }
          />
          <CardContent className={classes.cardContentContainer}>
            <Box className={classes.commentContent} dangerouslySetInnerHTML={createMarkup(linkifyHtml(comment.content, { target: "_blank" }))} />
          </CardContent>
        </Card>)
    })
    }
  </Box>)
}

export default Comments;
