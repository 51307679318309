import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import TextButton from "../text-button/text-button";

const useStyles = makeStyles(() => ({
  label: {
    height: 15,
    fontSize: 10,
    fontFamily: "'Roboto Mono',monospace",
    fontWeight: 500,
    cursor: "text",
    "&:hover": {
      cursor: "text",
    },
  },
}));

const RoleLabel = ({ programTypeDetails, userRoleLabel, regularRoleLabel, onClick }) => {
  const classes = useStyles();

  const labelBackground = programTypeDetails?.backgroundColor || null;
  const labelFontColor = programTypeDetails?.fontColor || null;

  const userRoleLabelText = userRoleLabel?.roleLabel?.roleLabel;

  if (!userRoleLabelText && !regularRoleLabel) {
    return null;
  }

  return (
    <TextButton
      className={classes.label}
      style={{ color: labelFontColor, backgroundColor: labelBackground }}
      label={userRoleLabelText}
      tabIndex={onClick ? "0" : "-1"}
      onClick={onClick}
  />);
};

export default RoleLabel;
