import React, { useState, useRef } from "react";

import { makeStyles } from "@material-ui/core/styles";

import { Typography, Chip, Button, Divider } from "@material-ui/core";
import ClearIcon  from '@material-ui/icons/Clear';
import CancelIcon  from '@material-ui/icons/Cancel';

import SearchBar from "./searchBar";
import MultipleSelect from "./customFilter";
import MultipleSectionsSelect from "./multipleSectionsSelect";
import * as colors from "../../../theme/colors";
import { handleKeyPress } from '../../../utils/accessibility';
import { checkIsClubsDomain } from '../../../utils/generic';

const useStyles = makeStyles((theme) => ({
  multiContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "5px 0",
    [theme.breakpoints.down("xs")]: {
      display: "inline-block",
    },
    "&:first-child": {
      marginBottom: 15,
    },
    "&:last-child": {
      marginTop: 15,
    },
  },
  chip: {
    maxWidth: 180,
    margin: 5,
    fontSize: 14,
    color: colors.dartThemeBlueGray,
    backgroundColor: colors.teal,
    '&:focus': {
      outline: 'thin solid black'
    }
  },
  cancelIcon: {
    color: colors.lightThemeGreen,
    '&:focus': {
      outline: 'thin solid black',
      outlineOffset: -2,
    }
  },
  filterBox: {
    [theme.breakpoints.down("xs")]: {
      display: "inline-block",
    },
  },
  filtersRow: {
    display: "inline-flex",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 10,
    },
  },
  labelContainer: {
    fontWeight: 500,
    fontFamily: "'Roboto Mono',monospace",
    fontSize: 12,
    color: colors.lightGrey,
  },
  clearAllBtContainer: {
    marginLeft: 'auto',
  },
  searchContainer: {
    marginLeft: 'auto',
    maxWidth: 232,
    [theme.breakpoints.down("xs")]: {
      marginLeft: '5px',
    },
  },
  clearAllBtn: {
    color: colors.darkThemeGreen,
    fontFamily: "'Roboto',monospace",
    fontWeight: "bold",
    fontSize: 16,
    marginLeft: 'auto',
    minWidth: 120
  },
  showMoreBtn: {
    color: colors.darkThemeGreen,
    fontFamily: "'Roboto',monospace",
    fontSize: 12,
    textTransform: 'none',
  },
  filterTagsHidden: {
    overflow: "hidden",
    maxHeight: 85,
  },
}));

const GalleryFilters = ({
  filters,
  items,
  handleAttributeChange,
  searchKey,
  onSearchInput,
  clearAllFilters,
  labels,
  isLoading,
  onReset,
}) => {
  const classes = useStyles();
  const {
    years,
    projectTypes,
    langProjectTypes,
    topicProjectTypes,
    programTypeIds,
    grades,
    countries,
    flags,
  } = items;
  const isClubsDomain = checkIsClubsDomain();
  const keys = Object.getOwnPropertyNames(filters);
  const isAnySelected = keys.filter((e) => filters[e].length).length > 0;

  const [isExpanded, setIsExpanded] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const fiterTagsRef = useRef(null);

  const multipleFilterItems = [
    {
      key: "onlyChallenge",
      label: "",
      values: flags,
      resetFilters: [],
    },
    {
      key: "programTypeIds",
      label: "Program Type",
      values: programTypeIds,
      resetFilters: ["grades", "countries"],
    },
    {
      key: "countries",
      label: "Countries",
      values: countries,
      resetFilters: ["grades"],
      dependsOn: "programTypeIds",
      hideFilterForClubs: !isClubsDomain,
    },
    {
      key: "grades",
      tooltip: "Available only for the USA location",
      label: "Grades",
      values: grades,
      dependsOn: "programTypeIds",
      showWhenCountrySelected: "225", // Show only if domestic country was selected
      hideFilter: !isClubsDomain,
    }
  ];

  const handleFilterChange = (filterName, attribute, resetFilters) => () => {
    handleAttributeChange(filterName, attribute, resetFilters);
  };

  const renderCheckboxFilter = (label, filterName, attribute, index) => {
    if (!filters[filterName].includes(attribute)) {
      return null;
    }

    return (
      <Chip
        key={index}
        className={classes.chip}
        clickable={true}
        label={label}
        tabIndex={0}
        size="medium"
        aria-label={`chip ${label}`}
        deleteIcon={
          <CancelIcon
            role="button"
            aria-label={`delete chip ${label}`}
            tabIndex={0}
            onKeyDown={handleKeyPress(handleFilterChange(filterName, attribute))} 
            className={classes.cancelIcon}
          />}
        onDelete={handleFilterChange(filterName, attribute)}
      />
    );
  };

  const showMore = () => {
    setIsExpanded(!isExpanded);
    setIsPressed(true);
  }

  const isOffsetHidden = !isPressed ? fiterTagsRef.current?.offsetHeight < fiterTagsRef.current?.scrollHeight : true;

  return (
    <div className={classes.filterBox}>
      <div className={classes.multiContainer}>
        <Typography className={classes.labelContainer}>
          {labels && labels.projectsListFilterFilterLabel}
        </Typography>
        <div className={classes.filtersRow}>
          <MultipleSelect
            className={classes.gradeFilterContainer}
            label={labels && labels.projectsListFilterYear}
            values={years}
            isLoading={isLoading}
            filters={filters}
            onSelectChange={handleFilterChange}
          />

          <MultipleSelect
            className={classes.statusFilterContainer}
            label={labels && labels.projectsListFilterProjectType}
            values={projectTypes}
            filters={filters}
            isLoading={isLoading}
            onSelectChange={handleFilterChange}
          />
        </div>
        <div className={classes.filtersRow}>
          <MultipleSelect
            className={classes.statusFilterContainer}
            label={labels && labels.singleProjectPageLanguageTagsLabel}
            values={langProjectTypes}
            filters={filters}
            isLoading={isLoading}
            onSelectChange={handleFilterChange}
          />
          <MultipleSelect
            className={classes.statusFilterContainer}
            label={labels && labels.singleProjectPageTopicTagsLabel}
            values={topicProjectTypes}
            filters={filters}
            isLoading={isLoading}
            onSelectChange={handleFilterChange}
          />
        </div>
        <MultipleSectionsSelect
          className={classes.statusFilterContainer}
          label={"Advanced Filter"}
          filters={filters}
          isLoading={isLoading}
          items={multipleFilterItems}
          onSelectChange={handleFilterChange}
        />
        <div className={classes.searchContainer}>
          <SearchBar
            labels={labels}
            value={searchKey}
            onChange={onSearchInput}
            onReset={onReset}
          />
        </div>
      </div>

      <Divider />
      {isAnySelected > 0 && (
        <div className={classes.multiContainer}>
          {keys && filters && (
            <div className={isExpanded ? '' : classes.filterTagsHidden} ref={fiterTagsRef} role="application" aria-label="tags">
              {keys.map((k, i) => 
                Array.isArray(filters[k]) && 
                filters[k].map((e, j) => {
                  const currentItem =
                    items && items[k] && items[k].find((i) => i.attribute == e);
                  
                  if (!currentItem || items[k].length == 0) {
                    return null;
                  }
                  return renderCheckboxFilter(currentItem.label, k, e, `${j}${i}`);
                })
              )}
            </div>
          )}
          <div className={classes.clearAllBtContainer}>
            <Button
              startIcon={<ClearIcon />}
              className={classes.clearAllBtn}
              onClick={() => {
                clearAllFilters();
              }}
            >
              Clear All
            </Button>
          </div>
        </div>
      )}
      {isOffsetHidden && isAnySelected > 0 && (
        <div>
          <Button
            className={classes.showMoreBtn}
            onClick={() => {
              showMore();
            }}
          >
            {isExpanded ? 'show less' : 'show more'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default GalleryFilters;
