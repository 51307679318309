import React from "react";

import { Field } from "formik";
import clsx from 'clsx';

import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import TextCustomButton from '../../../components/text-button/text-button';
import RadioButton from "../../../components/radioButton/radioButton";

import * as colors from "../../../theme/colors";

const useStyles = makeStyles({
  subTitle: {
    color: colors.darkThemeBlueGray,
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center"
  },
  userProfile: {
    marginTop: 10,
  },
  subLabel: {
    fontSize: 14,
    "& .MuiTypography-body1": {
      fontSize: 14,
    }
  },
  infoContainer: {
    border: "1px solid " + colors.lightGrey,
    padding: "5px 15px",
    borderColor: colors.darkThemeGreen,
    "& .MuiButton-label": {
      textTransform: "none",
    }

  },
  infoLinkBlock: {
    textAlign: "right",
    textTransform: "none",
    "& .MuiButton-text": {
      textDecoration: "underline",
      textDecorationColor: colors.darkThemeGreen,
    }
  },
  descriptionItem: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 20,
    textTransform: "uppercase",
    "& .MuiListItemText-primary": {
      color: colors.lightGrey,
    },
    "& .MuiListItemText-secondary": {
      color: "black",
      textTransform: "none",
    },
    "& .MuiTypography-body1": {
      margin: 0,
    },
    "& .MuiTypography-body2": {
      margin: 0,
      fontSize: "1rem",
      wordBreak: "break-word",

      "& button": {
        textAlign: "left",
      },
    }
  },
  subDescription: {
    marginTop: 20,
    "& .MuiFormGroup-root": {
      "& .MuiTypography-body1": {
        fontWeight: "bold",
      },
      "& .MuiIconButton-label": {
        color: colors.darkThemeGreen,
      },
    },
    '& .MuiFormLabel-root': {
      fontSize: 16,
      lineHeight: 1.5,
    }
  },
  clubCode: {
    fontWeight: 500,
    marginTop: 20,
    marginBottom: 10,
  },
  backgroundCheckInfo: {
    backgroundColor: colors.teal,
    padding: "11px 13px 8px 13px",
    lineHeight: "20px",
    fontWeight: 400,
  },
});

const SecondStep = ({
  program,
  setPage,
  affilationTypes,
  affilationType,
  backgroundCheckCurrentInit,
  hostSiteAffilationTypes,
  setProgram,
  setSubmitStarted,
  cleanClubInfoByCode,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.infoContainer}>
        <Box className={classes.userProfile}>
          <Typography className={classes.subTitle}>
            Does this look right?
          </Typography>
        </Box>
        <ListItemText
          primary="Club"
          className={classes.descriptionItem}
          secondary={program.name || ''}
        />
        <ListItemText
          primary="Club Code"
          className={classes.descriptionItem}
          secondary={program.code || ''}
        />
        <ListItemText
          primary="Club City"
          className={classes.descriptionItem}
          secondary={program.city || ''}
        />
        <Typography className={clsx(classes.subLabel, classes.infoLinkBlock)}>
          <TextCustomButton
            mode="primary"
            onClick={() => {
              cleanClubInfoByCode();
              setSubmitStarted(false);
              setProgram({});
              setPage(0);
            }}
            label="Try a different code"
          />
        </Typography>
      </Box>
      <Box className={classes.subDescription}>
        <Field
          name="affilationType"
          component={RadioButton}
          className={classes.clubCode}
          label="Are you employed by the school, library, or community organization that will host your Club?"
          options={affilationTypes}
        />
      </Box>
      {affilationType === Object.values(hostSiteAffilationTypes)[1] && <Box className={classes.backgroundCheckInfo}>
        {backgroundCheckCurrentInit ? 
        `It looks like you have a recent background check on file with us! 
        After you submit this application, you will be able to skip this verification step.` :
        `If you are eligible to host or facilitate a Girls Who Code Club, 
        we will follow up with you in the next hour to initiate a background check through 
        VerifiedFirst (portal@verifiedfirst.com) after you submit this application.`
        }
      </Box>}
    </>
  );
};

export default SecondStep;
