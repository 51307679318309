import { createAction } from 'redux-act';

export const getClubInfo = createAction();

export const loadUserProfile = createAction();
export const receivedUserProfile = createAction();

export const receivedClubInfo = createAction();

export const loadStateProvinces = createAction();
export const recievedStateProvinces = createAction();

export const changeUserInfo = createAction();
export const changeUserAddressInfo = createAction();
export const updateUserAvatarImage = createAction();
export const updateUserBio = createAction();

export const changeUserPassword = createAction();
export const passwordChangeCompleted = createAction();
export const setUserPassword = createAction();

export const loadProfileContent = createAction();
export const profileContentLoaded = createAction();

export const updateSmsConsent = createAction();
export const smsConsentSaved = createAction();

export const loadUserProjects = createAction();
export const receivedUserProjects = createAction();

export const updateAcceptedPrivacyPolicy = createAction();
export const receivedAcceptedPrivacyPolicy = createAction();