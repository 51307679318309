import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { push as pushToRoute } from "connected-react-router";

import { Box } from "@material-ui/core";

import SingleSelector from "./SingleSelector";
import { connectTo } from "../../../utils/generic";
import * as authActions from "../../../actions/auth";
import * as actions from "../../../actions/communityPartnerships";
import * as globalActions from "../../../actions/global";

import Tabs from "../Tabs";

const useStyles = makeStyles(theme => ({
  tabsSection: {
    alignItems: "center",
    display: "flex",
    marginLeft: 'auto',
  },
  navContainer: {
    backgroundColor: "#0D9C90",
    display: "flex",
    alignItems: 'center',
    width: "100%",
    minHeight: 48,

    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      minHeight: 48,
      display: 'block',
    }
  },
  programNameWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
    marginLeft: 15,
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const NavBar = (props) => {
  const { currentTab, setCurrentTab, auth: { user, currentRole }, partnerships, onSelectNode, partnership, push } = props;
  const classes = useStyles();
  const [curriculum, setCurriculum] = React.useState(null);

  React.useEffect(() => {
    const nodes = [];
    if (user && currentRole) {
      const currentPartnership = partnerships.communityPartnership;
      if (currentPartnership) {
        nodes.push({
          name: currentPartnership.name,
          id: currentPartnership.id,
        });

        if (currentPartnership.childPartnerships) {
          for (const child of currentPartnership.childPartnerships) {
            nodes.push({
              id: child.id,
              name: child.name,
            });
          }
        }
      }
    }

    setCurriculum({ nodes });
  }, [partnerships, user]);

  const handleNodeClick = (node) => {
    onSelectNode(node);
  };

  const code = currentRole && !!currentRole.partnership ? currentRole.partnership?.code : '';

  return (
    <Box>
      <Box className={classes.navContainer}>
        <Box component='div'>
          <div className={classes.programNameWrapper}>
            <div className={classes.labelContainer}>
              {curriculum && partnership && code &&
                <SingleSelector
                  value={partnership.name}
                  onChange={handleNodeClick}
                  options={curriculum.nodes}
                  code={code}
                />
              }
            </div>
          </div>
        </Box>
        <Box className={classes.tabsSection}>
          <Tabs
            value={currentTab}
            push={push}
            onChange={(evt, newValue) => {
              setCurrentTab(newValue);
            }}
            />
        </Box>
      </Box>
    </Box>
  );
};

export default connectTo(
  (state) => {
    return {
      auth: state.auth,
      contentful: state.contentful,
      partnerships: state.communityPartnerships,
      router: state.router,
    };
  },
  {
    push: pushToRoute,
    ...authActions,
    ...actions,
    ...globalActions,
  },
  NavBar
);
