import { getAuthHeader } from "../tokenStorage";
import { axiosInstance, noCacheHeaders } from './axiosInstance';

export const createAnswer = async (answer) => {
  return axiosInstance.post(
    `/blapi/openTextAnswers/createAnswer`,
    answer,
    {
      headers: getAuthHeader(),
    }
  );
};

export const getMyAnswersByProgram = async ({ programId }) => {
  return axiosInstance.get(
    `/blapi/openTextAnswers/getMyAnswersByProgram/${programId}`,
    {
      headers: {
        ...getAuthHeader(),
        ...noCacheHeaders,
      }
    }
  );
};

export const getUserAnswersByProgram = async ({ programId, userId }) => {
  return axiosInstance.get(
    `/blapi/openTextAnswers/getUserAnswersByProgram/${programId}/${userId}`,
    {
      headers: {
        ...getAuthHeader(),
        ...noCacheHeaders,
      }
    }
  );
};

export const getAllAnswersByActivity = async ({ programId, activity }) => {
  return axiosInstance.get(
    `/blapi/openTextAnswers/getAllAnswersByActivity/${programId}/${activity}`,
    {
      headers: {
        ...getAuthHeader(),
        ...noCacheHeaders,
      }
    }
  );
};

export const getCommentsByAnswer = async ({ programId, question }) => {
  return axiosInstance.get(
    `/blapi/openTextAnswers/getCommentsByAnswer/${programId}/${question}`,
    {
      headers: getAuthHeader(),
    }
  );
};

export const getCommentsByUser = async ({ programId, userId, question }) => {
  return axiosInstance.get(
    `/blapi/openTextAnswers/getCommentsByUser/${programId}/${userId}/${question}`,
    {
      headers: {
        ...getAuthHeader(),
        ...noCacheHeaders,
      }
    }
  );
};

export const getAllAnswersForProgram = async ({ programId }) => {
  return axiosInstance.get(
    `/blapi/openTextAnswers/getAllAnswersForProgram/${programId}`,
    {
      headers: {
        ...getAuthHeader(),
        ...noCacheHeaders,
      }
    }
  );
};

export const addComment = async (payload) => {
  return axiosInstance.post(
    `/blapi/openTextAnswers/addComment`,
    payload,
    {
      headers: getAuthHeader(),
    }
  );
};

export const updateComment = async (payload) => {
  return axiosInstance.post(
    `/blapi/openTextAnswers/updateComment/${payload.programId}/${payload.id}`,
      payload,
    {
      headers: getAuthHeader(),
    }
  );
};

export const updateStatus = async (payload) => {
  return axiosInstance.post(
    `/blapi/openTextAnswers/setStatus`,
    payload,
    {
      headers: getAuthHeader(),
    }
  );
};

export const deleteComment = async ({ id, programId }) => {
  return axiosInstance.post(
    `/blapi/openTextAnswers/deleteComment/${programId}/${id}`,
    null,
    {
      headers: getAuthHeader(),
    }
  );
};