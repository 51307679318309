import React, {useEffect, useState} from "react";
import "date-fns";
import * as moment from "moment";

import {makeStyles, withStyles} from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Create from "@material-ui/icons/Create";
import AttendanceRow from "./attendanceRow";
import PageTitle from "../../components/page-title/pageTitle";
import { AttendanceDownloadModal } from "./attendanceDownloadModal";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import CustomButton from "../../components/customButton/customButton";
import CustomToolbar from '../../components/date-input/CustomDateToolbar';
import * as colors from "../../theme/colors";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {getSortingTable, SORT_DIRECTION, stableSortTable} from "../../utils/sortTable";

const columns = [
  { id: "studentName", label: "Student Name", minWidth: 75 },
  {
    id: "status",
    label: "Status",
    minWidth: 20,
    maxWidth: 20,
    align: "left",
    format: (value) => value,
  },
  {
    id: "comment",
    label: "Comment",
    minWidth: 140,
    maxWidth: 140,
    align: "left",
    format: (value) => value,
  },
  {
    id: "name",
    label: "",
    minWidth: 30,
    createImage: true,
    hideOnView: true,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "15px auto 0",
    width: "95%",
    maxWidth: 1200
  },
  container: {
    maxHeight: 550,
  },
  text: {
    color: theme.palette.primary.main,
    fontSize: 17,
    maxWidth: 330,
  },
  pickerContainer: {
    display: "inline-flex",
    alignItems: "flex-end",
    marginTop: 10,
    marginBottom: 15,
  },
  pickerButton: {
    height: 30,
    marginLeft: 5,
    padding: "0px 15px",
  },
  pencilContainer: {
    width: "40px",
    height: "20px",
    verticalAlign: "middle",
    border: "none",
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  csvContainer: {
    margin: "auto 0",
  },
  csvBox: {
    display: "flex",
  },
  csvTitle: {
    margin: "auto 15px",
  },
  downloadInfo: {
    fontSize: 12,
    color: colors.grey800,
    margin: "auto 15px",
  },
  csvLabelContainer: {
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  }
}));

const StyledTableSortLabel = withStyles({
  root: {
    color: colors.dartThemeBlueGray,
  },
  active: {},
  icon: {
    color: "inherit !important",
  },
})((props) => <TableSortLabel {...props} />);

const AttendanceTable = ({
  data,
  onDateChange,
  openModal,
  onUpdate,
  updatingItems,
  editable,
  isLoading,
  programStartDate,
  attendances
}) => {
  const rows = data || [];
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(75);
  const [selectedDate, setSelectedDate] = useState(
    moment.parseZone(new Date()).format("MMMM Do YYYY")
  );
  const [openPicker, setOpenPicker] = useState(false);
  const [order, setOrder] = useState(SORT_DIRECTION.ASC);
  const [orderBy, setOrderBy] = useState(null);
  const [sortedData, setSortedData] = useState(rows);
  const [isSorted, setIsSorted] = useState(false);
  const [sortedOrder, setSortedOrder] = useState(false);

  useEffect(() => {
    if (rows && rows.length > 0) {
      if (isSorted) {
        if (!!sortedOrder && sortedOrder.length) {
          const sortedList = [];
          sortedOrder.forEach((item) => {
            const filteredItem = rows.filter((row) => row.user.id === item || row.nextUserId === item);
            if (filteredItem?.length === 1) {
              sortedList.push(filteredItem[0]);
            }
          });
          setSortedData(sortedList);
        }
      } else {
        const sortedList = stableSortTable(rows, getSortingTable(order, orderBy));
        setSortedData(sortedList);
        const sortedListOrder = sortedList.map((item) => item.user.id ?? item.nextUserId);
        setSortedOrder(sortedListOrder);
        setIsSorted(true);
      }

    }
  }, [rows, order, orderBy]);

  const handleDateChange = (date) => {
    const formattedDate = moment.parseZone(date).format("YYYY-MM-DD");
    const displayDate = moment.parseZone(date).format("MMMM Do YYYY");
    setIsSorted(false);
    setSelectedDate(displayDate);
    onDateChange(formattedDate);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const openDownloadModal = () => {
    setIsModalOpen(true);
  };

  const onDownloadModalClose = () => {
    setIsModalOpen(false);
  };

  const createSortHandler = (property) => () => {
    if (!isLoading) {
      const isDesc = orderBy === property && order === SORT_DIRECTION.DESC;

      setOrder(isDesc ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC);
      setOrderBy(property);
      setIsSorted(false);
    }
  };

  return (
    <div className={classes.root}>
      <Box className={classes.titleWrapper}>
      <PageTitle title="Attendance Tracker" isLoading={isLoading} />
       {!isLoading && !!programStartDate && (
          <Box className={classes.csvBox}>
            <Box className={classes.csvLabelContainer}>
              <Typography className={classes.csvTitle}>
                Download Attendance
              </Typography>
              <Typography
                className={classes.downloadInfo}
              >{`Since program start date: ${programStartDate}`}</Typography>
            </Box>
            <Tooltip
              title="Download table as CSV"
              className={classes.csvContainer}
            >
              <CustomButton mode="secondary" onClick={openDownloadModal}>
                <GetAppIcon />
              </CustomButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      <div className={classes.pickerContainer}>
        <Typography className={classes.text}>
          {editable ? "Taking" : "Viewing"} attendance for
        </Typography>
        <CustomButton
          className={classes.pickerButton}
          mode={"primary"}
          onClick={() => setOpenPicker(true)}
          label={selectedDate}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            TextFieldComponent={() => {
              return null;
            }}
            open={openPicker}
            inputVariant="filled"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            value={selectedDate}
            onChange={handleDateChange}
            onClose={() => setOpenPicker(false)}
            ToolbarComponent={(props) => <CustomToolbar {...props} />}
          ></KeyboardDatePicker>
        </MuiPickersUtilsProvider>
      </div>
      <Paper>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns
                  .filter((c) => editable || !c.hideOnView)
                  .map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.header}
                      color="primary"
                      sortDirection={orderBy === column.id ? order : false}
                      style={{
                        minWidth: column.minWidth,
                        color: "#083861",
                        fontWeight: "bold",
                      }}
                    >
                      {column.label && <StyledTableSortLabel
                        active={orderBy === column.id}
                        direction={order}
                        className={!isLoading ? classes.sortLabel : classes.disabledSortLabel}
                        onClick={createSortHandler(column.id)}
                      >
                        {column.label}
                      </StyledTableSortLabel>}
                      {column.createImage && (
                        <Create
                          disabled={true}
                          className={classes.pencilContainer}
                        />
                      )}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const uploading = updatingItems
                      ? updatingItems.find((e) => e === row.nextUserId)
                      : false;
                    return (
                      <AttendanceRow
                        row={row}
                        openModal={openModal}
                        onUpdate={onUpdate}
                        isLoading={uploading}
                        editable={editable}
                        key={index}
                      />
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[75, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      {!isLoading && !!programStartDate && isModalOpen && (
        <AttendanceDownloadModal
          isOpen={isModalOpen}
          onClose={onDownloadModalClose}
          data={attendances.filter((e) => e.date > programStartDate)}
        />
      )}
    </div>
  );
};

export default AttendanceTable;
