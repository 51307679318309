export const SORT_DIRECTION =
  {
    ASC: "asc",
    DESC: "desc",
  }

export const descSortTable = (a, b, orderBy) => {

  let item1 = a.sorting[orderBy];
  let item2 = b.sorting[orderBy];

  if (item1 === item2) {
    return 0;
  }

  if (!item1) {
    return -1;
  }
  if (!item2) {
    return 1;
  }

  const type = typeof item1;

  if (type === "date") {
    item1 = a.sorting[orderBy] ? new Date(a.sorting[orderBy]) : null;
    item2 = b.sorting[orderBy] ? new Date(b.sorting[orderBy]) : null;

    if (!item1 && item2) {
      return 0;
    }
  }

  if (type === "string") {
      item1 = a.sorting[orderBy] ? a.sorting[orderBy].toLowerCase() : "";
      item2 = b.sorting[orderBy] ? b.sorting[orderBy].toLowerCase() : "";
  }

  return item1 < item2 ? -1 : 1;
}

const statusesDictionary = {
  "PRESENT": 2,
  "ABSENT": 1,
  null: 3
};

export const stableSortTable = (array, cmp) => {
  const stabilizedThis = array.map((row) => {
    row.sorting = {
      studentName: `${row.user.firstName} ${row.user.lastName}`,
      status: statusesDictionary[row.status] ?? statusesDictionary[null],
      comment: row.comment  ?? null
    }
    return row;
  }).map((el, index) => [el, index]);

  const sortableArray = stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return sortableArray.map((el) => el[0]);
}

export const getSortingTable = (order, orderBy) => {
  return order === SORT_DIRECTION.ASC
    ? (a, b) => descSortTable(a, b, orderBy)
    : (a, b) => -descSortTable(a, b, orderBy);
}