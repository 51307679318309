import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { ConfirmationModal } from "./confirmationModal";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";

import * as colors from "../../../theme/colors";
import { ColorTheme } from '../../../utils/colorTheme';
import CustomButton from "../../customButton/customButton";

import GroupIconOutlined from "@material-ui/icons/GroupOutlined";
import SubjectIcon from "@material-ui/icons/Subject";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { DialogContent, CircularProgress } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { days, monthNames } from "../../../utils/generic";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: '16px 24px 16px',
    [theme.breakpoints.up('sm')]: {
      minWidth: 350,
    }
  },
  title: {
    color: ColorTheme.blue4.b,
    fontSize: 24,
    fontWeight: 500,
    margin: "22px 15px 20px 20px",
    width: "100%",
    wordBreak: 'break-word',
    fontWeight: "bold",
  },
  titleBox: {
    alignItems: 'center',
    display: 'flex',
    background: colors.lightGradient,
    justifyContent: "space-between"
  },
  closeBtn: {
    height: 40,
    width: 40,
    marginRight: 10
  },
  eventName: {
    color: ColorTheme.blue4.b,
    fontSize: 18,
    fontWeight: 500,
    width: "100%",
    wordBreak: 'break-word',
    fontWeight: "bold",
  },
  marginTop30: {
    marginTop: 30,
  },
  sectionWrapper: {
    marginTop: 10,
  },
  subTitleBox: {
    marginTop: 15,
    marginBottom: 10,
    display: "flex",
  },
  subTitleUpper: {
    marginRight: 10,
    fontWeight: 500,
    fontSize: 16,
  },
  sectionItem: {
    marginRight: 10,
  },
  subTitle: {
    color: colors.darkThemeBlueGray,
    fontWeight: 500,
    fontSize: 14,
  },
  buttonGroup: {
    marginTop: 10,
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none",
    fontSize: 16,
  },
  button: {
    borderRadius: 5,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: colors.grey400,
    color: "white",
    "&:hover": {
      backgroundColor: colors.grey500,
    },
  },
  boxSection: {
    display: "inline-flex",
    width: "100%",
  },
  link: {
    color: colors.darkThemeGreen,
    fontSize: 14,
    fontWeight: 500,
    textTransform: "initial",
    lineHeight: 1.75,
    marginTop: 10,
    "&:hover": {
      textDecoration: "none",
    },
    wordBreak: 'break-all',
  },
  subContent: {
    fontSize: 16,
    fontWeight: 500,
    marginTop: 30,
    marginBottom: 10,
  },
  boxWrapper: {
    marginTop: 10,
    display: "flex",
  },
  boxIcon: {
    marginRight: 10,
  },
  optionalTag: {
    width: 70,
    padding: 4,
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 500,
    marginRight: 5,
    borderRadius: 11,
    background: colors.greyLight2,
  },
  publicTag: {
    background: ColorTheme.teal1.b,
    width: 70,
    padding: 4,
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 500,
    marginRight: 5,
    borderRadius: 11,
  },
  partnerTag: {
    background: ColorTheme.orange1.b,
    width: 70,
    padding: 4,
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 500,
    marginRight: 5,
    borderRadius: 11,
  },
  optionSection: {
    marginTop: 5,
    display: "flex",
  },
  checkBoxItem: {
    marginTop: 15,
    backgroundColor: "white !important",
    display: "flex",
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      padding: "0px 10px 0px 0px",
    },
  },
  expandBtn: {
    cursor: "pointer",
    color: colors.darkThemeGreen,
  },
  attendeesBox: {
    display: "flex",
  },
  userLink: {
    color: colors.darkThemeGreen,
    fontWeight: "bold",
    cursor: "pointer",
    fontSize: 14,
    marginLeft: 10,
    "&:hover": {
      color: colors.lightThemeGreen,
    },
  },
  attendeesListTitle: {
    fontSize: 14,
  },
  attendeeEmail: {
    marginLeft: 10,
    fontSize: 14,
  },
  deleteBtnBox: {
    fontWeight: "bold",
    color: colors.grey500,
    marginRight: 5,
  },
  deleteBtn: {
    fontWeight: "bold",
    color: colors.grey500,
    marginRight: 5,
    [theme.breakpoints.down('md')]: {
      display: "none",
    }
  },
  editBtnBox: {
    fontWeight: "bold",
    color: colors.darkThemeGreen,
    marginRight: 5,
  },
  editBtn: {
    fontWeight: "bold",
    color: colors.darkThemeGreen,
    marginRight: 5,
    [theme.breakpoints.down('md')]: {
      display: "none",
    }
  },
  buttonBox: {
    display: "flex",
    alignItems: "center",
  },
  okBtn: {
    width: 80,
  },
  descriptionLabel: {
    fontSize: 14,
  },
  expandBox: {
    display: "flex",
    cursor: "pointer",
  }
}));

export const EventViewContent = ({
  event,
  timezone,
  onDelete,
  onEdit,
  isEditEnable,
  isDeleteEnable,
  onCloseModal,
  isLoadingSingleEventDetails,
  push,
  isExpandEnable,
  isInProgress
}) => {
  const classes = useStyles();

  const [confirmationModal, setConfirmationModal] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState({});

  const showConfirmation = (value) => {
    setConfirmationModal(value);
  };

  const expandAttendees = (index, value) => {
    let newItem = { ...isOpen };
    newItem[index] = value;
    setIsOpen(newItem);
  }

  const date = new Date(`${event.localStartDate}T00:00:00`);
  const eventTime = `${event.localStartTime} to ${event.localEndTime}`;
  const attendeeIds = event.attendees && event.attendees.map((e) => e.userId);
  const isExternalAttendeesExist = event && event.externalAttendees && !!event.externalAttendees.length;

  return (
    <>
      <Box className={classes.titleBox}>
        <Typography variant="h4" className={classes.title}>Event Details</Typography>
        <IconButton
          size="small"
          className={classes.closeBtn}
          color="inherit"
          onClick={onCloseModal}
          aria-label="close"
          disabled={isInProgress}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h2" className={classes.eventName}>{event.name}</Typography>
        <Typography className={classes.subTitle}>{`${days[date.getDay()]}, ${monthNames[date.getMonth()]
          } ${date.getDate()}, ${date.getFullYear()} ${eventTime} ${timezone}`}</Typography>
        <Box className={classes.boxWrapper}>
          <Box>
            <Link
              className={classes.link}
              target="_blank"
              color="secondary"
              href={event.url}
            >
              {event.url}
            </Link>
          </Box>
        </Box>
        {(event.isOptional || event.isPartner || event.isPublic) && (
          <Box className={classes.optionSection}>
            {event.isOptional && (
              <div className={classes.optionalTag}>Optional</div>
            )}
            {event.isPartner && (
              <div className={classes.partnerTag}>Partner</div>
            )}
            {event.isPublic && (
              <div className={classes.publicTag}>Public</div>
            )}
          </Box>
        )}
        <Box>
          <Box className={classes.subTitleBox}>
            <Typography className={classes.subTitleUpper}>Invitees</Typography>
            {isLoadingSingleEventDetails && <CircularProgress size={20} />}
          </Box>
          <Box className={classes.boxWrapper}>
            <GroupIconOutlined className={classes.boxIcon} />
            <Box>
              {!isLoadingSingleEventDetails && <Box>
                {event.programs &&
                  event.programs.map((e, index) => {
                    const invitedPeople = !!e.program && !!e.program.members ? e.program.members.filter((e) =>
                      attendeeIds.find((t) => t === e.userId)
                    ) : [];

                    return (<Box key={index}>
                      <Box className={classes.attendeesBox} >
                        <Box className={isExpandEnable ? classes.expandBox : ""} onClick={() => {
                          if (isExpandEnable) {
                            expandAttendees(index, !isOpen[index]);
                          }
                        }}>
                          <Typography
                            className={classes.attendeesListTitle}
                            key={index}
                          >{`${!!e.program ? e.program.name : ""} (${invitedPeople.length})`}</Typography>
                          {isExpandEnable && <>
                            {isOpen[index]
                              ? <ExpandMoreIcon className={classes.expandBtn} />
                              : <KeyboardArrowRightIcon className={classes.expandBtn} />}
                          </>}
                        </Box>
                      </Box>
                      {isOpen[index] && <Box>
                        {invitedPeople && invitedPeople.map((e, ind) => {
                          return <Typography
                            key={ind}
                            className={classes.userLink}
                            onClick={() => {
                              push(`/profile/${e.userId}`);
                            }}
                          >{e.email || e.username}</Typography>
                        })}
                      </Box>}
                    </Box>
                    );
                  })}
              </Box>}
              <Box>
                {!isLoadingSingleEventDetails && isExternalAttendeesExist && <Box className={classes.attendeesBox}>
                  <Box className={isExpandEnable ? classes.expandBox : ""} onClick={() => {
                    if (isExpandEnable) {
                      expandAttendees(-1, !isOpen[-1]);
                    }
                  }}>
                    <Typography className={classes.attendeesListTitle}>{`External (${event && event.externalAttendees ? event.externalAttendees.length : 0})`}</Typography>
                    {isExpandEnable && <>
                      {isOpen[-1]
                        ? <ExpandMoreIcon className={classes.expandBtn} />
                        : <KeyboardArrowRightIcon className={classes.expandBtn} />}
                    </>}
                  </Box>
                </Box>}
                {isOpen[-1] && <Box>
                  {event.externalAttendees && event.externalAttendees.map(e => { return <Typography className={classes.attendeeEmail}>{e.email}</Typography> })}
                </Box>}
              </Box>
            </Box>

          </Box>
        </Box>
        <Box>
          <Typography className={classes.subContent}>Details</Typography>
          <Box className={clsx(classes.boxWrapper, classes.sectionWrapper)}>
            <SubjectIcon className={classes.boxIcon} />
            <Box>
              <Typography className={classes.descriptionLabel}>{event.description}</Typography>
            </Box>
          </Box>
          {event.autoInviteMembers && (
            <Box className={classes.checkBoxItem}>
              <Checkbox checked={event.autoInviteMembers} disabled={true} />
              <Typography>Automatically invite new Members</Typography>
            </Box>
          )}
        </Box>
        <Divider className={classes.marginTop30} />
        <ConfirmationModal
          isOpen={confirmationModal}
          onClose={() => showConfirmation(false)}
          onSave={() => {
            onDelete(event.id);
            showConfirmation(false);
          }}
        />
        <div className={classes.buttonGroup}>
          <Box>
            {isEditEnable && !isLoadingSingleEventDetails &&
              <Button className={classes.editBtnBox} onClick={() => onEdit()} sel="edit" disabled={isInProgress}>
                <Box className={classes.buttonBox}>
                  <Typography className={classes.editBtn}>Edit</Typography>
                  <EditOutlinedIcon />
                </Box>
              </Button>
            }
            {isDeleteEnable && (
              <Button className={classes.deleteBtnBox} onClick={() => showConfirmation(true)} sel="delete" disabled={isInProgress}>
                <Box className={classes.buttonBox}>
                  <Typography className={classes.deleteBtn}>Delete</Typography>
                  <DeleteOutlineIcon />
                </Box>
              </Button>
            )}
          </Box>
          <CustomButton
            disabled={isInProgress}
            className={classes.okBtn}
            mode="primary"
            onClick={onCloseModal}
            label={"Ok"}
          />
        </div>
      </DialogContent>
    </>
  );
};
