import { createReducer } from 'redux-act';

import * as a from '../actions/facilitatorsConsentAgreement';

const getDefaultState = () => ({
  isLoadingFacilitatorsConsentAgreement: false,
  facilitatorConsent: {},
});

export default () =>
  createReducer(
    {
      [a.setFacilitatorsConsentAgreement]: (state) => {
        return {
          ...state,
          isLoadingFacilitatorsConsentAgreement: true,
        };
      },
      [a.setFacilitatorsConsentAgreementSuccess]: (state, programId) => {
        const facilitatorConsent = { ...state.facilitatorConsent };
        facilitatorConsent[programId] = { consented: true };

        return {
          ...state,
          facilitatorConsent,
          isLoadingFacilitatorsConsentAgreement: false,
        };
      },
      [a.setFacilitatorsConsentAgreementError]: (state) => {
        return {
          ...state,
          isLoadingFacilitatorsConsentAgreement: false,
        };
      },
    },
    getDefaultState()
  );
