import { call, put, select } from 'redux-saga/effects';
import { getV18 } from 'fingerprintjs2';

import * as graphqlTrackingLogMutations from '../api/graphql/mutation/tracking.mutation';
import { trackingIdLoaded } from '../actions/tracking';

export const getAuth = (state) => state.auth;
export const getTracking = (state) => state;
const feature = 'SIGNUP';

const getFingerprint = async () => {
  let hash = localStorage.getItem('fingerprint');

  if (hash) {
    return hash;
  }

  return await getV18({}, (hash, components) => {
    localStorage.setItem('fingerprint', hash);

    graphqlTrackingLogMutations.createFingerprint({ hash, data: JSON.stringify(components)});

    return hash;
  });
};

export function* createTrackingLog({payload}) {
  try {
    if (payload.userNextId) {
      yield call(graphqlTrackingLogMutations.addTrackingLog, {...payload, userNextId: payload.userNextId.toString()});
      return;
    }

    const fingerprint = yield call(getFingerprint);

    if (fingerprint) {
      let { user } = yield select(getAuth);
      const newPayload = {...payload, userNextId: user ? user.id.toString() : null, fingerprint};
      yield call(graphqlTrackingLogMutations.addTrackingLog, newPayload);
    }
  } catch {
    // TO DO: add error msg
  }
}

export function* createUsageLog({payload}) {
  try {
    const fingerprint = yield call(getFingerprint);
    let { user: { id }} = yield select(getAuth);
    const newPayload = { ...payload, userNextId: id.toString(), fingerprint };
    yield call(graphqlTrackingLogMutations.addUsageLogs, newPayload);
  } catch {
    // TO DO: add error msg
  }
}

export function* createUsageTrackingEvent({payload}) {
  try {
    const { tracking: { trackingId }} = yield select(getTracking);

    let usageTrackingId = trackingId;
    let { action, eventTime, data } = payload;

    if (!trackingId) {
      const { data: { createUsageTrackingEntry }} = yield call(graphqlTrackingLogMutations.addUsageTrackingEntry, {feature});
      usageTrackingId = createUsageTrackingEntry;
    }

    if (data) {
      delete data.password;
      data = JSON.stringify(data);
    }
    const newPayload = {usageTrackingId, event: {action, eventTime, data }};
    yield call(graphqlTrackingLogMutations.addUsageTrackingEvents, newPayload);
    yield put(trackingIdLoaded({trackingId: usageTrackingId}));
  } catch {
    // TO DO: add error msg
  }
}


