import React from 'react';
import Typography from "@material-ui/core/Typography";
import {getDateLabel} from "../utils/generic";
import {makeStyles} from "@material-ui/core/styles";
import * as colors from "../theme/colors";

const useStyles = makeStyles(() => ({
    container: {
        fontSize: 12,
        color: colors.lightGrey,
    },
}));

const AnswerStatusDetails = ({createdAt, status, statusChangedAt, statusChanger, showDate = true}) => {
    const classes = useStyles();

    const showStatus = statusChangedAt && status !== null;

    return (
        <>
            {showDate && <Typography className={classes.container}>Submitted: {getDateLabel(createdAt)}</Typography>}
            {showStatus &&
                <Typography className={classes.container}>Marked {status ? "Complete" : "Incomplete"}: {getDateLabel(statusChangedAt)} &nbsp;
                    {statusChanger && <>
                        by {statusChanger.firstName} {statusChanger.lastName}
                    </>}
                </Typography>
            }
        </>
    );
};

export default AnswerStatusDetails;