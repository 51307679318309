import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, CircularProgress } from "@material-ui/core";
import * as colors from "../../../theme/colors";

import { connectTo } from "../../../utils/generic";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: 'center',
  },
  typography: {
    display: "flex",
  },
  progressItem: {
    zIndex: 1,
    height: 45,
    width: 45,
    // transform: 'rotate(90deg) !important',
    color: (props) => props.mainColor,
  },
  boxWrapper: {
    borderRadius: 25,
    backgroundColor: (props) => props.backgroundColor,
  },
  whiteCircle: {
    height: 13,
    width: 13,
    backgroundColor: 'white',
    borderRadius: '50%',
  }
});

const styleTypes = {
  primary: {
    mainColor: colors.blue2,
    backgroundColor: colors.blue1,
  },
  secondary: {
    mainColor: colors.blue2,
    backgroundColor: colors.blue1,
  },
};

const ProgressCircleBlue = ({ value }) => {
  const classes = useStyles( value !== 100 ? styleTypes.primary : styleTypes.secondary );

  return (
    <div className={classes.root}>
      <Box position="relative" display="inline-flex">
        <CircularProgress thickness={15} variant="static" value={value} size={'medium'} className={classes.progressItem} />
        <Box
          className={classes.boxWrapper}
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <div className={classes.whiteCircle}/>
        </Box>
      </Box>
    </div>
  );
};

export default connectTo(
  (state) => {
    return {
      curriculum: state.contentful.curriculums,
      auth: state.auth,
      contentful: state.contentful,
      userActivitiesInfo: state.userActivitiesInfo,
    };
  },
  {},
  ProgressCircleBlue
);
