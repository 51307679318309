import { getAuthHeader } from "../tokenStorage";
import { axiosInstance, noCacheHeaders } from './axiosInstance';

export const getMyAnswerGroupsByProgram = async ({ programId }) => {
  return axiosInstance.get(
    `/blapi/answerGroups/getMyAnswerGroupsByProgram/${programId}`,
    {
      headers: {
        ...getAuthHeader(),
        ...noCacheHeaders,
      }
    }
  );
};

export const getAnswerGroupsByProgram = async ({ programId }) => {
  return axiosInstance.get(
    `/blapi/answerGroups/getAllAnswerGroupsForProgram/${programId}`,
    {
      headers: {
        ...getAuthHeader(),
        ...noCacheHeaders,
      }
    }
  );
};

export const getUserAnswerGroupsByProgram = async ({ programId, userId }) => {
  return axiosInstance.get(
    `/blapi/answerGroups/getUserAnswerGroupsByProgram/${programId}/${userId}`,
    {
      headers: {
        ...getAuthHeader(),
        ...noCacheHeaders,
      }
    }
  );
};

export const updateAnswerGroupsStatus = async (payload) => {
  return axiosInstance.post(
    `/blapi/answerGroups/setStatus`,
    payload,
    {
      headers: getAuthHeader(),
    }
  );
};

export const deleteAnswerGroupComment = async ({ id, programId, answerGroupId }) => {
  return axiosInstance.delete(
    `/blapi/answerGroups/deleteAnswerGroupComment/${programId}/${answerGroupId}/${id}`,
    {
      headers: getAuthHeader(),
    }
  );
};

export const addAnswerGroupComment = async (payload) => {
  const { programId, answerGroupId, content } = payload;
  return axiosInstance.post(
    `/blapi/answerGroups/addAnswerGroupComment/${programId}/${answerGroupId}`,
    { content },
    {
      headers: getAuthHeader(),
    }
  );
};

export const updateAnswerGroupComment = async (payload) => {
    return axiosInstance.post(
        `/blapi/answerGroups/updateAnswerGroupComment/${payload.programId}/${payload.id}`,
        payload,
        {
            headers: getAuthHeader(),
        }
    );
};
