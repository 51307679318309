import { call, put } from 'redux-saga/effects';

import {
  recievedUserAnswerGroups,
  getAnswerGroupsError,
  receivedAllAnswerGroups,
  receivedUpdatedAnswerGroup,
  receivedDeletedAnswerGroupComment,
  deleteAnswerGroupCommentError,
  receiveAddedAnswerGroupComment,
  addAnswerGroupCommentError,
  updateAnswerGroupCommentError, receiveUpdatedAnswerGroupComment,
} from '../actions/answerGroups';
import * as answerGroupsApi from '../api/answerGroups';
import { showErrorMessage, showSuccessMessage } from '../actions/notification';

const successUpdateStatus = 'Answer group status was successfully updated';
const errorUpdateStatus = "Answer group status wasn't updated";

const successDeletedComment = 'Comment was successfully deleted';
const successPostedComment = 'Comment was successfully added';
const successUpdatedComment = 'Comment was successfully updated';

const errorDeletedComment = "Comment wasn't deleted";
const errorPostedComment = "Comment wasn't added";
const errorUpdatedComment = "Comment wasn't updated";

export function* getMyAnswerGroupsByProgram({ payload }) {
  try {
    let { data } = yield call(
      answerGroupsApi.getMyAnswerGroupsByProgram,
      payload
    );

    yield put(recievedUserAnswerGroups({ data }));
  } catch (e) {
    yield put(getAnswerGroupsError());
    // TODO: throw generic error
    console.log('TODO: handle error here');
    console.log(e);
  }
}

export function* getUserAnswerGroupsByProgram({ payload }) {
  try {
    let { data } = yield call(
      answerGroupsApi.getUserAnswerGroupsByProgram,
      payload
    );

    yield put(recievedUserAnswerGroups({ data }));
  } catch (e) {
    yield put(getAnswerGroupsError());
    // TODO: throw generic error
    console.log('TODO: handle error here');
    console.log(e);
  }
}

export function* getAnswerGroupsByProgram({ payload }) {
  try {
    const { programId } = payload;
    let { data } = yield call(answerGroupsApi.getAnswerGroupsByProgram, {
      programId,
    });

    yield put(receivedAllAnswerGroups({ data }));
  } catch (e) {
    yield put(getAnswerGroupsError());
    // TODO: throw generic error
    console.log('TODO: handle error here');
    console.log(e);
  }
}

export function* updateAnswerGroupsStatus({ payload }) {
  try {
    let { data } = yield call(
      answerGroupsApi.updateAnswerGroupsStatus,
      payload
    );

    if (!data) {
      yield put(showErrorMessage(errorUpdateStatus));
    } else {
      yield put(receivedUpdatedAnswerGroup({ ...payload, data }));
      yield put(showSuccessMessage(successUpdateStatus));
    }
  } catch (e) {
    yield put(showErrorMessage(errorUpdateStatus));
    // TODO: throw generic error
    console.log('TODO: handle error here');
    console.log(e);
  }
}

export function* addAnswerGroupComment({ payload }) {
  try {
    let { data } = yield call(answerGroupsApi.addAnswerGroupComment, payload);

    if (!data || !data.id) {
      yield put(addAnswerGroupCommentError());
      yield put(showErrorMessage(errorPostedComment));
    } else {
      yield put(receiveAddedAnswerGroupComment({ ...payload, data }));
      yield put(showSuccessMessage(successPostedComment));
    }
  } catch (e) {
    yield put(showErrorMessage(errorPostedComment));
    yield put(addAnswerGroupCommentError());
    // TODO: throw generic error
    console.log('TODO: handle error here');
    console.log(e);
  }
}

export function* updateAnswerGroupComment({ payload }) {
  try {
    let { data } = yield call(answerGroupsApi.updateAnswerGroupComment, payload);

    if (!data || !data.id) {
      yield put(updateAnswerGroupCommentError());
      yield put(showErrorMessage(errorUpdatedComment));
    } else {
      yield put(receiveUpdatedAnswerGroupComment({ ...payload, data }));
      yield put(showSuccessMessage(successUpdatedComment));
    }
  } catch (e) {
    yield put(showErrorMessage(errorUpdatedComment));
    yield put(updateAnswerGroupCommentError());
  }
}

export function* deleteAnswerGroupComment({ payload }) {
  try {
    let { data } = yield call(
      answerGroupsApi.deleteAnswerGroupComment,
      payload
    );

    if (!data || !data.id) {
      yield put(addAnswerGroupCommentError());
      yield put(showErrorMessage(errorDeletedComment));
    } else {
      yield put(receivedDeletedAnswerGroupComment(payload));
      yield put(showSuccessMessage(successDeletedComment));
    }
  } catch (e) {
    yield put(showErrorMessage(errorDeletedComment));
    yield put(deleteAnswerGroupCommentError());
  }
}
