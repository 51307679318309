import React from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import * as momentTimezone from "moment-timezone";

import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";

import { HQModal } from "../HQModal";
import * as colors from "../../../theme/colors";
import CustomButton from "../../../components/customButton/customButton";

import TimezoneSelector from "./timezoneSelector";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    minWidth: 270,
    padding: 0,
  },
  marginTop30: {
    marginTop: 30,
  },
  subContent: {
    marginTop: 30,
    marginBottom: 10,
  },
  sectionItem: {
    marginRight: 10,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 22,
    fontWeight: "bold",
    margin: "25px auto 15px",
    maxWidth: 330,
    textAlign: "center",
  },
  buttonGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none",
    fontSize: 16,
  },
  button: {
    borderRadius: 5,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: colors.grey400,
    color: "white",
    "&:hover": {
      backgroundColor: colors.grey500,
    },
  },
  boxSection: {
    display: "inline-flex",
    width: "100%",
  },
  submitButton: {
    borderRadius: 5,
    backgroundColor: colors.tealA700,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: colors.teal500,
    },
  },
}));

export const TimezoneModal = ({ timezone, isOpen, onSave, onCloseModal }) => {
  const classes = useStyles();

  const tzData = momentTimezone.tz.names().map((e) => {
    return {
      id: e,
      name: e,
    };
  });

  return (
    <HQModal title="Change timezone" isOpen={isOpen} onClose={onCloseModal}>
      <DialogContent className={classes.dialogContent}>
        <Formik
          initialValues={{
            timezone: timezone || momentTimezone.tz.guess(),
          }}
          validationSchema={Yup.object({
            timezone: Yup.string()
              .trim()
              .required("Required")
              .typeError("Required"),
          })}
          onSubmit={(values) => {
            onSave(values);
          }}
        >
          <Form>
            <Field
              name="timezone"
              component={TimezoneSelector}
              label={"Your Timezone"}
              items={tzData}
            />

            <div className={classes.buttonGroup}>
              <CustomButton
                mode="textButton"
                onClick={onCloseModal}
                label={"Cancel"}
              />
              <CustomButton mode="primary" type="submit" label={"Submit"} />
            </div>
          </Form>
        </Formik>
      </DialogContent>
    </HQModal>
  );
};
