import React from "react";
import { Formik, Field, Form } from "formik";

import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import * as Yup from "yup";

import { HQModal } from "../HQModal";
import * as colors from "../../../theme/colors";
import CustomButton from "../../../components/customButton/customButton";
import SearchSelector from "../../../components/search-select/searchSelect";
import { PageLeaveModal } from "../pageLeaveWarning";

import { createMarkup } from "../../../utils/generic";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: 35,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 22,
    fontWeight: "bold",
    margin: "25px auto 15px",
    minWidth: 350,
    textAlign: "center",
  },
  textWrapper: {
    color: colors.darkThemeBlueGray,
    fontSize: 14,
  },
  fieldWrapper: {
    margin: "15px 0px"
  },
  buttonGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none",
    fontSize: 16,
  },
  button: {
    borderRadius: 5,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: colors.grey400,
    color: "white",
    "&:hover": {
      backgroundColor: colors.grey500,
    },
  },
  submitButton: {
    borderRadius: 5,
    backgroundColor: colors.tealA700,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: colors.teal500,
    },
  },
  updateAllCheckbox: {
    '& .MuiFormControlLabel-root': {
      margin: "0px",
      '& .MuiButtonBase-root': {
        padding: '9px 0px',
      }
    }
  },
  noPartnershipsMsg: {
    fontSize: 16,
    color: colors.darkThemeBlueGray,
  }
}));

export const PartnerAffiliationModal = ({
  program,
  isOpen,
  onSave,
  communityPartners,
  onCloseModal,
  labels,
}) => {
  const classes = useStyles();

  const [isEscapeConfirmDialog, setEscapeConfirmDialog] = React.useState(false);
  const [isDataChanged, setDataChanged] = React.useState(false);

  const options = communityPartners.map(e => {
    return {
      id: e.id,
      label: e.name
    }
  });
  const itemsExist = !!communityPartners && communityPartners.length;

  return (
    <HQModal title="Community Partner Affiliation" isOpen={isOpen} onClose={() => isDataChanged ? setEscapeConfirmDialog(true) : onCloseModal()}>
      <DialogContent className={classes.dialogContent}>
        {itemsExist ?
          <>
            <Typography className={classes.textWrapper}>If your Club is associated with a Community Partner (school districts, library networks, non-profit organizations)
              you can search for your affiliation here.
            </Typography>
            <Formik
              initialValues={{
                partnershipId: program.cpOrganizationId ? parseInt(program.cpOrganizationId) : "",
              }}
              validationSchema={Yup.object({
                partnershipId: Yup.number()
                  .positive()
                  .required("Required"),
              })}
              validate={() => {
                setDataChanged(true);
              }}
              onSubmit={(values) => {
                const item = communityPartners.find(e => e.id === values.partnershipId);
                if (item) {
                  onSave({ item });
                  setDataChanged(false);
                  onCloseModal();
                }
              }}
            >
              <Form>
                <Field
                  name="partnershipId"
                  component={SearchSelector}
                  label="Search Community Partnerships"
                  errorIsHtml={true}
                  className={classes.fieldWrapper}
                  options={options}
                />

                <Typography
                  className={classes.textWrapper}
                  dangerouslySetInnerHTML={createMarkup(labels.partnerAffiliationBenefitsLabel)} />
                <div className={classes.buttonGroup}>
                  <CustomButton
                    mode="secondary"
                    onClick={() => isDataChanged ? setEscapeConfirmDialog(true) : onCloseModal()}
                    label={"Cancel"}
                  />
                  <CustomButton mode="primary" type="submit" label={"Save"} />
                </div>
              </Form>
            </Formik>
          </>
          : <Box>
            <Typography
              className={classes.noPartnershipsMsg}
              dangerouslySetInnerHTML={createMarkup(!!labels
                ? labels.partnerAffiliationNoDataMsg.replace("{{programYear}}", program.year)
                : "")}
            />
            <div className={classes.buttonGroup}>
              <CustomButton
                mode="secondary"
                onClick={onCloseModal}
                label={"Cancel"}
              />
            </div>
          </Box>}
      </DialogContent>
      <PageLeaveModal
        onConfirm={() => { setDataChanged(false); setEscapeConfirmDialog(false); onCloseModal(); }}
        onCancel={() => { setEscapeConfirmDialog(false); }}
        open={isEscapeConfirmDialog}
      />
    </HQModal>
  );
};
