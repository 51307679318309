import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
} from "@material-ui/core/";
import TextInput from '../../../components/TextInput';
import { filterField, filterFieldsByKey } from '../../../utils/generic';
import * as colors from "../../../theme/colors";
import { createMarkup } from '../../../utils/generic';

const useStyles = makeStyles(() => ({
  description: {
    margin: "30px 10px",
    textWrap: "nowrap",
    color: colors.darkBlue100,
  },
  errorMsg: {
    color: colors.redA800,
    marginBottom: 12,
    fontWeight: 600,
    padding: "10px 40px",
    border: `2px solid ${colors.redA400}`,
    fontFamily: "Roboto Mono",
    textAlign: "center",
  },
}));

const alphanumericRegex = /^[a-zA-Z0-9]*$/;

const ClubStep = ({ signUpContent, checkingByCodeResult, setPage, clubCode, isClubCodeValidating, setErrorByClubCode, cleanCheckClubByCode }) => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  let clubCodeContent = filterField(signUpContent.signupFields, "id", "clubCode");
  useEffect(() => {
    if (clubCode && !isClubCodeValidating && checkingByCodeResult && !checkingByCodeResult?.errorMsg) {
      setError(null);
      setPage(1);
    } 
    if (checkingByCodeResult?.errorMsg) {
       setError(checkingByCodeResult?.errorMsg)
    }
    setErrorByClubCode(!!checkingByCodeResult?.errorMsg)
  }, [checkingByCodeResult, isClubCodeValidating]);

  const validateCode = value => {
    cleanCheckClubByCode();
    if (!value.clubCode) {
      setError(null);
    }
    const validationErrors = filterFieldsByKey(clubCodeContent.validationErrors, "messageCode", "message");
    if (!alphanumericRegex.test(value)) {
      return validationErrors.invalidCharactersID;
    }
  }

  return (
    <React.Fragment>
      {error && !!error.length && <Typography role='alert' aria-label={`alert ${error.replaceAll(/<\/?p[^>]*>/g, '')}`} className={classes.errorMsg} dangerouslySetInnerHTML={createMarkup(error)} />}
      <Box>
        <Typography className={classes.description}>Members join using the code provided by your facilitator.</Typography>
      </Box>
      <Field
        className={classes.clubCode}
        name="clubCode"
        component={TextInput}
        label="Program Code"
        validate={validateCode}
        errorIsHtml={true}
        InputLabelProps={{ shrink: true }}
        required
      />
    </React.Fragment>
  );
};

export default ClubStep;
