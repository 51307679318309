import { call, put } from "redux-saga/effects";

import {
  receivedCommunityPartnership,
  receivedCommunityPartnershipStatistic,
  receiveEmailPreviewTemplate
} from "../actions/communityPartnerships";
import { showSuccessMessage } from "../actions/notification";
import * as communityPartnershipsApi from "../api/bl-query/communityPartnerships.query";
import * as graphQlEmailNotificationMutationsApi from "../api/graphql/mutation/emailNotification.mutation";
import * as graphQlEmailNotificationQueriesApi from "../api/graphql/query/emailNotification.queries";

export function* getCommunityPartnership({ payload }) {
  try {
    const {
      data: {
        communityPartnership,
        partnershipStats,
      }
    } = yield call(
      communityPartnershipsApi.getCPWithStatsById,
      { id: payload.id }
    );
    
    if (payload.loadOnlyStat) {
      yield put(
        receivedCommunityPartnershipStatistic({
          partnershipStats: partnershipStats
        })
      );
      return;
    }
    yield put(
      receivedCommunityPartnership({
        communityPartnership: communityPartnership,
        partnershipStats: partnershipStats
      })
    );
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* previewEmail({ payload }) {
  try {
    const {
      user,
      additionalMessage,
      firstName,
      cpName,
      isInternational
    } = payload;

    const newPayload = {
      notificationCode: isInternational
        ? "partnerInternationalInvitation"
        : "partnerInvitation",
      locale: "en-US"
    };

    const {
      data: { EmailNotificationQuery }
    } = yield call(
      graphQlEmailNotificationQueriesApi.getEmailNotificationByLocale,
      newPayload
    );

    const isAdditionalMessageExist = additionalMessage && additionalMessage.trim().length > 0;
    const note = isAdditionalMessageExist
      ? `A note from ${user.firstName} ${user.lastName} : ${additionalMessage}`
      : '';

    EmailNotificationQuery.htmlContent = EmailNotificationQuery.htmlContent
      .replace("{{invitedFirstName}}", firstName)
      .replace("{{inviterFirstName}}", user.firstName)
      .replace("{{inviterLastName}}", user.lastName)
      .replace("{{additionalMessage}}", note)
      .replace(new RegExp("{{communityPartnerName}}", "g"), cpName);

    EmailNotificationQuery.plainTextContent = EmailNotificationQuery.plainTextContent
      .replace("{{invitedFirstName}}", firstName)
      .replace("{{inviterFirstName}}", user.firstName)
      .replace("{{inviterLastName}}", user.lastName)
      .replace("{{additionalMessage}}", note)
      .replace(new RegExp("{{communityPartnerName}}", "g"), cpName);

    yield put(receiveEmailPreviewTemplate(EmailNotificationQuery));
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* sendPartnerInvitationEmail({ payload }) {
  try {
    const {
      data: { sendPartnerInvitation }
    } = yield call(
      graphQlEmailNotificationMutationsApi.sendPartnerInvitation,
      payload
    );
    if (sendPartnerInvitation) {
      yield put(showSuccessMessage("Email was sent successfully"));
    }
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}
