import React from 'react';
import { Field, useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import EmailInput from './EmailInput';
import { PasswordInput } from '../../../components/PasswordInput';
import UserNameInput from './UserName';
import TextInput from '../../../components/TextInput';
import { filterField, filterFieldsByKey, validatePasswordRules } from '../../../utils/generic';
import { validateUserNameRules } from "../../../utils/generic";
import { getIsFormInvalid } from '../../../api/users';
import { usernameIsInUseMsg } from '../../../utils/userUtils';

const useStyles = makeStyles(() => ({
  marginBottom10: {
    marginBottom: 10,
  },
  marginTop20: {
    marginTop: 20,
  },
  childrenActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  clubCodeTopText: {
    marginTop: 30,
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 12,
  },
  clubCode: {
    marginTop: 10,
  },
}));

const validNameRegex = /^[ ',-\.A-Za-z\xC0-\xCF\xD1-\xD6\xD8-\xDD\xDF-\xE5\xE7-\xF6\xF8-\xFD\xFF\u0104-\u0107\u010C\u010D\u0116-\u0119\u012E\u012F\u0141-\u0144\u0152\u0160\u0161\u016A\u016B\u0172\u0173\u0178-\u017E\u2202\u0900\u0901\u0902\u0903\u0904\u0905\u0906\u0907\u0908\u0909\u090a\u090b\u090c\u090d\u090e\u090f\u0910\u0911\u0912\u0913\u0914\u0915\u0916\u0917\u0918\u0919\u091a\u091b\u091c\u091d\u091e\u091f\u0920\u0921\u0922\u0923\u0924\u0925\u0926\u0927\u0928\u0929\u092a\u092b\u092c\u092d\u092e\u092f\u0930\u0931\u0932\u0933\u0934\u0935\u0936\u0937\u0938\u0939\u093a\u093b\u093c\u093d\u093e\u093f\u0940\u0941\u0942\u0943\u0944\u0945\u0946\u0947\u0948\u0949\u094a\u094b\u094c\u094d\u094e\u094f\u0950\u0951\u0952\u0953\u0954\u0955\u0956\u0957\u0958\u0959\u095a\u095b\u095c\u095d\u095e\u095f\u0960\u0961\u0962\u0963\u0964\u0965\u0966\u0967\u0968\u0969\u096a\u096b\u096c\u096d\u096e\u096f\u0970\u0971\u0972\u0973\u0974\u0975\u0976\u0977\u0978\u0979\u097a\u097b\u097c\u097d\u097e\u097f]+$/;

const UserInfoStep = ({ signUpContent, invitedEmail }) => {
  const classes = useStyles();
  let emailInput = null;

  const { values } = useFormikContext();

  let emailContent = filterField(signUpContent.signupFields, "id", "signupEmail");
  let passwordContent = filterField(signUpContent.signupFields, "id", "newPassword");
  let firstNameContent = filterField(signUpContent.signupFields, "id", "firstName");
  let lastNameContent = filterField(signUpContent.signupFields, "id", "lastName");

  let now = moment();
  let dobDate = moment.parseZone(values.birthDate).format();
  const age = now.diff(dobDate, 'year');

  React.useEffect(() => {
    if (emailInput) {
      emailInput.focus();
    }
  }, []); 

  const validateEmail = value => {
    const validationErrors = filterFieldsByKey(emailContent.validationErrors, "messageCode", "message");

    if (!/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(value.trim())) {
      return validationErrors.existingEmail.replace("target='_blank'", "");
    }

    if (age < 13) return null;

    return getIsFormInvalid({ ...values, email: value })
    .then((result) => {
      return result.data
        // All links from Contentful will be with target='_blank'.
        // For reason of unification we will save this state and remove target on front-end
        ? validationErrors.existingEmail.replace("target='_blank'", "")
        : null;
    });
  };

  const validateUsername = (value) => {
    if (!value || value.length === 0) {
      return 'Required';
    }
    const validationErrors = filterFieldsByKey(emailContent.validationErrors, "messageCode", "message");

    if (age < 13 &&
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.trim())) {
        return getIsFormInvalid({ ...values, email: value })
          .then((result) => {
            return result.data
              // All links from Contentful will be with target='_blank'.
              // For reason of unification we will save this state and remove target on front-end
              ? validationErrors.existingEmail.replace("target='_blank'", "")
              : null;
          });
    }

    const result = validateUserNameRules(value, values);
    if (result) {
      return result;
    }

    return getIsFormInvalid({ ...values, username: value })
      .then((result) => {
        return result.data
          ? usernameIsInUseMsg
          : null;
      });
  };

  const validatePassword = value => {
    const validationErrors = filterFieldsByKey(passwordContent.validationErrors, "messageCode", "message");
    if (!value || value.length == 0){
      return validationErrors.requiredField;
    }

    const validateResult = validatePasswordRules(value, values.email, values.username, values.firstName);
    if (Object.keys(validateResult).length > 0) {
      return validationErrors.weakPassword;
    }
  }

  const validateName = value => {
    const validationErrors = filterFieldsByKey(firstNameContent.validationErrors, "messageCode", "message");
    if (!value || value.length == 0){
      return validationErrors.requiredField;
    }
    if (!validNameRegex.test(value)){
      return validationErrors.invalidCharacters;
    }
  }

  return (
    <React.Fragment>
       {age >= 13 && <Field
        innerRef={(i) => { emailInput = i }}
        name="email"
        component={EmailInput}
        label={age >= 13 ? emailContent.labelPrompt : 'Parent\'s email'}
        helperText={age >= 13 ? emailContent.helpText : 'Please enter a parent or guardian email'}
        validate={validateEmail}
        disabled={invitedEmail ? true : false}
        required
      />}
      {age < 13 && <Field
        name="username"
        component={TextInput}
        label="Username"
        required
        helperText="Use letters and numbers"
        className={classes.marginTop20}
        validate={validateUsername}
        errorIsHtml={true}
      />}
      <Field
        name="password"
        component={PasswordInput}
        label={passwordContent.labelPrompt}
        className={classes.marginTop20}
        helperText={passwordContent.helpText}
        validate={validatePassword}
        content={passwordContent}
        required
      />
      <Field
        name="firstName"
        component={UserNameInput}
        isFirstName={true}
        label={firstNameContent.labelPrompt}
        className={classes.marginTop20}
        validate={validateName}
        required
      />
      <Field
        name="lastName"
        component={UserNameInput}
        isFirstName={false}
        label={lastNameContent.labelPrompt}
        className={classes.marginTop20}
        validate={validateName}
        required
      />
    </React.Fragment>
  );
};

export default UserInfoStep;
