import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import * as colors from "../../../theme/colors";
import TextCustomButton from "../../../components/text-button/text-button";
import CustomButton from "../../../components/customButton/customButton";
import { PageLeaveModal } from "../../../components/modals/pageLeaveWarning"

import { allowedImageTypes, allowedTmageTypesMsg, imgTooltipLabel } from "../../../constants/fileConstants";
import { Box, Typography, Avatar, Tooltip } from "@material-ui/core/";

const useStyles = makeStyles({
  paperMainBox: {
    boxShadow: "0px 1px 4px 0px rgba(0,0,0,0.2)",
  },
  container: {
    height: "100%",
    maxWidth: "100%",
    padding: 25,
    margin: "auto",
    "& .ReactCrop__image": {
      display: "flex",
      justifyContent: "center",
    },
  },
  userPick: {
    width: 200,
    height: 200,
    margin: "auto",
    borderRadius: "50%",
    background: colors.grey300,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  initialsLabel: {
    color: colors.darkThemeBlueGray,
    fontSize: 80,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  errorMsg: {
    color: colors.redA400,
    fontSize: 12,
  },
  saveBtn: {
    marginLeft: 10,
  },
});


const AvatarCrop = ({ user, updateUserAvatarImage, onAvatarChange }) => {
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const fileInputRef = React.createRef();
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 1 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [file, setFile] = useState(null);
  const [fileErrorMsg, setFileErrorMsg] = useState(null);
  const [isEscapeConfirmDialog, setEscapeConfirmDialog] = React.useState(false);

  const classes = useStyles();

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const fileUploaded = e.target.files[0];
      const isValidType = allowedImageTypes.find((e) => e === fileUploaded.type);

      const maxSize = 5240000; // 5mb
      if (e.target.files[0].size > maxSize) {
        alert("Sorry, your file is too large. It should be less than 5 Mb.");
        return;
      }

      if (isValidType) {
        setFile(fileUploaded);
        const reader = new FileReader();
        reader.addEventListener("load", () => setUpImg(reader.result));
        reader.readAsDataURL(fileUploaded);
        setFileErrorMsg(null);
      } else {
        setFileErrorMsg(allowedTmageTypesMsg);
      }
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const generateDownload = (canvas, crop) => {
    if (!crop || !canvas) {
      return;
    }

    try {
      canvas.toBlob((blob) => {
        try {
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var base64data = reader.result;

            urltoFile(base64data, file.name, file.type).then(function (file) {
              const formData = new FormData();
              formData.append("image", file);

              axios({
                method: "POST",
                url: `${window._env_.REACT_APP_API_URL}/api/files/upload-avatar-image`,
                data: formData,
              }).then(({ data: { Location, key } }) => {
                updateUserAvatarImage({ avatarUrl: Location, avatarName: key });
                setFile(null);
              });
            });
          };
        } catch (e) {
          return;
        }
      });
    } catch (e) {
      return;
    }
  };

  const urltoFile = (url, filename, mimeType) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  const handleChipClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (onAvatarChange) {
      onAvatarChange(file);
    }
  }, [file, onAvatarChange]);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  const userInitials = user.lastName
    ? `${user.firstName.slice(0, 1)}${user.lastName.slice(0, 1)}`
    : `${user.firstName.slice(0, 1)}`;
  return (
    <Box className={classes.container}>
      {!file && (
        <Tooltip title={imgTooltipLabel} placement="bottom">
          <Box>
            <Box className={classes.userPick}>
              {/* {uploading ? <CircularProgress size={20} /> : props.children} */}
              {user.avatarName ? (
                <Avatar
                  style={{ width: 200, height: 200 }}
                  src={user.avatarUrl}
                  alt="User Avatar"
                />
              ) : (
                <Box className={classes.uploadBox}>
                  <Typography className={classes.initialsLabel}>
                    {userInitials}
                  </Typography>
                </Box>
              )}
              <input
                ref={fileInputRef}
                style={{ display: "none" }}
                type="file"
                accept={allowedImageTypes.join(', ')}
                onChange={onSelectFile}
              />
            </Box>
            <TextCustomButton
              isArrowWave={false}
              style={{ margin: "auto", display: "flex" }}
              onClick={handleChipClick}
              label="Select photo"
            />
          </Box>
        </Tooltip>
      )}
      {!!fileErrorMsg && (
        <Typography className={classes.errorMsg}>{fileErrorMsg}</Typography>
      )}
      {file && (
        <React.Fragment>
          <Box>
            <Box>
              <ReactCrop
                src={upImg}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={(c) => setCompletedCrop(c)}
                circularCrop={true}
              />
              <div style={{ display: "none" }}>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    width: Math.round(completedCrop?.width ?? 0),
                    height: Math.round(completedCrop?.height ?? 0),
                  }}
                />
              </div>
            </Box>
            <Box>
              <CustomButton
                mode="secondary"
                onClick={() => setEscapeConfirmDialog(true)}
                label="Сancel"
              />
              <CustomButton
                mode="primary"
                type="submit"
                className={classes.saveBtn}
                disabled={!completedCrop?.width || !completedCrop?.height}
                onClick={() => {
                  if (!completedCrop?.width || !completedCrop?.height) {
                    return;
                  }
                  generateDownload(previewCanvasRef.current, completedCrop);
                }}
                label="Save"
              />
            </Box>
          </Box>
        </React.Fragment>
      )}
      <PageLeaveModal
        onConfirm={() => { setEscapeConfirmDialog(false); setFile(null); }}
        onCancel={() => { setEscapeConfirmDialog(false); }}
        open={isEscapeConfirmDialog}
      />
    </Box>
  );
};

export default AvatarCrop;
