import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Card from "@material-ui/core/Card";
import { CardContent, Typography, Box, Link } from "@material-ui/core/";
import * as colors from "../../../theme/colors";
import { ColorTheme } from '../../../utils/colorTheme';
import EventBusyOutlinedIcon from "@material-ui/icons/EventBusyOutlined";

import { days, monthNames } from "../../../utils/generic";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    border: "1px solid #7A7A78",
    color: colors.darkThemeBlueGray,
    display: "block",
  },
  details: {
    width: "100%",
  },
  gradientBox: {
    border: "1px solid #7A7A78",
  },
  cover: {
    minWidth: 180,
    textAlign: "center",
    display: "inline-flex",
    width: "100%",
    borderBottom: "1px solid #7A7A78",
  },
  timeWrapper: {
    display: "flex",
  },

  dateSection: {
    minWidth: 75,
    display: "flex",
    alignItems: "baseline",
  },
  dateLabel: {
    lineHeight: 1.5,
    marginLeft: 10,
    marginRight: 10,
    fontSize: 36,
    fontFamily: "Roboto Mono",
    fontWeight: "bold",
  },
  dayInfoSection: {
    minWidth: "fit-content",
    marginBottom: 5,
    display: "none",
    alignItems: "center",
    marginLeft: 15,
  },
  contentBox: {
    cursor: "pointer",
    display: "block",
    alignItems: "center",
    textAlign: "center",
  },
  timeContainer: {
    marginRight: 30,
    fontWeight: "bold",
    width: 185,
    fontSize: 17,
  },
  dayLabel: {
    fontSize: 17,
    fontFamily: "Roboto Mono",
    marginLeft: 5,
    fontWeight: "bold",
  },
  monthYearLabel: {
    fontFamily: "Roboto Mono",
    fontSize: 17,
    marginLeft: 10,
    lineHeight: 1,
    fontWeight: "bold",
    width: "fit-content",
  },
  monthLabel: {
    display: "flex",
    marginLeft: 5,
  },
  contentLabel: {
    fontSize: 18,
    textAlign: "initial",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  },
  boxContent: {
    display: "flex",
    height: "fit-content",
  },
  stubBox: {
    width: "250px !important",

    display: "block",
  },
  itemSpace: {
    marginTop: 10,
  },
  stubItem: {
    display: "flex",
    alignItems: "center",
    textAlign: "inherit",
    padding: 10
  },
  link: {
    display: "inline-block",
    verticalAlign: "middle",
    maxWidth: 315,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: colors.darkThemeGreen,
    fontSize: 16,
    textTransform: "initial",
    lineHeight: 1.75,
    fontWeight: "bold",
    marginTop: 10,
    "&:hover": {
      textDecoration: "none",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: 200,
    },
  },
  eventTag: {
    fontFamily: "'Roboto Mono', monospace",
    width: 70,
    textAlign: "center",
    marginLeft: 5,
    borderRadius: 11,
    fontSize: 12,
    padding: 4,
    fontWeight: 500
  },
  optionalTag: {
    background: colors.greyLight2,
  },
  publicTag: {
    background: ColorTheme.teal1.b,
  },
  partnerTag: {
    background: ColorTheme.orange1.b,
  },
  contentWrapper: {
    justifyContent: "space-between",
    width: "100%",
  },
  timerBox: {
    display: "flex",
    alignItems: "center",
    margin: "auto 0px 15px 0px",
  },
  multiIcon: {
    marginRight: 10,
  },
}));

const ColorLabel = withStyles({
  root: {
    height: 18,
    width: 18,
    borderRadius: 2,
    marginRight: 10,
    background: (props) => props.color,
    backgroundColor: (props) => props.color,
  },
})((props) => <Typography {...props} />);

const DaySection = ({
  item,
  programs,
  programColors,
  selectedDate,
  isStubMode,
  labels,
}) => {
  const classes = useStyles();

  const renderCardHeader = (item) => {
    const date = item
      ? new Date(`${item.date}T00:00:00`)
      : new Date(selectedDate);

    return (
      <Box className={classes.cover}>
        <Box className={classes.timeWrapper}>
          <Box className={classes.dateSection}>
            <b className={classes.dateLabel}>{date.getDate()}</b>
            <b className={classes.dayLabel}>{days[date.getDay()]}</b>
            <b className={classes.monthLabel}>{`${
              monthNames[date.getMonth()]
            }, ${date.getFullYear()}`}</b>
          </Box>
        </Box>

        <Box className={classes.dayInfoSection}>
          <Typography className={classes.monthYearLabel}>{`${
            monthNames[date.getMonth()]
          }, ${date.getFullYear()}`}</Typography>
        </Box>
      </Box>
    );
  };

  const getItemColor = (item, programs, programColors) => {
    if (!programs || !programs.length) {
      return;
    }

    const currentProgram = programs.find(
      (e) => e.id === item.programs[0].programId
    );
    const programIndex = programs.indexOf(currentProgram);
    const color =
      programIndex >= programColors.length
        ? programColors[programIndex % programColors.length]
        : programColors[programIndex];
    return color;
  };

  const renderCardContent = (item, index, programs, programColors) => {
    const eventTime = `${item.localStartTime} to ${item.localEndTime}`;
    const isLinear = item. prepopulatedByProgramType || !item.programs.length || item.programs.length > 1;
    const itemColor = isLinear
      ? null
      : getItemColor(item, programs, programColors);
    const multiIcon =
      labels && labels.multipleProgramEventIcon
        ? labels.multipleProgramEventIcon
        : null;

    return (
      <Box
        key={index}
        className={
          index > 0
            ? clsx(classes.contentBox, classes.itemSpace)
            : classes.contentBox
        }
      >
        <Box className={classes.timerBox}>
          {isLinear && !!multiIcon && (
            <img
              className={classes.multiIcon}
              alt={multiIcon.alt}
              src={multiIcon.src}
            />
          )}
          {!isLinear && (
            <ColorLabel
              color={itemColor}
              className={isLinear ? classes.gradientBox : null}
            />
          )}
          <Typography
            className={classes.timeContainer}
            component="h5"
            variant="h5"
          >
            {eventTime}
          </Typography>
        </Box>
        <Box className={classes.contentWrapper}>
          <Box className={classes.boxContent}>
            <Typography
              className={classes.contentLabel}
              component="h5"
              variant="h5"
            >
              {item.name}
            </Typography>
            <Box className={classes.boxContent}>
              {item && item.isOptional && (
                <div className={clsx(classes.eventTag, classes.optionalTag)}>Optional</div>
              )}
              {item && item.isPartner && (
                <div className={clsx(classes.eventTag, classes.partnerTag)}>Partner</div>
              )}
              {item && item.isPublic && (
                <div className={clsx(classes.eventTag, classes.publicTag)}>Public</div>
              )}
            </Box>
          </Box>

          <Box className={classes.boxContent}>
            {item && item.url && (
              <Link
                className={classes.link}
                target="_blank"
                color="secondary"
                href={item.url}
              >
                {item.url}
              </Link>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  const renderStubCardContent = () => {
    return (
      <Box className={classes.stubItem}>
        <EventBusyOutlinedIcon
          style={{
            marginRight: 15,
            color: colors.deepOrange900,
            height: 32,
            width: 32,
          }}
        />
        <Box className={clsx(classes.contentWrapper, classes.stubBox)}>
          <Box>
            <Typography style={{ fontSize: 17 }}>
              No upcoming events scheduled.
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const sortedItems =
    item && item.items.length
      ? item.items.sort(
          (a, b) =>
            a.startDate.localeCompare(b.startDate) ||
            a.startDate.localeCompare(b.startDate)
        )
      : [];

  return (
    <Card className={classes.root}>
      {!isStubMode && renderCardHeader(item)}
      <div className={classes.details}>
      {isStubMode 
      ? renderStubCardContent()
      : (<CardContent>
          {item &&
            sortedItems.map((e, index) => {
              return renderCardContent(e, index, programs, programColors);
            })}
        </CardContent>)
      }
      </div>
    </Card>
  );
};

export default DaySection;
