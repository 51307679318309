import client from "../../../apolloClient";
import { gql } from "apollo-boost";

import enrollmentForm from '../types/EnrollmentForm';

export function enrollmentFormQuery() {
  return client
    .query({
      query: gql`{
        EnrollmentFormQuery {
          ${enrollmentForm}
        }
      }`
    });
}


export function GetCollegeMajors() {
  return client
    .query({
      query: gql`
      {
        SearchCollegeMajor(searchkey: ""){
          id
          name
        }
      }`
    });
}
