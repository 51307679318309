import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { DialogContent, Typography } from "@material-ui/core/";
import { HQModal } from "../HQModal";
import CustomButton from "../../../components/customButton/customButton";

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: 22,
    fontWeight: "bold",
    margin: "25px auto 15px",
    maxWidth: 330,
    textAlign: "center"
  },
  buttonGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none",
    fontSize: 16,
  },
  submitButton: {
    margin: "auto"
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 16,
    color: theme.palette.grey[500]
  }
}));

export const ConfirmationReportModal = ({ isOpen, onSave, onClose }) => {
  const classes = useStyles();

  return (
    <HQModal title="Report Confirmation" isOpen={isOpen} onClose={onClose}>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
          {`Do you want to proceed with comment reporting?`}
        </Typography>

        <div className={classes.buttonGroup}>
          <CustomButton
            className={classes.submitButton}
            mode="primary"
            type="submit"
            onClick={onSave}
            label={"Confirm"}
          />
        </div>
      </DialogContent>
    </HQModal>
  );
};
