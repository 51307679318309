import React from "react";
import moment from 'moment';

import Roles from "./permissions";

const calculateUserAge = (user) => !user?.birthDate
    ? -1
    : moment().diff(moment(user.birthDate), 'years');

export const userHasValidBirthday = (user) => calculateUserAge(user) >= 0;

export const userIsAllowApplyOrAddChild = (user) => {
  return !userHasValidBirthday(user) && user.roles.some((role) => role.roleId === Roles.Viewer || role.roleId === Roles.CommunityPartner);
}

export const userIsAdult = (user) => {
  if (!user?.birthDate) return;
  const userAge = moment().diff(moment(user.birthDate), 'years');

  return userAge >= 18;
};

export const userIsNotAStudent = (user) => {
  const studentRoles = [Roles.Student, Roles.SipStudent, Roles.LoopParticipant];
  //check that user has other NON-STUDENT roles
  return user.roles.some(({ roleId }) => !studentRoles.includes(roleId));
}

export const userIs13orOlder = (user) => {
  if (!user?.birthDate) return;
  const userAge = moment().diff(moment(user.birthDate), 'years');

  return userAge >= 13;
};

export const validNameRegex = /^[ ',-\.A-Za-z\xC0-\xCF\xD1-\xD6\xD8-\xDD\xDF-\xE5\xE7-\xF6\xF8-\xFD\xFF\u0104-\u0107\u010C\u010D\u0116-\u0119\u012E\u012F\u0141-\u0144\u0152\u0160\u0161\u016A\u016B\u0172\u0173\u0178-\u017E\u2202\u0900\u0901\u0902\u0903\u0904\u0905\u0906\u0907\u0908\u0909\u090a\u090b\u090c\u090d\u090e\u090f\u0910\u0911\u0912\u0913\u0914\u0915\u0916\u0917\u0918\u0919\u091a\u091b\u091c\u091d\u091e\u091f\u0920\u0921\u0922\u0923\u0924\u0925\u0926\u0927\u0928\u0929\u092a\u092b\u092c\u092d\u092e\u092f\u0930\u0931\u0932\u0933\u0934\u0935\u0936\u0937\u0938\u0939\u093a\u093b\u093c\u093d\u093e\u093f\u0940\u0941\u0942\u0943\u0944\u0945\u0946\u0947\u0948\u0949\u094a\u094b\u094c\u094d\u094e\u094f\u0950\u0951\u0952\u0953\u0954\u0955\u0956\u0957\u0958\u0959\u095a\u095b\u095c\u095d\u095e\u095f\u0960\u0961\u0962\u0963\u0964\u0965\u0966\u0967\u0968\u0969\u096a\u096b\u096c\u096d\u096e\u096f\u0970\u0971\u0972\u0973\u0974\u0975\u0976\u0977\u0978\u0979\u097a\u097b\u097c\u097d\u097e\u097f]+$/;

export const isEmailValid = (value) => {
  if (
    !/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(
      value.trim()
    )
  ) {
    return false;
  }
  return true;
};

export const usernameIsInUseMsg = 'Username already in use';
export const formInavlidMsg = 'There is an issue with the email you have entered';
export const formInavlidTooltipMsg =
  <>
    <span>
      There is an issue with the email you have entered. Here are a few reasons why this might have happened:
    </span>
    <ul>
      <li>
        Email is already registered: If you already have an account, you can log in instead.
      </li>
      <li>
        Invalid email format: Double-check that you've entered your email correctly. Sometimes even the smallest typo can cause issues.
      </li>
    </ul>
  </>;

/**
 * Returns true if user has roles
 * @param {Object} user User object
 * @param {number[]} roles User roles
 * @returns {boolean}
 */
export const userHasRoles = (user, roles = []) =>  user?.roles.some((role) => role?.programId && role?.program && roles?.includes(role.roleId));