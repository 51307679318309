import { call, put } from 'redux-saga/effects';
import { push } from "connected-react-router";

import { signup } from '../api/index';
import { receiveAuthData, loadCurrentUser } from '../actions/auth';
import { receivedCheckClubByCode } from '../actions/signUp';

import { createUsageTrackingEvent } from '../actions/tracking';
import { SIGNUP_ACCOUNT_CREATION_SUCCESS, SIGNUP_ACCOUNT_CREATION_ERROR } from '../constants/trackingEvents';
import { showErrorMessage } from '../actions/notification';

import { userIsAdult } from "../utils/userUtils";
import * as globalApi from '../api/global';
import * as programsApi from "../api/programs";
import { checkIsClubsDomain } from '../utils/generic';

const isClubsDomain = checkIsClubsDomain();

export function* register({ payload }) {
  try {
    let { data } = yield call(signup, payload);

    const { token, refreshToken } = data;
    yield put(receiveAuthData({ token, refreshToken }));
    yield put(createUsageTrackingEvent({ action: SIGNUP_ACCOUNT_CREATION_SUCCESS, eventTime: 0 }));
    const { role, birthDate, clubCode, country, invitationCode, qrClubCode } = payload;
    yield put(loadCurrentUser({ clubCode, birthDate, role, country, isParent: payload.isParent }));

    let { data: {
      hqConfigControl,
    } } = yield call(globalApi.getGlobalInfo, payload);

    if (!clubCode && !invitationCode && !qrClubCode
      && userIsAdult(payload)
      && country === hqConfigControl.domesticCountryId && isClubsDomain) {
        const child = payload.children?.length ? payload.children[0] : {};
        const isChildExist = child?.birthDate && child?.firstName && child?.lastName;
        if (!isChildExist && !payload.isParent) {
          return yield put(push(`/gwc-club-application`));
        }
        return yield put(push(`/home`));
    }
  } catch (error) {
    yield put(showErrorMessage(error.response.data.error));
    // TODO: throw generic error
    console.log('TODO: handle error here');
    yield call(createUsageTrackingEvent, { action: SIGNUP_ACCOUNT_CREATION_ERROR, eventTime: 0 });
    console.log(error);
  }
}


export function* checkClubByCode({ payload }) {
  try {
    let {
      data
    } = yield call(programsApi.checkProgramByCode, payload);

    yield put(receivedCheckClubByCode(data))
  } catch (e) {
    yield put(showErrorMessage(e.message));
    console.log("Error in checking club by code:");
    console.log(e);
  }
}

export function* cleanCheckClubByCode() {
  try {
    yield put(receivedCheckClubByCode(null))
  } catch (e) {
    yield put(showErrorMessage(e.message));
    console.log("Error in clearing checking club result by code:");
    console.log(e);
  }
}
