import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import LockIcon from '@material-ui/icons/HttpsOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/VisibilityOutlined';
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined';
import * as colors from '../theme/colors';
import { StyledFormHelper } from '../components/loginSignup/customFormHelper';
import { createMarkup, validatePasswordRules } from '../utils/generic';

const useStyles = makeStyles({
  root: {
    borderRadius: 2,
  },
  error: {
    color: colors.redA400,
    "& > p": {
      marginBottom: 0
    }
  }
});

const customStyles = makeStyles(theme => ({
  passwordHint: {
    background: '#01426a',
    borderRadius: 4,
    color: colors.white,
    fontSize: 12,
    padding: 20,
    left: '0',
    position: 'relative',
    top: 0,
    width: "auto",

    [theme.breakpoints.up('md')]: {
      "&:not(.modal-view)": {
        left: '100%',
        position: 'absolute',
        top: '-45%',
        width: 230,

        "&::before": {
          borderBottom: '20px solid transparent',
          borderRight: '15px solid #01426a',
          borderTop: '20px solid transparent',
          content: "''",
          height: '2px',
          left: '-14px',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)'
        }
      }
    }
  },
  passwordShouldBe: {
    fontWeight: 'bold',
    marginBbottom: 25
  },
  passwordRule: {
    lineHeight: "22px",
    paddingLeft: 35,

    "&::before": {
      border: "1px solid " + colors.grey200,
      borderRadius: "50%",
      content: "''",
      height: 20,
      left: 25,
      position: "absolute",
      width: 20
    },

    "&.validRule": {
      color: colors.tealA200,

      "&::before": {
        borderColor: colors.tealA200,
        background: colors.tealA200,
        content: "''"
      }
    }
  },
  blockRelative: {
    position: "relative"
  }
}));

export const PasswordInput = ({ field, form, label, helperText, content, fromModal, email, username, firstName,
   permamentShowPassword, ...props }) => {
  const classes = useStyles();
  const customClasses = customStyles();
  const [showPassword, setShowPassword] = useState(false || permamentShowPassword);
  const [showHint, setShowHint] = useState(false);

  let error = form.errors[field.name];
  let isTouched = form.touched[field.name];

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  
  const emailValue = email || form.values.email || "";
  const usernameValue = username || form.values.username || "";
  const firstNameValue = firstName || form.values.firstName || "";

  let tooltipMessages;
  let validateResult;
  
  if (content && content.tooltipMessages) {
    tooltipMessages = content.tooltipMessages.reduce((acc, message) => ({
      ...acc,
      [message.messageCode]: message.message,
    }), {});
    validateResult = validatePasswordRules(field.value, emailValue, usernameValue, firstNameValue);
  }

  const errorMessageId = `error-message-${field.name} ${tooltipMessages ? "passwordShouldBe minValue differentThanUserData isCommonPassword" : ""}`

  const passwordChanged = (value) => {
    field.onChange(value);
    if (content && content.tooltipMessages) {
      const validatePasswordResult = validatePasswordRules(value.target.value, emailValue, usernameValue, firstNameValue);
      setShowHint(Object.keys(validatePasswordResult).length > 0);
    }
  }

  return (
    <React.Fragment>
      <div className={customClasses.blockRelative}>
      <TextField
        {...props}
        fullWidth
        label={<span aria-hidden="true">{label}</span>}
        inputProps={{
          maxLength: 100,
        }}
        name={field.name}
        error={!!error && isTouched}
        onChange={passwordChanged}
        type={showPassword ? 'text' : 'password'}
        variant="outlined"
        value={field.value}
        InputProps={{
          classes,
          endAdornment: (
            <InputAdornment position="end">
              {!permamentShowPassword && <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>}
            </InputAdornment>
          ),
          placeholder: label,
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
          "aria-describedby": error ? errorMessageId : null,
        }}
      />
      {error && isTouched
      ? (<StyledFormHelper errorMessageId={errorMessageId} helperText={error} isError={true} isHtml={true} />)
      : (<StyledFormHelper errorMessageId={errorMessageId} helperText={helperText} isHtml={true} />)}
      
      {showHint && tooltipMessages && 
        <div aria-hidden className={customClasses.passwordHint + (fromModal ? " modal-view" : "")}>
          <div id="passwordShouldBe" className={customClasses.passwordShouldBe} dangerouslySetInnerHTML={createMarkup(tooltipMessages.passwordShouldBe)}></div>
          <div>
            <div className={customClasses.passwordRule + (validateResult["minValue"] ? "" : " validRule")}>
              <div id="minValue" className="minValue" dangerouslySetInnerHTML={createMarkup(tooltipMessages.atLeast7Characters)}></div>
            </div>
            <div className={customClasses.passwordRule + (validateResult["differentThanUserData"] ? "" : " validRule")}>
              <div id="differentThanUserData" className="differentThanUserData" dangerouslySetInnerHTML={createMarkup(tooltipMessages.differentThanUserDataLabel)}></div>
            </div>
            <div className={customClasses.passwordRule + (validateResult["isCommonPassword"] ? "" : " validRule")}>
              <div id="isCommonPassword" className="isCommonPassword" dangerouslySetInnerHTML={createMarkup(tooltipMessages.avoidCommonPasswordsLabel)}></div>
            </div>
          </div>
        </div>
        }
      </div>
    </React.Fragment>
  );
};

const Input = (props) => {
  const classes = useStyles();
  const {
    error,
    label,
    value,
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <TextField
      error={!!error}
      fullWidth
      id="input-with-icon-textfield"
      label={<span aria-hidden="true">{label}</span>}
      helperText={error ? error : null}
      variant="outlined"
      value={value}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        classes,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
        placeholder: label,
        startAdornment: (
          <InputAdornment position="start">
            <LockIcon />
          </InputAdornment>
        ),
      }}
      inputStyle={{ borderRadius: '1px', color: 'blue' }}
    />
  );
};

export default Input;
