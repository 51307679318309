import { createAction } from 'redux-act';

export const getCommunityPartnership = createAction();
export const receivedCommunityPartnership = createAction();
export const receivedCommunityPartnershipStatistic = createAction();

export const previewEmail = createAction();
export const receiveEmailPreviewTemplate = createAction();

export const sendPartnerInvitationEmail = createAction();
