import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import * as colors from "../../theme/colors";
import { ColorTheme } from "../../utils/colorTheme";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography"
import ClearIcon from '@material-ui/icons/Clear';
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";

import MultipleSelect from "../../components/multiple-select/multipleSelect";
import SearchInput from "../../components/search-input/searchInput";
import CancelIcon from '@material-ui/icons/Cancel';
import Divider from '@material-ui/core/Divider';
import { Paper } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    width: '100%',
    padding: 10,
    marginTop: 15,
  },
  filtersBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  },
  selectBox: {
    display: "inline-flex",
    alignItems: "center",
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  },
  divider: {
    margin: '10px 0px',
  },
  chip: {
    maxWidth: 180,
    margin: 5,
    fontSize: 14,
    color: colors.dartThemeBlueGray,
    backgroundColor: colors.teal,
  },
  clearAllBtn: {
    color: colors.darkThemeGreen,
    fontFamily: "'Roboto',monospace",
    fontWeight: "bold",
    fontSize: 16,
    marginLeft: 'auto',
    minWidth: 120
  },
  selectorItem: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      margin: '10px 0px',
      width: '100%',
      "& >div": {
        width: '100%',
      },
    }
  },
  searchInput: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    }
  },
  emptyMsgContainer: {
    marginTop: 15,
  },
  emptyMsgBox: {
    display: "flex",
  },
  emptyMsgLabelBox: {
    display: 'block',
    margin: 'auto',
    marginLeft: 25,
    marginRight: 10,
    padding: 10,
  },
  emptyResultImage: {
    padding: 20,
  },
  emptyMsgLabel: {
    fontSize: 18,
    fontWeight: 600,
    color: ColorTheme.black4.b
  },
  emptyMsgAdditionalLabel: {
    fontSize: 16,
    color: ColorTheme.black4.b,
    marginTop: 15,
  },
  cancelIcon: {
    color: colors.lightThemeGreen,
  },
  filtersLabel: {
    color: colors.lightGrey,
  }
}));

export const Filters = ({
  items,
  handleFilterChange,
  filters,
  onSearchInput,
  onReset,
  clearAllFilters,
  studentView,
}) => {
  const classes = styles();

  useEffect(() => {
    clearAllFilters();
  }, [studentView]);

  const renderCheckboxFilter = (label, filterName, attribute, index) => {
    if (!filters[filterName].includes(attribute)) {
      return null;
    }

    return (
      <Chip
        key={index}
        className={classes.chip}
        clickable={true}
        label={label}
        size="medium"
        deleteIcon={<CancelIcon className={classes.cancelIcon} />}
        onDelete={handleFilterChange(filterName, attribute)}
      />
    );
  };

  const keys = Object.getOwnPropertyNames(filters);
  const isAnyFilterSelected = !!keys.filter(key => !!filters[key].length).length;

  return (
    <Paper className={classes.root}>
      <Box className={classes.filtersBox}>
        <Box className={classes.selectBox}>
          <Typography className={classes.filtersLabel}>Filter by:</Typography>
          {items && items.setType && !!items.setType.length &&
            <MultipleSelect
              label={"Type"}
              values={items.setType}
              filters={filters}
              onSelectChange={handleFilterChange}
              className={classes.selectorItem}
            />}
          {items && items.estimatedTimeInMinutes && !!items.estimatedTimeInMinutes.length &&
            <MultipleSelect
              label={"Time"}
              values={items.estimatedTimeInMinutes}
              filters={filters}
              onSelectChange={handleFilterChange}
              className={classes.selectorItem}
            />}
          {items && items.easeOfFacilitation && !!items.easeOfFacilitation.length && !studentView &&
            <MultipleSelect
              label={"Ease of Facilitation"}
              values={items.easeOfFacilitation}
              filters={filters}
              onSelectChange={handleFilterChange}
              className={classes.selectorItem}
            />}
        </Box>
        <Box>
          <SearchInput
            label={"Search"}
            value={filters.searchKey}
            onReset={onReset}
            values={items.searchKeys}
            onChange={onSearchInput}
            className={classes.searchInput}
          />
        </Box>
      </Box>
      {isAnyFilterSelected && <Divider className={classes.divider} />}
      <Box className={classes.filtersBox}>
        <Box>
          {keys.map((k, i) =>
            Array.isArray(filters[k]) &&
            filters[k].map((e, j) => {
              const currentItem = items && items[k] && items[k].find((i) => i.attribute == e);

              if (!currentItem || items[k].length == 0) {
                return null;
              }
              return renderCheckboxFilter(currentItem.label, k, e, `${j}${i}`);
            })
          )}
        </Box>
        {isAnyFilterSelected && <Box>
          <Button
            className={classes.clearAllBtn}
            startIcon={<ClearIcon />}
            onClick={() => {
              clearAllFilters();
            }}
          >
            Clear All
          </Button>
        </Box>}
      </Box>
    </Paper>
  );
};


export const EmptyFilterSection = ({ curriculumLabels }) => {
  const classes = styles();

  return (
    <Paper className={classes.emptyMsgContainer}>
      <Box className={classes.emptyMsgBox}>
        <Box>
          <img
            className={classes.emptyResultImage}
            alt="empty result"
            src={curriculumLabels && curriculumLabels.filterNoResultsCta.icon.src}></img>
        </Box>
        <Box className={classes.emptyMsgLabelBox}>
          <Typography className={classes.emptyMsgLabel}>{curriculumLabels.filterNoResultsLabel}</Typography>
          <Typography className={classes.emptyMsgAdditionalLabel}>{curriculumLabels.filterNoResultsAdditionalLabel}</Typography>
        </Box>
      </Box>
    </Paper>
  );
}