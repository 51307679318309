import React, { useEffect, useState } from "react";
import { push as pushToRoute } from "connected-react-router";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";

import { connectTo, isSchoolClub } from "../../utils/generic";
import {
  removeInvitation,
} from "../../api/graphql/mutation/emailNotification.mutation";
import { sendUserInvitation } from "../../api/userInvitations";
import * as programsApi from "../../api/programs";
import * as userRolesApi from "../../api/userRoles";
import * as authActions from "../../actions/auth";
import * as contentfulActions from "../../actions/contentful";
import * as membersActions from '../../actions/members';
import * as notificationActions from '../../actions/notification';
import * as userProfileActions from "../../actions/userProfile";
import * as facilitatorsConsentAgreementActions from "../../actions/facilitatorsConsentAgreement";

import { createBouncedUsersNotificationText } from "../../containers/NotificationService/createBouncedUsersNotificationText";
import { darkThemeGreen } from '../../theme/colors'

import NavBar from "../HorizontalNavigation";
import Footer from "../../containers/Footer/footer";
import MembersBlock from "./Members";
import InvitationsBlock from "./Invitations";
import { InviteMembersModal } from "../../components/modals/members/inviteMembersModal";
import members from "../../reducers/members";

import { useTitle } from "../../utils/pageTitles";
import Roles from "../../utils/permissions";
import * as colors from "../../theme/colors";

const useStyles = makeStyles((theme) => ({
  alert: {
    backgroundColor: darkThemeGreen,
  },
  root: {
    display: "block",
  },
  main: {
    margin: "auto auto",
    height: "100%",
    minHeight: 'calc(100vh - 132px)',
    paddingBottom: 15,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    paddingTop: 48,
    [theme.breakpoints.down('md')]: {
      paddingTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 100,
    },
  },
  divider: {
    margin: "20px 20px",
    [theme.breakpoints.up("md")]: {
      margin: "40px 25px",
    },
  },
  successAlert: {
    backgroundColor: colors.darkThemeGreen,
    color: "#fff",
  },
  errorAlert: {
    backgroundColor: colors.red3Theme,
    color: "#fff",
  },
}));

const Members = (props) => {
  useTitle("members");

  const classes = useStyles();
  const {
    auth,
    changeRole,
    contentful: {
      hqConfig: { defaultProgram },
      GlobalComponentQuery,
    },
    changeUserPassword,
    getInvitations,
    getMembers,
    getRecruitmentResourceLink,
    deleteUserRoleByProgram,
    createUsersGroups,
    updateUsersGroup,
    deleteUsersGroup,
    getUsersGroups,
    showErrorMessage,
    showSuccessMessage,
    match: {
      params: { clubCode },
    },
    members: {
      invitations,
      loadingInvitations,
      loadingMembers,
      members: clubMembers,
      recruitmentResourceLink,
      loadingRecruitmentResourceLink,
      isCreatingMembersGroup,
      usersGroups,
      loadingMembersGroup,
    },
    push,
    userProfile: { profileContent },
    loadProfileContent,
    setFacilitatorsConsentAgreement,
    facilitatorsConsentAgreement: { isLoadingFacilitatorsConsentAgreement, facilitatorConsent },
  } = props;
  const { currentRole, user } = auth;

  const [rolePermissions, setRolePermissions] = React.useState({});
  const [inviteModalOpened, setInviteModalOpened] = React.useState(null);

  const [programs, setPrograms] = useState([]);
  const [programMembers, setProgramMembers] = useState([]);
  const [isLoadingProgramMembers, setIsLoadingProgramMembers] = useState(false);

  const [invitationsFiltered, setInvitationsFiltered] = useState([]);
  const [membersLabels, setMembersLabels] = React.useState(null);
  const [facilitatorConsentSet, setFacilitatorConsentSet] = useState(false);
  const [programTypeDetails, setProgramTypeDetails] = useState(null);

  useEffect(() => {
    if (!user) return;

    const { roles } = user;

    if (!clubCode) {
      push("/home");
      return;
    }

    const selectedRole = roles.find((r) => {
      return r.program && r.program.code === clubCode;
    });

    if (!selectedRole) {
      push("/home");
      return;
    }

    const permissions = currentRole.permissions ?? {};
    setRolePermissions(permissions);

    const defaultProgramId = parseInt(defaultProgram);
    const userPrograms = user.roles.filter(
      (role) =>
        role.roleId === Roles.Facilitator &&
        role.programId && 
        [defaultProgramId, selectedRole.programId].indexOf(role.programId) === -1 && 
        !!role.program
    ).map((e) => e.program)
      .map((el) => {
        return {
          id: el.id,
          label: el.name,
        };
      });

    setPrograms(userPrograms);

    // if it's not a facilitator or summer teacher or viewer
    // or if it's a default club
    if (
      !selectedRole ||
      !permissions.viewMembers ||
      selectedRole.programId === parseInt(defaultProgram, 10)
    ) {
      push("/gwc"); // redirect home
      return;
    }

    if (currentRole.programId !== selectedRole.programId) {
      changeRole({ programId: selectedRole.programId });
    }

    getMembers(selectedRole.programId);
    if (permissions.viewInvitations) {
      getInvitations(selectedRole.programId);
    }

    if (permissions.viewMembers) {
      getUsersGroups({ programId: selectedRole.programId });
    }

    if (!membersLabels && GlobalComponentQuery) {
      const {
        membersPageLabels
      } = GlobalComponentQuery;
      setMembersLabels(membersPageLabels);

      loadProfileContent();
    }

    const currentProgramTypeDetails = currentRole.program?.programTypeDetails;
    setProgramTypeDetails(currentProgramTypeDetails);
  }, [user]);

  useEffect(() => {
    setInvitationsFiltered(!!invitations 
      ? invitations.filter(invitation => !invitation.facilitatorInvitation) 
      : []);
  }, [invitations]);

  useEffect(() => {
    if (!!currentRole && !!currentRole.programId && !!members && members.length === 0 && currentRole?.roleId === Roles.Facilitator) {
      getRecruitmentResourceLink(currentRole.programId);
    }
  }, [members]);

  const sendInvite = ({ facilitatorFullName, emails, facilitatorInvitation }) => {
    return sendUserInvitation({
      facilitatorFullName,
      emails,
      programId: currentRole.program.id,
      facilitatorInvitation,
    })
      .then(({ data }) => {
        if (data?.bouncedUsers && data.bouncedUsers.length > 0) {
          showErrorNotificationForBouncedUsers(data.bouncedUsers);
          return;
        }
        showSuccessMessage({ text: "Your invitations were sent." })
      })
      .catch(() => {
        showErrorMessage({ text: "Your invitations weren't sent." })
      })
  };

  const afterModalSubmit = () => {
    setTimeout(() => {
      getInvitations(parseInt(currentRole.program.id));
    }, 1000);
  }

  const onCloseModal = () => {
    setInviteModalOpened(false);
  }

  /* eslint-disable */
  const loadProgramMembers = (programIds, eventId) => {
    setIsLoadingProgramMembers(true);
    Promise.all(programIds.map((id) => {
      return programsApi
        .getProgramMembers({ id: parseInt(id) });
    })).then(values => {
      const programMembersList = { ...programMembers };

      // show members have not participated already in current club;
      values.map(e => {
        if (!!e.data && !!e.data.length) {
          programMembersList[e.data[0].programId] = e.data.filter(x => !clubMembers.find(y => y.userId === x.userId));
        }
      });
      setProgramMembers(programMembersList);
      setIsLoadingProgramMembers(false);
    })
      .catch(error => {
        setIsLoadingProgramMembers(false);
      });
  };

  const removeUserInvitation = (id) => {
    removeInvitation(parseInt(id, 10)).then(() => {
      setTimeout(() => {
        getInvitations(parseInt(currentRole.program.id));
      }, 1000);
    });
  };

  const showErrorNotificationForBouncedUsers = (bouncedUsers) => {
    const errorMessage = createBouncedUsersNotificationText(bouncedUsers);
    showErrorMessage({ text: errorMessage, options: { persist: true } });
  }

  const removeUser = (userId) => {
    deleteUserRoleByProgram({programId: currentRole.programId, userId})
  };

  const changePassword = (values) => {
    const data = {
      programId: currentRole.programId,
      ...values,
    };

    changeUserPassword(data);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <NavBar />
      <main className={classes.main}>
        { currentRole && <MembersBlock
          rolePermissions={rolePermissions}
          members={clubMembers}
          recruitmentResourceLink={recruitmentResourceLink}
          isLoading={loadingMembers || loadingRecruitmentResourceLink}
          removeUser={removeUser}
          onChangePassword={changePassword}
          profileContent={profileContent}
          user={user}
          onInviteMembersClick={() => {
            setInviteModalOpened(true);
          }}
          createUsersGroups={createUsersGroups}
          updateUsersGroup={updateUsersGroup}
          deleteUsersGroup={deleteUsersGroup}
          isCreatingMembersGroup={isCreatingMembersGroup}
          programId={currentRole.programId}
          usersGroups={usersGroups}
          membersLabels={membersLabels}
          getUsersGroups={getUsersGroups}
          loadingMembersGroup={loadingMembersGroup}
          programTypeDetails={programTypeDetails}
        />}

        {rolePermissions.viewInvitations && invitationsFiltered.length > 0 && (
          <>
            <Divider className={classes.divider} />
            <InvitationsBlock
              invitations={invitationsFiltered}
              isLoading={loadingInvitations}
              resendInvite={(invite) => {
                if (!invite) return;
                const { email, facilitatorFullName } = invite;
                sendInvite({
                  emails: [email.trim().toLowerCase()],
                  programId: currentRole.program.id,
                  facilitatorInvitation: false,
                  facilitatorFullName: facilitatorFullName,
                });
              }}
              removeInvite={removeUserInvitation}
            />
          </>
        )}
      </main>
      <Footer />
      {inviteModalOpened && (
        <InviteMembersModal
          isOpen={inviteModalOpened}
          clubCode={clubCode}
          role={currentRole}
          user={user}
          programs={programs}
          currentClubMembers={clubMembers}
          loadProgramMembers={loadProgramMembers}
          programMembers={programMembers}
          isLoadingProgramMembers={isLoadingProgramMembers}
          anonymousUsers={currentRole && currentRole.program ? currentRole.program.anonymousUser : false}
          isLoadingFacilitatorsConsentAgreement={isLoadingFacilitatorsConsentAgreement}
          onCloseModal={onCloseModal}
          afterModalSubmit={afterModalSubmit}
          sendUserInvitation={sendInvite}
          createBulkUserRolesWithEnroll={userRolesApi.createBulkUserRolesWithEnroll}
          createAnonymousUsersWithEnroll={userRolesApi.createAnonymousUsersWithEnroll}
          showErrorNotificationForBouncedUsers={showErrorNotificationForBouncedUsers}
          getMembers={getMembers}
          getInvitations={getInvitations}
          membersLabels={membersLabels}
          profileContent={profileContent}
          getUsersGroups={getUsersGroups}
          setFacilitatorsConsentAgreement={setFacilitatorsConsentAgreement}
          setFacilitatorConsentSet={setFacilitatorConsentSet}
          programTypeDetails={programTypeDetails}
          showFacilitatorsConsentModal={
            currentRole && currentRole.program
              ? isSchoolClub(currentRole.program.clubType) &&
                !currentRole.program.hasFacilitatorConsent && !facilitatorConsentSet &&
                (!facilitatorConsent[currentRole.programId] || !facilitatorConsent[currentRole.programId].consented)
              : false
          } />
      )}
    </div>
  );
};

export default connectTo(
  (state) => ({
    curriculum: state.curriculum,
    auth: state.auth,
    contentful: state.contentful,
    members: state.members,
    userProfile: state.userProfile,
    facilitatorsConsentAgreement: state.facilitatorsConsentAgreement,
  }),
  {
    ...authActions,
    ...contentfulActions,
    ...notificationActions,
    ...userProfileActions,
    ...membersActions,
    ...facilitatorsConsentAgreementActions,
    push: pushToRoute,
  },
  Members
);
