import React from "react";
import clsx from 'clsx';

import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckIcon from '@material-ui/icons/Check';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Typography from "@material-ui/core/Typography";
import * as colors from "../../theme/colors";
import { getAcademicYear } from "../../utils/generic";

const useStyles = makeStyles((theme) => ({
  container: {
    width: 'auto',
    margin: "0 10px",
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  labelButton: {
    backgroundColor: "transparent",
    color: colors.dartThemeBlueGray,
    fontWeight: 500,
    fontSize: 14,
    display: "inline-flex",
    fontFamily: "'Roboto Mono',monospace",
    border: `1px solid #b8b8b8`,
    "&:hover": {
      border: `1px solid #43d6b8`,
      color: colors.darkThemeGreen,
    },
    width: "max-content",
  },
  icon: {
    color: colors.darkThemeGreen,
  },
  checkedIcon: {
    color: colors.darkThemeGreen,
  },
  disabledLabelButton: {
    borderRadius: 2,
    display: "inline-flex",
    fontFamily: "'Roboto Mono',monospace",
    border: `1px solid #b8b8b8`,
    height: 34,
    width: 'fit-content',
    padding: '4px 20px',
    fontSize: 14,
    fontWeight: 500,
  },
  labelActive: {
    border: `1px solid ${colors.greenA350}`,
    color: colors.darkThemeGreen,
  },
  labels: {
    backgroundColor: "white",
    fontFamily: "'Roboto Mono',monospace",
    "& .MuiTypography-body1": {
      color: colors.dartThemeBlueGray,
      fontFamily: "'Roboto Mono',monospace",
      fontSize: 14,
      fontWeight: 500,
    },
  },
  paperContainer: {
    top: '4px',
    zIndex: 11,
    backgroundColor: "#fff",
    borderRadius: 16,
    border: "1px solid #bbbbbf",
    alignItems: "center",
    "& div": {
      borderRadius: "inherit",
      padding: 1,
    },
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const CustomSelector = ({ value, options, onChange, showAcademicYearFormat }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleMenuItemClick = (event, index) => {
    onChange(options[index].name);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const isSingleValue = options.length < 2 || !options.length;
  const icon = open ? <ExpandLessIcon className={classes.icon} /> : <ExpandMoreIcon className={classes.icon} />;
  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        {isSingleValue ? (
          <Typography className={classes.disabledLabelButton}>
            {showAcademicYearFormat ? getAcademicYear(value) : value}
          </Typography>
        ) : (
          <React.Fragment>
              <Button
                className={open ? clsx(classes.labelActive, classes.labelButton) : classes.labelButton}
                ref={anchorRef}
                size="small"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                disabled={isSingleValue}
                onClick={handleToggle}
              >
                {showAcademicYearFormat ? getAcademicYear(value) : value}
                {icon}
              </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              className={classes.paperContainer}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu">
                        {options.map((option, index) => (
                          <MenuItem
                            key={option.name}
                            selected={option.name === value}
                            onClick={(event) =>
                              handleMenuItemClick(event, index)
                            }
                            className={classes.labels}
                          >
                             <ListItemText primary={showAcademicYearFormat ?  option.label : option.name} />
                            {option.name === value && <CheckIcon className={classes.checkedIcon} />}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
};

export default CustomSelector;
