import React, { useState } from "react";
import clsx from 'clsx';

import {
  Box,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core/";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import HqAvatar from '../../../components/avatar/avatar';
import * as colors from "../../../theme/colors";
import CustomButton from "../../../components/customButton/customButton";
import TextCustomButton from "../../../components/text-button/text-button";
import { AnswerCard } from "./answerCards/answerCard";
import Collapse from "@material-ui/core/Collapse";
import { createMarkup } from "../../../utils/generic";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import AnswerStatusDetails from "../../../components/AnswerStatusDetails";
import {renderCheckboxes} from "../../../utils/programHelper";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: '0px 24px 16px',
    minWidth: 480,
    [theme.breakpoints.down('sm')]: {
      minWidth: 350,
      marginBottom: 20,
    },
    "& *": {
      maxWidth: '100%',
    },
    '& ul': {
      "padding-left": 40,
    },
    '& li': {
      lineHeight: 1.8,
      paddingLeft: 10,
      "&::marker": {
        color: colors.darkThemeGreen,
      }
    },
    '& details': {
      backgroundColor: colors.white,
      borderRadius: 5,
      border: '1px solid',
      borderColor: colors.darkThemeGreen,
      margin: '10px 0',
      padding: 10,

      '&:focus-within': {
        border: '2px solid',
        borderColor: colors.black,
        padding: 9
      },

      '& > summary': {
        outline: 'none',
        cursor: 'pointer',
        color: colors.darkThemeGreen,
        fontWeight: 700
      }
    },
    '& a': {
      color: colors.darkThemeGreen,
      lineHeight: 1.8,
      fontWeight: 700,
      textTransform: "initial",
    },
  },
  content: {
    [theme.breakpoints.down('xs')]: {
      width: "100%",
    },
  },
  formWrapper: {
    display: 'flex',
  },
  title: {
    fontWeight: "bold",
    fontSize: 24,
  },
  titleLabel: {
    fontSize: 18,
    color: colors.darkThemeBlueGray,
    marginBottom: 15,
    fontWeight: "bold",
    wordBreak: 'break-word',
    display: "flex",
    alignItems: "baseline",
  },
  buttonGroup: {
    marginTop: 30,
    height: 42,
    width: "100%",
    justifyContent: "right",
    display: "flex",
    boxShadow: "none",
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      display: "block",
      marginTop: 10,
    },
  },
  cancelBtn: {
    marginRight: 10,
  },
  completeBtn: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 8,
    },
  },
  cardHeaderContainerWrapper: {
    alignItems: "normal",
  },
  divider: {
    marginBottom: 20,
  },
  lowerDivider: {
    marginTop: 20,
  },
  cardHeaderContainer: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  createdAtLabel: {
    fontSize: 12,
    color: colors.lightGrey,
  },
  createdAtLabelAnswer: {
    paddingTop: 10,
  },
  progressIcon: {
    marginLeft: 10,
  },
  gradingNotRequiredText: {
    fontWeight: "bold",
  },
  viewOlderBtn: {
    marginLeft: 'auto',
  },
  questionContent: {
    color: colors.darkThemeBlueGray,
    fontSize: 14,
    width: "100%",
  },
  completedBox: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    color: "#083861",
  },
}));

const TeacherViewAnswerCard = ({ user, students, answers, headerContent, isLoading, addComment, updateComment, updateAnswerGroupComment, updateStatus, deleteComment, requiredGrading, setViewUserKey, answersToReviewToRender, question: questionData, history }) => {
  const classes = useStyles();
  const [addCommentMode, setAddCommentMode] = useState({});
  const [showOlderAnswers, setShowOlderAnswers] = useState(false);

  const {
    id,
    programId,
    userId,
    createdAt,
    path,
    question,
    status,
    statusChangedAt,
    statusChanger,
  } = answers[0];
  const [currentQuestionDescription, setCurrentQuestionDescription] = React.useState({});

  const student = students.find(e => e.userId === userId);

  const index = answersToReviewToRender.findIndex((e) => e.userId === userId);
  const nextUserId = answersToReviewToRender.length - 1 > index ? answersToReviewToRender[index + 1].userId : null;
  const itemKey = `${path}-${question}`;
  const showActionButtons = Object.values(addCommentMode).filter(e => !!e).length;
  const name = student ? [student.firstName, student.lastName].filter(e => !!e).join(" ") : "";

  React.useEffect(() => {
    // add event and styles to dynamic render checkbox
    renderCheckboxes();
  }, [currentQuestionDescription]);

  const toggleViewOlder = () => setShowOlderAnswers(showOlderAnswers => !showOlderAnswers);
  const questionContent = questionData?.contentSection;
  return (
    <Box>
      <CardHeader className={classes.cardHeaderContainerWrapper}
        avatar={
          <HqAvatar
            user={student}
            src={student.avatarName && student.avatarUrl}
          />
        }
        title={
          <Box className={classes.cardHeaderContainer}>
            <Box className={classes.formWrapper}>
                <Typography variant="h2" className={classes.title}>
                  {name}
                </Typography>
                {isLoading && <CircularProgress className={classes.progressIcon} size={24} />}
            </Box>
            <Box className={classes.formWrapper}>
              <Typography className={classes.createdAtLabelAnswer}>
                <AnswerStatusDetails
                    createdAt={createdAt}
                    status={status}
                    statusChangedAt={statusChangedAt}
                    statusChanger={statusChanger}
                />
              </Typography>
            {answers.length > 1 && <TextCustomButton className={classes.viewOlderBtn} label={showOlderAnswers ? 'Hide older' : 'View older'} onClick={toggleViewOlder}/>}
            </Box>
          </Box>
        }
      />
      
      <CardContent className={classes.dialogContent}>
        <Divider className={classes.divider} />
        <Box className={classes.content}>
          <Box className={classes.titleLabel}>
            {headerContent}
            <Box className={classes.completedBox}>
            <TextCustomButton
              onClick={() => {
                currentQuestionDescription[questionContent?.id] = currentQuestionDescription[questionContent?.id] === undefined ||
                !currentQuestionDescription[questionContent?.id];
                setCurrentQuestionDescription({ ...currentQuestionDescription})
              }}
              label={`${currentQuestionDescription[questionContent?.id] ? "Hide Question Description" : "View Question Description"}`.toUpperCase()}
              endIcon={currentQuestionDescription[questionContent?.id] ? <ExpandLess /> : <ExpandMore />}
              sel="view-completed"
            />
          </Box>
          </Box>

          <Collapse 
            in={currentQuestionDescription[questionContent?.id]} 
            timeout="auto" unmountOnExit>
            <Box
              className={classes.questionContent}
              dangerouslySetInnerHTML={createMarkup(questionContent?.content)}
            />
          </Collapse>
          <Box>
            <Grid container>
              {showOlderAnswers ? answers && answers.map((answer, index) => <AnswerCard
                key={answer.id}
                user={user}
                answer={answer}
                addComment={addComment}
                deleteComment={deleteComment}
                addCommentToAnswer={addCommentMode}
                updateComment={updateComment}
                updateAnswerGroupComment={updateAnswerGroupComment}
                setAddCommentToAnswer={(el) => setAddCommentMode({...addCommentMode, [el.id]: el.value })}
                headerContent={headerContent}
                isLast={index + 1 === answers.length}
                showDate={true}
                history={history}
              />) :
              <AnswerCard
                user={user}
                answer={answers[0]}
                addComment={addComment}
                deleteComment={deleteComment}
                addCommentToAnswer={addCommentMode}
                updateComment={updateComment}
                updateAnswerGroupComment={updateAnswerGroupComment}
                setAddCommentToAnswer={(el) => setAddCommentMode({...addCommentMode, [el.id]: el.value })}
                headerContent={headerContent}
                isLast={true}
                history={history}
              />}
            </Grid>
          </Box>
        </Box>

        {!showActionButtons && <>
          <Divider className={clsx(classes.divider, classes.lowerDivider)} />
          <div className={classes.buttonGroup}>
            {requiredGrading ? (
                <Box>
                  <CustomButton
                    className={classes.cancelBtn}
                    mode="textButton"
                    onClick={() => {
                      updateStatus({
                        id,
                        programId,
                        status: false,
                        questionName: headerContent,
                        itemKey,
                      });
                      setViewUserKey(nextUserId);
                    }}
                    label='Mark Incomplete'
                  />
                  <CustomButton
                    mode="primary"
                    className={classes.completeBtn}
                    type="submit"
                    onClick={() => {
                      updateStatus({
                        id,
                        programId,
                        status: true,
                        questionName: headerContent,
                        itemKey,
                      });
                      setViewUserKey(nextUserId);
                    }}
                    label='Mark Complete'
                  />
                </Box>
              ) : (
                <Typography className={classes.gradingNotRequiredText} variant="body1">Grading not required</Typography>
              )}
          </div>
        </>}
      </CardContent>
    </Box>
  );
};

export default TeacherViewAnswerCard;
