import { createReducer } from 'redux-act';
import * as a from '../actions/global';

const getDefaultState = () => ({
  currentPrograms: null,
  leftMenuVisible: false,
  initialLoadersCount: 0,
  programs: [],
  isMobileView: false,
  isMobile: false,
  staticPageContent: null,
  studentView: false,
});

export default () =>
  createReducer({
    [a.setMenuVisibility]: (state) => ({
      ...state,
    }),
    [a.incrementInitialLoaders]: (state) => ({
      ...state,
      initialLoadersCount: state.initialLoadersCount + 1,
    }),
    [a.decrementInitialLoaders]: (state) => ({
      ...state,
      initialLoadersCount: state.initialLoadersCount === 0 ? 0 : state.initialLoadersCount - 1,
    }),
    [a.switchMobileMenu]: (state, data) => {
      const custom = data.hasOwnProperty('leftMenuVisible');
      return {
        ...state,
        leftMenuVisible: data && custom ? data.leftMenuVisible : !state.leftMenuVisible,
      };
    },
    [a.setIsMobile]: (state, isMobile) => {
      return {
        ...state,
        isMobile
      };
    },
    [a.setMenuMode]: (state, data) => {
      const { isMobileView, leftMenuVisible } = data;
      return {
        ...state,
        isMobileView,
        leftMenuVisible
      };
    },
    [a.closeMobileMenu]: (state) => ({
      ...state,
      leftMenuVisible: false,
    }),
    [a.setDefaultState]: () => {
      return getDefaultState();
    },
    [a.receivedStaticPagesContent]: (state, content) => ({
      ...state,
      staticPageContent: content
    }),
    [a.setStudentView]: (state, studentView) => {
      return {
        ...state,
        studentView
      };
    },  
  },
    getDefaultState());
