import { createAction } from 'redux-act';

export const getPosts = createAction();
export const receivedPosts = createAction();

export const getSinglePost = createAction();
export const receivedSinglePost = createAction();

export const createPost = createAction();
export const receivedPost = createAction();

export const updatePost = createAction();
export const receivedUpdatedPost = createAction();

export const reportMessage = createAction();

export const createComment = createAction();
export const receivedNewComment = createAction();

export const updateComment = createAction();

export const deleteMessage = createAction();
export const receivedDeletedPost = createAction();
export const receivedDeletedComment = createAction();

export const onError = createAction();
