import React from "react";

import { Box, Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from '@material-ui/lab/Skeleton';

import { ColorTheme } from "../../../utils/colorTheme";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginBottom: 8,
    padding: 10,
    boxShadow: 'none',
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  cover: {
    minWidth: 170,
    display: "block",
    padding: 10,
    boxShadow: `1px 0 0 0 ${ColorTheme.black1.b}`,
    [theme.breakpoints.down("md")]: {
      display: "inline-flex",
      width: "100%",
      borderBottom: `1px solid ${ColorTheme.black2.b}`,
      boxShadow: 'none'
    },
  },
  timeWrapper: {
    minWidth: 75,
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: '100%'
    }
  },
  contentBox: {
    display: "flex",
    marginLeft: 10,
    padding: 10,
    [theme.breakpoints.down("md")]: {
      display: "block",
      marginLeft: 0
    },
    '&:last-child': {
      padding: 10
    }
  },
  timeContainer: {
    marginRight: 30,
    width: 140,
    [theme.breakpoints.down("md")]: {
      width: '100%'
    },
  },
  dayOfWeekLabel: {
    width: 100
  },
  fullDateLabel: {
    width: 150
  },
  timerBox: {
    display: "flex",
    alignItems: "start",
  },
  icon: {
    marginRight: 10,
    marginTop: 2,
    width: 20,
    height: 20,
    transform: 'none'
  },
  contentWrapper: {
    display: 'block',
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginLeft: 28
    }
  },
  contentLabel: {
    width: 500,
    [theme.breakpoints.down("md")]: {
      width: 150
    },
  },
  link: {
    width: 300,
  },
}));

const SkeletonCalendar = (props) => {
  const {count} = props;
  const classes = useStyles();

  return (
    <>
    {[...Array(count)].map((e, i) => (
    <Card className={classes.root} key={i}>
      <Box className={classes.cover}>
        <Box className={classes.timeWrapper}>
          <Skeleton className={classes.dayOfWeekLabel} />
          <Skeleton className={classes.fullDateLabel} />
        </Box>
      </Box>  
      <CardContent className={classes.contentBox}>
        <Box className={classes.timerBox}>
          <Skeleton className={classes.icon} />
          <Skeleton className={classes.timeContainer} />
        </Box>
        <Box className={classes.contentWrapper}>
          <Skeleton className={classes.contentLabel} />
          <Skeleton className={classes.link} />
        </Box>
      </CardContent>
    </Card>
    ))}
    </>
  );
};

export default SkeletonCalendar;
