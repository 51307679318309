import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { DialogContent, DialogActions, DialogContentText, Box, Typography } from "@material-ui/core/";
import { HQModal } from "../HQModal";
import * as colors from "../../../theme/colors";
import CustomButton from "../../../components/customButton/customButton";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: 30,
  },
  contentText: {
    color: theme.palette.primary.main,
    fontSize: 16,
    textAlign: "left",
    maxWidth: 400,
  },
  buttonGroup: {
    padding: "0 30px 30px",
    justifyContent: "right",
    display: "flex",
    fontSize: 16,
    boxShadow: "none"
  },
  csItemLabel: {
    color: colors.darkThemeGreen,
    fontSize: 16,
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      color: colors.lightThemeGreen,
    },
    marginTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
  },
}));

const UnfinishedSectionsModal = ({ isOpen, onClose, sections, push, node, onSectionClick }) => {
  const classes = useStyles();

  return (
    <HQModal title="You're almost there!" isOpen={isOpen} onClose={onClose}>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={classes.contentText}>
          You still need to finish the following parts in order to complete this activity:
        </DialogContentText>
        <Box>{sections.map((section, index) => {
          const csTitle = section.__typename === "Surveys" ? "Survey" : (section.heading || section.header || `Section ${index + 1}`);
          const isStudentCs = !section.onlyVisibleToFacilitators;
          return (<Typography
            className={classes.csItemLabel}
            onClick={() => {
              const sectionIndex = node.contentSections.findIndex(el => el.id === section.id)
              onSectionClick(sectionIndex);
              push(`/gwc${node.path}#${section.id}`, { enforcePresenter: isStudentCs });
              onClose();
            }}>{csTitle}</Typography>)
        })}</Box>
      </DialogContent>
      <DialogActions className={classes.buttonGroup}>
        <CustomButton
          mode="primary"
          label="Close"
          onClick={onClose}
          sel="confirmClose"
        />
      </DialogActions>
    </HQModal>
  );
};

export default UnfinishedSectionsModal;