import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';

import { HQModal } from "../HQModal";
import DialogContent from "@material-ui/core/DialogContent";

import * as colors from "../../../theme/colors";
import CustomButton from "../../../components/customButton/customButton";

const useStyles = makeStyles({
  marginTop30: {
    marginLeft: 5,
    marginRight: 5,
  },
  buttonGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    justifyContent: "center",
    display: "flex",
    boxShadow: "none",
  },
  button: {
    borderRadius: 5,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: colors.grey400,
    color: "white",
    "&:hover": {
      backgroundColor: colors.grey500,
    },
  },
  submitButton: {
    borderRadius: 5,
    backgroundColor: colors.tealA700,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: colors.teal500,
    },
  },
  fieldWrapper: {
    display: "flex",
    alignItems: "center",
    width: '45%',
  },
});

export const TimerModal = ({ isOpen, onSave, onCloseModal }) => {
  const classes = useStyles();
  const [selectedDate, handleDateChange] = useState(new Date(2020, 4, 4, 0, 5, 0));
  const [dateError, setDateError] = useState(null);

  return (
    <HQModal title="Set timer" isOpen={isOpen} onClose={() => { handleDateChange(new Date(2020, 4, 4, 0, 5, 0)); onCloseModal(); }}>
      <DialogContent className={classes.dialogContent}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardTimePicker
            views={["minutes", "seconds"]}
            ampm={false}
            variant="inline"
            inputVariant="outlined"
            label="Minutes and seconds"

            sel="date"
            role={"region"}
            aria-label={"Select a time"}
            KeyboardButtonProps={{
              className: classes.datePickerButton,
              "aria-label": "Select a time",
            }}

            value={selectedDate}
            onChange={handleDateChange}
            // disableToolbar
            format="mm:ss"
            onError={(error) => {
              setDateError(error);
            }}
          />
        </MuiPickersUtilsProvider>

        <div className={classes.buttonGroup}>
          <CustomButton
            mode="primary"
            type="submit"
            label={"Start"}
            aria-label="Start timer"
            onClick={() => {
              if (dateError) return;

              onSave(selectedDate);
              onCloseModal();
              handleDateChange(new Date(2020, 4, 4, 0, 5, 0));
            }}
          />
        </div>
      </DialogContent>
    </HQModal>
  );
};
