import { call, put } from 'redux-saga/effects';

import { getActivity } from '../api/graphql/query/activity.queries';
import { activityLoaded } from '../actions/curriculum';

export function* loadActivity({ payload: { slug, permissionContext, noCache } }) {
  try {
    let { data: { ActivityQuery } } = yield call(getActivity, slug, permissionContext, noCache);
    yield put(activityLoaded({activity: ActivityQuery, roleId: permissionContext.roleId}));
  } catch (e) {
    // TODO: throw generic error
    console.log('TODO: handle error here');
    console.log(e);
  }
}

