import React, { useState } from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import * as colors from "../../../theme/colors";

import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";

import Typography from "@material-ui/core/Typography";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from "@material-ui/core/CircularProgress";

import { createMarkup, checkIsClubsDomain } from "../../../utils/generic";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "8px 8px 12px 8px",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  cardHeader: {
    padding: 0,

    [theme.breakpoints.down("xs")]: {
      wordBreak: "break-all",
    },
    "& .MuiCardHeader-action": {
      margin: 0,
      "align-self": "auto",
    },
  },
  cardContent: {
    padding: "0 0 0 4px",
    "&:last-child": {
      paddingBottom: 0,

      [theme.breakpoints.down("xs")]: {
        wordBreak: "break-all",
      },
    },
  },
  checkBoxContainer: {
    lineHeight: 1.5,
    fontSize: 15,
    color: colors.darkThemeBlueGray,
    wordBreak: 'break-word',
    "& a": {
      textDecoration: "none",
      textTransform: "inherit",
      padding: "0px 1px",
      color: colors.darkThemeGreen,
      fontWeight: "bold",
      "&:hover": {
        textDecoration: "none",
      },
    }

  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
  },
  progressBar: {
    marginLeft: 5,
  }
}));

const GreenCheckbox = withStyles({
  root: {
    color: '#3DB390',
    '&&.Mui-disabled': {
      color: "grey", 
    },
    '&$checked': {
      color: '#3DB390',
    },
  },
  checked: {},
})((props) => <Checkbox {...props} />);

const PreferencesCard = ({
  club,
  labels,
  updatePreferences,
  isUpdatingPreferences,
}) => {
  const classes = useStyles();
  const [statuses, setStatuses] = useState(
    {
      clubIsActive: !!club.clubIsActive,
      openClosedEnrollment: club.openClosedEnrollment === "Open",
      externalFacilitators: !!club.externalFacilitators,
      showProjectsInGallery: !!club.showProjectsInGallery,
    }
  );

  const isClubsDomain = checkIsClubsDomain();

  const handleChange = name => ({ target: { checked } }) => {
    const newStatuses = { ...statuses };
    newStatuses[name] = checked;
    setStatuses(newStatuses);
    updatePreferences({
      id: club.id,
      data: { 
        ...newStatuses, 
        openClosedEnrollment: !!newStatuses.openClosedEnrollment ? "Open" : "Closed" 
      }
    });
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        title={<Box className={classes.titleBox}>
          <Typography className={classes.title}>Preferences</Typography>
          {isUpdatingPreferences && <CircularProgress size={20} className={classes.progressBar} />}
        </Box>}
        className={classes.cardHeader}
      />
      <CardContent className={classes.cardContent}>
        <FormControlLabel
          className={classes.checkBoxContainer}
          control={
            <GreenCheckbox
              checked={statuses["clubIsActive"]}
              value={statuses["clubIsActive"]}
              disabled={isUpdatingPreferences}
              onChange={handleChange("clubIsActive")}
              className={classes.checkboxControl}
            />
          }
          label={<div dangerouslySetInnerHTML={createMarkup(labels.clubPreferencesActiveLabel)} />}
          key={1}
        />
        <FormControlLabel
          className={classes.checkBoxContainer}
          control={
            <GreenCheckbox
              checked={statuses["openClosedEnrollment"]}
              disabled={isUpdatingPreferences}
              onChange={handleChange("openClosedEnrollment")}
              className={classes.checkboxControl}
            />
          }
          label={<div dangerouslySetInnerHTML={createMarkup(labels.clubPreferencesOpenClosedEnrollmentLabel)} />}
          key={2}
        />
        <FormControlLabel
          className={classes.checkBoxContainer}
          control={
            <GreenCheckbox
              checked={statuses["externalFacilitators"]}
              disabled={isUpdatingPreferences}
              onChange={handleChange("externalFacilitators")}
              className={classes.checkboxControl}
            />
          }
          label={<div dangerouslySetInnerHTML={createMarkup(labels.clubPreferencesExternalFacilitatorsLabel)} />}
          key={3}
        />
        {isClubsDomain && <FormControlLabel
          className={classes.checkBoxContainer}
          control={
            <GreenCheckbox
              checked={statuses["showProjectsInGallery"]}
              disabled={isUpdatingPreferences}
              onChange={handleChange("showProjectsInGallery")}
              className={classes.checkboxControl}
            />
          }
          label={<div dangerouslySetInnerHTML={createMarkup(labels.clubPreferencesShowProjectsInGalleryLabel)} />}
          key={4}
        />}
      </CardContent>

    </Card>
  );
};

export default PreferencesCard;
