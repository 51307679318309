import React from 'react';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { getTheme } from "../../../utils/colorTheme";
import * as colors from '../../../theme/colors';
import TextButton from "../../../components/text-button/text-button";
import { TooltipWrapper } from "../../../components/tooltip/tooltip";
import { handleKeyPress } from '../../../utils/accessibility';
import { permissions } from "../../../utils/permissions";
import { calcNodeProgress } from "../curriculumHelper";

const styles = makeStyles({
  buttonBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 15,
    minHeight: 37,
  },
  folderButtonBox: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 5,
    marginLeft: 15,
    marginRight: 15,
  },
  folderCertificate: {
    marginTop: '-10px',
  },
  certificateIcon: {
    height: 30,
  },
  cardTitleContainer: {
    minHeight: 80,
  },
  cardHeader: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  cardName: {
    fontSize: 18,
    fontWeight: 700,
    color: "#013046",
    '&.centerText': {
      textAlign: "center",
    },
  },
  completedItem: {
    width: 25,
    height: 25,
    color: colors.lightThemeGreen,
    position: 'absolute',
    top: 15,
    right: 15,
  },
  folderIconWrapper: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
    marginTop: 20,
    minHeight: 150,
  },
  grid: {
    marginTop: 15,
  },
  paper: {
    boxShadow: "none",
    cursor: 'pointer',
    height: 430,
    position: 'relative',
    '&:hover': {
      transform: 'scale(1.05, 1.05)',
      transition: 'transform 150ms linear'
    },
  },
  folderPaper: {
    boxShadow: "none",
    cursor: 'pointer',
    height: 300,
    position: 'relative',
    '&:hover': {
      transform: 'scale(1.05, 1.05)',
      transition: 'transform 150ms linear'
    },
  },
  folderCardIcon: {
    maxHeight: 150,
    maxWidth: 150
  },
  cardWrapper: {
    color: colors.blueGrey800,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    position: 'relative',
    transition: 'color .5s',
    width: '100%',
  },
  folderContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: 165,
    marginTop: 5,
    fontWeight: 'bold',
    backgroundColor: (prop) => prop.backColor,
    '&.cardWithoutHeader': {
      height: 110,
      marginTop: 0,
      fontWeight: 'bold',
      borderRadius: 5,
    },
    '&.cardFolderNoIcon': {
      height: '100%'
    },
    '&.cardWithPadding': {
      marginTop: 20,
    }
  },
  folderCardName: {
    fontSize: 20,
    margin: 20,
    textAlign: 'left',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    textOverflow: '-o-ellipsis-lastline',
    lineHeight: '1em',
    top: 0,
    left: 0,
    color: (prop) => prop.fontColor,
  },
  label: {
    height: 14,
    fontSize: 10,
    fontFamily: "'Roboto Mono',monospace",
    fontWeight: 500,
    textTransform: "uppercase",
    alignSelf: "center",
    marginRight: "auto",
  },
  certificateWrapper: {
    marginLeft: "auto",
  }
});

const Curriculum = (props) => {
  const {
    node,
    defaultActivityIcon,
    onNodeClick,
    userActivitiesInfo,
    isFacilitator,
    communityPartnershipLink,
    labelBackground,
    labelFontColor,
    programRoleLabel,
    studentView,
    certificateRibbonIcon,
    isAllNodesFacilitatorsOnly,
    openTextAnswers,
    programId,
  } = props;
  let { progress } = props;

  const removeDuplicates = (data) => {
    return data.filter((value, index) => data.indexOf(value) === index);
  };

  const subNodesCount = Object.keys(node.nodes).length;

  const subNodes = Object.values(node.nodes);

  // count progress for collections with nodeType = folder;
  const allNodesProgress = subNodes.map(node => {
    return calcNodeProgress({ node, communityPartnershipLink, userActivitiesInfo, isFacilitator, removeDuplicates, studentView, openTextAnswers, programId });
  })
  const ifAllNestedItemsCompleted = allNodesProgress.filter(e => e === 100).length === subNodesCount;
  if (ifAllNestedItemsCompleted) {
    progress = 100;
  }

  const isAllNestedContentFacilitatorsOnly = isFacilitator && !studentView && !!subNodes.length && !subNodes.some(e => e.permissionLevel !== permissions.facilitatorOnly);
  const folderWithNestedFacilitatorContent = isFacilitator && !studentView && node.__typename === 'CurriculumFolder' && !subNodes.some(node => Object.values(node.nodes).some(e => e.permissionLevel !== permissions.facilitatorOnly));

  const cardHeaderName = node.folderType
    ? node.folderType.name
    : node.collectionType
      ? node.collectionType.name
      : null;

  const nodeTheme = node.folderType
    ? node.folderType.colorTheme
    : node.collectionType
      ? node.collectionType.colorTheme
      : null;

  const endsInCertificate = node.endsInCertificate;
  const cardTheme = getTheme(nodeTheme);
  const cardClasses = styles({ backColor: cardTheme.b, fontColor: cardTheme.f, accentColor: cardTheme.a });

  progress = progress || 0;

  const isFacilitatorOnlyActivity = node.permissionLevel === permissions.facilitatorOnly;
  const tooltipMsg = 'Earn a certificate by completing required activities';

  const renderIconInsideCurriculum = () => {
    const icon = node.icon || node.coverImage || defaultActivityIcon;
    return (<>
      {icon
        ? <Box className={cardClasses.folderIconWrapper}>
          <img src={icon.src} alt={icon.alt} className={cardClasses.folderCardIcon} />
        </Box>
        : ""}
    </>)
  };

  return (
    <Grid key={node.id} item xl={3} lg={4} md={6} sm={6} xs={12}
      className={cardClasses.grid}
    >
      <Paper className={cardClasses.folderPaper}>
        <Box
          tabIndex={0}
          className={cardClasses.cardWrapper}
          sel="activity"
          onClick={() => onNodeClick(node)}
          onKeyPress={handleKeyPress(() => onNodeClick(node, isFacilitatorOnlyActivity ? true : false))}
        >
          <Box>
            <Box onClick={() => onNodeClick(node, isFacilitatorOnlyActivity ? true : false)}>
              {!isFacilitatorOnlyActivity && progress === 100 && subNodesCount !== 0 && <CheckCircleIcon className={cardClasses.completedItem} />}
            </Box>
            {(renderIconInsideCurriculum())}
            <Box>
              <Box className={clsx(
                cardClasses.folderContentWrapper,
                !cardHeaderName ? 'cardWithPadding' : '',
                'cardWithoutHeader',
                node.icon || defaultActivityIcon ? '' : 'cardFolderNoIcon')}>
                <Box className={cardClasses.cardTitleContainer}>
                  <Typography className={clsx(cardClasses.cardName, cardClasses.folderCardName)} variant="h4">{node.name}</Typography>
                </Box>
                <Box className={!endsInCertificate ? cardClasses.folderButtonBox : clsx(cardClasses.folderButtonBox, cardClasses.folderCertificate)}>
                  {(isFacilitatorOnlyActivity || isAllNestedContentFacilitatorsOnly || folderWithNestedFacilitatorContent) && !isAllNodesFacilitatorsOnly && <TextButton
                    className={cardClasses.label}
                    style={{ color: labelFontColor, backgroundColor: labelBackground }}
                    label={programRoleLabel}
                  />}
                  {certificateRibbonIcon && endsInCertificate && <Box className={cardClasses.certificateWrapper}>
                    <TooltipWrapper title={tooltipMsg}>
                      <Box>
                        <img className={cardClasses.certificateIcon} src={certificateRibbonIcon.src} alt={certificateRibbonIcon.alt} />
                      </Box>
                    </TooltipWrapper>
                  </Box>}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

export default Curriculum;
