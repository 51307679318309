import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Button, Box, Typography } from '@material-ui/core';
import { getTheme } from "../../../utils/colorTheme";
import 'highlight.js/styles/paraiso-dark.css';  // import your preferred style
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import * as colors from '../../../theme/colors';

const notesStyle = makeStyles(theme => ({
    showNotesButton: {
      color: (prop) => prop.fontColor,
      fontWeight: 'bold',
      border: (prop) => `1px solid ${prop.fontColor}`,
      borderRadius: 5,
  
      '& .MuiTypography-body1': {
       color: (prop) => prop.fontColor,
      },
  
      [theme.breakpoints.down('xs')]: {
        padding: 8,
        minWidth: 30,
  
        '& .showNotesLabel': {
          display: 'none'
        }
      },
    },
    endIcon: {
      [theme.breakpoints.down('xs')]: {
        margin: 0
      }
    },
    showNotes: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    submitBtn: {
      color: colors.darkThemeBlueGray
    },
  }));

const FacilitatorNotes = (props) => {
    const { toggleFunction, showNotes, colorTheme } = props;
    if (!colorTheme) colorTheme = getTheme(null);
    const notesClasses = notesStyle({fontColor: colorTheme.f});
  
    return (
      <Box className={notesClasses.showNotes}>
        <Button
          disableElevation
          style={{ backgroundColor: 'transparent' }}
          variant="contained"
          size="large"
          className={notesClasses.showNotesButton}
          classes={{endIcon: notesClasses.endIcon}}
          endIcon={showNotes ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          onClick={toggleFunction}
        >
          <Typography className="showNotesLabel">Facilitator Notes</Typography>
        </Button>
      </Box>
    );
  };
  export default FacilitatorNotes;