import client from "../../../apolloClient";
import { gql } from "apollo-boost";
import obj2arg from "graphql-obj2arg";

export function getEmailNotificationByLocale({notificationCode, locale}) {
  const graphQlArguments = {
    notificationCode,
    locale
  };

  return client.query({
    query: gql`{
        EmailNotificationQuery(${obj2arg(graphQlArguments, {
          noOuterBraces: true
        })}) {
          ${getEmailNotificationAttributes()}
        }
      }`
  });
}

const getEmailNotificationAttributes = () => {
  return `
    name
    notificationCode
    emailSubject
    contentTitle
    image {
      src
    }
    plainTextContent
    htmlContent
  `;
};
