import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomButton from "../../components/customButton/customButton";
import EditIcon from '@material-ui/icons/Edit';
import { ColorTheme } from '../../utils/colorTheme';

const useStyles = makeStyles({
  card: {
    width: "100%"
  },
  title: {
    fontSize: "30px",
    marginLeft: "16px"
  },
  gridHeader: {
    justifyContent: "space-between"
  },
  list: {
    width: "100%"
  },
  item: {
    marginTop: "15px",
    marginBottom: "15px"
  },
  editIcon: {
    marginTop: 8
  },
  loader: {
    marginLeft: 10,
    color: ColorTheme.blue4.b
  },
  divider: {
    marginBottom: "15px",
    marginTop: "15px",
    backgroundColor: "black"
  },
  infoSection: {
    display: "flow-root"
  },
  tooltipContainer: {
    marginTop: 15
  },
  editContainer: {
    backgroundColor: "transparent",
    border: "1px solid #565656",
    borderRadius: "50%",
    height: "36px",
    lineHeight: "37px",
    textAlign: "center",
    cursor: "pointer",
    width: "36px",
  },
  profileIcon: {
    height: 35,
    width: 35
  }
});

export const ProfileCard = props => {
  const classes = useStyles();
  const { title, content, icon, isEditable, tooltip, isLoading } = props;
  return (
    <React.Fragment>
      <Card className={classes.card}>
        <CardContent>
          <Grid className={classes.gridHeader} container>
            <div className="personal-info-header">
              {icon &&
                <img
                  className={classes.profileIcon}
                  alt=""
                  src={icon.src}
                />}
              <Typography variant="h5" className={classes.title} component="h1" sel={title}>
                {title}
              </Typography>
              {isLoading && <CircularProgress className={classes.loader} />}
            </div>
            <div className={classes.tooltipContainer}>{tooltip}</div>
            {props.openEditForm && isEditable && (
              <div>
                <CustomButton
                  mode="secondary"
                  label={<EditIcon />}
                  onClick={() => props.openEditForm()}
                  sel={"edit-" + title}
                  aria-label={`edit ${title}`}
                />
              </div>
            )}
          </Grid>

          <Divider className={classes.divider} />
          {content}
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default ProfileCard;
