import client from "../../../apolloClient";
import { gql } from "apollo-boost";
import obj2arg from "graphql-obj2arg";
import { userNextAttributes } from "../query/user.queries";

export function updateUserInfo({ userId, data }) {
  return client.mutate({
    mutation: gql`
      mutation {
        updateUserInfo(userId: "${userId}", userData: ${obj2arg(data)} ){
          ${userNextAttributes}
        }
      }`
  });
}

export function updateUserNextInvitation({ invitationId, invitationToken }) {
  return client.mutate({
    mutation: gql`
    mutation{
      expireUserNextInvitation(invitationId: ${invitationId}, invitationToken: "${invitationToken}")
    }`
  });
}
