import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { DialogContent, Typography, Box, Divider } from "@material-ui/core/";

import { HQModal } from "../HQModal";
import * as colors from "../../../theme/colors";
import CustomButton from "../../../components/customButton/customButton";
import { createMarkup } from "../../../utils/generic";
import './welcomeModal.scss';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: "35px",
    "& p": {
      overflow: "auto",
    },
    "& img": {
      maxWidth: "100%",
    }
  },
  titleLabel: {
    fontSize: 32,
    color: colors.darkThemeBlueGray,
    fontWeight: 700,
  },
  divider: {
    marginTop: 15,
  },
  headerBox: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 22,
    fontWeight: "bold",
    margin: "25px auto 15px",
    maxWidth: 330,
    textAlign: "center",
  },
  buttonGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    justifyContent: "end",
    display: "flex",
    boxShadow: "none",
    fontSize: 16,
  },
  button: {
    borderRadius: 5,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: colors.grey400,
    color: "white",
    "&:hover": {
      backgroundColor: colors.grey500,
    },
  },
  submitButton: {
    borderRadius: 5,
    backgroundColor: colors.tealA700,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: colors.teal500,
    },
  },
  modalContent: {
    fontSize: "16px",
    '& ul': {
      "padding-left": "15px",
    },
    '& li': {
      lineHeight: 1.8,
      paddingLeft: "10px",
      "&::marker": {
        color: colors.darkThemeGreen,
      }
    },
    '& a': {
      color: colors.darkThemeGreen,
      fontWeight: "medium",
      textTransform: "initial",
    },
  }
}));

export const WelcomeModal = ({ isOpen, onClose, item }) => {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);

  const currentItem = item.modalContentSections[currentIndex];

  React.useEffect(() => {
    // add styles to dynamic render checkbox
    const inputsWithDataAttr = document.querySelectorAll("[data-attr]");
    for (let input of inputsWithDataAttr) {
      const attr = input.getAttribute("data-attr");
      const parent = input.parentNode;
        input.setAttribute('checkbox', 'true');
        const checkbox = document.createElement("input");
        checkbox.setAttribute('id', attr);
        checkbox.type = "checkbox";
        const label = document.createElement('label');
        label.setAttribute("for", attr);
        label.innerHTML = input.parentNode.innerHTML.split('checkbox="true">')[1];
        parent.setAttribute("class", 'md-checkbox');

        // Adding attributes for acessibility to checkmark and label;
        label.setAttribute("id", `id=${attr}`);
        checkbox.setAttribute('tabindex', '0');
        checkbox.setAttribute('aria-labelledby', `id=${attr}`);

        parent.textContent = '';
        parent.appendChild(checkbox);
        parent.appendChild(label);
    }
  }, [currentItem]);

  return (
    <HQModal
      title={item.name}
      isOpen={isOpen}
      onClose={() => {
        onClose({ isLastPage: currentIndex + 1 === item.modalContentSections.length });
      }}
      titleOptions={{
        textAlign: "initial",
      }}
      maxWidth={"md"}
    >
      <DialogContent className={classes.dialogContent}>
        <Box>
          <Box className={classes.headerBox}>
            <Typography className={classes.titleLabel}>{currentItem.heading}</Typography>
          </Box>
          <Box className={classes.modalContent} dangerouslySetInnerHTML={createMarkup(currentItem.content)} />
        </Box>
        <Divider className={classes.divider} />
        <div className={classes.buttonGroup}>
          <CustomButton
            mode="primary"
            onClick={() => {
              currentIndex + 1 < item.modalContentSections.length
                ? setCurrentIndex(currentIndex + 1)
                : onClose({ isLastPage: true });
            }}
            label={currentItem.buttonLabel}
          />
        </div>
      </DialogContent>
    </HQModal>
  );
};
