import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import CustomButton from './customButton/customButton';
import { HQModal } from './modals/HQModal';
import * as colors from '../theme/colors';

const useStyles = makeStyles(() => ({
  button: {
    fontWeight: "bold",
  },
  contentTitle: {
    color: colors.darkThemeBlueGray,
  },
  buttonGroup: {
    marginTop: 30,
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none",
    fontSize: 16,
  },
}));

const DeleteConfirmationDialog = ({ title, open, onClose, onConfirm }) => {
  const classes = useStyles();

  return (
    <HQModal title="Delete Confirmation" isOpen={open} onClose={onClose}>
      <DialogTitle className={classes.contentTitle} id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <div className={classes.buttonGroup}>
        <CustomButton
          mode="textButton"
          onClick={onClose}
          label="Cancel"
        />
        <CustomButton
          mode="primary"
          type="submit"
          onClick={onConfirm}
          label="Yes"
        />
      </div>
    </HQModal>
  );
};

export default DeleteConfirmationDialog;
