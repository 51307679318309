import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";

import * as colors from "../../.././theme/colors";

import { createMarkup } from "../../../utils/generic";

const useStyles = makeStyles((theme) => ({
    subTitle: {
        color: colors.darkThemeBlueGray,
        fontSize: 18,
        fontWeight: "bold",
        marginBottom: 10,
        [theme.breakpoints.down("xs")]: {
            padding: "0 16px",
        },
    },
    boxWrapper: {
        marginTop: 20,
    },
    sectionContainer: {
        display: "flex",
        margin: "20px 0px",
        backgroundColor: colors.blueA400,
        color: colors.white,
    },
    sectionBox: {
        width: "30%",
        wordBreak: "break-word",
        margin: 10,
    },
    sectionTitle: {
        color: colors.white,
        fontSize: 12,
        fontWeight: "bold",
    },
    htmlWhiteSection: {
        lineHeight: 1.5,
        fontSize: 12,
        color: colors.white,
        "& a": {
            textDecoration: "none",
            textTransform: "inherit",
            padding: "0px 1px",
            color: colors.darkThemeGreen,
            fontWeight: "bold",
            "&:hover": {
                textDecoration: "none",
            },
        }
    },
    htmlSection: {
        lineHeight: 1.5,
        fontSize: 14,
        color: colors.darkThemeBlueGray,
        "& a": {
            textDecoration: "none",
            textTransform: "inherit",
            padding: "0px 1px",
            color: colors.darkThemeGreen,
            fontWeight: "bold",
            "&:hover": {
                textDecoration: "none",
            },
        }
    },
    thirdStepWrapper: {
        margin: "0 24px 0 24px"
    }
}));

const ThankYouPage = ({ labels }) => {
    const classes = useStyles();

    return (
        <Box className={classes.thirdStepWrapper}>
            <Box>
                <Typography className={classes.subTitle}>
                    {labels.thankYouLabel}
                </Typography>
            </Box>
            <Box className={classes.boxWrapper}>
                <div className={classes.htmlSection} dangerouslySetInnerHTML={createMarkup(labels.thankYouPageMainInfo)} />
            </Box>
            <Box className={classes.sectionContainer}>
                <Box className={classes.sectionBox}>
                    <Typography className={classes.sectionTitle}>SISTERHOOD</Typography>
                    <div className={classes.htmlWhiteSection} dangerouslySetInnerHTML={createMarkup(labels.thankYouPageSisterhoodInfo)} />
                </Box>
                <Box className={classes.sectionBox}>
                    <Typography className={classes.sectionTitle}>CODE</Typography>
                    <div className={classes.htmlWhiteSection} dangerouslySetInnerHTML={createMarkup(labels.thankYouPageCodeInfo)} />
                </Box>
                <Box className={classes.sectionBox}>
                    <Typography className={classes.sectionTitle}>IMPACT</Typography>
                    <div className={classes.htmlWhiteSection} dangerouslySetInnerHTML={createMarkup(labels.thankYouPageImpactInfo)} />
                </Box>
            </Box>
            <Box className={classes.boxWrapper}>
                <Typography className={classes.subTitle}>
                    Next Steps
                </Typography>
            </Box>
            <Box>
                <div className={classes.htmlSection} dangerouslySetInnerHTML={createMarkup(labels.thankYouPageNextStepsInfo)} />
            </Box>

            <Box className={classes.boxWrapper}>
                <div className={classes.htmlSection} dangerouslySetInnerHTML={createMarkup(labels.thankYouPageAdditionalFacilitatorsInfo)} />
            </Box>
            <Box className={classes.boxWrapper}>
                <div className={classes.htmlSection} dangerouslySetInnerHTML={createMarkup(labels.thankYouPageReturningMembersInfo)} />
            </Box>
            <Box className={classes.boxWrapper}>
                <div className={classes.htmlSection} dangerouslySetInnerHTML={createMarkup(labels.thankYouPageAdditionalClubsInfo)} />
            </Box>
        </Box>
    );
};

export default ThankYouPage;
