// components
import cta from './Cta';
import {anyFormField} from './FormFields';
import image from './Image';
import messaging from './Messaging';

// modules
import LoginSignup from './LoginSignup';
import FacilitatorProgressPage from "./FacilitatorProgressPage";

export const activityType = `
  name
  colorTheme
`;

export const changePassword = `
  name
  resetStepHeading
  resetStepInstruction
  resetStepFields {
    ${anyFormField}
  }
  resetStepCta {
    ${cta}
  }
  cancelCta {
    ${cta}
  }
  formMessages {
    ${messaging}
  }
  tokenNotFoundHeading
  tokenNotFoundInstruction
  termsAndConditions
`;

export const contentSection = `
  heading
  subheading
  content
  facilitatorNote
  visibleInPageNavigation
  onlyVisibleToFacilitators
  isOpenTextAnswer
  requiredGrading
  isOptionalTextAnswer
`;

export const contentPageTab = `
  name
  tab {
    ${cta}
  }
  switchLabel
  contentTabContent {
    ...on ContentSection {
      ${contentSection}
    }
  }
  childFriendlyContent {
    ...on ContentSection {
      ${contentSection}
    }
  }
`;

export const surveys = `
  surveyName
  surveyDescription
  surveyUrl
  salesforceId
  hqId
  programId
  userBasicData
  height
  ctaText
  isDomestic
  isEligibleForInternationalApplication
  hideLanguageSelector
`;

export const quizQuestion = `
  name
  header
  description
  isOptionalQuiz
  answers {
    ... on QuizAnswer {
      name
      answerTitle
      answerBody
      hint
      isCorrect
    }
  }
`;

export const program = `
  id
  code
  name
  time
  address
  city
  state
  countryId
  sfCurriculumType
  year
  active
  startDate
  automaticMembershipSync
  isInternational
  programType
  programTypeId
  isDeleted
  facilitators {
    firstName
    lastName
    incognito
  }
`;

export const userProgram = `
  id
  programId
  userId
  childId
  isFacilitator
`;

export const ContentPage = `
  name
  heading
  switchLabel
  contentSections {
    ...on ContentSection {
      ${contentSection}
    }
  }
  childFriendlyContentSections {
    ...on ContentSection {
      ${contentSection}
    }
  }
  tabsWithContent {
    ...on ContentPageTab {
      ${contentPageTab}
    }
  }
`;

export const PrivacyPolicy = `
  name
  contentPage {
    ${ContentPage}
  }
`;

export const ForgotPasswordPage = `
  header
  subheader
  inputLabel
  inputHelperText
  submitButtonText
  headerSucessScreen
  subheaderSuccessScreen
  canNotFindLabel
  goBackLink
  resendButtonText
  notFoundError
  expiredTokenMessage
  expiredTokenIconCta {
    ${cta}
  }
`;

export const ForgotPassword = `
  name
  firstStepHeading
  firstStepInstruction
  firstStepField {
    ${anyFormField}
  }
  firstStepCta {
    ${cta}
  }
  secondStepHeading
  secondStepInstruction
  secondStepAdditionalInstruction
  secondStepAdditionalCta {
    ${cta}
  }
  secondStepCta {
    ${cta}
  }
  formMessages {
    ${messaging}
  }
  multipleEmailsStepHeading
  multipleEmailsStepInstruction
  multipleEmailsStepCta {
    ${cta}
  }`;

export const Curriculum = `
  id
  name
  code
`;

export const CurriculumNavigation = `
  code
  content {
    ... on Collection {
          id
          name
          invisibleCollection
          endsInCertificate
          program
          permissionLevel
          slug
     }
    ... on CurriculumFolder {
          id
          name
          slug
          permissionLevel
          folderType {
            name
            colorTheme
          }
          coverImage {
            ${image}
          }
          content {
            id
            name
            invisibleCollection
            endsInCertificate
            program
            permissionLevel
            slug
         }
    }
  }
`;

export const CurriculumFull = `
  code
  content {
    ... on Collection {
          id
          name
          invisibleCollection
          certificateType {
            name
            certificateCode
          }
          endsInCertificate
          program
          permissionLevel
          slug
          sets {
            id
            type
            name
            slug
            shortDescription
            introduction
            goals
            estimatedTimeInMinutes
            externalUrl
            permissionLevel
            activities {
              id
              name
              slug
            }
            setType {
              name
              colorTheme
            }
            icon {
              ${image}
            }
          }
     }
    ... on CurriculumFolder {
          id
          name
          slug
          permissionLevel
          folderType
          coverImage {
            ${image}
          }
          content {
           id
           name
           invisibleCollection
           certificateType {
            name
            certificateCode
          }
          endsInCertificate
           program
           permissionLevel
           slug
           sets {
             id
             type
             name
             slug
             shortDescription
             introduction
             goals
             estimatedTimeInMinutes
             externalUrl
             permissionLevel
             activities {
               id
               name
               slug
             }
             setType {
               name
               colorTheme
             }
             icon {
               ${image}
             }
          }
        }
    }
  }
`;

export const JoinClub = `
  name
  firstStepLabel
  secondStepLabel
  thirdStepLabel
  backButton {
    ${cta}
  }
  heading
  firstStepInstruction
  firstStepInstructionClub
  firstStepAdditionalInstruction
  firstStepField {
    ${anyFormField}
  }
  firstStepCta {
    ${cta}
  }
  secondStepSubheading
  secondStepClubLabel
  secondStepRoleLabel
  secondStepFacilitatorLabel
  secondStepCta {
    ${cta}
  }
  secondStepInstruction
  thirdStepSubheadingFacilitator
  thirdStepCtaFacilitator {
    ${cta}
  }
  messages {
    ${messaging}
  }`;

export const JoinLoop = `
  name
  firstStepLabel
  secondStepLabel
  thirdStepLabel
  backButton {
    ${cta}
  }
  heading
  firstStepInstruction
  firstStepField {
    ${anyFormField}
  }
  firstStepCta {
    ${cta}
  }
  firstStepJoinClubCta {
    ${cta}
  }
  firstStepApplyClubCta {
    ${cta}
  }
  secondStepSubheading
  secondStepProgramLabel
  secondStepFacilitatorLabel
  secondStepCta {
    ${cta}
  }
  secondStepInstruction
  messages {
    ${messaging}
  }`;

export const modules = {
  LoginSignup,
  FacilitatorProgressPage,
  ForgotPassword,
  ForgotPasswordPage,
  ContentPage,
  PrivacyPolicy,
  Curriculum,
  JoinClub,
  JoinLoop,
};

export function getModulesQuery() {
  return Object.keys(modules).map(module => `
    ...on ${module} {
      ${modules[module]}
    }
  `).join('');
}
