import { createReducer } from "redux-act";

import * as a from "../actions/notification";
import { isString } from '../utils/isString';

const getDefaultState = () => ({
  message: null,
  options: null,
});

export default () =>
  createReducer(
    {
      [a.showErrorMessage]: (state, args) => {
        const isTextPassed = isString(args);

        const message = {
          text: isTextPassed ? args : args.text,
          type: 'error',
        };
        return {
          ...state,
          message,
          options: isTextPassed ? {} : { ...args.options },
        };
      },
      [a.showSuccessMessage]: (state, args) => {
        const isTextPassed = isString(args);

        const message = {
          text: isTextPassed ? args : args.text,
          type: 'success'
        };
        return {
          ...state,
          message,
          options: isTextPassed ? {} : { ...args.options },
        };
      },
      [a.showInfoMessage]: (state, args) => {
        const isTextPassed = isString(args);

        const message = {
          text: isTextPassed ? args : args.text,
          type: 'info'
        };
        return {
          ...state,
          message,
          options: isTextPassed ? {} : { ...args.options },
        };
      }
    },
    getDefaultState()
  );
