
import image from './Image';

const ClubsProfileMessaging = `
  name
  navbarRenewalCtaLabel
  clubProfileBasicInfoLabel
  clubProfileScheduleInfoLabel
  clubProfileMembersCountInfoLabel
  clubProfileHostSiteNameLabel
  clubProfileHostSiteTypeLabel
  clubProfileDecisionMakerNameLabel
  clubProfileFacilitatorNameLabel
  clubProfileHostSiteAddressLabel
  clubProfileCurriculumChoiceLabel
  clubProfileScheduleStartDateLabel
  clubProfileScheduleEndDateLabel
  clubProfileScheduleDaysLabel
  clubProfileNumbersOfFacilitatorsLabel
  clubProfileNumbersOfParticipantsLabel
  clubProfileSchedulePrimaryMeetingDayLabel
  clubProfileScheduleSessionStartTimeLabel
  clubProfileScheduleSessionEndTimeLabel
  clubProfileSuccessSpecialistLabel
  clubProfileEnrolledParticipantsLabel
  clubProfileActiveFacilitatorsLabel
  clubProfileShippingLabel
  clubProfileShippingAddressLabel
  clubProfileShippingStreetLabel
  clubProfileShippingCityLabel
  clubProfileShippingStateLabel
  clubProfileShippingZipCodeLabel
  clubProfileConfirmationMessageLabel
  clubProfileConfirmationMessageContent
  clubProfileBasicInfoUpdateLabel
  clubProfileBasicInfoUpdateContent
  clubProfileSaveLabel
  clubProfileCancelLabel
  clubProfileIcon {
    ${image}
  }
  clubProfileScheduleIcon {
    ${image}
  }
  clubProfileMembersCountIcon {
    ${image}
  }
  clubProfileInternationalUpdateLabel
  clubPreferencesOpenClosedEnrollmentLabel
  clubPreferencesActiveLabel
  clubPreferencesExternalFacilitatorsLabel
  clubPreferencesShowProjectsInGalleryLabel
  partnerAffiliationBenefitsLabel
  partnerAffiliationNoDataMsg
  clubBenefitsText
  clubBenefitsTextTooltip
  clubIncentiveCardsContent {
    clubFundTitle
    clubFundText
    clubFundApplyButtonLink
    clubFundLearnMoreButtonLink
    tShirtCardTitle
    tShirtCardText
    tShirtCardLearnMoreButtonLink
    tShirtCardOrderButtonLink
    tShirtCardOrderButtonText
    tShirtCardLearnMoreButtonText
    clubFundLearnMoreButtonText
    clubFundApplyButtonText
    membersCardLeanMoreButtonLink
    membersCardLeanMoreButtonText
    membersCardIncentivesTitle
    membersCardIncentivesText
  }
`;

export default ClubsProfileMessaging;
