import React from "react";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  chartСounter: {
    borderRadius: '4px',
    boxSizing: 'border-box',
    cursor: 'default',
    height: '150px',
    position: 'relative',
    textAlign: 'center',
    width: '120px',
    alignItems: 'center',
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly'
  },
  amount: {
    fontSize: "50px",
    color: 'blue',
    fontWeight: "bold"
  },
  label: {
    fontSize: "16px",
    textTransform: "uppercase",
  }
});

const ChartCounter = ({amount, label}) => {
  const classes = useStyles();

    return (
      <div className={classes.chartСounter}>
        <div className={classes.amount}>
          {amount || '0' }
        </div>
        <div className='label'>
          {label}
        </div>
      </div>
    );
}


export default ChartCounter;
