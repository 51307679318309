import React, { useEffect, useState } from "react";

import { push as pushToRoute } from "connected-react-router";

import * as authActions from "../../actions/auth";
import * as contentfulActions from "../../actions/contentful";
import * as programActions from "../../actions/program";
import * as announcements from "../../actions/announcements";
import * as globalActions from "../../actions/global";
import * as joinClubActions from '../../actions/joinClub';
import Footer from "../../containers/Footer/footer";

import { makeStyles } from "@material-ui/core/styles";
import { connectTo } from "../../utils/generic";
import Collapse from "@material-ui/core/Collapse";
import * as colors from "../../theme/colors";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import ProgramsList from "./program/programsList";
import AnnouncementCard from "./announcement/announcement";
import ChildrenList from "./children/childrenList";
import ChildModal from "./children/childModal";
import { ParentConsentModal } from "./parentConsent";
import { ChildRegisteringModal } from "./childRegisteringModal";
import { ChildViewModal } from "./childViewModal";
import UserDOBModal from '../../components/modals/userProfile/userDOBModal';

import * as trackingActions from "../../actions/tracking";
import * as accountOriginConstants from "../../constants/userConstants";
import {
  HOME_PAGE_VIEW,
  WELCOME_MESSAGE_BUTTON_CLICK,
} from "../../constants/trackingEvents";
import { ADDED_BY_PARENT, cpProgramTypeDetails } from "../../utils/constants";
import TextCustomButton from "../../components/text-button/text-button";
import Carousel from 'react-material-ui-carousel'
import { useTitle } from "../../utils/pageTitles";
import { Roles } from "../../utils/permissions";
import { checkIsClubsDomain } from '../../utils/generic';
import history from '../../../src/history';
import { arrayToObjectNotForcedArrayValues } from "../../utils/generic";
import { userIsAdult, userHasValidBirthday, userIsAllowApplyOrAddChild } from "../../utils/userUtils";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content: {
    maxWidth: 1000,
    margin: "auto auto",
    height: "100%",
    minHeight: 'calc(100vh - 135px)',
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  announcements: {
    margin: "auto",
    padding: "30px 30px 0 30px",
  },
  collapseBtn: {
    height: 40,
    borderRadius: 5,
    backgroundColor: 'white',
    color: colors.tealA700,
    fontSize: 16,
    fontWeight: "bold",
    '& .MuiTypography-body1': {
      color: colors.tealA700,
    },
    marginRight: 5,
    "&:hover": {
      backgroundColor: 'white',
    },
  },
  completedSection: {
    display: "flex",
    fontWeight: "bold",
  },
  completedBox: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0 30px",
  },
  bottomBox: {
    bottom: 0,
    left: 0,
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'space-between',
    backgroundColor: 'rgb(12, 107, 104)',
    padding: '15px 0',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      margin: '10px 0 0 0',
    }
  },
  bottomLinksBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      marginLeft: 25,
    }
  },
  gwc: {
    color: colors.white,
    fontWeight: "bold",
    marginLeft: 25,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10,
    }
  },
  bottomLink: {
    color: colors.white,
    marginRight: 10,
    fontSize: 12,
    '&:hover': {
      color: colors.lightThemeGreen,
      textDecoration: 'none',
    }
  },
  builtBy: {
    margin: '0 25px 0 0',
    color: colors.white,
    fontSize: 12,
  },
  buildByContainer: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
    }
  },
  linksContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '90%',
    }
  }
}));

const MESSAGES_KEY = "gwcNextAnnouncements";

const Home = ({
  user,
  announcements: { announcements },
  changeRole,
  contentful,
  getAnnouncements,
  push,
  createChild,
  updateChild,
  createUsageLog,
  authorizeChild,
  setStudentView,
  loadHomePageContent,
  getStaticPages,
  currentUserUpdated,
  getEnrollmentFormData,
  joinClub,
  createTrackingLog,
  isCreatingChild,
  setParentConsent,
  isSettingParentConsent,
  parentConsentError,
  loadGlobalContent,
  getEnrollmentData,
  enrolledChildError,
  childReceived,
  setChildReceived,
  generateParentConsentPdf
}) => {
  useTitle("home");

  const classes = useStyles();
  const [gwcAnnouncements, setGwcAnnouncements] = useState(null);
  const [items, setItems] = useState({
    currentClub: null,
    programs: null,
    partnerships: null,
    completedPrograms: null,
    inactiveCP: null
  });
  const [openCompleted, setOpenCompleted] = useState(false);
  const [children, setChildren] = useState(null);
  const [selectedChild, setSelectedChild] = useState({});
  const [childrenLabels, setChildrenLabels] = useState(null);
  const [homeContent, setHomeContent] = useState({});
  const [defaultProgramId, setDefaultProgramId] = useState(null);
  const [isChildModalOpen, setIsChildModalOpen] = useState(false);
  const [showApply, setShowApply] = useState(false);
  const [domesticCountry, setDomesticCountry] = useState(null);
  const [countryId, setCountryId] = useState(null);
  const [internationalUser, setInternationalUser] = useState(false);
  const [isParentConsentModal, setParentConsentModal] = useState(false);
  const [isRegisterChildModal, setRegisterChildModal] = useState(false);
  const [isChildViewModal, setChildViewModal] = useState(false);
  const [programTypesById, setProgramTypesById] = useState(null);
  const [allProgramTypes, setAllProgramTypes] = useState([]);
  const [userDOBModal, setUserDOBModal] = React.useState(false);

  const isClubsDomain = checkIsClubsDomain();

  useEffect(() => {
    if (!user || !contentful) {
      return;
    }

    const { roles, children, countryId } = user;
    const {
      hqConfig: { defaultProgram: dbDefaultProgramId },
      programTypes,
   } = contentful;

    const programTypeDetailsById = arrayToObjectNotForcedArrayValues(programTypes, "id");
    setProgramTypesById(programTypeDetailsById);
    setAllProgramTypes(programTypes);

    let programs = roles.filter((e) => e.programId && e.program);
    const showDefaultProgramToStudent = programs.filter(role => role.program.id != dbDefaultProgramId).every(role => {
      return role.roleId === Roles.Student && programTypeDetailsById[role.program.programTypeId].showDefaultProgram;
    });
    programs = programs.map((e) => e.program);

    if (programs.filter((p) => !p.completed && p.active).length > 1 && !showDefaultProgramToStudent) {
      programs = programs.filter((e) => parseInt(e.id, 10) != dbDefaultProgramId);
    }

    setCountryId(countryId);

    if (domesticCountry && (countryId !== domesticCountry)) {
      setInternationalUser(true);
    }

    if ((isClubsDomain && !internationalUser && (userIsAdult(user) || userIsAllowApplyOrAddChild(user)))) {
      setShowApply(true);
    } else {
      setShowApply(false);
    }

    const dob = moment.parseZone(user.birthDate).format();
    var year = moment().diff(dob, "years");

    let allCP = roles.filter((e) => e.cpOrganizationId)
    .map((e) => {
      return {
        ...e.partnership,
        communityPartnershipDetails: cpProgramTypeDetails,
      };
    });
    let activePartnerships = allCP.filter(e => e.isActive);

    const inactiveCP = allCP.filter(e => !e.isActive);

    const {
      sortedPrograms,
      sortedPartnerships,
      defaultClub,
    } = sortPrograms(roles, programs, activePartnerships);

    const completedPrograms = programs.filter((e) => !e.active);
    const completedActivePrograms = programs.filter((e) => e.active && e.completed);
    const allInactivePrograms = completedPrograms.concat(completedActivePrograms);

    setItems({
      currentClub: defaultClub,
      completedPrograms: allInactivePrograms,
      programs: sortedPrograms,
      partnerships: sortedPartnerships,
      inactiveCP: inactiveCP
    });

    setChildren(children);

    if (!announcements) {
      const data = extractUserParamters(user, dbDefaultProgramId);
      getAnnouncements(data);
    } else {
      showAnnouncements(user, year, announcements);
    }
  }, [user, announcements, contentful, domesticCountry]);

  useEffect(() => {
    getStaticPages('/login');
    createUsageLog({
      action: HOME_PAGE_VIEW,
    });
  }, []);

  useEffect(() => {
    if (!contentful.GlobalComponentQuery) {
      loadGlobalContent();
      return;
    }

    if (!contentful.HomePageQuery) {
      loadHomePageContent();
      return;
    }

    setHomeContent(contentful.HomePageQuery);
    const {
      childrenManagementLabels,
    } = contentful.GlobalComponentQuery;
    const {
      hqConfig: { defaultProgram, domesticCountryId },
    } = contentful;

    setDefaultProgramId(parseInt(defaultProgram, 10));
    setDomesticCountry(parseInt(domesticCountryId, 10));
    setChildrenLabels(childrenManagementLabels);
  }, [contentful]);

  useEffect(() => {
    if (!!childReceived?.id) {
      setChildViewModal(true);
      setRegisterChildModal(false);
    }
  }, [childReceived?.id]);

  const hideAnnoucement = (announcement) => {
    const { id } = announcement;
    const hidedAnnouncements = localStorage.getItem(MESSAGES_KEY);
    const excludedIds = hidedAnnouncements
      ? JSON.parse(hidedAnnouncements)
      : [];
    excludedIds.push(id);

    const announcements = gwcAnnouncements
      ? gwcAnnouncements.filter((e) => excludedIds.indexOf(e.id) === -1)
      : null;
    setGwcAnnouncements(announcements);
    localStorage.setItem(MESSAGES_KEY, JSON.stringify(excludedIds));
  };

  const showAnnouncements = (user, year, announcements) => {
    if (announcements.length === 0) {
      return;
    }

    const hidedAnnouncements = localStorage.getItem(MESSAGES_KEY);
    const excludedIds = hidedAnnouncements
      ? JSON.parse(hidedAnnouncements)
      : [];

    let data = announcements.filter(
      (e) =>
        (!e.maxAge || e.maxAge >= year) &&
        ((e.minAge && e.minAge <= year) || !e.minAge)
    );

    const gwcAnnouncements = data
      ? data.filter((e) => excludedIds.indexOf(e.id) === -1)
      : null;
    setGwcAnnouncements(gwcAnnouncements);
  };

  const distinct = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const extractUserParamters = (user, defaultProgramId) => {
    const { roles, countryId, children } = user;
    let curriculumIds = [];
    let programTypeIds = [];

    roles.map((e) => {
      if (e.programId && e.program && e.programId !== Number(defaultProgramId) && e.program.programTypeId) {
        programTypeIds.push(e.program.programTypeId);
      }
      if (!!e.program?.curriculum) {
        curriculumIds.push(parseInt(e.program.curriculum.id, 10));
      }
    });

    const isStudent = roles.some((e) => e.roleId === Roles.Student && !!e.programId && e.programId !== Number(defaultProgramId));
    const isFacilitator = roles.some((e) => e.roleId === Roles.Facilitator && !!e.programId && e.programId !== Number(defaultProgramId));
    const isParent = children ? children.length > 0 : false;

    curriculumIds = curriculumIds.filter(distinct);
    programTypeIds = programTypeIds.filter(distinct);

    return {
      curriculumIds,
      programTypeIds,
      countryId,
      isStudent,
      isFacilitator,
      isParent,
    };
  };

  const sortPrograms = (roles, programs, partnerships) => {
    const currentRole = roles.find((e) => e.current);

    const currentProgram =
      programs &&
      currentRole &&
      programs.find((e) => e.id === currentRole.programId && !e.completed);
    const currentPartnerships =
      partnerships &&
      currentRole &&
      partnerships.find((e) => e.id === currentRole.cpOrganizationId);

    const sortedPrograms = sortByLaunchDate(
      programs,
      currentProgram ? currentProgram.id : null,
    );

    const sortedPartnerships = sortByLaunchDate(
      partnerships,
      currentPartnerships ? currentRole.cpOrganizationId : null,
    );

    return {
      sortedPrograms,
      sortedPartnerships,
      defaultClub: currentProgram || currentPartnerships,
    };
  };

  const sortByLaunchDate = (array, currentId) => {
    if (!array) {
      return;
    }

    let excludeCurrentArray = array.filter(
      (e) => e.id !== currentId && !e.completed
    );
    const newArray = excludeCurrentArray.sort(function (a, b) {
      return a.launchDate > b.launchDate ? 1 : -1;
    });
    return newArray;
  };

  const onSelectProgram = (program) => {
    const { id, completed, programTypeId } = program;

    if (completed && (!programTypesById[programTypeId]?.inactiveAvailableEnabled)) {
      return;
    }

    changeRole({ programId: id });
    setStudentView(false);
    push(`/gwc`);
  };

  const onSelectPartnership = (partnership) => {
    changeRole({ cpOrganizationId: partnership.id });
    setStudentView(false);
    push(`/community-partnership`);
  };

  const onJoinChild = (child) => {
    push(`/join-program/${child.id}`);
  };

  const onJoinNew = () => {
    createUsageLog({
      action: WELCOME_MESSAGE_BUTTON_CLICK,
      data: JSON.stringify({ href: window.location.href }),
    });
    push("/join-program");
  };

  const onApply = () => {
    createUsageLog({
      action: WELCOME_MESSAGE_BUTTON_CLICK,
      data: JSON.stringify({ href: window.location.href }),
    });
    push("/gwc-club-application");
  };

  const onSaveChild = (values) => {
    values.accountOrigin = accountOriginConstants.ADDED_BY_PARENT;
    if (values.id) {
      updateChild(values);
    } else {
      const childData = { ...values, countryId: user.countryId };
      createChild({ child: childData, userId: user.id, accountOrigin: ADDED_BY_PARENT });
    }
  };

  const triggerModal = (value, child) => {
    if (child) {
      setSelectedChild(child);
    }
    setIsChildModalOpen(value);
  };

  const loginChild = (child) => {
    if (!child.roles || !child.roles.length) {
      return;
    }
    authorizeChild({ childId: child.id, parentId: user.id });
  };

  useEffect(() => {
    if (history?.location?.search.includes('addchild=true')) {
      !user?.acceptedParentConsentAt ? setParentConsentModal(true) : setRegisterChildModal(true);
      const queryParams = "";
      history.replace({
        search: queryParams,
      });
    }
  }, [history?.location, user?.acceptedParentConsentAt]);

  useEffect(() => {
    if (user?.isParent) {
      !user?.acceptedParentConsentAt && setParentConsentModal(true);
    }
  }, [user]);

  const onAddChild = () => !user?.acceptedParentConsentAt ? setParentConsentModal(true) : setRegisterChildModal(true);

  const renderDOBModal = () => {
    return (
      <UserDOBModal
        onClose={() => {
          setUserDOBModal(false);
        }}
        userDOBModal={userDOBModal}
        callback={(user) => userIsAdult(user) && onAddChild()}
        message='In order to add a child you must be at least 18 years old.'
      />
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {gwcAnnouncements && gwcAnnouncements.length > 0 && (
          <Carousel
            className={classes.announcements}
            fullHeightHover={false}
            autoPlay={false}
            indicators={!!(gwcAnnouncements.length > 1)}
            navButtonsAlwaysInvisible={!(gwcAnnouncements.length > 1)}
          >
            {gwcAnnouncements.map((e, index) => (
              <AnnouncementCard
                key={index}
                item={e}
                onHide={() => hideAnnoucement(e)}
              />
            ))
            }
          </Carousel>
        )}
        {children && children.length > 0 && childrenLabels && (
          <ChildrenList
            title="Children"
            className={classes.container}
            children={children}
            labels={childrenLabels}
            onJoin={onJoinChild}
            onEdit={triggerModal}
            onAddChild={() => userHasValidBirthday(user) ? onAddChild() : setUserDOBModal(true)}
            loginChild={loginChild}
            showAddChild={!userHasValidBirthday(user) || userIsAdult(user)}
          />
        )}
        {items.programs && (
          <ProgramsList
            showApply={showApply}
            title={"My Programs"}
            defaultProgramId={defaultProgramId}
            className={classes.container}
            onSelectProgram={onSelectProgram}
            onSelectPartnership={onSelectPartnership}
            onJoinNewClick={() => onJoinNew()}
            onApplyClick={() => onApply()}
            icons={homeContent}
            currentClub={items.currentClub}
            programs={items.programs}
            partnerships={items.partnerships}
            userRoles={user.roles}
            contentful={contentful}
            user={user}
            internationalUser={internationalUser}
            push={push}
            programTypesById={programTypesById}
          />
        )}

        {(items.completedPrograms && items.completedPrograms.length > 0 ||
          items.inactiveCP && items.inactiveCP.length > 0)
          && contentful && (
            <>
              <div className={classes.completedBox}>
                <TextCustomButton
                  className={classes.childBtn}
                  onClick={() => setOpenCompleted(!openCompleted)}
                  label={"View Inactive Programs"}
                  endIcon={openCompleted ? <ExpandLess /> : <ExpandMore />}
                  sel="view-completed"
                />
              </div>
              <Collapse in={openCompleted} timeout="auto" unmountOnExit>
                <ProgramsList
                  title="Inactive Programs"
                  defaultProgramId={defaultProgramId}
                  className={classes.container}
                  onSelectProgram={onSelectProgram}
                  icons={homeContent}
                  inactiveCP={items.inactiveCP}
                  completedPrograms={items.completedPrograms}
                  contentful={contentful}
                  userRoles={user.roles}
                  user={user}
                  push={push}
                  programTypesById={programTypesById}
                />
              </Collapse>
            </>
          )}
        {user && (
          <ChildModal
            child={selectedChild}
            isOpen={isChildModalOpen}
            onSave={onSaveChild}
            onClose={() => triggerModal(false, {})}
            domesticCountry={domesticCountry}
            countryId={countryId}
          />
        )}
        {!user?.acceptedParentConsentAt && isParentConsentModal &&
          <ParentConsentModal
            setParentConsentModal={setParentConsentModal}
            setRegisterChildModal={setRegisterChildModal}
            content={homeContent}
            currentUserUpdated={currentUserUpdated}
            setParentConsent={setParentConsent}
            parentConsentError={parentConsentError}
            isSettingParentConsent={isSettingParentConsent}
            generateParentConsentPdf={generateParentConsentPdf}
        />}
        {isRegisterChildModal &&
          <ChildRegisteringModal
            setRegisterChildModal={setRegisterChildModal}
            currentUserUpdated={currentUserUpdated}
            user={user}
            getEnrollmentFormData={getEnrollmentFormData}
            joinClub={joinClub}
            createChild={createChild}
            createTrackingLog={createTrackingLog}
            isCreatingChild={isCreatingChild}
            getEnrollmentData={getEnrollmentData}
            enrolledChildError={enrolledChildError}
            childRegisteringContent={homeContent?.creatingChild}
            defaultProgramId={defaultProgramId}
            allProgramTypes={allProgramTypes}
          />
        }
        {isChildViewModal &&
          <ChildViewModal
            user={user}
            labels={childrenLabels}
            domesticCountry={domesticCountry}
            setRegisterChildModal={setRegisterChildModal}
            setChildReceived={setChildReceived}
            addedChild={childReceived}
            setChildViewModal={setChildViewModal}
        />
      }
      </div>
      <Footer />
      {user && userDOBModal && renderDOBModal()}
    </div>
  );
};

export default connectTo(
  ({ announcements, auth: { user, isCreatingChild, isSettingParentConsent, parentConsentError, enrolledChildError, childReceived }, contentful, joinClub }) => ({
    announcements,
    contentful,
    user,
    joinClub,
    isCreatingChild,
    isSettingParentConsent,
    parentConsentError,
    enrolledChildError,
    childReceived
  }),
  {
    push: pushToRoute,
    ...authActions,
    ...programActions,
    ...announcements,
    ...trackingActions,
    ...globalActions,
    ...contentfulActions,
    ...joinClubActions,
  },
  Home
);
