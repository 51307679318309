import React, { useState } from 'react';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import RefreshIcon from '@material-ui/icons/Refresh';
import { getTheme } from "../../../utils/colorTheme";
import { ProgressButton } from './Buttons';
import ProgressBar from './ProgressBar';
import * as colors from '../../../theme/colors';
import { formatEstimatedMinutes } from '../../../utils/generic';
import ConfirmDialog from '../../../components/ConfirmDialog';
import TextButton from "../../../components/text-button/text-button";

import { calcNodeProgress } from "../curriculumHelper";
import { handleKeyPress } from '../../../utils/accessibility';

const styles = makeStyles({
  buttonBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 15,
    minHeight: 37,
  },
  cardContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: 165,
    marginTop: 5,
    '&.cardWithoutHeader': {
      height: 110,
      marginTop: 0,
      backgroundColor: 'green',
    },
    '&.cardFolderNoIcon': {
      height: '100%'
    },
    '&.cardWithPadding': {
      marginTop: 20,
    }
  },
  cardDescr: {
    fontSize: 14,
    overflow: 'hidden',
    boxOrient: 'vertical',
    lineClamp: 3,
    display: '-webkit-box',
    marginTop: 8,
    color: "#013046",
  },
  cardHeader: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  cardHeaderWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardName: {
    fontSize: 18,
    fontWeight: 700,
    color: "#013046",
    '&.centerText': {
      textAlign: "center",
    },
  },
  clockSmall: {
    transition: 'filter 500ms',
    width: 16,
    height: 16,
  },
  completedItem: {
    width: 25,
    height: 25,
    color: colors.lightThemeGreen,
    position: 'absolute',
    top: 15,
    right: 15,
  },
  grid: {
    marginTop: 15,
  },
  paper: {
    boxShadow: "none",
    cursor: 'pointer',
    height: 430,
    position: 'relative',
    '&:hover': {
      transform: 'scale(1.05, 1.05)',
      transition: 'transform 150ms linear'
    },
  },
  cardIcon: {
    maxHeight: 100,
    maxWidth: 100
  },
  cardIconBox: {
    alignItems: 'center',
    display: 'flex',
    height: 90,
    margin: 35,
    justifyContent: 'center',
    textAlign: 'center',
  },
  cardWrapper: {
    color: colors.blueGrey800,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    position: 'relative',
    transition: 'color .5s',
    width: '100%',
    "&:focus": {
      outline: `2px auto ${colors.grey400}`,
    },
  },
  cardInnerWrapper: {
    padding: '20px 16px 0px 16px',
  },
  setName: {
    fontSize: 10,
    fontFamily: "'Roboto Mono',monospace",
    color: colors.green950,
    textTransform: "uppercase",
    fontWeight: 500,
  },
  timeBox: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 8,
  },
  noTimeBox: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 8,
  },
  timeBoxText: {
    fontSize: 12,
    color: colors.lightGrey,
    marginLeft: 8,
  },
  subNodesText: {
    fontSize: 12,
    color: colors.lightGrey,
    marginLeft: 10,
    paddingLeft: 10,
    borderLeft: "1px #EDEDED solid",
  },
  subNodesTextNoEta: {
    fontSize: 12,
    color: colors.lightGrey,
  },
  buttonIcon: {
    marginRight: 5,
    marginTop: -2,
  },
  actionButton: {
    fontSize: 16,
    '&:hover': {
      color: "#013046",
    },
  },
  label: {
    height: 14,
    fontSize: 10,
    fontFamily: "'Roboto Mono',monospace",
    fontWeight: 500,
    textTransform: "uppercase",
    alignSelf: "center",
    marginLeft: "auto",
    marginRight: 5,
  },
});

const Card = (props) => {
  const {
    item,
    node,
    defaultActivityIcon,
    onNodeClick,
    onRedoClick,
    onResumeClick,
    onStartClick,
    userActivitiesInfo,
    isFacilitator,
    communityPartnershipLink,
    labelBackground,
    labelFontColor,
    isAllNodesFacilitatorsOnly,
    programRoleLabel,
    openTextAnswers,
    programId,
  } = props;

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [data, setData] = useState({
    progress: 0,
    subNodesCount: 0,
    estimatedTimeInMinutes: 0,
  });

  const enableProgress = !!item.nodeType || item.__typename === 'Collection';

  const removeDuplicates = (data) => {
    return data.filter((value, index) => data.indexOf(value) === index);
  };

  React.useEffect(() => {
    setData({
      progress: calcNodeProgress({ node, communityPartnershipLink, userActivitiesInfo, isFacilitator, removeDuplicates, studentView: false, openTextAnswers, programId }),
      subNodesCount: Object.values(node.nodes).filter((key) => !key.isOptional).length,
      estimatedTimeInMinutes: node.estimatedTimeInMinutes ? formatEstimatedMinutes(node.estimatedTimeInMinutes) : null,
    });
  }, [node, userActivitiesInfo, openTextAnswers]);

  const { progress, subNodesCount, estimatedTimeInMinutes } = data;

  const cardHeader = item.setType && item.setType.name ? item.setType.name : '';
  const cardHeaderName = node.activityType
    ? node.activityType.name
    : node.setType
      ? node.setType.name
      : cardHeader;

  const nodeTheme = node.setType
    ? node.setType.colorTheme
    : node.activityType
      ? node.activityType.colorTheme
      : null;

  const cardTheme = getTheme(nodeTheme);
  const cardClasses = styles({ backColor: cardTheme.b, fontColor: cardTheme.f, accentColor: cardTheme.a });

  const isFacilitatorOnlyActivity = node.permissionLevel === "Facilitators/Teachers only";

  return (
    <Grid key={node.id} item xl={4} lg={4} md={4} sm={4} xs={12}
      className={cardClasses.grid}
    >
      <Paper className={cardClasses.paper}>
        <Box
          className={cardClasses.cardWrapper}
          sel="activity"
          onKeyPress={handleKeyPress(() => onNodeClick(node, isFacilitatorOnlyActivity ? true : false))}
          onClick={() => onNodeClick(node, isFacilitatorOnlyActivity ? true : false)}
          tabIndex={0}
        >
          <Box>
            <Box>
              {!isFacilitatorOnlyActivity && progress === 100 && <CheckCircleIcon className={cardClasses.completedItem} />}
              {(
                node.icon
                  ? <Box className={cardClasses.cardIconBox}>
                    <img src={node.icon.src} alt={node.icon.alt} className={cardClasses.cardIcon} loading="lazy" />
                  </Box>
                  : defaultActivityIcon
                    ? <Box className={cardClasses.cardIconBox}><img src={defaultActivityIcon.src} alt={defaultActivityIcon.alt} className={cardClasses.cardIcon} loading="lazy" /></Box>
                    : ""
              )}

              {!isFacilitatorOnlyActivity && enableProgress && <Box>
                <ProgressBar variant="determinate" value={progress} />
              </Box>}
            </Box>
            <Box className={cardClasses.cardInnerWrapper}>
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                  onNodeClick(node)
                }}>
                <Box className={cardClasses.cardHeaderWrapper}>
                  <Box className={cardClasses.cardHeader}>
                    <Box><Typography className={cardClasses.setName}>{cardHeaderName}</Typography></Box>
                  </Box>
                </Box>

                <Box className={clsx(
                  cardClasses.cardContentWrapper,
                  !cardHeaderName ? 'cardWithPadding' : '',
                  node.icon || defaultActivityIcon ? '' : 'cardFolderNoIcon')}>
                  <Typography className={cardClasses.cardName} variant="h4">{node.name}</Typography>

                  {node.estimatedTimeInMinutes ?
                    (<Box className={cardClasses.timeBox}>
                      <QueryBuilderIcon className={cardClasses.clockSmall} />
                      <Typography className={cardClasses.timeBoxText}>{estimatedTimeInMinutes}</Typography>
                      {subNodesCount > 0 && (
                        <Typography className={cardClasses.subNodesText}>{`${subNodesCount} ${subNodesCount > 1 ? 'Activities' : 'Activity'}`}</Typography>
                      )}
                    </Box>)
                    : subNodesCount > 0 &&
                      <Box className={cardClasses.noTimeBox}>
                        <Typography className={cardClasses.subNodesTextNoEta}>{`${subNodesCount} ${subNodesCount > 1 ? 'Activities' : 'Activity'}`}</Typography>
                      </Box>
                  }

                  {node.shortDescription && <Typography className={cardClasses.cardDescr}>{node.shortDescription}</Typography>}
                </Box>
              </Box>

              {subNodesCount <= 0 ?
                (<Box className={cardClasses.buttonBox}>
                  {!isFacilitatorOnlyActivity && progress === 0 && <ProgressButton variant="contained" onClick={(e) => {
                    e.stopPropagation();
                    onStartClick(node);
                  }} className={cardClasses.actionButton}>
                    <PlayArrowIcon className={cardClasses.buttonIcon} />Start
                  </ProgressButton>}

                  {!isFacilitatorOnlyActivity && (progress !== 0 && progress !== 100) &&
                    <ProgressButton onClick={(e) => {
                      e.stopPropagation();
                      onResumeClick(node)
                    }}
                      variant="contained"
                      className={cardClasses.actionButton}>
                      <SkipNextIcon className={cardClasses.buttonIcon} />Resume
                    </ProgressButton>}

                  {!isFacilitatorOnlyActivity && progress === 100 && <ProgressButton variant="contained"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowConfirmation(true);
                    }}
                    className={cardClasses.actionButton}>
                    <RefreshIcon className={cardClasses.buttonIcon} />Redo
                  </ProgressButton>}

                  {!isFacilitatorOnlyActivity && <Tooltip title="Preview" placement="top">
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        onNodeClick(node, true);
                      }}
                    >
                      <VisibilityOutlinedIcon />
                    </IconButton>
                  </Tooltip>}

                  {isFacilitatorOnlyActivity && !isAllNodesFacilitatorsOnly && <TextButton
                    className={cardClasses.label}
                    style={{ color: labelFontColor, backgroundColor: labelBackground }}
                    label={programRoleLabel}
                  />}
                </Box>)
                : (
                  <Box className={cardClasses.buttonBox}>
                    <ProgressButton variant="contained"
                      onClick={(e) => {
                        e.stopPropagation();
                        onNodeClick(node)
                      }} className={cardClasses.actionButton}>
                      View Set
                    </ProgressButton>
                    {isFacilitatorOnlyActivity && !isAllNodesFacilitatorsOnly && <TextButton
                      className={cardClasses.label}
                      style={{ color: labelFontColor, backgroundColor: labelBackground }}
                      label={programRoleLabel}
                    />}
                  </Box>
                )
              }
            </Box>
          </Box>
        </Box>
      </Paper>
      {!!showConfirmation && < ConfirmDialog
        content="Are you sure you want to redo this item? Your progress will be cleared."
        onCancel={() => { setShowConfirmation(false); }}
        onConfirm={() => {
          setShowConfirmation(false);
          onRedoClick(node);
        }}
        open={showConfirmation}
        title="Clear progress"
        okTitle="Agree"
      />}
    </Grid>
  );
};

export default Card;
