import { createReducer } from "redux-act";

import * as a from "../actions/calendar";

const getDefaultState = () => ({
  isLoading: false,
  isLoaded: false,
  isLoadingMembersList: false,
  isLoadingSingleEventDetails: false,
  events: [],
  calendarLabels: {},
  programs: [],
  pageNumber: 1,
  originalEvents: [], // need to store events without their repeated duplicates
  loadMore: false,
});

const calcMonthDays = (month, year) => {
  return new Date(year, month, 0).getDate();
};

const getDateWithoutLocalTimezone = (newDate, startDateTime) => {
  const date = newDate.toISOString().split("T")[0];
  const time = startDateTime.split("T")[1];

  return `${date}T${time}`;
};

const createRepeatingEvents = (events, isLoadMore) => {
  const eventList = [];
  let lastSingleEventDate = null;

  if (!!isLoadMore) {
    const items = events.filter(e => !e.repeatingFreq || e.repeatingFreq.toUpperCase() === "NONE");
    lastSingleEventDate = new Date(Math.max.apply(null, items.map(function(e) {
      return new Date(e.startDate);
    })));
  }

  for (const ev of events) {
    if (
      (!ev.repeatingFreq || ev.repeatingFreq.toUpperCase() === "NONE") ||
      !ev.repeatingUntil
    ) {
      eventList.push(ev);
    } else {
      const evStartDate = new Date(ev.startDate);
      const evRepeatUntill = new Date(!!lastSingleEventDate && lastSingleEventDate < ev.repeatingUntil
        ? lastSingleEventDate
        : ev.repeatingUntil);

      const diffTime = Math.abs(evRepeatUntill - evStartDate);
      if (evRepeatUntill > evStartDate) {
        switch (ev.repeatingFreq.toUpperCase()) {
          case "DAILY":
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            for (let i = 0; i < diffDays; i++) {
              let cpStDate = new Date(ev.startDate);
              let cpEnDate = new Date(ev.endDate);
              eventList.push({
                ...ev,
                originStartDate: ev.startDate,
                startDate: getDateWithoutLocalTimezone(
                  new Date(cpStDate.setDate(cpStDate.getDate() + i)),
                  ev.startDate
                ),
                endDate: getDateWithoutLocalTimezone(
                  new Date(cpEnDate.setDate(cpEnDate.getDate() + i)),
                  ev.endDate
                ),
              });
            }
            break;
          case "WEEKLY":
            const diffWeeks = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 7));

            for (let i = 0; i < diffWeeks; i++) {
              let cpStDate = new Date(ev.startDate);
              let cpEnDate = new Date(ev.endDate);
              eventList.push({
                ...ev,
                originStartDate: ev.startDate,
                startDate: getDateWithoutLocalTimezone(
                  new Date(cpStDate.setDate(cpStDate.getDate() + i * 7)),
                  ev.startDate
                ),
                endDate: getDateWithoutLocalTimezone(
                  new Date(cpEnDate.setDate(cpEnDate.getDate() + i * 7)),
                  ev.endDate
                ),
              });
            }
            break;
          case "MONTHLY":
            eventList.push(ev);

            let cpStDate = new Date(ev.startDate);
            let cpEnDate = new Date(ev.endDate);

            while (evRepeatUntill > cpStDate) {
              const daysInMonth = calcMonthDays(
                cpStDate.getMonth() + 1,
                cpStDate.getFullYear()
              );
              const diffMonthTime = Math.abs(evRepeatUntill - cpStDate);
              const diffMonths = Math.ceil(
                diffMonthTime / (1000 * 60 * 60 * 24 * daysInMonth)
              );

              if (!(diffMonths - 1)) {
                break;
              }

              eventList.push({
                ...ev,
                originStartDate: ev.startDate,
                startDate: getDateWithoutLocalTimezone(
                  new Date(cpStDate.setDate(cpStDate.getDate() + daysInMonth)),
                  ev.startDate
                ),
                endDate: getDateWithoutLocalTimezone(
                  new Date(cpEnDate.setDate(cpEnDate.getDate() + daysInMonth)),
                  ev.endDate
                ),
              });
            }
            break;

          default:
            break;
        }
      } else {
        eventList.push(ev);
      }
    }
  }
  return eventList;
};

export default () =>
  createReducer(
    {
      [a.loadCalendarsData]: (state) => ({
        ...state,
        isLoading: true,
        calendars: [],
      }),
      [a.getProgramsAndMembersInfo]: (state)=> ({
        ...state,
        isLoadingMembersList: true,
      }),
      [a.getEventDetailsInfo]: (state) => ({
        ...state,
        isLoadingSingleEventDetails: true,
      }),
      [a.receivedCalendarData]: (state, payload) => {
        const { events, calendarLabels, pageNumber, totalEvents } = payload;

        let originalEvents = [];

        if (pageNumber === 1) {
          originalEvents = [...events];
        } else {
          // merge new events with previously loaded;
          originalEvents = [...state.originalEvents, ...events];
        }
        const isAllEventsLoaded = totalEvents > originalEvents.length;

        return {
          ...state,
          events: createRepeatingEvents(originalEvents, isAllEventsLoaded),
          calendarLabels,
          isLoading: false,
          pageNumber,
          originalEvents,
          loadMore: isAllEventsLoaded,
          totalEvents,
        };
      },
      [a.receivedProgramsAndMembersInfo]: (state, payload) => {
        return {
          ...state,
          isLoadingMembersList: false,
          programs: payload,
        };
      },
      [a.createEventData]: (state) => {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      },
      [a.receivedCreatedEvent]: (state, payload) => {
        const newOriginalEvents = [...state.originalEvents, payload];
        const eventList = createRepeatingEvents(newOriginalEvents, state.loadMore);

        return {
          ...state,
          events: eventList,
          originalEvents: newOriginalEvents,
          isLoading: false,
          isLoaded: true,
        };
      },
      [a.receivedEventDetailsInfo]: (state, payload) => {
        const newEvents = state.events.map(el => {
          if (el.id === payload.id) {
            el.programs = payload.programs;
            el.attendees = payload.attendees;
            el.externalAttendees = payload.externalAttendees;
          }
          return el;
        });

        return {
          ...state,
          events: newEvents,
          isLoadingSingleEventDetails: false,
        };
      },
      [a.updateEventData]: (state, payload) => {
        const removedItemIndex = state.events.findIndex((item) => {
          return item.id === payload.event.id
        });
        state.events[removedItemIndex].isLoading = true;

        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      },
      [a.receivedUpdateEventData]: (state, payload) => {
        const newOriginalEvents = [...state.originalEvents.filter((e) => e.id !== payload.id), payload];
        const eventList = createRepeatingEvents(newOriginalEvents, state.loadMore);

        return {
          ...state,
          events: [...eventList],
          originalEvents: newOriginalEvents,
          isLoading: false,
          isLoaded: true,
        };
      },
      [a.deleteEvent]: (state) => {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      },
      [a.receivedDeletedEvent]: (state, payload) => {
        const newEvents = [...state.events].filter((e) => e.id !== payload);
        const newOriginalEvents = [...state.originalEvents].filter((e) => e.id !== payload);
        return {
          ...state,
          events: newEvents,
          originalEvents: newOriginalEvents,
          isLoading: false,
          isLoaded: true,
        };
      },
      [a.onError]: (state) => {        
        return {
          ...state,
          isLoading: false,
        };
      },
    },
    getDefaultState()
  );
