import React, { useState } from "react";

import clsx from "clsx";
import * as colors from "../../theme/colors";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import { formatEstimatedMinutes } from "../../utils/generic";
import { TimerModal } from "../../components/modals/timer/timerModal";
import UnfinishedSectionsModal from "../../components/modals/curriculum/unfinishedSectionsModal";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import TimerIcon from "@material-ui/icons/Timer";
import PauseIcon from "@material-ui/icons/Pause";
import StopIcon from "@material-ui/icons/Stop";
import { TooltipWrapper } from "../../components/tooltip/tooltip";

import AccessTimeIcon from "@material-ui/icons/AccessTime";
import SpeedIcon from "@material-ui/icons/Speed";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";

import StopPresentationIcon from "./stopPresentationIcon";
import CustomButton from "../../components/customButton/customButton";
import TextCustomButton from "../../components/text-button/text-button";

import { Roles } from "../../utils/permissions";

import {
  STOP_PRESENT_VIEW,
  START_PRESENT_VIEW,
} from "../../constants/trackingEvents";
import { PageLeaveModal } from '../../components/modals/pageLeaveWarning';

const tooltipMsg = {
  start:
    "Click here to view this activity as a presentation. This is also what students see!",
  startStudents: "Click here to view this activity as a presentation",
  stop: "Click here to stop presentation mode",
  timer: "Click here to set a timer",
};


const useStyles = makeStyles((theme) => ({
  presentationIconsWrapper: {
    width: "inherit",
    minWidth: "70%",
    alignItems: "center",
    justifyContent: "space-between",
    display: "inline-flex",
  },
  containerWrapper: {
    backgroundColor: "white",
    position: "sticky",
    top: 113,
    zIndex: 1000,
    [theme.breakpoints.down('md')]: {
      top: 145,
    },
  },
  iconsContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: 10,
    },
  },
  metaContainer: {
    padding: "13px 15px 10px",
    maxWidth: 1200,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    textAlign: "center",

    [theme.breakpoints.down("xs")]: {
      padding: "5px 0",
      display: "inline",
    },
  },
  metaBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  metaDetails: {
    marginTop: 2,
    fontSize: 14,
    color: "#003046",
    fontWeight: "bold",
    lineHeight: 1.5,
    [theme.breakpoints.down("xs")]: {
      maxWidth: 75,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  presentationButton: {
    [theme.breakpoints.down("xs")]: {
      "& div": {
        display: "none",
      },
    },
    height: 42,
    padding: "8px 8px 8px 8px",
    textAlign: "left",
    fontWeight: 700,
    display: "flex",
    marginLeft: 5,
    minWidth: "fit-content",
    cursor: "pointer",
  },
  stopPresentationButton: {
    height: 40,
    width: 64,
    padding: "8px 8px 8px 11px",
    textAlign: "left",
    fontWeight: 700,
    cursor: "pointer",
    backgroundColor: "#ff475b",
    "&:hover": {
      color: "white",
      backgroundColor: "#cf4d4d",
    },
    '&:focus': {
      outline: 'thin solid black'
    },
    color: "white",
  },
  nextButton: {
    '&:focus': {
      outline: 'thin solid black'
    },
  },
  progressLabel: {
    minWidth: 45,
  },
  arrowIcon: {
    width: 15,
    height: 20,
    "&:focus": {
      outline: 'thin solid black',
    },
  },
  tagSection: {
    display: "flex",
    justifyContent: "space-around",
    width: "calc(100% - 50px)",
  },
  progressSection: {
    justifyContent: "flex-end",
    display: "flex",
    width: "inherit",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },
  nextBtn: {
    border: "1px solid #ccc",
    padding: 7,
    borderRadius: 5,
    marginLeft: 10,
    '&:focus': {
      outline: 'thin solid black'
    }
  },
  nextLbl: {
    marginLeft: 3,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  timerWrapper: {
    display: "flex",
    alignItems: "center",
    width: "80%",
    justifyContent: "center",
  },
  timeLabel: {
    color: props => props.timeColor,
    marginLeft: 10,
    fontSize: 20,
    fontWeight: 600,
  },
  tagIcon: {
    color: colors.themeGreen,
  },
}));


// Timer properties
var pause = false;
var secondsLeft = 100;
var countdown;

const calcTimeColor = () => {
  if (pause) {
    return "#8d8d8d";
  }

  return secondsLeft < 60 ? "#ff475b" : "#6eceb2";
};

const MetaIcons = ({
  user,
  parentNode,
  currentNode,
  contentful,
  curriculum,
  presentationMode,
  activity,
  createUsageLog,
  location,
  studentsSections,
  currentRole,
  activityStep,
  disabledNext,
  onNextClick,
  next,
  setActivityStep,
  setCurrentActivityInfo,
  setShowGoals,
  setIsPresentationMode,
  switchMobileMenu,
  setStartContentSectionTime,
  push,
  studentView,
  isOpenQuestionAnswerUpdated,
  setOpenQuestionAnswerUpdated,
  setDisabledNext,
  answeredQuestionsState,
  activityUnfinishedSections,
  onSectionClick,
}) => {
  
  const classes = useStyles({ timeColor: calcTimeColor() });

  const [isTimerOpen, setIsTimerOpen] = React.useState(false);
  const [timeLeft, setTimeLeft] = React.useState(null);
  const [isTimerRun, setIsTimerRun] = React.useState(false);
  const [paused, setPaused] = React.useState(null);
  const [isEscapeConfirmDialog, setEscapeConfirmDialog] = useState('');
  const [isUnfinishedSectionsOpen, setUnfinishedSectionsOpen] = useState(false);

  const onPresentationClick = () => {
    const { href, pathname } = window.location;
    createUsageLog({
      action: !presentationMode
        ? START_PRESENT_VIEW
        : STOP_PRESENT_VIEW,
      url: href,
      programId: `${currentRole.programId}`,
      collection: pathname,
    });

    if (!presentationMode) {
      setStartContentSectionTime(new Date());

      setIsTimerRun(false);
      clearInterval(countdown);
      pause = false;
      setPaused(false);
      secondsLeft = false;
    }

    const studentsSections = activity.contentSections
      && activity.contentSections.filter(e => !e.onlyVisibleToFacilitators);

    if (presentationMode && studentsSections?.length > 0) {
      const element = document.getElementById(studentsSections[activityStep].id);
      if (element) {
        element.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'start' });
        window.scrollBy(0, -230);
        window.history.pushState("", "", `#${studentsSections[activityStep].id}`);
      }
    }

    const currentActivity = !presentationMode
      ? {
        currentActivity: {
          userId: user.id.toString(),
          programId: currentRole.programId.toString(),
          contentSection: studentsSections && studentsSections.length > 0 ? studentsSections[activityStep].id : null,
          isViewed: true,
          title: activity.slug,
          path: location.pathname,
        },
        parentNode
      }
      : null;

    setCurrentActivityInfo(currentActivity);
    setShowGoals(false);
    switchMobileMenu({ leftMenuVisible: presentationMode });

    const isParentSet = parentNode.type === 'set' || parentNode.nodeType === 'set';
    if (isParentSet) {
       if (isOpenQuestionAnswerUpdated) {
        setEscapeConfirmDialog('isParentSetPush');
      } else {
        push(`/gwc${parentNode.path}`);
      }
    } else {
      if (isOpenQuestionAnswerUpdated) {
        setEscapeConfirmDialog('presentationMode');
      } else {
        setIsPresentationMode(!presentationMode);
        setActivityStep(0);
      }
    }
  };

  const onSetTimer = (values) => {
    if (countdown) {
      clearInterval(countdown);
    }

    secondsLeft = values.getMinutes() * 60 + values.getSeconds();
    timer();
    setIsTimerRun(true);
  };

  const timer = () => {
    countdown = setInterval(() => {
      if (secondsLeft < 1) {
        clearInterval(countdown);
        setTimeLeft(null);
        setIsTimerRun(false);
        return;
      }

      if (pause === true) {
        return;
      }

      secondsLeft--;
      let minutes = Math.floor(secondsLeft / 60);
      let seconds = Math.floor(secondsLeft % 60);

      let time = minutes ? `${minutes} m ${seconds} s` : `${seconds} s`;
      setTimeLeft(time);
    }, 1000);
  };

  if (!parentNode) return null;

  if (!currentNode) return null;

  if (
    currentNode.type !== "activity" &&
    currentNode.nodeType !== "activity"
  ) {
    return null;
  }

  if (
    !contentful ||
    !contentful.GlobalComponentQuery ||
    !curriculum.currentActivity
  )
    return null;

  const {
    estimatedTimeInMinutes,
    easyOfFacilitation,
    tags,
  } = curriculum.currentActivity;

  const getSectionName = (previousOrNext) => {
    const index = activityStep + previousOrNext;
    // header - regular section, heading - quiz, surveyName - survey
    return studentsSections[index].heading || studentsSections[index].header || studentsSections[index].surveyName;
  }

  const isLastStep =
    !studentsSections || activityStep === studentsSections.length - 1;

  let nextTooltipTitle;
  if (isLastStep) {
    const isAnsweredAllQuestions = answeredQuestionsState?.numberOfAnswered?.length === answeredQuestionsState?.totalQuestions;

    if (next && isAnsweredAllQuestions) {
      nextTooltipTitle = next.name;
    } else {
      nextTooltipTitle = 'Make sure to complete all parts of the activity';
    }
  } else {
    nextTooltipTitle =
      studentsSections.length > 0
        ? getSectionName(1)
        : null;
  }

  let previousToolTip;
  if (activityStep !== 0) {
    previousToolTip = studentsSections?.length > 0
    ? getSectionName(-1)
    : null;
  }

  const isFacilitator = currentRole.roleId === Roles.Facilitator && !studentView;
  const startTooltip = isFacilitator
    ? tooltipMsg.start
    : tooltipMsg.startStudents;
  
  const isCollection = parentNode.__typename === 'Collection';
  const showNextLabel = isCollection && isLastStep ? false : true;

  const onCloseUnfinishedSectionsModal = () => {
    setUnfinishedSectionsOpen(false);
  }

  const isImDoneText = isLastStep ? 'I\'m done!' : '';
  const isNextText = showNextLabel ? 'Next content section' : '';

  return (
    <Container
      className={
        presentationMode
          ? clsx(classes.containerWrapper, classes.iconsContainer)
          : classes.iconsContainer
      }
      style={{ minWidth: "100%", backgroundColor: "white" }}
    >
      <Container className={classes.metaContainer}>
        <div className={classes.presentationIconsWrapper}>
          {!presentationMode &&
            <TooltipWrapper
              role="tooltip"
              id="startTooltip"
              title={startTooltip}
              aria-label={startTooltip}
            >
              <div>
                <CustomButton
                  mode="secondary"
                  aria-describedby="startTooltip"
                  aria-label={`Start`}
                  className={classes.presentationButton}
                  onClick={() => onPresentationClick()}
                >
                  <div>
                    Start
                  </div>
                  <PlayArrowIcon />
                </CustomButton> 
              </div>
            </TooltipWrapper>
          }
          {presentationMode &&
            <TooltipWrapper
              title={tooltipMsg.stop}
              role="tooltip"
              id="stopTooltip"
              aria-label={tooltipMsg.stop}
            >
              <div>
                <TextCustomButton
                  mode={"secondary"}
                  aria-describedby="stopTooltip"
                  aria-label="Stop presentation mode"
                  className={classes.stopPresentationButton}
                  onClick={() => onPresentationClick()}
                >
                  <div>
                    <StopPresentationIcon />
                  </div>
                </TextCustomButton>
              </div>
            </TooltipWrapper>}
          {presentationMode && (
            <div className={classes.timerWrapper}>
              {presentationMode && !isTimerRun && (
                <TooltipWrapper
                  title={tooltipMsg.timer}
                  role="tooltip"
                  id="timerTooltip"
                  aria-label={tooltipMsg.timer}
                >
                  <div>
                    <TextCustomButton
                      mode="secondary"
                      aria-describedby="timerTooltip"
                      aria-label="Timer"
                      className={classes.nextBtn}
                      onClick={() => {
                        setIsTimerOpen(true);
                      }}
                    >
                      <TimerIcon />
                    </TextCustomButton>
                  </div>
                </TooltipWrapper>
              )}
              {isTimerRun && secondsLeft > 0 && !paused && (
                <TextCustomButton
                  className={classes.nextBtn}
                  aria-label="pause"
                  mode="secondary"
                  onClick={() => {
                    pause = true;
                    setPaused(true);
                  }}
                >
                  <PauseIcon />
                </TextCustomButton>
              )}
              {isTimerRun && paused && (
                <TextCustomButton
                  mode="secondary"
                  className={classes.nextBtn}
                  aria-label="play"
                  onClick={() => {
                    pause = false;
                    setPaused(false);
                  }}
                >
                  <PlayArrowIcon />
                </TextCustomButton>
              )}
              {isTimerRun && secondsLeft > 0 && (
                <TextCustomButton
                  className={classes.nextBtn}
                  mode="secondary"
                  aria-label="stop"
                  onClick={() => {
                    setIsTimerRun(false);
                    clearInterval(countdown);
                    pause = false;
                    setPaused(false);
                    secondsLeft = false;
                  }}
                >
                  <StopIcon />
                </TextCustomButton>
              )}
              {secondsLeft > 0 && (
                <div className={classes.timeLabel}>{timeLeft}</div>
              )}
            </div>
          )}
          {!presentationMode && (
            <div className={classes.tagSection}>
              {estimatedTimeInMinutes && !presentationMode && (
                <Box className={classes.metaBox}>
                  <AccessTimeIcon className={classes.tagIcon} />
                  <Typography className={classes.metaDetails}>
                    {formatEstimatedMinutes(estimatedTimeInMinutes)}
                  </Typography>
                </Box>
              )}
              {easyOfFacilitation && isFacilitator && !studentView && (
                <Box className={classes.metaBox}>
                  <SpeedIcon className={classes.tagIcon} />
                  <Typography className={classes.metaDetails}>
                    {easyOfFacilitation}
                  </Typography>
                </Box>
              )}
              {tags && (
                <Box className={classes.metaBox}>
                  <FolderOpenIcon className={classes.tagIcon} />
                  <Typography className={classes.metaDetails}>
                    {tags.join(", ")}
                  </Typography>
                </Box>
              )}
            </div>
          )}
        </div>
        {presentationMode && (
          <div className={classes.progressSection}>
            {activityStep !== 0 && (
              <TooltipWrapper
                style={{ minWidth: "fit-content" }}
                title={previousToolTip}
                role="tooltip" 
                id="previousTooltip"
                aria-label={previousToolTip}
              >
                <div>
                  <IconButton
                    aria-label="Back to previous content section"
                    aria-describedby="previousTooltip"
                    onClick={() => {
                      if (isOpenQuestionAnswerUpdated) {
                        setEscapeConfirmDialog('back');
                      } else {
                        setActivityStep(activityStep - 1);
                        setDisabledNext(false);
                      }
                    }}
                    className={classes.arrowIcon}
                  >
                    <ArrowBackIos className={classes.arrowIcon} />
                  </IconButton>
                </div>
              </TooltipWrapper>
            )}
            {studentsSections && (
              <span className={classes.progressLabel}>{`${activityStep + 1
                } of ${studentsSections.length}`}</span>
            )}
            <TooltipWrapper
              style={{ minWidth: "fit-content" }}
              title={nextTooltipTitle}
              role="tooltip" 
              id="nextTooltip"
              aria-label={nextTooltipTitle}
            >
              <div>
                <CustomButton
                  mode={isLastStep 
                    ? disabledNext
                      ? "disabled"
                      : "primary"
                    : "secondary"}
                  onClick={() => {
                    if (disabledNext && isLastStep) {
                      setUnfinishedSectionsOpen(true);
                      return;
                    }
                    if (isOpenQuestionAnswerUpdated) {
                      setEscapeConfirmDialog('forward');
                    } else {
                      onNextClick();
                    }
                  }}
                  className={classes.nextButton}
                  aria-describedby="nextTooltip"
                  aria-label={isImDoneText + isNextText}     
                >
                  {isLastStep && <span>I'M DONE!</span>}
                  {showNextLabel && <span className={classes.nextLbl}>NEXT</span>}
                  <ArrowForwardIosIcon className={classes.arrowIcon} />
                </CustomButton>
              </div>
            </TooltipWrapper>
          </div>
        )}
      </Container>
      <TimerModal
        isOpen={isTimerOpen}
        onSave={onSetTimer}
        onCloseModal={() => {
          setIsTimerOpen(false);
        }}
      />
      <UnfinishedSectionsModal
        isOpen={isUnfinishedSectionsOpen}
        onClose={onCloseUnfinishedSectionsModal}
        sections={activityUnfinishedSections}
        push={push}
        node={currentNode}
        onSectionClick={onSectionClick}
      />
      <PageLeaveModal
        onCancel={() => { setEscapeConfirmDialog(''); }}
        onConfirm={() => {
          setOpenQuestionAnswerUpdated(false);
          if (isEscapeConfirmDialog === 'back') {
            setActivityStep(activityStep - 1);
            setDisabledNext(false);
          } else if (isEscapeConfirmDialog === 'presentationMode') {
            setIsPresentationMode(!presentationMode);
            setActivityStep(0);
          } else if (isEscapeConfirmDialog === 'isParentSetPush') {
            push(`/gwc${parentNode.path}`);
          } else {
            onNextClick();
          }
          setEscapeConfirmDialog('');
        }}
        open={!!isEscapeConfirmDialog.length}
      />
    </Container>
  );
};

export default MetaIcons;