import React from "react";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import * as colors from "../../theme/colors";

import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { NavLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import TextButton from "../../components/text-button/text-button";
import { TooltipWrapper } from "../../components/tooltip/tooltip";
import { permissions } from "../../utils/permissions";

const titleStyles = makeStyles((theme) => ({
  titleBox: {
    width: "100%",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  titleContent: {
    margin: "auto",
    maxWidth: 1280,
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 10,
    paddingLeft: 24,
    paddingRight: 24,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  nameTitle: {
    color: colors.darkBlue100,
    fontSize: 32,
    fontWeight: "bold",
    marginTop: 5,
    [theme.breakpoints.down("xs")]: {
      fontSize: 22,
    },
    display: 'flex',
    alignItems: 'center',
  },
  certificateWrapper: {
    paddingLeft: 10,
    marginTop: 5,
  },
  certificateIcon: {
    height: 30,
  },
  linksBlock: {
    display: "flex",
    marginTop: 15,
    flexDirection: "column",
    justifyContent: "center",

    [theme.breakpoints.down("xs")]: {
      margin: "10px 5px 0 20px",
      overflow: "hidden",
      boxOrient: "vertical",
      lineClamp: 2,
      display: "-webkit-box",
      minHeight: "auto",
    },
  },
  breadCrumbLink: {
    color: colors.darkThemeGreen,
    fontWeight: "bold",
    fontSize: 14,
    textDecoration: "none",
    '&:hover': {
      color: colors.lightThemeGreen,
    },
  },
  breadCrumbLastLink: {
    color: colors.lightGrey,
    fontWeight: "bold",
    fontSize: 14,
    textDecoration: "none",
  },
  showGoalsBlock: {
    minHeight: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 15,

    [theme.breakpoints.down("md")]: {
      "& .showGoalsActionTitle": {
        display: "none",
      },
    },
  },
  showGoalsButton: {
    color: colors.darkBlue100,
    fontWeight: "bold",
    border: () => `1px solid ${colors.darkBlue100}`,
    borderRadius: 5,
    backgroundColor: "transparent",

    '&:hover': {
      backgroundColor: "transparent",
    },

    '& .MuiTypography-body1': {
      color: colors.darkBlue100,
      fontWeight: "bold",
    },

    [theme.breakpoints.down("xs")]: {
      padding: 8,
      minWidth: 30,

      "& .showGoalsLabel": {
        display: "none",
      },
    },
  },
  endIcon: {
    [theme.breakpoints.down("xs")]: {
      margin: 0,
    },
  },
  label: {
    height: 14,
    fontSize: 10,
    fontFamily: "'Roboto Mono',monospace",
    fontWeight: 500,
    cursor: "text",
    "& >span": {
      lineHeight: 0.1,
    },
    "&:hover": {
      cursor: "text",
    },
    marginLeft: 15,
    marginRight: 10,
    marginTop: 12,
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginTop: 0,
    },
    lineHeight: "0.5",
  },
  labelSmallScreen: {
    display: 'none',
    marginLeft: 15,
    [theme.breakpoints.down("xs")]: {
      display: 'block',
    },
  },
  regularLabel: {
    display: 'block',
    [theme.breakpoints.down("xs")]: {
      display: 'none',
    },
  },
  optionalLabel: {
    color: colors.white,
    backgroundColor: colors.lightGrey,
    '&:hover': {
      backgroundColor: colors.lightGrey,
      color: colors.white,
    },
  }
}));

const Title = ({
  chain,
  currentNode,
  labelFontColor,
  labelBackground,
  programRoleLabel,
  isFacilitatorOnlyAvailable,
  goals,
  materials,
  infoSet,
  showGoals,
  setShowGoals,
  nodeTheme,
  certificateRibbonIcon,
  isFacilitator,
  studentView,
}) => {
  const titleClasses = titleStyles({ themeFont: nodeTheme.f });
  const nodes = Object.values(currentNode ? currentNode.nodes : []);
  const isAllNestedContentFacilitatorsOnly = isFacilitator && !studentView && !!nodes.length && !nodes.some(e => e.permissionLevel !== permissions.facilitatorOnly);
  const folderWithNestedFacilitatorContent = isFacilitator && !studentView && currentNode.__typename === 'CurriculumFolder' && !nodes.some(node => Object.values(node.nodes).some(e => e.permissionLevel !== permissions.facilitatorOnly));
  const isActivity = currentNode.type === "activity" ||
    currentNode.nodeType === "activity";
  const { isOptional, endsInCertificate } = currentNode;
  const tooltipMsg = 'Earn a certificate by completing required activities';

  return (
    <Box className={titleClasses.titleBox}>
      <Box className={titleClasses.titleContent}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Box className={titleClasses.linksBlock}>
            <Breadcrumbs
              className={titleClasses.breadCrumbLink}
              separator=" / "
              aria-label="breadcrumb"
            >
              <NavLink
                className={titleClasses.breadCrumbLink}
                color="inherit"
                to={`/gwc`}
              >
                {'Curriculum'}
              </NavLink>
              {chain &&
                chain.map(
                  (c, index) =>
                    index !== chain.length - 1 && (
                      <NavLink
                        key={index}
                        id={c.id}
                        className={titleClasses.breadCrumbLink}
                        color="inherit"
                        to={`/gwc${c.path}`}
                      >
                        {c.name}
                      </NavLink>
                    )
                )}
              <Typography className={titleClasses.breadCrumbLastLink}>
                {currentNode.name}
              </Typography>
            </Breadcrumbs>
            <Box className={titleClasses.titleContainer}>
              <Typography className={titleClasses.nameTitle}>
                {currentNode && currentNode.name}
                {certificateRibbonIcon && endsInCertificate && <TooltipWrapper title={tooltipMsg}>
                  <Box className={titleClasses.certificateWrapper}>
                    <img className={titleClasses.certificateIcon} src={certificateRibbonIcon.src} alt={certificateRibbonIcon.alt} />
                  </Box>
                </TooltipWrapper>}
              </Typography>
              {(isFacilitatorOnlyAvailable || isAllNestedContentFacilitatorsOnly || folderWithNestedFacilitatorContent )&&
                <TextButton
                  tabIndex="-1"
                  className={clsx(titleClasses.label, titleClasses.regularLabel)}
                  style={{ color: labelFontColor, backgroundColor: labelBackground }}
                  label={programRoleLabel}
                />}
              {isOptional &&
                <TextButton
                  tabIndex="-1"
                  className={clsx(titleClasses.label, titleClasses.regularLabel, titleClasses.optionalLabel)}
                  label="Optional"
                />}
            </Box>
          </Box>
        </Box>
        {(goals || materials || infoSet) && isActivity && (
          <Box className={titleClasses.showGoalsBlock}>
            <Button
              disableElevation
              variant="contained"
              size="large"
              className={titleClasses.showGoalsButton}
              classes={{ endIcon: titleClasses.endIcon }}
              endIcon={
                showGoals ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )
              }
              onClick={() => {
                setShowGoals(!showGoals);
              }}
            >
              <Typography className="showGoalsLabel">
                <span className="showGoalsActionTitle">
                  {!showGoals ? "SHOW" : "HIDE"}
                </span>
                {" GOALS"}
              </Typography>
            </Button>
          </Box>
        )}
      </Box>
      {(isFacilitatorOnlyAvailable || isAllNestedContentFacilitatorsOnly || folderWithNestedFacilitatorContent) &&
        <TextButton
          tabIndex="-1"
          className={clsx(titleClasses.label, titleClasses.labelSmallScreen)}
          style={{ color: labelFontColor, backgroundColor: labelBackground }}
          label={programRoleLabel}
        />}
      {isOptional &&
        <TextButton
          tabIndex="-1"
          className={clsx(titleClasses.label, titleClasses.labelSmallScreen, titleClasses.optionalLabel)}
          label="Optional"
        />}
    </Box>
  );
};


export default Title;