import { createAction } from 'redux-act';

export const authorize = createAction();
export const authorizeParent = createAction();
export const authorizeChild = createAction();
export const unauthorize = createAction();
export const receiveAuthData = createAction();
export const invalidCredentials = createAction();
export const logout = createAction();
export const logoutSuccess = createAction();
export const receiveConferenceLink = createAction();

export const loadCurrentUser = createAction();
export const loadCurrentUserError = createAction();

export const currentUserLoaded = createAction();
export const currentUserUpdated = createAction();

export const changeRole = createAction();

export const setHome = createAction();
export const receivedHome = createAction();

export const updateChild = createAction();
export const createChild = createAction();

export const receivedChild = createAction();

export const receivedUserProgram = createAction();

export const recievedFacilitatorsConsent = createAction();

export const enrolledChild = createAction();

export const setParentConsent = createAction();
export const setChildReceived = createAction();
export const generateParentConsentPdf = createAction();
export const receivedParentConsent = createAction();