import axios from 'axios';
import * as tokenStorage from '../tokenStorage';
import store from "../store";
import { bindActionCreators } from 'redux';
import { logout } from '../actions/auth';

import { getToken, getRefreshToken } from '../tokenStorage';

const REFRESH_TOKEN_URL = '/auth/refreshtoken';

export const noCacheHeaders = {
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
};

export const axiosInstance = axios.create({
  baseURL: `${window._env_.REACT_APP_API_URL}`,
});

export function refreshToken(token) {
  return axiosInstance.post(REFRESH_TOKEN_URL, {
    refreshToken: token,
    accessToken: getToken(),
  }, {
    'Cache-Control': 'no-cache',
  });
}

axiosInstance.interceptors.request.use(config => {
  // add no cache headers to each request via axios;
  Object.keys(noCacheHeaders).forEach((key) => {
    config.headers[key] = noCacheHeaders[key];
  })

  // add Access-Control-Allow-Origin header with current origin url;
  // its needed to verify origin in gateway project to restrict cors requests;
  config.headers['Access-Control-Allow-Origin'] = window.location.origin;

	return config;
});

let isAlreadyFetchingAccessToken = false;

let subscribers = [];

axiosInstance.interceptors.response.use(
  async (res) => {
    if (isTokenExpiredError(res)) {
      return await resetTokenAndReattemptRequest(res, undefined);
    }
    return res;
  },
  async function (error) {
    const errorResponse = error.response;
    if (isTokenExpiredError(errorResponse)) {
      // skip if refresh token req failed
      if (error.config.url === REFRESH_TOKEN_URL) {
        return Promise.reject(error);
      }

      return await resetTokenAndReattemptRequest(errorResponse, error)
    }

    return Promise.reject(error);
  }
)
function isTokenExpiredError(res) {
  return res.status === 401;
}

async function resetTokenAndReattemptRequest(errorResponse, error) {
  try {
    const resetToken = getRefreshToken();
    if (!resetToken || !!errorResponse.config._retry) {
      return Promise.reject(error || errorResponse);
    }
    
    if (!errorResponse.config._retry) {
      errorResponse.config._retry = true;
    }
    
    const retryOriginalRequest = new Promise(resolve => {
      addSubscriber(accessToken => {
        if (errorResponse.config.headers.Authorization) {
          errorResponse.config = setHeadersForRetry(errorResponse.config, 'Authorization', accessToken);
        }

        if (errorResponse.config.headers.authorization) {
          errorResponse.config = setHeadersForRetry(errorResponse.config, 'authorization', accessToken);
        }

        resolve(axiosInstance(errorResponse.config));
      });
    });
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      try {
        const response = await refreshToken(resetToken);

        if (!response.data) {
          return Promise.reject(error);
        }

        const newToken = response.data.accessToken;
        tokenStorage.setToken(newToken);
        isAlreadyFetchingAccessToken = false;
        onAccessTokenFetched(newToken);
      } catch (err) {
        if (!!err.response && isTokenExpiredError(err.response)) {
          subscribers = [];
          isAlreadyFetchingAccessToken = false;
          logoutAction();

          return Promise.resolve(errorResponse);
        }

      }
    }
    return retryOriginalRequest;
  } catch (err) {
    return Promise.reject(err);
  }
}

function setHeadersForRetry(config, authKey, accessToken) {
  config.headers['Cache-Control'] = 'no-cache';
  config.headers['Pragma'] = 'no-cache';
  config.headers['Expires'] = '0';
  config.headers[authKey] = `Bearer ${accessToken}`;
  config._retry = true;

  return config;
}

function onAccessTokenFetched(access_token) {
  subscribers.forEach(callback => callback(access_token));
  subscribers = [];
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

const actions = bindActionCreators({ logout }, store.dispatch);
const logoutAction = (() => {
  actions.logout();
});