import React from "react";
import objectPath from "object-path";

import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { StyledFormHelper } from "../../../components/loginSignup/customFormHelper";

import * as colors from "../../../theme/colors";

const useStyles = makeStyles({
  container: {
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        // borderColor: colors.darkThemeGreen,
        border: () => `1px solid ${colors.lightThemeGreen}`,
      },
    },
  },
});

const TextInput = ({
  field,
  form: { errors, touched },
  label,
  helperText,
  Icon,
  errorIsHtml,
  className,
  maxLength,
  maxValue,
  minValue,
  maxLengthInfo,
  maxLengthStartingFrom,
  stepValue,
  ariaLabel,
  placeholder,
  ...props
}) => {
  const classes = useStyles();

  let error = errors[field.name];
  let isTouched = touched[field.name];

  if (field.name.indexOf("[") !== -1 || field.name.indexOf(".") !== -1) {
    const path = field.name.replace("[", ".").replace("]", "").split(".");
    error = objectPath.get(errors, path);
    isTouched = objectPath.get(touched, path);
  }

  const remainingLength =
    field.value && maxLength && maxLengthInfo
      ? maxLength - field.value.length
      : 0;
  const showRemainingLength = maxLengthStartingFrom
    ? maxLengthStartingFrom > remainingLength
    : true;

  return (
    <React.Fragment>
      <TextField
        {...props}
        className={clsx(classes.container, className)}
        name={field.name}
        onChange={field.onChange}
        error={!!error && isTouched}
        onBlur={() => {
          touched[field.name] = true;
        }}
        fullWidth
        helperText={
          error && isTouched ? (errorIsHtml ? "" : error) : helperText
        }
        variant={props.variant || "outlined"}
        value={field.value}
        label={label}
        inputProps={{
          step: stepValue ? parseInt(stepValue) : null,
          maxLength: maxLength,
          max: maxValue,
          min: minValue,
          'aria-label': ariaLabel,
        }}
        InputProps={{
          placeholder: placeholder,
          startAdornment: Icon ? (
            <InputAdornment position="start">
              <Icon />
            </InputAdornment>
          ) : null,
        }}
      />
      {maxLength && field.value && maxLengthInfo && showRemainingLength && (
        <StyledFormHelper
          helperText={`${maxLengthInfo.replace("${value}", remainingLength)}`}
          isHtml={true}
        />
      )}
      {error &&
        isTouched &&
        (errorIsHtml ? (
          <StyledFormHelper helperText={error} isError={true} isHtml={true} />
        ) : (
          ""
        ))}
    </React.Fragment>
  );
};

export default TextInput;
