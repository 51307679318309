import { combineReducers, createStore } from 'redux';
// import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';

import auth from './auth';
import changePassword from './changePassword';
import curriculum from './curriculum';
import forgotPassword from './forgotPassword';
import global from './global';
import previousRouter from './previous-router';
import signUp from './signUp';
import { unauthorize } from '../actions/auth';
import history from '../history';
import clubProfile from './clubProfile';
import userProfile from './userProfile';
import joinClub from './joinClub';
import announcements from './announcements';
import communityPartnerships from './communityPartnerhips';
import contentful from './contentful';
import notification from './notification';
import tracking from './tracking';
import attendance from './attendance';
import members from './members';
import messages from './messages';
import userActivitiesInfo from './userActivitiesInfo';
import projectGallery from './projectGallery';
import rating from './rating';
import calendar from './calendar';
import certificates from './certificates';
import openTextAnswers from './openTextAnswers';
import answerGroups from './answerGroups';
import quizesAnswers from './quizesAnswers';
import facilitatorApplication from './facilitatorApplication';
import facilitatorsConsentAgreement from './facilitatorsConsentAgreement';
import decisionMakers from './decisionMakers';

const getNewReducer = () =>
  combineReducers({
    router: connectRouter(history),
    ...Object.entries({
      previousRouter,
      auth,
      curriculum,
      clubProfile,
      forgotPassword,
      global,
      userProfile,
      signUp,
      joinClub,
      announcements,
      changePassword,
      communityPartnerships,
      contentful,
      notification,
      tracking,
      attendance,
      members,
      messages,
      userActivitiesInfo,
      projectGallery,
      rating,
      calendar,
      certificates,
      openTextAnswers,
      answerGroups,
      quizesAnswers,
      facilitatorApplication,
      facilitatorsConsentAgreement,
      decisionMakers
    }).reduce(
      (acc, [key, createReducer]) => ({
        ...acc,
        [key]: createReducer()
      }),
      {}
    )
  })

const reducer = getNewReducer()

export default (state, action) => {
  if (action.type === unauthorize.getType()) {
    return reducer(createStore(getNewReducer()).getState())
  }

  return reducer(state, action)
}
