import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import * as colors from "../../theme/colors";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  rootError: {
    display: "flex",
    border: `1px solid ${colors.redA400}`
  },
  fieldSet: {
    margin: 10
  },
  errorLabel: {
    color: colors.redA400
  },
  label: {
    zIndex: 2,
    padding: "0 2px"
  },
  subTitle: {
    color: theme.palette.primary.main,
    fontSize: 14,
  }
}));

const CheckboxGroup = ({ field, form, onValueChange, title, items, defaultItems }) => {
  const classes = useStyles();
  const error =
    form && field && form.errors[field.name] ? form.errors[field.name] : null;

  const defaultData = {};
  const values = defaultItems ? defaultItems.split(";") : [];
  if (values && items) {
    values.map((e) => {
      const itemExist = items.find(x => x.value === e);
      if (itemExist) {
        defaultData[e] = true;
      }
    });
  }

  const [itemsState, setItemsState] = React.useState(defaultData || {});

  const handleChange = name => ({ target: { checked } }) => {
    let result = {};
    if (checked === true) {
      result = {
        ...itemsState,
        [name]: checked,
      };
    } else {
      result = { ...itemsState, [name]: checked };
    }
    setItemsState(result);
    if (field && field.onChange) {
      const upValue = Object.keys(result).reduce((acc, curr) => {
        if (result[curr] === true) {
          return `${acc}${acc.length === 0 ? "" : ";"}${curr}`;
        }
        return acc;
      }, "");
      if (onValueChange) {
        onValueChange(upValue);
      }
      form.setFieldValue(field.name, upValue);
    }
  };
  return (
    <div className={error ? classes.rootError : classes.root}>
      <FormControl component="fieldset" className={classes.fieldSet}>
        <FormLabel component="legend" className={classes.subTitle}>
          {title}
        </FormLabel>
        <FormGroup>
          {items && items.map(({ label, value }) => (
            <FormControlLabel
              key={value}
              control={
                <Checkbox
                  color="primary"
                  checked={itemsState[value]}
                  onChange={handleChange(value)}
                  value={value}
                />
              }
              label={label}
            />
          ))}
        </FormGroup>
        {error && (
          <FormHelperText className={classes.errorLabel}>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};
export default CheckboxGroup;
