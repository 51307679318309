import { call, put } from "redux-saga/effects";

import {
    receivedQuizesAnswers,
    quizesAnswersError,
} from "../actions/quizesAnswers";
import * as quizApi from '../api/quiz';

export function* getQuizesAnswers({ payload }) {
  try {
    let { data } = yield call(quizApi.getAllLastQuizUserAttemptsByProgram, payload);

    yield put(receivedQuizesAnswers(data));
  } catch (e) {
    yield put(quizesAnswersError());
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}
