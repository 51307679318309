import axios from 'axios';

const googleApiUserInfoUrl = 'https://www.googleapis.com/oauth2/v1/userinfo';

export const getGoogleSsoData = async (token, onSuccess, onError) => {
  await axios
  .get(
    `${googleApiUserInfoUrl}?access_token=${token}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  )
  .then((res) => {
    onSuccess({
      accessToken: token,
      profileObj: res.data,
    });
  })
  .catch(() => onError());
}
