// unique filter for array
export const uniqueFilter = (value, index, self) => self.indexOf(value) === index;

export const findNodeBySlug = (item, slug) => {
  // nodes, sets, activities, contentSections

  if (!!item.slug && item.slug === slug) return item;

  if (!item.nodes) return null;

  for (let i = 0; i < Object.keys(item.nodes).length; i++) {
    return findNodeBySlug(item.nodes[i], slug);
  }
};

export const findSetById = (item, setId, parentNode) => {
  if (item.id && item.id === setId) {
    return {
      ...item,
      parentNode,
    };
  }

  const itemsToSearch = [
    ...(item.activities ? item.activities : []),
    ...(item.nodes && !parentNode  ? Object.values(item.nodes) : []),
    ...(item.sets ? item.sets : []),
  ];

  if (itemsToSearch.length === 0) return null;

  for (let i = 0; i < itemsToSearch.length; i++) {
    const result = findSetById(itemsToSearch[i], setId, item);

    if (result) return result;
  }

  return null;
};

export const findActivity = (item, activitySlug, activitySetSlug, parentNode) => {
  if (activitySlug && item.slug && item.slug === activitySlug) {

    // returning null if this is an activity from different activity set
    if (activitySetSlug && parentNode && parentNode.slug && parentNode.slug !== activitySetSlug) {
      return null;
    }

    return {
      ...item,
      parentNode,
    };
  }

  const itemsToSearch = [
    ...(item.activities ? item.activities : []),
    ...(item.nodes && !parentNode ? Object.values(item.nodes) : []),
    ...(item.sets ? item.sets : []),
  ];

  if (itemsToSearch.length === 0) return null;

  for (let i = 0; i < itemsToSearch.length; i++) {
    const result = findActivity(itemsToSearch[i], activitySlug, activitySetSlug, item);

    if (result) return result;
  }

  return null;
};

export const getTableData = (curriculum, uas) => {
  return getTableDataNew(curriculum, uas);
};

///////////////// updated calculation

export const getCurriculumCollections = (node) => {
  if (!node.nodes) return [];

  let collections = [];

  for (const item of Object.values(node.nodes)) {
    if (item.__typename === 'Collection') {
      collections.push(Object.assign({}, item));
    }

    if (item.nodes) {
      collections = [...collections, ...getCurriculumCollections(item)];
    }
  }

  return collections;
};

const calculateTimeSpent = (logs, activity) => {
  const timeSpent = logs
    .filter(l => activity.contentSections && activity.contentSections.find(cs => cs.id === l.contentSection))
    .map(r => r.duration).reduce((a, b) => a + b, 0)

  return timeSpent || 0;
};

const getActivityProgressUnderCollection = (collection, activity, uas) => {
  const result = {
    isDone: false,
    latestContentSection: null,
    numberOfCompletedContentSections: 0,
    contentSections: [],
    lastActivityLogDate: null,
    path: `/gwc${collection.path}/${activity.slug}`,
    shouldBeRendered: false,
    timeSpent: 0
  };

  const slugPart = `/${collection.slug}/${activity.slug}`;
  const logs = uas.filter(r => r.title === activity.slug && r.path && r.path.indexOf(slugPart) !== -1);

  if (logs.length > 0) {
    result.shouldBeRendered = true;
  }
  const completedLog = logs.find(r => r.isDone === true);

  if (completedLog) {
    if (activity.contentSections) {
      result.latestContentSection = activity.contentSections[activity.contentSections.length - 1];
    }
    result.isDone = true;
    result.numberOfCompletedContentSections = activity.contentSections ? activity.contentSections.length : 0;
    result.lastActivityLogDate = completedLog.date;
    result.contentSections = activity.contentSections ? activity.contentSections : [];

    result.timeSpent = calculateTimeSpent(logs, activity);
  } else {
    if (activity.contentSections) {
      for (const cs of activity.contentSections) {
        const csLogs = logs.filter(l => l.contentSection === cs.id);

        if (csLogs.length > 0) {
          result.contentSections.push({
            ...cs,
            timeSpent: csLogs.map(r => r.duration).reduce((a, b) => a + b, 0),
          });

          // setting last log date of the activity
          result.lastActivityLogDate = csLogs.sort((a, b) => (a.date < b.date) ? 1 : -1)[0].date;
          result.latestContentSection = cs;
        }
      }

      result.timeSpent = result.contentSections.map(r => r.timeSpent).reduce((a, b) => a + b, 0);
    } else {
      // no content section
    }
  }

  return result;
};

const getActivityProgress = (collection, set, activity, uas) => {
  const result = {
    isDone: false,
    latestContentSection: null,
    numberOfCompletedContentSections: 0,
    contentSections: [],
    lastActivityLogDate: null,
    path: `/gwc${collection.path}${set ? `/${set.slug}` : ''}/${activity.slug}`,
    timeSpent: 0
  };

  const slugPart = `${collection.slug}${set ? `/${set.slug}` : ''}/${activity.slug}`;

  const logs = uas.filter(r => r.title === activity.slug && r.path && r.path.indexOf(slugPart) !== -1);

  const completedLog = logs.find(r => r.isDone === true);
  if (completedLog) {
    result.isDone = true;
    result.latestContentSection = activity.contentSections[activity.contentSections.length - 1];
    result.numberOfCompletedContentSections = activity.contentSections.length;
    result.lastActivityLogDate = completedLog.date;
    result.contentSections = activity.contentSections;

    result.timeSpent = calculateTimeSpent(logs, activity);
  } else {
    // getting content sections ids

    if (activity.contentSections) {
      for (const cs of activity.contentSections) {
        const csLogs = logs.filter(l => l.contentSection === cs.id);

        if (csLogs.length > 0) {
          result.contentSections.push({
            ...cs,
            timeSpent: csLogs.map(r => r.duration).reduce((a, b) => a + b, 0),
          });

          // setting last log date of the activity
          result.lastActivityLogDate = csLogs.sort((a, b) => (a.date < b.date) ? 1 : -1)[0].date;
          result.latestContentSection = cs;
        }
      }

      result.timeSpent = result.contentSections.map(r => r.timeSpent).reduce((a, b) => a + b, 0);
    } else {
      // no content sections
    }
  }

  return result;
};

const getTableDataNew = (curriculum, uas) => {
  if (uas.length === 0) {
    return [];
  }

  // getting all collections from curriculum
  const collections = getCurriculumCollections(curriculum);

  // loop through collections and populating progress for sets and activities
  for (let col of collections) {
    if (!col.sets) continue;
    const originalSets = [...col.sets ];
    col.sets = [];

    for (let oset of originalSets) {
      let set = Object.assign({}, oset);
      // if (set.__typename === 'ActivitySet') {
      if (set.type === 'set') {
        set.path = `/${col.slug}/${set.slug}`;
        set.pathWithFolder = col.nodeType === 'folder' ? `${col.path}/${set.slug}` : null;

        if (set.activities) {
          set.latestActivity = set.activities[0];

          const originalActivities = [...set.activities];
          set.activities = [];

          for (let act of originalActivities) {
            act.logs = getActivityProgress(col, set, act, uas);

            if (!set.shouldBeRendered && act.logs.latestContentSection) {
              set.shouldBeRendered = true;
            }

            if (act.logs.latestContentSection && new Date(act.logs.lastActivityLogDate) > new Date(set.latestActivity.logs.lastActivityLogDate)) {
              set.latestActivity = act;
              set.shouldBeRendered = true;
            }

            set.activities.push(Object.assign({}, act));
          }

        }

      } else if (set.type === 'activity') {
        // handling activity under collection
        set.path = `/${col.slug}/${set.slug}`;
        set.pathWithFolder = col.nodeType === 'folder' ? `${col.path}/${set.slug}` : null;
        set.logs = getActivityProgressUnderCollection(col, set, uas);
      }
      col.sets.push(Object.assign({}, set));
    }
  }

  return collections;
};

export const getOpenTextContentSections = (curriculum) => {
  let openTextSections = [];
  if (curriculum.nodes) {
    getNodesContentSections(curriculum, openTextSections);
  }

  return openTextSections;
};

export const getIsUserAnswersExists = (curriculum, answers) => {
  if (!Object.keys(answers).length) return false;

  const collections = getCurriculumCollections(curriculum);
  return collections.some(collection => {
    const openTextContentSections = getOpenTextContentSections(collection);
    return openTextContentSections.some(answer => answers && !!answers[`${answer.activity.path}-${answer.contentSection.id}`]);
  });
}

const getNodesContentSections = (item, openTextSections, collection = {}) => {
  if ((item.type === 'activity' || item.nodeType === 'activity') && !!item.contentSections) {
    const csSections = item.contentSections.filter(e => e.isOpenTextAnswer);

    csSections.forEach((contentSection) => {
      openTextSections.push({ contentSection, activity: item, collection: {...collection} });
    });
  } else if (item.nodes) {
    if (item?.__typename === "Collection") {
      collection = {...collection, ...{ id: item.id, name: item.name }}
    }
    Object.values(item.nodes).forEach((node) => {

      getNodesContentSections(node, openTextSections, collection);
    });
  }
  
  return;
}

export const getMapOfGroups = (allContentSections) => {
  return Array.isArray(allContentSections) &&
    allContentSections.reduce((groupOpenTextSections, answer) => {
    if (answer?.contentSection?.contentSectionGroup) {
      const groupId = `${answer.contentSection.contentSectionGroup.id}-${answer.activity.id}-${answer.collection?.id}`;
      groupOpenTextSections[groupId] = groupOpenTextSections[groupId] || [];
      groupOpenTextSections[groupId].push(answer);
    }
    return groupOpenTextSections;
  }, {});
}
