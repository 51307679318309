import React from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";

import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import { HQModal } from "../HQModal";
import TextInput from "../../../components/TextInput";
import * as colors from "../../../theme/colors";
import CustomButton from "../../../components/customButton/customButton";
import { programAddressValidationObj } from "../../../utils/programHelper";
import { PageLeaveModal } from "../pageLeaveWarning";

const useStyles = makeStyles({
  marginTop30: {
    marginTop: 30
  },
  buttonGroup: {
    marginTop: "30px",
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none",
    fontSize: 16,
  },
  button: {
    borderRadius: 5,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: colors.grey400,
    color: "white",
    "&:hover": {
      backgroundColor: colors.grey500,
    }
  },
  submitButton: {
    borderRadius: 5,
    backgroundColor: colors.tealA700,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: colors.teal500,
    }
  }
});

export const ShippingAddressModal = ({ program, isOpen, onSave, onCloseModal }) => {
  const classes = useStyles();
  const [isEscapeConfirmDialog, setEscapeConfirmDialog] = React.useState(false);
  const [isDataChanged, setDataChanged] = React.useState(false);

  return (
    <HQModal title="Shipping Address" isOpen={isOpen} onClose={() => isDataChanged ? setEscapeConfirmDialog(true) : onCloseModal()}>
      <DialogContent className={classes.dialogContent}>
        <Formik
          initialValues={{
            shippingCity: program.shippingCity || "",
            shippingStreet: program.shippingStreet || "",
            shippingState: program.shippingState || "",
            shippingZipCode: program.shippingZipCode || "",
          }}
          validationSchema={Yup.object(programAddressValidationObj)}
          validate={() => {
            setDataChanged(true);
          }}
          onSubmit={values => {
            onSave(values);
            setDataChanged(false);
            onCloseModal();
          }}
        >
          <Form>
            <Field
              name="shippingStreet"
              type="text"
              component={TextInput}
              label="Shipping Street"
            />
            <Field
              name="shippingCity"
              type="text"
              component={TextInput}
              label="Shipping City"
              className={classes.marginTop30}
            />

            <Field
              name="shippingZipCode"
              type="text"
              component={TextInput}
              label="Shipping Zip Code"
              className={classes.marginTop30}
            />
            <Field
              name="shippingState"
              type="text"
              component={TextInput}
              label="Shipping State"
              className={classes.marginTop30}
            />
            <div className={classes.buttonGroup}>
              <CustomButton
                mode="textButton"
                onClick={() => isDataChanged ? setEscapeConfirmDialog(true) : onCloseModal()}
                label={"Cancel"}
              />
              <CustomButton
                mode="primary"
                type="submit"
                label={"Save"}
              />
            </div>
          </Form>
        </Formik>
      </DialogContent>
      <PageLeaveModal
        onConfirm={() => { setDataChanged(false); setEscapeConfirmDialog(false); onCloseModal(); }}
        onCancel={() => { setEscapeConfirmDialog(false); }}
        open={isEscapeConfirmDialog}
      />
    </HQModal>
  );
};
