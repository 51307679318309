import { createAction } from 'redux-act';

export const createAnswer = createAction();
export const receivedAnswer = createAction();
export const createAnswerError = createAction();

export const getMyAnswersByProgram = createAction();
export const receivedMyAnswersByProgram = createAction();
export const getMyAnswersByProgramError = createAction();

export const getUserAnswersByProgram = createAction();
export const receivedUserAnswersByProgram = createAction();
export const getUserAnswersByProgramError = createAction();

export const getAnswersForActivity = createAction();
export const receivedAnswersForActivity = createAction();

export const getCommentsByAnswer = createAction();
export const receivedCommentsByAnswer = createAction();
export const getCommentsByAnswerError = createAction();

export const getAllAnswersForProgram = createAction();
export const receivedAllAnswersForProgram = createAction();

export const addComment = createAction();
export const receiveAddedComment = createAction();
export const addCommentError = createAction();

export const updateComment = createAction();
export const receiveUpdatedComment = createAction();
export const updateCommentError = createAction();

export const updateStatus = createAction();
export const receivedUpdatedStatus = createAction();

export const getCommentsByUser = createAction();
export const receiveCommentsByUser = createAction();
export const getCommentsByUserError = createAction();

export const deleteComment = createAction();
export const receivedDeletedComment = createAction();
export const deleteCommentError = createAction();

export const resetAnswerProgress = createAction();

export const getCommentsForAnswerByUser = createAction();
export const getCommentsForAnswerByUserError = createAction();
export const receiveCommentsForAnswerByUser = createAction();
