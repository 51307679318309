import React, { useEffect, useState } from "react";

import { push as pushToRoute } from "connected-react-router";

import * as authActions from "../../../actions/auth";
import * as contentfulActions from "../../../actions/contentful";

import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import { connectTo } from "../../../utils/generic";
import * as colors from '../../../theme/colors'

import ContentSection from "../../Curriculum/ContentSection/ContentSection";
import { Box, Typography } from "@material-ui/core/";

import backLinkImage from "../../../images/arrow-back-icon.svg";
import navBackground from "../../../images/navBackground.png";

import { Link, NavLink } from "react-router-dom";
import { useTitle } from "../../../utils/pageTitles";
import { userIsAdult, userIsNotAStudent } from "../../../utils/userUtils";

const useStyles = makeStyles({
  root: {},
  content: {
    margin: "auto",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    maxWidth: "95%",
  },
  header: {
    minHeight: 100,
    backgroundImage: `url(${navBackground})`,
    backgroundRepeat: "repeat-x",
    display: "flex",
    backgroundColor: "#0D9C90",
    alignItems: "center",
    justifyContent: "left",
  },
  headerContainer: {
    display: "flex",
    height: 100,
    alignItems: "center",
    margin: "auto auto auto 100px",
  },
  headerText: {
    color: "white",
    margin: "0px 0px 0px 10px",
    fontSize: "2.625em",
    fontWeight: 900,
  },
  resetLink: {
    color: colors.darkBlack,
    fontWeight: "bold",
    textDecoration: "none",
  },
  agePolicyCard: {
    padding: "20px",
    marginTop: "15px",
  },
});


const stage = 'next';

const StartClub = ({ user, contentful, loadHomePageContent }) => {
  useTitle("apply");

  const classes = useStyles();

  const [contentSections, setContentSections] = useState(null);
  const [isDomestic, setIsDomestic] = useState(null);

  // if the user is over 18 or is not a student - show content
  const showContent = () => {
    return userIsAdult(user) || userIsNotAStudent(user);
  }

  useEffect(() => {
    if (!contentful.HomePageQuery) {
      loadHomePageContent();
      return;
    }

    const {
      HomePageQuery: { contentSections },
      hqConfig: { domesticCountryId },
    } = contentful;

    let sections;
    const domain = window.location.host.split('.')[0];

    // TO DO: Temporary added restriction for stage env
    if(domain === stage) {
      //TO DO :  Set up stab links for testing on stage env
      sections = [
        {
          id: "3rTMYzcWE0mgCcY2y2sSQc",
          type: "surveys",
          surveyName: "Clubs Application",
          surveyDescription: null,
          surveyUrl:
            "https://www.surveygizmo.com/s3/5538080/gwcclubsapplication5",
          salesforceId: false,
          hqId: false,
          programId: false,
          userBasicData: true,
          height: 800,
          ctaText: "Open in a new window",
          isDomestic: true,
          isEligibleForInternationalApplication: false,
          hideLanguageSelector: true,
        },
        {
          id: "461aObiC9ES1rU5uymRAoD",
          type: "surveys",
          surveyName: "International Clubs Application ",
          surveyDescription: null,
          surveyUrl:
            "https://www.surveygizmo.com/s3/5155564/gwcinternationalclubsapp3",
          salesforceId: false,
          hqId: false,
          programId: false,
          userBasicData: true,
          height: 800,
          ctaText: "Open in a new window",
          isDomestic: false,
          isEligibleForInternationalApplication: true,
          hideLanguageSelector: true,
        },
      ];
    } else {
      sections = contentSections;
    }

    setContentSections(sections);
    setIsDomestic(user.countryId === parseInt(domesticCountryId, 10));
  }, [user, contentful]);

  const section = contentSections
    ? contentSections.find((e) => e.isDomestic === isDomestic)
    : null;

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box className={classes.headerContainer}>
          <Box>
            <NavLink className={classes.backLink} to={`/gwc-club-application`}>
              <img src={backLinkImage} alt="Back" />
            </NavLink>
          </Box>
          <Box>
            <Typography variant="h4" className={classes.headerText} sel="header">
              {section ? section.surveyName : ""}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box className={classes.wrapper}>
        {Boolean(user) && showContent() ? (
          section && <ContentSection key={section.id} item={section} />
        ) : (
          <Card className={classes.agePolicyCard}>
            We're sorry. You must be 18 or older to apply to start a Club. Click{" "}
            <Link to="/home" className={classes.resetLink}>
              My Programs
            </Link>{" "}
            to return to your homepage.
          </Card>
        )}
      </Box>
    </Box>
  );
};

export default connectTo(
  (state) => {
    return {
      user: state.auth.user,
      contentful: state.contentful,
    };
  },
  { push: pushToRoute, ...authActions, ...contentfulActions },
  StartClub
);