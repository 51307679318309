import React, { useState } from "react";

import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";

import * as colors from "../../../../theme/colors";

import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CheckCircle from '@material-ui/icons/CheckCircle';

import { TooltipWrapper } from "../../../../components/tooltip/tooltip";
import TextCustomButton from "../../../../components/text-button/text-button";
import CopyLinkButton from '../../../../components/copyLinkButton/copyLinkButton';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px 15px 15px 15px",
  },
  title: {
    fontSize: 16,
    textTransform: "uppercase",
    fontWeight: 700,
    display: "flex",
  },
  contentLabel: {
    fontSize: 14,
    wordBreak: "break-all",
    color: colors.darkThemeBlueGray,
    fontWeight: "normal",
  },
  emailCopy: {
    width: 1,
    height: 1,
    opacity: 0,
  },
  copyBtn: {
    fontSize: 12,
    fontWeight: "normal",
    cursor: "pointer",
  },
  contentBox: {
    marginBottom: 15,
  },
  content: {
    padding: "0 0 0 4px",
    "&:last-child": {
      paddingBottom: 0,

      [theme.breakpoints.down("xs")]: {
        wordBreak: "break-all",
      },
    },
  },
  icon: {
    color: colors.darkThemeGreen,
    fontSize: '1.25rem',
  },
  tooltipText: {
    fontSize: "1em",
  },
  memberCountWrapper: {
    marginRight: 5,
  },
  card: {
    padding: 10,
  },
  infoWrapper: {
    display: 'flex',
    justifyContent: "space-between",
  },
  textWrapper: {
    display: "flex",
  },
  divider: {
    width: "100%",
  },
  info: {
    paddingBottom: 5,
    paddingTop: 5,
    marginRight: 5,
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: 14,
  },
  text: {
    paddingBottom: 5,
    paddingTop: 5,
    fontSize: 14,
  },
  labelBox: {
    paddingBottom: 5,
    paddingTop: 5,
    fontSize: 14,
    fontWeight: 700,
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 0,
  },
  exitBtn: {
    height: 42,
    color: colors.darkThemeGreen,
  },
  chip: {
    fontSize: 12,
    fontWeight: 500,
    color: colors.grayTheme,
    fontFamily: "'Roboto Mono',monospace",
    marginLeft: 10,
  },
  cardHeader: {
    paddingLeft: 0,
  },
}));

const RenderFacilitators = ({ item, index }) => {
  const classes = useStyles();

  return (
    <Box key={index} className={classes.contentBox}>
      <Box className={classes.contentWrapper}>
        <Box className={classes.boxContent}>
          <Typography sel = "facilitator"
            className={classes.labelBox}
          >
            {`${item.firstName} ${item.lastName}`}
          </Typography>

          <Box className={classes.boxContent}>
            {item.email && <CopyLinkButton link={item.email} textClass={classes.contentLabel} />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const tooltipMsg = (status, decisionMaker, classes, labels) => {
  switch (status) {
    case 'Application In Review':
      return labels.tooltipMessagesInReview;
    case 'In Review':
      return labels.tooltipMessagesInReview;
    case 'Pending Applicant Verification':
      return labels.tooltipMessagesPendingApplicantVerification;
    case 'Pending PA':
      return labels.tooltipMessagesPendingPa?.replace("{{decisionMaker}}", decisionMaker);
    case 'Pending Facilitators':
      return labels.tooltipMessagesPendingFacilitators;
    default:
      return '';
  }
};

const identifyInactiveStatus = (status) => {
  switch (true) {
    case (status === 'Pending Facilitators' || status === 'Pending Final Approval' || status === 'Pending Applicant Verification' || status === 'Pending PA' || status === 'Application In Review' || status === 'Application Incomplete' || status === 'In Review'):
      return 'PENDING';
    case (status === 'Rejected'):
      return 'REJECTED';
    case (status === 'Withdrawn' || status ==='Withdrawn - COVID'):
      return 'WITHDRAWN';
    case (status === 'Dropped'):
      return 'DROPPED';
    case (status === 'Completed'):
      return 'COMPLETED';
    case (status === 'Active'):
      return 'ACTIVE';
  };
};

const ClubCard = ({ club, labels }) => {
  const classes = useStyles();
  const [showFacilitators, setShowFacilitators] = useState(false);

  const labelStatus = identifyInactiveStatus(club.detailedStatus);
  const pendingTooltip = tooltipMsg(club.detailedStatus, club.decisionMaker, classes, labels);

  const formattedDate = !!club.launchDate && moment(club.launchDate).format('MMM DD, YYYY');
  const filteredFacilitators = club.facilitatorsInProgram
    .map(e => e.user)
    .filter(facilitator => !!facilitator && facilitator.email !== club.facilitatorEmail);

  return (
    <Grid item key={club.id} xs={12} sm={6} md={4} lg={4} xl={4} className={classes.card}>    
      <Card className={classes.root}>
        <CardHeader className={classes.cardHeader}
          title={!club.renewal ? <Typography className={classes.title}>{club.name}</Typography> :
            <Typography className={classes.title}>{club.name} 
              <Box className={classes.tag}>
                <TooltipWrapper title='Renewed' >
                <Chip 
                  size="small" 
                  label='R'
                  className={classes.chip}
                />
                </TooltipWrapper>
              </Box>
            </Typography>}
        />
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Box className={classes.contentWrapper}>
              <Box className={classes.boxContent}>
                <Box className={classes.infoWrapper}>
                  {club.sfCurriculumType && <Box className={classes.textWrapper}>
                    <Typography className={classes.info}>
                      {'CURRICULUM:  '}
                    </Typography>
                    <Typography className={classes.text}>{club.sfCurriculumType}</Typography>
                  </Box>}
                  <Typography className={classes.info}>
                    {club.code}
                  </Typography>
                </Box>
                <Box className={classes.infoWrapper}>
                  {club.launchDate && <Box className={classes.textWrapper}>
                    <Typography className={classes.info}>
                      {'START:  '}
                    </Typography>
                    <Typography className={classes.text}>{formattedDate}</Typography>
                  </Box>}
                  <TooltipWrapper title={pendingTooltip} interactive >
                    <Typography className={classes.info} >
                      {labelStatus}
                    </Typography>
                  </TooltipWrapper>
                </Box>
                {club.participantsCount < 3 ? ( <Box className={classes.textWrapper}>
                  <Typography className={classes.info}>
                    {'MEMBERS:  '}
                  </Typography>
                  <Typography className={classes.text}>{club.participantsCount}</Typography>
                </Box>) : 
                (<Grid container direction="row" alignItems="center">
                  <Grid item>
                    <Box className={classes.memberCountWrapper}>
                      <Box className={classes.textWrapper}>
                        <Typography className={classes.info}>
                          {'MEMBERS:  '}
                        </Typography>
                        <Typography className={classes.text}>{club.participantsCount}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item>
                    <CheckCircle className={classes.icon} />
                  </Grid>
                </Grid>)}

              {(club.facilitatorEmail || club.decisionMaker) && <Divider className={classes.divider} />}
              <Box className={classes.boxContent}>
                {club.decisionMaker && (
                  <Box>
                    <Typography className={classes.info}>
                        DECISION MAKER
                    </Typography>
                    <Typography className={classes.labelBox}>
                      {club.decisionMaker}
                    </Typography>
                    <CopyLinkButton textClass={classes.contentLabel} link={club.decisionMakerEmail} />
                    </Box>
                  )}

                  {club.facilitatorEmail && (
                    <Box style={{ marginBottom: 10 }}>
                      <Typography className={classes.info}>
                          FACILITATOR(S)
                      </Typography>
                        <Typography className={classes.labelBox}>
                        {`${club.facilitatorFirstName} ${club.facilitatorLastName}`}
                      </Typography>
                      <CopyLinkButton textClass={classes.contentLabel} link={club.facilitatorEmail} />
                    </Box>
                  )}
                  {showFacilitators && filteredFacilitators.map((facilitator, index) => {
                    return <RenderFacilitators item={facilitator} index={index} />;
                  })}
                </Box>
              </Box>
            </Box>
          </CardContent>
          {club.facilitators.length > 1 && <CardActions className={classes.btnContainer}>
            {!showFacilitators ? <TextCustomButton
              className={classes.exitBtn}
              mode="secondary"
              onClick={() => setShowFacilitators(true)}
              label={`VIEW ${filteredFacilitators.length} MORE`}
            /> : 
            <TextCustomButton
              className={classes.exitBtn}
              mode="secondary"
              onClick={() => setShowFacilitators(false)}
              label={`SHOW LESS`}
            />
            }
          </CardActions>}
        </div>
      </Card>
    </Grid>
  );
};

export default ClubCard;