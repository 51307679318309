import React from "react";

import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as colors from "../../theme/colors";
import clsx from "clsx";
import ArrowWave from "../arrow-wave/arrow-wave";

const useStyles = makeStyles({
  btnContainer: {
    backgroundColor: (props) => props.backgroundColor,
    border: 0,
    boxSizing: "border-box",
    color: (props) => props.mainColor,
    fontWeight: 700,
    fontSize: 16,
    position: "relative",
    verticalAlign: "middle",
    "&:hover": {
      color: (props) => props.onHoverColor,
      backgroundColor: (props) => props.onHoverBackgroundColor,
    }
  },
});

const styleTypes = {
  primary: {
    mainColor: colors.darkThemeGreen,
    backgroundColor: "transparent",
    onHoverColor: colors.darkThemeGreen,
    onHoverBackgroundColor: "transparent",
  },
  secondary: {
    mainColor: colors.darkThemeBlue,
    backgroundColor: "transparent",
    onHoverColor: colors.darkThemeBlue,
    onHoverBackgroundColor: "transparent",
  },
  menuItem: {
    mainColor: colors.dartThemeBlueGray,
    backgroundColor: "transparent",
    onHoverColor: colors.darkThemeGreen,
    onHoverBackgroundColor: "transparent",
  },
  activeItem: {
    mainColor: colors.darkThemeBlue,
    backgroundColor: colors.lightThemeGreen,
    onHoverColor: colors.darkThemeBlue,
    onHoverBackgroundColor: colors.darkThemeGreen,
  },
  disabled: {
    mainColor: colors.darkGrey100,
    backgroundColor: colors.white,
    onHoverColor: colors.greyLight2,
    onHoverBackgroundColor: colors.white,
  },
};

const getStyles = (disabled, isActive, mode) => {
  if (!isActive) {
    return disabled ? styleTypes.disabled : styleTypes[mode];
  }
  return styleTypes.activeItem;
}

const TextButton = ({
  mode = "primary",
  endIcon,
  isArrowWave,
  className,
  disabled,
  onClick,
  label,
  startIcon,
  isActive,
  ...props
}) => {
  const classes = useStyles(getStyles(disabled, isActive, mode));
  const [active, setActive] = React.useState(false);

  return (
    <Button
      aria-label={label}
      {...props}
      startIcon={startIcon}
      onClick={onClick}
      disableTouchRipple={true}
      onMouseOver={() => setActive(true)}
      onMouseOut={() => setActive(false)}
      endIcon={isArrowWave ? <ArrowWave isActive={active} fill={disabled ? styleTypes.disabled.mainColor : colors.darkThemeGreen}/> : endIcon}
      className={className ? clsx(classes.btnContainer, className) : classes.btnContainer}
    >
      {<span aria-hidden="true">{label}</span>} {props.children}
    </Button>
  );
};

export default TextButton;
