import React, { useState, useRef, useEffect } from "react";

import {
  Box,
} from "@material-ui/core/";

import { makeStyles } from "@material-ui/core/styles";
import * as colors from "../../../theme/colors";
import { Formik, Form } from "formik";
import { createEmptyEditor, exportEditor } from '../../../components/textEditor/utils';
import Editor from '../../../components/textEditor';
import { StyledFormHelper } from '../../../components/loginSignup/customFormHelper';
import CustomButton from "../../../components/customButton/customButton";
import { PageLeaveModal } from '../../../components/modals/pageLeaveWarning';
import { get } from 'lodash';

const maxMsgLength = 1200;

const useStyles = makeStyles(() => ({
  answerLabel: {
    color: colors.lightThemeGrey,
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "uppercase",
    display: "flex",
  },
  textAreaInput: {
    width: "100%",
    maxWidth: "900%",
    resize: "none",
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: () => `2px solid ${colors.lightThemeGreen}`,
      },
    },
    "& .MuiOutlinedInput-root": {
      fontSize: 14,
    },
    "&:focus": {
      outlineColor: colors.lightThemeGreen,
    }
  },
  submitButton: {
    marginLeft: 20,
  },
  formContainer: {
    display: "flex",
    paddingTop: 10,
  },
  messageArea: {
    'resize': 'none',
    width: '100%',
    fontSize: 16,
  },
}))

export const ReSubmitAnswer = ({
  item,
  questionName,
  onSubmit,
  onCancel,
  groupAnswer,
  history,
}) => {
  const classes = useStyles();
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [isEscapeConfirmDialog, setEscapeConfirmDialog] = useState(false);
  const [isDataChanged, setDataChanged] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);

  const unblockRef = useRef(null);

  useEffect(() => {
    unblockRef.current = history.block((location) => {
      setNextLocation(location);
      setEscapeConfirmDialog(true);

      return !isDataChanged;
    });

    return () => {
      unblockRef.current && unblockRef.current();
  };
  }, [isDataChanged, isEscapeConfirmDialog]);

  const validateContent = (value) => {
    if (!value || value.length == 0) {
      setDisabledSubmit(true);
      return;
    }

    if (value.length > maxMsgLength) {
      setDisabledSubmit(true);
      return `Answer should contain less ${maxMsgLength} characters`;
    }

    setDisabledSubmit(false);
    return null;
  };

  return (
    <Box>
      <Formik
        initialValues={{
          content: createEmptyEditor(),
        }}
        validateOnChange={true}
        validateOnBlur={false}
        validate={(values) => {
          let errors = {};
          if (values.content.getCurrentContent().getPlainText().length > maxMsgLength) {
            setDisabledSubmit(true);
            setDataChanged(false);
            errors.body = `Answer should contain less than ${maxMsgLength} characters`;
          }
          if (!values.content || !values.content.getCurrentContent().getPlainText().trim().length) {
            setDisabledSubmit(true);
            setDataChanged(false);
          } 
          else {
            setDisabledSubmit(false);
            setDataChanged(true);
          }
          return errors;
        }}
        onSubmit={(values) => {
          const currentQuestion = get(groupAnswer, ['contentSection'], {});
          const existingAnswerForSomeQuestion = get(item, ['0'], {});

          const answerGroupId = get(currentQuestion, ['contentSectionGroup', 'id']);
          const questionGroupName = get(currentQuestion, ['contentSectionGroup', 'name']);
          const groupRequiredGrading = get(currentQuestion, ['contentSectionGroup', 'requiredGrading']);
          const questionsIds = get(currentQuestion, ['contentSectionGroup', 'questionsIds'], []);
          const totalQuestions = questionsIds.length;
          const leftNotAnsweredQuestions = questionsIds.filter(questionId => !(Array.isArray(item) && item.some(answer => answer?.question === questionId)))

          const isLastQuestionInGroup = leftNotAnsweredQuestions?.length === 1 &&
            leftNotAnsweredQuestions[0] === currentQuestion?.id;
          let body = {};
          const editorToHtml = exportEditor(values.content);
          if (answerGroupId) {
            body = {
              collection: existingAnswerForSomeQuestion.collection,
              question: currentQuestion.id,
              set: existingAnswerForSomeQuestion.set,
              activity: groupAnswer?.activity?.id,
              path: groupAnswer?.activity?.path,
              programId: existingAnswerForSomeQuestion.programId,
              content: editorToHtml,
              questionName: currentQuestion.heading,
              folderId: existingAnswerForSomeQuestion.folderId,
              answersGroup: {
                id: answerGroupId,
                name: questionGroupName,
                requiredGrading: !!groupRequiredGrading,
                totalQuestions,
              }
            }
          } else {
            body = {
              collection: item.collection,
              question: item.question,
              set: item.set,
              activity: item.activity,
              path: item.path,
              programId: item.programId,
              content: editorToHtml,
              questionName,
            };
          }
          if ((!!answerGroupId && isLastQuestionInGroup) || 
            (answerGroupId && !leftNotAnsweredQuestions.length)) {
            body.answersGroup.done = true;
          }

          onSubmit(body);
          onCancel();
        }}
      >
        {({ errors, touched, values }) => (<Form>
          <Box>
            <span className={classes.answerLabel}>Your Reply:</span>
          </Box>
          <Box>
            <Editor
              name={`content`}
              showToolbar={true}
              placeholder='Type your response here'
              className={classes.messageArea}
              maxLength={maxMsgLength}
              maxLengthStartingFrom={1000}
              maxLengthInfo="Characters remaining: ${value}"
              aria-live="polite"
              validate={validateContent}
              isOpenText={true}
            />
            {errors && touched && !values.content.getCurrentContent().getPlainText().length &&
              <StyledFormHelper helperText={errors.body} isError={true} isHtml={true} />
            }
          </Box>
          <Box className={classes.formContainer}>
            <div>
              <CustomButton
                mode="textButton"
                onClick={() => {
                  isDataChanged ? setEscapeConfirmDialog(true) : onCancel()
                }}
                label={"Cancel"}
              />
              <CustomButton
                className={classes.submitButton}
                type="submit"
                mode={"primary"}
                disabled={disabledSubmit}
                label={"Submit"}
              />
            </div>
          </Box>
        </Form>)}
      </Formik>
      <PageLeaveModal
        onConfirm={() => {
          if (unblockRef) {
            unblockRef.current();
          }
          setDataChanged(false);
          !!nextLocation ? history.push(nextLocation) : onCancel();
          setEscapeConfirmDialog(false);
        }}
        onCancel={() => { setEscapeConfirmDialog(false); }}
        open={isEscapeConfirmDialog}
      />
    </Box>);
}
