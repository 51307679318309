import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(() => ({
  rootPaper: {
    margin: '0 auto'
  },
  uploadedImage: {
    animation: "fade-in 1s forwards",
    display: "block",
    margin: "10px auto",
    maxHeight: "400px",
    maxWidth: "90%"
  }
}));
const Image = (props) => {
  const { value, onRemove } = props;

  const classes = useStyles();

  const removeImage = () => {
    onRemove(value);
  }

  return (
    <Paper className={classes.rootPaper}>
      {value &&
        <div style={{ position: 'relative' }}>
          <img src={value} alt="Remove" className={classes.uploadedImage} />
          <IconButton
            color="primary"
            style={{ position: 'absolute', top: 0, left: 0, color: '#f44336' }}
            onClick={removeImage}
            sel="remove-img"
          >
            <ClearIcon />
          </IconButton>
        </div>
      }
    </Paper>
  );
};

export default Image;
