import { createReducer } from "redux-act";

import * as a from "../actions/certificates";

const getDefaultState = () => ({
  isLoading: false,
  userCertificates: {},
  programMemberCertificates: {},
  certificateGenerating: {},
});

export default () =>
  createReducer(
    {
      [a.loadUserCertificates]: (state) => {
        return {
          ...state,
          isLoading: true,
        }
      },
      [a.receivedUserCertificates]: (state, payload) => {
        const { userId, data } = payload;
        const newUserCertificates = { ...state.userCertificates };
        newUserCertificates[userId] = data;
        return {
          ...state,
          isLoading: false,
          userCertificates: newUserCertificates,
        };
      },
      [a.loadAllUserCertificates]: (state) => {
        return {
          ...state,
          isLoading: true,
        }
      },
      [a.receivedAllUserCertificates]: (state, payload) => {
        const { userId, data } = payload;
        const newUserCertificates = { ...state.userCertificates };
        newUserCertificates[userId] = data;
        return {
          ...state,
          isLoading: false,
          userCertificates: newUserCertificates,
        };
      },
      [a.receivedUserCertificatesByProgram]: (state, payload) => {
        const { programId, students, certificates, status } = payload;
        const items = { ...state.programMemberCertificates };

        items[programId] = { ...items[programId], students, certificates, ...status };

        return {
          ...state,
          programMemberCertificates: items,
        };
      },
      [a.receiveCertificateGenerationStatus]: (state, payload) => {
        const { programId, allowToGenerate, isRunning } = payload;
        const items = { ...state.programMemberCertificates };

        items[programId] = { ...items[programId], allowToGenerate, isRunning };
        return {
          ...state,
          programMemberCertificates: items,
        };
      },
      [a.receiveCertificateNameChangeStatus]: (state, payload) => {
        const { certificateId, isRunning, memberId, newFirstName, newLastName } = payload;
        const items = { ...state.certificateGenerating };

        items[certificateId] = { ...items[certificateId], isRunning };
        let userCertificates = { ...state.userCertificates };
        if (memberId) {
          let currenUserCertificates = userCertificates[memberId];
          const updatedCertificates = currenUserCertificates.map(e => {
            if (e.id === certificateId) {
              return { ...e, certificateFirstName: newFirstName, certificateLastName: newLastName };
            } else return e;
          });
          userCertificates[memberId] = updatedCertificates;
        }
        return {
          ...state,
          certificateGenerating: items,
          userCertificates,
        };
      }
    },
    getDefaultState()
  );
