import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { DialogContent, Typography } from "@material-ui/core/";

import { HQModal } from "../HQModal";
import * as colors from "../../../theme/colors";
import CustomButton from "../../../components/customButton/customButton";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: "35px",
  },
  marginTop30: {
    marginTop: 30,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 22,
    fontWeight: "bold",
    margin: "25px auto 15px",
    maxWidth: 330,
    textAlign: "center",
  },
  buttonGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none",
  },
  button: {
    borderRadius: 5,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: colors.grey400,
    color: "white",
    "&:hover": {
      backgroundColor: colors.grey500,
    },
  },
  submitButton: {
    borderRadius: 5,
    backgroundColor: colors.tealA700,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: colors.teal500,
    },
  },
}));

export const ConfirmationModal = ({ isOpen, onSave, onClose, text }) => {
  const classes = useStyles();

  return (
    <HQModal title="Edit Confirmation" isOpen={isOpen} onClose={onClose}>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
          {text}
        </Typography>

        <div className={classes.buttonGroup}>
          <CustomButton mode="textButton" onClick={onClose} label={"Cancel"} />
          <CustomButton
            mode="primary"
            type="submit"
            onClick={onSave}
            label={"YES"}
          />
        </div>
      </DialogContent>
    </HQModal>
  );
};
