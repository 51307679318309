import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const Switcher = ({ field: { name, onChange, value }, toggleId }) => {
  const label = value === true ? 'Yes' : 'No';
  return (
    <FormControl
      variant="outlined"
      // fullWidth
      // className={className}
    >
       <FormControlLabel
          value={value}
          name={name}
          control={<Switch defaultChecked={value} id={toggleId} color="primary" onChange={onChange} />}
          label={<div aria-hidden="true" htmlFor={toggleId}>{label}</div>}
          labelPlacement="start"
        />
    </FormControl>
  );
};

export default Switcher;
