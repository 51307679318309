import React, { useEffect } from "react";
import { push as pushToUrl } from 'connected-react-router';

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import * as actions from "../../actions/messages";
import AnnouncementContent from "./AnnouncementContent";

import Roles from "../../utils/permissions";
import { connectTo } from "../../utils/generic";
import { useTitle } from "../../utils/pageTitles";

const useStyles = makeStyles({
  item: {
    margin: "auto",
    paddingBottom: "15px"
  }
});

const isViewMode = true;

const AnnouncementView = ({
  user,
  isLoading,
  singlePost,
  match: {
    params: { postId }
  },
  getSinglePost,
  createComment,
  updatePost,
  reportMessage,
  updateComment,
  deleteMessage,
  contentful,
  push,
  studentView,
}) => {
  useTitle("view-post");

  const classes = useStyles();
  const [loadedPosts, setLoadedPosts] = React.useState([]);
  const [loopLabels, setLoopLabels] = React.useState(null);
  const [role, setRole] = React.useState(null);

  useEffect(() => {
    if (!user) return;

    const currentRole = user.roles.find(e => e.current);
    setRole(currentRole);
  }, [user]);

  useEffect(() => {
    if (singlePost) {
      if (singlePost.deleted) {
        push('/messages');
      }
      else {
        setLoadedPosts([singlePost]);
      }
    } else {
      getSinglePost({ postId });
    }
  }, [singlePost]);

  useEffect(() => {
    if (!contentful.GlobalComponentQuery) {
      return;
    }
    const {
      GlobalComponentQuery: { loopDashboardLabels }
    } = contentful;
    setLoopLabels(loopDashboardLabels);
  }, [contentful]);

  const createPostModel = (message) => {
    return {
      id: message.id || null,
      fileName: message.fileName,
      body: message.body,
      programId: role && role.programId ? role.programId : null,
    };
  }

  const createCommentModel = (message) => {
    return {
      id: message.id || null,
      fileName: message.fileName,
      body: message.body,
      parentId: message.parentId,
      programId: role && role.programId ? role.programId : null,
    };
  }

  const addComment = message => {
    createComment(createCommentModel(message));
  };

  const editPost = message => {
    const { isComment } = message;

    if (isComment) {
      updateComment(createCommentModel(message));
    } else {
      updatePost(createPostModel(message));
    }
  };

  const reportMessageInProgram = (id, isComment) => {
    reportMessage({ id, isComment, programId: role && role.programId ? role.programId : null });
  };

  const removeMessage = (id, isComment) => {
    deleteMessage({ id, isComment, programId: role && role.programId ? role.programId : null });
  };

  return (
    <React.Fragment>
      <div>
        <Grid container className={classes.item}>
          <AnnouncementContent
            removeMessage={removeMessage}
            posts={loadedPosts}
            editItem={editPost}
            addComment={addComment}
            reportMessage={reportMessageInProgram}
            userId={user ? user.id : null}
            isViewMode={isViewMode}
            labels={loopLabels}
            isLoading={isLoading}
            isFacilitator={role && role.roleId == Roles.Facilitator}
            studentView={studentView}
          />
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default connectTo(
  state => {
    return {
      user: state.auth.user,
      ...state.messages,
      contentful: state.contentful,
      studentView: state.global.studentView,
    };
  },
  {
    ...actions,
    push: pushToUrl
  },
  AnnouncementView
);
