import React from "react";

import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as colors from "../../theme/colors";
import clsx from "clsx";

const useStyles = makeStyles({
  btnContainer: {
    transition: "color 0.25s",
    backgroundColor: (props) => props.backgroundColor,
    border: 0,
    boxSizing: "border-box",

    boxShadow: (props) => `inset 0 0 0 2px ${props.borderColor}`,
    color: (props) => props.mainColor,
    fontSize: "inherit",
    fontWeight: 700,
    position: "relative",
    verticalAlign: "middle",


    "&:hover": {
      borderColor: (props) => props.secondColor,
      backgroundColor: (props) => props.hoverBackgroundColor,

      "&::before": {
        width: "100%",
        height: "100%",
        "border-top-color": (props) => props.secondColor,
        "border-right-color": (props) => props.secondColor,

        transition: "width .25s ease-out,height .25s ease-out .25s",
        transitionProperty: "width, height",
        transitionDuration: "0.25s, 0.25s",
        transitionTimingFunction: "ease-out, ease-out",
        transitionDelay: "0s, 0.25s",
      },

      "&::after": {
        width: "100%",
        height: "100%",
        "border-bottom-color": (props) => props.secondColor,
        "border-left-color": (props) => props.secondColor,

        transition:
          "border-color 0s ease-out .5s,width .25s ease-out .5s,height .25s ease-out .75s",
        transitionProperty: "border-color, width, height",
        transitionDuration: "0s, 0.25s, 0.25s",
        transitionTimingFunction: "ease-out, ease-out, ease-out",
        transitionDelay: "0.5s, 0.5s, 0.75s",
      },
    },

    "&::before": {
      top: 0,
      left: 0,
      border: "2px solid transparent",
      boxSizing: "inherit",
      content: "''",
      position: "absolute",
      width: 0,
      height: 0,
    },

    "&::after": {
      bottom: 0,
      right: 0,
      border: "2px solid transparent",
      boxSizing: "inherit",
      content: "''",
      position: "absolute",
      width: 0,
      height: 0,
    },
  },
});

const styleTypes = {
  primary: {
    mainColor: colors.dartThemeBlueGray,
    secondColor: colors.dartThemeBlueGray,
    backgroundColor: colors.dartThemeBlueGray,
    borderColor: colors.lightThemeGreen,
    hoverBackgroundColor: colors.lightThemeGreen,
    backgroundColor: colors.lightThemeGreen,
  },
  secondary: {
    mainColor: colors.dartThemeBlueGray,
    secondColor: colors.dartThemeBlueGray,
    borderColor: colors.lightThemeGreen,
    hoverBackgroundColor: "transparent",
    backgroundColor: "transparent",
  },
  textButton: {
    mainColor: colors.darkThemeGreen,
    secondColor: colors.dartThemeBlueGray,
    borderColor: "transparent",
    hoverBackgroundColor: "transparent",
    backgroundColor: "transparent",
  },
  deleteButton: {
    mainColor: colors.red3Theme,
    secondColor: colors.dartThemeBlueGray,
    borderColor: "transparent",
    hoverBackgroundColor: "transparent",
    backgroundColor: "transparent",
  },
  disabled: {
    mainColor: colors.black,
    secondColor: colors.greyLight2,
    backgroundColor: colors.dartThemeBlueGray,
    borderColor: colors.greyLight2,
    hoverBackgroundColor: colors.greyLight2,
    backgroundColor: colors.greyLight2,
  },
};

const CustomButton = ({
  mode = "primary",
  label,
  size,
  onClick,
  variant,
  className,
  endIcon,
  icon,
  disabled,
  role,
  ...props
}) => {
  const classes = useStyles(disabled ? styleTypes.disabled : styleTypes[mode]);

  return (
    <Button
      aria-label={label}    
      {...props}
      size={size}
      startIcon={icon}
      disabled={disabled}
      endIcon={endIcon}
      variant={variant}
      role={role}
      className={className ? clsx(classes.btnContainer, className) : classes.btnContainer}
      onClick={onClick}
    >
      {<span aria-label={label} role="region">{label}</span>} {props.children}
    </Button>
  );
};

export default CustomButton;
