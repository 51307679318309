import { getAuthHeader } from "../tokenStorage";
import { axiosInstance } from "./axiosInstance";

export const sendUserInvitation = async ({
  emails,
  programId,
  facilitatorFullName,
  facilitatorInvitation,
}) => {
  const args = {
    emails,
    programId,
    facilitatorFullName,
    facilitatorInvitation,
  };
  return axiosInstance.post(
    "/blapi/invitations/createBulkUsersInvitation",
    args,
    { headers: getAuthHeader() }
  );
};

export const getInvitationsByProgram = async ({ programId }) => {
  return axiosInstance.get(`/blapi/invitations/get/${programId}`, {
    headers: getAuthHeader(),
  });
};
