import React from "react";
import { Modal } from "../../components/modals/modal";
import { ChildView } from "./childRegistering/childView";

export const ChildViewModal = ({
  user,
  labels,
  domesticCountry,
  setChildViewModal,
  setRegisterChildModal,
  addedChild,
  setChildReceived
}) => {  

  const onClose = () => {
    setChildViewModal(false);
    setChildReceived({});
  };

  const onSubmit = () => {
    setRegisterChildModal(true);
    setChildViewModal(false);
    setChildReceived({});
  };

  return (    
    <Modal isOpen={true} onClose={onClose}>      
      <ChildView
        user={user}
        labels={labels}
        onClose={onClose}
        onSubmit={onSubmit}
        domesticCountry={domesticCountry}
        addedChild={addedChild}
      />      
    </Modal>
  );
};