import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import { StyledFormHelper } from "../../loginSignup/customFormHelper";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import ListItemText from "@material-ui/core/ListItemText";
import * as colors from "../../../theme/colors";
import debounce from "debounce";
import { Typography, Box, CircularProgress } from "@material-ui/core";
import { TooltipWrapper } from "../../../components/tooltip/tooltip";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiSelect-selectMenu": {
      color: colors.darkThemeBlueGray,
    },
    "& .Mui-disabled": {
      opacity: "0.5",
    },
  },
  checkBoxItem: {
    color: colors.greenA300,
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: colors.greenA300,
    },
    backgroundColor: "white !important",
    "&.Mui-focusVisible": {
      outline: `2px dotted ${colors.grey400}`,
      outlineOffset: -2
    }
  },
  errorLabel: {
    color: colors.redA400,
  },
  subLabel: {
    fontSize: 18,
    marginLeft: 20,
  },
  searchInput: {
    margin: "15px auto",
    width: "90%",
  },
  label: {
    zIndex: 2,
    padding: "0 2px",
  },
  adortmentBorder: {
    marginRight: 10,
  },
  inputWrapper: {
    display: "flex",
    outlineWidth: "0",
    "& .MuiInput-underline:before": {
      borderBottomColor: colors.lightThemeGreen,
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: colors.themeGreen,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: colors.lightThemeGreen,
    },
  },
  boxLabel: {
    display: "flex",
  },
  circularItem: {
    marginLeft: 5,
  },
  nameLabel: {
    color: colors.darkBlue100,
  },
  nameLabelWrapper: {
    padding: 0,
  },
}));

const getGroupedItems = (array, flag, eventValue) => {
  const ids = array.filter(e => !e.noBackGroundCheckInit).map((e) => e.id);
  let cleanedItems = eventValue.filter(function (el) {
    return !ids.includes(el);
  });

  cleanedItems = cleanedItems.filter((e) => e > 0);
  return flag ? cleanedItems : [...cleanedItems, ...ids];
};

const getIsAllFlag = (array, value) => {
  const ids = array.map((e) => e.id);
  const cleanedItems = value.filter(function (el) {
    return ids.includes(el);
  });
  return cleanedItems.length === array.length;
};

const noBackGroundCheckInitMsg = 'Facilitator must complete a new application for a current background check.';

const MultipleGroupedSelector = ({
  className,
  field: { name, value },
  label,
  title,
  form: { errors, touched, setFieldValue },
  options,
  helperText,
  onValueChange,
  Icon,
  errorIsHtml,
  isLoading,
}) => {
  const classes = useStyles();
  const [searchKey, setSearchKey] = React.useState(null);

  if (!options) return null;
  let error = errors[name];
  let isTouched = touched[name];

  const searchInputChangeDebounced = debounce((searchKey) => {
    searchKey = !!searchKey.trim ? searchKey.trim() : "";
    setSearchKey(searchKey);
  }, 500);

  const sortItem = (a, b) => {
    return a.label > b.label ? 1 : -1;
  };

  const selectedAll = getIsAllFlag(options.filter(option => !option.noBackGroundCheckInit), value);

  const selectAllEnabled = options.filter(e => !e.noBackGroundCheckInit).length

  return (
    <React.Fragment>
      <FormControl
        variant="outlined"
        fullWidth
        className={className}
        helperText={helperText}
      >
        <Select
          id={name}
          name={name}
          className={classes.root}
          multiple
          label={helperText}
          tabSelectsValue={false}
          disabled={isLoading}
          error={!!error && isTouched}
          helperText={
            error && isTouched ? (errorIsHtml ? "" : error) : helperText
          }
          value={value}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return (
                <Box className={classes.boxLabel}>
                  <Typography>Select {title}</Typography>
                  {isLoading && (
                    <CircularProgress
                      className={classes.circularItem}
                      size={20}
                    />
                  )}
                </Box>
              );
            }
            return (
              <Box className={classes.boxLabel}>
                <Typography>{selected.length} {title}</Typography>
                {isLoading && (
                  <CircularProgress
                    className={classes.circularItem}
                    size={20}
                  />
                )}
              </Box>
            );
          }}
          MenuProps={{
            getContentAnchorEl: () => null,
          }}
          InputProps={{
            placeholder: label,
            startAdornment: Icon ? (
              <InputAdornment position="start">
                <Icon />
              </InputAdornment>
            ) : null,
          }}
          startAdornment={
            Icon ? (
              <div className={classes.adortmentBorder}>
                {" "}
                <Icon />
              </div>
            ) : null
          }
          displayEmpty
          onChange={(event) => {
            if (event.target.value.includes(-1)) {
              const items = getGroupedItems(
                options,
                selectedAll,
                event.target.value
              );
              setFieldValue(name, items);
              return;
            }
            
            const items = event.target.value.filter((e) => e > 0);
            if (onValueChange) {
              onValueChange(event.target.value);
            }

            setFieldValue(name, items);
          }}
          onClose={() => {
            setSearchKey(null);
          }}
        >
          <MenuItem
            className={classes.inputWrapper}
            style={{ backgroundColor: "transparent" }}
          >
            <>
              <Input
                key={"Search"}
                autoFocus={true}
                label={"Search"}
                placeholder={"Search"}
                className={classes.searchInput}
                style={{ backgroundColor: "transparent" }}
                onClickCapture={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                onChange={(event) => {
                  searchInputChangeDebounced(event.target.value);
                }}
              />
            </>
          </MenuItem>
          {!searchKey && (
            <MenuItem disabled={!selectAllEnabled} className={classes.checkBoxItem} key={-1} value={-1}>
              <Checkbox inputProps={{ 'aria-labelledby':"select-all" }} checked={selectedAll} />
              <ListItemText primaryTypographyProps={{ id:"select-all" }} primary="Select All" />
            </MenuItem>
          )}
          {options
            .filter(
              (e) =>
                !searchKey ||
                e.label.toLowerCase().includes(searchKey.toLowerCase())
            )
            .sort(sortItem)
            .filter(option => !option.noBackGroundCheckInit)
            .map(({ id, label }) => (
              <MenuItem
                className={classes.checkBoxItem}
                selected={false}
                key={id}
                value={id}
              >
                <Checkbox inputProps={{ 'aria-labelledby':id }} checked={value.includes(id)} />
                <ListItemText primaryTypographyProps={{ id:id }} primary={label} className={classes.nameLabel} />
              </MenuItem>
            ))}
            {options
              .filter(
                (e) =>
                  !searchKey ||
                  e.label.toLowerCase().includes(searchKey.toLowerCase())
              )
              .sort(sortItem)
              .filter(option => option.noBackGroundCheckInit)
              .map(({ id, label, noBackGroundCheckInit }) => (
                <TooltipWrapper title={noBackGroundCheckInit ? noBackGroundCheckInitMsg : ''}>
                  <div className={classes.nameLabelWrapper}>
                    <MenuItem
                      className={classes.checkBoxItem}
                      selected={false}
                      disabled={noBackGroundCheckInit}
                      key={id}
                      value={id}
                    >
                      <Checkbox inputProps={{ 'aria-labelledby':id }} checked={value.includes(id)} />
                      <ListItemText primaryTypographyProps={{ id:id }} primary={label} className={classes.nameLabel} />
                    </MenuItem>
                  </div>
                </TooltipWrapper>
            ))}
        </Select>
        {error &&
          isTouched &&
          (errorIsHtml ? (
            <StyledFormHelper helperText={error} isError={true} isHtml={true} />
          ) : (
            <FormHelperText className={classes.errorLabel}>
              {error}
            </FormHelperText>
          ))}
      </FormControl>
    </React.Fragment>
  );
};

export default MultipleGroupedSelector;
