import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography
} from "@material-ui/core/";

import { HQModal } from "../HQModal";
import TextCustomButton from "../../text-button/text-button";
import { ShareProgramCodeSection } from "./shareProgramCodeSection";
import { InviteMembersListModal } from "./InviteMembersListModal";
import { CreateMembersModal } from "./CreateMembers/CreateMembersModal";
import { ReviewMembersModal } from "./CreateMembers/ReviewMembersModal";
import { DownloadMembersDataModal } from "./CreateMembers/DownloadMembersDataModal";
import { InviteUsersFromProgramsModal } from "./InviteUsersFromProgramsModal";
import { ColorTheme } from '../../../utils/colorTheme';
import { FacilitatorsConsentModal } from './facilitatorsConsentModal';

const useStyles = makeStyles({
  subTitle: {
    color: ColorTheme.blue4.b,
    fontSize: 18,
    marginBottom: 20,
    fontWeight: "bold",
  },
  sectionBtn: {
    padding: 0,
    textAlign: 'left'
  },
  sectionBox: {
    marginTop: 15,
  },
  sectionDesc: {
    fontSize: 14
  },
  inviteOptionsBox: {
    marginBottom: 20
  }
});

const defaultPage = 0;
const anonymousUsersCreation = 4;

export const InviteMembersModal = ({
  isOpen,
  onCloseModal,
  clubCode,
  programs,
  programMembers,
  isLoadingProgramMembers,
  loadProgramMembers,
  role,
  sendUserInvitation,
  afterModalSubmit,
  createBulkUserRolesWithEnroll,
  createAnonymousUsersWithEnroll,
  showErrorNotificationForBouncedUsers,
  getInvitations,
  getMembers,
  currentClubMembers,
  user,
  anonymousUsers,
  membersLabels,
  profileContent,
  setFacilitatorsConsentAgreement,
  isLoadingFacilitatorsConsentAgreement,
  showFacilitatorsConsentModal,
  setFacilitatorConsentSet,
  programTypeDetails,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(anonymousUsers && !showFacilitatorsConsentModal ? anonymousUsersCreation : defaultPage);
  const [userNames, setUserNames] = useState([]);
  const [generatedMembersData, setGeneratedMembersData] = useState(null);
  const [isEscapeConfirmDialog, setEscapeConfirmDialog] = useState(false);
  const [isDataChanged, setDataChanged] = useState(false);

  const onCloseMembersModal = () => {
    if (isDataChanged) {
        setEscapeConfirmDialog(true);
    } else {
        setDataChanged(false);
        setEscapeConfirmDialog(false);
        onCloseModal();
    }
};

const getModalTitle = (page, consent) => {
  if (consent) return 'Consent Agreement';

  switch (page) {
    case 1: return <>Share <span>Program </span> Code</>;
    default: return 'Add Members';
  }
}

useEffect(() => {
  if (!anonymousUsers) return;

  // redirect to the anonymousUsersCreation page after consent agreement submitted
  if (!showFacilitatorsConsentModal && page === defaultPage) {
    setPage(anonymousUsersCreation);
  }
}, [anonymousUsers, showFacilitatorsConsentModal ])

  return (
    <HQModal
      title={getModalTitle(page, showFacilitatorsConsentModal)}
      titleOptions={{ isUpperCase: true, isBold: true }}
      maxWidth={page === 5 ? 'lg' : 'sm'}
      isOpen={isOpen}
      onClose={onCloseMembersModal}
    >
      <>
        {page === 1 && (
          <ShareProgramCodeSection
            clubCode={clubCode}
            onBack={() => setPage(0)}
            programTypeDetails={programTypeDetails}
          />
        )}
        {page === 2 && (
          <InviteMembersListModal
            sendUserInvitation={sendUserInvitation}
            getInvitations={getInvitations}
            role={role}
            user={user}
            onBack={() => setPage(0)}
            onCloseModal={onCloseModal}
            afterModalSubmit={afterModalSubmit}
            showErrorNotificationForBouncedUsers={showErrorNotificationForBouncedUsers}
            isDataChanged={isDataChanged}
            setDataChanged={setDataChanged}
            isEscapeConfirmDialog={isEscapeConfirmDialog}
            setEscapeConfirmDialog={setEscapeConfirmDialog}
          />
        )}
        {page === 3 && (
          <InviteUsersFromProgramsModal
            programs={programs}
            programMembers={programMembers}
            loadProgramMembers={loadProgramMembers}
            isLoadingProgramMembers={isLoadingProgramMembers}
            createBulkUserRolesWithEnroll={createBulkUserRolesWithEnroll}
            currentRole={role}
            getMembers={getMembers}
            onBack={() => setPage(0)}
            currentClubMembers={currentClubMembers}
            onCloseModal={onCloseModal}
            isDataChanged={isDataChanged}
            setDataChanged={setDataChanged}
            isEscapeConfirmDialog={isEscapeConfirmDialog}
            setEscapeConfirmDialog={setEscapeConfirmDialog}
          />
        )}
        {page === 4 && (
          <CreateMembersModal
            onNext={() => setPage(5)}
            onCloseModal={onCloseModal}
            setUserNames={setUserNames}
            userNames={userNames}
          />
        )}
        {page === 5 && (
          <ReviewMembersModal
            role={role}
            onBack={() => setPage(4)}
            onNext={() => setPage(6)}
            onCloseModal={onCloseModal}
            clubCode={clubCode}
            userNames={userNames}
            setUserNames={setUserNames}
            setGeneratedMembersData={setGeneratedMembersData}
            generatedMembersData={generatedMembersData}
            createAnonymousUsersWithEnroll={createAnonymousUsersWithEnroll}
            getMembers={getMembers}
            profileContent={profileContent}
          />
        )}
        {page === 6 && (
          <DownloadMembersDataModal
            role={role}
            onCloseModal={onCloseModal}
            generatedMembersData={generatedMembersData}
            membersLabels={membersLabels}
            clubCode={clubCode}
          />
        )}
        {page === 0 &&
          (!showFacilitatorsConsentModal ? (
            <Box className={classes.inviteOptionsBox}>
              <Typography id="dialog-description" className={classes.subTitle}>
                Choose how to add new Members:
              </Typography>
              <Box className={classes.sectionBox}>
                <TextCustomButton
                  className={classes.sectionBtn}
                  isArrowWave={true}
                  label={'Share Program Code'}
                  onClick={() => setPage(1)}
                />
                <Typography className={classes.sectionDesc}>
                  Click here to display an enlarged view of your Code for
                  Members to join.
                </Typography>
              </Box>
              <Box className={classes.sectionBox}>
                <TextCustomButton
                  className={classes.sectionBtn}
                  isArrowWave={true}
                  label={'Send Email Invites'}
                  onClick={() => setPage(2)}
                />
                <Typography className={classes.sectionDesc}>
                  If you have potential Members' emails, you can send an email
                  invitation to join the Program.
                </Typography>
              </Box>
              {programs && programs.length > 0 && (
                <Box className={classes.sectionBox}>
                  <TextCustomButton
                    className={classes.sectionBtn}
                    isArrowWave={true}
                    label={'Add From Another Program'}
                    onClick={() => setPage(3)}
                  />
                  <Typography className={classes.sectionDesc}>
                    Transfer returning Members from previous Program(s).
                  </Typography>
                </Box>
              )}
            </Box>
          ) : (
            <FacilitatorsConsentModal
              onSubmit={() => {
                setFacilitatorConsentSet(true);
                setFacilitatorsConsentAgreement({ programId: role.programId });
              }}
              labels={membersLabels.consentAgreementModalText}
              programName={role.program.name}
              isLoading={isLoadingFacilitatorsConsentAgreement}
            />
          ))}
      </>
    </HQModal>
  );
};
