import React from "react";

import { Field } from "formik";
import clsx from 'clsx';

import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomCheckbox from "../../../components/customCheckbox/customCheckbox";
import { Typography, Box, Link } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";

import * as colors from "../../.././theme/colors";

const useStyles = makeStyles({
  stepTitle: {
    color: colors.darkThemeBlueGray,
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 10,
  },
  subTitle: {
    color: colors.darkThemeBlueGray,
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 10,
  },
  boxWrapper: {
    marginTop: 20,
    '& .MuiTypography-body1': {
      fontSize: 14,
    }
  },
  requiredBox: {
    display: "flex",
  },
  starLabel: {
    color: colors.redA700,
    marginLeft: 1,
  },
  subLabel: {
    fontSize: 14,
    "& .MuiTypography-body1": {
      fontSize: 14,
    }
  },
  item: {
    display: "flex",
    marginBottom: 20,
    "& .MuiTypography-body1": {
      width: "40%",
      margin: 0,
      fontWeight: "bold",
    },
    "& .MuiTypography-body2": {
      width: "60%",
      margin: 0,
      fontSize: "14px",
      color: colors.dartThemeBlueGray,
      wordBreak: "break-word",

      "& button": {
        textAlign: "left",
        
      },
    },
  },
  infoContainer: {
    border: "1px solid #7A7A78",
    padding: "5px 15px",
    '& .MuiTypography-body1': {
      fontSize: 14,
    }
  },
  infoLinkBlock: {
    margin: '8px 0 0 31px'
  },
  infoLink: {
    color: colors.darkThemeGreen,
    fontSize: 14,
    fontWeight: 700,
    textDecoration: "none",
    padding: '0 0 0 5px',
    "&:hover": {
      textDecoration: "none",
    },
  },
  firstStepWrapper: {
    padding: "0 24px",
  }
});

const FirstStep = ({ labels, program, isLoading }) => {
  const classes = useStyles();

  const programExpirationYear = parseInt(program.year, 10) + 1;

  return (
    <Box className={classes.firstStepWrapper}>
      <Box>
        <Typography className={classes.stepTitle}>
          {`Step 1: ${labels.firstStepLabel}`}{" "}
          {isLoading && <CircularProgress size={25} thickness={5} />}
        </Typography>
      </Box>
      <Box className={classes.boxWrapper}>
        <Typography>
          {labels.firstStepProgramExpirationDateLabel.replace(
            "{{programYearPlusOne}}",
            programExpirationYear || ""
          )}
          {isLoading && <CircularProgress size={15} thickness={5} />}
        </Typography>
      </Box>
      <Box className={classes.boxWrapper}>
        <Typography className={classes.subLabel}>{labels.firstStepProgramInfoDescription}</Typography>
      </Box>
      <Box className={classes.boxWrapper}>
        <Typography className={classes.subTitle}>
          Please review the following information:
        </Typography>
      </Box>
      <Box className={classes.infoContainer}>
        <ListItemText
          primary="Host organization:"
          className={classes.item}
          secondary={program.hostOrganizationName || ""}
        />
        <ListItemText
          primary="Organization Address:"
          className={classes.item}
          secondary={program.organizationAddress || ""}
        />
      </Box>
      <Box className={classes.boxWrapper}>
        <Typography className={classes.subTitle}>
          Will the above information remain the same for your Program?*
        </Typography>
      </Box>
      <Field
        name="conditions"
        component={CustomCheckbox}
        className={classes.subLabel}
        label={"Yes, this information is still correct for my Program"}
      />
      <Typography className={clsx(classes.subLabel, classes.infoLinkBlock)}>
        If not, please
        <Link
          target="_blank"
          className={classes.infoLink}
          href={labels.firstStepClubRenewalSurveyLink}
        >Complete a new Application</Link>
      </Typography>
    </Box>
  );
};

export default FirstStep;
