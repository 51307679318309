import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography
} from "@material-ui/core/";

import { HQModal } from "../HQModal";
import { Formik, Field, Form } from "formik";
import CustomButton from "../../customButton/customButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Selector from "../../selector/Selector";

const useStyles = makeStyles((theme) => ({
  selectionBox: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 400,
    }
  },
  title: {
    margin: "10px 0",
    textAlign: 'left'
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 35,
  },
  addBtn: {
    padding: '0 25px'
  },
  progressIcon: {
    width: 20,
    height: 20,
    marginLeft: 5,
  },
  boxSection: {
    display: "flex",
    marginBottom: 5,
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      display: "block",
    },
  },
  roleLabelField: {
    margin: '0 auto',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
      marginLeft: 0,
    },
    '& .MuiTypography-body1': {
      lineHeight: 'inherit',
    }
  }
}));

const getRenderedOptions = (roleLabels) => {
  const items = roleLabels.map(label => {
    return { value: label.id, label: label.roleLabel };
  }).sort(
    (a, b) =>
      a.label.localeCompare(b.label) ||
      a.label.localeCompare(b.label)
  );
  items.unshift({ value: 'none', label: 'No Role' });
  return items;
}

export const EditUserRoleLabelModal = ({
  isOpen,
  onCloseModal,
  roleLabels,
  editedUserRoleLabel,
  programId,
  createUserRoleLabel,
  isLoading,
  deleteUserRoleLabel,
}) => {
  const classes = useStyles();

  const { fullName, id, userRoleLabel } = editedUserRoleLabel;
  const roleLabel = userRoleLabel && roleLabels.find(label => label.id === userRoleLabel.roleLabelId);
  const userCurrentRoleLabel = roleLabel?.id ? roleLabel.id : 'none';

  return (
    <HQModal title="Program Role" 
      titleOptions={{isUpperCase: false, isBold: true}}
      isOpen={isOpen} onClose={onCloseModal}
    >
      <Box className={classes.selectionBox}>
      <Typography id="dialog-description" className={classes.title}>Edit program role for {fullName}:</Typography>
      <Formik
        initialValues={{
          userRoleLabel: userCurrentRoleLabel,
        }}
        onSubmit={(values) => {
          if (userRoleLabel?.id && userRoleLabel?.roleLabelId !== values.userRoleLabel && values.userRoleLabel === 'none') {
            deleteUserRoleLabel(userRoleLabel.id)
          }
          if (values.userRoleLabel !== 'none' && (!userRoleLabel?.id || userRoleLabel?.roleLabelId !== values.userRoleLabel)) {
            createUserRoleLabel({
              userId: id,
              programId,
              roleLabelId: values.userRoleLabel,
            });
          }
          onCloseModal();
        }}
      >
        <Form>
          <Box className={classes.boxSection}>
            <Field
              name={`userRoleLabel`}
              className={classes.roleLabelField}
              component={Selector}
              label="Program Role"
              options={getRenderedOptions(roleLabels)}
              shrink
            />
          </Box>
          <Box className={classes.btnContainer}>
            <CustomButton mode={"textButton"} onClick={() => onCloseModal()}>Cancel</CustomButton>
            <CustomButton
              className={classes.addBtn}
              disabled={isLoading}
              mode={"primary"}
              type="submit"
              label={"Save"}>
                {isLoading && (
                  <CircularProgress
                    size={20}
                    className={classes.progressIcon}
                  />
                )}
            </CustomButton>
          </Box>
        </Form>
      </Formik>
    </Box>
    </HQModal>
  );
};
