import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

const useStyles = makeStyles({
    icon: {
        border: "1px solid #0D9C90",
        borderRadius: '50%',
        width: 18,
        height: 18,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        border: "1px solid #0D9C90",
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#0D9C90,#0D9C90 45%,transparent 50%)',
            content: '""',
        },
        'input:hover ~ &': {
            // backgroundColor: theme.palette.primary.main,
        },
        checkBoxLabel: {
            fontSize: 12,
        },
        radio: {
            fontSize: 12,
        },
    },
});

const StyledRadio = (props) => {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="red"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

const RadioSelector = ({ field, form, items, isDisabled, onValueChange }) => {
    const classes = useStyles();

    return (
        <FormControl component="fieldset">
            <React.Fragment>
                <RadioGroup
                    name="roleSelector"
                    value={field.value}
                    onChange={(evt) => {
                        if (onValueChange) {
                            onValueChange(evt.target.value === "true");
                        }
                        form.setFieldValue(field.name, evt.target.value === "true");
                    }}
                >
                    {items.map((item) => (
                        <FormControlLabel
                            key={item.id}
                            classes={{ label: classes.checkBoxLabel }}
                            value={item.name}
                            control={
                                <StyledRadio color="green" disabled={isDisabled} className={classes.radio} />
                            }
                            label={<span style={{ fontSize: 14 }}>{item.alias}</span>}
                        />
                    ))}
                </RadioGroup>
            </React.Fragment>
        </FormControl>
    );
};

export default RadioSelector;
