import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import {
  EventAvailable,
  Class,
  DonutLarge,
  GroupOutlined,
  DashboardOutlined,
  QuestionAnswerOutlined
} from '@material-ui/icons';
import * as colors from "../../theme/colors";

const StyledTabs = withStyles(theme => ({
  indicator: {
    height: 4,
    marginBottom: 3,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 90,
      [theme.breakpoints.down('xs')]: {
        maxWidth: 30,
      },
      width: '100%',
      backgroundColor: "#fff",
    },
  },
}))(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    fontSize: 16,
    color: colors.white,
    opacity: 1,
    minWidth: 100,
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },
    '&$selected': {
      color: colors.white,
      fontWeight: theme.typography.fontWeightBold,
      border: 'none'
    },
    "&:hover": {
      color: colors.white,
    },
    "&:focus": {
      outline: `2px dotted ${colors.grey400}`,
      outlineOffset: -4,
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

export default ({ onChange, value, push, currentRole, user, permissions, attendanceEnabled, messagesEnabled, studentView, isMobile }) => {
  return (
    <StyledTabs
      color="secondary"
      value={value}
      onChange={onChange}
    >
      <StyledTab
        value='Curriculum'
        icon={isMobile ? <Class /> : ''}
        label={!isMobile ? 'Curriculum' : ''}
        onClick={() => push('/gwc')}
        sel="curriculum"
        tabIndex="0"
      />
      {currentRole && <StyledTab
        value='Program Info'
        icon={isMobile ? <DashboardOutlined /> : ''}
        label={!isMobile ? 'Program Info' : ''}
        onClick={() => push(`/club-profile/${currentRole.program.code}`)}
        sel="program info"
        tabIndex="0"
      />}
      {currentRole && permissions.membersPage && !studentView && <StyledTab
        value='Members'
        label={!isMobile ? 'Members' : ''}
        icon={isMobile ? <GroupOutlined /> : ''}
        onClick={() => push(`/members/${currentRole.program.code}`)}
        sel="members"
        tabIndex="0"
      />}
      {currentRole && (studentView || permissions.progressPageStudent) && <StyledTab
        value='Progress'
        label={!isMobile ? 'Progress' : ''}
        icon={isMobile ? <DonutLarge /> : ''}
        onClick={() => push(`/progress/${currentRole.program.code}/${user.id}`)}
        sel="progress"
        tabIndex="0"
      />}
      {currentRole && permissions.progressPageTeacher && !studentView && <StyledTab
        value='Progress'
        label={!isMobile ? 'Progress' : ''}
        icon={isMobile ? <DonutLarge /> : ''}
        onClick={() => push(`/progress/${currentRole.program.code}`)}
        sel="progress"
        tabIndex="0"
      />}
      {attendanceEnabled && !studentView && <StyledTab
        value='Attendance'
        label={!isMobile ? 'Attendance' : ''}
        icon={isMobile ? <EventAvailable /> : ''}
        onClick={() => push('/attendance')}
        sel="attendance"
        tabIndex="0"
      />}
      {messagesEnabled &&
        <StyledTab
          value='Messages'
          label={!isMobile ? 'Messages' : ''}
          icon={isMobile ? <QuestionAnswerOutlined /> : ''}
          onClick={() => push('/messages')}
          sel="messages"
          tabIndex="0"
      />}
    </StyledTabs>
  );
};