import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from '@material-ui/core/Dialog';

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import * as colors from "../../theme/colors";

const useStyles = makeStyles(() => ({
  main: {
    margin: "auto",
  },
  dialogContent: {
    padding: "0px 0px 15px 0px",
  },
  title: {
    color: colors.dartThemeBlueGray,
    fontSize: 24,
    fontWeight: 700,
    margin: "25px auto 15px auto;",
    maxWidth: 330,
    textAlign: "center",
  },
  titleBox: {
    display: 'flex',
    background: colors.lightGradient
  },
}));

export const Modal = (props) => {
  const {
    title,
    isOpen,
    onClose,
    children
  } = props;
  const classes = useStyles();

  return (
    <div>
      <Dialog maxWidth={'lg'} className={classes.main} open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
        <Box className={classes.titleBox}>
          <Typography variant="h4" className={classes.title}>
            {title}
          </Typography>
        </Box>
        <DialogContent className={classes.dialogContent}>
          {children}
        </DialogContent>
      </Dialog>
    </div>
  );
};
