import React from "react";
import objectPath from "object-path";

import { makeStyles } from "@material-ui/core/styles";
import EmailIcon from "@material-ui/icons/EmailOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { Typography, Box } from "@material-ui/core";

import { StyledFormHelper } from "../loginSignup/customFormHelper";
import * as colors from "../../theme/colors";
import { TooltipWrapper } from "../tooltip/tooltip";
import { formInavlidTooltipMsg } from '../../utils/userUtils';

const useStyles = makeStyles({
  error: {
    color: colors.redA400,
  },
  note: {
    textAlign: "center",
    color: colors.grey600,
    fontSize: 12,
  },
});

const EmailInput = ({ field, form, label, helperText, subTitle, isIconEnable = true, shrink = false, showTooltip, ...props }) => {
  const classes = useStyles();
  const { errors, touched } = form;

  let error = errors[field.name];
  let isTouched = touched[field.name];

  if (field.name.indexOf("[") !== -1 || field.name.indexOf(".") !== -1) {
    const path = field.name.replace("[", ".").replace("]", "").split(".");
    error = objectPath.get(errors, path);
    isTouched = objectPath.get(touched, path);
  }
  const InputLabelProps = {
    for: field.name
  };

  if (shrink) {
    InputLabelProps.shrink = shrink;
  }

  return (
    <React.Fragment>
      <TextField
        {...props}
        name={field.name}
        error={!!error && isTouched}
        onChange={(event) => {
          form.setFieldValue(field.name, event.target.value);
        }}
        fullWidth
        label={label}
        variant="outlined"
        value={field.value}
        InputProps={{
          classes,
          placeholder: label || props?.placeholder || '',
          id: field.name,
          startAdornment: (
            isIconEnable && <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),
        }}
        InputLabelProps={InputLabelProps}
      />
      {subTitle && <Typography className={classes.note}>
        {subTitle}
      </Typography>}
      {error && isTouched ? (showTooltip ?
        <Box component="span">
          <TooltipWrapper title={formInavlidTooltipMsg}>
            <Box component="span">
              <StyledFormHelper helperText={error} isHtml={true} isError={true} />
            </Box>
          </TooltipWrapper>
        </Box> :
        <StyledFormHelper helperText={error} isHtml={true} isError={true} />
      ) : (
        <StyledFormHelper helperText={helperText} isHtml={true} />
      )}
    </React.Fragment>
  );
};

export default EmailInput;
