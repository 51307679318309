import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";

import { Modal } from "../../../../components/modals/modal";

import Markdown from "react-remarkable";
import * as colors from "../../../../theme/colors";
import CustomButton from "../../../../components/customButton/customButton";

const useStyles = makeStyles({
  dialogContent: {
    padding: "35px"
  },
  body: {
    fontSize: '1rem',
    // fontWeight: 400,
    lineHeight: '1.5em',
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 500,
  },
  buttonGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none"
  },
  button: {
    borderRadius: 5,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: colors.grey400,
    color: "white",
    "&:hover": {
      backgroundColor: colors.grey500
    }
  },
  submitButton: {
    borderRadius: 5,
    backgroundColor: colors.tealA700,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: colors.teal500
    }
  }
});

const SpreadWorldModal = ({
  labels,
  isInternational,
  isOpen,
  onClose,
  onSpread
}) => {
  const classes = useStyles();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <DialogContent className={classes.dialogContent}>
        {labels && (
          <Markdown options={{ html: true }} className={classes.body}>
            {!isInternational
              ? labels.inviteFormCommunityPartnerSpreadWorld
              : labels.inviteInternationalCommunityPartnerSpreadWorld}
          </Markdown>
        )}
        <div className={classes.buttonGroup}>
          <CustomButton
            mode="secondary"
            onClick={() => onClose()}
            label={"Done"}
            sel="done-button"
          />
          <CustomButton
            mode="primary"
            onClick={() => onSpread()}
            label={"Spread the word!"}
            sel="spreadWord-button"
          />
        </div>
      </DialogContent>
    </Modal>
  );
};

export default SpreadWorldModal;
