import React from "react";
import { useField } from 'formik';
import DraftEditor from './Components/Editor';
import { EditorState } from 'draft-js';

const Editor = ({ label, name, maxLength, maxLengthStartingFrom, showToolbar, placeholder, handleReturn, removeScroll, isOpenText }) => {
  const [field, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;

  const handleEditorChange = (value) => {
    const content = value.getCurrentContent();
    return setValue(EditorState.set(value, { currentContent: content }))
  };

  return (
    <DraftEditor
      label={label}
      {...field}
      value={value}
      onChange={handleEditorChange}
      maxLength={maxLength}
      showToolbar={showToolbar}
      placeholder={placeholder}
      handleReturn={handleReturn}
      removeScroll={removeScroll}
      name="text-editor" 
      maxLengthStartingFrom={maxLengthStartingFrom}
      isOpenText={isOpenText}
    />
  )
}

export { default as decorator } from './decorator';
export default Editor;
