import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import Photo from "./Photo";

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    minWidth: "100%",
    margin: "auto",
  },
  subGrid: {
    minWidth: "25%",
    width: "25%",
  },
  subPhotoGrid: {
    marginTop: 5,
    minWidth: "100%",
    margin: "auto",
  },
  mainPhotoContainer: {
    minWidth: "75%",
    width: "75%",
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
    [theme.breakpoints.down("xs")]: {
      padding: "0 5px",
    },
  },
  photoContainer: {
    display: "flex",
    alignItems: "center",
    padding: "5px 0px",
  },
  containerRoot: {
    padding: 15,
    marginBottom: 10,
  },
  mainContainer: {
    display: "flex",
    width: "100%",
  },
  multipleUploadBox: {
    height: "100px",
  },
}));

const PhotosSelector = (props) => {
  const classes = useStyles();
  const {
    field: { name, value },
    form,
    label,
    showErrorMessage,
  } = props;

  const error = form.errors[name];
  const isTouched = form.touched[name];

  const addImage = (url) => {
    value.push(url);
    form.setFieldValue(name, value);
  };

  const removeImage = (url) => {
    const result = value.filter((p) => p !== url);
    form.setFieldValue(name, result);
  };

  return (
    <Container className={classes.containerRoot}>
      <Grid container className={classes.mainGrid}>
        {!!value && value.length > 0 && (
          <Box className={classes.mainContainer}>
            <Grid
              item
              xs={6}
              sm={6}
              key={value[0]}
              className={classes.mainPhotoContainer}
            >
              <Photo
                key={value[0]}
                value={value[0]}
                onChange={(val) => addImage(val)}
                onRemove={(val) => removeImage(val)}
                showErrorMessage={showErrorMessage}
              />
            </Grid>
            <Box className={classes.subGrid}>
              <Box>
                <Grid container className={classes.subPhotoGrid}>
                  {value &&
                    value.map((p, index) => {
                      if (index > 0) {
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            key={p}
                            className={classes.photoContainer}
                          >
                            <Photo
                              key={p}
                              value={p}
                              onChange={(val) => addImage(val)}
                              onRemove={(val) => removeImage(val)}
                              showErrorMessage={showErrorMessage}
                            />
                          </Grid>
                        );
                      }
                    })}
                </Grid>
              </Box>
              <Box>
                <Grid item xs={12} sm={4} className={classes.mainGrid}>
                  {value && value.length < 4 && (
                    <Photo
                      value={null}
                      onChange={(val) => addImage(val)}
                      className={classes.multipleUploadBox}
                      showErrorMessage={showErrorMessage}
                    />
                  )}
                </Grid>
              </Box>
            </Box>
          </Box>
        )}
      </Grid>
      {!value.length && (
        <Grid item xs={12} sm={4} className={classes.mainGrid}>
          {value && value.length < 4 && (
            <Photo value={null} onChange={(val) => addImage(val)} showErrorMessage={showErrorMessage} />
          )}
        </Grid>
      )}
      <FormHelperText error={!!error && isTouched}>
        {!!error && isTouched ? error : label}
      </FormHelperText>
    </Container>
  );
};

export default PhotosSelector;
