import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import linkifyHtml from "linkify-html";

import * as colors from "../../../theme/colors";

import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { createMarkup, decodeHTML, targetBlankForLinks } from '../../../utils/generic';
import TermsInput from "../../../components/termsInput/TermsInput";
import CustomButton from "../../../components/customButton/customButton";
import TextButton from "../../../components/text-button/text-button";
import { HQModal } from "../../../components/modals/HQModal";

const useStyles = makeStyles(() => ({
  subTitle: {
    '& > div': {
      color: colors.darkThemeBlueGray,
      fontSize: 14,
      lineHeight: 1.4,
      textAlign: "left",
      fontWeight: 400,
      '& > span': {
        fontWeight: 700,
      },
    },
    marginBottom: 10,
  },
  agreementBox: {
    overflowY: 'scroll',
    height: 325,
    fontSize: 16,
    lineHeight: 1.25,
    color: colors.darkThemeBlueGray,
    border: `1px solid ${colors.grey350}`,
    boxShadow: `inset 0 0 5px ${colors.grey350}`,
    padding: '0px 10px',
    overflowX: 'hidden',
    "& > div": {
      margin: '20px!important'
    },
    '& a': {
      color: `${colors.darkThemeGreen} !important`
    }
  },
  printBoxWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 4,
    textDecoration: "underline",
    textDecorationColor: colors.darkThemeGreen,
  },
  pdflink: {
    color: colors.darkThemeGreen,
    fontSize: 14,
    fontWeight: 400,
    textTransform: "none",
  },
  confirmWrapper: {
    display: "flex",
    marginTop: 12,
    '& .MuiFormControlLabel-root': {
      marginRight: 4,
    },
    "& .MuiIconButton-label": {
      color: colors.darkThemeGreen,
    },
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: 12,
  },
  acceptBtn: {
    width: 185,
  },
  agreementBoxDescription: {
    color: colors.darkThemeBlueGray,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.25,
    '& a': {
      color: colors.darkThemeGreen
    }
  },
  blockCheckbox: {
    opacity: 0.3,
    pointerEvents: 'none',
  },
}));


export const ParentConsentModal = ({
  setParentConsentModal,
  content,
  currentUserUpdated,
  setParentConsent,
  isSettingParentConsent,
  parentConsentError,
  setRegisterChildModal,
  generateParentConsentPdf
}) => {
  const classes = useStyles();

  const [enableAccept, setEnableAccept] = useState(false);
  const [ref, setRef] = useState(false);
  const [isParentConsentViewed, setParentConsentViewed] = useState(false);
  const [isSubmitingParentConsent, setSubmitingParentConsent] = useState(false);
  const [consentContent, setConsentContent] = useState(null);

  useEffect(() => {
    if (!content || !content.creatingChild?.parentConsentObject) {
      return;
    }
    setConsentContent(content.creatingChild?.parentConsentObject);
  }, [content]);

  useEffect(() => {
    if (!isSettingParentConsent && !parentConsentError && isSubmitingParentConsent) {
      setSubmitingParentConsent(false);
      setRegisterChildModal(true);
    }
  }, [isSettingParentConsent]);

  // Attach the scroll listener to the div
  useEffect(() => {
    const element = document.getElementById('parentConsentContent');
    if (element) {
      let lastScrollTop = 0;
      element.onscroll = () => {
        if (element.scrollTop < lastScrollTop) {
          return;
        }
        lastScrollTop = element.scrollTop <= 0 ? 0 : element.scrollTop;
        if (element.scrollTop + element.offsetHeight >= element.scrollHeight) {
          setParentConsentViewed(true);
        }
      }
    }
  }, [ref]);

  const onCloseParentConsentModal = () => {
    setParentConsentModal(false);
    currentUserUpdated({ isParent: false });
  };

  const formatConsentContent = () => {
    const result = createMarkup(targetBlankForLinks(linkifyHtml(decodeHTML(consentContent?.parentConsentFile?.richTextContent), {target: "_blank"})));

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = result.__html;

    const pdfOnlyElements = tempDiv.querySelectorAll('.pdf_only');
    pdfOnlyElements.forEach((element) => {
      element.parentNode.removeChild(element);
    });

    result.__html = tempDiv.innerHTML;

    return result;
  }

  return !!consentContent?.parentConsentFile?.content ? (
    <HQModal 
      title={consentContent?.title} 
      isOpen={true} 
      onClose={onCloseParentConsentModal}
      titleOptions={{isBold: true}}>
      <Box>
        <Box className={classes.subTitle}>
          <div
            dangerouslySetInnerHTML={createMarkup(consentContent?.subtitle)}
          />
        </Box>
        <Box>
          <Box>
            <div
              id="parentConsentContent"
              ref={(link) => {
                setRef(link);
              }}
              className={classes.agreementBox}
              dangerouslySetInnerHTML={formatConsentContent()}
            />
          </Box>
        </Box>
        <Box className={classes.printBoxWrapper}>
          <TextButton 
            className={classes.pdflink}
            onClick={() => {
              generateParentConsentPdf(consentContent?.parentConsentFileLink);
          }}>Printable PDF Version</TextButton>
        </Box>
        <Box className={classes.agreementCheckBox}>
          <Formik
            initialValues={{
              termsAndConditions: false,
            }}
            validate={(values) => {
              let errors = {};
              setEnableAccept(values.termsAndConditions);
              return errors;
            }}
            onSubmit={() => {
              setSubmitingParentConsent(true);
              setParentConsent();
              setParentConsentModal(false);
              setRegisterChildModal(true);
            }}
            validateOnMount={true}
          >
            <Form>
              <Box className={classes.confirmWrapper}>
                <div className={`${isParentConsentViewed ? '' : classes.blockCheckbox }`}>
                  <Field
                    disabled={!isParentConsentViewed}
                    required
                    name="termsAndConditions"
                    component={TermsInput}
                    termsContent=''
                  />
                </div>
                <Box className={classes.agreementBoxDescription}>
                  <div
                    dangerouslySetInnerHTML={createMarkup(consentContent?.parentConsentDescription)}
                  />
                </Box>
              </Box>
              <Box className={classes.btnContainer}>
                <CustomButton
                  disabled={!enableAccept}
                  className={classes.acceptBtn}
                  mode={"primary"}
                  type="submit"
                  label="I ACCEPT">
                </CustomButton>
              </Box>
            </Form>
          </Formik>
        </Box>
      </Box>
    </HQModal>
  ) : null;
};
