import React, { useState } from "react";

import {
  Box,
  CardContent,
  Grid,
} from "@material-ui/core/";

import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircle from '@material-ui/icons/CheckCircle';
import TextCustomButton from "../../../components/text-button/text-button";
import CustomButton from "../../../components/customButton/customButton";
import { createMarkup } from "../../../utils/generic";
import Collapse from "@material-ui/core/Collapse";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import Comments from "./comments";
import { AnswerCard } from "./answerCard";
import { ReSubmitAnswer } from './resubmit';
import { makeStyles } from "@material-ui/core/styles";
import * as colors from "../../../theme/colors";
import Divider from "@material-ui/core/Divider";
import {renderCheckboxes} from "../../../utils/programHelper";

const useStyles = makeStyles(() => ({
  viewBox: {
    display: 'block',
    padding: 25,

    "& *": {
      maxWidth: '100%',
    },
    '& ul': {
      "padding-left": 40,
    },
    '& li': {
      lineHeight: 1.8,
      paddingLeft: 10,
      "&::marker": {
        color: colors.darkThemeGreen,
      }
    },
    '& details': {
      backgroundColor: colors.white,
      borderRadius: 5,
      border: '1px solid',
      borderColor: colors.darkThemeGreen,
      margin: '10px 0',
      padding: 10,

      '&:focus-within': {
        border: '2px solid',
        borderColor: colors.black,
        padding: 9
      },

      '& > summary': {
        outline: 'none',
        cursor: 'pointer',
        color: colors.darkThemeGreen,
        fontWeight: 700
      }
    },
    '& a': {
      color: colors.darkThemeGreen,
      lineHeight: 1.8,
      fontWeight: 700,
      textTransform: "initial",
    },
  },
  answerLabelWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
    answerLabel: {
    color: colors.lightThemeGrey,
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "uppercase",
    display: "flex",
  },
  completedBox: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    color: "#083861",
  },
  completedBoxTitle: {
    fontSize: 18,
  },
  answerContent: {
    color: colors.darkThemeBlueGray,
    fontSize: 18,
    width: "100%",
  },
  answerBtn: {
    margin: "40px 0",
  },
  completedLabel: {
    fontSize: 18,
    color: colors.darkThemeBlueGray,
    display: 'flex',
    marginTop: 30,
  },
  checkmarkIcon: {
    color: colors.lightThemeGreen,
    height: 22,
    marginLeft: 5,
  },
  viewAllAnswers: {
    color: colors.grayTheme,
    "&:hover": {
      color: colors.grayTheme,
    }
  }, 
  viewAllAnswersWrapper: {
    width: '100%',
    display: "flex",
    justifyContent: "space-around",
    marginTop: -50,
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

export const GroupAnswers = ({
  currentNode,
  items,
  isLoadingComments,
  answerGroup,
  createAnswer,
  setIsReSubmit,
  isCompleted,
  setCurrentReSubmitAnswer,
  currentReSubmitAnswer,
  currentGroupQuestionDescription,
  setCurrentGroupQuestionDescription,
  disableResubmit,
  isLoadingAnswer,
  history,
}) => {
  const classes = useStyles();
  const [answerForQuestion, setAnswerForQuestion] = useState();
  const [groupQuestionAnswer, setGroupQuestionAnswer] = React.useState({});

  React.useEffect(() => {
    // add event and styles to dynamic render checkbox
    renderCheckboxes();
  }, [currentGroupQuestionDescription]);

  const getRestAnswersArr = (currentQuestionsAsnwers = [], id) => currentQuestionsAsnwers.reverse().reduce((restAnswers, item, index, arr) => {
      if (arr.length > index && index === 2) {
        restAnswers.push(
          <Box className={classes.viewAllAnswersWrapper}>
            <TextCustomButton
              className={classes.viewAllAnswers}
              onClick={() => {
                groupQuestionAnswer[id] = !groupQuestionAnswer[id];
                setGroupQuestionAnswer({ ...groupQuestionAnswer });
              }}
              label={`${groupQuestionAnswer[id] ? "Hide All" : "View All"}`.toUpperCase()}
              endIcon={groupQuestionAnswer[id] ? <ExpandLess /> : <ExpandMore />}
            />
          </Box>
        )
      }
      restAnswers.push(<AnswerCard
        key={`${item.question}-${index}`}
        item={item}
        isLast={index + 1 === items.length}
        isVisible={index < 2 ? true : !!groupQuestionAnswer[id]}
      />);
  
      return restAnswers;
    }, []);

  const groupWithAnswers = Array.isArray(currentNode) && currentNode.map((groupAnswer, index) => {
    const {
      contentSection: {
        id,
        heading,
        content,
      } } = groupAnswer;

    const currentQuestionsAsnwers = Array.isArray(items) && items.filter(item => item.question === id) || [];
    return (<CardContent className={classes.viewBox} key={`${id}-${index}`}>
      <Box>
        <Box className={classes.answerLabelWrapper}>
          <Box className={classes.answerLabel}>
            <Box className={[classes.completedBox, classes.completedBoxTitle].join(' ')}>
              {heading}
            </Box>
            <Box className={classes.completedBox}>
              <TextCustomButton
                onClick={() => {
                  currentGroupQuestionDescription[id] = currentGroupQuestionDescription[id] === undefined ||
                  !currentGroupQuestionDescription[id];

                  setCurrentGroupQuestionDescription({ ...currentGroupQuestionDescription})
                }}
                label={`${currentGroupQuestionDescription[id] ? "Hide Question Description" : "View Question Description"}`.toUpperCase()}
                endIcon={currentGroupQuestionDescription[id] ? <ExpandLess /> : <ExpandMore />}
              />
            </Box>
          </Box>
          <Box>
            {((!disableResubmit && !isCompleted && 
              !!currentQuestionsAsnwers.length &&
              !currentReSubmitAnswer[id] && 
                !answerGroup?.status) ||
                (!disableResubmit && !groupAnswer?.contentSection?.contentSectionGroup?.requiredGrading &&
              !!currentQuestionsAsnwers.length)) &&
                  <TextCustomButton
                    label={"Resubmit"}
                    onClick={() => {
                      const reSubmitObj = {};
                      reSubmitObj[id] = true;
                      setCurrentReSubmitAnswer(reSubmitObj);
                      setIsReSubmit(true);
                    }}
                  />}
          </Box>
        </Box>

        {isLoadingComments && <CircularProgress size={15} />}
      </Box>

      <Collapse in={currentGroupQuestionDescription[id]} timeout="auto" unmountOnExit>
        <Box
          className={classes.answerContent}
          dangerouslySetInnerHTML={createMarkup(content)}
        />
      </Collapse>
      {!disableResubmit && !currentQuestionsAsnwers.length &&
        answerForQuestion !== id &&
        <CustomButton
          className={classes.answerBtn}
          disabled={isLoadingAnswer}
          mode={"primary"}
          label="Answer question"
          onClick={() => {
            setAnswerForQuestion(id);
          }}
        />
      }
      {currentReSubmitAnswer[id] || answerForQuestion === id
        ? <Box>
          <ReSubmitAnswer
            item={items}
            questionName={heading}
            groupAnswer={groupAnswer}
            history={history}
            onCancel={() => {
              if (currentReSubmitAnswer[id]) {
                const reSubmitObj = {};
                reSubmitObj[id] = false;
                setCurrentReSubmitAnswer(reSubmitObj);
                setIsReSubmit(false);
              }
              if (answerForQuestion) {
                setAnswerForQuestion();
              }

            }}
            onSubmit={createAnswer}
          />
          <Divider className={classes.divider} />
        </Box>
        : <Box>
          {isCompleted && 
            <Box className={classes.completedLabel}>
              Complete
              <CheckCircle className={classes.checkmarkIcon} />
            </Box>
          }
        </Box>}
      <Grid container>
      { getRestAnswersArr(currentQuestionsAsnwers, id) }
      </Grid>
    </CardContent>);
  })

  return groupWithAnswers && <>
    {groupWithAnswers}
    <CardContent className={classes.viewBox}>
      {Comments({ comments: answerGroup?.answerGroupComments })}
    </CardContent>
  </>
}