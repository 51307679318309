import { call, put } from 'redux-saga/effects';

import { receivedResponseOnApplyToFacilitate } from "../actions/facilitatorApplication";

import * as applyToFacilitateApi from "../api/facilitatorApplication";

import {
  showErrorMessage,
  showSuccessMessage,
} from "../actions/notification";

export function* applyToFacilitate({ payload }) {
  const { programCode, data } = payload;

  try {
    const res = yield call(applyToFacilitateApi.applyToFacilitate, { programCode, data });
    if (!res?.data?.error?.length) {
      yield put(receivedResponseOnApplyToFacilitate(true));
      yield put(showSuccessMessage('Applying to facilitate club successfully registered'));
    } else {
      yield put(receivedResponseOnApplyToFacilitate(false));
      const msg = res?.data?.error?.length > 5 ? res?.data?.error : 'Error during applying to facilitate';
      yield put(showErrorMessage(msg));
    }

  } catch (e) {
    yield put(receivedResponseOnApplyToFacilitate(false));
    yield put(showErrorMessage('Error during applying to facilitate'));
    // TODO: throw generic error
    console.log('TODO: handle error here');
    console.log(e);
  }
}

