import { call, put, all } from "redux-saga/effects";

import {
  receivedFiltersData,
  receivedProjectGalleries,
  receivedMyProjectsByProgram,
  receivedProjectsByProgram,
  receivedIsUserProjectsByProgram,
  receivedUsersProjectsByProgram,
} from "../actions/projectGallery";

import * as countriesQueries from "../api/graphql/query/countries.queries";
import * as galleryProjectQueries from "../api/graphql/query/galleryProjects.queries";
import * as projectGalleryQueries from '../api/galleryProjects';

export function* getProjectGalleries({ payload }) {
  try {
    const { pageNumber, projectGalleries } = payload;

    const {
      gpData: {
        data: pagedGalleryProjects,
      },
      gpTotalScore: {
        data: { GetGalleryProjectsTotalScore },
      },
      gpActualFilters: {
        data: { GetActiveGalleryProjectFilters: actualFilters }
      }
    } = yield all({
      gpData: call(projectGalleryQueries.getPagedGalleryProjects, payload),
      gpTotalScore: call(
        galleryProjectQueries.getGalleryProjectsTotalScore,
        payload
      ),
      gpActualFilters: call(
        galleryProjectQueries.getActiveGalleryProjectFilters,
        payload
      ),
    });

    const newGalleryProjectsList =
      pageNumber > 0
        ? [...projectGalleries, ...pagedGalleryProjects]
        : pagedGalleryProjects;

    yield put(
      receivedProjectGalleries({
        projects: newGalleryProjectsList,
        totalScore: GetGalleryProjectsTotalScore,
        actualFilters,
      })
    );
  } catch (e) {
    yield put(receivedProjectGalleries([]));
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* getFiltersData() {
  try {
    const {
      data: {
        topicsTags, langTags, projectTypesTags,
      },
    } = yield call(projectGalleryQueries.getAllGalleryTags);

    const topicProjectTypes = topicsTags.map((e) => {
      return {
        label: e.name,
        filterName: "topicProjectTypes",
        attribute: e.id.toString(),
      };
    });

    const languageTags = langTags.map((e) => {
      return {
        label: e.name,
        filterName: "langProjectTypes",
        attribute: e.id.toString(),
      };
    });

    const projectTypes = projectTypesTags.map((e) => {
      return {
        label: e.name,
        filterName: "projectTypes",
        attribute: e.id.toString(),
      };
    });

    let {
      data: { getTranslatedCountriesForProgramsFilter: countriesList },
    } = yield call(countriesQueries.GetTranslatedCountryForProjectGalleryFilter, { langId: 4 });

    const countries = countriesList.map((e) => {
      return {
        label: e.name,
        filterName: "countries",
        attribute: e.id.toString(),
      };
    });
    const filtersData = {
      topicProjectTypes,
      projectTypes,
      languageTags,
      countries,
    };
    yield put(
      receivedFiltersData({ filtersData, topicsTags, langTags, projectTypesTags })
    );
  } catch (e) {
    console.log(e);
  }
}

export function* getUserProjectsByProgram({ payload }) {
  try {
    let { data } = yield call(
      projectGalleryQueries.getUserProjectsByProgram,
      payload,
    );

    yield put(
      receivedMyProjectsByProgram({
        data,
      })
    );
  } catch (e) {
    console.log(e);
  }
}

export function* getIsUserProjectsByProgram({ payload }) {
  try {
    let { data } = yield call(
      projectGalleryQueries.getIsUserProjectsByProgram,
      payload,
    );

    yield put(
      receivedIsUserProjectsByProgram({
        data,
      })
    );
  } catch (e) {
    console.log(e);
  }
}

export function* getUsersProjectsByProgram({ payload }) {
  try {
    let { data } = yield call(
      projectGalleryQueries.getUsersProjectsByProgram,
      payload,
    );

    yield put(
      receivedUsersProjectsByProgram({
        data,
      })
    );
  } catch (e) {
    console.log(e);
  }
}

export function* getProjectsByProgram({ payload }) {
  try {
    let { data } = yield call(
      projectGalleryQueries.getProjectsByProgram,
      payload,
    );

    yield put(
      receivedProjectsByProgram({
        data: !!data && !!data.length ? data : [],
      })
    );
  } catch (e) {
    console.log(e);
  }
}
