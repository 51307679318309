import { createReducer } from 'redux-act'

import * as a from '../actions/rating'

const getDefaultState = () => ({
  isLoading: true,
  programRatings: null,
})

export default () =>
  createReducer(
    {
      [a.saveRating]: state => ({
        ...state,
        isLoading: true
      }),

      [a.ratingSaved]: state => ({
        ...state,
        isLoading: false
      }),

      [a.onError]: state => {
        return {
          ...state,
          isLoading: false
        };
      },
      
      [a.programRatingsReceived]: (state, payload) => ({
        ...state,
        programRatings: payload,
      })
    },
    getDefaultState()
  )
