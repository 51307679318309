import { createReducer } from "redux-act";

import * as a from "../actions/joinClub";

const getDefaultState = () => ({
  isLoading: false,
  program: null,
  currentStep: 0,
  enrollmentForm: {},
  collegeMajors: [],
  enrollmentData: {},
});

export default () =>
  createReducer(
    {
      [a.getProgramByCode]: state => ({
        ...state,
        isLoading: true
      }),
      [a.getEnrollmentData]: state => ({
        ...state,
        isLoading: true
      }),
      [a.setCurrentStep]: state => ({
        ...state,
        isLoading: true
      }),
      [a.enrollUserInClub]: state => ({
        ...state,
        isLoading: true
      }),
      [a.receivedProgramInfo]: (state, payload) => {
        const { program, step } = payload;
        const checkingByCodeResult = state.isLoading ? !!program : state.checkingByCodeResult;
        return {
          ...state,
          isLoading: false,
          program: program,
          currentStep: step,
          checkingByCodeResult
        }
      },
      [a.receivedEnrollmentData] : (state, payload) => {
        const { step, EnrollmentFormQuery, collegeMajors, enrollmentData } = payload;
        return {
          ...state,
          isLoading: false,
          currentStep: step,
          enrollmentForm: EnrollmentFormQuery,
          collegeMajors: collegeMajors,
          enrollmentData: enrollmentData,
        }
      },
      [a.receiveCurrentStep]: (state, step) => ({
        ...state,
        isLoading: false,
        currentStep: step,
      })
    },
    getDefaultState()
  );
