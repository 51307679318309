import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import * as colors from '../../../theme/colors';

export const ProgressButton = withStyles(() => ({
  root: {
    color: colors.darkThemeGreen,
    backgroundColor: 'transparent',
    fontWeight: 'bold',
    boxShadow: "none",
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: "none",
    },
  },
}))(Button);
