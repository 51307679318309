import { getAuthHeader } from "../tokenStorage";
import { axiosInstance } from './axiosInstance';

export const createUserRoleLabel = async (userRoleLabel) => {
  return axiosInstance.post(
    `/blapi/userRoleLabels/create`,
    userRoleLabel,
    {
      headers: getAuthHeader(),
    }
  );
};

export const deleteUserRoleLabel = async (id) => {
  return axiosInstance.delete(`/blapi/userRoleLabels/delete/${id}`, {
    headers: getAuthHeader(),
  });
};

export const getUserRoleLabelsByProgramId = async (id) => {
  return axiosInstance.get(`/blapi/userRoleLabels/getByProgram/${id}`, {
    headers: getAuthHeader(),
  });
};
