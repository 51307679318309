import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";

import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

import * as colors from "../../../theme/colors";
import Tabs from "./index";
import SkeletonCards from "../../../pages/project-gallery/skeletonCards/index";
import CustomButton from "../../../components/customButton/customButton";
import PageTitle from "../../../components/page-title/pageTitle";
import UserProjectCard from "../../../components/user-project-card/user-project-card";

const useStyles = makeStyles((theme) => ({
  galleryCard: {
    borderBottom: "6px solid $grey-dark",
    boxSizing: "border-box",
    cursor: "pointer",
    margin: 12,
    padding: 24,
    transition: "transform 150ms linear",
    height: "fit-content",
    width: "-webkit-fill-available",
    "&:hover": {
      transform: "scale(1.05, 1.05)",
    },
    "&:focus": {
      outline: "none",
      transform: "scale(1.05, 1.05)",
    },
  },
  cardСover: {
    height: 200,
  },
  cardTitle: {
    overflow: "hidden",
    fontWeight: 700,
    fontSize: 16,
    height: 48,
    boxOrient: 'vertical',
    lineClamp: 2,
    display: '-webkit-box'
  },
  cardContent: {
    padding: "16px 0 0 !important",
    display: "flex",
  },
  projectLink: {
    textDecoration: "none",
  },
  contentBox: {
    width: "95%",
    margin: "auto",
  },
  title: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    color: colors.grayTheme,
    fontSize: 32,
    fontWeight: "bold",
    textTransform: 'uppercase',
  },
  titleBox: {
    minHeight: 100,
    width: "100%",
    marginLeft: '-20px'
  },
  titleContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: 20,
    minHeight: 100,
  },
  breadCrumbs: {
    fontSize: 12,
    marginTop: 20,
    marginLeft: '-5px',
  },
  nextUserBtn: {
    cursor: "pointer",
    color: colors.grayTheme,
    fontWeight: "bold",
    fontSize: 18,
    marginRight: 10,
  },
  header: {
    margin: "15px auto",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  tabsSection: {
    margin: '-10px auto 15px -25px',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      marginBottom: '0px',
      marginLeft: '-10px',
    }
  },
  viewAllBtn: {
    display: "flex",
    justifyContent: "flex-end",
    fontWeight: 700,
    fontSize: 14,
    color: colors.teal2,
    marginLeft: 'auto'
  },
  tag: {
    paddingLeft: 10,
  },
  chip: {
    fontSize: 12,
    fontWeight: 500,
    color: colors.grayTheme,
    fontFamily: "'Roboto Mono',monospace",
  },
  publishedChip: {
    backgroundColor: colors.lightBlue,
  },
  notPublishedChip: {
    backgroundColor: '#EDEDED',
  },
  publishedCardTitle: {
    color: colors.dartThemeBlueGray,
  },
  notPublishedCardTitle: {
    color: '#CECECE',
  },
  unPublishedCard: {
    opacity: '0.6',
  },
  breadCrumbsLink: {
    marginLeft: 10,
    cursor: 'pointer',
    color: colors.darkThemeGreen,
    fontWeight: "bold",
    fontSize: 14,
    textDecoration: "none",
    '&:hover': {
      color: colors.lightThemeGreen,
    },
    "&:focus": {
      outline: `2px dotted ${colors.grey400}`,
    },
  },
  breadCrumbsName: {
    color: colors.lightThemeGrey,
    fontWeight: "bold",
    fontSize: 14,
  },
  nextUserBtnIcon: {
    height: 40,
    width: 40,
  },
  nextUserBtnWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  projectIcon: {
    height: 120,
    width: 120,
  },
  emptyPageText: {
    fontSize: 22,
    fontWeight: 700,
  },
  projectIconWrapper: {
    paddingBottom: 10,
  },
  emptyPageCard: {
    width: '100%',
    height: '100%',
    minHeight: 'calc(100vh - 400px)',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    display: 'flex',
    padding: 15,
  },
}));

const UserProjects = ({
  getUserProjectsByProgram,
  galleryProjects,
  programId,
  userId,
  isLoadingProjects,
  setCurrentTab,
  isUserProjects,
  currentTab,
  push,
  nextUserProgressId,
  navigateBack,
  user,
  showCertificates,
  showAnswers,
  projectIcon,
  userFullName,
}) => {
  
  useEffect(() => {
    if (programId !== undefined) {
      getUserProjectsByProgram({ programId, userId });
    }
  }, [programId, userId]);

  const classes = useStyles();

  const showUserHeader = parseInt(userId, 10) !== user?.id;

  const keyDown = (e) => {
    if (e.key === "Enter") {
      e.target.click();
    }
    return;
  }
  
  return (
    <Box className={classes.contentBox}>
      {!showUserHeader &&
        <Box className={classes.header}>
          <PageTitle title="Progress" isLoading={isLoadingProjects} />
        </Box>
      }
      {showUserHeader && (
      <>
        <Breadcrumbs
          className={classes.breadCrumbs}
          separator=" / "
          aria-label="breadcrumb"
        >
          <Link
            onKeyDown={keyDown}
            tabIndex={0}
            underline="none"
            className={classes.breadCrumbsLink}
            onClick={() => { navigateBack(null) }}
          >
            Progress
          </Link>
          <Typography className={classes.breadCrumbsName}>
            {userFullName}
          </Typography>
        </Breadcrumbs>
        <Box className={classes.titleBox}>
          <Box className={classes.titleContent}>
            <Box className={classes.title}>
              <PageTitle title={userFullName} isLoading={isLoadingProjects} />
            </Box>
            <Box className={classes.nextUserBtnWrapper} >
              {nextUserProgressId && (
                <>
                <Typography
                  className={classes.nextUserBtn}
                  onClick={() => {
                    navigateBack(nextUserProgressId);
                  }}
                >
                  {"Next Member"}
                </Typography>
                <CustomButton aria-label="Next Member" size="large" mode="secondary" onClick={() => { navigateBack(nextUserProgressId) }}
                  className={classes.nextUserBtnIcon}
                >
                  <ArrowForwardIcon />
                </CustomButton>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </>
      )}
      <div className={classes.tabsSection}>
        <Tabs
          value={currentTab}
          showProjectTab={isUserProjects}
          showCertificateTab={showCertificates}
          showAnswers={showAnswers}
          onChange={(evt, newValue) => {
            setCurrentTab(newValue);
          }}
        />
        <Button
          className={classes.viewAllBtn}
          onClick={() => {
            push(`/profile/${userId}`);
          }}
        >
          VIEW ALL PROJECTS
        </Button>
      </div>
      <Grid container className={classes.projectsTable}>
        {isLoadingProjects
          ? <SkeletonCards count={4}/>
          : !!galleryProjects?.length ? galleryProjects.sort((a,b) => b.isPublished - a.isPublished).map((project, id) => 
            <UserProjectCard 
              key={id}
              classes={classes}
              project={project}
              currentTab={currentTab}
            />) :
            <Card className={classes.emptyPageCard}>
              <CardContent>
                {!!projectIcon && <Box className={classes.projectIconWrapper}>
                  <img className={classes.projectIcon} src={projectIcon.src} alt={projectIcon.alt} />
                </Box>}
                <Typography className={classes.emptyPageText}>
                  This member doesn't have any projects, yet!
                </Typography>
              </CardContent>
            </Card>
        }
      </Grid>
    </Box>
  );
};

export default UserProjects;
