import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import * as colors from "../../../../theme/colors";

import HqAvatar from "../../../../components/avatar/avatar";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";

import { sortLastThenFirst } from '../../../../utils/generic';
import CopyLinkButton from '../../../../components/copyLinkButton/copyLinkButton';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px 15px 15px 15px",
  },
  title: {
    fontSize: 16,
    textTransform: "uppercase",
    fontWeight: 700,
  },
  media: {
    height: 0,
    paddingTop: "56.25%",
  },
  contentWrapper: {
    display: "flex",
  },
  avatar: {
    margin: "auto 10px auto 0",
  },
  contentLabel: {
    padding: 0,
    wordBreak: "break-all",
    color: colors.darkThemeBlueGray,
    fontWeight: "normal",
  },
  emailCopy: {
    width: 1,
    height: 1,
    opacity: 0,
  },
  copyBtn: {
    fontSize: 12,
    fontWeight: "normal",
    cursor: "pointer",
  },
  contentBox: {
    marginBottom: 15,
  },
  labelBox: {
    color: colors.darkThemeGreen,
    fontWeight: "bold",
    cursor: "pointer",
    "&:hover": {
      color: colors.lightThemeGreen,
    },
  },
  content: {
    padding: "0 0 0 4px",
    "&:last-child": {
      paddingBottom: 0,

      [theme.breakpoints.down("xs")]: {
        wordBreak: "break-all",
      },
    },
  },
}));

export const RenderCardContent = ({ item, index, push }) => {
  const classes = useStyles();

  return (
    <Box key={index} className={classes.contentBox}>
      <Box className={classes.contentWrapper}>
        <Box className={classes.avatar}>
          <HqAvatar user={item} src={item.avatarName && item.avatarUrl} />
        </Box>
        <Box className={classes.boxContent}>
          <Typography sel = "facilitator"
            className={classes.labelBox}
            onClick={() => {
              push(`/profile/${item.id}`);
            }}
          >
            {`${item.firstName} ${item.lastName}`}
          </Typography>

          <Box className={classes.boxContent}>
            {item.email && <CopyLinkButton link={item.email} />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const CommunityPartnerCard = ({ partnership, push }) => {
  const classes = useStyles();

  const data = partnership && partnership.communityPartners
    ? sortLastThenFirst(partnership.communityPartners)
    : [];

  return (
    <Card className={classes.root}>
      <CardHeader
        title={<Typography className={classes.title}>Community Partners</Typography>}
        className={classes.cardHeader}
      />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          {data && !!data.length ? (
            data.map((e, index) => {
              return <RenderCardContent item={e} index={index} push={push} />;
            })
          ) : (
            <Typography>Partnership has no Community Partners yet</Typography>
          )}
        </CardContent>
      </div>
    </Card>
  );
};

export default CommunityPartnerCard;