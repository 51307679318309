import React from 'react'

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import * as colors from '../../theme/colors';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Menu from "@material-ui/core/Menu";
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';

import AlertDialog from './AlertDialog';
import ResetPasswordDialog from './ResetPasswordDialog';
import HqAvatar from '../../components/avatar/avatar';
import Link from "../../components/Link";
import { getUserName } from "../../utils/generic";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '8px 8px 12px 8px',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  regularLink: {
    "&:hover": {
      textDecoration: "none"
    },
    fontWeight: 600,
  },
  redLink: {
    color: colors.red300,
    "&:hover": {
      textDecoration: "none"
    },
    fontWeight: 600,
  },
  cardHeader: {
    padding: 0,

    [theme.breakpoints.down("xs")]: {
      wordBreak: 'break-all',
    },
  },
  cardContent: {
    padding: '0 0 0 4px',
    '&:last-child': {
      paddingBottom: 0,

      [theme.breakpoints.down("xs")]: {
        wordBreak: 'break-all',
      },
    },
  },
  lastLogin: {
    marginTop: 8,
    fontFamily: "'Roboto Mono',monospace",
    '& b': {
      fontFamily: "'Roboto', sans-serif"
    }
  },
}));

const UsersCard = (props) => {
  const classes = useStyles();

  const {
    member: {
      userId, 
      user,
    },
    excludeUser,
    onChangePassword,
    hasContextMenu,
    profileContent,
  } = props;

  const { email, username, firstName, lastLogin, createdAt } = user;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [alertOpened, setAlertOpened] = React.useState(false);
  const [passwordDialogOpened, setPasswordDialogOpened] = React.useState(false);

  const title = () => (<p>{getUserName(user)}</p>);

  const getLastLoginDate = () => {
    if (lastLogin) {
      return moment(lastLogin).local().format('MM/DD/YYYY');
    }
    else if (createdAt) {
      return moment(createdAt).local().format('MM/DD/YYYY');
    }
    else {
      return 'never';
    }
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeAlert = () => {
    setAlertOpened(false);
  };

  const removeUser = () => {
    excludeUser(userId);
    setAlertOpened(false);
  };

  const closePasswordDialog = () => {
    setPasswordDialogOpened(false);
  };

  const changePassword = (values) => {
    onChangePassword({
      ...values,
      userId: user.id,
    });
    closePasswordDialog();
  };

  return (
    <Card className={classes.root} sel="user-card">
      <CardHeader
        avatar={
          <HqAvatar
            user={user}
            src={user.avatarName && user.avatarUrl}
          />
        }
        action={hasContextMenu &&
          <IconButton aria-label="settings" onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        }
        title={
          <Link className={classes.profileLink} href={`/profile/${user.id}`}>{ title() }</Link>
        }
        className={classes.cardHeader}
        sel="user-full-name"
      />
      <CardContent className={classes.cardContent}>
        <Typography variant="body2" color="textSecondary" component="p" className={classes.email} sel="email-username">
          {email ? email : username}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p" className={classes.lastLogin}>
          <b>{`${lastLogin ? 'Last login' : 'Created At'}: `}</b>{getLastLoginDate()}
        </Typography>
      </CardContent>
      {hasContextMenu && <>
      <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            className={classes.regularLink}
            id={user.id}
            onClick={() => {
              handleClose();
              setPasswordDialogOpened(true);
            }}
            sel="reset-password"
          >
            Reset Password
          </MenuItem>
          <MenuItem
            className={classes.redLink}
            id={user.id}
            onClick={() => {
              handleClose();
              setAlertOpened(true);
            }}
            sel="remove-member"
          >
            Remove Member
          </MenuItem>
        </Menu>
        <AlertDialog
          open={alertOpened}
          onClose={closeAlert}
          onConfirm={removeUser}
        />
        {profileContent && profileContent.changePasswordForm && <ResetPasswordDialog
          firstName={firstName}
          email={email}
          username={username}
          open={passwordDialogOpened}
          onClose={closePasswordDialog}
          profileContent={profileContent}
          onSubmit={changePassword}
        />}
        </>}
    </Card>
  );
};

export default UsersCard;
