import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

import * as tokenStorage from '../tokenStorage';

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  useEffect(() => {
    const token = tokenStorage.getToken();

    if (!token) return;
  }, []);

  return (<Route
    {...rest}
    render={props => {
     const { pathname, search } = props.location;
     const redirectPath = pathname ? pathname.length > 1 && !pathname.includes('/home') : false;
     const qrCodeInvite = pathname.includes('/join-program/qrCodeInvitation')
     const token = tokenStorage.getToken();
     if (token) {
      return (<Component {...props} />);
     }

     if (qrCodeInvite) {
      window.location = `/login/?showTab=signup&qrClubCode=true&redirect=${encodeURIComponent(`${pathname}${search}`)}`;
     } else {
      window.location = redirectPath ? `/?redirect=${encodeURIComponent(`${pathname}${search}`)}`: '/';
     }
    }}
  />);
};

export default AuthenticatedRoute;
