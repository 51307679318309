import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import * as colors from "../../../theme/colors";

import HqAvatar from "../../../components/avatar/avatar";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";

import { sortLastThenFirst } from '../../../utils/generic';

const useStyles = makeStyles(() => ({
  root: {
    padding: "8px 8px 12px 8px",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  media: {
    height: 0,
    paddingTop: "56.25%",
  },
  content: {
    padding: '0 0 0 4px',
    "&:last-child": {
      paddingBottom: 0
    },
  },
  contentWrapper: {
    display: "flex",
  },
  avatar: {
    margin: "auto 10px auto 0",
  },
  contentLabel: {
    padding: "0 8px",
    wordBreak: "break-all",
  },
  emailCopy: {
    width: 1,
    height: 1,
    opacity: 0,
  },
  copyBtn: {
    cursor: "pointer",
  },
  contentLabel: {
    color: colors.darkThemeBlueGray,
    wordBreak: "break-all",
  },
  cardHeader: {
    padding: 0,
    marginBottom: 12
  },
  contentBox: {
    marginBottom: 15,
  },
  labelBox: {
    color: colors.darkThemeGreen,
    fontWeight: "bold",
    cursor: "pointer",
    "&:hover": {
      color: colors.lightThemeGreen,
    },
  },
}));

export const RenderCardContent = ({ item, index, push, isViewer, isEditable }) => {
  const classes = useStyles();

  return (
    <Box key={index} className={classes.contentBox}>
      <Box className={classes.contentWrapper}>
        <Box className={classes.avatar}>
          <HqAvatar user={item} src={item.avatarName && item.avatarUrl} />
        </Box>
        <Box className={classes.boxContent}>
          <Typography
            className={classes.labelBox}
            sel="partners"
            onClick={() => {
              push(`/profile/${item.id}`);
            }}
          >
            {`${item.firstName} ${item.lastName}`}
          </Typography>
          <Box className={classes.boxContent}>
            {(isViewer || isEditable) && (
              <Typography className={classes.contentLabel}>
                {`${item.email}`}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const ViewersCard = ({ club, push, isViewer, isEditable, viewerHeader }) => {
  const classes = useStyles();

  const data = club && club.viewers
    ? sortLastThenFirst(club.viewers)
    : [];

  return (
    <Card className={classes.root}>
      <CardHeader
        title={<Typography className={classes.title}>{viewerHeader || 'Partners'}</Typography>}
        className={classes.cardHeader}
      />
      <CardContent className={classes.content}>
        {data &&
          data.map((e, index) => {
            return (
              <RenderCardContent
                item={e}
                index={index}
                push={push}
                isViewer={isViewer}
                isEditable={isEditable}
              />
            );
          })}
      </CardContent>
    </Card>
  );
};

export default ViewersCard;
