import Image from './Image';
import Messaging from './Messaging';

export const commonFieldAttributes = `
  type
  name
  id
  labelPrompt
  icon {
    ${Image}
  }
  helpText
  validationErrors {
    ${Messaging}
  }
  infoText`;

export const formField = `
  fieldType
  accessibleInstructions
`;

export const passwordField = `
  accessibleInstructions
  tooltipMessages {
    ${Messaging}
  }
`;

export const selectField = `
  id
  labelPrompt
  accessibleInstructions
  options {
    name
    label
    value
  }
`;

export const questionField = `
  question
  answers {
    ${selectField}
  }
`;

export const anyFormField = `
  ${commonFieldAttributes}
  ...on FormField {
    ${formField}
  }
  ...on PasswordField {
    ${passwordField}
  }
  ...on SelectField {
    ${selectField}
  }
  ...on QuestionField {
    ${questionField}
  }
`;
