import { createReducer } from "redux-act";

import * as a from "../actions/openText";

const getDefaultState = () => ({
  isLoadingAnswer: false,
  isLoadingComments: false,
  isLoadingAnswersForActivity: false,
  isLoadingCommentsByUser: false,
  isLoadingCommentsForAnswerByUser: false,
  isAddingComment: false,
  isUpdatingComment: false,
  isRemovingComment: false,
  userAnswers: {},
  programActivityAnswers: {},
  programAnswers: {},
  programMembers: {},
});

export default () =>
  createReducer(
    {
      [a.createAnswer]: (state) => ({
        ...state,
        isLoadingAnswer: true,
      }),
      [a.createAnswerError]: (state) => ({
        ...state,
        isLoadingAnswer: false,
      }),
      [a.receivedAnswer]: (state, payload) => {
        const { path, question, answer } = payload;
        const itemKey = `${path}-${question}`;
        const newAnswers = {
          ...state.userAnswers,
          [itemKey]: [answer, ...(state.userAnswers[itemKey] || [])],
        };

        return {
          ...state,
          userAnswers: newAnswers,
          isLoadingAnswer: false,
        };
      },
      [a.getMyAnswersByProgram]: (state) => ({
        ...state,
        isLoadingAnswer: true,
      }),
      [a.getMyAnswersByProgramError]: (state) => ({
        ...state,
        isLoadingAnswer: false,
      }),
      [a.getCommentsByAnswer]: (state) => ({
        ...state,
        isLoadingComments: true,
      }),
      [a.getCommentsByAnswerError]: (state) => ({
        ...state,
        isLoadingComments: false,
      }),
      [a.receivedMyAnswersByProgram]: (state, payload) => {
        const { data } = payload;
        return {
          ...state,
          userAnswers: data,
          isLoadingAnswer: false,
        };
      },
      [a.getUserAnswersByProgram]: (state) => ({
        ...state,
        isLoadingAnswer: true,
      }),
      [a.getUserAnswersByProgramError]: (state) => ({
        ...state,
        isLoadingAnswer: false,
      }),
      [a.receivedUserAnswersByProgram]: (state, payload) => {
        const { data } = payload;
        return {
          ...state,
          userAnswers: data,
          isLoadingAnswer: false,
        };
      },
      [a.getAnswersForActivity]: (state) => ({
        ...state,
        isLoadingAnswersForActivity: true,
      }),
      [a.receivedAnswersForActivity]: (state, payload) => {
        const { activity, data } = payload;
        return {
          ...state,
          programActivityAnswers: {
            ...state.programActivityAnswers,
            [activity]: data,
          },
          isLoadingAnswersForActivity: false,
        };
      },
      [a.receivedCommentsByAnswer]: (state, payload) => {
        const { itemKey, comments } = payload;

        const updatedUserAnswers = {
          ...state.userAnswers,
          [itemKey]: state.userAnswers[itemKey].map((answer) => ({
            ...answer,
            comments: comments.filter((comment) => comment.answerId === answer.id),
          })),
        };

        return {
          ...state,
          isLoadingComments: false,
          userAnswers: updatedUserAnswers,
        };
      },
      [a.getCommentsForAnswerByUser]: (state) => ({
        ...state,
        isLoadingCommentsForAnswerByUser: true,
      }),
      [a.getCommentsForAnswerByUserError]: (state) => ({
        ...state,
        isLoadingCommentsForAnswerByUser: false,
      }),
      [a.receiveCommentsForAnswerByUser]: (state, payload) => {
        const { itemKey, comments } = payload;

        const updatedUserAnswers = {
          ...state.userAnswers,
          [itemKey]: state.userAnswers[itemKey].map((answer) => ({
            ...answer,
            comments: comments.filter((comment) => comment.answerId === answer.id),
          })),
        };

        return {
          ...state,
          isLoadingCommentsForAnswerByUser: false,
          userAnswers: updatedUserAnswers,
        };
      },
      [a.receivedAllAnswersForProgram]: (state, payload) => {
        const { answers, members } = payload;

        return {
          ...state,
          programAnswers: answers,
          programMembers: members,
        };
      },
      [a.getCommentsByUser]: (state) => ({
        ...state,
        isLoadingCommentsByUser: true,
      }),
      [a.getCommentsByUserError]: (state) => ({
        ...state,
        isLoadingCommentsByUser: false,
      }),
      [a.receiveCommentsByUser]: (state, payload) => {
        const { userId, fullPath, comments } = payload;

        const updatedProgramAnswers = {
          ...state.programAnswers,
          [fullPath]: {
            ...state.programAnswers[fullPath],
            [userId]: state.programAnswers[fullPath][userId].map((answer) => ({
              ...answer,
              comments: comments.filter((comment) => comment.answerId === answer.id),
            })),
          },
        };

        return {
          ...state,
          programAnswers: updatedProgramAnswers,
          isLoadingCommentsByUser: false,
        };
      },
      [a.addComment]: (state) => ({
        ...state,
        isAddingComment: true,
      }),
      [a.addCommentError]: (state) => ({
        ...state,
        isAddingComment: false,
      }),
      [a.receiveAddedComment]: (state, payload) => {
        const { answerId, userId, itemKey, data } = payload;

        const updatedProgramAnswers = {
          ...state.programAnswers,
          [itemKey]: {
            ...state.programAnswers[itemKey],
            [userId]: state.programAnswers[itemKey][userId].map((answer) => {
              if (answer.id === answerId) {
                return {
                  ...answer,
                  comments: [data, ...answer.comments],
                  totalComments: answer.totalComments + 1,
                };
              }
              return answer;
            }),
          },
        };

        return {
          ...state,
          isAddingComment: false,
          programAnswers: updatedProgramAnswers,
        };
      },
      [a.updateComment]: (state) => ({
        ...state,
        isUpdatingComment: true,
      }),
      [a.updateCommentError]: (state) => ({
        ...state,
        isUpdatingComment: false,
      }),
      [a.receiveUpdatedComment]: (state, payload) => {
        const { answerId, userId, itemKey, data } = payload;

        const updatedProgramAnswers = {
          ...state.programAnswers,
          [itemKey]: {
            ...state.programAnswers[itemKey],
            [userId]: state.programAnswers[itemKey][userId].map((answer) => {
              if (answer.id === answerId) {
                return {
                  ...answer,
                  comments: answer.comments.map((comment) => {
                    if (comment.id === data.id) {
                      return {
                        ...comment,
                        content: data.content,
                        updatedAt: new Date(),
                      };
                    }
                    return comment;
                  }),
                };
              }
              return answer;
            }),
          },
        };

        return {
          ...state,
          isUpdatingComment: false,
          programAnswers: updatedProgramAnswers,
        };
      },
      [a.receivedUpdatedStatus]: (state, payload) => {
        const { id, itemKey, data: { userId, status, statusChangedBy, statusChangedAt, statusChanger } } = payload;

        const updatedProgramAnswers = {
          ...state.programAnswers,
          [itemKey]: {
            ...state.programAnswers[itemKey],
            [userId]: state.programAnswers[itemKey][userId].map((answer) => {
              if (answer.id === id) {
                return {
                  ...answer,
                  status: status ? 1 : 0,
                  statusChangedAt,
                  statusChangedBy,
                  statusChanger,
                };
              }
              return answer;
            }),
          },
        };

        return {
          ...state,
          programAnswers: updatedProgramAnswers,
        };
      },
      [a.deleteComment]: (state) => ({
        ...state,
        isRemovingComment: true,
      }),
      [a.deleteCommentError]: (state) => ({
        ...state,
        isRemovingComment: false,
      }),
      [a.receivedDeletedComment]: (state, payload) => {
        const { answerId, id, userId, itemKey } = payload;

        const updatedProgramAnswers = {
          ...state.programAnswers,
          [itemKey]: {
            ...state.programAnswers[itemKey],
            [userId]: state.programAnswers[itemKey][userId].map((answer) => {
              if (answer.id === answerId) {
                const newComments = answer.comments.filter((comment) => comment.id !== id);
                return {
                  ...answer,
                  comments: newComments,
                  totalComments: newComments.length,
                };
              }
              return answer;
            }),
          },
        };

        return {
          ...state,
          programAnswers: updatedProgramAnswers,
          isRemovingComment: false,
        };
      },
      [a.resetAnswerProgress]: () => getDefaultState(),
    },
    getDefaultState()
  );
