import React, { useState, useEffect } from "react";

import { Field } from "formik";
import clsx from 'clsx';
import * as moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Link } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import TextInput from "../../../components/TextInput";

import * as colors from "../../.././theme/colors";
import { getCountryFormat } from "../../../utils/generic";

const useStyles = makeStyles({
  subTitle: {
    color: colors.darkThemeBlueGray,
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center"
  },
  boxWrapper: {
    marginBottom: 20,
    fontSize: 14,
    fontWeight: 400,
  },
  userProfile: {
    marginTop: 10,
  },
  subLabel: {
    fontSize: 14,
    "& .MuiTypography-body1": {
      fontSize: 14,
    }
  },
  infoContainer: {
    border: "1px solid " + colors.lightGrey,
    padding: "5px 15px",
    borderColor: colors.darkThemeGreen,
  },
  infoLinkBlock: {
    textAlign: "right",
    textDecoration: "underline",
    textDecorationColor: colors.darkThemeGreen,
  },
  infoLink: {
    color: colors.darkThemeGreen,
    fontSize: 14,
    fontWeight: 700,
    textDecoration: "none",
    padding: '0 0 0 5px',
    "&:hover": {
      textDecoration: "none",
    },
  },
  descriptionItem: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 20,
    textTransform: "uppercase",
    "& .MuiListItemText-primary": {
      color: colors.lightGrey,
    },
    "& .MuiListItemText-secondary": {
      color: "black",
      textTransform: "none",
    },
    "& .MuiTypography-body1": {
      margin: 0,
    },
    "& .MuiTypography-body2": {
      margin: 0,
      fontSize: "1rem",
      wordBreak: "break-word",
      "& button": {
        textAlign: "left",
      },
    }
  },
  subDescription: {
    marginTop: 20,
  },
  clubCode: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.darkThemeGreen,
    },
  },
  clubCodeTitle: {
    textTransform: "uppercase",
    color: colors.lightGrey,
    fontSize: 14,
  },
  errorStyle: {
    color: "red",
    fontSize: 12,
  }
});

const FirstStep = ({ user = {}, program, isSubmitStarted, isLoading, domesticCountry, defaultProgramId }) => {
  const classes = useStyles();

  const [isDefaultProgram, setIsDefaultProgram] = useState(false);

  const isInvalidProgram = !Object.keys(program).length;

  useEffect(() => {
    setIsDefaultProgram(program.id === defaultProgramId);
  }, [program, defaultProgramId])

  return (
    <>
      <Box className={classes.boxWrapper}>
        <Typography className={classes.subLabel}>We're excited that you're interested in joining the
        Girls Who Code global movement to close the gender gap in tech with our Clubs program</Typography>
      </Box>
      <Box className={classes.infoContainer}>
        <Box className={classes.userProfile}>
          <Typography className={classes.subTitle}>
            User Profile
          </Typography>
        </Box>
        <ListItemText
          primary="Name"
          className={classes.descriptionItem}
          secondary={`${user.firstName || ''} ${user.lastName || ''}`}
        />
        <ListItemText
          primary="Email"
          className={classes.descriptionItem}
          secondary={user.email|| ''}
        />
        <ListItemText
          primary="Date of birth"
          className={classes.descriptionItem}
          secondary={moment
            .parseZone(user.birthDate)
            .format(getCountryFormat(user.countryId, domesticCountry).toUpperCase())}
        />
        <Typography className={clsx(classes.subLabel, classes.infoLinkBlock)}>
          <Link
            target="_blank"
            className={classes.infoLink}
            href={"/user-profile"}
          >Update Information</Link>
        </Typography>
      </Box>
      <Box className={`${classes.boxWrapper} ${classes.subDescription}`}>
        <Typography className={classes.subLabel}>
          Please enter the Club Code for the Club you'd like to join as a Facilitator below:
        </Typography>
      </Box>
      <Box>
        <Typography className={classes.clubCodeTitle}>Club code</Typography>
      </Box>
      <Field
        name="clubCode"
        component={TextInput}
        className={classes.clubCode}
        label=""
        placeholder="Code"
        setTouchedOnChange={true}
        helperText={
          !isLoading && isSubmitStarted && (
            isInvalidProgram ? (
              <Typography className={classes.errorStyle}>Please check that you have typed your code correctly</Typography>
            ) : isDefaultProgram ? (
              <Typography className={classes.errorStyle}>Code belongs to default program</Typography>
            ) : null
          )
        }
      />
    </>
  );
};

export default FirstStep;
