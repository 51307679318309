import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { push as pushToRoute } from "connected-react-router";

import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import { Box, Typography } from "@material-ui/core/";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

import * as authActions from "../../actions/auth";
import * as contentfulActions from "../../actions/contentful";
import * as facilitatorApplicationActions from '../../actions/facilitatorApplication';
import * as notificationActions from "../../actions/notification";
import * as clubProfile from '../../actions/clubProfile';


import ApplyPageCard from "./applyPageCard";
import Footer from "../../containers/Footer/footer";
import { connectTo } from "../../utils/generic";
import * as colors from '../../theme/colors'
import TextCustomButton from "../../components/text-button/text-button";
import PageTitle from "../../components/page-title/pageTitle";
import { useTitle } from "../../utils/pageTitles";
import { userIsAdult, userIsNotAStudent } from "../../utils/userUtils";
import ApplyForm from "./applyForm";
import history from '../../history';
import { PageLeaveModal } from '../../components/modals/pageLeaveWarning';


const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  content: {
    maxWidth: 1000,
    paddingLeft: 10,
    margin: "auto auto",
    height: "100%",
    minHeight: 'calc(100vh - 135px)',
  },
  subTitle: {
    height: 30,
    width: "100%",
    fontSize: 24,
    fontWeight: 500,
    marginTop: 20,
    fontWeight: "bold",
  },
  agePolicyCard: {
    padding: 20,
    marginTop: 15,
  },
  backLink: {
    color: colors.darkBlack,
    fontWeight: "bold",
    textDecoration: "none",
  },
  clubWrapper: {
    display: "flex",
    [theme.breakpoints.down('xs')]: {
      flexWrap: "wrap",
    },
  },
  backButtonApplyBox: {
    margin: "auto",
    justifyContent: "center",
    maxWidth: 500,
  }
}));

const PAGE_URL = '/gwc-club-application';

const Apply = ({  user, contentful, loadHomePageContent, push,
  applyToFacilitate,
  showErrorMessage,
  facilitatorApplication: {
    isLoadingApplicationToFacilitate,
    isAppliedToFacilitate,
  
  },
  facilitatorApplicationIcon,
  getClubInfoByCode,
  searchProgram,
  cleanClubInfoByCode,
  cleanApplyToFacilitate,
  isLoadingProgram,
}) => {
  useTitle("apply");

  const classes = useStyles();

  const [startClubSection, setStartClubSection] = useState(null);
  const [facilitateExistingClubSection, setFacilitateExistingClubSection] = useState(null);
  const [collegeLoopSection, setCollegeLoopSection] = useState(null);
  const [startCollegeLoopLink, setStartCollegeLoopLink] = useState(null);
  const [applyRequest, setApplyRequest] = useState(false);
  const [domesticCountry, setDomesticCountry] = useState(null);
  const [isEscapeConfirmDialog, setEscapeConfirmDialog] = useState(false);
  const [defaultProgramId, setDefaultProgramId] = useState(null);
  const applyRef = useRef();

  useEffect(() => {
    window.history.pushState(null, null, window.location.href);    
    const onBack = () => {
      applyRequest ? setApplyRequest(false) : push('/home')  
    };
    window.addEventListener('popstate', onBack);
    return () => { 
      window.removeEventListener('popstate', onBack);
    }
  }, [applyRequest, setApplyRequest])

  useEffect(() => {
    if (!contentful.hqConfig || !contentful.GlobalComponentQuery) {
      return;
    }

    const {
      hqConfig: { domesticCountryId, defaultProgram },
    } = contentful;

    setDomesticCountry(parseInt(domesticCountryId, 10));
    setDefaultProgramId(+defaultProgram);
  }, [contentful]);

  useEffect(() => {
    if (!user) return;

    // if user doesn't have permission - push to main page
    if (!showContent() && !!history.location.search) return push(PAGE_URL);

    if (history?.location?.search.includes('application=true')) {
      setApplyRequest(true);
    }

    if (history.location.search.includes('facilitate=true')) {
      onFacilitateExistingClubClick();
    }
  }, [user]);
  // if the user is over 18 or is not a student - show content
  const showContent = () => {
    return userIsAdult(user) || userIsNotAStudent(user);
  }

  const onStartClubClick = () => {
    push("/start-club-application")
  }

  const onStartCollegeLoopClick = () => {
    if (startCollegeLoopLink) {
      window.open(startCollegeLoopLink, '_blank');
    }
  }

  const onFacilitateExistingClubClick = () => {
    cleanClubInfoByCode();
    cleanApplyToFacilitate();
    setApplyRequest(!applyRequest);
  }

  useEffect(() => {
    if (!contentful.HomePageQuery) {
      loadHomePageContent();
      return;
    }

    const {
      HomePageQuery: { facilitateAnExistingClubSection, startNewClubSection, collegeLoopSection, showCollegeLoopSection, startCollegeLoopLink },
    } = contentful;

    setStartClubSection(startNewClubSection);
    setFacilitateExistingClubSection(facilitateAnExistingClubSection);
    if (showCollegeLoopSection) {
      setCollegeLoopSection(collegeLoopSection);
      setStartCollegeLoopLink(startCollegeLoopLink);
    }
  }, [user, contentful]);

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Box className={`${applyRequest ? classes.backButtonApplyBox : classes.backButtonBox}`}>
          <TextCustomButton
            mode="primary"
            startIcon={<KeyboardArrowLeftIcon />}
            onClick={() => {
              const codeClub = applyRef.current && applyRef.current.getCodeClub();
              applyRequest 
                ? codeClub
                  ? setEscapeConfirmDialog(true)
                  : setApplyRequest(!applyRequest)
                : push("/home")}
            }
            label={`Back to ${applyRequest ? 'Apply Page' : 'My Programs'}`}
          />
        </Box>
        { !applyRequest && (<PageTitle title="Apply" />)}
        <Box className={classes.wrapper}>
        { !applyRequest && (Boolean(user) && showContent() ? (
          <Box>
            <Typography className={classes.subTitle}>K-12 Programs</Typography>
            <Box className={classes.clubWrapper}>
              {startClubSection && <ApplyPageCard
                onClick={() => onStartClubClick()}
                section={startClubSection}
               />}
              {facilitateExistingClubSection && <ApplyPageCard
                onClick={() => onFacilitateExistingClubClick()}
                section={facilitateExistingClubSection}
               />}
            </Box>
            {collegeLoopSection && <>
              <Typography className={classes.subTitle}>College & Career Programs</Typography>
              <ApplyPageCard
                onClick={() => onStartCollegeLoopClick()}
                section={collegeLoopSection}
               />
            </>}
          </Box>
        ) :
          <Card className={classes.agePolicyCard}>
            We're sorry. You must be 18 or older to apply to start a Club. Click{" "}
            <Link to="/home" className={classes.backLink}>
              My Programs
            </Link>{" "}
            to return to your homepage.
          </Card>
        )}
        {applyRequest && 
          <Box className={classes.wrapper}>
            <ApplyForm
              user={user}
              applyToFacilitate={applyToFacilitate}
              isAppliedToFacilitate={isAppliedToFacilitate}
              isLoadingApplicationToFacilitate={isLoadingApplicationToFacilitate}
              showErrorMessage={showErrorMessage}
              push={push}
              facilitatorApplicationIcon={facilitatorApplicationIcon}
              getClubInfoByCode={getClubInfoByCode}
              searchProgram={searchProgram}
              cleanClubInfoByCode={cleanClubInfoByCode}
              domesticCountry={domesticCountry}
              defaultProgramId={defaultProgramId}
              ref={applyRef}
              isLoadingProgram={isLoadingProgram}
            />
          </Box>}
      </Box>
      </Box>
      <Footer />
      <PageLeaveModal
        onConfirm={() => {
          setApplyRequest(!applyRequest);
          setEscapeConfirmDialog(false);
        }}
        onCancel={() => { setEscapeConfirmDialog(false); }}
        open={isEscapeConfirmDialog}
      />
    </Box>
  );
};

export default connectTo(
  (state) => {
    return {
      user: state.auth.user,
      contentful: state.contentful,
      facilitatorApplication: state.facilitatorApplication,
      facilitatorApplicationIcon: state.contentful?.HomePageQuery?.facilitatorApplicationIcon,
      searchProgram: state.clubProfile.programByCode,
      isLoadingProgram: state.clubProfile.isLoading,
    };
  },
  { push: pushToRoute, ...authActions, ...contentfulActions,
    ...facilitatorApplicationActions, ...notificationActions,
    ...clubProfile },
  Apply
);
