import * as colors from "../theme/colors";

export const ADDED_BY_PARENT = 'ADDED_BY_PARENT';
export const ADDED_BY_CHILD = 'ADDED_BY_CHILD';

export const PROGRAM_TYPES = {
  CLUB: "CLUB",
  COLLEGE_LOOP: 'COLLEGE_LOOP',
  SIP: "SIP",
  COMMUNITY_PARTNERSHIP: "COMMUNITY_PARTNERSHIP",
  SPP: "SPP",
}

export const PROGRAM_ROLE_IDS = {
  Facilitator: 1,
  Member: 2,
  Viewer: 3,
}

export const TASK_STATUSES = {
  review: null,
  incomplete: 0,
  complete: 1,
  notStarted: -1,
};

export const SCHOOL_CLUBS = {
  ELEMENTARY_SCHOOL: 'Elementary School',
  HIGH_SCHOOL: 'High School',
  K12_SCHOOL: 'K-12 School',
  MIDDLE_SCHOOL: 'Middle School',
  MS_HS: 'MS/HS',
  PRIVATE_SCHOOL: 'Private School',
  PUBLIC_SCHOOL: 'Public School'
};

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_TIMEZONE = 'YYYY-MM-DDT00:00:00Z';

export const cpProgramTypeDetails = {
  programType: PROGRAM_TYPES.COMMUNITY_PARTNERSHIP,
  name: 'Community Partnership',
  backgroundColor: colors.darkThemeGreen,
  fontColor: colors.white,
  programIcon: 'communityPartnershipIcon',
};
