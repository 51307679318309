import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import TextCustomButton from "../../../components/text-button/text-button";
import { TimezoneModal } from "../../../components/modals/eventScheduler/timezoneModal";
import * as colors from "../../../theme/colors";

const useStyles = makeStyles({
  root: {
    border: "1px solid #7A7A78",
    marginTop: 30,
  },
  title: {
    paddingLeft: 10,
    marginTop: 5,
    color: colors.darkThemeBlueGray,
    fontSize: 18,
    fontWeight: "bold",
  },
  contentWrapper: {
    margin: 10,
  },
  changeTimezoneBtn: {
    margin: "auto",
    padding: 8,
    display: "flex",
  },
});

const TimezoneSection = ({ timezone, updateTimezone }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box className={classes.root}>
      <TimezoneModal
        timezone={timezone}
        isOpen={isOpen}
        onCloseModal={() => setIsOpen(false)}
        onSave={(values) => {
          updateTimezone(values);
          setIsOpen(false);
        }}
      />
      <Typography className={classes.title}>Timezone</Typography>
      <Box className={classes.contentWrapper}>
        {`You appear to be in the`} <b>{timezone}</b>
        {". All event times are listed in this timezone"}
      </Box>
      <TextCustomButton
        className={classes.changeTimezoneBtn}
        onClick={() => {
          // TO DO
          setIsOpen(true);
        }}
        label={"Change timezone"}
      />
    </Box>
  );
};

export default TimezoneSection;
