// Tracking event constants

// Signup
export const SIGNUP_TAB = 'SIGNUP_TAB';
export const SIGNUP_BACK_NAVIGATION = 'SIGNUP_BACK_NAVIGATION';
export const SIGNUP_COUNTRY_SELECTED = 'SIGNUP_COUNTRY_SELECTED';
export const SIGNUP_BIRTHDAY_INFORMED = 'SIGNUP_BIRTHDAY_INFORMED';
export const SIGNUP_ROLE_SELECTED = 'SIGNUP_ROLE_SELECTED';
export const SIGNUP_ACCOUNT_INFO_FILLED = 'SIGNUP_ACCOUNT_INFO_FILLED';
export const SIGNUP_ACCOUNT_INFO_FILLED_NEEDS_PARENT = 'SIGNUP_ACCOUNT_INFO_FILLED_NEEDS_PARENT';
export const SIGNUP_PARENT_INFO_FILLED = 'SIGNUP_PARENT_INFO_FILLED';
export const SIGNUP_ACCOUNT_CREATION_SUCCESS = 'SIGNUP_ACCOUNT_CREATION_SUCCESS';
export const SIGNUP_ACCOUNT_CREATION_ERROR = 'SIGNUP_ACCOUNT_CREATION_ERROR';
export const SIGNUP_QUICK_WORKFLOW_SKIP_STEP = 'SIGNUP_QUICK_WORKFLOW_SKIP_STEP';
export const SIGNUP_INVITATION_APPLIED = 'SIGNUP_INVITATION_APPLIED';

export const SIGNUPV2_BACK_NAVIGATION = 'SIGNUP_BACK_NAVIGATION';
export const SIGNUPV2_PAGE1_DONE = 'SIGNUPV2_PAGE1_DONE';
export const SIGNUPV2_ACCOUNT_INFO_FILLED = 'SIGNUPV2_ACCOUNT_INFO_FILLED';
export const SIGNUPV2_ACCOUNT_INFO_FILLED_NEEDS_PARENT = 'SIGNUPV2_ACCOUNT_INFO_FILLED_NEEDS_PARENT';
export const SIGNUPV2_PARENT_INFO_FILLED = 'SIGNUPV2_PARENT_INFO_FILLED';
export const SIGNUPV2_PARENT_INFO_SKIPPED = 'SIGNUPV2_PARENT_INFO_SKIPPED';
export const SIGNUPV2_ACCOUNT_CREATION_SUCCESS = 'SIGNUP_ACCOUNT_CREATION_SUCCESS';
export const SIGNUPV2_ACCOUNT_CREATION_ERROR = 'SIGNUP_ACCOUNT_CREATION_ERROR';

// Login
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_GOOGLE = 'LOGIN_USER_GOOGLE';

export const SET_FIELD_VALUE = 'SET_FIELD_VALUE';

// Curriculum
export const ACTIVITY_DONE_BUTTON_CLICKED = 'ACTIVITY.DONE_BUTTON_CLICKED';
export const NAVIGATION_LEFT_NAVIGATION_MENU = 'NAVIGATION.LEFT_NAVIGATION_MENU';
export const NAVIGATION_HORIZONTAL_NAVIGATION_MENU = 'NAVIGATION.HORIZONTAL_NAVIGATION_MENU';

// General events
export const SESSION_START = 'SESSION_START';

// Navigation
export const ACTIVITY_VIEW = 'ACTIVITY_VIEW';
export const ACTIVITY_SET_VIEW = 'ACTIVITY_SET_VIEW';
export const PROFILE_PAGE_VIEW = 'PROFILE_PAGE_VIEW';
export const HOME_PAGE_VIEW = 'HOME_PAGE_VIEW';
export const CURRICULUM_VIEW = 'CURRICULUM_VIEW';
export const WELCOME_MESSAGE_BUTTON_CLICK = 'WELCOME_MESSAGE_BUTTON_CLICK';

// HQ Actions

export const ENROLL_PROGRAM_STARTED = 'ENROLL_PROGRAM_STARTED';
export const ENROLL_PROGRAM_ERROR = 'ENROLL_PROGRAM_ERROR';
export const STUDENT_JOINED_CLUB = 'STUDENT_JOINED_CLUB';
export const SWITCHED_CHILD_VIEW = 'SWITCHED_CHILD_VIEW';
export const SWITCHED_PARENT_VIEW = 'SWITCHED_PARENT_VIEW';
export const JOINED_COMMUNITY_PARTNERSHIP = 'JOINED_COMMUNITY_PARTNERSHIP';
export const FAILED_USER_ADDRESS_UPDATE = 'FAILED_USER_ADDRESS_UPDATE';

// Club Renewal
export const CLUB_RENEWAL_BUTTON_CLICKED = 'CLUB_RENEWAL_BUTTON_CLICKED';
export const CLUB_RENEWAL_FORM_COMPLETED = 'CLUB_RENEWAL_FORM_COMPLETED';
export const CLUB_RENEWAL_FORM_ABORTED = 'CLUB_RENEWAL_FORM_ABORTED';
export const CLUB_RENEWAL_DUPLICATE_FORM_PREVENTED = 'CLUB_RENEWAL_DUPLICATE_FORM_PREVENTED';
export const CLUB_RENEWAL_REDIRECTED_TO_REGULAR_APPLICATION = 'CLUB_RENEWAL_REDIRECTED_TO_REGULAR_APPLICATION';
export const CLUB_RENEWAL_UPDATE_SUCCESS = 'CLUB_RENEWAL_UPDATE_SUCCESS';
export const CLUB_RENEWAL_UPDATE_ERROR = 'CLUB_RENEWAL_UPDATE_ERROR';

// Loop Actions
export const LOOP_COMMENT_CREATED = 'LOOP_COMMENT_CREATED';
export const LOOP_COMMENT_UPDATED = 'LOOP_COMMENT_UPDATED';
export const LOOP_COMMENT_DELETED = 'LOOP_COMMENT_DELETED';
export const LOOP_POST_CREATED = 'LOOP_POST_CREATED';
export const LOOP_POST_UPDATED = 'LOOP_POST_UPDATED';
export const LOOP_POST_DELETED = 'LOOP_POST_DELETED';

// Gallery Project
export const PROJECT_PAGE_LEFT_PAGE = 'PROJECT_PAGE.LEFT_PAGE';
export const PROJECT_PAGE_VIEW = 'PROJECT_PAGE.VIEW';
export const PROJECT_PAGE_REPOSITORY_URL_CLICKED = 'PROJECT_PAGE.REPOSITORY_URL_CLICKED';
export const PROJECT_PAGE_PROJECT_URL_CLICKED = 'PROJECT_PAGE.PROJECT_URL_CLICKED';
export const PROJECT_GALLERY_UPLOAD_CLICKED = 'PROJECT_GALLERY.UPLOAD_CLICKED';
export const PROJECT_GALLERY_SHARE = 'PROJECT_GALLERY.SHARE';
export const PROJECT_GALLERY_VIEW = 'PROJECT_GALLERY.VIEW';
export const PROJECT_GALLERY_LEFT_PAGE = 'PROJECT_GALLERY.LEFT_PAGE';
export const PROJECT_GALLERY_FILTERS_CHANGED = 'PROJECT_GALLERY.FILTERS_CHANGED';
export const PROJECT_GALLERY_SEE_MORE_PROJECTS_CLICKED = 'PROJECT_GALLERY.SEE_MORE_PROJECTS_CLICKED';
export const SUBMISSION_FORM_VIEW = 'SUBMISSION_FORM.VIEW';
export const SUBMISSION_FORM_LEFT_PAGE = 'SUBMISSION_FORM.LEFT_PAGE';
export const SUBMISSION_FORM_SUBMIT_ATTEMPT = 'SUBMISSION_FORM.SUBMIT_ATTEMPT';
export const SUBMISSION_FORM_SUBMIT_SUCCESS = 'SUBMISSION_FORM.SUBMIT_SUCCESS';
export const SUBMISSION_FORM_SUBMIT_ERROR = 'SUBMISSION_FORM.SUBMIT_ERROR';

// Club Profile
export const CLUB_PROFILE_EDIT = 'CLUB_PROFILE.EDIT';
export const CLUB_PROFILE_VIEW = 'CLUB_PROFILE.VIEW';
export const CLUB_PROFILE_UPDATE_CLUB_SCHEDULE = 'CLUB_PROFILE.UPDATE_CLUB_SCHEDULE';
export const CLUB_PROFILE_UPDATE_MEMBERS_COUNT = 'CLUB_PROFILE.UPDATE_MEMBERS_COUNT';
export const CLUB_PROFILE_UPDATE_SHIPPING_ADDRESS = 'CLUB_PROFILE.UPDATE_SHIPPING_ADDRESS';
export const CLUB_PROFILE_UPDATE_SUCCESS = 'CLUB_PROFILE.UPDATE_SUCCESS';
export const CLUB_PROFILE_UPDATE_ERROR = 'CLUB_PROFILE.UPDATE_ERROR';

export const CLUB_PROFILE_UPDATE_PARTNER_AFFILIATION = 'CLUB_PROFILE_UPDATE_PARTNER_AFFILIATION';

// Language selector
export const LANGUAGE_SELECTOR_CHANGE = 'LANGUAGE_SELECTOR.CHANGE';

// Presentation Mode
export const START_PRESENT_VIEW = 'START_PRESENT_VIEW';
export const STOP_PRESENT_VIEW = 'STOP_PRESENT_VIEW';

// Progress tracking
export const PROGRESS_VIEW_PROGRAM = 'PROGRESS_VIEW_PROGRAM';
export const PROGRESS_VIEW_MEMBER = 'PROGRESS_VIEW_MEMBER';
export const PROGRESS_VIEW_MEMBER_EXPAND = 'PROGRESS_VIEW_MEMBER_EXPAND';
export const PROGRESS_VIEW_SELF = 'PROGRESS_VIEW_SELF';
