import React, { useEffect } from "react";
import moment from "moment";
import PickerToolbar from "@material-ui/pickers/_shared/PickerToolbar";
import ToolbarButton from "@material-ui/pickers/_shared/ToolbarButton";
import "./custom-date-toolbar.scss";

const CustomToolbar = function (props) {
  const { date, isLandscape, openView, setOpenView, title } = props;

  const handleChangeViewClick = (view) => () => setOpenView(view);

  useEffect(() => {
    const selectedEl = document.querySelector(".MuiPickersYear-yearSelected");
    if (selectedEl) selectedEl.focus();
  });

  const year = moment(date).format("YYYY");
  return (
    <PickerToolbar
      className='picker-toolbar'
      title={title}
      isLandscape={isLandscape}
      tabIndex={0}
    >
      <ToolbarButton
        id='year'
        onClick={handleChangeViewClick("year")}
        variant='subtitle1'
        label={year}
        selected={openView === "year"}
        aria-label={`Please select year, selected ${year}`}
        role='button'
      />
      <ToolbarButton
        id='date'
        onClick={handleChangeViewClick("date")}
        variant='h4'
        selected={openView === "date"}
        label={moment(date).format("ddd, MMM DD")}
        aria-label={moment(date).format("DD, dddd, MMMM YYYY")}
        role='button'
      />
    </PickerToolbar>
  );
};

export default CustomToolbar;
