const UserwayAccessibilityWidget = ({apiKey}) => {

  const script = document.createElement('script')
  script.src = "https://cdn.userway.org/widget.js"
  script.async = true
  script.setAttribute('data-account', apiKey);

  document.body.appendChild(script)
  return () => {
    document.body.removeChild(script)
  }
};

export default UserwayAccessibilityWidget;