import React from "react";
import {
  Box,
  Typography,
  Grid,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";

import * as colors from "../../theme/colors";
import PageTitle from "../../components/page-title/pageTitle";
import Tabs from "../UserProgress/Tabs/";
import SkeletonCards from "../../pages/project-gallery/skeletonCards/index";
import { UserProjectCard } from "../../components/user-project-card/user-project-card";
import { FilterBlock } from "../../containers/Progress/index";
import {Card, CardContent} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  galleryCard: {
    borderBottom: "6px solid $grey-dark",
    boxSizing: "border-box",
    cursor: "pointer",
    margin: 12,
    padding: 24,
    transition: "transform 150ms linear",
    height: "fit-content",
    width: "-webkit-fill-available",
    "&:hover": {
      transform: "scale(1.05, 1.05)",
    },
    "&:focus": {
      outline: "none",
      transform: "scale(1.05, 1.05)",
    },
  },
  cardСover: {
    height: 200,
  },
  cardTitle: {
    overflow: "hidden",
    fontWeight: 600,
    fontSize: 16,
    height: 48,
    boxOrient: 'vertical',
    lineClamp: 2,
    display: '-webkit-box'
  },
  cardContent: {
    padding: "16px 0 0 !important",
    display: "flex",
  },
  projectLink: {
    textDecoration: "none",
  },
  contentBox: {
    width: "95%",
    margin: "auto",
  },
  header: {
    margin: "15px auto",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tabsSection: {
    margin: '-10px auto 15px -25px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '-10px',
      marginBottom: '0px',
    },
  },
  tag: {
    paddingLeft: 10,
    display: 'inline-block',
  },
  titleBox: {
    display: 'inline-block',
  },
  chip: {
    fontSize: 12,
    fontWeight: 500,
    color: colors.grayTheme,
    fontFamily: "'Roboto Mono',monospace",
  },
  userTitle: {
    display: 'inline-block',
    fontWeight: 700,
    color: colors.dartThemeBlueGray,
    flexBasis: '100%',
    marginTop: 20,
  },
  publishedChip: {
    backgroundColor: colors.lightBlue,
  },
  notPublishedChip: {
    backgroundColor: '#EDEDED',
  },
  publishedCardTitle: {
    color: colors.dartThemeBlueGray,
  },
  notPublishedCardTitle: {
    color: '#CECECE',
  },
  unPublishedCard: {
    opacity: '0.6',
  },
  emptyPageCard: {
    width: '100%',
    height: '100%',
    minHeight: 'calc(100vh - 400px)',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    display: 'flex',
    padding: 15,
  },
  emptyPageText: {
    fontSize: 22,
    fontWeight: 700,
  },
  projectIconWrapper: {
    paddingBottom: 10,
  },
  projectIcon: {
    height: 120,
    width: 120,
  },
}));

const AllProgramProjects = ({
  isLoadingProjects,
  setCurrentTab,
  usersProjectsByProgram,
  currentTab,
  showCertificateTab,
  showAnswers,
  selectedGroupUsers,
  setSelectedGroupUsers,
  usersGroupsList,
  originClasses,
  projectsToRender,
  projectIcon
}) => {

  const classes = useStyles();

  const isLoading = isLoadingProjects || (usersProjectsByProgram?.length && projectsToRender === null);

  return (
    <Box className={classes.contentBox}>
      <Box className={classes.header}>
        <PageTitle title="Progress" isLoading={isLoadingProjects} />
        { !!usersGroupsList.length && <FilterBlock
          classes={originClasses}
          setSelectedGroupUsers={setSelectedGroupUsers}
          usersGroupsList={usersGroupsList}
          selectedGroupUsers={selectedGroupUsers}
        />}
      </Box>
      <div className={classes.tabsSection}>
        <Tabs
          value={currentTab}
          showProjectTab={usersProjectsByProgram}
          showCertificateTab={showCertificateTab}
          showAnswers={showAnswers}
          onChange={(evt, newValue) => {
            setCurrentTab(newValue);
          }}
        />
      </div>
      <Grid container className={classes.projectsTable}>
        {isLoading
          ? <SkeletonCards count={4}/>
          :
          (!!projectsToRender && Object.keys(projectsToRender).length) ? Object.keys(projectsToRender).sort((a, b) => a.localeCompare(b)).map((user) =>
            <>
              <Typography
                align="left"
                component="h3"
                className={classes.userTitle}
              >
                {user}
              </Typography>
              {projectsToRender[user].sort((a,b) => b.isPublished - a.isPublished).map((project, id) => 
                <UserProjectCard 
                  key={id}
                  classes={classes}
                  project={project}
                  currentTab={currentTab}
                />
              )}
            </>
          )
            :
            <Card className={classes.emptyPageCard}>
              <CardContent>
                {!!projectIcon && <Box className={classes.projectIconWrapper}>
                  <img className={classes.projectIcon} src={projectIcon.src} alt={projectIcon.alt} />
                </Box>}
                <Typography className={classes.emptyPageText}>
                  This member group doesn't have any projects, yet!
                </Typography>
              </CardContent>
            </Card>
        }
      </Grid> 
    </Box>
  );
};

export default AllProgramProjects;



