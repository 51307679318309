import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import MoodIcon from '@material-ui/icons/Mood';
import StarIcon from '@material-ui/icons/Star';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';

import * as colors from '../../theme/colors';

const useStyles = makeStyles({
  bottomText: {
    fontWeight: 'bold',
    marginTop: 40,
  },
  description: {
    marginTop: 10,
  },
  header: {
    fontWeight: 'bold',
    fontSize: 22,
  },
  iconMargin: {
    marginRight: 10,
  },
  iconWText: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  progressWrapper: {
    paddingTop: 45,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  smileIcon: {
    color: colors.greenA300,
    marginTop: 30,
  },
  startButton: {
    '&:disabled': {
      backgroundColor: colors.indigoA700,
      color: 'white',
    },
  },
  startButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,
  },
  statusesBox: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 40,
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: colors.red100,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: colors.red400,
  },
})(LinearProgress);

const EmptyProgress = () => {
  const classes = useStyles();

  return (
    <Table style={{ tableLayout: 'fixed' }} aria-label="spanning table" role="presentation">
      <TableBody>
        <TableRow key="emptyRow">
          <TableCell colSpan={4}>
            <Box className={classes.wrapper}>
              <Typography className={classes.header}>It looks like you haven't started any activities, yet.</Typography>

              <Box className={classes.statusesBox}>
                <Box>
                  <Box className={classes.iconWText}><StarIcon className={classes.iconMargin} style={{ color: colors.indigoA700 }} />TO DO</Box>
                  <Typography className={classes.description} style={{ color: colors.indigoA700 }}>+ When you start an Activity</Typography>
                  <Box className={classes.startButtonWrapper}>
                    <Button disabled className={classes.startButton} variant="contained">START</Button>
                  </Box>
                </Box>
                <Divider orientation="vertical" flexItem style={{ fontWeight: 'bold', color: colors.greenA300}} />
                <Box>
                  <Box className={classes.iconWText}>DOING</Box>
                  <Typography className={classes.description} style={{ color: colors.red400 }}>We'll keep track of where you left off...</Typography>

                  <Box className={classes.progressWrapper}>
                    <BorderLinearProgress variant="determinate" value={30} style={{ maxWidth: 200 }} />
                  </Box>
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box>
                  <Box className={classes.iconWText}><CheckCircleOutlineIcon className={classes.iconMargin} style={{ color: colors.greenA300 }} />DONE</Box>
                  <Typography className={classes.description} style={{ color: colors.greenA300 }}>And celebrate your accomplishments</Typography>
                  <MoodIcon className={classes.smileIcon} />
                </Box>
              </Box>

              <Typography className={classes.bottomText}>Search the Curriculum From the Menu on the Left To Get Started</Typography>

            </Box>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default EmptyProgress;
