import { axiosInstance } from '../axiosInstance';
import { getAuthHeader } from '../../tokenStorage';

export const getCPbyCode = async ({ code }) => {
  return axiosInstance.get(`/blapi/communityPartners/getByCode/${code}`,
    {
      headers: getAuthHeader(),
    });
};

export const getCPWithStatsById = async ({ id }) => {
  return axiosInstance.get(`/blapi/communityPartners/getCommunityPartnershipById/${id}`,
    {
      headers: getAuthHeader(),
    });
};

export const getCommunityPartnerships = async () => {
  return axiosInstance.get(`/blapi/communityPartners/getAll`,
    {
      headers: getAuthHeader(),
    })
    .then((res) => !!res && !!res.data ? res.data : [])
    .catch((ex) => {
      console.log(ex);
      return [];
    });;
};