import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import * as colors from "../../../theme/colors";
import { PROGRAM_TYPES } from '../../../utils/constants';
import { enrollmentCreatingChildTrackingEvent } from "../../../containers/JoinProgram/constants";

const useStyles = makeStyles(() => ({
  programInfoWrapper: {
    padding: 20,
  },
  titleContainer: {
    textAlign: "center",
  },
  title: {
    fontSize: 14,
    fontWeight: 700,
  },
  subTitle: {
    color: colors.lightGrey,
    fontSize: 14,
    textTransform: "uppercase",
    marginBottom: 10,
    marginTop: 10,
    fontWeight: 500,
  },
  item: {
    fontSize: 14,
    lineHeight: 1.4,
    wordWrap: "break-word",
    fontWeight: 400,
    color: colors.darkThemeBlueGray,
  },
  container: {
    width: "100%",
    display: "flex",
    padding: 0,
  },
  itemsWrapper: {
    columns: 2,
    "-webkit-columns": 2,
    "-moz-columns": 2,
    display: "block",
  }
}));

export const ProgramInfoDetails = ({
  program,
  type,
  footer,
  createTrackingLog,
}) => {
  useEffect(() => {
    return () => {
      createTrackingLog({
        event: enrollmentCreatingChildTrackingEvent.CHILD_ENROLLMENT_STEP_2_LEAVE,
        data: JSON.stringify({ 
          programCode: program.code,
          programType: program.programType,
         }),
      });
    };
  }, []);

  const isFacilitatorsExist =
    program && program.facilitators && program.facilitators.length > 0;
  const sortedFacilitators = program && program.facilitators
    ? program.facilitators.sort((a, b) => {
      const aFirstName = a.user.firstName ? a.user.firstName.toLowerCase() : null;
      const bFirstName = b.user.firstName ? b.user.firstName.toLowerCase() : null;
      return (aFirstName > bFirstName ? 1 : -1);
    })
    : [];

  const classes = useStyles();

  return (
    <>
      <Box className={classes.programInfoWrapper}>
        <Box className={classes.titleContainer}>
          <Typography className={classes.title}>
            Does this look right?
          </Typography>
        </Box>
        <Container className={classes.container}>
        <Grid content xs={12} className={classes.grid}>
          <>
            <Grid item xs={12} sm={12}>
              <Box>
                <Typography className={classes.subTitle}>
                  {type === PROGRAM_TYPES.CLUB && "Club"}
                  {type === PROGRAM_TYPES.COLLEGE_LOOP && "College Loop"}
                </Typography>
                <Typography className={classes.item} sel="prog-name">
                  {program?.name}
                </Typography>
              </Box>
              <Box>
                <Typography className={classes.subTitle}>
                  {type === PROGRAM_TYPES.CLUB && "Facilitators"}
                  {type === PROGRAM_TYPES.COLLEGE_LOOP && "Presidents"}
                </Typography>
                {!isFacilitatorsExist
                  ? <Typography className={classes.item}>None</Typography>
                  : <Box className={sortedFacilitators.length > 6 ? classes.itemsWrapper : ""}>
                      {sortedFacilitators.map((e) => (
                        <Typography className={classes.item}>
                          {`${e.user.firstName} ${e.user.lastName}`}
                        </Typography>))}
                    </Box>}
              </Box>
            </Grid>
          </>
        </Grid>
      </Container>
      </Box>
      {footer}
    </>
  );
};

