import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  skeletonRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  skeletonCell: {
    padding: 5
  }
}));


const SkeletonTable = ({
  rows,
  columns,
  showRowsOnly,
  colspan
}) => {
  const classes = useStyles();

  const renderRows = () => {
    return Array(rows).fill('').map((rowItem, rowIndex) => (
      <TableRow key={rowIndex} className={classes.skeletonRow}>
        {Array(columns).fill('').map((colItem, colIndex) => (
          <TableCell 
            key={colIndex} 
            align="center" 
            className={classes.skeletonCell}
            colSpan={!!colspan ? colspan[colIndex+1] || null : null}
            >
            <Skeleton />
          </TableCell>
        ))}
      </TableRow>
    ))
  }
  return (
    showRowsOnly 
      ? renderRows()
      : (
        <TableContainer>
          <Table aria-label="customized table">
            <TableBody>
              {renderRows()}
            </TableBody>
          </Table>
        </TableContainer>
      )
  );
};

export default SkeletonTable;
