import { takeLatest, takeEvery } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';

import * as authActions from '../actions/auth';
import * as authSagas from './auth';

import * as routerSagas from './router';

import * as signUpActions from '../actions/signUp';
import * as signUpSagas from './signUp';

import * as curriculumActions from '../actions/curriculum';
import * as curriculumSagas from './curriculum';

import * as clubProfileActions from '../actions/clubProfile';
import * as clubProfileSagas from './clubProfile';

import * as userProfileActions from '../actions/userProfile';
import * as userProfileSagas from './userProfile';

import * as joinClubActions from '../actions/joinClub';
import * as joinClubSagas from './joinClub';

import * as messagesActions from '../actions/messages';
import * as messagesSagas from './messages';

import * as announcementsActions from '../actions/announcements';
import * as announcementsSagas from './annoucements';

import * as forgotPasswordActions from '../actions/forgotPassword';
import * as forgotPasswordSagas from './forgotPassword';

import * as changePasswordActions from '../actions/changePassword';
import * as changePasswordSagas from './changePassword';

import * as contentfulActions from '../actions/contentful';
import * as contentfulSagas from './contentful';

import * as globalActions from '../actions/global';
import * as globalSagas from './global';

import * as communityPartnershipsActions from '../actions/communityPartnerships';
import * as communityPartnershipsSagas from '../sagas/communityPartnerships';

import * as trackingActions from '../actions/tracking';
import * as trackingSagas from '../sagas/tracking';

import * as attendanceActions from '../actions/attendance';
import * as attendanceSagas from '../sagas/attendance';

import * as membersActions from '../actions/members';
import * as membersSagas from '../sagas/members';

import * as userActivitiesInfoActions from '../actions/userActivitiesInfo';
import * as userActivitiesInfoSagas from '../sagas/userActivitiesInfo';

import * as ratingActions from '../actions/rating';
import * as ratingSagas from '../sagas/rating';

import * as projectGalleryActions from '../actions/projectGallery';
import * as projectGallerySagas from '../sagas/projectGallery';

import * as calendarActions from '../actions/calendar';
import * as calendarSagas from '../sagas/calendar';

import * as programActions from '../actions/program';
import * as programSagas from '../sagas/program';

import * as certificatesActions from '../actions/certificates';
import * as certificatesSagas from '../sagas/certificates';

import * as openTextActions from '../actions/openText';
import * as openTextAnswersSagas from '../sagas/openTextAnswers';

import * as answerGroupActions from '../actions/answerGroups';
import * as answerGroupSagas from '../sagas/answerGroups';
import * as quizAnswersActions from '../actions/quizesAnswers';
import * as quizAnswersSagas from './quizesAnswers';

import * as facilitatorApplicationActions from '../actions/facilitatorApplication';
import * as facilitateApplicationSagas from '../sagas/facilitatorApplication';

import * as facilitatorsConsentAgreementActions from '../actions/facilitatorsConsentAgreement';
import * as facilitatorsConsentAgreementSagas from '../sagas/facilitatorsConsentAgreement';

import * as decisionMakersActions from '../actions/decisionMakers';
import * as decisionMakersSagas from '../sagas/decisionMakers';

export default function* saga() {
  const relations = [
    [changePasswordActions, changePasswordSagas],
    [clubProfileActions, clubProfileSagas],
    [contentfulActions, contentfulSagas],
    [authActions, authSagas],
    [curriculumActions, curriculumSagas],
    [forgotPasswordActions, forgotPasswordSagas],
    [signUpActions, signUpSagas],
    [userProfileActions, userProfileSagas],
    [joinClubActions, joinClubSagas],
    [announcementsActions, announcementsSagas],
    [globalActions, globalSagas],
    [communityPartnershipsActions, communityPartnershipsSagas],
    [trackingActions, trackingSagas],
    [attendanceActions, attendanceSagas],
    [membersActions, membersSagas],
    [messagesActions, messagesSagas],
    [userActivitiesInfoActions, userActivitiesInfoSagas],
    [projectGalleryActions, projectGallerySagas],
    [calendarActions, calendarSagas],
    [ratingActions, ratingSagas],
    [programActions, programSagas],
    [certificatesActions, certificatesSagas],
    [openTextActions, openTextAnswersSagas],
    [answerGroupActions, answerGroupSagas],
    [quizAnswersActions, quizAnswersSagas],
    [facilitatorApplicationActions, facilitateApplicationSagas],
    [facilitatorsConsentAgreementActions, facilitatorsConsentAgreementSagas],
    [decisionMakersActions, decisionMakersSagas]
  ];

  for (const [actions, sagas] of relations) {
    for (const [actionName, action] of Object.entries(actions)) {
      const saga = sagas[actionName];
      if (saga) yield takeEvery(action.getType(), saga);
    }
  }

  yield takeLatest(LOCATION_CHANGE, routerSagas.locationChange)
}
