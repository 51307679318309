import React from "react";

import { Field, useFormikContext } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { getIsFormInvalid } from '../../../api/users';
import EmailInput from "../../../components/EmailInput";
import { PasswordInput } from "../../../components/PasswordInput";
import {
  validatePasswordRules,
  filterFieldsByKey,
} from "../../../utils/generic";
import { isEmailValid } from '../../../utils/userUtils';
import { validateUserNameRules } from "../../../utils/generic";
import { usernameIsInUseMsg, formInavlidMsg } from '../../../utils/userUtils';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 14,
    marginBottom: 20,
    display: "flex",
    justifyContent: "space-around",
  },
  childLoginFormWrapper: {
    margin: 20,
  },
  marginTop25: {
    marginTop: 25,
  },
}));

const maxLength = 255;

const ChildForm = ({ setIsEmail, childRegisteringContent, isEmail }) => {
  const classes = useStyles();
  const { values } = useFormikContext();

  let passwordContent = childRegisteringContent.password;
  const validateEmail = (value) => {
    if (!value) return 'Required field';
    if (value?.length > maxLength) return `Must be ${maxLength} characters or less`;
    if (isEmailValid(value)) {
      setIsEmail(true);
      return getIsFormInvalid({ ...values, email: value })
        .then((result) => {
          return result.data
            ? formInavlidMsg
            : null;
        });
    } else {
      setIsEmail(false);
    }

    const result = validateUserNameRules(value, values);
    if (result) {
      return result;
    }

    return getIsFormInvalid({ ...values, username: value })
        .then((result) => {
          return result.data
            ? usernameIsInUseMsg
            : null;
        });
  };

  const validatePassword = value => {
    const validationErrors = filterFieldsByKey(passwordContent.validationErrors, "messageCode", "message");
    if (!value || value.length == 0) {
      return validationErrors.creatingChildPassword;
    }

    const validateResult = validatePasswordRules(value, values.email, values.username, values.firstName);
    if (Object.keys(validateResult).length > 0) {
      return validationErrors.creatingChildWeakPassword;
    }
  }

  return (
    <>
      <Typography className={classes.title}>Your child will use this information to login to their account.</Typography>
      <Field
        name="email"
        component={EmailInput}
        label="Child's Username or Email"
        validate={validateEmail}
        required
        showTooltip={!!isEmail}
      />
      <Field
        name="password"
        component={PasswordInput}
        label="Child's Password"
        className={classes.marginTop25}
        helperText={passwordContent.helpText}
        validate={validatePassword}
        content={passwordContent}
        required
        showHelperText={true}
        fromModal={true}
      />
    </>
  );
};

export const ChildLogin = ({ childRegisteringContent, signUpContent, user, footer, setIsEmail, isEmail }) => {
  const classes = useStyles();

  const { values } = useFormikContext();

  return (
    <>
      <Box className={classes.childLoginFormWrapper}>
        <ChildForm
          signUpContent={signUpContent}
          user={user}
          values={values}
          setIsEmail={setIsEmail}
          childRegisteringContent={childRegisteringContent}
          isEmail={isEmail}
        />
      </Box>
      {footer}
    </>
  );
};

