import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Typography,
  Box
} from "@material-ui/core";

import * as colors from "../../../theme/colors";
import CustomButton from "../../../components/customButton/customButton";
import moment from "moment";

const useStyles = makeStyles({
  container: {
    display: "flex",
    marginBottom: 25,
    minWidth: 300,
    justifyContent: 'center'
  },
  header: {
    fontSize: "22px",
    padding: 0
  },
  card: {
    width: "80%",
    cursor: "pointer",
    padding: 10,
    display: "inline-grid"
  },
  initials: {
    color: colors.blue900,
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center"
  },
  actionContainer: {
    display: "flex",
    justifyContent: "center"
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 15
  },
  cardContent: {
    margin: "auto"
  },
  imageContainer: {
    margin: "auto",
    marginBottom: 15,
    width: 100,
    height: 100,
    display: "flex",
    overflow: "hidden",
    position: "relative",
    fontSize: "1.25rem",
    alignItems: "center",
    flexShrink: 0,
    lineHeight: 1,
    borderRadius: "50%",
    justifyContent: "center"
  },
  submitBtn: {
    height: 42,
    width: 145,
  },
  childClubContainer: {
    textAlign: 'center'
  }
});

const ChildCard = ({ child, labels, onJoin, onEdit, loginChild }) => {
  const classes = useStyles();
  const { childrenTabDefaultAvatar } = labels;
  let coverImage = childrenTabDefaultAvatar ? childrenTabDefaultAvatar.src : "";
  const needToUpdate = child && (!child.birthDate || !child.kinshipId || !moment(child.birthDate).isValid());

  return (
    <Grid item xs={3} md={3} className={classes.container}>
      <Card className={classes.card} >
        <div className={classes.content}>
          <CardContent className={classes.cardContent} onClick={() => {
            if (needToUpdate) {
              return;
            }
            loginChild(child);
          }}>
            <CardMedia className={classes.imageContainer} image={coverImage} />
            <Box color="primary">
              <Typography
                className={classes.initials}
                sel="child-name"
              >{`${child.firstName} ${child.lastName}`}</Typography>
            </Box>
            <Box className={classes.childClubContainer}>
              {child.roles && child.roles.map((e, ind) => { 
                return (<Typography key={ind}>{e.program && e.program.code}</Typography>)})
              }
            </Box>
          </CardContent>
        </div>

        <CardActions className={classes.actionContainer}>
          {needToUpdate ? (
            <CustomButton
              className={classes.submitBtn}
              mode={"secondary"}
              onClick={() => onEdit(true, child)}
              label={"Update Info"}
            />
          ) : (
            <CustomButton
              className={classes.submitBtn}
              mode={"secondary"}
              onClick={() => onJoin(child)}
              label={"Join Club"}
            />
          )}
        </CardActions>
      </Card>
    </Grid>
  );
};

export default ChildCard;
