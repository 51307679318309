import React from "react";

import DatetimePicker from "./DatetimePicker";
import CalendarPicker from "./calendarPicker";
import TimezoneSection from "./timezoneSection";

const CalendarSection = ({
  selectedDate,
  setSelectedDate,
  programs,
  programColors,
  filters,
  setFilters,
  timezone,
  updateTimezone,
  labels,
  isLoading,
}) => {
  
  const onFilterChange = (attr, value) => {
    const newFilters = { ...filters };
    if (attr === 'showPublic') {
      newFilters[attr] = value;
      setFilters(newFilters);
      return;
    }

    const isItemExist = newFilters[attr].includes(value);
    if (isItemExist) {
      newFilters[attr] = newFilters[attr].filter((e) => e != value);
    } else {
      newFilters[attr].push(value);
    }
    setFilters(newFilters);
  };

  const checkAllFilters = (checkAll) => {
    const newFilters = { ...filters };
    
    newFilters.showPublic = !!checkAll;
    newFilters.programIds = !!checkAll 
      ? programs.map((e) => e.id)
      : [];
    
    setFilters(newFilters);
  };

  return (
    <>
      <DatetimePicker date={selectedDate} setSelectedDate={setSelectedDate}/>
      {!!programs.length && (
        <CalendarPicker
          filters={filters}
          programs={programs}
          programColors={programColors}
          onChange={onFilterChange}
          onCheckAllFilters={checkAllFilters}
          sel="calendar"
          calendarLabels={labels}
          isLoading={isLoading}
        />
      )}
      <TimezoneSection timezone={timezone} updateTimezone={updateTimezone} />
    </>
  );
};

export default CalendarSection;
