import { getAuthHeader } from '../tokenStorage';
import { axiosInstance } from './axiosInstance';
import { applyFilterFlags } from '../utils/projectGalleryHelper';

export const getUserProjectsByProgram = async ({ programId, userId }) => {
  return axiosInstance.get(`/blapi/galleryProjects/myProjectsByProgram/${programId}/${userId}`, {
    headers: getAuthHeader(),
  });
};

export const getIsUserProjectsByProgram = async ({ programId, userId }) => {
  return axiosInstance.get(`/blapi/galleryProjects/isUserProjectsByProgram/${programId}/${userId}`, {
    headers: getAuthHeader(),
  });
};

export const getGalleryProjectById = async (projectId) => {
  return axiosInstance.get(`/blapi/galleryProjects/getGalleryProjectById/${projectId}`, {
    headers: getAuthHeader(),
  });
};

export const getUsersProjectsByProgram = async ({ programId }) => {
  return axiosInstance.get(`/blapi/galleryProjects/usersProjectsByProgram/${programId}`, {
    headers: getAuthHeader(),
  });
};

export const getProjectsByProgram = async ({ programId }) => {
  return axiosInstance.get(`/blapi/galleryProjects/projectsByProgram/${programId}`, {
    headers: getAuthHeader(),
  });
};

export const getUserProjects = async (userId) => {
  return axiosInstance.get(`/blapi/galleryProjects/getUserProjects/${userId}`, {
    headers: getAuthHeader(),
  });
};

export const createProject = async (payload) =>  {
  return axiosInstance.post(
    '/blapi/galleryProjects/create',
    payload,
    {
      headers: getAuthHeader(),
    }
  );
};

export const updateProject = async (id, payload) => {
  return axiosInstance.post(
    `/blapi/galleryProjects/update/${id}`,
    payload,
    {
      headers: getAuthHeader(),
    }
  );
};

export const getPagedGalleryProjects = async ({ pageNumber, filter, searchKey }) => {
  const options = {
    pageNumber,
    filter: {
      ...filter,
      onlyApproved: true,
    },
    searchKey
  };

  options.filter = applyFilterFlags(options.filter);

  return axiosInstance.post(
    '/blapi/galleryProjects/getPaged',
    options,
    {
      headers: getAuthHeader(),
    }
  );
};

export const getAllGalleryTags = async () => {
  return axiosInstance.get('/blapi/galleryProjects/getAllGalleryTags', {
    headers: getAuthHeader(),
  });
};
