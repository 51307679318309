import client from "../../../apolloClient";
import { gql } from "apollo-boost";

export function GetCalendarPageLabels() {
  return client.query({
    query: gql`{
      CalendarPageQuery {
              ${calendarPageLabelAttributes}
            }
          }`,
  });
}

export const calendarPageLabelAttributes = `
  title
  subscriptionInfo
  multipleProgramEventIcon {
    alt
    src
    name
  }
`;
