import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';

import GWCLogo from '../images/GWC_HQ_Logo.svg';
import GwcQaLogo from '../images/gwcqalogo.png';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    animation: `$mui-ripple-pulsate 2500ms ${theme.transitions.easing.easeInOut} 150ms infinite`,
    height: 100,
  },
  '@keyframes mui-ripple-pulsate': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(0.92)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
}));

const MainLoader = () => {
  const classes = useStyles();

  return (
    <Container className={classes.wrapper}>
      <img
        className={classes.logo}
        src={window._env_.REACT_APP_PRODUCTION_LOGO ? GWCLogo : GwcQaLogo}
        alt="Application loading"
      />
    </Container>
  );
};

export default MainLoader;
