import React from 'react';
import linkifyHtml from "linkify-html";

import { makeStyles } from '@material-ui/core/styles';

import { createMarkup } from "../../../utils/generic";

import { Box, CardContent, CardHeader } from '@material-ui/core';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';

import FacilitatorView from "./FacilitatorView";
import StudentView from './StudentView';

import {ReactComponent as GradeIcon} from '../../../images/grade.svg';
import { getNodeIds } from '../../../utils/getNodeIds';
import * as colors from '../../../theme/colors';

const useStyles = makeStyles((theme) => ({
  header: {
    minHeight: 80,
    color: props => props.fontColor,
    fontSize: 32,
    paddingLeft: 20,
    flex: 7,
  },
  title: {
    fontSize: 28,
    flex: 7,
    [theme.breakpoints.down('xs')]: {
      fontSize: 22
    }
  },
  subheader: {
    color: colors.white,
    fontSize: 18,
    lineHeight: 1,
    padding: '5px 0'
  },
  content: {
    maxWidth: '100%',
    borderRadius: '0 0 4px 4px',
    padding: '20px 80px',
    flexDirection: "column-reverse",
    [theme.breakpoints.down('md')]: {
      padding: '20px 50px'
    },

    [theme.breakpoints.down('xs')]: {
      padding: 20,
      flexDirection: 'column-reverse'
    },
  },
  htmlBox: {
    display: "inline-block",
    width: "100%",
    verticalAlign: "top",
    backgroundColor: colors.white,
    paddingBottom: 25,
  },
  responseArea: {
    width: "100%",
    maxWidth: "900%",
    resize: "none",
  },
  headerBox: {
    display: "flex",
    alignItems: "center",
  },
  assignmentIconBox: {
    marginRight: 10,
  },
  formContainer: {
    display: "flex",
    justifyContent: "stretch",
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      justifyContent: "inherit",
    }
  },
  loaderBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  formBox: {
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
    "& .MuiFormHelperText-root": {
      fontSize: "12px",
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  buttonViewGroup: {
    marginTop: 30,
    height: 42,
    width: "90%",
    display: "flex",
    boxShadow: "none",
    fontSize: 16,
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonGroup: {
    marginTop: 30,
    height: 42,
    width: "90%",
    display: "flex",
    boxShadow: "none",
    fontSize: 16,
  },
  viewBtn: {
    fontWeight: 700,
    fontSize: 16,
    color: colors.teal2,
  },
  messageArea: {
    'resize': 'none',
    width: '100%',
    fontSize: 14,
  },
  submittedLabel: {
    lineHeight: 2.5,
    fontSize: 16,
  },
  answerLabel: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "'Roboto Mono',monospace",
    color: colors.lightGrey,
  },
  viewBox: {
    display: "flex",
    justifyContent: "space-between",
    padding: "25px 50px",
  },
  viewBtn: {
    textTransform: 'uppercase',
  },
  noAnswersBox: {
    fontSize: 14,
    color: colors.dartThemeBlueGray,
  },
  viewAnswersBtn: {
    fontSize: 14,
    fontWeight: 500,
  },
  viewBtnDisabled: {
    color: colors.grey350,
    fontSize: 14,
    fontWeight: 500,
    cursor: "default",
  },
  itemContent: {
    fontSize: 18,
    lineHeight: 1.8,
    color: colors.dartThemeBlueGray,
    "& *": {
      maxWidth: '100%',
    },
    '& ul': {
      "padding-left": 40,
    },
    '& li': {
      lineHeight: 1.8,
      paddingLeft: 10,
      "&::marker": {
        color: colors.darkThemeGreen,
      }
    },
    '& details': {
      backgroundColor: colors.white,
      borderRadius: 5,
      border: '1px solid',
      borderColor: colors.darkThemeGreen,
      margin: '10px 0',
      padding: 10,

      '&:focus-within': {
        border: '2px solid',
        borderColor: colors.black,
        padding: 9
      },

      '& > summary': {
        outline: 'none',
        cursor: 'pointer',
        color: colors.darkThemeGreen,
        fontWeight: 700
      }
    },
    '& a': {
      color: colors.darkThemeGreen,
      lineHeight: 1.8,
      fontWeight: 700,
      textTransform: "initial",
    },
  },
  submitBox: {
    display: "flex",
    alignItems: "center",
  },
  submitLabel: {
    marginRight: 5,
  },
  circularItem: {
    color: colors.lightGrey,
  },
  gradeIcon: {
    width: 20,
    height: 20,
    fill: colors.white,
    marginRight: 10,
  },
}));

const OpenTextAnswer = (
  {
    item,
    isFacilitator,
    theme,
    program,
    openTextAnswers:
    {
      isLoadingAnswer,
      userAnswers,
      isLoadingAnswersForActivity,
      programActivityAnswers,
    },
    receivedAnswer,
    getMyAnswersByProgram,
    getAnswersForActivity,
    onNextClick,
    chains,
    push,
    user,
    setDisabledNext,
    studentView,
    showInfoMessage,
    isFinishedProject,
    setOpenQuestionAnswerUpdated,
    isLastContentSection,
    answeredQuestionsState,
    presentationMode,
    roleId,
    history,
  }) => {
  const classes = useStyles();

  const { activityId, setId, collectionId, url, folderId } = getNodeIds(chains);

  return (
    <Box>
      <CardHeader
        className={classes.header}
        classes={{ title: classes.title, content: classes.cardContent, subheader: classes.subheader, action: classes.action }}
        style={{ backgroundColor: theme.b, color: theme.f }}
        subheader={(item.subheading) &&
            <Box>{item.subheading}</Box>
        }
        title={
          <Box className={classes.headerBox}>
             {(item?.contentSectionGroup ? item?.contentSectionGroup?.requiredGrading : item.requiredGrading) ? 
             <GradeIcon className={classes.gradeIcon} /> 
             : <AssignmentOutlinedIcon className={classes.assignmentIconBox} />}
            {item.heading}
          </Box>
        }
      />
      <CardContent className={classes.content}>
        <div style={{ width: "100%", position: "relative" }}>
          <div className={classes.htmlBox}>
            <div className={classes.itemContent} dangerouslySetInnerHTML={createMarkup(linkifyHtml(item.content, { target: "_blank" }))} />
          </div>
        </div>
        {isFacilitator && !studentView ?
          <FacilitatorView
            classes={classes}
            item={item}
            program={program}
            isLoading={isLoadingAnswersForActivity}
            programActivityAnswers={programActivityAnswers}
            getAnswersForActivity={getAnswersForActivity}
            activityId={activityId}
            setId={setId}
            collectionId={collectionId}
            url={url}
            push={push}
          />
          : <StudentView
            classes={classes}
            item={item}
            receivedAnswer={receivedAnswer}
            program={program}
            userAnswers={userAnswers}
            isLoadingAnswer={isLoadingAnswer}
            getMyAnswersByProgram={getMyAnswersByProgram}
            activityId={activityId}
            setId={setId}
            collectionId={collectionId}
            url={url}
            push={push}
            user={user}
            onNextClick={onNextClick}
            questionName={item.heading}
            setDisabledNext={setDisabledNext}
            showInfoMessage={showInfoMessage}
            studentView={studentView}
            isFinishedProject={isFinishedProject}
            setOpenQuestionAnswerUpdated={setOpenQuestionAnswerUpdated}
            isLastContentSection={isLastContentSection}
            answeredQuestionsState={answeredQuestionsState}
            presentationMode={presentationMode}
            history={history}
            folderId={folderId}
            roleId={roleId}
          />}
      </CardContent>
    </Box>
  );
};

export default OpenTextAnswer;
