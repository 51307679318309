import React from "react";
import "./navBar.scss";
import navBackground from "../../images/navBackground.png";
import arrowBackIcon from "../../images/arrow-back-icon.svg";
import { handleKeyPress } from '../../utils/accessibility';
import { PageLeaveModal } from "../modals/pageLeaveWarning";

import { makeStyles } from "@material-ui/core/styles";
import  { useHistory }  from "react-router-dom";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  tabsSection: {
    alignItems: "center",
    display: "flex",
    margin: 'auto',
  },
  navContainer: {
    backgroundImage: `url(${navBackground})`,
    backgroundColor: "#0D9C90",
    display: "flex",
    width: "100%",
    minHeight: 100,

    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      minHeight: 100,
      display: 'block',
    }
  },
  navBackArrow: {
    backgroundImage: `url(${arrowBackIcon})`,
    display: "inline-block",
    height: "40px",
    transition: "transform 0.3s",
    minWidth: "20px",
    cursor: "pointer",
  },
  navText: {
    color: "#fff",
    fontSize: "2.625em",
    fontWeight: 900,
    margin: "20px 0px 0px 10px",
    wordBreak: 'break-word'
  },
  roleLabel: {
    color: "#fff",
    marginLeft: "20px",
    fontSize: "20px"
  },
  gridWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: 'auto auto auto 100px',
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      margin: 'auto',
    }
  },
}));

const NavBar = props => {
  const classes = useStyles();
  const history = useHistory();

  const showBack = props.showBack == undefined || props.showBack;

  const [isEscapeConfirmDialog, setEscapeConfirmDialog] = React.useState(false);

  const narrowClick = () => {
    if (props.uploadedAvatar) {
      setEscapeConfirmDialog(true);
      return;
    }
    history.goBack();
  };

  return (
    <>
      <Grid xs={12}>
        <div className={classes.navContainer}>
            <div className={classes.gridWrapper}>
              <div className={classes.labelContainer}>
              {showBack && (
                <div
                  onClick={narrowClick}
                  onKeyPress={handleKeyPress(narrowClick)}
                  className={classes.navBackArrow}
                  tabindex="0"
                  aria-label="back to the previous page"
                  role="button"
                />
              )}
              <div>
                <div className={classes.navText}>{props.title}</div>
                <p className={classes.roleLabel}>{props.role}</p>
              </div>
              </div>
            </div>
        </div>
      </Grid>
      <PageLeaveModal
        onConfirm={() => { setEscapeConfirmDialog(false); history.goBack(); }}
        onCancel={() => { setEscapeConfirmDialog(false); }}
        open={isEscapeConfirmDialog}
      />
    </>
  );
};

export default NavBar;
