import React from "react";

import axios from "axios";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import CloudUploadOutlined from "@material-ui/icons/CloudUploadOutlined";
import ClearIcon from "@material-ui/icons/Clear";
import * as colors from "../../../theme/colors";
import { allowedImageTypes, allowedTmageTypesMsg, imgTooltipLabel } from "../../../constants/fileConstants";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 100,
  },
  uploadBox: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: 130,
    justifyContent: "center",
    cursor: "pointer",

    transition: "color 0.25s",
    backgroundColor: (props) => props.backgroundColor,
    border: 0,
    boxSizing: "border-box",

    border: (props) => `3px dashed ${props.borderColor}`,
    color: (props) => props.mainColor,
    fontSize: "inherit",
    fontWeight: 700,
    position: "relative",
    verticalAlign: "middle",

    "&:hover": {
      border: () => `3px dashed ${colors.lightThemeGreen}`,
    },
  },
  rootPaper: {
    margin: "0 auto",
    boxShadow: "none",
    position: 'relative',
    "&:focus": {
      outline: 'none',
      "& > div": {
        borderColor: colors.lightThemeGreen,
      }
    }
  },
  uploadIcon: {
    height: 65,
    width: 65,
    color: colors.greyLight2,
    [theme.breakpoints.down("xs")]: {
      height: 45,
      width: 45,
    },
  },
  msgLabel: {
    color: colors.greyLight2,
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center",
  },
  tooltip: {
    whiteSpace: 'pre',
  },
  error: {
    color: theme.palette.error.main,
    position: 'absolute',
    fontSize: '12px',
    bottom: '-40px',
    left: 0,
    whiteSpace: 'nowrap',
    fontWeight: 'normal',
    textTransform: 'initial'
  },
}));

const styleTypes = {
  primary: {
    mainColor: colors.dartThemeBlueGray,
    secondColor: colors.dartThemeBlueGray,
    backgroundColor: colors.dartThemeBlueGray,
    borderColor: colors.lightThemeGreen,
    hoverBackgroundColor: colors.lightThemeGreen,
    backgroundColor: colors.lightThemeGreen,
  },
  secondary: {
    mainColor: colors.dartThemeBlueGray,
    secondColor: colors.lightThemeGreen,
    borderColor: colors.greyLight2,
    hoverBackgroundColor: colors.white,
    backgroundColor: colors.white,
  },
  disabled: {
    mainColor: colors.black,
    secondColor: colors.greyLight2,
    backgroundColor: colors.dartThemeBlueGray,
    borderColor: colors.greyLight2,
    hoverBackgroundColor: colors.greyLight2,
    backgroundColor: colors.greyLight2,
  },
};

const Photo = (props) => {
  const { value, onChange, onRemove, className, mode = "secondary", showErrorMessage } = props;
  const classes = useStyles(styleTypes[mode]);
  const fileInputRef = React.createRef();

  const [uploading, setUploading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const validationMessage = null;

  const onFileAdded = (evt) => {
    if (!evt.target.files || evt.target.files.length === 0) {
      return;
    }

    const maxSize = 5240000; // 5mb
    if (evt.target.files[0].size > maxSize) {
      alert("Sorry, your file is too large. It should be less than 5 Mb.");
      return;
    }

    if (!allowedImageTypes.includes(evt.target.files[0].type)) {
      alert("file should be an image (jpeg, jpg, png, gif).");
      return;
    }

    setUploading(true);

    const formData = new FormData();
    formData.append("image", evt.target.files[0]);

    axios({
      method: "POST",
      url: `${window._env_.REACT_APP_API_URL}/api/files/upload-image`,
      data: formData,
    })
      .then(({ data: { Location } }) => {
        onChange(Location);
        setUploading(false);
        setError(null);
      })
      .catch((e) => {
        console.log("error uploading file");
        console.log(e);
        if (showErrorMessage) {
          showErrorMessage("An error occurred while uploading the file");
        }
        setUploading(false);
        setError(allowedTmageTypesMsg);
      });

    fileInputRef.current.value = null;
  };

  const handleChipClick = () => {
    if (uploading || !!props.value) return;
    fileInputRef.current.click();
  };

  const handleKeyDown = (e) => {
    if (uploading || !!props.value || e.key !== "Enter") return;
    fileInputRef.current.click();
  };

  const removeImage = () => {
    onRemove(value);
  };

  return (
    <Tooltip title={imgTooltipLabel} placement="bottom" disableHoverListener={!!value}>
      <Paper onClick={handleChipClick} onKeyDown={handleKeyDown} className={classes.rootPaper} tabIndex="0">
        {value && (
          <div style={{ position: "relative" }}>
            <img src={value} alt="Remove" style={{ width: "100%" }} />
            <IconButton
              color="secondary"
              style={{ position: "absolute", top: 0, left: 0, color: "#f44336" }}
              onClick={removeImage}
              sel="remove-img"
            >
              <ClearIcon />
            </IconButton>
          </div>
        )}
        {!value && (
          <Box className={clsx(classes.uploadBox, className)}>
            {uploading ? (
              <CircularProgress size={50} />
            ) : (
              <CloudUploadOutlined className={classes.uploadIcon} />
            )}
            <Typography className={classes.msgLabel}>
              {validationMessage || "Upload Image"}
            </Typography>
          </Box>
        )}
        <input
          ref={fileInputRef}
          style={{ display: "none" }}
          type="file"
          accept={allowedImageTypes.join(', ')}
          onChange={onFileAdded}
        />
        <span className={classes.error}>{error}</span>
      </Paper>
    </Tooltip>
  );
};

export default Photo;
