import { call, put } from "redux-saga/effects";

import {
  receivedInvitations,
  receivedMembers,
  receivedRecruitmentResourceLink,
  userRoleDeleted,
  createdUsersGroups,
  receivedUsersGroups,
  usersGroupDeleted,
  onError,
  updatedUsersGroup,
  receivedProgramCertificateId
} from '../actions/members';
import {
  showSuccessMessage,
  showErrorMessage
} from "../actions/notification";

import { changeUserPasswordAsFacilitator } from '../api/index';
import * as userRolesApi from '../api/userRoles';
import * as programsApi from '../api/programs';
import * as usersGroupsApi from '../api/usersGroups';
import * as userInvitationsApi from '../api/userInvitations';

export function* getMembers({ payload }) {
  try {
    const { data: members } = yield call(
      userRolesApi.getUserRolesByProgram, parseInt(payload, 10));
    yield put(receivedMembers(members));
  } catch (e) {
    yield put(receivedMembers([]));
    // TODO: throw generic error
    console.log('TODO: handle error here');
    console.log(e);
  }
}

export function* getInvitations({ payload }) {
  try {
    let { data: invitations } = yield call(
      userInvitationsApi.getInvitationsByProgram,
      { programId: payload }
    );
    yield put(receivedInvitations(invitations));
  } catch (e) {
    yield put(receivedInvitations([]));
    console.log(e);
  }
}

export function* changeUserPassword({ payload }) {
  try {
    let { data: { error }} = yield call(changeUserPasswordAsFacilitator, { ...payload });
    if (!error) {
      yield put(showSuccessMessage('Password has been successfully changed'));
    }
    else {
      yield put(showErrorMessage(error));
    }
  } catch (e) {
    yield put(showErrorMessage("Password has not been changed due to error"));
  }
}

export function* getRecruitmentResourceLink({ payload }) {
  try {
    const { data: link } = yield call(
      programsApi.getRecruitmentResourceLink, parseInt(payload, 10));
    yield put(receivedRecruitmentResourceLink(link));
  } catch (e) {
    yield put(receivedRecruitmentResourceLink(null));
    // TODO: throw generic error
    console.log('TODO: handle error here');
    console.log(e);
  }
}

export function* deleteUserRoleByProgram({ payload } ) {
  try {
    const { programId, userId } = payload;
    
    const result = yield call(userRolesApi.deleteUserRoleByProgram, programId, userId);
    if (!!result) {
      yield put(userRoleDeleted(userId));
      yield put(showSuccessMessage('User has been successfully removed from the program.'));
    }
    else {
      yield put(showErrorMessage("Failed to remove user from the program. Please, reload the page and try again."));  
    }
  } catch (e) {
    yield put(showErrorMessage("Failed to remove user from the program. Please, reload the page and try again."));
    // TODO: throw generic error
    console.log('TODO: handle error here');
    console.log(e);
  }
}

export function* createUsersGroups({ payload }) {
  try {
    const { data } = yield call(
      usersGroupsApi.createUsersGroups, payload);
    if (data?.error) {
      throw Error(data.error)
    }

    yield put(createdUsersGroups({ ...data }));
    yield put(showSuccessMessage('Group has been successfully created'));
  } catch (e) {
    yield put(createdUsersGroups(null));
    yield put(showErrorMessage("Failed to create group"));
    // TODO: throw generic error
    console.log('TODO: handle error here');
    console.log(e);
  }
}

export function* getUsersGroups({ payload: { programId }}) {
  try {
    const { data } = yield call(
      usersGroupsApi.getMembersGroupsByProgram, programId);
    yield put(receivedUsersGroups(data));
  } catch (e) {
    yield put(receivedUsersGroups(null));
    yield put(showErrorMessage("Failed to get group members"));
    // TODO: throw generic error
    console.log('TODO: handle error here');
    console.log(e);
  }
}

export function* deleteUsersGroup({ payload: { groupId, groupName } }) {
  try {
    const { data } = yield call(usersGroupsApi.deleteUsersGroup, groupId);
    if (data?.error) {
      throw Error(data.error);
    }

    yield put(usersGroupDeleted(groupName));
    yield put(showSuccessMessage('Group has been successfully deleted'));
  } catch (e) {
    console.log('TODO: handle error here');
    console.log(e);
    yield put(showErrorMessage('Failed to remove group'));
  }
}

export function* updateUsersGroup({payload: { groupName, groupId, memberUserIds, oldGroupName, programId } }) {
  try {
    const { data } = yield call(
      usersGroupsApi.updateUsersGroup,
      { memberUserIds, groupName, programId },
      groupId
    );

    if (data?.error) {
      throw Error(data.error);
    }

    yield put(
      updatedUsersGroup({ oldGroupName, group: { ...data, users: memberUserIds } })
    );
    yield put(showSuccessMessage('Group has been successfully updated'));
  } catch (e) {
    console.log('TODO: handle error here');
    console.log(e);
    yield put(onError());
    yield put(showErrorMessage('Failed to update group'));
  }
}

export function* getProgramCertificateId({ payload }) {
  try {
    const { data: certificateId } = yield call(
      programsApi.getProgramCertificateId, parseInt(payload, 10));
    yield put(receivedProgramCertificateId(certificateId));
  } catch (e) {
    yield put(receivedProgramCertificateId(null));
    // TODO: throw generic error
    console.log('TODO: handle error here');
    console.log(e);
  }
}
