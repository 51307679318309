import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { connectTo } from "../../../utils/generic";

import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";

import * as globalActions from "../../../actions/global";
import LeftMenuContainer from "./MenuContainer";

const drawerWidth = 350;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: (props) => props.width,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: 65,
    height: "calc(100% - 65px)",
    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
  },
}));

const LeftNavigationMenu = ({
  leftMenuVisible,
  switchMobileMenu,
  isMobileView,
  isMobile,
  ...props
}) => {
  const menuWidth = leftMenuVisible && !isMobile ? 300 : 0;
  const classes = useStyles({ width: menuWidth });
  const theme = useTheme();

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      {isMobileView && (
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={leftMenuVisible}
            onClose={switchMobileMenu}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <LeftMenuContainer>{props.children} </LeftMenuContainer>
          </Drawer>
        </Hidden>
      )}
    </nav>
  );
};

export default connectTo(
  (state) => ({
    leftMenuVisible: state.global.leftMenuVisible,
    isMobileView: state.global.isMobileView,
    isMobile: state.global.isMobile
  }),
  {
    ...globalActions,
  },
  LeftNavigationMenu
);
