import * as Yup from 'yup';
import { filterFieldWithReturnKey, filterField, filterFieldsByKey } from '../../../utils/generic';

export default (page, role, content) => {
  const schemas = getSchemas(content);
  if (page === 1 && role === 10) {
    return Yup.object({
      ...schemas[page],
      ...schemas[3],
    });
  }

  if (page === 4) {
    return Yup.object({
      ...schemas[4],
    });
  }

  return Yup.object(schemas[page]);
};

const getSchemas = (content) => {
  return [
    {
      country: Yup.string().required(content.signupNationalityStepCountrySelection.accessibleInstructions),
      birthDate: Yup.string().required(filterFieldWithReturnKey(content.signupBirthStepDateInput.validationErrors, "messageCode", "requiredField", "message")),
      // role: Yup.string().required('Required'),
      termsAndConditions: Yup.boolean()
        .test(
          'is-true',
          filterFieldWithReturnKey(content.formMessages, "messageCode", "termsAndConditionsRequired", "message"),
          value => value === true),
    },
    {
      termsAndConditions: Yup.boolean()
        .test(
          'is-true',
          filterFieldWithReturnKey(content.formMessages, "messageCode", "termsAndConditionsRequired", "message"),
          value => value === true),
    },
    {
      parents: Yup.array().of(Yup.object({})).min(1),
    },
    {
      children: Yup.array().of(Yup.object({}))
        .test('children email duplicates', function (value) {
          // display error msg for latest child with duplicate email in list;
          for (let i = value.length - 1; i > 0; i--) {
            let item = value[i];
            const duplicates = value.filter(e => !!e.email && e.email === item.email);
            if (duplicates.length > 1) {
              let emailContent = filterField(content.signupFields, "id", "signupEmail");
              const validationErrors = filterFieldsByKey(emailContent.validationErrors, "messageCode", "message");
              return this.createError({
                path: `children[${i}].email`,
                message: validationErrors.childrenWithTheSameEmail,
              });
            }
          }
          return true;
        }),
    },
    {
      clubCode: Yup.string().required("Required"),
    }
  ];
}
