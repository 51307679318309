import React, { useState } from "react";
import { connectTo } from "../../utils/generic";
import { push as pushToRoute } from "connected-react-router";
import {
  Box,
  Typography,
  CardContent,
  Card,
  Grid,
  Avatar,
} from "@material-ui/core/";
import Certificates from "./certificateCard/certificateCard";

import NavBar from "../../components/nav-bar/navBar";
import * as colors from "../../theme/colors";
import * as authActions from "../../actions/auth";
import * as certificatesActions from "../../actions/certificates";
import * as actions from "../../actions/userProfile";
import PublishedUserProjects from "./publishedProjects/publishedUserProjects";
import "./public-profile.scss";
import { getUserName } from "../../utils/generic";
import { useTitle } from "../../utils/pageTitles";
import { Roles } from "../../utils/permissions";
import CopyLinkButton from '../../components/copyLinkButton/copyLinkButton';
import ChangeCerificateNameModal from "../../components/modals/publicProfile/changeCerificateNameModal";

const PublicProfile = ({
  loadUserProfile,
  loadUserProjects,
  userProfileContent,
  loadAllUserCertificates,
  certificates,
  push,
  userProjects,
  isLoading,
  isLoadingProjects,
  match: {
    params: { userId },
  },
  user,
  startCertificateNameChangeGeneration,
  loadCertificateNameChangeStatus,
  contentful,
}) => {
  useTitle("public-profile");

  const [changeCertificateModalOpened, setChangeCertificateModalOpened] = useState(null);
  const [currentCerficate, setCurrentCerficate] = useState(null);
  const [showEditCertificateBtn, setShowEditCertificateBtn] = useState(null);
  const [isCertificateGenerated, setIsCertificateGenerated] = useState(null);
  const [isGenerationRunning, setIsGenerationRunning] = useState(false);

  let userInitials = "";

  React.useEffect(() => {
    if (!userId) {
      return;
    }

    loadAllUserCertificates({ userId });

    loadUserProfile({ userId });

    loadUserProjects({ userId })
  }, userId);

  React.useEffect(() => {
    if (!user || !contentful) {
      return;
    }
    const { hqConfig: { defaultProgram: dbDefaultProgramId } } = contentful;
    const { roles } = user;
    const isFacilitator = roles.some((e) => e.roleId === Roles.Facilitator && !!e.programId && e.programId !== Number(dbDefaultProgramId));
    if (isFacilitator) {
      setShowEditCertificateBtn(true);
    }

  }, [user, contentful]);

  if (userProfileContent.firstName || userProfileContent.lastName) {
    userInitials = userProfileContent.lastName
      ? `${userProfileContent.firstName.slice(0, 1)}${userProfileContent.lastName.slice(0, 1)}`
      : `${userProfileContent.firstName.slice(0, 1)}`;
  }

  const onModalClose = () => {
    setChangeCertificateModalOpened(false);
    setCurrentCerficate(null);
  }

  let checkCertitificateGenerationTimeOut;
  if (isGenerationRunning) {
    checkCertitificateGenerationTimeOut = setTimeout(() => {
      if (Object.keys(certificates.certificateGenerating).length) {
        Object.keys(certificates.certificateGenerating).map(certificateId => {
          certificates.certificateGenerating[certificateId].isRunning && loadCertificateNameChangeStatus(certificateId);
        })
      }
    }, 10000);
  }

  if (!isGenerationRunning) {
    clearTimeout(checkCertitificateGenerationTimeOut);
  }

  return (
    <>
      <NavBar
        title={!isLoading && !!userProfileContent.firstName ? `${getUserName(userProfileContent)}` : ''}
      />
      <Grid content lg={10} xl={8} className="public-profile-page">
        <>
          <Card className="card">
            <CardContent>
              <Grid container className="item">
                <Grid item xs={12} sm={5} md={4} className="item-container">
                  <Box
                    className="avatar"
                    style={{ background: colors.grey300 }}
                  >
                    {userProfileContent.avatarUrl ? (
                      <Avatar
                        style={{ width: 200, height: 200 }}
                        src={userProfileContent.avatarUrl}
                      />
                    ) : (
                      <Box>
                        <Typography
                          className="initials"
                          style={{ color: colors.darkThemeBlueGray }}
                        >
                          {userInitials}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  {userProfileContent.email && (
                    <Box className="email-box">
                      <CopyLinkButton textClass="user-email" link={userProfileContent.email} />
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12} sm={7} md={8} className="item-container">
                  <Box className="bio-box">
                    <Typography variant="body1" className="bio-label">
                      About me
                    </Typography>

                    <Typography>
                      {userProfileContent.bio || "Nothing to see here... yet!"}
                    </Typography>
                  </Box>
                  <Certificates
                    certificates={certificates}
                    userId={userId}
                    push={push}
                    currentUser={user}
                    isPublicProfile={true}
                    setChangeCertificateModalOpened={setChangeCertificateModalOpened}
                    setCurrentCerficate={setCurrentCerficate}
                    showEditCertificateBtn={showEditCertificateBtn}
                    certificateGenerating={certificates.certificateGenerating}
                  />
                </Grid>
              </Grid>
              {userProjects && userProjects.length > 0 && <PublishedUserProjects
                isLoadingProjects={isLoadingProjects || isLoading}
                userProjects={userProjects}
              />}
            </CardContent>
          </Card>
        </>
      </Grid>
      {changeCertificateModalOpened && (
        <ChangeCerificateNameModal
          isOpen={changeCertificateModalOpened}
          onCloseModal={onModalClose}
          currentCertificate={currentCerficate}
          firstName={currentCerficate.certificateFirstName ? currentCerficate.certificateFirstName : userProfileContent.firstName}
          lastName={currentCerficate.certificateLastName ? currentCerficate.certificateLastName : userProfileContent.lastName}
          startCertificateNameChangeGeneration={startCertificateNameChangeGeneration}
          memberId={userId}
          loadCertificateNameChangeStatus={loadCertificateNameChangeStatus}
          certificateGenerating={certificates.certificateGenerating}
          setIsCertificateGenerated={setIsCertificateGenerated}
          isCertificateGenerated={isCertificateGenerated}
          isGenerationRunning={isGenerationRunning}
          setIsGenerationRunning={setIsGenerationRunning}
        />
      )}
    </>
  );
};

export default connectTo(
  (state) => {
    return {
      ...state.userProfile,
      user: state.auth.user,
      contentful: state.contentful,
      certificates: state.certificates,
    };
  },
  {
    push: pushToRoute,
    ...actions,
    ...authActions,
    ...certificatesActions,
  },
  PublicProfile
);
