import React from "react";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import CustomButton from "../../../components/customButton/customButton";
import EditIcon from "@material-ui/icons/Edit";
import userTypes from "../../../constants/userTypeConstants";

const useStyles = makeStyles({
  card: {
    width: "100%",

    "& .MuiTypography-colorTextSecondary": {
      color: "#003046",
    },

    "& .MuiTypography-body2": {
      fontFamily: "'Roboto Mono',monospace",
    },
  },
  title: {
    fontSize: "30px",
    marginLeft: "16px",
  },
  label: {
    fontSize: 50,
  },
  gridHeader: {
    justifyContent: "space-between",
  },
  list: {
    width: "100%",
  },
  itemContainer: {
    marginTop: "15px",
    marginBottom: "15px",
  },
  item: {
    marginTop: "15px",
    marginBottom: "15px",
    wordWrap: "break-word",
  },
  editIcon: {
    marginTop: 8,
  },
  editContainer: {
    backgroundColor: "transparent",
    border: "1px solid #565656",
    borderRadius: "50%",
    height: "36px",
    lineHeight: "37px",
    textAlign: "center",
    cursor: "pointer",
    width: "36px",
  },
  divider: {
    marginBottom: "15px",
    marginTop: "15px",
    backgroundColor: "black",
  },
  profileIcon: {
    height: 35,
    width: 35,
  },
});

export const UserAddress = (props) => {
  const classes = useStyles();
  const { user, labels, countries } = props;

  const country =
    user && user.countryId && countries
      ? countries.find((e) => e.value === user.countryId.toString())
      : null;

  const allowEdit = user.userType !== userTypes.Anonymous;

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <CardContent>
          <Grid className={classes.gridHeader} container>
            <div className="personal-info-header">
              <img
                className={classes.profileIcon}
                alt=""
                src={labels.personalAddressInfoIcon.src}
              />
              <Typography
                variant="h5"
                className={classes.title}
                component="h1"
                sel="account-info"
              >
                Address
              </Typography>
            </div>
            <div>
              {allowEdit && <CustomButton
                mode="secondary"
                label={<EditIcon />}
                onClick={() => props.openEditForm()}
                sel="edit-account-info"
                aria-label="edit your adddress"
              />}
            </div>
          </Grid>

          <Divider className={classes.divider} />
          <Grid container className={classes.item}>
            {user.streetAddress && (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className={classes.itemContainer}
              >
                <ListItemText
                  primary="Street address"
                  className={classes.item}
                  secondary={user.streetAddress || "--"}
                />
              </Grid>
            )}

            {user.city && (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className={classes.itemContainer}
              >
                <ListItemText
                  primary="City"
                  className={classes.item}
                  secondary={user.city || "--"}
                />
              </Grid>
            )}

            {user.state && (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className={classes.itemContainer}
              >
                <ListItemText
                  primary="State"
                  className={classes.item}
                  secondary={user.state || "--"}
                />
              </Grid>
            )}

            {user.zipcode && (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className={classes.itemContainer}
              >
                <ListItemText
                  primary="Postal Code"
                  className={classes.item}
                  secondary={user.zipcode || "--"}
                />
              </Grid>
            )}

            {user.countryId && country && (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className={classes.itemContainer}
              >
                <ListItemText
                  primary="Country"
                  className={classes.item}
                  secondary={country.label || "--"}
                  sel="country"
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default UserAddress;
