import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as colors from '../../theme/colors';

import Card from '@material-ui/core/Card';
import Box from "@material-ui/core/Box";
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Menu from "@material-ui/core/Menu";
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Grid from "@material-ui/core/Grid";

import { TooltipWrapper } from "../../components/tooltip/tooltip";
import HqAvatar from '../../components/avatar/avatar';
import Link from "../../components/Link";
import { sortLastThenFirst, getUserName, getRestNames } from '../../utils/generic';
import DeleteConfirmationDialog from '../../components/DeleteConfirmationDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    padding: '8px 8px 15px 18px',
  },
  regularLink: {
    "&:hover": {
      textDecoration: "none"
    },
    fontWeight: 600,
  },
  redLink: {
    color: colors.red300,
  },
  cardHeader: {
    padding: 0,
    height: 48,
    [theme.breakpoints.down("xs")]: {
      wordBreak: 'break-all',
    },
  },
  cardContent: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 15,
    justifyContent: 'flex-start',
    alignContent: 'space-around',
    gap: "20px",
    padding: 0,

    '&:last-child': {
      paddingBottom: 0,

      [theme.breakpoints.down("xs")]: {
        wordBreak: 'break-all',
      },
    },
  },
  groupNameTitle: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    overflow: 'hidden ',
    textOverflow: 'ellipsis',
    color: colors.darkThemeGreen,
    fontWeight: "bold",
    fontSize: "16px",
    textTransform: "uppercase",
    wordBreak: "break-all",
    "&:hover": {
      textDecoration: "none",
    }
  },
  tooltipList:{ 
    whiteSpace: 'pre-line' 
  },
}));

const GroupCard = (props) => {
  const classes = useStyles();
  const maxMembersToDisplay = 11;
  const {
    members,
    groupName,
    hasContextMenu,
    cardIndex,
    onDelete,
    onEdit,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialogOpened, setDialogOpened] = React.useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeDialog = () => {
    setDialogOpened(false);
  };

  const openDialog = () => {
    setDialogOpened(true);
    handleClose();
  }
  
  const handleDelete = () => {
    onDelete();
    closeDialog();
  }

  const handleEdit = () => {
    onEdit();
    handleClose();
  }

  const sortedMembers = members && members.length
    ? sortLastThenFirst(members.map(item => Object.assign(item, {
      firstName: item.user.firstName,
      lastName: item.user.lastName
    })))
    : [];
  const extraMembersToDisplay = sortedMembers.length - maxMembersToDisplay;

  return (
    <Card className={classes.root}>
      {<CardHeader
        action={hasContextMenu &&
          <IconButton aria-label="settings" onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        }
        title={
          <Box className={classes.groupNameTitle}>{groupName}</Box>
        }
        className={classes.cardHeader}
      />
      }
      <CardContent className={classes.cardContent}>
        {!!sortedMembers?.length &&
          <Grid container spacing={3}>
            {sortedMembers.map((member, index) => {
              if (index < maxMembersToDisplay || extraMembersToDisplay === 1) {
                return (<Grid item xs={2} sm={3} md={3} lg={2} key={index}>
                  <TooltipWrapper title={getUserName(member)}>
                    <Box>
                      <Link href={`/profile/${member.userId}`}>
                        <HqAvatar
                          user={member.user}
                          src={member.user.avatarName && member.user.avatarUrl}
                        />
                      </Link>
                    </Box>
                  </TooltipWrapper>
                </Grid>)
              } else if (index === maxMembersToDisplay && extraMembersToDisplay > 1) {
                return <Grid item xs={2} sm={3} md={3} lg={2} key={sortedMembers.length}>
                  <TooltipWrapper title={
                    <div className={classes.tooltipList}>{getRestNames(sortedMembers, maxMembersToDisplay)}</div>
                  }>
                    <Box>
                      <HqAvatar
                        user={{}}
                        label={`+${extraMembersToDisplay}`}
                      />
                    </Box>
                  </TooltipWrapper>
                </Grid>
              }
            }
            ).filter(x => x)}
          </Grid>
        }
      </CardContent>
      {hasContextMenu && <>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            className={classes.regularLink}
            id={cardIndex}
            onClick={handleEdit}
            sel="edit-group"
          >
            Edit Group
          </MenuItem>
          <MenuItem
            className={clsx(classes.regularLink,classes.redLink)}
            id={cardIndex}
            onClick={openDialog}
            sel="delete-group"
          >
            Delete Group
          </MenuItem>
        </Menu>
        <DeleteConfirmationDialog
          open={dialogOpened}
          onClose={closeDialog}
          onConfirm={handleDelete}
          title="Are you sure you want to remove this group?"
        />
      </>}
    </Card>
  );
};

export default GroupCard;
