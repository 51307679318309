import React from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 12,
  },
  error: {
    color: theme.palette.error.main,
  },
}));

const GreenCheckbox = withStyles({
  root: {
    color: '#3DB390',
    '&$checked': {
      color: '#3DB390',
    },
  },
  checked: {},
})((props) => <Checkbox {...props} />);

const CustomLabel = (props) => (
  <span className={props.className}>{props.children}</span>
);

const CheckBoxControl = ({ field: { name, onChange, value }, form: { errors, touched }, label, indeterminate, disabled, ...props }) => {
  const classes = useStyles();

  let isTouched = touched[name];

  return (
    <React.Fragment>
      <FormControlLabel
        className={clsx(props.className, classes.root)}
        control={
          <GreenCheckbox
            disabled={disabled}
            indeterminate={indeterminate}
            className={classes.label}
            name={name}
            checked={value}
            onChange={onChange}
          />
        }
        label={<CustomLabel className={clsx(props.labelStyle, classes.label)} >{label}</CustomLabel>}
      />
      {errors[name] && isTouched && <FormHelperText className={classes.error}>{errors[name]}</FormHelperText>}
    </React.Fragment>
  );
};

export default CheckBoxControl;
