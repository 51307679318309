import { call, put } from "redux-saga/effects";
import { push } from "connected-react-router";

import {
  receivedUserProfile,
  recievedStateProvinces,
  profileContentLoaded,
  passwordChangeCompleted,
  smsConsentSaved,
  receivedUserProjects,
  receivedAcceptedPrivacyPolicy,
} from "../actions/userProfile";

import { currentUserUpdated } from "../actions/auth";

import { FAILED_USER_ADDRESS_UPDATE } from "../constants/trackingEvents";
import { createUsageLog } from "../actions/tracking";

import * as graphqlStateProvincesQuery from "../api/graphql/query/stateProvinces.queries";
import * as graphqlProfilePageContentQuery from "../api/graphql/query/profile.queries";
import { changePassword, setPassword } from "../api/index";
import { getUserProjects } from "../api/galleryProjects";
import * as usersApi from "../api/users";

import { setToken, setRefreshToken } from "../tokenStorage";

import {
  showSuccessMessage,
  showErrorMessage
} from "../actions/notification";

export function* changeUserInfo({ payload }) {
  try {
    const { data: { userData, error } } = yield call(usersApi.updateMyInfo, payload.data);
    if (!!error) {
      yield put(showErrorMessage('There was an error while updating user\'s information. Please try again later.'));
    }
    else {
      yield put(currentUserUpdated(userData));
      yield put(showSuccessMessage('User information has been successfully updated.'));
      if (payload?.callback && +new Date(userData?.birthDate) > 0) {
        yield call(payload?.callback);
        if (payload?.message) yield put(showSuccessMessage(payload?.message));
      }
    }
  } catch (e) {
    yield put(showErrorMessage('There was an error while updating user\'s information. Please try again later.'));
  }
}

export function* changeUserAddressInfo({ payload }) {
  try {
    const { data: { countryId } } = payload;

    const { data: { userData, error } } = yield call(usersApi.updateMyAddressInfo, {
      ...payload.data,
      countryId: parseInt(countryId)
    });

    if (!!error) {
      yield put(
        createUsageLog({
          action: FAILED_USER_ADDRESS_UPDATE,
          data: JSON.stringify(payload),
        })
      );
      yield put(showErrorMessage('There was an error while updating user\'s address. Please try again later.'));
    }
    else {
      yield put(currentUserUpdated(userData));
      yield put(showSuccessMessage('Address has been successfully updated.'));
    }
  } catch (e) {
    yield put(showErrorMessage('There was an error while updating user\'s address. Please try again later.'));
  }
}

export function* updateUserAvatarImage({ payload }) {
  try {
    let { data: { avatarUrl } } = yield call(usersApi.updateMyAvatarImage, payload.avatarUrl);
    if (!!avatarUrl) {
      yield put(currentUserUpdated({ avatarUrl, avatarName: payload.avatarName }));
      yield put(showSuccessMessage('Avatar has been successfully updated.'));
    }
    else {
      yield put(showErrorMessage('There was an error while saving the avatar. Please try again later.'));
    }
  } catch (e) {
    yield put(showErrorMessage('There was an error while saving the avatar. Please try again later.'));
  }
}

export function* updateUserBio({ payload }) {
  try {
    let { data: { bio } } = yield call(usersApi.updateMyUserBio, payload);
    yield put(currentUserUpdated({ bio }));
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* loadStateProvinces({ payload }) {
  try {
    const {
      data: { GetTranslatedStatesProvinces },
    } = yield call(
      graphqlStateProvincesQuery.GetTranslatedStatesProvinces,
      payload
    );

    let statesProvinces = GetTranslatedStatesProvinces.map((el) => {
      return {
        value: el.id,
        label: el.name,
      };
    });

    yield put(recievedStateProvinces(statesProvinces));
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* changeUserPassword({ payload }) {
  try {
    const { data: {
      token,
      refreshToken,
    } } = yield call(changePassword, payload);

    setToken(token);
    setRefreshToken(refreshToken);

    yield put(passwordChangeCompleted({ saved: true }));
    yield put(showSuccessMessage('Password has been successfully updated.'));
  } catch (e) {
    // TODO: throw generic error
    yield put(
      passwordChangeCompleted({
        saved: false,
        error:
          e && e.response && e.response.data && e.response.data.error
            ? e.response.data.error
            : "Request failed to change password.",
      })
    );
    yield put(showErrorMessage('There was an error while updating your password. Please try again later.'));
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* setUserPassword({ payload }) {
  try {
    yield call(setPassword, payload);
    yield put(passwordChangeCompleted({ saved: true }));
    yield put(showSuccessMessage('Password has been successfully updated.'));
  } catch (e) {
    // TODO: throw generic error
    yield put(
      passwordChangeCompleted({
        saved: false,
        error:
          e && e.response && e.response.data && e.response.data.error
            ? e.response.data.error
            : "Request failed to change password.",
      })
    );
    yield put(showErrorMessage('There was an error while updating your password. Please try again later.'));
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* loadProfileContent({ payload }) {
  try {
    let {
      data: { ProfilePageQuery },
    } = yield call(graphqlProfilePageContentQuery.ProfilePageQuery, payload);
    yield put(profileContentLoaded(ProfilePageQuery));
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* loadUserProfile({ payload }) {
  try {
    const { userId } = payload;
    let { data } = yield call(usersApi.getUserProfile, userId);
    yield put(receivedUserProfile(data));
  } catch (e) {
    yield put(push("/home"));
  }
}

export function* updateSmsConsent({ payload }) {
  try {
    const { isSmsConsent } = payload;
    const { data: { isSuccess } } = yield call(usersApi.updateMySmsConsent, isSmsConsent);
    if (isSuccess) {
      yield put(smsConsentSaved(!!isSmsConsent));
      yield put(showSuccessMessage('Your changes have been saved.'));
    }
    else {
      yield put(showErrorMessage('There was an issue. Please try again later.'));
    }
  } catch (e) {
    yield put(showErrorMessage('There was an issue. Please try again later.'));
  }
}

export function* loadUserProjects({ payload }) {
  try {
    const { userId } = payload;
    let { data } = yield call(getUserProjects, userId);
    yield put(receivedUserProjects(data));
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* updateAcceptedPrivacyPolicy({ payload }) {
  try {
    let { data: { acceptedPolicyAt } } = yield call(usersApi.updatePrivacyPolicyAgreementDate, payload);

    yield put(receivedAcceptedPrivacyPolicy(acceptedPolicyAt));
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}
