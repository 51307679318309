import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { DialogContent, DialogTitle, DialogContentText } from "@material-ui/core/";

import { HQModal } from "../HQModal";
import * as colors from "../../../theme/colors";
import CustomButton from "../../../components/customButton/customButton";

const useStyles = makeStyles({
  marginTop30: {
    marginTop: 30,
  },
  contentTitle: {
    color: colors.darkThemeBlueGray,
  },
  buttonGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none",
    fontSize: 16,
  },
  cancelBtn: {
    marginLeft: 10,
  },
});

export const ConfirmationModal = ({ isOpen, onSave, onClose }) => {
  const classes = useStyles();

  return (
    <HQModal title="Delete Confirmation" isOpen={isOpen} onClose={onClose}>
      <DialogTitle className={classes.contentTitle} id="alert-dialog-title">{'Are you sure you want to delete this post?'}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText id="alert-dialog-description">
          This action cannot be undone.
        </DialogContentText>

        <div className={classes.buttonGroup}>
          <CustomButton
            className={classes.cancelBtn}
            mode="textButton"
            onClick={onClose}
            label={"Cancel"}
          />
          <CustomButton
            mode="primary"
            type="submit"
            onClick={onSave}
            label={"Yes"}
          />
        </div>
      </DialogContent>
    </HQModal>
  );
};
