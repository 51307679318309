import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { RetryLink } from "apollo-link-retry";
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { buildAxiosFetch } from "axios-fetch";
import { axiosInstance  } from './api/axiosInstance';

import { getToken } from './tokenStorage';

const link = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true
  },
  attempts: {
    max: 2,
    retryIf: (error) => !!error
  }
});;

const httpLink = link.concat(createHttpLink({
  uri: `${window._env_.REACT_APP_API_URL}/graphql`,
  fetch: buildAxiosFetch(axiosInstance),
}));

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const options = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: options,
});

export default client;
