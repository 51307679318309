import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import LocationIcon from '@material-ui/icons/LocationOnOutlined';
import TextField from '@material-ui/core/TextField';
import { StyledFormHelper } from '../../loginSignup/customFormHelper';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

const TimezoneSelector = ({ items, field, form, label }) => {
  const classes = useStyles();
  const meta = form.getFieldMeta(field.name);
  let selectedValue = null;
  if (form.values.timezone){
    const filteredItem = items.filter((o) => o.id === form.values.timezone);
    if (filteredItem && filteredItem.length > 0) selectedValue = filteredItem[0];
  }

  let error = meta.error || false;
  let isTouched = meta.touched || false;

  return (
    <React.Fragment>
      <Autocomplete
        autoSelect
        autoHighlight
        autoComplete
        className={classes.root}
        clearOnEscape
        options={items}
        value={selectedValue}
        getOptionLabel={option => option.name}
        filterOptions={(options, { inputValue }) => {
          const byName = options.filter((o) => o.name.toLowerCase().includes(inputValue.toLowerCase()));
          return Object.values([
            ...byName,
          ].reduce((a, c) => {
            a[c.id] = c;
            return a
          }, {}));
        }}
        onChange={(evt, val) => {
          const id = val ? val.id : '';
          form.setFieldValue(field.name, id);
        }}
        renderInput={(params) => {
          const newParams = {
            ...params,
          };

          newParams.InputProps.startAdornment = (
            <InputAdornment position="start">
              <LocationIcon />
            </InputAdornment>
          );

          return (
            <React.Fragment>
            <TextField
              label={label}
              placeholder={label}
              margin="normal"
              variant="outlined"
              fullWidth
              error={!!error && isTouched}
              {...newParams}
            />
            {isTouched && error &&
            (<StyledFormHelper helperText={error} isError={true} />)}
            </React.Fragment>
          );
        }}
        renderOption={option => (
          <React.Fragment>
            {option.name}
          </React.Fragment>
        )}
      />
    </React.Fragment>
  );
};

export default TimezoneSelector;
