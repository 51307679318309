import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field, FieldArray } from "formik";
import {
  Box,
  Typography,
  Grid,
} from "@material-ui/core/";
import CircularProgress from "@material-ui/core/CircularProgress";

import TextButton from "../../../text-button/text-button";
import CustomButton from "../../../customButton/customButton";
import { ColorTheme } from '../../../../utils/colorTheme';
import TextInput from "./TextInput";
import { StyledFormHelper } from "../../../../components/loginSignup/customFormHelper";

import animalNames from './dataForUsernameGeneration/animalNames.json';
import colorNames from './dataForUsernameGeneration/colorNames.json';
import spaceChars from './dataForUsernameGeneration/spaceChars.json';
import specChars from './dataForUsernameGeneration/specChars.json';

import { PasswordInput } from '../../../../components/PasswordInput';

import { validatePasswordRules, filterFieldsByKey, filterFieldsWithKey } from '../../../../utils/generic';

const useStyles = makeStyles((theme) => ({
  dialogContent: {},
  title: {
    color: ColorTheme.blue4.b,
    fontSize: 16,
    fontWeight: "bold",
    margin: "10px 0",
  },
  subTitle: {
    marginBottom: 10,
    fontSize: 14,
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 25,
  },
  inviteBtn: {
    fontSize: 16,
    padding: '0 25px'
  },
  progressIcon: {
    width: 20,
    height: 20,
    marginLeft: 5,
  },
  input: {
    width: 320,
    paddingRight: 5,
    paddingBottom: 5,
    paddingTop: 5,
    [theme.breakpoints.down('md')]: {
      maxWidth: "100%",
    }
  },
  titles: {
    marginLeft: "-5px"
  },
  rowTitle: {
    textAlign: 'left',
    color: '#7A7A78',
    fontSize: 12,
    fontFamily: "'Roboto Mono',monospace",
  },
  asteriks: {
    color: "#EA3850",
    paddingLeft: 5,
    fontSize: '1.2rem'
  },
  titleContainer: {
    display: "flex",
    marginBottom: "-15px",
    marginTop: "15px",
  },
  boxContainer: {
    [theme.breakpoints.up('sm')]: {
      display: "flex",
    }
  },
}));

const alphanumericRegex = /^[a-zA-Z0-9]*$/;
const randomElement = (array) => array[Math.floor(Math.random() * array.length)];
const randomNumber = () => Math.floor(Math.random() * 10);
const generatePassword = () => {
  return `${randomElement(colorNames)}${randomElement(spaceChars)}${randomElement(animalNames).toLowerCase()}${randomElement(specChars)}${randomNumber()}${randomNumber()}`;
}

export const ReviewMembersModal = ({
  onBack,
  userNames,
  setUserNames,
  setGeneratedMembersData,
  generatedMembersData,
  clubCode,
  createAnonymousUsersWithEnroll,
  role,
  onNext,
  getMembers,
  profileContent,
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [userNamesAlreadyExist, setUserNamesAlreadyExist] = useState(null);
  const [enableGeneration, setEnableGeneration] = useState(false);
  const [passwordContent, setPasswordContent] = React.useState({});
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    if (!profileContent || !profileContent.changePasswordForm) {
      return;
    }
    
    const resetFields = filterFieldsWithKey(profileContent?.changePasswordForm.resetStepFields, "id");
    const newPasswordContent = resetFields.newPassword;
    setPasswordContent(newPasswordContent);
    const newErrors = filterFieldsByKey(newPasswordContent.validationErrors, "messageCode", "message");
    setErrors(newErrors);

  }, [profileContent]);

  const validateNewPassword = (value, user) => {
    const { email, username, firstName } = user;
    const validateResult = validatePasswordRules(value, email, username, firstName);
    if (Object.keys(validateResult).length > 0) {
      return errors.weakPassword;
    }
  }

  useEffect(() => {
    const users = userNames.filter((user) => !!user).map((name) => {
      return {
        firstName: name,
        username: `${clubCode}${name.replaceAll(" ", "")}${randomNumber()}`,
        password: generatePassword(),
      };
    })
    setGeneratedMembersData(users);
  }, [userNames]);

  const createUserRoles = (users) => {
    setIsLoading(true);
    return createAnonymousUsersWithEnroll({
      programId: role.programId,
      users
    })
      .then(values => {
        setIsLoading(false);
        if(!values.data.error) {
          getMembers(role.programId);
          onNext();
        } else {
          const usedUsernames = values.data.usernames.map(username => username.toLowerCase())
          setEnableGeneration(false);
          setUserNamesAlreadyExist(usedUsernames);
        }
      })
      .catch(() => {
        setIsLoading(false);
        getMembers(role.programId);
      });
  }

  const backClick = () => {
    setGeneratedMembersData(null);
    onBack();
  }

  return (
    <Box>
      <Typography className={classes.title}>Review new accounts</Typography>
      <Typography className={classes.subTitle}>
        You can manually edit account details before we create your member's accounts.
      </Typography>
      {generatedMembersData && (<Box>
        <Formik
          initialValues={{ users: generatedMembersData }}
          onSubmit={(values) => {
            setGeneratedMembersData(values.users);
            createUserRoles(values.users);
          }}
          validate={(values) => {
            const uniqueUsernames = new Set(values.users.map(user => user.username.toLowerCase()));
            let errors = {};
            if (!values.users) {
              errors.body = "There must be at least one user";
              setEnableGeneration(false);
            }
            if (userNamesAlreadyExist && !!values.users.find(user => !!userNamesAlreadyExist.includes(user.username.toLowerCase()))) {
              errors.body = "Usernames must be unique";
              setEnableGeneration(false);
            } else {
              errors = {};
              setEnableGeneration(true);
            }
            if (values.users.find((user) => !alphanumericRegex.test(user.username))) {
              errors.body = "Usernames shouldn't contain special characters";
              setEnableGeneration(false);
            }
            if (uniqueUsernames.size < values.users.length) {
              errors.body = "Usernames must be unique";
              setEnableGeneration(false);
            }
            
            const itemsWithEmptyFirstName = values.users.filter(e => {
              return !e.firstName.trim().length;
            })
            
            if (itemsWithEmptyFirstName.length) {
              setEnableGeneration(false);
            }

            const names = !!values.users && values.users.map((user) => user.firstName)

            const dontNeedUpdate = userNames.filter(e => !names.find(x => x === e));
            if (!dontNeedUpdate) {
              setUserNames(names);
            }
            return errors;
          }}
          validateOnMount={true}
        >
          {({ values, errors }) => (
            <Form>
              <FieldArray
                name="users"
                render={() => (
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Box className={classes.titleContainer}>
                          <Typography className={classes.rowTitle}>
                            FIRST NAME
                          </Typography>
                          <Typography className={classes.asteriks}>
                            *
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item className={classes.titles} xs={4}>
                        <Box className={classes.titleContainer}>
                          <Typography className={classes.rowTitle}>
                            USERNAME
                          </Typography>
                          <Typography className={classes.asteriks}>
                            *
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item className={classes.titles} xs={4}>
                        <Box className={classes.titleContainer}>
                          <Typography className={classes.rowTitle}>
                            PASSWORD
                          </Typography>
                          <Typography className={classes.asteriks}>
                            *
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    {values.users.map((formItem, index) => (
                      <Box className={classes.boxContainer} key={index}>
                        <Field 
                          name={`users.${index}.firstName`}
                          component={TextInput}
                          className={classes.input}
                          size="small"
                          required
                        />
                        <Field 
                          name={`users.${index}.username`}
                          component={TextInput}
                          className={classes.input}
                          size="small" 
                          required
                          errorIsHtml={true}
                          helperText={
                            ((!!userNamesAlreadyExist && userNamesAlreadyExist.includes(formItem.username.toLowerCase())) && 'Username is already taken') ||
                            (!alphanumericRegex.test(formItem.username) && "Username shouldn't contain special characters") ||
                            ((values.users.filter(user => user.username.toLowerCase() === formItem.username.toLowerCase()).length > 1) && 'Username must be unique')
                          }
                        />
                        <Box className={classes.input}>
                          <Field 
                            name={`users.${index}.password`}
                            component={PasswordInput}
                            content={passwordContent}
                            permamentShowPassword={true}
                            validate={(value) => {
                              return validateNewPassword(value, formItem);
                            }}
                            fromModal={true}
                            username={formItem.username}
                            firstName={formItem.firstName}
                            size="small"
                            required
                          />
                        </Box>
                      </Box>
                    ))}
                    {errors && <StyledFormHelper helperText={errors.body} isHtml={true} isError={true} />}
                    <Box className={classes.btnContainer}>
                      <TextButton onClick={() => backClick()}>Back</TextButton>
                      <CustomButton
                        className={classes.inviteBtn}
                        disabled={!enableGeneration || !generatedMembersData.length || isLoading}
                        mode={"primary"}
                        type="submit"
                        label="Create accounts">
                          {isLoading && (
                            <CircularProgress
                              size={20}
                              className={classes.progressIcon}
                            />
                          )}
                      </CustomButton>
                    </Box>
                  </Box>
                )}
              />
            </Form>
          )}
        </Formik>
      </Box>)}
    </Box>
  );
};
