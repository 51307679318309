import React, { useEffect, useState } from "react";
import * as moment from "moment";
import { push as pushToUrl } from "connected-react-router";

import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";

import * as attendances from "../../actions/attendance";
import { connectTo } from "../../utils/generic";
import NavBar from "../HorizontalNavigation";
import Footer from "../../containers/Footer/footer";

import AttendanceTable from "./attendanceTable";
import { AttendanceModal } from "./attendanceModal";

import { useTitle } from "../../utils/pageTitles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
  },
  main: {
    width: "100%",
    height: "100%",
    minHeight: 'calc(100vh - 132px)',
    paddingTop: 50,
    [theme.breakpoints.down('md')]: {
      paddingTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 100,
    },
  },
  backButton: {
    marginRight: theme.spacing(1),
    cursor: "pointer",
  },
  content: {
    maxWidth: "1100px",
    width: "100%",
    margin: "auto",
  },
  attendanceModalContainer: {
    minWidth: 350,
  },
}));

const getEqualDate = (date, selectedDate) => {
  return date.split("T")[0] === selectedDate;
};

const getDate = (date) => {
  return date.includes("T") ? date.split("T")[0] : date.split(" ")[0];
};

const Attendance = ({
  auth: { user, currentRole },
  push,
  isLoading,
  getAttendances,
  updateAttendance,
  attendances,
  students,
  updatingItems,
}) => {
  useTitle("attendance");

  const classes = useStyles();

  const [loaddedAttendances, setLoadedAttendances] = useState(0);
  const [programId, setProgramId] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [attendance, setAttendance] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    moment.parseZone(new Date()).format("YYYY-MM-DD")
  );
  const [permissions, setPermissions] = useState({});
  const [programStartDate, setProgramStartDate] = useState(null);

  useEffect(() => {
    if (!attendances) return;

    loadAttendances(selectedDate);
  }, [attendances]);

  useEffect(() => {
    if (!user || !currentRole) {
      return;
    }

    const attendanceEnabledForProgramType = currentRole.program?.programTypeDetails?.attendanceEnabled;
    const attendanceEnabledForUser = currentRole.permissions.attendancePage;

    if (!attendanceEnabledForProgramType || !attendanceEnabledForUser) {
      push("/home");
      return;
    }

    setPermissions(currentRole.permissions);
    setProgramId(currentRole.programId);

    if (currentRole.program.startDate) {
      setProgramStartDate(getDate(currentRole.program.startDate));
    }

    if (user && !loaddedAttendances) {
      getAttendances({
        programId: currentRole.programId,
      });
    }
  }, [user, currentRole]);

  const loadAttendances = (date) => {
    const data =
      attendances && attendances.length > 0
        ? attendances.filter((e) => getEqualDate(e.date, date))
        : [];

    const studentsToAdd = students.filter(
      (e) => !data.find((m) => m.nextUserId === e.userId)
    );

    const convertedMembers = studentsToAdd.map((e) => {
      return {
        user: e.user,
        nextUserId: e.userId,
        programId,
      };
    });

    const newList = [...data, ...convertedMembers].sort((a, b) => {
      const aFirstName = a.user.firstName
        ? a.user.firstName.toLowerCase()
        : null;
      const bFirstName = b.user.firstName
        ? b.user.firstName.toLowerCase()
        : null;
      return aFirstName > bFirstName ? 1 : -1;
    })

    setLoadedAttendances(newList);
    setSelectedDate(date);
  };

  const openModal = (data) => {
    setAttendance(data);
    setIsOpen(true);
  };

  const onModalClose = () => {
    setIsOpen(false);
  };

  const patchAttendance = (obj) => {
    const { id, status, comment, nextUserId } = obj;
    const data = {
      id,
      programId,
      comment,
      status,
      nextUserId,
      date: selectedDate || moment.parseZone(new Date()).format("YYYY-MM-DD"),
    };

    updateAttendance({ attendance: data });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <NavBar />
      <main className={classes.main}>
        {permissions.editAttendance && isOpen && attendance && (
          <AttendanceModal
            isOpen={isOpen}
            onClose={onModalClose}
            row={attendance}
            onSave={patchAttendance}
            className={classes.attendanceModalContainer}
          />
        )}
        <AttendanceTable
          data={loaddedAttendances}
          updatingItems={updatingItems}
          onDateChange={loadAttendances}
          openModal={openModal}
          onUpdate={patchAttendance}
          editable={permissions.editAttendance}
          isLoading={isLoading}
          programStartDate={programStartDate}
          attendances={attendances}
        />
      </main>
      <Footer />
    </div>
  );
};

export default connectTo(
  (state) => {
    return {
      auth: state.auth,
      ...state.attendance,
    };
  },
  {
    ...attendances,
    push: pushToUrl,
  },
  Attendance
);
