import React from "react";

import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { allowedImageTypes, allowedTmageTypesMsg, imgTooltipLabel } from "../../../constants/fileConstants";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  uploadBox: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 100,
    justifyContent: 'center',
    cursor: 'pointer',
  },
  error: {
    color: theme.palette.error.main,
    position: 'absolute',
    fontSize: '12px',
    bottom: '-40px',
    right: 0,
    whiteSpace: 'nowrap',
    fontWeight: 'normal',
    textTransform: 'initial'
  },
}));
const FileUpload = (props) => {
  const { hasFile, onChange, labelClass, errorClass } = props;
  //const [hasFile, setHasFile] = React.useState(!!value);
  const [uploading, setUploading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const classes = useStyles();
  const fileInputRef = React.createRef();

  // React.useEffect(() => {
  //   setHasFile(!!value);
  // }, [value])

  const validationMessage = null;

  const onFileAdded = (evt) => {
    if (!evt.target.files || evt.target.files.length === 0) {
      return;
    }

    const maxSize = 5240000; // 5mb
    if (evt.target.files[0].size > maxSize) {
      alert('Sorry, your file is too large. It should be less than 5 Mb.');
      return;
    }

    setUploading(true);

    const formData = new FormData();
    formData.append('image', evt.target.files[0]);

    axios({
      method: 'POST',
      url: `${window._env_.REACT_APP_API_URL}/api/files/message/upload-image`,
      data: formData,
    }).then(({ data: { Location } }) => {
      onChange(Location);
      setUploading(false);
      setError(null);
    }).catch((e) => {
      console.log('error uploading file');
      console.log(e);
      setUploading(false);
      setError(allowedTmageTypesMsg);
    });

    fileInputRef.current.value = null;
  };

  const handleChipClick = () => {
    if (uploading || hasFile) return;
    fileInputRef.current.click();
  };

  const keyDown = (e) => {
    if (e.key === "Enter") {
      e.target.click();
    }
    return;
  }

  return hasFile 
    ? <></>
    : (
    <Tooltip title={imgTooltipLabel} placement="bottom">
      <Box tabIndex={0} onClick={handleChipClick} className={`${labelClass || ''} ${classes.root}`} onKeyDown={keyDown}>
        {uploading 
          ? <CircularProgress size={20} />
          : props.children}
        {validationMessage && <Box className={classes.uploadBox}>
          {validationMessage && <Typography style={{ color: 'red'}}>{validationMessage}</Typography>}
        </Box>}
        <input
          ref={fileInputRef}
          style={{ display: 'none'}}
          type="file"
          accept={allowedImageTypes.join(', ')}
          onChange={onFileAdded}
        />
        {error && <span className={`${classes.error} ${errorClass || ''}`}>{error}</span>}
      </Box>
    </Tooltip>
  );
};

export default FileUpload;
