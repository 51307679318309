import React from "react";

import Grid from "@material-ui/core/Grid";
import {
  Card,
  CardContent,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonCard = () => {
  return (
    <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
      <Card
        style={{ height: "100%", minHeight: 450 }}
      >
        <Skeleton animation="wave" variant="rect" style={{ marginBottom: 6 }} />
        <Skeleton
          animation="wave"
          variant="circle"
          width={200}
          height={200}
          style={{ margin: "auto" }}
        />
        <CardContent>
          <React.Fragment>
            <Skeleton
              animation="wave"
              variant="rect"
              width="80%"
              style={{ marginTop: "30px" }}
            />
            <Skeleton
              animation="wave"
              variant="rect"
              width="90%"
              style={{ marginTop: "20px" }}
            />
            <Skeleton
              animation="wave"
              width="100%"
              style={{ marginTop: "20px" }}
            />
            <Skeleton
              animation="wave"
              variant="rect"
              width="90%"
              style={{ marginTop: "20px" }}
            />
          </React.Fragment>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default SkeletonCard;
