import React from "react";

import Grid from "@material-ui/core/Grid";
import {
  Card,
  CardContent,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(() => ({
  clubSkeletonCard: {
    margin: 12, 
    height: "fit-content", 
    width: "-webkit-fill-available"
  },
  skeletonHeader: {
    marginBottom: 6
  },
  skeletonBar: {
    marginTop: 10,
  },
}));

const SkeletonCards = (props) => {
  const {count} = props;
  const classes = useStyles();

  return [...Array(count)].map((e, i) => (
    <SkeletonCard key={i} classes={classes}/>
  ));
} 

const SkeletonCard = (props) => {
  const {classes} = props;
  return (
    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
      <Card className={classes.clubSkeletonCard}>
        <CardContent>
          <React.Fragment>
            <Skeleton
              animation="wave"
              variant="rect"
              className={classes.skeletonBar}
            />
            <Skeleton
              animation="wave"
              variant="rect"
              className={classes.skeletonBar}
            />
            <Skeleton
              animation="wave"
              variant="rect"
              className={classes.skeletonBar}
            />
            <Skeleton
              animation="wave"
              variant="rect"
              className={classes.skeletonBar}
            />
            <Skeleton
              animation="wave"
              variant="rect"
              className={classes.skeletonBar}
            />
          </React.Fragment>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default SkeletonCards;