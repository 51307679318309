import { getAuthHeader } from '../tokenStorage';
import { axiosInstance } from './axiosInstance';

export const getUserCertificatesByProgram = async ({ programId }) => {
  return axiosInstance.get(`/blapi/certificates/getUserProgramCertificates/${programId}`,
    {
      headers: getAuthHeader(),
    });
};

export const getUserCertificates = async ({ userId }) => {
  return axiosInstance.get(`/blapi/certificates/getUserCertificates/${userId}`,
    {
      headers: getAuthHeader(),
    });
};

export const getAllUserCertificatesByUserId = async ({ userId }) => {
  return axiosInstance.get(`/blapi/certificates/getAllUserCertificates/${userId}`,
    {
      headers: getAuthHeader(),
    });
};

export const getUserProgramCertificatesByUserId = async ({ userId, programId }) => {
  return axiosInstance.get(`/blapi/certificates/getUserProgramCertificates/${programId}/${userId}`,
    {
      headers: getAuthHeader(),
    });
};

export const getSignedUrlCerfificate = async ({ title, format }) => {
  return axiosInstance.get(`/blapi/certificates/getCertificateUrl/${title}/${format}`);
};

export const downloadCertificate = async (item) => {
  return axiosInstance.get(`/blapi/certificates/download/${item.title}/pdf`,
    {
      headers: getAuthHeader(),
      responseType: "blob",
    });
};

export const getCertificateGenerationStatus = async (programId) => {
  return axiosInstance.get(`/blapi/certificates/getCertificateGenerationStatus/${programId}`,
    {
      headers: getAuthHeader(),
    });
};

export const generateCertificates = async ({ programId, studentIds }) => {
  return axiosInstance.post(
    `/blapi/certificates/generateCertificates`,
    { programId, studentIds },
    {
      headers: getAuthHeader(),
    }
  );
};

export const generateCertificate = async ({ userId, programId }) => {
  return axiosInstance.get(`/blapi/certificates/generateCertificate/${userId}/${programId}`,
    {
      headers: getAuthHeader(),
    });
};

export const viewCertificateTemplate = async (programId) => {
  return axiosInstance.get(`/blapi/certificates/viewTemplate/${programId}`,
    {
      headers: getAuthHeader(),
    });
};

export const loadCertificatePDFTemplate = async (programId) => {
  return axiosInstance.get(`/blapi/certificates/loadTemplate/${programId}`,
    {
      headers: getAuthHeader(),
      responseType: "blob",
    });
};

export const downloadAllCertificates = async (programId) => {
  return axiosInstance.get(`/blapi/certificates/${programId}/downloadAll`,
    {
      headers: getAuthHeader(),
      responseType: "blob",
    });
};

export const getCertificateNameChangeStatus = async (certificateId) => {
  return axiosInstance.get(`/blapi/certificates/getCertificateNameChangeStatus/${certificateId}`,
    {
      headers: getAuthHeader(),
    });
};

export const generateRenamedCertificates = async (payload) => {
  return axiosInstance.post(
    `/blapi/certificates/generateRenamedCertificates`,
    payload,
    {
      headers: getAuthHeader(),
    }
  );
};
