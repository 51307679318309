import React from "react";

import {
  makeStyles,
} from "@material-ui/core/styles";
import CheckCircle from '@material-ui/icons/CheckCircle';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import MoodIcon from '@material-ui/icons/Mood';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';

import { ColorTheme } from '../../utils/colorTheme';

const useStyles = makeStyles(() => ({
  checkmarkIcon: {
    color: ColorTheme.teal3.b,
  },
  sadFace: {
    color: ColorTheme.blue2.b
  },
  neutralFace: {
    color: ColorTheme.red2.b
  },
  happyFace: {
    color: ColorTheme.teal3.b
  },
  progressPage: {
    color: ColorTheme.blue2.b,
    fontSize: '1.25rem',
  },
}));

export const RatingIcon = (props) => {
  const classes = useStyles();
  const { rate, progressPage } = props;
  
  if (!rate || [-1, 0, 1].indexOf(rate.rating) == -1) {
    return (progressPage ? <CheckCircle className={classes.progressPage} /> : <CheckCircleOutline className={classes.checkmarkIcon} />)
  }

  switch (rate.rating) {
    case -1:
      return (<MoodBadIcon className={progressPage ? classes.progressPage : classes.sadFace} sel="sad-face"/>);
    case 0:
      return (<SentimentSatisfiedIcon className={progressPage ? classes.progressPage : classes.neutralFace} sel="neutral-face"/>);
    default:
      return (<MoodIcon className={progressPage ? classes.progressPage : classes.happyFace} sel="happy-face"/>);
  }
};
