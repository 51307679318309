import { call, put } from "redux-saga/effects";

import {
  receivedAnswer,
  receivedMyAnswersByProgram,
  receivedAnswersForActivity,
  receivedCommentsByAnswer,
  receiveCommentsByUser,
  receiveCommentsForAnswerByUser,
  receivedAllAnswersForProgram,
  receiveAddedComment,
  receivedUpdatedStatus,
  receivedDeletedComment,
  receivedUserAnswersByProgram,
  addCommentError,
  createAnswerError,
  deleteCommentError,
  getCommentsByUserError,
  getCommentsByAnswerError,
  getCommentsForAnswerByUserError,
  getMyAnswersByProgramError,
  getUserAnswersByProgramError, receiveUpdatedComment,
} from "../actions/openText";
import {
  updateMyAnswerGroups,
} from "../actions/answerGroups";
import {
  receivedMembersCount,
} from '../actions/members';
import * as openTextApi from '../api/openTextAnswers';


import {
  showErrorMessage,
  showSuccessMessage,
} from "../actions/notification";

const successUpdateStatus = "Answer status was successfully updated";
const successDeletedComment = "Comment was successfully deleted";
const successPostedComment = "Comment was successfully added";
const successUpdatedComment = "Comment was successfully updated";

const errorUpdateStatus = "Answer Status wasn't updated";
const errorDeletedComment = "Comment wasn't deleted";
const errorPostedComment = "Comment wasn't added";
const errorUpdatedComment = "Comment wasn't updated";


export function* createAnswer({ payload }) {
  try {
    const { question, path } = payload;
    let { data } = yield call(openTextApi.createAnswer, payload);

    yield put(receivedAnswer({ question, path, answer: data }));
    yield put(updateMyAnswerGroups(data));
  } catch (e) {
    yield put(showErrorMessage("Your answer hasn't been saved due to error. Please reload the page and try again."));
    yield put(createAnswerError());
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* getMyAnswersByProgram({ payload }) {
  try {
    let { data } = yield call(openTextApi.getMyAnswersByProgram, payload);

    yield put(receivedMyAnswersByProgram({ data }));
  } catch (e) {
    yield put(getMyAnswersByProgramError());
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* getUserAnswersByProgram({ payload }) {
  try {
    let { data } = yield call(openTextApi.getUserAnswersByProgram, payload);

    yield put(receivedUserAnswersByProgram({ data }));
  } catch (e) {
    yield put(getUserAnswersByProgramError());
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* getAnswersForActivity({ payload }) {
  try {
    let { data } = yield call(openTextApi.getAllAnswersByActivity, payload);
    yield put(receivedAnswersForActivity({ ...payload, data }));
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* getCommentsByAnswer({ payload }) {
  try {
    let { data } = yield call(openTextApi.getCommentsByAnswer, payload);

    yield put(receivedCommentsByAnswer({ ...payload, comments: data }));
  } catch (e) {
    yield put(getCommentsByAnswerError());
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* getCommentsForAnswerByUser({ payload }) {
  try {
    let { data } = yield call(openTextApi.getCommentsByUser, payload);

    yield put(receiveCommentsForAnswerByUser({ ...payload, comments: data }));
  } catch (e) {
    yield put(getCommentsForAnswerByUserError());
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* getCommentsByUser({ payload }) {
  try {
    let { data } = yield call(openTextApi.getCommentsByUser, payload);

    yield put(receiveCommentsByUser({ ...payload, comments: data }));
  } catch (e) {
    yield put(getCommentsByUserError());
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* getAllAnswersForProgram({ payload }) {
  try {
    let {
      data: {
        answers,
        members,
        programId,
        totalScore
      }
    } = yield call(openTextApi.getAllAnswersForProgram, payload);

    yield put(receivedAllAnswersForProgram({ ...payload, answers, members }));
    yield put(receivedMembersCount({programId, totalScore}));
  } catch (e) {
    yield put(receivedMembersCount(null));
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* addComment({ payload }) {
  try {
    let { data } = yield call(openTextApi.addComment, payload);

    if (!data || !data.id) {
      yield put(showErrorMessage(errorPostedComment));
    } else {
      yield put(receiveAddedComment({ ...payload, data }));
      yield put(showSuccessMessage(successPostedComment));
    }

  } catch (e) {
    yield put(showErrorMessage(errorPostedComment));
    yield put(addCommentError());
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* updateComment({ payload }) {
  try {
    let { data } = yield call(openTextApi.updateComment, payload);

    if (!data || !data.id) {
      yield put(showErrorMessage(errorUpdatedComment));
    } else {
      yield put(receiveUpdatedComment({ ...payload, data }));
      yield put(showSuccessMessage(successUpdatedComment));
    }

  } catch (e) {
    yield put(showErrorMessage(errorUpdatedComment));
    yield put(addCommentError());
  }
}


export function* updateStatus({ payload }) {
  try {
    let { data } = yield call(openTextApi.updateStatus, payload);

    if (!data || !data.id) {
      yield put(showErrorMessage(errorUpdateStatus));
    } else {
      yield put(receivedUpdatedStatus({ ...payload, data }));
      yield put(showSuccessMessage(successUpdateStatus));
    }
  } catch (e) {
    yield put(showErrorMessage(errorUpdateStatus));
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* deleteComment({ payload }) {
  try {
    let { data } = yield call(openTextApi.deleteComment, payload);

    if (!data || !data.id) {
      yield put(showErrorMessage(errorDeletedComment));
    } else {
      yield put(receivedDeletedComment(payload));
      yield put(showSuccessMessage(successDeletedComment));
    }
  } catch (e) {
    yield put(showErrorMessage(errorDeletedComment));
    yield put(deleteCommentError());
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}