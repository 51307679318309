import React from 'react';
import objectPath from 'object-path';

import InputAdornment from '@material-ui/core/InputAdornment';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { StyledFormHelper } from './loginSignup/customFormHelper';

const TextArea = ({ field, form: { errors, touched }, label, helperText, Icon, errorIsHtml, maxLength, maxValue, minValue, maxLengthInfo, maxLengthStartingFrom, ...props }) => {
  let error = errors[field.name];
  let isTouched = touched[field.name];

  if (field.name.indexOf('[') !== -1 || field.name.indexOf('.') !== -1) {
    const path = field.name.replace('[', '.').replace(']', '').split('.');
    error = objectPath.get(errors, path);
    isTouched = objectPath.get(touched, path);
  }

  const remainingLength = field.value && maxLength && maxLengthInfo ? maxLength - field.value.length : 0;
  const showRemainingLength = maxLengthStartingFrom ? maxLengthStartingFrom > remainingLength : true;

  return (
    <React.Fragment>
    <TextareaAutosize
      {...props}
      name={field.name}
      onChange={field.onChange}
      error={!!error && isTouched}
      fullWidth
      placeholder={label}
      helperText={error && isTouched ? (errorIsHtml ? "" : error) : helperText}
      variant={props.variant || "outlined"}
      value={field.value}
      inputProps={{
        maxLength: maxLength,
        max: maxValue,
        min: minValue,
      }}
      InputProps={{
        placeholder: label,
        startAdornment: Icon ? (
          <InputAdornment position="start">
            <Icon />
          </InputAdornment>
        ) : null,
      }}
    />
    {maxLength && field.value && maxLengthInfo && showRemainingLength
    && <StyledFormHelper helperText={`${maxLengthInfo.replace("${value}", remainingLength)}`} isHtml={true} />}
    {error && isTouched &&
      (errorIsHtml
        ? <StyledFormHelper helperText={error} isError={true} isHtml={true} />
        : "")}
    </React.Fragment>
  );
}

export default TextArea;
