import React from "react";

import {
  Box,
  Typography,
  Divider,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import linkifyHtml from "linkify-html";

import TextCustomButton from "../../../../components/text-button/text-button";
import AddComment from "./addComment";
import CommentCard from "./commentCard";
import { createMarkup, getDateLabel } from "../../../../utils/generic";
import * as colors from "../../../../theme/colors";

const useStyles = makeStyles((theme) => ({
  boxWrapper: {
    width: '100%',
    "&:not(:last-child)": {
      marginBottom: 50,
    },
    [theme.breakpoints.down('sm')]: {
      width: "90%",
    },
  },
  cardContainer: {
    width: '100%',
    marginTop: 20,
  },
  answerBox: {
    border: "1px solid " + colors.grey500,
    color: colors.darkThemeBlueGray,
    minHeight: 100,
    padding: 20,
    "& a": {
      color: colors.darkThemeGreen,
      fontSize: 14,
    }
  },
  itemDivider: {
    marginTop: 40,
  },
  commentsBox: {
    marginTop: 10,
    justifyContent: "center",
  },
  answerCreatedAt: {
    color: colors.lightGrey,
    fontSize: 12,
  },
  addCommentBox: {
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    marginTop: 5,
  },
  chatBubbleIcon: {
    marginRight: 3,
    height: 20,
  },
}));

export const AnswerCard = ({
  user,
  answer,
  isLast,
  addComment,
  deleteComment,
  headerContent,
  addCommentToAnswer,
  setAddCommentToAnswer,
  showDate,
  history,
  updateComment,
  updateAnswerGroupComment,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.boxWrapper}>
      {showDate && <Typography className={classes.answerCreatedAt}>{getDateLabel(answer.createdAt)}</Typography>}
      <Box className={classes.cardContainer}>
        <Box className={classes.answerBox} dangerouslySetInnerHTML={createMarkup(linkifyHtml(answer.content, { target: "_blank" }))} />
        {!addCommentToAnswer[answer.id] && <Box>
          <TextCustomButton
            onClick={() => {
              setAddCommentToAnswer({ id: answer.id, value: true });
            }}
            label={<Box className={classes.addCommentBox}>
              <ChatBubbleOutlineIcon className={classes.chatBubbleIcon} />
              Add comments
            </Box>}
          />
        </Box>}
        <Box className={classes.commentsBox}>
          {addCommentToAnswer[answer.id]
            ? <AddComment
              answer={answer}
              onCancel={() => setAddCommentToAnswer({ id: answer.id, value: false })}
              onSubmit={addComment}
              headerContent={headerContent}
              history={history}
            />
            : <>{!!answer.comments?.length && answer.comments.map((comment) => {
              return <CommentCard
                key={comment.id}
                classes={classes}
                answer={answer}
                comment={comment}
                userId={user.id}
                deleteComment={deleteComment}
                updateComment={updateComment}
                updateAnswerGroupComment={updateAnswerGroupComment}
                history={history}
              />
            })
            }</>
          }
        </Box>
      </Box>
      {!isLast && <Divider className={classes.itemDivider} />}
    </Box>
  );
}