import React, { useState, useEffect } from "react";
import clsx from 'clsx';

import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/ErrorRounded';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import WarningIcon from '@material-ui/icons/Warning';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import * as colors from "../../../../theme/colors";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';

import { ColorTheme } from '../../../../utils/colorTheme';
import UserTableBody from './UserTableBody';
import SortingTable from './SortingTable';
import sortingIcon from '../../../../images/sortingIcon.jfif';
import { TASK_STATUSES } from '../../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  table: {
    width: 380,
    overflowY: "auto",
    [theme.breakpoints.down('sm')]: {
      marginTop: "-30px",
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: 720,
    },
  },
  headerTitle: {
    color: colors.darkThemeBlue,
    fontSize: 16,
    fontWeight: "bold",
  },
  icon: {
    fontSize: '1rem',
    marginLeft: 5,
    marginBottom: '-2px',
  },
  doneIcon: {
    fill: ColorTheme.teal2.b,
    color: ColorTheme.teal2.b,
  },
  notDoneIcon: {
    fill: ColorTheme.red4.b,
    color: ColorTheme.red4.b,
  },
  incompleteIcon: {
    color: ColorTheme.orange3.b,
    fill: ColorTheme.orange3.b,
  },
  notStartedIcon: {
    color: ColorTheme.blue4.b,
    fill: ColorTheme.blue4.b,
  },
  sortingIcon: {
    height: 20,
  },
  sortingCell: {
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 30,
      width: 80,
    },
    [theme.breakpoints.down('xs')]: {
      width: 110,
    },
  }
}));

const UserTable = ({
  answers,
  setViewUserKey,
  viewUserKey,
  requiredGrading,
  answersToReviewToRender,
  answersCompletedToRender,
  answersIncompleteToRender,
  answersNotStartedToRender,
  onShowMembersClose,
  getCommentsByUser,
  isAnswerGroup,
  setSortByDate,
  sortByDate,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openToReviewCollapse, setOpenToReviewCollapse] = useState(false);
  const [openCompleteCollapse, setOpenCompleteCollapse] = useState(false);
  const [openIncompleteCollapse, setOpenIncompleteCollapse] = useState(false);
  const [openNotStartedCollapse, setOpenNotStartedCollapse] = useState(false);

  useEffect(() => {
    if (requiredGrading && answersToReviewToRender) {
      setOpenToReviewCollapse(!!answersToReviewToRender.length);
    }
  }, [requiredGrading, answersToReviewToRender]);

  useEffect(() => {
    if (!requiredGrading && answersCompletedToRender) {
      setOpenCompleteCollapse(!!answersCompletedToRender.length);
    }
  }, [requiredGrading, answersCompletedToRender]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getSortingLocation = () => {
    switch (true) {
      case (!!answersToReviewToRender?.length):
        return TASK_STATUSES.review;
      case (!!answersCompletedToRender?.length):
        return TASK_STATUSES.complete;
      case (!!answersIncompleteToRender?.length):
        return TASK_STATUSES.incomplete;
      default:
        return TASK_STATUSES.notStarted;
    };
  }

  return (
  
    <TableContainer component={Paper} className={classes.table}>
      <Table>
        {requiredGrading && !!answersToReviewToRender?.length && <TableHead>
          <TableRow>
            <TableCell className={classes.headerTitle}>
              <IconButton
                style={{color: colors.teal2 }}
                aria-label={`${openToReviewCollapse ? 'collapse' : 'expand'} row with to review status`}
                aria-expanded={openToReviewCollapse ? "true" : "false"}
                role="button"
                size="small"
                onClick={() => {
                  setOpenToReviewCollapse(!openToReviewCollapse);
                }}
              >
                {openToReviewCollapse ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
              To Review
              <ErrorIcon className={clsx(classes.icon, classes.notDoneIcon)}/>
            </TableCell>
            <TableCell className={classes.sortingCell}>
              {getSortingLocation() === TASK_STATUSES.review && <IconButton onClick={handleClick} className={classes.sortingIconWrapper}>
                <img className={classes.sortingIcon} alt="sorting" src={sortingIcon} />
              </IconButton>}
            </TableCell>
          </TableRow>
        </TableHead>}
        {requiredGrading && !!answersToReviewToRender?.length && openToReviewCollapse && (
          <UserTableBody
            answers={answers}
            answersToRender={answersToReviewToRender}
            setViewUserKey={setViewUserKey}
            viewUserKey={viewUserKey}
            onShowMembersClose={onShowMembersClose}
            getCommentsByUser={getCommentsByUser}
            isAnswerGroup={isAnswerGroup}
            />
        )}
        {!!answersCompletedToRender?.length && <TableHead>
          <TableRow>
            <TableCell className={classes.headerTitle}>
              <IconButton
                  style={{color: colors.teal2 }}
                  aria-label={`${openCompleteCollapse ? 'collapse' : 'expand'} row with complete status`}
                  aria-expanded={openCompleteCollapse ? "true" : "false"}
                  role="button"
                  size="small"
                  onClick={() => {
                    setOpenCompleteCollapse(!openCompleteCollapse);
                  }}
                >
                  {openCompleteCollapse ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
              Complete
              <CheckCircleIcon className={clsx(classes.icon, classes.doneIcon)}/>
            </TableCell>
            <TableCell className={classes.sortingCell}>
              {getSortingLocation() === TASK_STATUSES.complete && <IconButton onClick={handleClick}>
                <img className={classes.sortingIcon} alt="sorting" src={sortingIcon} />
              </IconButton>}
            </TableCell>
          </TableRow>
        </TableHead>}
        {!!answersCompletedToRender?.length && openCompleteCollapse && (
          <UserTableBody
            answers={answers}
            answersToRender={answersCompletedToRender}
            setViewUserKey={setViewUserKey}
            viewUserKey={viewUserKey}
            onShowMembersClose={onShowMembersClose}
            getCommentsByUser={getCommentsByUser}
            isAnswerGroup={isAnswerGroup}
          />
        )}
        {!!answersIncompleteToRender?.length && <TableHead>
          <TableRow>
            <TableCell className={classes.headerTitle}>
              <IconButton
                  style={{color: colors.teal2 }}
                  aria-label={`${openIncompleteCollapse ? 'collapse' : 'expand'} row with incomplete status`}
                  aria-expanded={openIncompleteCollapse ? "true" : "false"}
                  role="button"
                  size="small"
                  onClick={() => {
                    setOpenIncompleteCollapse(!openIncompleteCollapse);
                  }}
                >
                {openIncompleteCollapse ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
              Incomplete
              <WarningIcon className={clsx(classes.icon, classes.incompleteIcon)}/>
            </TableCell>
            <TableCell className={classes.sortingCell}>
              {getSortingLocation() === TASK_STATUSES.incomplete && <IconButton onClick={handleClick}>
                <img className={classes.sortingIcon} alt="sorting" src={sortingIcon} />
              </IconButton>}
            </TableCell>
          </TableRow>
        </TableHead>}
        {!!answersIncompleteToRender?.length && openIncompleteCollapse && (
          <UserTableBody
            answers={answers}
            answersToRender={answersIncompleteToRender}
            setViewUserKey={setViewUserKey}
            viewUserKey={viewUserKey}
            onShowMembersClose={onShowMembersClose}
            getCommentsByUser={getCommentsByUser}
            isAnswerGroup={isAnswerGroup}
            showWarningIcon={true}
          />
        )}
        {!!answersNotStartedToRender?.length && <TableHead>
          <TableRow>
            <TableCell className={classes.headerTitle}>              
              <IconButton
                style={{color: colors.teal2 }}
                aria-label={`${openNotStartedCollapse ? 'collapse' : 'expand'} row with not started status`}
                aria-expanded={openNotStartedCollapse ? "true" : "false"}
                role="button"
                size="small"
                onClick={() => {
                  setOpenNotStartedCollapse(!openNotStartedCollapse);
                }}
              >
                {openNotStartedCollapse ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
              Not Started
              <EventBusyIcon className={clsx(classes.icon, classes.notStartedIcon)}/>
            </TableCell>
          <TableCell className={classes.sortingCell}>
            {getSortingLocation() === TASK_STATUSES.notStarted && <IconButton onClick={handleClick}>
              <img className={classes.sortingIcon} alt="sorting" src={sortingIcon} />
            </IconButton>}
          </TableCell>
          </TableRow>
        </TableHead>}
        {!!answersNotStartedToRender?.length && openNotStartedCollapse && (
          <UserTableBody
            answers={answers}
            answersToRender={answersNotStartedToRender}
            setViewUserKey={setViewUserKey}
            viewUserKey={viewUserKey}
            onShowMembersClose={onShowMembersClose}
            getCommentsByUser={getCommentsByUser}
            isAnswerGroup={isAnswerGroup}
          />
        )}
      </Table>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <SortingTable
          setSortByDate={setSortByDate}
          sortByDate={sortByDate}
          setAnchorEl={setAnchorEl}
        />
      </Popover>
    </TableContainer>)
}

export default UserTable;