import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';

import history from './history';
import { logout, unauthorize } from './actions/auth';
import { setToken, setRefreshToken } from './tokenStorage';

export const sagaMiddleware = createSagaMiddleware();

const localStorageMiddleware = store => next => action => {
  if (action.type === unauthorize.getType()) {
    localStorage.clear();
  }

  const prevState = store.getState()
  const result = next(action)
  const nextState = store.getState()

  if (prevState.auth.token !== nextState.auth.token && nextState.auth.token) {
    if (action.type !== logout.getType()) {
      setToken(nextState.auth.token);
      setRefreshToken(nextState.auth.refreshToken);
    }
  }
  return result;
}

export default [
  routerMiddleware(history),
  localStorageMiddleware,
  sagaMiddleware,
];
