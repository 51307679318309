import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography
} from "@material-ui/core/";

import { HQModal } from "../HQModal";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import Roles from "../../../utils/permissions";
import CustomButton from "../../customButton/customButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import MultipleGroupedSelector from "../members/multipleGroupedSelector";

const useStyles = makeStyles((theme) => ({
  sectionBtn: {
    padding: 0,
    textAlign: 'left'
  },
  sectionBox: {
    marginTop: 15,
  },
  sectionDesc: {
    fontSize: 14
  },
  inviteOptionsBox: {
    marginBottom: 20
  },
  membersSelectionBox: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 400,
    }
  },
  title: {
    fontSize: 16,
    margin: "10px 0",
    textAlign: 'center'
  },
  subTitle: {
    marginBottom: 10,
    fontSize: 14
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 35,
  },
  addBtn: {
    fontSize: 16,
    padding: '0 25px'
  },
  progressIcon: {
    width: 20,
    height: 20,
    marginLeft: 5,
  },
  boxSection: {
    display: "flex",
    marginBottom: 5,
    [theme.breakpoints.down('xs')]: {
      display: "block",
    },
  },
  attendanceField: {
    margin: '0 auto',
    width: 300,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 10,
      marginLeft: 0,
    },
    '& .MuiTypography-body1': {
      lineHeight: 'inherit',
    }
  }
}));

export const AddLeadershipModal = ({
  isOpen,
  onCloseModal,
  club,
  programMembers,
  isLoadingProgramMembers,
  loadProgramMembers,
  createBulkCollegeLoopPresidentsRoles,
  getMembers,
  user,
  getClubInfo,
  defaultProgram,
}) => {
  const classes = useStyles();
  const [selectedAttendees, setSelectedAttendees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const programCode = club.code;

  useEffect(() => {
    if (!user || !club) return;
    loadProgramMembers([club.id]);
  }, [club]);

  const items = !!programMembers[club.id] && programMembers[club.id]
    .filter((e) => e.roleId == Roles.Student)
    .map((el) => {
      return {
        id: el.userId,
        label: `${el.firstName} ${el.lastName}`,
      };
    }).filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);

  const createUserRoles = (attendeesIds) => {
    setIsLoading(true);
    return createBulkCollegeLoopPresidentsRoles({
      programId: club.id,
      userIds: attendeesIds,
      sfProgramId: club.sfProgramId,
      programCode
    })
      .finally (() => {
        setIsLoading(false);
        getMembers(club.id);
        getClubInfo({ programCode, defaultProgram })
        onCloseModal();
      });
  }

  return (
    <HQModal title="Add Leadership" 
      titleOptions={{isUpperCase: true, isBold: true}}
      isOpen={isOpen} onClose={onCloseModal}>
      <Box className={classes.membersSelectionBox}>
      <Typography id="dialog-description" className={classes.title}>Select members to add to leadership:</Typography>
      <Formik
        validationSchema={Yup.object({
          attendeeIds: Yup.array().required("Required"),
        })}
        initialValues={{
          attendeeIds: [],
        }}
        validate={(values) => {
          let errors = {};
          setSelectedAttendees(values.attendeeIds);
          return errors;
        }}
        onSubmit={(values) => {
          createUserRoles(values.attendeeIds);
        }}
      >
        {({ values }) => (<Form>
          <Box className={classes.boxSection}>
            <Field
              name={`attendeeIds`}
              className={classes.attendanceField}
              component={MultipleGroupedSelector}
              title={values.attendeeIds.length === 1 ? 'Member' : 'Members'}
              label="Members"
              options={items}
              isLoading={isLoadingProgramMembers}
            />
          </Box>
          <Box className={classes.btnContainer}>
            <CustomButton mode={"secondary"} onClick={() => onCloseModal()}>Cancel</CustomButton>
            <CustomButton
              className={classes.addBtn}
              disabled={!selectedAttendees.length || isLoading}
              mode={"primary"}
              type="submit"
              label={"Save"}>
                {isLoading && (
                  <CircularProgress
                    size={20}
                    className={classes.progressIcon}
                  />
                )}
            </CustomButton>
          </Box>
        </Form>)}
      </Formik>
    </Box>
    </HQModal>
  );
};
