import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Field, Form } from "formik";
import {
  Box,
  Typography,
} from "@material-ui/core/";

import TextButton from "../../../text-button/text-button";
import CustomButton from "../../../customButton/customButton";
import UserNamesTextFieldGroup from "./UserNamesTextFieldGroup";
import { ColorTheme } from '../../../../utils/colorTheme';

const useStyles = makeStyles({
  dialogContent: {},
  title: {
    color: ColorTheme.blue4.b,
    fontSize: 16,
    fontWeight: "bold",
    margin: "10px 0",
  },
  subTitle: {
    marginBottom: 10,
    fontSize: 14
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 25,
  },
  inviteBtn: {
    fontSize: 16,
    padding: '0 25px'
  },
  progressIcon: {
    width: 20,
    height: 20,
    marginLeft: 5,
  },
});

export const CreateMembersModal = ({
  onCloseModal,
  onNext,
  setUserNames,
  userNames,
}) => {
  const classes = useStyles();
  const [enableGeneration, setEnableGeneration] = useState(false);

  const onCloseClick = () => {
    setUserNames([]);
    onCloseModal();
  }

  return (
    <Box>
      <Typography id="dialog-description" className={classes.title}>Add your students to create their accounts.</Typography>
      <Typography className={classes.subTitle}>
        Enter student names or nicknames below. Separate each name with a line break. We will generate a username and first time password.
      </Typography>
      <Box>
        <Formik
          initialValues={{
            currentName: !!userNames ? userNames.join("\n") : "",
            names: userNames,
          }}
          validate={(values) => {
            let errors = {};
            if (!values.names && !values.currentName) {
              errors.body = "There must be at least one user";
              setEnableGeneration(false)
            } else{
              setEnableGeneration(values.names.length > 0 || !!values.currentName);
            }
            return errors;
          }}
          onSubmit={(values) => {
            setUserNames(values.names);
            onNext();
          }}
          validateOnMount={true}
        >
          <Form>
            <Field
              name="currentName"
              originalValue="names"
              defaultValues={userNames}
              component={UserNamesTextFieldGroup}
              minRows={10}
              maxRows={40}
              label={"Name"}
              required
            />
            <Box className={classes.btnContainer}>
              <TextButton onClick={() => onCloseClick()}>CANCEL</TextButton>
              <CustomButton
                disabled={!enableGeneration}
                className={classes.inviteBtn}
                mode={"primary"}
                type="submit"
                label="Next">
              </CustomButton>
            </Box>
          </Form>
        </Formik>
      </Box>
    </Box>
  );
};
