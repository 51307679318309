import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import { StyledFormHelper } from "../../../loginSignup/customFormHelper";
import * as colors from "../../../../theme/colors";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginRight: 20,
    width: "100%",
    maxWidth: "100%",
  },
  contentField: {
    padding: 0,
  },
  colorPrimaryAdd: {
    backgroundColor: "#e0e0e0",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    color: "#504e4e",
    padding: 5,
    verticalAlign: "top",
    marginTop: 15,

    "&:hover": {
      color: "#ffffff",
      backgroundColor: theme.palette.primary.main,
    },
  },
  iconBtn: {
    backgroundColor: (props) => props.backgroundColor,
    border: (props) => `2px solid ${props.borderColor}`,
    color: (props) => props.mainColor,
    padding: 3,
    fontSize: "inherit",
    fontWeight: 700,
    position: "relative",
    verticalAlign: "top",
    marginTop: 15,

    "&:hover": {
      borderColor: (props) => props.secondColor,
      backgroundColor: (props) => props.hoverBackgroundColor,
    },
  },
  chipItem: {
    margin: 5,
  },
  notification: {
    marginTop: 5,
    fontSize: 14
  },
}));

const styleTypes = {
  primary: {
    mainColor: colors.dartThemeBlueGray,
    secondColor: colors.dartThemeBlueGray,
    backgroundColor: colors.dartThemeBlueGray,
    borderColor: colors.lightThemeGreen,
    hoverBackgroundColor: colors.lightThemeGreen,
    backgroundColor: colors.lightThemeGreen,
  },
  secondary: {
    mainColor: colors.dartThemeBlueGray,
    secondColor: colors.dartThemeBlueGray,
    borderColor: colors.lightThemeGreen,
    hoverBackgroundColor: colors.white,
    backgroundColor: colors.white,
  },
  disabled: {
    mainColor: colors.black,
    secondColor: colors.greyLight2,
    backgroundColor: colors.dartThemeBlueGray,
    borderColor: colors.greyLight2,
    hoverBackgroundColor: colors.greyLight2,
    backgroundColor: colors.greyLight2,
  },
};

const TextFieldGroup = (props) => {
  const {
    field: { name, value },
    form: { errors, setFieldValue, setFieldTouched, touched, values },
    label,
    mode = "secondary",
    originalValue,
    disabled,
    defaultValues,
  } = props;

  const classes = useStyles(disabled ? styleTypes.disabled : styleTypes[mode]);
  const names = values[originalValue];

  let isTouched = touched[name];
  const error = errors[name];

  return (
    <Box>
      <Container className={classes.contentField}>
        <TextareaAutosize
          {...props}
          className={classes.textField}
          fullWidth
          error={error && isTouched}
          value={value}
          helperText={error ? error : null}
          label={label}
          defaultValue={defaultValues.join("\n")}
          variant="outlined"
          onChange={(event) => {
            const parsedValues = event.target.value
              .split("\n")
              .map((e) => e.trim());

            setFieldValue(originalValue, parsedValues);
            setFieldValue(name, event.target.value);

            setFieldTouched(name, true, false);
          }}
        />
        {error && isTouched && (
          <StyledFormHelper helperText={error} isHtml={true} isError={true} />
        )}
      </Container>
      <Typography
        className={classes.notification}
      >{`Create (${names.filter((name) => !!name).length}) accounts`}</Typography>
      <Typography className={classes.notification}>
        Creating accounts for your members indicates that you have parent permission to create this account and have read our Terms of Service and Privacy Policy.
      </Typography>
    </Box>
  );
};

export default TextFieldGroup;