import { createAction } from 'redux-act';

export const getMembers = createAction();
export const receivedMembers = createAction();
export const getInvitations = createAction();
export const receivedInvitations = createAction();
export const changeUserPassword = createAction();
export const passwordChanged = createAction();
export const getRecruitmentResourceLink = createAction();
export const receivedRecruitmentResourceLink = createAction();
export const deleteUserRoleByProgram = createAction();
export const userRoleDeleted = createAction();
export const createUsersGroups = createAction();
export const createdUsersGroups = createAction();
export const getUsersGroups = createAction();
export const receivedUsersGroups = createAction();
export const deleteUsersGroup = createAction();
export const usersGroupDeleted = createAction();
export const updateUsersGroup = createAction();
export const updatedUsersGroup = createAction();
export const onError = createAction();
export const getProgramCertificateId = createAction();
export const receivedProgramCertificateId = createAction();
export const receivedMembersCount = createAction();
