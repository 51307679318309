import { createReducer } from 'redux-act'

import * as a from '../actions/decisionMakers';

const getDefaultState = () => ({
  decisionMakers: {},
})

export default () =>
  createReducer(
    {
      [a.decisionMakerLoaded]: (state, data) => ({
        ...state,
        decisionMakers: {
          ...state.decisionMakers,
          [data.id]: data
        }
      }),
    },
    getDefaultState()
  )
