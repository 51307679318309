import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Typography,
  Link
} from "@material-ui/core";

import * as colors from "../../../theme/colors";
import CustomButton from "../../../components/customButton/customButton";
import TextCustomButton from "../../../components/text-button/text-button";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 15,
  },
  container: {
    "& .MuiTypography-h5": {
      fontWeight: 900,
    },
  },
  header: {
    fontSize: "22px",
    padding: 0,
    color: colors.darkBlue100,
    fontSize: 28,
    fontWeight: "900",
    textAlign: "center"
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  content: {
    display: "flex",
    marginTop: 25,
    alignItems: "center",

    [theme.breakpoints.down('xs')]: {
      display: "block",
      marginTop: 15
    }
  },
  imageContainer: {
    width: 100,
    height: 100,
    display: "flex",
    overflow: "hidden",
    position: "relative",
    fontSize: "1.25rem",
    alignItems: "center",
    flexShrink: 0,
    lineHeight: 1,
    borderRadius: "50%",
    justifyContent: "center",
    marginRight: 15,

    [theme.breakpoints.down('xs')]: {
      margin: '0 auto 15px',
      width: 65,
      height: 65
    }
  },
  exitBtn: {
    height: 42,
    color: colors.darkThemeBlue,
  },
  ctaSection: {
    display: "flex",
    margin: 10
  },
  ctaLink: {
    margin: "auto",
    '&:hover': {
      textDecoration: 'none'
    }
  },
  submitButton: {
    height: 42,
    minWidth: 150,
  }
}));

const AnnouncementCard = ({ item, onHide }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.container}>
        <CardHeader
          className={classes.header}
          title={item.heading}
          subheader={item.subheading}
        />
        <div className={classes.content}>
          <Typography>{item.body}</Typography>
        </div>
        {item.ctaUrl && item.ctaLabel && (
          <div className={classes.ctaSection}>
            <Link
              className={classes.ctaLink}
              target="_blank"
              href={item.ctaUrl}
            >
              <CustomButton mode={"secondary"} className={classes.submitButton} label={item.ctaLabel} />
            </Link>
          </div>
        )}
      </CardContent>
      <CardActions className={classes.btnContainer}>
        <TextCustomButton className={classes.exitBtn} mode="secondary" onClick={onHide} label={"Dismiss"} />
      </CardActions>
    </Card>
  );
};

export default AnnouncementCard;
