import React from "react";
import clsx from 'clsx';

import { makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import * as colors from "../../../../theme/colors";

const useStyles = makeStyles(() => ({
  popoverTableRow: {
    "&:hover": {
      backgroundColor: colors.lightBlue,
    },
    textAlign: 'right',
    cursor: "pointer",
    color: colors.darkThemeBlue,
    fontSize: 14,
  },
  popoverTable: {
    width: 100,
  },
  currentActive: {
    fontWeight: "bold",
  },
}));

const SortingTable = ({ setSortByDate, sortByDate, setAnchorEl }) => {
  const classes = useStyles();

  const handleSortClick = (sortAnswersByDate) => {
    setSortByDate(sortAnswersByDate)
    setAnchorEl(null)
  }

  return (
    <TableContainer component={Paper} className={classes.popoverTable}>
      <Table>
        <TableRow onClick={() => handleSortClick(false)}>
          <TableCell  className={!sortByDate ? clsx(classes.popoverTableRow, classes.currentActive) : classes.popoverTableRow}>
            A to Z
          </TableCell>
        </TableRow>
        <TableRow onClick={() => handleSortClick(true)}>
          <TableCell className={sortByDate ? clsx(classes.popoverTableRow, classes.currentActive) : classes.popoverTableRow}>
            Oldest
          </TableCell>
        </TableRow>
      </Table>
    </TableContainer>)
}

export default SortingTable;
