import React, { useEffect } from 'react';
import {
  Box,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import * as colors from "../../theme/colors";
import CustomButton from "../customButton/customButton";
import { createMarkup } from '../../utils/generic';

import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  main: {
    backgroundColor: colors.white,
    maxWidth: 350
  },
  submitButton: {
    height: 42,
    width: 100,
  },
  subTitle: {
    color: theme.palette.primary.main,
    fontSize: 16,
    lineHeight: 1.75,
  },
  link: {
    color: colors.darkThemeGreen,
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "initial",
    lineHeight: 1.75,
    marginTop: 10,
    "&:hover": {
      textDecoration: "none"
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: colors.white,
    backgroundColor: 'white',
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
    fontSize: '0.875rem',
    alignItems: 'center',
    height: 'auto',
    fontWeight: 400,
    lineHeight: 1.43,
    borderRadius: 2,
  },
  actionBtn: {
    minWidth: 100,
    margin: 2,
    height: 42,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  boxHtml: {
    '& a': {
      color: colors.darkThemeGreen,
      fontSize: 16,
      fontWeight: "bold",
      textTransform: "initial",
      textDecoration: "none",
      lineHeight: 1.75,
      marginTop: 10,
      "&:hover": {
        textDecoration: "none"
      },
    },
    '& p': {
      color: theme.palette.primary.main,
      fontSize: 16,
      lineHeight: 1.75,
      margin: "5px 0px",
    }
  }
}));

const Notification = props => {
  const { itemKey, handleClose, isShow, children, content, btnLabel } = props;

  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();


  const handleClick = () => {
    enqueueSnackbar(
    <Box
      className={classes.container}
      role="dialog"
      aria-describedby={`dialogDesc-${itemKey}`}
    >
      {!!content && !!content.length ?
        <Box className={classes.boxHtml} dangerouslySetInnerHTML={createMarkup(content)} />
        :
        <Box>
          {children}
        </Box>}
      <Box className={classes.buttonGroup}>
        <CustomButton
          className={classes.actionBtn}
          mode={"primary"}
          label={btnLabel}
          onClick={() => {
            handleClose();
            closeSnackbar(itemKey);
          }}
        />
      </Box>
    </Box>, { variant: 'infoPrivacy', key: itemKey, persist: true });
  };

  useEffect(() => {
    if (isShow) {
      handleClick();
    }
  }, [isShow]);

  return (
    <></>
  );
};

export default Notification;
