import React from "react";

export default () => (
  <div style={{ background: 'transparent', display: 'flex', borderRadius: 2 }}>
  <svg class="MuiSvgIcon-root jss79" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
    <path d="M21 3H3c-1.11 0-2 .89-2 2v12c0 1.1.89 2 2 2h5v2h8v-2h5c1.1 0 1.99-.9 1.99-2L23 5c0-1.11-.9-2-2-2zm0 14H3V5h18v12zm-5-6l-7 4V7z"></path>
    <path d="M 1 2 L 24 20" stroke="white" stroke-width="3"></path>
    </svg>
  </div>
  )
