import React from "react";
import clsx from 'clsx';

import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckIcon from '@material-ui/icons/Check';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Typography from "@material-ui/core/Typography";
import * as colors from "../../../theme/colors";

const useStyles = makeStyles((theme) => ({
  container: {
    width: 'auto',
    margin: "0 10px",
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  labelButton: {
    backgroundColor: "#0D9C90",
    color: "#fff",
    fontSize: "16px",
    display: "inline-flex",
    textTransform: "uppercase",
  },
  checkedIcon: {
    color: colors.darkThemeGreen,
  },
  disabledLabelButton: {
    backgroundColor: "#0D9C90",
    color: "#fff",
    fontSize: "16px",
    display: "inline-flex",
    textTransform: "uppercase",
  },
  labelActive: {
    color: "#fff",
  },
  labels: {
    backgroundColor: "white",
    "& .MuiTypography-body1": {
      color: colors.dartThemeBlueGray,
      fontSize: 14,
      fontWeight: 500,
    },
  },
  paperContainer: {
    top: '4px',
    zIndex: 11,
    backgroundColor: "#fff",
    alignItems: "center",
    "& div": {
      borderRadius: "inherit",
      padding: 1,
    },
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const SingleSelector = ({ value, options, onChange, code }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleMenuItemClick = (event, index) => {
    onChange(options[index].id);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const isSingleValue = options.length < 2 || !options.length;
  const icon = open ? <ExpandLessIcon /> : <ExpandMoreIcon />;
  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        {isSingleValue ? (
          <Typography className={classes.disabledLabelButton}>
            {`(${code}) ${value}`}
          </Typography>
        ) : (
          <React.Fragment>
              <Button
                className={open ? clsx(classes.labelActive, classes.labelButton) : classes.labelButton}
                ref={anchorRef}
                size="small"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                disabled={isSingleValue}
                onClick={handleToggle}
              >
                {`(${code}) ${value}`}
                {icon}
              </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              className={classes.paperContainer}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList>
                        {options.map((option, index) => (
                          <MenuItem
                            key={option.id}
                            value={option.id}
                            selected={option.name === value}
                            onClick={(event) =>
                              handleMenuItemClick(event, index)
                            }
                            className={classes.labels}
                          >
                            {option.name === value && <CheckIcon className={classes.checkedIcon} />}
                            <ListItemText primary={option.name} />
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
};

export default SingleSelector;
