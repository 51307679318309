import React, { useCallback, useRef } from "react";
import debounce from "debounce";

import { makeStyles } from "@material-ui/core/styles";
import {
  OutlinedInput,
  Input,
  Chip,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Checkbox,
  ListItemText,
  InputLabel,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import CancelIcon from "@material-ui/icons/Cancel";

import { StyledFormHelper } from "../../../components/loginSignup/customFormHelper";
import * as colors from "../../../theme/colors";
import { getUserName } from "../../../utils/generic";

const useStyles = makeStyles(() => ({
  checkBoxItem: {
    color: colors.greenA300,
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: colors.greenA300,
    },
    "&.Mui-focusVisible": {
      outline: `2px dotted ${colors.grey400}`,
      outlineOffset: -2
    },
    backgroundColor: "white !important",
  },
  errorLabel: {
    color: colors.redA400,
  },
  label: {
    zIndex: 2,
    padding: "0 2px",
  },
  adortmentBorder: {
    marginRight: 10,
  },
  chips: {
    display: 'flex',
    flexWrap: "wrap",
  },
  chipItem: {
    margin: 5,
  },
  subLabel: {
    fontSize: 14,
    marginLeft: 20,
  },
  inputWrapper: {
    display: "flex",
    outlineWidth: "0",
    "& .MuiInput-underline:before": {
      borderBottomColor: colors.lightThemeGreen,
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: colors.themeGreen,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: colors.lightThemeGreen,
    },
  },
  searchInput: {
    margin: "15px auto",
    width: "90%",
  },
}));

const TagChipSelector = ({
  className,
  field: { name, value },
  label,
  form: { errors, touched, setFieldValue, values },
  data,
  helperText,
  onValueChange,
  Icon,
  errorIsHtml,
  isRequired,
  shrink = true,
  placeholder,
  dropDownclassName,
  groupedSelect,
  enableSearch,
}) => {
  const classes = useStyles();
  const labelRef = useRef();
  const [width, setWidth] = React.useState(0);
  const [searchKey, setSearchKey] = React.useState(null);
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    if (!groupedSelect) {
      setOptions(data);
    } else {
      if (values.selectedProgram) {
        const program = data.find((e) => e.id === values.selectedProgram);
  
        if (program) {
          setOptions(
            program.members.map((e) => {
              return {
                id: e.userId,
                name: getUserName(e),
                isFacilitator: e.isFacilitator,
                isStudent: e.isStudent,
                isViewer: e.isViewer,
              };
            })
          );
        } else {
          setOptions([]);
        }
      }
    }
  }, [values, data]);

  const useHookWithRefCallback = () => {
    const setRef = useCallback(node => {
      labelRef.current = node
    }, []);

    return [setRef];
  }

  const [ref] = useHookWithRefCallback();

  React.useEffect(() => {
    if(!labelRef){
      return;
    }

    setWidth(labelRef.current && labelRef.current.clientWidth ? labelRef.current.clientWidth : 0);
  }, [labelRef]);

  if (!options) return null;
  let error = errors[name];
  let isTouched = touched[name];

  const handleDelete = (e, id) => {
    e.preventDefault();
    const newValue = value.filter(e => e !== id);
    setFieldValue(name, newValue);
  };

  const selectedAdd = value.length === options.length;

  const searchInputChangeDebounced = debounce((searchKey) => {
    searchKey = !!searchKey.trim ? searchKey.trim() : "";
    setSearchKey(searchKey);
  }, 500);

  const filteredOptions = options.filter(
    (e) => !searchKey || e.name.toLowerCase().includes(searchKey.toLowerCase())
  );

  const facilitators = filteredOptions.filter((e) => e.isFacilitator);
  const viewers = filteredOptions.filter((e) => e.isViewer);
  const students = filteredOptions.filter((e) => e.isStudent);

  return (
    <React.Fragment>
      <FormControl
        variant="outlined"
        fullWidth
        className={className}
        helperText={helperText}
        required={isRequired}
      >
        {label && (shrink || !value?.length) &&
          <InputLabel
            id={`label-${name}`}
            ref={shrink ? ref : null}
            shrink={shrink}
            htmlFor={name}
            className={classes.label}>
            {label}
          </InputLabel>}
        <Select
          SelectDisplayProps={{role: 'listbox', "aria-label": `${isRequired ? `${helperText} required`: helperText}`}}
          labelId={`label-${name}`}
          aria-label={label}
          id={name}
          name={name}
          multiple
          label={helperText}
          error={!!error && isTouched}
          input={
            <OutlinedInput
              labelWidth={width}
            />
          }
          MenuProps={{
            classes: { paper: dropDownclassName ? dropDownclassName : '' },
            getContentAnchorEl: () => null,
          }}
          helperText={
            error && isTouched ? (errorIsHtml ? "" : error) : helperText
          }
          value={value}
          renderValue={(selected) => {
            if (!selected || !selected.length) {
              return placeholder;
            }

            return (
              <div className={classes.chips}>
                {selected.map((value) => {
                  const item = options.find(e => e.id === value);
                  if (!item) {
                    return;
                  }
                  return (
                    <Chip
                      key={item.id}
                      label={item.name}
                      className={classes.chipItem}
                      clickable
                      deleteIcon={
                        <CancelIcon
                          onMouseDown={(event) => event.stopPropagation()}
                        />
                      }
                      onDelete={(e) => handleDelete(e, value)}
                    />
                  );
                })}
              </div>
            );
          }}
          InputProps={{
            placeholder: label,
            startAdornment: Icon ? (
              <InputAdornment position="start">
                <Icon />
              </InputAdornment>
            ) : null,
          }}
          startAdornment={
            Icon ? (
              <div className={classes.adortmentBorder}>
                {" "}
                <Icon />
              </div>
            ) : null
          }
          displayEmpty
          onChange={(event) => {
            if (event.target.value.includes(-1)) {
              const items = selectedAdd ? [] : options.map((e) => e.id);
              setFieldValue(name, items);
              if (onValueChange) {
                onValueChange(items, setFieldValue, values, touched);
              }
              return;
            }
            const items = event.target.value;
            if (onValueChange) {
              onValueChange(event.target.value, setFieldValue, values, touched);
            }
            setFieldValue(name, items);
          }}
        >
          {enableSearch && <MenuItem
            className={classes.inputWrapper}
            style={{ backgroundColor: "transparent" }}
          >
            <>
              <Input
                key={"Search"}
                autoFocus={true}
                label={"Search"}
                placeholder={"Search"}
                className={classes.searchInput}
                style={{ backgroundColor: "transparent" }}
                onClickCapture={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                onChange={(event) => {
                  searchInputChangeDebounced(event.target.value);
                }}
              />
            </>
          </MenuItem>}

          {!groupedSelect && options.length && options.map(({ id, name }) => (
            <MenuItem className={classes.checkBoxItem} key={id} value={id}>
              <Checkbox checked={value.includes(id)} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}

          {!!facilitators.length && (
            <div className={classes.subLabel}>Facilitators / Teachers</div>
          )}
          {facilitators.map(({ id, name }) => (
            <MenuItem className={classes.checkBoxItem} key={id} value={id}>
              <Checkbox checked={value.includes(id)} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}

          {!!students.length && (
            <div className={classes.subLabel}>Students</div>
          )}
          {students.map(({ id, name }) => (
            <MenuItem className={classes.checkBoxItem} key={id} value={id}>
              <Checkbox checked={value.includes(id)} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}

          {!!viewers.length && <div className={classes.subLabel}>Viewers</div>}
          {viewers.map(({ id, name }) => (
            <MenuItem className={classes.checkBoxItem} key={id} value={id}>
              <Checkbox checked={value.includes(id)} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        {error &&
          isTouched &&
          (errorIsHtml ? (
            <StyledFormHelper helperText={error} isError={true} isHtml={true} />
          ) : (
            <FormHelperText className={classes.errorLabel}>
              {error}
            </FormHelperText>
          ))}
      </FormControl>
    </React.Fragment>
  );
};

export default TagChipSelector;
