import client from "../../../apolloClient";
import { gql } from "apollo-boost";
import obj2arg from "graphql-obj2arg";

export const sendPartnerInvitation = ({
  userId,
  invitedFirstName,
  invitedLastName,
  email,
  communityPartnershipId,
  additionalMessage
}) => {
  const graphQlArguments = {
    userId,
    invitedFirstName,
    invitedLastName,
    email,
    communityPartnershipId,
    additionalMessage
  };

  return client.mutate({
    mutation: gql`mutation {
      sendPartnerInvitation(invitation: {${obj2arg(graphQlArguments, {
        noOuterBraces: true
      })}})
    }`
  });
};

export const removeInvitation = (id) => {
  return client.mutate({
    mutation: gql`mutation {
      removeUserNextInvitation(${obj2arg({ id }, {
        noOuterBraces: true
      })})
    }`
  });
};
