import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";

import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import { connectTo } from "../../utils/generic";
import * as actions from "../../actions/changePassword";

import Link from "../../components/Link";

import { PasswordInput } from "../../components/PasswordInput";
import CustomButton from "../../components/customButton/customButton";
import * as colors from "../../theme/colors";
import Footer from "../../containers/Footer/footer";

import * as userProfileActions from "../../actions/userProfile";

import { validatePasswordRules, filterFieldsByKey, filterFieldsWithKey } from '../../utils/generic';

const useStyles = makeStyles(() => ({
  alert: {
    marginTop: 28,
  },
  gradientBox: {
    height: "100%",
  },
  container: {
    width: "98%",
    zIndex: 1,
  },
  content: {
    //backgroundImage: `url(${PatternImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0 -85px",
    backgroundSize: "100% 508px",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 123px)",
  },
  card: {
    maxWidth: 445,
    width: 445,
    marginTop: 15,
  },
  cardContent: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: "0px !important",
  },
  form: {
    width: "100%",
  },
  login: {
    margin: "58px auto 0px auto",
  },
  title: {
    margin: "55px auto 15px",
    fontSize: 22,
    fontWeight: "bold",
    maxWidth: 330,
    textAlign: "center",
  },
  subTitle: {
    fontSize: 16,
    width: "70%",
    textAlign: "center",
    marginTop: 5,
    lineHeight: 1.75,
  },
  invalidToken: {
    fontSize: 16,
    width: "70%",
    textAlign: "center",
    marginTop: 5,
    marginBottom: 55,
    lineHeight: 1.75,
  },
  cantFindIt: {
    fontSize: 16,
    width: "70%",
    textAlign: "center",
    marginTop: 60,
    lineHeight: 1.75,
  },
  createAccountBox: {
    marginTop: 32,
  },
  smallText: {
    color: "#565656",
    fontSize: 12,
  },
  submitContainer: {
    marginTop: 56,
    marginBottom: 16,
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
  },
  blockSpacer: {
    color: "#20a8ed",
    margin: "0 11px",
  },
  goBack: {
    marginTop: 30,
    textDecoration: "none",
    fontSize: 14,
    fontWeight: "bold",
  },
  resetLink: {
    fontSize: 16,
    textDecoration: "underline",
  },
  submitButton: {
    height: 42,
    width: "95%",
  },
  bottomLinksBox: {
    backgroundColor: colors.darkGreenBackground,
    bottom: 0,
    left: 0,
    width: "100%",
    display: "inline-flex",
    justifyContent: "space-between",
    height: 58,
    alignItems: "center",
  },
  bottomLink: {
    color: colors.white,
    marginLeft: 10,
    fontSize: 12,
    "&:hover": {
      color: colors.lightThemeGreen,
      textDecoration: "none",
    },
  },
  gwc: {
    marginLeft: 20,
    color: colors.white,
  },
  buttonContainer: {
    margin: "auto",
  },
  builtBy: {
    color: colors.white,
    fontSize: 12,
    marginLeft: 25,
    marginRight: 20,
    marginTop: 20,
    paddingBottom: 20,
  },
  bottomLinksContainer: {
    display: "inline-flex",
    alignItems: 'center',
  }
}));

const validationSchema = Yup.object({
  password: Yup.string()
    .required("Required"),
  confirmPassword: Yup.mixed()
    .required("Required")
    .test("match", "Passwords do not match", function (value) {
      const { password } = this.parent;
      return value === password;
    }),
});

const ChangePassword = (props) => {
  const classes = useStyles();

  const {
    history: { push },
    match: {
      params: { token },
    },
    changePassword,
    error,
    loading,
    tokenIsValid,
    tokenVerified,
    loadProfileContent,
    userProfile: { profileContent },
  } = props;

  const [userInfo, setUserInfo] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [passwordContent, setPasswordContent] = React.useState({});

  React.useEffect(() => {
    axios({
      method: "GET",
      url: `${window._env_.REACT_APP_API_URL}/auth/verifyResetToken?token=${token}`
    })
      .then(({ data }) => {
        setUserInfo(data);
        loadProfileContent();
        
        tokenVerified(true);
      })
      .catch(() => {
        push("/forgot-password", { showNote: true });
        tokenVerified(false);
      });
  }, []);

  React.useEffect(() => {
    if (!profileContent || !profileContent.changePasswordForm) {
      return;
    }
    
    const resetFields = filterFieldsWithKey(profileContent?.changePasswordForm.resetStepFields, "id");
    const newPasswordContent = resetFields.newPassword;
    setPasswordContent(newPasswordContent);
    const newErrors = filterFieldsByKey(newPasswordContent.validationErrors, "messageCode", "message");
    setErrors(newErrors);

  }, [profileContent]);

  const validateNewPassword = value => {
    const validateResult = validatePasswordRules(value, userInfo.email, userInfo.username, userInfo.firstName);
    if (Object.keys(validateResult).length > 0) {
      return errors.weakPassword;
    }
  }
  
  return (
    <Box className={classes.gradientBox}>
      <Box className={classes.content}>
        <Card className={classes.card}>
          {!tokenIsValid && (
            <CardContent className={classes.cardContent}>
              <Typography
                variant="h4"
                className={classes.title}
                color="primary"
                gutterBottom
              >
                Invalid Recovery Link
              </Typography>
              <Typography
                variant="h6"
                color="primary"
                className={classes.invalidToken}
              >
                This link is no longer valid for resetting your password. Please
                fill the &nbsp;
                <Link
                  className={classes.resetLink}
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    push("/forgot-password");
                  }}
                >
                  Recovery Password Form
                </Link>{" "}
                again to receive a new one via email.
              </Typography>
            </CardContent>
          )}
          {tokenIsValid && (
            <CardContent className={classes.cardContent}>
              <Typography
                variant="h4"
                className={classes.title}
                color="primary"
                gutterBottom
              >
                Please change your password
              </Typography>
              <Typography
                variant="h6"
                color="primary"
                className={classes.subTitle}
              >
                Let's try to look up your account.
              </Typography>
              <Formik
                initialValues={{
                  password: "",
                  confirmPassword: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  changePassword({
                    password: values.password,
                    token,
                  });
                }}
              >
                <Form className={classes.form}>
                  <Container className={classes.container}>
                    {!!error && (
                      <Alert
                        className={classes.alert}
                        severity="error"
                        variant="outlined"
                      >
                        {error}
                      </Alert>
                    )}
                    <Box className={classes.login}>
                      <Field
                        name="password"
                        component={PasswordInput}
                        label="New password"
                        className={classes.marginTop30}
                        helperText="Please remember to store your password in a safe place."
                        content={passwordContent}
                        username={userInfo.username}
                        firstName={userInfo.firstName}
                        email={userInfo.email}
                        fromModal={true}
                        validate={validateNewPassword}
                      />
                    </Box>
                    <Box className={classes.login}>
                      <Field
                        name="confirmPassword"
                        component={PasswordInput}
                        label="Retype password"
                        className={classes.marginTop30}
                      />
                    </Box>
                  </Container>
                  <Box className={classes.submitContainer}>
                    <CustomButton
                      disabled={loading}
                      type="submit"
                      className={classes.submitButton}
                      mode={"primary"}
                      label={"SUBMIT"}
                    />
                  </Box>
                </Form>
              </Formik>
            </CardContent>
          )}
        </Card>
      </Box>
      <Footer />
    </Box>
  );
};

export default connectTo(
  (state) => ({
    userProfile: state.userProfile,
    ...state.changePassword,
  }),
  { 
    ...actions,
    ...userProfileActions,
  },
  ChangePassword
);
