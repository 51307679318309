import { createReducer } from "redux-act";

import * as a from "../actions/userProfile";

const getDefaultState = () => ({
  isLoading: false,
  isLoadingProjects: false,
  isEditable: false,
  program: {},
  inactivePrograms: [],
  parents: [],
  statesProvinces: [],
  profileContent: {},
  userProfileContent: {},
  passwordChangedResult: {},
  userProjects: []
});

export default () =>
  createReducer(
    {
      [a.loadUserProfile]: state => ({
        ...state,
        isLoading: true,
      }),
      [a.getClubInfo]: state => ({
        ...state,
      }),
      [a.receivedClubInfo]: (state, program) => ({
        ...state,
        program: { ...program }
      }),
      [a.receivedUserProfile]: (state, content) => ({
        ...state,
        userProfileContent: content,
        isLoading: false
      }),
      [a.recievedStateProvinces]: (state, statesProvinces) => ({
        ...state,
        statesProvinces: statesProvinces,
      }),
      [a.profileContentLoaded]: (state, content) => ({
        ...state,
        profileContent: content
      }),
      [a.passwordChangeCompleted]: (state, result) => ({
        ...state,
        passwordChangedResult: result
      }),
      [a.smsConsentSaved]: (state, isSmsConsent) => ({
        ...state,
        smsConsent: isSmsConsent
      }),
      [a.loadUserProjects]: state => ({
        ...state,
        isLoadingProjects: true,
      }),
      [a.receivedUserProjects]: (state, projects) => ({
        ...state,
        isLoadingProjects: false,
        userProjects: projects
      }),
      [a.receivedAcceptedPrivacyPolicy]: (state, result) => ({
        ...state,
        acceptedPolicyAt: result
      }), 
    },
    getDefaultState()
  );
