import React from "react";
import { push as pushToRoute } from "connected-react-router";

import { connectTo } from "../utils/generic";
import * as tokenStorage from '../tokenStorage';

import * as authActions from "../actions/auth";
import * as contentfulActions from "../actions/contentful";
import * as globalActions from "../actions/global";
import * as communityPartnershipActions from "../actions/communityPartnerships";
import * as trackingActions from '../actions/tracking';
import * as userProfileActions from "../actions/userProfile";

import Notifications from "./Notifications/Notifications";
import Loader from "../components/MainLoader";
import { ChangePasswordModal } from "./PasswordChangeModal/ChangePasswordModal";

import userTypes from "../constants/userTypeConstants";


const AppLoader = props => {
  const {
    children,
    incrementInitialLoaders,
    global: { initialLoadersCount, studentView },
    auth: { currentRole, user },
    contentful: { hqConfig, GlobalComponentQuery },
    loadCurrentUser,
    loadGlobalContent,
    push,
    roleChanged,
    userProfile: { passwordChangedResult, profileContent },
    loadProfileContent,
    setUserPassword,
    updateAcceptedPrivacyPolicy
  } = props;

  const [forceChangePassword, setForceChangePassword] = React.useState(false);

  React.useEffect(() => {
    incrementInitialLoaders();
    const token = tokenStorage.getToken();

    setTimeout(() => {
      // loading global content from contentful
      loadGlobalContent();

      const invite = window.location.pathname.includes("invitation");
      const facilitatorInvite = window.location.pathname.includes("facilitatorInvitation");

      if (invite && !token) {
        const tokenId = window.location.pathname
          .substring(1, window.location.pathname.length)
          .split("/")[1];

        push(`/login?invitationToken=${tokenId}`);
      }

      if (facilitatorInvite && !token) {
        const tokenId = window.location.pathname
          .substring(1, window.location.pathname.length)
          .split("/")[1];

        push(`/login?facilitatorInvitationToken=${tokenId}`);
      }

      if (!token) return;

      // loading current user
      incrementInitialLoaders();
      loadCurrentUser();
    });
  }, []);

  React.useEffect(() => {
    if (!user) return;
    if (!hqConfig) return;

    if (currentRole) {
      roleChanged({ roleId: currentRole.roleId, redirectTo: user.redirect });
    }

    const isAnonymousForcePassword = user.userType === userTypes.Anonymous && user.createdAt === user.updatedAt;

    if (isAnonymousForcePassword || user.passwordNeedUpdate) {
      loadProfileContent();
      setForceChangePassword(true);
    }
  }, [user, hqConfig, studentView]);

  const updateUserPassword = (data) => {
    setUserPassword({
      newPassword: data.confirmPassword,
    });
  };

  const handleClose = (event, reason) => {
    if ((reason && reason == "backdropClick") || (reason && reason == "escapeKeyDown")) {
      return;
    }
    setForceChangePassword(false);
  };

  const forceChangePasswordModal = (isOpen) => {
    return (
      <ChangePasswordModal
        isOpen={isOpen}
        onClose={handleClose}
        onSave={updateUserPassword}
        passwordChanged={passwordChangedResult}
        changePasswordContent={profileContent.changePasswordForm}
        user={user}
        showPasswordChangeResult={true}
      />
    );
  };

  return (
    initialLoadersCount > 0 ? <Loader /> : (
      <React.Fragment>
        {children}
        <Notifications
          user={user}
          hqConfig={hqConfig}
          GlobalComponentQuery={GlobalComponentQuery}
          updateAcceptedPrivacyPolicy={updateAcceptedPrivacyPolicy}
        />
        {profileContent?.changePasswordForm && forceChangePassword &&
          forceChangePasswordModal(forceChangePassword)
        }
      </React.Fragment>)
  );
};

export default connectTo(
  state => ({
    auth: state.auth,
    global: state.global,
    contentful: state.contentful,
    userProfile: state.userProfile,
    ...state.communityPartnerships
  }),
  {
    ...authActions,
    ...contentfulActions,
    ...globalActions,
    ...communityPartnershipActions,
    ...trackingActions,
    ...userProfileActions,
    push: pushToRoute,
  },
  AppLoader,
);
