import React from "react";

import {
  Box,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";

import linkifyHtml from "linkify-html";

import { createMarkup } from "../../../../utils/generic";
import * as colors from "../../../../theme/colors";
import AnswerStatusDetails from "../../../../components/AnswerStatusDetails";

const useStyles = makeStyles((theme) => ({
  boxWrapper: {
    width: '100%',
    "&:not(:last-child)": {
      marginBottom: 50,
    },
    [theme.breakpoints.down('sm')]: {
      width: "90%",
    },
  },
  cardContainer: {
    width: '100%',
    marginTop: 20,
  },
  answerBox: {
    border: "1px solid " + colors.grey500,
    color: colors.darkThemeBlueGray,
    minHeight: 100,
    padding: 20,
    "& a": {
      color: colors.darkThemeGreen,
      fontSize: 14,
    }
  },
  answerCreatedAt: {
    color: colors.lightGrey,
    fontSize: 12,
  },
  noResponseBox: {
    color: colors.darkThemeBlueGray,
    backgroundColor: colors.grey300,
    padding: 20,
  },
  noResponseWrapper: {
    minHeight: 100,
  }
}));

const AnswerGroupCard = ({ answer, showDate }) => {
  const classes = useStyles();

  return (
    <Box className={classes.boxWrapper}>
      {answer.content && <AnswerStatusDetails
          showDate={showDate}
          createdAt={answer.createdAt}
          status={answer.status}
          statusChangedAt={answer.statusChangedAt}
          statusChanger={answer.statusChanger}
      />}
      <Box className={classes.cardContainer}>
        {answer.content
          ? <Box className={classes.answerBox} dangerouslySetInnerHTML={createMarkup(linkifyHtml(answer.content, { target: "_blank" }))} />
          : <Box className={classes.noResponseWrapper}>
              <Box className={classes.noResponseBox}>No Response</Box>
            </Box>
        }
      </Box>
    </Box>
  );
}

export default AnswerGroupCard;