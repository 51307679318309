import React from "react";

import {
  Box,
  CircularProgress,
  Typography,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import * as colors from "../../theme/colors";

const useStyles = makeStyles(() => ({
  pageTitleBox: {
    display: 'flex'
  },
  blockTitle: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: 1.375,
    textTransform: "uppercase",
    color: colors.dartThemeBlueGray
  },
  loader: {
    marginLeft: 15
  }
}));


export const PageTitle = (props) => {
  const classes = useStyles();
  const { title, isLoading } = props;
  return (
    <Box className={classes.pageTitleBox}>
      <Typography
        variant="h1"
        component="h1"
        className={classes.blockTitle}
      >
        {title}
      </Typography>
      {isLoading && <CircularProgress className={classes.loader} />}
    </Box>
  );
}

export default PageTitle;
