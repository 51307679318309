import React, { useState } from "react";

import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CustomButton from "../../components/customButton/customButton";
import PageTitle from "../../components/page-title/pageTitle";

import { CSVLink } from "react-csv";
import Tooltip from "@material-ui/core/Tooltip";
import GetAppIcon from "@material-ui/icons/GetApp";
import Tabs from "../MembersGroups/Tabs/";

import { ColorTheme } from '../../utils/colorTheme';
import Roles from "../../utils/permissions";
import Individuals from "./Individuals";
import Groups from "./Groups";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: 15
  },
  csvContainer: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  csvBtn: {
    height: 45
  },
  subheaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 45
  },
  list: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  membersGrid: {
    marginTop: 20,
  },
  enrolledCounter: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  enrolledCounterSmall: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  noUsersInfo: {
    margin: '20px 0',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: '40px 20px',
  },
  searchBig: {
    color: ColorTheme.blue4.b,
    fontSize: 90,
  },
  noUsersInfoMessage: {
    color: ColorTheme.blue4.b,
    marginBottom: 10
  },
  divider: {
    margin: '30px 0',
    width: '70%'
  },
  title: {
    display: "flex",
  },
  inviteBtn: {
    marginLeft: 10,
    minWidth: 45,
    height: 45,
  },
  addMembersBtn: {
    height: 42
  },
  link: {
    color: ColorTheme.teal3.b,
    fontSize: 14,
    fontWeight: 700,
    textDecoration: "none",
    paddingLeft: 5,
    "&:hover": {
      textDecoration: "none",
    }
  },

  tabsSection: {
    margin: '-10px auto 15px -18px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: -11,
    },
  }
}));

const csvHeaders = [
  { label: "Name", key: "name" },
  { label: "Username or Email", key: "userNameOrEmail" },
  { label: "Last Login", key: "lastLogin" },
  { label: "Created At", key: "createdAt" },
  { label: "Phone Number", key: "phoneNumber" },
  { label: "Mailing Address", key: "mailingAddress" },
  { label: "Personal Email", key: "personalEmail" },
];

const title = (firstName, lastName) =>
  `${firstName}${firstName ? " " : ""}${lastName}`;

const formatDate = (date) => {
  return moment(date).local().format("MM/DD/YYYY");
}

const Members = ({
  members,
  isLoading,
  removeUser,
  onChangePassword,
  rolePermissions,
  onInviteMembersClick,
  recruitmentResourceLink,
  profileContent,
  user,
  createUsersGroups,
  updateUsersGroup,
  deleteUsersGroup,
  isCreatingMembersGroup,
  getUsersGroups,
  programId,
  usersGroups,
  membersLabels,
  loadingMembersGroup,
  programTypeDetails,
}) => {
  const [currentTab, setCurrentTab] = useState('Individuals');

  const classes = useStyles();

  const participants = members.filter((r) => r.roleId === Roles.Student && !r.user.deletedAt);
  const hasParticipants = participants.length !== 0;

  let csvRows = participants.map((e) => {
    if (!e.user) {
      return { name: '', userNameOrEmail: '', lastLogin: '' };
    }

    let addressData = [e.user.streetAddress, e.user.city, e.user.state, e.user.zipcode].filter(el => !!el);

    const disableContactSharing = e.user.disableContactSharing;

    const memberInfo = {
      name: title(e.user && e.user.firstName ? e.user.firstName : '', e.user && e.user.lastName ? e.user.lastName : ''),
      userNameOrEmail: '',
      lastLogin: '',
      createdAt: '',
      phoneNumber: '',
      mailingAddress: '',
      personalEmail: '',
    };
    if (disableContactSharing) {
     return memberInfo;
    }
    return {
      ...memberInfo,
      userNameOrEmail: e.user.username || e.user.email,
      lastLogin: e.user.lastLogin ? formatDate(e.user.lastLogin) : '',
      createdAt: formatDate(e.user.createdAt),
      phoneNumber: e.user.phoneNumber,
      mailingAddress: addressData.join(', '),
      personalEmail: e.user.personalEmail || '',
    }
  });

  const enrolledCountMessage = `${participants.length} member${participants.length === 1 ? '' : 's'} enrolled`;
  const currentMembersGroups = {};
  let groupNameOfMaxGroup = '';
  const groups = Object.entries(usersGroups || {}).sort(([, valueA], [, valueB]) => {
    const { updatedAt: updatedAtA, createdAt: createdAtA } = valueA;
    const { updatedAt: updatedAtB, createdAt: createdAtB } = valueB;
  
    // compare by updatedAt if exists, otherwise by createdAt
    const dateToCompareA = updatedAtA || createdAtA;
    const dateToCompareB = updatedAtB || createdAtB;
    return moment(dateToCompareB).diff(moment(dateToCompareA))
  });

  groups.forEach(([groupName]) => {
    currentMembersGroups[groupName] = members?.filter((item) => usersGroups[groupName].users.includes(item.userId));
  });

  const currentMembersGroupsList = Object.keys(currentMembersGroups);
  return (
    <Container>
      <Box className={classes.header}>
        <Box>
          <Box className={classes.title}>
            <PageTitle title="Members" isLoading={isLoading || isCreatingMembersGroup || loadingMembersGroup} />
          </Box>
        </Box>

        <Box className={classes.subheaderContainer}>
          {!isLoading && hasParticipants && (
            <Typography
              variant="body2"
              component="p"
              className={classes.enrolledCounter}
              sel="members-enrolled"
            >
              {enrolledCountMessage}
            </Typography>
          )}
          {hasParticipants && csvRows && csvRows.length > 0 && <Tooltip
            title="Download table as CSV"
            className={classes.csvContainer}
          >
            <CSVLink
              data={csvRows}
              filename={`Members.csv`}
              headers={csvHeaders}
            >
              <CustomButton mode="secondary" className={classes.csvBtn}>
                <GetAppIcon />
              </CustomButton>
            </CSVLink>
          </Tooltip>}
        </Box>
      </Box>
      <div className={classes.tabsSection}>
        <Tabs
          value={currentTab}
          showGroups={!(!!!participants.length && !!currentMembersGroupsList.length)}
          onChange={(evt, newValue) => {
            setCurrentTab(newValue);
          }}
        />
      </div>
      {currentTab === 'Individuals' &&
        <Individuals
          members={members}
          isLoading={isLoading}
          removeUser={removeUser}
          onChangePassword={onChangePassword}
          rolePermissions={rolePermissions}
          onInviteMembersClick={onInviteMembersClick}
          recruitmentResourceLink={recruitmentResourceLink}
          profileContent={profileContent}
          programTypeDetails={programTypeDetails}
        />}
      {currentTab === 'Groups' &&
        <Groups
          members={members}
          user={user}
          createUsersGroups={createUsersGroups}
          updateUsersGroup={updateUsersGroup}
          deleteUsersGroup={deleteUsersGroup}
          isCreatingMembersGroup={isCreatingMembersGroup}
          getUsersGroups={getUsersGroups}
          usersGroups={usersGroups}
          programId={programId}
          currentMembersGroups={currentMembersGroups}
          membersLabels={membersLabels}
          isLoading={isLoading}
          groupNameOfMaxGroup={groupNameOfMaxGroup}
          loadingMembersGroup={loadingMembersGroup}
        />}
    </Container>
  );
};

export default Members;
