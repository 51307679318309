import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Field } from "formik";
import clsx from 'clsx';

import Selector from "../../../components/selector/Selector";
import Typography from "@material-ui/core/Typography";
import CheckboxGroup from "../../../components/checkboxGroup/checkBoxGroup";
import * as colors from "../../../theme/colors";
import TextCustomButton from "../../../components/text-button/text-button";

import { enrollmentCreatingChildTrackingEvent } from "../../../containers/JoinProgram/constants";
import { PROGRAM_TYPES } from '../../../utils/constants';

const useStyles = makeStyles(() => ({
  childFormWrapper: {
    margin: 20,
  },
  subTitle: {
    margin: "5px 0 20px 0",
    fontSize: 14,
    fontWeight: 700,
    textAlign: "center",
  },
  answerLabel: {
    fontSize: 14,
    lineHeight: "1.5",
    fontWeight: 400,
    color: colors.darkThemeBlueGray,
  },
  item: {
    width: "100%",
  },
  raceFieldBox: {
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 10,
    '& .MuiIconButton-label': {
      color: colors.darkThemeGreen,
    },
    "& .Mui-checked": {
      color: colors.darkThemeGreen,
    },
    "& .MuiTypography-root": {
      "& .MuiBox-root": {
        fontSize: 14,
        fontWeight: 400,
        textAlign: "left",
      },
    },
    "& .MuiFormControl-root": {
      "& .MuiFormLabel-root": {
        color: colors.darkThemeBlueGray,
        fontSize: 14,
        fontWeight: 400,
      },
      "& .MuiFormGroup-root": {
        "& .MuiFormControlLabel-root": {
          "& .MuiTypography-body1": {
            fontSize: 14,
            lineHeight: "1.5",
            fontWeight: 400,
            color: colors.darkThemeBlueGray,
          }
        }
      }
    }
  },
  fieldBox: {
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 10,
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      fontSize: 14,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      backgroundColor: colors.white,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.4,
      color: colors.darkThemeBlueGray,
    },
    "& .MuiFormControl-root": {
      "& .MuiFormControlLabel-root": {
        "& .MuiFormControlLabel-label": {
          fontSize: 14,
          fontWeight: 400,
          lineHeight: 1.4,
          color: colors.darkBlue100,
        }
      },
    },
    '& .MuiButtonBase-root': {
      color: colors.darkThemeGreen,
    },
  },
  lastStepTitle: {
    fontSize: 14,
    textAlign: "center",
  },
  additionalButton: {
    color: colors.darkThemeGreen,
    fontSize: 12,
    cursor: "pointer",
    marginButton: 30,
  },
  participatedInGirlsWhoCodeProgram: {
    border: "none",
    "& .MuiButtonBase-root": {
      color: colors.grey350,
    },
    "& .Mui-checked": {
      color: colors.darkThemeGreen,
    },
    "& .MuiFormControl-root": {
      "& .MuiFormControlLabel-root": {
        "& .MuiFormControlLabel-label": {
          fontSize: 14,
          fontWeight: 400,
          lineHeight: 1.4,
          color: colors.darkBlue100,
        }
      },
    },
    "& .MuiFormLabel-root": {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.4,
      color: colors.darkBlue100,
    },
    "& div": {
      border: 0,
    }
  },
  previousEmail: {
    justifyContent: "flex-end",
    display: "inline-flex",
    width: "100%",
  },
  clubsField: {
    marginTop: 20,
  }
}));

export const Enroll = ({
  type,
  enrollmentForm,
  enrollmentData,
  createTrackingLog,
  footer,
  joinClub,
}) => {
  const classes = useStyles();

  const {
    collegeMajors,
  } = joinClub
  const {
    additionalAsianEthnicityField,
    additionalMultiRacialEthnicityField,
    collegeGraduationYearField,
    ethnicityField,
  } = enrollmentForm;

  const asianItems = additionalAsianEthnicityField
    ? additionalAsianEthnicityField.options.map((e) => {
      return { label: e.label, value: e.value };
    })
    : [];

  const etnicities = ethnicityField ? ethnicityField.options : [];
  const multiRaceOptions = additionalMultiRacialEthnicityField
    ? additionalMultiRacialEthnicityField.options.map((e) => {
      return { label: e.label, value: e.value };
    })
    : [];
  const [showAsianEtnicity, setShowAsianEtnicity] = useState(null);
  const [showMultiEnticity, setShowMultiEnticity] = useState(null);
  const [showMinor, setShowMinor] = useState(false);
  const [showAdditionalMinor, setShowAdditionalMinor] = useState(false);
  const [showAdditionalMajor, setShowAdditionalMajor] = useState(false);

  const {
    raceEthnicity,
    raceEthnicityMultiracial,
    raceEthnicityAsian,
  } = enrollmentData;

  if (raceEthnicity) {
    // show items by default;
    if (raceEthnicity === "Multiracial" && showMultiEnticity === null) {
      setShowMultiEnticity(raceEthnicity === "Multiracial");
    }

    if (raceEthnicity === "Asian" && showAsianEtnicity === null) {
      setShowAsianEtnicity(raceEthnicity === "Asian");
    }
  }

  useEffect(() => {
    return () => {
      createTrackingLog({
        event: enrollmentCreatingChildTrackingEvent.CHILD_ENROLLMENT_STEP_3_LEAVE,
        data: JSON.stringify(enrollmentData),
      });
    };
  }, []);

  return (
    <>
      <Box className={classes.childFormWrapper}>
        <Typography className={classes.lastStepTitle}>
          Last step! Help us learn more about your child.
        </Typography>
        {type === PROGRAM_TYPES.COLLEGE_LOOP &&
          <>
            <Typography className={classes.subTitle}>
              We are changing the face of tech! Help us track our progress.
            </Typography>
            <Field
              name="raceEthnicity"
              component={Selector}
              className={classes.fieldBox}
              label="What is your child's race or ethnicity?"
              options={etnicities}
              onValueChange={(value) => {
                setShowMultiEnticity(value === "Multiracial");
                setShowAsianEtnicity(value === "Asian");
              }}
              helperText="What is your child's race or ethnicity?"
              required
              showHelperText={false}
            />
            {showAsianEtnicity && (
              <Box className={classes.raceFieldBox}>
                <Field
                  name="raceEthnicityAsian"
                  component={CheckboxGroup}
                  title="Please select one or more:"
                  className={classes.item}
                  defaultItems={raceEthnicityAsian}
                  items={asianItems}
                />
              </Box>
            )}
            {showMultiEnticity && (
              <Box className={classes.fieldBox}>
                <Field
                  name="raceEthnicityMultiracial"
                  component={CheckboxGroup}
                  title="Please select one or more:"
                  className={classes.item}
                  defaultItems={raceEthnicityMultiracial}
                  items={multiRaceOptions}
                />
              </Box>
            )}
            <Box className={classes.fieldBox}>
              <Field
                name="collegeMajorId"
                component={Selector}
                label={enrollmentForm.collegeMajorField?.labelPrompt}
                options={collegeMajors || []}
                required
              />
            </Box>
            {showAdditionalMajor && (
              <>
                <Box className={classes.fieldBox}>
                  <Field
                    name="collegeAdditionalMajorId"
                    component={Selector}
                    label={enrollmentForm.collegeMajorField?.labelPrompt}
                    options={collegeMajors || []}
                  />
                </Box>
                <TextCustomButton
                  className={classes.additionalButton}
                  mode="primary"
                  onClick={() => {
                    setShowAdditionalMajor(false);
                  }}
                  label={enrollmentForm.removeMajorLabel}
                />
              </>
            )}
            {showMinor && (
              <>
                <Box className={classes.fieldBox}>
                  <Field
                    name="collegeMinorId"
                    component={Selector}
                    label={enrollmentForm.collegeMinorField?.labelPrompt}
                    options={collegeMajors || []}
                  />
                </Box>
                <TextCustomButton
                  className={classes.additionalButton}
                  mode="primary"
                  onClick={() => {
                    setShowMinor(false);
                  }}
                  label={enrollmentForm.removeMajorLabel}
                />
              </>
            )}
            {showAdditionalMinor && (
              <>
                <Box className={classes.fieldBox}>
                  <Field
                    name="collegeAdditionalMinorId"
                    component={Selector}
                    label={enrollmentForm.collegeAdditionalMinorField?.labelPrompt}
                    options={collegeMajors || []}
                  />
                </Box>
                <TextCustomButton
                  className={classes.additionalButton}
                  mode="primary"
                  onClick={() => {
                    setShowAdditionalMinor(false);
                  }}
                  label={enrollmentForm.removeMajorLabel}
                />
              </>
            )}
            <Box className={classes.previousEmail}>
              {(!showMinor || !showAdditionalMinor) && (
                <TextCustomButton
                  className={classes.additionalButton}
                  mode="primary"
                  onClick={() => {
                    if (!showMinor) {
                      setShowMinor(true);
                    } else {
                      setShowAdditionalMinor(true);
                    }
                    return;
                  }}
                  label={enrollmentForm.addAnotherMinorLabel}
                />
              )}
              {!showAdditionalMajor && (
                <TextCustomButton
                  className={classes.additionalButton}
                  mode="primary"
                  onClick={() => {
                    setShowAdditionalMajor(!showAdditionalMajor);
                  }}
                  label={
                    showAdditionalMajor
                      ? enrollmentForm.removeMajorLabel
                      : enrollmentForm.addAnotherMajorLabel
                  }
                />
              )}
            </Box>
            <Box className={classes.fieldBox}>
              <Field
                name="collegeGraduationYear"
                component={Selector}
                required
                className={classes.item}
                label={enrollmentForm.collegeGraduationYearField?.labelPrompt}
                options={
                  collegeGraduationYearField
                    ? collegeGraduationYearField.options
                    : []
                }
              />
            </Box>
          </>}
        {type === PROGRAM_TYPES.CLUB &&
          <Box className={clsx(classes.fieldBox, classes.clubsField)}>
            <Field
              name="graduationYear"
              component={Selector}
              className={classes.fieldBox}
              required
              label="What grade is your child in?"
              options={
                enrollmentForm.gradeField
                  ? enrollmentForm.gradeField.options
                  : []
              }
              errorIsHtml
            />
          </Box>}
      </Box>
      {footer}
    </>
  );
};

