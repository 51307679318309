import { createAction } from 'redux-act';

export const loadCalendarsData = createAction();
export const receivedCalendarData = createAction();

export const createEventData = createAction();
export const receivedCreatedEvent = createAction();

export const updateEventData = createAction();
export const receivedUpdateEventData = createAction();

export const deleteEvent = createAction();
export const receivedDeletedEvent = createAction();

export const getProgramsAndMembersInfo = createAction();
export const receivedProgramsAndMembersInfo = createAction();

export const getEventDetailsInfo = createAction();
export const receivedEventDetailsInfo = createAction();

export const onError = createAction();
