/*
MultipleSectionsSelect and filer infrastructure is based on options in groups, so it's impossible to make stand-alone flag.
That's why we need this remapping to connect flags to existed stand-alone API parameters
*/
export const applyFilterFlags = (filterFlags) => {

  if (!!filterFlags.flags) {
    filterFlags.flags.forEach(e => filterFlags[e] = true)
    delete filterFlags.flags;
  }

  return filterFlags;
}