import React from "react";

import * as momentTimezone from "moment-timezone";

import { makeStyles } from "@material-ui/core/styles";

import * as colors from "../../../theme/colors";

import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import DaySection from "./DaySection";

import TextButton from "../../../components/text-button/text-button";

import { days, monthNames } from "../../../utils/generic";
import { PROGRAM_TYPES } from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "8px 8px 12px 8px",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  media: {
    height: 0,
    paddingTop: "56.25%",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  regularLink: {
    "&:hover": {
      textDecoration: "none",
    },
    fontWeight: 600,
  },
  redLink: {
    color: colors.red300,
    "&:hover": {
      textDecoration: "none",
    },
    fontWeight: 600,
  },
  cardHeader: {
    padding: 0,

    [theme.breakpoints.down("xs")]: {
      wordBreak: "break-all",
    },
    "& .MuiCardHeader-action": {
      margin: 0,
      "align-self": "auto",
    },
  },
  cardContent: {
    padding: "0 0 0 4px",
    "&:last-child": {
      paddingBottom: 0,

      [theme.breakpoints.down("xs")]: {
        wordBreak: "break-all",
      },
    },
  },
  lastLogin: {
    marginTop: 8,
    fontFamily: "'Roboto Mono',monospace",
    "& b": {
      fontFamily: "'Roboto', sans-serif",
    },
  },
  item: {
    display: "flex",
    marginBottom: 20,
    "& .MuiTypography-body1": {
      width: "40%",
      margin: "auto 0",
    },
    "& .MuiTypography-body2": {
      width: "60%",
      margin: "auto 0",
    },
  },
  link: {
    color: colors.darkThemeGreen,
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "initial",
    lineHeight: 1.75,
    marginTop: 10,
    "&:hover": {
      textDecoration: "none",
    },
  },
  subTitle: {
    display: "flex",
  },
  startTitle: {
    fontWeight: "bold",
    marginRight: 5,
  },
  eventTitle: {
    fontWeight: "bold",
    marginTop: 10,
    marginBottom: 5,
  },
  boxWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 15,
  },
  viewCalendarBtn: {
    '&:hover': {
      color: colors.lightThemeGreen,
    },
  }
}));

const applyFilters = (events, clubId) => {
  let newEvents = [...events];
  const programIds = [clubId];

  newEvents = newEvents.filter(
    (e) => e?.programs?.filter((x) => programIds?.includes(x.programId)).length > 0 || !!e.prepopulatedByProgramType
  );
  return newEvents;
};

const getCurrDate = (dateObj) => {
  var month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  var date = ("0" + dateObj.getDate()).slice(-2);
  var year = dateObj.getFullYear();
  return year + "-" + month + "-" + date;
};

const getDate = (date) => {
  const newDate =  date.includes('T') ? date.split("T")[0] : date.split(" ")[0];
  return `${newDate}T00:00:00`;
}

const getDateString = (club, programTypeDetails) => {
  if (!club) return "";

  const isClubOrCL = [PROGRAM_TYPES.CLUB, PROGRAM_TYPES.COLLEGE_LOOP].includes(programTypeDetails?.programType);

  if ((!club.launchDate && isClubOrCL) || (!isClubOrCL && !club.startDate)) {
    return "";
  }

  let launchFomatted;
  if (club.launchDate) {
    launchFomatted = getDate(club.launchDate);
  }

  let date = club.launchDate && isClubOrCL
    ? new Date(launchFomatted)
    : new Date(getDate(club.startDate));

  return `${days[date.getDay()]}, ${
    monthNames[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()}`;
};

const programColors = [
  "#7A7A78",
  "#0D9C90",
  "#0D38D3",
  "#EA3850",
  "#FA7815",
  "#FDD946",
  "#D7F9F4",
  "#0169E1",
  "#F37C6F",
  "#F9B88C",
  "#A31746",
  "#0C6B68",
  "#003046",
];

const ScheduleCard = ({
  club,
  calendarData: { events },
  userTimeZone,
  push,
  labels,
  programs,
  programTypeDetails,
}) => {
  const classes = useStyles();
  const selectedDate = new Date();

  const currentDate = getCurrDate(selectedDate);

  const eventList = applyFilters(events, parseInt(club.id, 10)).map((e) => {
    // Convert start and end date to user timezone;
    const convertedStartDate = momentTimezone(e.startDate)
      .clone()
      .tz(userTimeZone);

    const convertedEndDate = momentTimezone(e.endDate).clone().tz(userTimeZone);
    const localStartDate = convertedStartDate.format("YYYY-MM-DD");
    const localStartTime = convertedStartDate.format("h:mm A");
    const localEndDate = convertedEndDate.format("YYYY-MM-DD");
    const localEndTime = convertedEndDate.format("h:mm A");
    return {
      convertedStartDate,
      localStartDate,
      localStartTime,
      localEndDate,
      localEndTime,
      ...e,
    };
  });

  const groups = eventList.reduce((groups, item) => {
    if (!groups[item.localStartDate]) {
      groups[item.localStartDate] = [];
    }
    groups[item.localStartDate].push(item);
    return groups;
  }, {});

  // Edit: to add it in the array format instead
  const groupArrays = Object.keys(groups)
    .filter((x) => x >= currentDate)
    .map((date) => {
      return {
        date,
        items: groups[date],
      };
    })
    .sort(function (a, b) {
      return new Date(a.date) - new Date(b.date);
    });
  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <div className={classes.subTitle}>
            {" "}
            <Typography className={classes.startTitle}>Start:</Typography>
            <Typography>{getDateString(club, programTypeDetails)}</Typography>
          </div>
        }
        title={<Typography className={classes.title}>Schedule</Typography>}
        className={classes.cardHeader}
      />
      <CardContent className={classes.cardContent}>
        <Typography className={classes.eventTitle}>Upcoming events</Typography>
        {groupArrays.length ? (
          <DaySection
            item={groupArrays[0]}
            programColors={programColors}
            labels={labels}
            programs={programs}
          />
        ) : (
          <DaySection
            isStubMode={true}
            selectedDate={selectedDate}
            labels={labels}
            programs={programs}
          />
        )}
        <Box className={classes.boxWrapper}>
          <TextButton
            className={classes.viewCalendarBtn}
            onClick={() => {
              push(`/calendar?programIds=${club.id}`);
            }}
          >
            View Program Calendar
          </TextButton>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ScheduleCard;
