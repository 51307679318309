import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import * as colors from "../../theme/colors";
import Link from "@material-ui/core/Link";

import { Typography } from "@material-ui/core";
import CustomButton from "../../components/customButton/customButton";

const useStyles = makeStyles((theme) => ({
  bottomBox: {
    bottom: 0,
    left: 0,
    width: "100%",
    display: "inline-flex",
    justifyContent: "space-between",
    backgroundColor: "rgb(12, 107, 104)",
    padding: "15px 0",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      margin: "10px 0 0 0",
    },
  },
  bottomLinksBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      marginLeft: 25,
    },
  },
  gwc: {
    color: colors.white,
    fontWeight: "bold",
    marginLeft: 25,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 10,
    },
  },
  bottomLink: {
    color: colors.white,
    marginRight: 10,
    fontSize: 12,
    "&:hover": {
      color: colors.lightThemeGreen,
      textDecoration: "none",
    },
  },
  builtBy: {
    margin: "0 25px 0 0",
    color: colors.white,
    fontSize: 12,
  },
  buildByContainer: {
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },
  linksContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "space-between",
      width: "90%",
    },
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Box className={classes.bottomBox}>
      <CustomButton
        className={classes.gwc}
        mode="secondary"
        label="© GIRLS WHO CODE"
        onClick={() => window.open("https://girlswhocode.com", "_blank")}
      />
      <Box className={classes.bottomLinksBox}>
        <div className={classes.linksContainer} sel="policies">
          <Link className={classes.bottomLink} href="/privacy" aria-label="Privacy Policy">
            <span aria-hidden="true">Privacy Policy</span>
          </Link>
          <Link className={classes.bottomLink} href="/terms" aria-label="Terms of Service">
            <span aria-hidden="true">Terms of Service</span>
          </Link>
          <Link className={classes.bottomLink} href="/cookie-policy"  aria-label="Cookies Policy">
            <span aria-hidden="true">Cookies Policy</span>
          </Link>
        </div>
        <div className={classes.buildByContainer}>
          <Typography className={classes.builtBy} aria-hidden="true">
            Built by Girls Who Code
          </Typography>
        </div>
      </Box>
    </Box>
  );
};

export default Footer;
