import React from 'react';
import objectPath from 'object-path';

import { makeStyles } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { StyledFormHelper } from '../../../components/loginSignup/customFormHelper';
import * as colors from '../../../theme/colors';

const useStyles = makeStyles({
  error: {
    color: colors.redA400
  },
});

const EmailInput = ({ field, form, label, helperText, ...props }) => {
  const classes = useStyles();
  const { errors, touched } = form;

  let error = errors[field.name];
  let isTouched = touched[field.name];

  if (field.name.indexOf('[') !== -1 || field.name.indexOf('.') !== -1) {
    const path = field.name.replace('[', '.').replace(']', '').split('.');
    error = objectPath.get(errors, path);
    isTouched = objectPath.get(touched, path);
  }

  return (
    <React.Fragment>
      <TextField
        {...props}
        name={field.name}
        error={!!error && isTouched}
        onChange={(event) => {
          form.setFieldValue(field.name, event.target.value);
        }}
        fullWidth
        label={<span aria-hidden="true">{label}</span>}
        variant="outlined"
        value={field.value}
        InputProps={{
          classes,
          placeholder: label,
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),
        }}
      />
      {error && isTouched
      ? (<StyledFormHelper helperText={error} isHtml={true} isError={true} />)
      : (<StyledFormHelper helperText={helperText} isHtml={true} />)}
    </React.Fragment>
  );
}

export default EmailInput;
