import client from "../../../apolloClient";
import { gql } from "apollo-boost";

import OrganizationMessaging from '../types/OrganizationMessaging';
import MembersPageMessaging from '../types/MembersPageMessaging';
import InviteMessaging from '../types/InviteMessaging';
import ProjectGalleryMessaging from '../types/ProjectGalleryMessaging';
import LoopMessaging from "../types/LoopMessaging";
import ChildrenMessaging from "../types/ChildrenMessaging";
import ProfileMessaging from "../types/ProfileMessaging";
import ClubsProfileMessaging from '../types/ClubsProfileMessaging';
import CreatingChild from '../types/CreatingChild';

export const getGlobalContent = (uid) => {
  return client.query({
    fetchPolicy: "no-cache",
    query: gql`
        {
          GlobalComponentQuery(uid: "${uid}") {
            uid
            name
            siteName
            collegeLoopRenewalNextFormUrl
            activityPageLabels {
              name
              estimatedTimeLabel
              easyOfFacilitationLabel
              createdWithLabel
              tagsLabel
              goalsLabel
              materialsLabel
              facilitatorNoteLabel
              doneLabel
              exitLabel
              showInfoCta {
                name
                text
                url
                alternateText
              }
              hideInfoCta {
                name
                text
                url
                alternateText
              }
              estimatedTimeIcon {
                name
                src
                alt
              }
              easyOfFacilitationIcon {
                name
                src
                alt
              }
              tagsIcon {
                name
                src
                alt
              }
              facilitatorNoteIcon {
                name
                src
                alt
              }
              onlyFacilitatorsCta {
                name
                text
                url
                alternateText
                icon {
                  alt
                  name
                  src
                }
              }
            }
            setPageLabels {
              name
                showInfoCta {
                  name
                  text
                  url
                  alternateText
                }
                hideInfoCta {
                  name
                  text
                  url
                  alternateText
                }
                introductionLabel
                goalsLabel
                filterNoResultsLabel
                filterNoResultsAdditionalLabel
                filterNoResultsCta {
                  name
                  text
                  url
                  alternateText
                  icon {
                    alt
                    name
                    src
                  }
                }
                certificateRibbonIcon {
                  name
                  src
                  alt
                }
                projectIcon {
                  name
                  src
                  alt
                }
            }
            header {
              name
              switchProfilesPasswordConfirmationLabel
              switchProfilesCancelLabel
              switchProfilesSendLabel
              switchProfilesUnusedChildHeading
              switchProfilesUnusedChildMessage
              switchProfilesUnusedChildConfirmationLabel
              highContrastThemeToggleAriaLabel
              navBarHelpLink
            }
            footer {
              name
              copyright
              additionalText
            }
            membersPageLabels {
              ${MembersPageMessaging}
            }
            messages {
              messageCode
              name
              messageType
              message
            }
            errorPageMessages {
              messageCode
              name
              messageType
              message
            }
            organizationManagementLabels {
              ${OrganizationMessaging}
            }
            inviteStudentsLabels {
              ${InviteMessaging}
            }
            projectGalleryLabels
            {
              ${ProjectGalleryMessaging}
            }
            loopDashboardLabels {
              ${LoopMessaging}
            }
            childrenManagementLabels {
              ${ChildrenMessaging}
            }
            defaultActivityIcon {
              name
              src
              alt
            }
            profilePageLabels {
              ${ProfileMessaging}
            }
            clubsProfileLabels {
              ${ClubsProfileMessaging}
            }
            renewalLabels {
              firstStepLabel
              secondStepLabel
              thirdStepLabel
              thankYouLabel
              firstStepProgramExpirationDateLabel
              firstStepProgramInfoDescription
              firstStepClubRenewalSurveyLink
              secondStepInfoLabel
              secondStepRememberTableData
              thirdStepInfoLabel
              thirdStepAnonymousSignedAgreementMsg
              thirdStepAgreementFile {
                content
              }
              thirdStepDecisionMakerInfoMsg
              thirdStepDecisionMakerLink
              thankYouPageMainInfo
              thankYouPageAdditionalFacilitatorsInfo
              thankYouPageReturningMembersInfo
              thankYouPageAdditionalClubsInfo
              thankYouPageNextStepsInfo
              thankYouPageSisterhoodInfo
              thankYouPageCodeInfo
              thankYouPageImpactInfo
            }
            notifications {
              privacyPolicyMessage
              cookieAgreementMessage
            }
          }
        }
      `
  });
};

export const getHomePageContent = () => {
  return client.query({
    query: gql`
      {
        HomePageQuery {
          title
          programIcon {
            alt
            src
            name
          }
          organizationIcon {
            alt
            src
            name
          }
          communityPartnershipIcon {
            alt
            src
            name
          }
          collegeLoopIcon {
            alt
            src
            name
          }
          sipIcon {
            alt
            src
            name
          }
          clubIcon {
            alt
            src
            name
          }
          selfPacedProgramIcon {
            alt
            src
            name
          }
          facilitatorApplicationIcon {
            alt
            src
            name
          }
          contentSections {
            id
            type
            ... on ContentSection {
              heading
              subheading
              content
              facilitatorNote
              visibleInPageNavigation
              onlyVisibleToFacilitators
              isOpenTextAnswer
              requiredGrading
              isOptionalTextAnswer
            }
            ... on Surveys {
              surveyName
              surveyDescription
              surveyUrl
              salesforceId
              hqId
              programId
              userBasicData
              height
              ctaText
              isDomestic
              isEligibleForInternationalApplication
              hideLanguageSelector
            }
          }
          facilitateAnExistingClubSection {
            id
            ... on ContentSection {
              heading
              subheading
              content
            }
          }
          startNewClubSection {
            id
            ... on ContentSection {
              heading
              subheading
              content
            }
          }
          showCollegeLoopSection
          collegeLoopSection {
            id
            ... on ContentSection {
              heading
              subheading
              content
            }
          }
          startCollegeLoopLink
          landingClubSection {
            id
            ... on ContentSection {
              heading
              subheading
              content
            }
          }
          landingSipAndSppSection {
            id
            ... on ContentSection {
              heading
              subheading
              content
            }
          }
          landingCollegeLoopSection {
            id
            ... on ContentSection {
              heading
              subheading
              content
            }
          }
          landingVisitorSection {
            id
            ... on ContentSection {
              heading
              subheading
              content
            }
          }
          landingContent {
            id
            ... on ContentSection {
              heading
              subheading
              content
            }
          }
          visitorSectionIcon {
            alt
            src
            name
          }
          creatingChild {
            ${CreatingChild}
          }
          programIcons {
            ... on Image {
              alt
              src
              name
            }
          }
          gwcChallenges {
            id
            type
            ... on ContentSection {
              heading
              subheading
              content
              facilitatorNote
              visibleInPageNavigation
              onlyVisibleToFacilitators
              isOpenTextAnswer
              requiredGrading
              isOptionalTextAnswer
            }
            ... on Surveys {
              surveyName
              surveyDescription
              surveyUrl
              salesforceId
              hqId
              programId
              userBasicData
              height
              ctaText
              isDomestic
              isEligibleForInternationalApplication
              hideLanguageSelector
            }
          }
          gwcCahellengesBanner {
            alt
            src
            name
          }
        }
      }
    `
  });
};
