import image from './Image';

const LoopMessaging = `
  name
  newPostCtaLabel
  newPostSendEmailToAll
  newPostSave
  newPostCancel
  newPostPlaceholder
  newPostAddImage
  newPostChangeImage
  newPostRemoveImage
  deletePostConfirm
  editPostSave
  editPostCancel
  reportPostConfirm
  reportPostFeedback
  postMenuEdit
  postMenuDelete
  postMenuView
  postMenuReport
  newCommentSave
  newCommentPlaceholder
  commentListLoadMore
  commentListShowLess
  deleteCommentConfirm
  editCommentSave
  editCommentCancel
  commentMenuEdit
  commentMenuDelete
  commentMenuReport
  reportCommentConfirm
  reportCommentFeedback
  welcomePostAuthor
  welcomePostAvatar {
    ${image}
  }
  welcomePostContent
  sipPostAvatar {
    ${image}
  }
  campusPostAvatar {
    ${image}
  }
  clubPostAvatar {
    ${image}
  }
`;

export default LoopMessaging;
