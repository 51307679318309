import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Field, Form } from "formik";
import {
  Box,
  Typography,
} from "@material-ui/core/";

import TextButton from "../../text-button/text-button";
import CustomButton from "../../customButton/customButton";
import TextFieldGroup from "./TextFieldGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ColorTheme } from '../../../utils/colorTheme';
import { PageLeaveModal } from '../../../components/modals/pageLeaveWarning';

const useStyles = makeStyles({
  dialogContent: {},
  title: {
    color: ColorTheme.blue4.b,
    fontSize: 16,
    fontWeight: "bold",
    margin: "10px 0",
  },
  subTitle: {
    marginBottom: 10,
    fontSize: 14
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 25,
  },
  inviteBtn: {
    fontSize: 16,
    padding: '0 25px'
  },
  progressIcon: {
    width: 20,
    height: 20,
    marginLeft: 5,
  },
});

const validateEmailList = (value) => {
  if (
    !/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(
      value.trim()
    )
  ) {
    return false;
  }
  return true;
};

export const InviteMembersListModal = ({
  onBack,
  role,
  sendUserInvitation,
  onCloseModal,
  afterModalSubmit,
  user,
  isDataChanged,
  setDataChanged,
  isEscapeConfirmDialog,
  setEscapeConfirmDialog,
}) => {
  const classes = useStyles();
  const [enableInvite, setEnableInvite] = useState(false);
  const [bulkInvProcessing, setBulkInvProcessing] = useState(false);

  const sendBulkInvitation = (emails) => {
    setBulkInvProcessing(true);

    sendUserInvitation({
      emails: emails.map(e => e.trim().toLowerCase()),
      programId: role.program.id,
      facilitatorFullName: `${user.firstName} ${user.lastName}`,
      facilitatorInvitation: false,
    })
      .finally(() => {
        setBulkInvProcessing(false);
        onCloseModal();
        afterModalSubmit();
      })
  };

  return (
    <Box>
      <Typography id="dialog-description" className={classes.title}>Send Email Invitations</Typography>
      <Typography id="form-subtitle" className={classes.subTitle}>
        Enter emails below. Separate multiple emails by line breaks (i.e. pressing enter) or empty spaces.
      </Typography>
      <Box>
        <Formik
          initialValues={{
            currentEmail: "",
            emails: [],
          }}
          validate={(values) => {
            let errors = {};
            setDataChanged(true);
            const parsedValues = values.currentEmail
              .replaceAll("\n", " ")
              .split(" ")
              .map((e) => e.trim());
            const invalidPart = parsedValues.find(
              (e) => !!e && !validateEmailList(e)
            );
            
            if (!!invalidPart) {
              setEnableInvite(false);
              errors.currentEmail = "Invalid email in list";
            } else {
              setEnableInvite(parsedValues.length > 0);
            }

            return errors;
          }}
          onSubmit={(values) => {
            sendBulkInvitation(values.emails);
          }}
        >
          <Form>
            <Field
              name="currentEmail"
              originalValue="emails"
              component={TextFieldGroup}
              rowsMin={10}
              rowsMax={40}
              validateEmailList={validateEmailList}
              label={"Email"}
              countLabel={`Send ({count}) invitations`}
            />
            <Box className={classes.btnContainer}>
              <TextButton onClick={() => isDataChanged ? setEscapeConfirmDialog(true) : onBack()}>Back</TextButton>
              <CustomButton
                className={classes.inviteBtn}
                disabled={!enableInvite || bulkInvProcessing}
                mode={"primary"}
                type="submit"
                label="Invite">
                  {bulkInvProcessing && (
                    <CircularProgress
                      size={20}
                      className={classes.progressIcon}
                    />
                  )}
              </CustomButton>
            </Box>
          </Form>
        </Formik>
      </Box>
      <PageLeaveModal
        onCancel={() => { setEscapeConfirmDialog(false) }}
        onConfirm={()=> { setDataChanged(false);setEscapeConfirmDialog(false); onCloseModal();}}
        open={isEscapeConfirmDialog}
      />
    </Box>
  );
};
