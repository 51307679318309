import React from 'react';

import { makeStyles } from "@material-ui/core/styles";
import { Box, CardMedia } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import * as colors from "../../../theme/colors";

const useStyles = makeStyles({
  subDescription: {
    lineHeight: "30px",
    marginTop: 20,
    "& .MuiFormGroup-root": {
      "& .MuiTypography-body1": {
        fontWeight: "bold",
      },
      "& .MuiIconButton-label": {
        color: colors.darkThemeGreen,
      },
    },
  },
  imageContainer: {
    width: 253,
    height: 167,
  },
  imageContainerWrapper: {
    display: "flex",
    justifyContent: "space-around",
  },
  infoLink: {
    color: colors.darkThemeGreen,
  }
});

const ThirdStep = ({ icon }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.imageContainerWrapper}>
        <CardMedia className={classes.imageContainer} image={icon?.src} />
      </Box>
      <Box className={classes.subDescription}>
        <p>
          Thanks for your interest in becoming a Girls Who Code Club Facilitator! We’re happy that we were able to match you with your Club. We look forward to working together to bring sisterhood, code, and community.
        </p>
        <p>
          Please note, it may take up to 24 hours for you to gain access to all curriculum materials and for your Club to appear on your Girls Who Code HQ “My Programs” page.
        </p>
        <p>
          If you have any questions, please email <Link className={classes.infoLink} href="mailto:clubs@girlswhocode.com">clubs@girlswhocode.com</Link> and we will reach out to you within 72 hours.
        </p>
      </Box>
    </>
  );
};

export default ThirdStep;
