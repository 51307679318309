import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Formik, Field } from "formik";

import editorStyles from '../styles/editorStyles';
import { validateLinkInsert } from '../utils';
import CustomButton from "../../../components/customButton/customButton";
import TextInput from "../../../components/TextInput";

const useStyles = makeStyles(() => ({
  buttonGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none",
    fontSize: 16,
  },
  button: {
    width: "100px",
  }
}));

const LinkPopper = (props) => {
  const { classes, show, anchorEl = null, handleClose, submit, initialValues, removeLink } = props;
  const modalClasses = useStyles();

  const closeModal = (resetForm) => {
    resetForm();
    handleClose();
  };

  const onClickAway = (e, resetForm) => {
    if (props.anchorEl?.contains(e.target)) return;
    closeModal(resetForm);
  };

  const onRemoveClick = (e, resetForm) => {
    closeModal(resetForm);
    removeLink(e);
  }

  const handleFormSubmit = (values, { resetForm }) => {
    submit(values.text, values.link);
    closeModal(resetForm);
  };

  const onKeyDown = (e, ) => {
    if (e.key === 'Escape') {
      handleClose();
    }
  }

  return (
    <Popper
      open={show}
      onKeyDown={e => onKeyDown(e)}
      anchorEl={anchorEl}
      transition
      disablePortal
      className={classes.zIndex9}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={250}>
          <Card className={classes.linkCard}>
            <Formik
              enableReinitialize
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validate={validateLinkInsert}
            >
              {({ resetForm, handleSubmit }) => (
                <ClickAwayListener onClickAway={e => onClickAway(e, resetForm)}>
                <CardContent className={classes.linkCardContent}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        type="text"
                        name="text"
                        component={TextInput}
                        label="Text"
                        errorIsHtml={true}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        type="text"
                        name="link"
                        component={TextInput}
                        label="Link"
                        required
                        autoFocus
                        errorIsHtml={true}
                      />
                    </Grid>
                    <div className={modalClasses.buttonGroup}>
                      <CustomButton
                        className={modalClasses.button}
                        mode="textButton"
                        label={"Cancel"}
                        onClick={() => closeModal(resetForm)}
                        sel="cancel"
                      />
                      <CustomButton
                        className={modalClasses.removeButton}
                        mode="deleteButton"
                        label={"Remove"}
                        onClick={(e) => onRemoveClick(e, resetForm)}
                        sel="remove"
                      />
                      <CustomButton
                        className={modalClasses.button}
                        mode="primary"
                        label={"Save"}
                        onClick={handleSubmit}
                        sel="save"
                      />
                    </div>
                  </Grid>
                </CardContent>
              </ClickAwayListener>
            )}
          </Formik>
        </Card>
      </Fade>)}
    </Popper>
  );
};

export default withStyles(editorStyles)(LinkPopper);
