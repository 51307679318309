import { getAuthHeader } from '../tokenStorage';
import { axiosInstance } from './axiosInstance';

export const storeQuizAttempt = async (attempt) => {
  return axiosInstance.post('/blapi/userQuizAttempts/store',
    attempt,
    {
      headers: getAuthHeader()
    }
  );
};

export const getLastQuizAttempt = async (id, filters) => {
  return axiosInstance.post(`/blapi/userQuizAttempts/getLastByQuizId/${id}`,
    { filters },
    {
      headers: getAuthHeader()
    }
  );
};

  export const getAllLastQuizUserAttemptsByProgram = async ({programId}) => {
  return axiosInstance.get(`/blapi/userQuizAttempts/getAllLastQuizUserAttemptsByProgram/${programId}`,
    {
      headers: getAuthHeader()
    }
  );
};
