import { call, put } from 'redux-saga/effects';

import { requestSent } from '../actions/forgotPassword';
import { resetPassword } from '../api/index';

export function* sendRequest({ payload }) {
  try {
    yield call(resetPassword, payload);
  } catch (e) {
    // TODO: throw generic error
    console.log('TODO: handle error here');
    console.log(e);
  } finally {
    yield put(requestSent());
  }
}
