import React from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import ProfileCard from "../../../components/profile-card/profile-card";
import CopyLinkButton from '../../../components/copyLinkButton/copyLinkButton';
import * as colors from "../../../theme/colors";
import { formatLastLogin } from '../../../utils/formatLastLogin';

const useStyles = makeStyles({
  item: {
    wordWrap: 'break-word',
    '& .MuiTypography-body2': {
      fontFamily: "'Roboto Mono',monospace",
    },
    "& .MuiTypography-colorTextSecondary": {
      color: "#003046",
    },
  },
  editIcon: {
    cursor: "pointer"
  },
  contactUs: {
    color: colors.lightGrey,
  },
  divider: {
    marginBottom: "15px",
    marginTop: "15px",
    backgroundColor: "black"
  },
  itemContainer: {
    marginTop: "15px",
    marginBottom: "15px",
  },
});

const guardians = [
  { value: 1, label: "Mother" },
  { value: 2, label: "Father" },
  { value: 3, label: "Grandmother" },
  { value: 4, label: "Grandfather" },
  { value: 5, label: "Legal Guardian" },
  { value: 6, label: "Other" }
];

export const ParentsInfo = props => {
  const classes = useStyles();
  const { parents, labels } = props;

  let content;
  if (parents) {
    content = createParentsContent(parents, classes);
  }

  return (
    <ProfileCard
      icon={labels.parentsIcon}
      title={"Parents & Guardian information"}
      isEditable={false}
      content={<React.Fragment>{content}</React.Fragment>}
    />
  );
};

const createParentsContent = (parents, classes) => {

  const parentsInfoForm = parents.map((parent, index) => {
    let kinship = guardians.find(el => el.value == parent.kinshipId);
    let kinshipName = null;
    const lastLogin = formatLastLogin(parent.lastLogin);
    if (kinship) {
      kinshipName = kinship.label;
    }
    const isDividerEnable = parents.length - 1 !== index;
    return (
      <React.Fragment key={index}>
        <Grid key={index} container className={classes.item}>
          <Grid item xs={12} sm={6} md={4} className={classes.itemContainer}>
            <ListItemText
              primary="First name"
              className={classes.item}
              secondary={parent.firstName}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} className={classes.itemContainer}>
            <ListItemText
              primary="Last name"
              className={classes.item}
              secondary={parent.lastName}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} className={classes.itemContainer}>
            <ListItemText
              primary="Email"
              className={classes.item}
              secondary={parent.email}
            />
          </Grid>
          {parent.phoneNumber && <Grid item xs={12} sm={6} md={4} className={classes.itemContainer}>
            <ListItemText
              primary="Phone number"
              className={classes.item}
              secondary={parent.phoneNumber || "--"}
            />
          </Grid>}
          <Grid item xs={12} sm={6} md={4} className={classes.itemContainer}>
            <ListItemText
              primary="Their/guardian's relationship"
              className={classes.item}
              secondary={kinshipName}
            />
          </Grid>
          {lastLogin && <Grid item xs={12} sm={6} md={4} className={classes.itemContainer}>
            <ListItemText
              primary="Last login"
              className={classes.item}
              secondary={lastLogin}
            />
          </Grid>}
          <Grid item xs={12} sm={12} md={12} className={classes.itemContainer}>
            <Typography className={classes.contactUs} variant="body2">
              {`If you want to change something, please email us at `}
              <CopyLinkButton link="hq@girlswhocode.com" />
            </Typography>
          </Grid>
        </Grid>
        {isDividerEnable && <Divider />}
      </React.Fragment>
    );
  });
  return parentsInfoForm;
};

export default ParentsInfo;
