
export const downloadFile = (blob, title, extention) => {
    const convertToPDFBlob = blob.slice(0, blob.size, extention);

    const url = window.URL.createObjectURL(
        convertToPDFBlob
    );

    var downloadLink = document.createElement("a");
    downloadLink.download = title;

    if (window.webkitURL != null) {
        downloadLink.href = window.webkitURL.createObjectURL(convertToPDFBlob);
    } else {
        downloadLink.href = url;
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
    }
    downloadLink.click();
}
