import React from "react";
import { Link } from "react-scroll";

export default class MenuItem extends React.PureComponent {
  render() {
    const { id, title } = this.props;
    return (
      <React.Fragment>
        <Link
          to={id}
          spy={true}
          offset={-75}
          className="nav-item"
        >
          {title}
        </Link>
      </React.Fragment>
    );
  }
}
