import Cookies from 'js-cookie';

window.addEventListener('storage', (event) => {
  if (event.key === "refreshToken") {
    window.location.reload();
  }
})

export const getToken = () => {
  const lsToken = localStorage.getItem('token');
  const cToken = Cookies.get('token');
  return lsToken || cToken;
};

export const getRefreshToken = () => {
  return localStorage.getItem('refreshToken') ? localStorage.getItem('refreshToken') : Cookies.get('refreshToken');
};

export const setToken = (token) => {
  localStorage.setItem('token', token);
  Cookies.set('token', token, { domain: '.girlswhocode.com' });
};

export const setRefreshToken = (refreshToken) => {
  localStorage.setItem('refreshToken', refreshToken);
  Cookies.set('refreshToken', refreshToken, { domain: '.girlswhocode.com' });
};

export const clear = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');

  localStorage.removeItem('fingerprint');
  localStorage.removeItem('lastActivity');
  localStorage.removeItem('welcomeModals');
  localStorage.removeItem('cookieAgreement');
  localStorage.removeItem('signupCountryId');
  localStorage.removeItem('gwcPartnershipAnnouncements');
  localStorage.removeItem('facilitatorConsent');

  Cookies.remove('token', { domain: '.girlswhocode.com' });
  Cookies.remove('refreshToken', { domain: '.girlswhocode.com' });
};

export const clearAll = () => {
  clear();
  localStorage.clear();
};

export const getAuthHeader = () => {
  const token = getToken();
  if (!token) return {};
  
  return {
    'Authorization': `Bearer ${token}`
  }
};
