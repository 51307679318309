import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import * as colors from "../../../theme/colors";

import CustomButton from "../../../components/customButton/customButton";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "8px 8px 12px 8px",
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  cardHeader: {
    padding: 0,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      wordBreak: "break-all",
    },
    "& .MuiCardHeader-action": {
      margin: 0,
      "align-self": "auto",
    },
  },
  count: {
    color: colors.blue2,
    fontSize: 70,
    fontWeight: "bold",
    textAlign: "center",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "center",
    padding: 10,
  },
  content: {
    padding: "0 0 0 4px",
    "&:last-child": {
      paddingBottom: 0,

      [theme.breakpoints.down("xs")]: {
        wordBreak: "break-all",
      },
    },
  },
  primaryButton: {
    marginRight: 10,
  },
}));

const TshirtsCard = ({ club, labels, filteredStudentsCount }) => {
  const classes = useStyles();

  const { clubIncentiveCardsContent } = labels;
  if (!clubIncentiveCardsContent) return;

  const filteredTeachers = club.facilitators.filter(user => !user.incognito).length || 0;
  const numberOfTShirts = filteredStudentsCount + filteredTeachers;

  const text = clubIncentiveCardsContent.tShirtCardText.replace('{count}', numberOfTShirts)

  return (
    <Card className={classes.root}>
      <CardHeader
        title={<Typography className={classes.title}>{clubIncentiveCardsContent.tShirtCardTitle}</Typography>}
        className={classes.cardHeader}
      />
        <CardContent className={classes.content}>
          <Box className={classes.contentBox}>
            <Box>
              <Typography className={classes.count}>{numberOfTShirts}</Typography>
              <Typography className={classes.text}>{text}</Typography>
                <Box className={classes.buttonGroup}>
                  <CustomButton
                    mode="primary"
                    label={clubIncentiveCardsContent.tShirtCardOrderButtonText}
                    onClick={() => {
                      window.open(clubIncentiveCardsContent.tShirtCardOrderButtonLink,  "_blank");
                    }}
                    className={classes.primaryButton}
                  />
                  <CustomButton
                    mode="secondary"
                    label={clubIncentiveCardsContent.tShirtCardLearnMoreButtonText}
                    onClick={() => {
                      window.open(clubIncentiveCardsContent.tShirtCardLearnMoreButtonLink,  "_blank");
                    }}
                  />
                </Box>
            </Box>
          </Box>
        </CardContent>
    </Card>
  );
};

export default TshirtsCard;
