import React, { useState } from "react";
import { CSVLink } from "react-csv";
import clsx from 'clsx';

import { makeStyles } from "@material-ui/core/styles";
import {
  DialogActions,  
  Box,
  Typography, 
  RadioGroup,
  Radio,
  FormControlLabel} from "@material-ui/core";

import { HQModal } from "../../components/modals/HQModal";
import CustomButton from "../../components/customButton/customButton";
import { sortLastThenFirst } from '../../utils/generic';

const useStyles = makeStyles((theme) => ({
  contentBox: {
    padding: 10
  },
  subTitle: {
    fontSize: 16,
    marginBottom: 10,
  },
  checkBoxLabel: {
    fontSize: 14,
  },
  radio: {
    fontSize: 12,
  },
  buttonGroup: {
    marginTop: 20,
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none",
    fontSize: 16,
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  radioGroup: {
    margineLeft: 15
  },
  csvLink: {
    textDecoration: 'none'
  }
}));

const StyledRadio = (props) => {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};

const csvHeaders = [
  { label: "Name", key: "name" },
  { label: "Email", key: "email" },
  { label: "Date", key: "date" },
  { label: "Status", key: "status" },
  { label: "Comment", key: "comment" },
];

const title = (firstName, lastName) => `${firstName}${firstName ? " " : ""}${lastName}`;

export const AttendanceDownloadModal = ({ isOpen, onClose, data }) => {
  const classes = useStyles();

  const [sortBy, setSortBy] = useState("date");
  const [csvRows, setCsvRows] = useState([]);
  
  const onDownload = () => {
    const mappedData = data.filter(at => !!at.status).map((e) => {
      if (!e.user) {
        return { name: "", email: "", date: "" };
      }
 
      const firstName = e.user && e.user.firstName ? e.user.firstName : "";
      const lastName = e.user && e.user.lastName ? e.user.lastName : "";
      return {
        firstName,
        lastName,
        name: title(firstName, lastName),
        email: e.user.email,
        fullDate: e.date,
        date: e.date ? e.date.split("T")[0] : "",
        status: e.status,
        comment: e.comment,
      };
    }).sort((a, b) => new Date(a.fullDate) - new Date(b.fullDate));

    setCsvRows(sortBy === "date" ? mappedData : sortLastThenFirst(mappedData));
  }

  return (
    <HQModal title="Download Attendance" isOpen={isOpen} onClose={onClose}>
      <Box className={classes.contentBox}>
        <Typography className={classes.subTitle}>
          {`How do you want to sort the data?`}
        </Typography>

        <RadioGroup
          name="csvOptionSelector"
          value={sortBy}
          className={classes.radioGroup}
          onChange={(evt) => {
              setSortBy(evt.target.value);
            }
          }
        >
          <FormControlLabel
            key={1}
            classes={{ label: classes.checkBoxLabel }}
            value="date"
            control={
              <StyledRadio color="primary" className={classes.radio} />
            }
            label="Date of Attendance"
          />
          <FormControlLabel
            key={2}
            classes={{ label: classes.checkBoxLabel }}
            value="name"
            control={
              <StyledRadio color="primary" className={classes.radio} />
            }
            label="Student Name"
            />
        </RadioGroup>
      </Box>
      <DialogActions className={classes.buttonGroup}>
        <CustomButton
          mode="textButton"
          label="Cancel"
          onClick={onClose}
        />
        <CSVLink
          data={csvRows}
          filename={`attendance.csv`}
          headers={csvHeaders}
          className={classes.csvLink}
        >
          <CustomButton
            mode="primary"
            label="Download"
            onClick={onDownload}
          />
        </CSVLink>
      </DialogActions>
    </HQModal>
  );
};
