import { call, put } from "redux-saga/effects";

import {
  receivedPosts,
  receivedPost,
  receivedSinglePost,
  receivedDeletedPost,
  receivedDeletedComment,
  receivedUpdatedPost,
  onError
} from "../actions/messages";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../actions/notification";
import * as messagesApi from "../api/messages";
import { createUsageLog } from "../actions/tracking";
import { createBouncedUsersNotificationText } from '../containers/NotificationService/createBouncedUsersNotificationText';

import {
  LOOP_COMMENT_CREATED,
  LOOP_COMMENT_UPDATED,
  LOOP_COMMENT_DELETED,
  LOOP_POST_CREATED,
  LOOP_POST_UPDATED,
  LOOP_POST_DELETED,
} from "../constants/trackingEvents";

export function* getPosts({ payload }) {
  try {
    let {
      data: { Messages, error }
    } = yield call(messagesApi.getMessages, payload);
    
    if (error) {
      yield put(onError(error));
    }
    else {
      yield put(
        receivedPosts({
          posts: Messages
        })
      );
    }
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* getSinglePost({ payload }) {
  try {
    let {
      data: { Message, error }
    } = yield call(messagesApi.getSingleMessage, payload);

    if (error) {
      yield put(onError(error));
    }
    else {
      yield put(
        receivedSinglePost({
          singlePost: Message,
        })
      );
    }
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* createPost({ payload }) {
  try {
    const { origin } = window.location;
    payload.origin = origin;

    let {
      data: { SavedMessage, error, bouncedUsers }
    } = yield call(messagesApi.createMessage, payload);

    if (bouncedUsers && bouncedUsers.length > 0) {
      const errorMessage = createBouncedUsersNotificationText(bouncedUsers);
      yield put(showErrorMessage({ text: errorMessage, options: { persist: true } }))
    }

    if (error) {
      yield put(onError(error));
    }
    else {
      yield put(createUsageLog({
        action: LOOP_POST_CREATED,
      }));
      yield put(receivedPost(SavedMessage))
    }
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* reportMessage({ payload }) {
  try {
    const {
      data: { error }
    } = yield call(messagesApi.reportMessage, payload);
    
    if (error) {
      yield put(onError(error));
      yield put(showErrorMessage(error));
    }
    else {
      yield put(showSuccessMessage('Message was successfully reported'));
    }
  } catch (e) {
    yield put(showErrorMessage(e.message ? e.message : 'Error'));
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* createComment({ payload }) {
  try {
    payload.isComment = true;

    let {
      data: { SavedMessage, error }
    } = yield call(messagesApi.createMessage, payload);
    
    if (error) {
      yield put(onError(error));
    }
    else {
      yield put(createUsageLog({
        action: LOOP_COMMENT_CREATED,
      }));
      yield put(receivedUpdatedPost(SavedMessage))
    }
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* updatePost({ payload }) {
  try {
    let {
      data: { SavedMessage, error }
    } = yield call(messagesApi.updateMessage, payload);

    if (error) {
      yield put(onError(error));
    }
    else {
      yield put(createUsageLog({
        action: LOOP_POST_UPDATED,
      }));
      yield put(receivedUpdatedPost(SavedMessage));
    }
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* updateComment({ payload }) {
  try {
    payload.isComment = true;

    let {
      data: { SavedMessage, error }
    } = yield call(messagesApi.updateMessage, payload);
    
    if (error) {
      yield put(onError(error));
    }
    else {
      yield put(createUsageLog({
        action: LOOP_COMMENT_UPDATED,
      }));
      yield put(receivedUpdatedPost(SavedMessage));
    }
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* deleteMessage({ payload }) {
  try {
    const { isComment } = payload;
    let {
      data: { error }
    } = yield call(messagesApi.deleteMessage, payload);

    if (error) {
      yield put(onError(error));
      yield put(showErrorMessage(error));
    }
    else {
      if (isComment) {
        yield put(createUsageLog({
          action: LOOP_COMMENT_DELETED,
        }));
        yield put(receivedDeletedComment(payload));
      }
      else {
        yield put(createUsageLog({
          action: LOOP_POST_DELETED,
        }));
        yield put(receivedDeletedPost(payload));
      }
      yield put(showSuccessMessage('Message was successfully deleted'));
    }
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}