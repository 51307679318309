import React, { useState, useEffect } from "react";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import {
    Box,
    Typography,
    Button,
    CardActions,
    CardMedia,
    Card,
    Grid,
} from "@material-ui/core/";

import GetAppIcon from '@material-ui/icons/GetApp';
import CropFreeIcon from '@material-ui/icons/CropFree';
import EditIcon from '@material-ui/icons/Edit';
import AddCertificateLinkedinButton from "../../../components/add-certificate-linkedin/AddCertificateLinkedinButton";
import * as certificatesApi from "../../../api/certificates";
import * as colors from "../../../theme/colors";
import SkeletonCards from "../../project-gallery/skeletonCards/index";
import TextCustomButton from "../../../components/text-button/text-button";
import { downloadFile } from "../../../utils/downloadFile";
import { userIs13orOlder } from "../../../utils/userUtils";

const useStyles = makeStyles((theme) => ({
    card: {
        borderBottom: "6px solid $grey-dark",
        boxSizing: "border-box",
        cursor: "pointer",
        margin: 12,
        padding: 24,
        transition: "transform 150ms linear",
        height: "fit-content",
        width: "-webkit-fill-available",
        "&:hover": {
            transform: "scale(1.05, 1.05)",
        },
        "&:focus": {
            outline: "none",
            transform: "scale(1.05, 1.05)",
        },
    },
    progressCertificate: {
        maxWidth: 400,
        [theme.breakpoints.up('xl')]: {
            maxWidth: 500,
        }
    },
    cardCover: {
        height: 140,
    },
    progressCardCover: {
        height: 220,
    },
    img: {
        width: 300,
    },
    cardActions: {
        justifyContent: "center",
    },
    progressCardActios: {
        justifyContent: "space-between",
    },
}));

export const CertificateCard = ({
    item,
    push,
    currentUser,
    userId,
    isPublicProfile,
    setChangeCertificateModalOpened,
    setCurrentCerficate,
    showEditCertificateBtn,
    certificateGenerating,
}) => {
    const classes = useStyles();
    const [imgUrl, setImgUrl] = useState(null);

    useEffect(() => {
        fetchData();
    }, [item]);

    async function fetchData() {
        const url = await getImageUrl(item);
        setImgUrl(url);
    }

    const getImageUrl = async (item) => {
        const { data: { url } } = await certificatesApi.getSignedUrlCerfificate({ title: item.title, format: "png" });
        return url;
    };

    const download = async (item) => {
        await certificatesApi.downloadCertificate(item)
            .then((response) => {
                downloadFile(response.data, item.title, "application/pdf");
            });
    };

    const verifyCertificate = (item) => {
        push(`/verify/${item.title}`, {
            prevPath: window.location.pathname,
            label: isPublicProfile ? "profile" : "progress",
          });
    }

    const handleEditNameClick = (certificate) => {
        setChangeCertificateModalOpened(true);
        setCurrentCerficate(certificate);
    }

    const getCertificateName = () => {
      if (!!item.collection) {
        return item.courseName;
      } else {
        return !!programRole ? programRole.program?.name : "";
      }
    }

    const programRole = currentUser?.roles.find(e => e.programId === item.programId);
    const isRunning = certificateGenerating && certificateGenerating[item.id]?.isRunning;

    return (
        <Grid key={item.title} item xs={12} sm={6} md={6} lg={4} xl={3}>
            <Card
                className={isPublicProfile ? classes.card : clsx(classes.card, classes.progressCertificate)}
                tabIndex="0"
            >
                <CardMedia image={imgUrl} className={isPublicProfile ? classes.cardCover : classes.progressCardCover} />
                <CardActions className={isPublicProfile ? classes.cardActions : classes.progressCardActios} disableSpacing>
                    {currentUser && currentUser.id === parseInt(userId) && userIs13orOlder(currentUser) && <AddCertificateLinkedinButton name={getCertificateName()} item={item} />}
                    <Button disabled={!!isRunning} onClick={() => download(item)} sel="download-certificate" ><GetAppIcon /></Button>
                    <Button disabled={!!isRunning} onClick={() => verifyCertificate(item)} sel="view-certificate"><CropFreeIcon /></Button>
                    {isPublicProfile && showEditCertificateBtn && <Button onClick={() => handleEditNameClick(item)} sel="change-certificate-name"><EditIcon /></Button>}
                </CardActions>
            </Card>
        </Grid>
    );
};

export const Certificates = ({ certificates, userId, push, currentUser, isPublicProfile, setChangeCertificateModalOpened, setCurrentCerficate, showEditCertificateBtn, certificateGenerating }) => {
    const [viewMoreCertificates, setViewMoreCertificates] = useState(false);
    const [visibleCertificates, setVisibleCertificates] = useState([]);

    const userCertificates = certificates
        && !!certificates.userCertificates[userId]
        ? certificates.userCertificates[userId]
        : [];

    useEffect(() => {
        if (!userCertificates.length) return;
            setVisibleCertificates(viewMoreCertificates ? userCertificates : userCertificates.slice(0, 3));
        }, [userCertificates, viewMoreCertificates]);

    const { isLoading } = certificates;

    return (
        (isLoading || !!userCertificates.length) && <Box className="certificate-box">
            <Typography
                className="certificate-label"
                style={{ color: colors.darkThemeBlueGray }}
            >
                My Certificates
            </Typography>
            <Box className="certificates-list">
                {isLoading ? <SkeletonCards count={3} />
                    : visibleCertificates.map(e => {
                        return (<CertificateCard className="certificate-item" userId={userId} item={e} push={push} currentUser={currentUser} isPublicProfile={isPublicProfile} setChangeCertificateModalOpened={setChangeCertificateModalOpened} setCurrentCerficate={setCurrentCerficate} showEditCertificateBtn={showEditCertificateBtn} certificateGenerating={certificateGenerating} />)
                    })}

            </Box>
            <Box className="btn-container">
                {userCertificates.length > 3
                    && <TextCustomButton
                        label={viewMoreCertificates ? "View Less" : "View More"}
                        onClick={() => setViewMoreCertificates(!viewMoreCertificates)}
                    />}
            </Box>
        </Box>
    )
}

export default Certificates;
