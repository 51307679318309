import React from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { default as CheckboxMaterial } from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ColorTheme } from '../utils/colorTheme';

const useStyles = makeStyles({
  label: {
    fontSize: 16,
    lineHeight: 1.75,
    verticalAlign: 'middle',
    color: ColorTheme.blue4.b
  },
});

const GreenCheckbox = withStyles({
  root: {
    color: '#3DB390',
    '&$checked': {
      color: '#3DB390',
    }
  },
  checked: { }
})((props) => <CheckboxMaterial {...props} color="default" />);

const CustomLabel = (props) => (
  <span className={props.className}>{props.children}</span>
);

const Checkbox = ({ name, onChange, checked, label, disabled, ...props }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <FormControlLabel
        className={props.className}
        control={
          <GreenCheckbox
            disabled={disabled}
            className={classes.label}
            name={name}
            checked={checked}
            onChange={onChange}
          />
        }
        label={<CustomLabel className={clsx(props.labelStyle, classes.label)}>{label}</CustomLabel>}
      />
    </React.Fragment>
  );
};

export default Checkbox;
