import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import {
  Box,
  Typography,
} from "@material-ui/core/";

import TextButton from "../../text-button/text-button";
import CustomButton from "../../customButton/customButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import MultipleGroupedSelector from "./multipleGroupedSelector";
import MultipleSelector from "../../../components/multipleSelect/customSelect";
import { getUserName } from "../../../utils/generic";
import userTypes from "../../../constants/userTypeConstants";
import Roles from "../../../utils/permissions";
import { PageLeaveModal } from '../../../components/modals/pageLeaveWarning';

const useStyles = makeStyles((theme) => ({
  programSelectionBox: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 500,
    }
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: "bold",
    margin: "10px 0",
  },
  subTitle: {
    marginBottom: 10,
    fontSize: 14
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 35,
  },
  addBtn: {
    fontSize: 16,
    padding: '0 25px'
  },
  progressIcon: {
    width: 20,
    height: 20,
    marginLeft: 5,
  },
  boxSection: {
    display: "flex",
    marginBottom: 5,
    [theme.breakpoints.down('xs')]: {
      display: "block",
    },
  },
  attendanceField: {
    marginLeft: 10,
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
      marginLeft: 0,
    },
    '& .MuiTypography-body1': {
      lineHeight: 'inherit',
    }
  }
}));


export const InviteUsersFromProgramsModal = ({
  onBack,
  programs,
  programMembers,
  loadProgramMembers,
  isLoadingProgramMembers,
  createBulkUserRolesWithEnroll,
  currentRole,
  getMembers,
  onCloseModal,
  currentClubMembers,
  isDataChanged,
  setDataChanged,
  isEscapeConfirmDialog,
  setEscapeConfirmDialog,
}) => {
  const classes = useStyles();
  const [selectedProgramIds, setSelectedProgramIds] = useState([]);
  const [selectedAttendees, setSelectedAttendees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onProgramChange = (newValues, oldValues, programs) => {
    const newProgramsSelected = newValues.filter(e => !oldValues.includes(e));
    const programWithoutLoadedMembers = programs
      .filter(e => newProgramsSelected.includes(e.id) && !programMembers[e.id]);

    if (!!programWithoutLoadedMembers.length) {
      loadProgramMembers(programWithoutLoadedMembers.map(e => e.id));
    }
  };

  // convert members
  let programMembersList = [];
  programs
    .filter((e) => selectedProgramIds.includes(e.id))
    .map((e) => {
      const membersList = programMembers[e.id];
      if (!!membersList) {
        programMembersList = [
          ...programMembersList,
          ...membersList.filter(x => !currentClubMembers.find(y => y.userId === x.userId))
        ];
      }
    });

  const items = programMembersList
    .filter((e) => e.roleId == Roles.Student && e.userType !== userTypes.Anonymous)
    .map((el) => {
      return {
        id: el.userId,
        label: getUserName(el),
      };
    })
    .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);

  const createUserRoles = (attendeesIds) => {
    setIsLoading(true);
    return createBulkUserRolesWithEnroll({
      programId: currentRole.programId,
      userIds: attendeesIds
    })
      .then(() => {
        setIsLoading(false);
        getMembers(currentRole.programId);
        onCloseModal();
      })
      .catch(() => {
        setIsLoading(false);
        getMembers(currentRole.programId);
        onCloseModal();
      });
  }

  return (
    <Box className={classes.programSelectionBox}>
      <Typography id="dialog-description" className={classes.title}>From Another Program</Typography>
      <Typography className={classes.subTitle}>Select the Program(s) and Members would you like to add.</Typography>
      <Formik
        validationSchema={Yup.object({
          programIds: Yup.array().required("Required"),
          attendeeIds: Yup.array().required("Required"),
        })}
        initialValues={{
          programIds: selectedProgramIds,
          attendeeIds: [],
        }}
        validate={(values) => {
          let errors = {};
          setDataChanged(!!values.programIds.length);
          setSelectedAttendees(values.attendeeIds);
          return errors;
        }}
        onSubmit={(values) => {
          createUserRoles(values.attendeeIds);
        }}
      >
        <Form>
          <Box className={classes.boxSection}>
            <Field
              name={`programIds`}
              component={MultipleSelector}
              label="Programs"
              className={classes.programSelectBox}
              options={programs}
              onValueChange={(values, func, options) => {
                onProgramChange(values, options.programIds, programs);
                setSelectedProgramIds(values);
              }}
            />
            <Field
              name={`attendeeIds`}
              className={classes.attendanceField}
              component={MultipleGroupedSelector}
              label="People"
              title='Members'
              options={items}
              isLoading={isLoadingProgramMembers}
            />
          </Box>
          <Box className={classes.btnContainer}>
            <TextButton onClick={() => isDataChanged ? setEscapeConfirmDialog(true) : onBack()}>Back</TextButton>
            <CustomButton
              className={classes.addBtn}
              disabled={!selectedAttendees.length || isLoading}
              mode={"primary"}
              type="submit"
              label={"Add"}>
                {isLoading && (
                  <CircularProgress
                    size={20}
                    className={classes.progressIcon}
                  />
                )}
            </CustomButton>
          </Box>
        </Form>
      </Formik>
      <PageLeaveModal
        onConfirm={() => { setDataChanged(false); setEscapeConfirmDialog(false); onBack();}}
        onCancel={() => { setEscapeConfirmDialog(false) }}
        open={isEscapeConfirmDialog}
      />
    </Box>
  );
};
