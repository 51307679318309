import { call, put } from "redux-saga/effects";

import { 
  receivedClubInfo, 
  receivedPreferences, 
  receivedSocialMedia, 
  receivedCommunityPartnership,
  receivedClubInfoByCode,
  cleanedClubInfoByCode,
  receivedRoleLabels,
  receivedChangedUserRoleLabel,
  receivedUserRoleLabels,
  receivedDeletedUserRoleLabel,
  onError } from "../actions/clubProfile";
import { receiveConferenceLink } from "../actions/auth";
import { createTrackingLog } from "../actions/tracking";
import * as programsApi from "../api/programs";
import * as userRolesApi from "../api/userRoles";
import * as roleLabelsApi from "../api/roleLabels";
import * as userRoleLabelsApi from "../api/userRoleLabels";

import { showErrorMessage, showSuccessMessage } from "../actions/notification";
import {
  CLUB_PROFILE_UPDATE_SUCCESS,
  CLUB_PROFILE_UPDATE_ERROR,
} from "../constants/trackingEvents";

import { Roles } from "../utils/permissions";
import { PROGRAM_TYPES } from '../utils/constants';

const errorUpdateClubMsg = "Club wasn't updated";
const successUpdateClubMsg = "Club was successfully updated";
const errorUpdateShippingMsg = "Shipping Address wasn't updated";
const successUpdateShippingMsg = "Shipping Address was successfully updated";
const errorUpdateVirtualLinkMsg = "Virtual Conference Link wasn't updated";
const successUpdateVirtualLinkMsg = "Virtual Conference Link was successfully updated";
const errorUpdatePreferencesMsg = "Your Preferences were not updated";
const successUpdatePreferencesMsg = "Your Preferences were successfully updated";
const errorUpdateSocialMediaMsg = "Social Media link wasn't updated";
const successUpdateSocialMediaMsg = "Social Media link was successfully updated";
const errorUpdateCPMsg = "Community Partner affiliation wasn't updated";
const successUpdateCPMsg = "Community Partner affiliation was successfully updated";

export function* getClubInfo({ payload }) {
  try {
    const { defaultProgram } = payload;
    let {
      data: program,
    } = yield call(programsApi.getProgramByCode, payload);
    if (program && program.id !== parseInt(defaultProgram)) {
      const { data } = yield call(
        userRolesApi.getUserRolesByProgram,
        program.id
      );

      program.students = data
        .filter((e) => e.roleId == Roles.Student)
        .map((e) => {
          return e.user;
        });
      program.facilitators = data
        .filter((e) => e.roleId == Roles.Facilitator)
        .map((e) => {
          return e.user;
        });
      program.viewers = data
        .filter((e) => e.roleId == Roles.Viewer)
        .map((e) => {
          return e.user;
        });
    }

    yield put(receivedClubInfo(program));
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* updateClubInfo({ payload }) {
  try {
    let { data } = yield call(programsApi.updateProgram, {
      ...payload.data,
      id: payload.id
    });

    if (!data.error) {
      yield put(showSuccessMessage(successUpdateClubMsg));
      yield put(
        createTrackingLog({
          event: CLUB_PROFILE_UPDATE_SUCCESS,
          data: JSON.stringify(payload),
        })
      );

      const { virtualConferenceLink } = payload.data;
      yield put(receiveConferenceLink({ programId: payload.id, virtualConferenceLink }));

      yield put(receivedClubInfo({ ...data, communityPartnership: payload.data.communityPartnership }));
    }
    else throw true;
  } catch (e) {
    yield put(
      createTrackingLog({
        event: CLUB_PROFILE_UPDATE_ERROR,
        data: JSON.stringify(payload),
      })
    );
    yield put(showErrorMessage(errorUpdateClubMsg));
    yield put(onError());
  }
}

export function* updateClubShipping({ payload }) {
  try {
    let { data } = yield call(programsApi.updateProgramShipping, {
      id: payload.id,
      ...payload.shippingData
    });
    if (!data.error) {
      yield put(
        createTrackingLog({
          event: CLUB_PROFILE_UPDATE_SUCCESS,
          data: JSON.stringify(payload),
        })
      );
      yield put(showSuccessMessage(successUpdateShippingMsg));
      yield put(receivedClubInfo(data));
    }
    else throw true;
  } catch (e) {
    yield put(
      createTrackingLog({
        event: CLUB_PROFILE_UPDATE_ERROR,
        data: JSON.stringify(payload),
      })
    );
    yield put(showErrorMessage(errorUpdateShippingMsg));
    yield put(onError());
  }
}

export function* updateVirtualLinkOnly({ payload }) {
  try {
    let { data } = yield call(programsApi.updateVirtualLink, {
      ...payload.data,
      id: payload.id
    });

    yield put(
      createTrackingLog({
        event: CLUB_PROFILE_UPDATE_SUCCESS,
        data: JSON.stringify(payload),
      })
    );
    yield put(showSuccessMessage(successUpdateVirtualLinkMsg));
    yield put(receivedClubInfo(data));
  } catch (e) {
    yield put(showErrorMessage(errorUpdateVirtualLinkMsg));
    yield put(onError());
  }
}

export function* updatePreferences({ payload }) {
  try {
    let { data: {
      externalFacilitators,
      openClosedEnrollment,
      clubIsActive,
      error
    } } = yield call(programsApi.updatePreferences, {
      ...payload.data,
      id: payload.id,
    });
    
    if (!error) {
      yield put(showSuccessMessage(successUpdatePreferencesMsg));
      yield put(receivedPreferences({
        externalFacilitators,
        openClosedEnrollment,
        clubIsActive,
      }));
    }
    else throw true;
  } catch (e) {
    yield put(showErrorMessage(errorUpdatePreferencesMsg));
    yield put(onError());
  }
}

export function* updateSocialMedia({ payload }) {
  try {
    let { data: {
      socialMedia,
      error
    } } = yield call(programsApi.updateSocialMedia, {
      ...payload.data,
      id: payload.id,
    });

    if (!error) {
      yield put(receivedSocialMedia({ socialMedia }));
      yield put(showSuccessMessage(successUpdateSocialMediaMsg));
    }
    else throw true;
  } catch (e) {
    yield put(showErrorMessage(errorUpdateSocialMediaMsg));
    yield put(onError());
  }
}

export function* updateCommunityPartnershipAffilation({ payload }) {
  try {
    const { clubId, item: { id } } = payload;

    let { data: {
      error
    } } = yield call(programsApi.updatePartnerAffiliation, {
      id: clubId, 
      cpId: id
    });

    if (!error) {
      yield put(receivedCommunityPartnership(payload.item));
      yield put(showSuccessMessage(successUpdateCPMsg));
    }
    else throw true;
  } catch (e) {
    yield put(showErrorMessage(errorUpdateCPMsg));
    yield put(onError());
  }
}

export function* getClubInfoByCode({ payload }) {
  try {
    let {
      data: program,
    } = yield call(programsApi.getProgramByCode, payload);
    if ((program.programType || '').toLocaleLowerCase() !== PROGRAM_TYPES.CLUB.toLocaleLowerCase()) {
      yield put(receivedClubInfoByCode(null));
    } else {
      yield put(receivedClubInfoByCode(program));
    }
  } catch (e) {
    yield put(showErrorMessage(e.message));
    console.log("Error in getClubInfoByCode:");
    console.log(e);
  }
}

export function* cleanClubInfoByCode() {
  try {
    yield put(cleanedClubInfoByCode());
  } catch (e) {
    console.log("Error in cleanClubInfoByCode:");
    console.log(e);
  }
}

export function* getRoleLabels({ payload }) {
  try {
    const { data } = yield call(roleLabelsApi.getRoleLabelsByProgramId, payload);

    yield put(receivedRoleLabels(data));
  } catch (e) {
    console.log("Error in getRoleLabels:");
    console.log(e);
    yield put(onError());
  }
}

export function* getUserRoleLabels({ payload }) {
  try {
    const { data } = yield call(userRoleLabelsApi.getUserRoleLabelsByProgramId, payload);

    yield put(receivedUserRoleLabels(data));
  } catch (e) {
    console.log("Error in getUserRoleLabels:");
    console.log(e);
    yield put(onError());
  }
}

export function* createUserRoleLabel({ payload }) {
  try {
    const { data } = yield call(userRoleLabelsApi.createUserRoleLabel, payload);

    yield put(receivedChangedUserRoleLabel(data));
  } catch (e) {
    console.log("Error in createUserRoleLabel:");
    console.log(e);
    yield put(onError());
  }
}

export function* deleteUserRoleLabel({ payload }) {
  try {
    yield call(userRoleLabelsApi.deleteUserRoleLabel, payload);

    yield put(receivedDeletedUserRoleLabel(payload));
  } catch (e) {
    console.log("Error in deleteUserRoleLabel:");
    console.log(e);
    yield put(onError());
  }
}
