import { createReducer } from "redux-act";

import * as a from "../actions/projectGallery";

const getDefaultState = () => ({
  isLoading: false,
  isLoadingFilters: false,
  isLoadingMore: false,
  projectGalleries: [],
  filtersData: {
    projectTypes: [],
    languageTags: [],
    topicProjectTypes: [],
    countries: [],
  },
  actualFiltersData: {
    countries: [],
    tags: [],
    programTypeIds: [],
    curriculums: [],
    years: [],
    flags: [],
  },
  totalScore: 0,
  projectsByProgram: null,
  allProjectsByProgram: null,
  isUserProjectsByProgram: null,
  usersProjectsByProgram: null,
  isLoadingProjects: false,
  projectLanguageTags: [],
  projectTypesTags: [],
  projectTopicTypeTags: [],
});

export default () =>
  createReducer(
    {
      [a.getProjectGalleries]: (state, payload) => {
        const isFirstLoad = payload?.pageNumber === 0;
        return {
        ...state,
        isLoading: isFirstLoad,
        isLoadingMore: !isFirstLoad
      }},
      [a.getFiltersData]: (state) => ({
        ...state,
        isLoadingFilters: true,
      }),
      [a.receivedProjectGalleries]: (state, payload) => {
        const { projects, totalScore, actualFilters } = payload;
        return {
          ...state,
          projectGalleries: projects,
          totalScore,
          actualFiltersData: actualFilters,
          isLoading: false,
          isLoadingMore: false
        };
      },
      [a.receivedFiltersData]: (state, payload) => {
        const { filtersData, langTags, projectTypesTags, topicsTags } = payload;
        return {
          ...state,
          isLoadingFilters: false,
          filtersData,
          projectLanguageTags: langTags,
          projectTypesTags,
          projectTopicTypeTags: topicsTags,
        };
      },
      [a.getUserProjectsByProgram]: (state) => ({
        ...state,
        isLoadingProjects: true,
        projectsByProgram: null,
      }),
      [a.receivedMyProjectsByProgram]: (state, payload) => ({
        ...state,
        isLoadingProjects: false,
        projectsByProgram: payload.data,
      }),
      [a.getIsUserProjectsByProgram]: (state) => ({
        ...state,
        isUserProjectsByProgram: null,
      }),
      [a.receivedIsUserProjectsByProgram]: (state, payload) => ({
        ...state,
        isUserProjectsByProgram: payload.data,
      }),
      [a.getUsersProjectsByProgram]: (state) => ({
        ...state,
        usersProjectsByProgram: null,
      }),
      [a.receivedUsersProjectsByProgram]: (state, payload) => ({
        ...state,
        usersProjectsByProgram: payload.data,
      }),
      [a.getProjectsByProgram]: (state) => ({
        ...state,
        isLoadingProjects: true,
        allProjectsByProgram: null,
      }),
      [a.receivedProjectsByProgram]: (state, payload) => ({
        ...state,
        isLoadingProjects: false,
        allProjectsByProgram: payload.data,
      }),
    },
    getDefaultState()
  );
