const fieldsContentSectionGroup = `
  id
  ...on ContentSectionGroup {
    name
    total
    current
    questionsIds
    requiredGrading
  }
`;

export default fieldsContentSectionGroup;
