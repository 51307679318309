import client from "../../../apolloClient";
import { gql } from "apollo-boost";
import { getModulesQuery } from "../types/modules";

export const getStaticPages = (url) => {
  return client.query({
    query: gql` 
    {
      PageQuery(
        slug: "${url}"
      ) {
        id
        slug
        layout
        modules {
          id
          type
          ${getModulesQuery()}
        }
      }
    }`
  });
};
