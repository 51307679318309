import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';

import GoogleButton from '../../../components/GoogleButton';
import { PasswordInput } from '../../../components/PasswordInput';
import { connectTo, filterField, filterFieldWithReturnKey, createMarkup, checkIsClubsDomain } from '../../../utils/generic';
import * as actions from '../../../actions/auth';
import DividerWithText from '../../../components/DividerWithText';
import EmailInput from '../Register/EmailInput';
import * as tokenStorage from '../../../tokenStorage';
import CustomButton from "../../../components/customButton/customButton";
import TextCustomButton from "../../../components/text-button/text-button";
import * as colors from "../../../theme/colors";

const useStyles = makeStyles(() => ({
  alertRoot: {
    marginTop: 25,
  },
  alertMessage: {
    '& p': {
      margin: 0
    }
  },
  container: {
    width: '100%',
  },
  linksContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  link: {
    '& a': {
      color: 'inherit',
      display: 'block',
      marginTop: 15,
    },
  },
  login: {
    margin: '35px auto 0 auto',
  },
  password: {
    margin: '35px auto 0 auto',
  },
  submitContainer: {
    marginBottom: 20,
    marginTop: 40,
    width: '100%',
  },
  ssoDivider: {
    marginTop: 20,
    marginBottom: 20,
  },
  ssoButton: {
    // display: 'flex',
    // fontWeight: 'bold',
    // justifyContent: 'center',
    // marginBottom: 20,
    // width: '100%',
    height: 43,
  },
  customSsoButton: {
    fontWeight: 'bold',
  },
  submitButton: {
    height: 43,
    width: '100%',
    textTransform: "uppercase",
  },
  textBtn: {
    color: colors.dartThemeBlueGray,
    fontSize: 12,
    textTransform: "uppercase",
    '&:hover': {
      color: colors.dartThemeBlueGray,
    },
    "&:focus": {
      outline: `2px dotted ${colors.grey400}`,
    },
  }
}));

const validationSchema = (content) => {
  let emailErrors = filterField(content, "id", "loginEmail").validationErrors;
  let passwordErrors = filterField(content, "id", "password").validationErrors;

  return Yup.object({
    login: Yup.string().trim()
          // .email(filterFieldWithReturnKey(emailErrors, "messageCode", "invalidEmail", "message"))
          .required(filterFieldWithReturnKey(emailErrors, "messageCode", "requiredField", "message")),
    password: Yup.string()
          .required(filterFieldWithReturnKey(passwordErrors, "messageCode", "requiredField", "message")),
  });
}

const Login = (props) => {
  const classes = useStyles();
  const { authorize, loginError, loginErrorType, loginContent, invitedEmail } = props;
  const [submitting, setSubmitting] = React.useState(false);
  const isClubsDomain = checkIsClubsDomain();

  React.useEffect(() => {
    const token = tokenStorage.getToken();

    if (!!token && !window.location.href.includes("login"))  {
      window.location.href = '/gwc';
    }
  }, []);

  React.useEffect(() => {
    if (!!loginError) setSubmitting(false);
  }, [loginError]);

  const responseGoogle = (response) => {
    const { accessToken, profileObj } = response;

    if (!profileObj) return;

    const { email } = profileObj;

    setSubmitting(true);
    authorize({ accessToken, login: email, googleLogin: true });
  };

  const responseGoogleError = () => {
    // TODO: next line was commented out because qa-automation gets alert for test.hq.girlswhocode.com
    // This should be handled properly
    // alert('Please, make sure cookies are enabled for current application');
  };

  const errorType = loginErrorType || (isClubsDomain ? 'invalidCredentialsClub' : 'invalidCredentials');
  return (
    <Formik
      enableReinitialize
      initialValues={{
        login: invitedEmail,
        password: '',
      }}
      validateOnMount={true}
      validationSchema={validationSchema(loginContent.loginFields)}
      onSubmit={(values) => {
        setSubmitting(true);
        authorize({ ...values, googleLogin: false });
      }}
    >
      {({errors}) => (
      <Form className={classes.container}>
        <Container className={classes.container}>
          {loginError && <Alert
            classes={{
              root: classes.alertRoot,
              message: classes.alertMessage
            }}
            severity="error"
            variant="outlined"
          >
            <div className={classes.link} dangerouslySetInnerHTML={createMarkup(filterFieldWithReturnKey(loginContent.formMessages, "messageCode", errorType, "message"))}></div>
          </Alert>}
          <Box className={classes.login}>
            <Field
              name="login"
              component={EmailInput}
              required
              label={filterFieldWithReturnKey(loginContent.loginFields, "id", "loginEmail", "labelPrompt")}
            />
          </Box>
          <Box className={classes.password}>
            <Field
              name="password"
              component={PasswordInput}
              required
              label={filterFieldWithReturnKey(loginContent.loginFields, "id", "password", "labelPrompt")}
              className={classes.email}
            />
          </Box>
          <Box className={classes.linksContainer}>
            <TextCustomButton
              className={classes.textBtn}
              mode="primary"
              onClick={() => {
                window.location.href = loginContent.loginForgotPasswordCTA.url;
              }}
              label={loginContent.loginForgotPasswordCTA.text}
              sel="forgot-password"
            />
            <TextCustomButton
              className={classes.textBtn}
              mode="primary"
              onClick={() => {
                window.location.href = loginContent.loginNeedHelpCTA.url;
              }}
              label={loginContent.loginNeedHelpCTA.text}
            />
          </Box>

          <Box className={classes.submitContainer}>
            <CustomButton
              disabled={Object.keys(errors).length > 0 || !!submitting}
              className={classes.submitButton}
              mode={"primary"}
              type="submit"
              label="Log in"
            />
          </Box>

          <DividerWithText className={classes.ssoDivider}>OR</DividerWithText>

          <Box>
            <GoogleButton
              variant="outlined"
              labelText="Log in with Google"
              onSuccess={responseGoogle}
              onError={responseGoogleError}
            />
          </Box>
        </Container>
      </Form>
      )}
    </Formik>
  );
};

export default connectTo(
  state => ({ ...state.auth, ...state.global }),
  actions,
  Login,
);
