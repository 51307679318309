import { createTheme } from '@material-ui/core/styles';
import { redA200, redA400, redA700, greenA200, greenA400, greenA700 } from './colors';

export default createTheme({
  overrides: {
    MuiTreeItem: {
      root: {

      },
    },
    MuiCheckbox: {
      root: {
        '&.Mui-focusVisible': {
          outline: 'thin solid black'
        }
      }
    },
    MuiRadio: {
      root: {
        '&.Mui-focusVisible': {
          outline: 'thin solid black'
        }
      }
    },
    MuiButton: {
      root: {
        '&.Mui-focusVisible': {
          outline: 'thin solid black'
        }
      }
    },
    MuiIconButton: {
      root: {
        '&.Mui-focusVisible': {
          outline: 'thin solid black'
        }
      }
    },
    MuiInputLabel: {
      root: {
        '& .MuiInputLabel-asterisk': {
          color: 'red',
        }
      }
    },
    MuiFormLabel: {
      root: {
        '& .MuiFormLabel-asterisk': {
          color: 'red',
        }
      }
    }
  },
  typography: {
    fontFamily: [
      'Roboto',
      'sans-serif'
    ],
    body1: {
      fontFamily: "'Roboto',sans-serif",
      fontweight: 500,
      lineheight: 1.5,
      color: '#003046',
    },
    body2: {
      // fontFamily: "'Roboto Mono',monospace",
      fontweight: 400,
      lineheight: 1.43,
      fontsize: '0.875rem',
    },
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: '#40628f',
      main: '#083861',
      // main: indigo[900],
      dark: '#001237',
      // dark: indigo[900],
      contrastText: '#fff',
    },
    secondary: {
      light: greenA200,
      main: greenA400,
      dark: greenA700,
      contrastText: '#fff',
      // contrastText: getContrastText(palette.secondary.A400),
    },
    error: {
      light: redA200,
      main: redA400,
      dark: redA700,
      contrastText: '#fff',
    },
  },
  shape: {
    borderRadius: 2
  },
});
