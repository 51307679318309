import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { HQModal } from "../HQModal";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import {
    Box,
    Typography,
} from "@material-ui/core/";
import TextButton from "../../text-button/text-button";
import CustomButton from "../../customButton/customButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import MultipleGroupedSelector from "../../modals/members/multipleGroupedSelector";
import TextInput from "../../../components/TextInput";
import { TooltipWrapper } from "../../../components/tooltip/tooltip";
import { PageLeaveModal } from '../../../components/modals/pageLeaveWarning';

const useStyles = makeStyles((theme) => ({
    programSelectionBox: {
        [theme.breakpoints.up('sm')]: {
            minWidth: 500,
        }
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: 16,
        fontWeight: "bold",
        margin: "10px 0",
    },
    subTitle: {
        marginTop: 30,
        fontSize: 14
    },
    saveBtnContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: 35,
    },
    addBtn: {
        fontSize: 16,
        paddingLeft: 20,
        paddingRight: 20,
    },
    progressIcon: {
        width: 20,
        height: 20,
        marginLeft: 5,
    },
    boxSection: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        marginBottom: 5,
        flexWrap: "wrap",
        [theme.breakpoints.down('xs')]: {
            display: "block",
        },
    },
    programMembersField: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 10,
            marginLeft: 0,
        },
        '& .MuiTypography-body1': {
            lineHeight: 'inherit',
        }
    }
}));

const isGroupExist = (groupNameList = [], groupName) => groupNameList.some((str) => str.toLowerCase() === groupName)

const validateGroupName = (groupName = '', groupNameList = [], nameLengthLimit, oldGroupName = '') => {
    const nameLowercased = groupName.toLowerCase().trim();
    
    if (!nameLowercased.length) return "Required field";
    if (nameLowercased.length >= nameLengthLimit) return `Group Name must be less than ${nameLengthLimit} characters`;
    if (isGroupExist(groupNameList, nameLowercased) && nameLowercased !== oldGroupName.toLowerCase()) return 'Group Name is already taken';
};
const nameLengthLimit = 255;

export const GroupMemberModal = ({
    isOpen,
    onCloseModal,
    membersList,
    createUsersGroups,
    updateUsersGroup,
    isCreatingMembersGroup,
    programId,
    currentMembersGroups,
    isLoading,
    editingGroup,
    isEdit,
}) => {
    const classes = useStyles();

    const [enableAddGroup, setEnableAddGroup] = useState(false);
    const [selectedMembersError, setSelectedMembersError] = useState('');
    const messageMembersError = "Please add at least two members";
    const [isEscapeConfirmDialog, setEscapeConfirmDialog] = useState(false);
    const [isDataChanged, setDataChanged] = useState(false);
    const onCloseCreateGroupModal = () => {
        if (isDataChanged) {
            setEscapeConfirmDialog(true);
        } else {
            setDataChanged(false);
            setEscapeConfirmDialog(false);
            onCloseModal();
        }
    };

    return (
        <HQModal title={isEdit ? "EDIT A GROUP" : "CREATE A GROUP"}
            titleOptions={{ isUpperCase: true, isBold: true }}
            maxWidth="lg"
            isOpen={isOpen} onClose={onCloseCreateGroupModal}>
                <Box className={classes.programSelectionBox}>
                    <Formik
                        validationSchema={Yup.object({
                            groupName: Yup.string().required("Required"),
                            programMembers: Yup.array().required("Required"),
                        })}
                        initialValues={{
                            groupName: editingGroup?.groupName ?? "",
                            programMembers: editingGroup?.groupMembers ?? [],
                            oldGroupName: editingGroup?.groupName ?? "",
                        }}
                        validateOnMount={false}
                        validate={({ groupName, programMembers }) => {
                            let errors = {};
                            setDataChanged(!!groupName || !!programMembers.length);
                            const groupNameList = Object.keys(currentMembersGroups || {});
                            const groupNameError = validateGroupName(groupName, groupNameList, nameLengthLimit, editingGroup?.groupName);
                            if (groupNameError) {
                                errors.groupName = groupNameError;
                            }
                            setEnableAddGroup(programMembers?.length >= 2 && !groupNameError);
                            setSelectedMembersError(programMembers?.length < 2 && messageMembersError)

                            return errors;
                        }}
                        onSubmit={({ programMembers, groupName, oldGroupName }) => {
                            if (isEdit) {
                              updateUsersGroup({
                                groupName: groupName.trim(),
                                memberUserIds: programMembers,
                                groupId: editingGroup.id,
                                oldGroupName,
                                programId,
                              });
                            } else {
                              createUsersGroups({
                                groupName: groupName.trim(),
                                membersIds: programMembers,
                                programId,
                              });
                            }
                            onCloseModal();
                        }}
                        
                    >
                        <Form>
                            <Box className={classes.boxSection}>
                                <Box>
                                    <Typography className={classes.subTitle}>GROUP NAME</Typography>
                                </Box>
                                <Field
                                    type="text"
                                    name="groupName"
                                    component={TextInput}
                                    label=""
                                    placeholder="Name Your Group"
                                    setTouchedOnChange={true}
                                    errorIsHtml={true}
                                />
                                <Box>
                                    <Typography className={classes.subTitle}>GROUP MEMBERS</Typography>
                                </Box>
                                <Field
                                    name="programMembers"
                                    component={MultipleGroupedSelector}
                                    label="Members"
                                    title="Members"
                                    className={classes.programMembersField}
                                    options={membersList}
                                />
                            </Box>
                            <Box className={classes.saveBtnContainer}>
                                <TextButton onClick={() => isDataChanged ? setEscapeConfirmDialog(true) : onCloseModal()}>CANCEL</TextButton>
                                <TooltipWrapper title={selectedMembersError}>
                                    <div >
                                        <CustomButton
                                            className={classes.addBtn}
                                            disabled={!enableAddGroup || isCreatingMembersGroup || isLoading}
                                            type="submit"
                                            label="SAVE GROUP">
                                            {isLoading && isCreatingMembersGroup && (
                                                <CircularProgress
                                                    size={20}
                                                    className={classes.progressIcon}
                                                />
                                            )}
                                        </CustomButton>
                                    </div>
                                </TooltipWrapper>
                            </Box>
                        </Form>
                    </Formik>
                    <PageLeaveModal
                        onCancel={() => { setEscapeConfirmDialog(false) }}
                        onConfirm={()=> { setDataChanged(false);setEscapeConfirmDialog(false); onCloseModal();}}
                        open={isEscapeConfirmDialog}
                    />
                </Box>
        </HQModal>
    );
};
