import React from 'react'

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Menu from "@material-ui/core/Menu";
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import PageTitle from "../../components/page-title/pageTitle";
import * as colors from "../../theme/colors";

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 15
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  pendingRow: {
    backgroundColor: theme.palette.warning.light,
  },
  regularRow: {
    backgroundColor: theme.palette.success.light,
  },
  tableContainer: {
    marginTop: 10,
    width: '100%',
  },
  inviteButton: {
    backgroundColor: '#6eceb2',
    '&:hover': {
      backgroundColor: '#3cb390',
    },
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  pendingBadge: {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
  },
  acceptedBadge: {
    color: colors.darkThemeGreen,
    borderColor: colors.darkThemeGreen,
  },
  tdSmall: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  title: {
    wordBreak: 'break-all',
  },
  smOnly: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  largeScreensOnly: {
    display: 'table-cell',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  largeScreensLastLoginOnly: {
    display: 'table-cell',
    fontFamily: "'Roboto Mono',monospace",
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  regularLink: {
    "&:hover": {
      textDecoration: "none"
    },
    fontWeight: 600,
  },
  redLink: {
    color: colors.red300,
    "&:hover": {
      textDecoration: "none"
    },
    fontWeight: 600,
  },
  inviteBtn: {
    height: 42,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    }
  },
  notifyMessage: {
    margin: '25px 0',
    fontStyle: 'italic'
  }
}));

const Invitations = ({ invitations, isLoading, resendInvite, removeInvite }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedInvitation, setSelectedInvitation] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <Box className={classes.header}>
        <PageTitle title="Invitations" isLoading={isLoading} />
      </Box>

      {isLoading 
      ? <></> 
      : <TableContainer component={Box} className={classes.tableContainer}>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            {invitations.map((row) => (
              <TableRow key={row.id} sel="invite-row">
                <TableCell component="th" scope="row">
                  <Typography variant="body2" className={classes.title} >{row.email}</Typography>
                  <Typography variant="body2" className={classes.smOnly} sel="invitation-date">{moment(row.createdAt).local().format('MM/DD/YYYY')}</Typography>
                </TableCell>
                <TableCell align="right" className={classes.largeScreensLastLoginOnly}>{moment(row.createdAt).local().format('MM/DD/YYYY')}</TableCell>
                <TableCell align="center" className={classes.tdSmall}>
                  <Chip
                    variant="outlined"
                    label={row.pending ? 'pending' : 'accepted'}
                    className={row.pending ? classes.pendingBadge : classes.acceptedBadge}
                    sel="invitation-status"
                  />
                </TableCell>
                <TableCell align="right" className={classes.tdSmall}>
                  {row.pending && <IconButton aria-label="settings" onClick={(evt) => {
                    setSelectedInvitation(row);
                    handleClick(evt);
                  }}>
                    <MoreVertIcon />
                  </IconButton>}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </TableContainer>
      }
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          className={classes.regularLink}
          onClick={() => {
            resendInvite(selectedInvitation);
            handleClose();
          }}
        >
          Resend
        </MenuItem>
        <MenuItem
          className={classes.redLink}
          onClick={() => {
            removeInvite(selectedInvitation.id);
            handleClose();
          }}
        >
          Remove
        </MenuItem>
      </Menu>
    </Container>
  );
};

export default Invitations;
