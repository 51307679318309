import { getAuthHeader } from '../tokenStorage';
import { axiosInstance } from './axiosInstance';

export const getUserRolesByProgram = async (programId) => {
  return axiosInstance.get(`/blapi/UserRoles/byProgram/${programId}`, {
    headers: getAuthHeader(),
  });
};

export const createBulkUserRolesWithEnroll = async (payload) => {
  return axiosInstance.post(
    '/blapi/UserRoles/createBulkUserRolesWithEnroll',
    payload,
    {
      headers: getAuthHeader(),
    }
  );
};

export const createBulkFacilitatorRoles = async (payload) => {
  return axiosInstance.post(
    '/blapi/UserRoles/createBulkFacilitatorRoles',
    payload,
    {
      headers: getAuthHeader(),
    }
  );
};

export const createBulkCollegeLoopPresidentsRoles = async (payload) => {
  return axiosInstance.post(
    '/blapi/UserRoles/createBulkCollegeLoopPresidentsRoles',
    payload,
    {
      headers: getAuthHeader(),
    }
  );
};

export const createAnonymousUsersWithEnroll = async ({ programId, users }) => {
  return axiosInstance.post(
    `/blapi/register/createAnonymousUsersWithEnroll/${programId}`,
    users,
    {
      headers: getAuthHeader(),
    }
  );
};

export const deleteUserRoleByProgram = async (programId, userId) => {
  return axiosInstance.delete(
    `${window._env_.REACT_APP_API_URL}/blapi/UserRoles/deleteProgramRole/${programId}/${userId}`,
    {
      headers: getAuthHeader(),
    }
  );
};
