import { createReducer } from 'redux-act'

import * as a from '../actions/curriculum';

const getDefaultState = () => ({
  activities: {},
  currentActivity: null,
})

export default () =>
  createReducer(
    {
      [a.activityLoaded]: (state, data) => ({
        ...state,
        activities: {
          ...state.activities,
          [data.activity.slug]: {
            roleId: data.roleId,
            ...data.activity
          }
        },
        currentActivity: data.activity,
      }),
      [a.setCurrentActivity]: (state, data) => ({
        ...state,
        currentActivity: data,
      }),
    },
    getDefaultState()
  )
