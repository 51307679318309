import client from "../../../apolloClient";
import { gql } from "apollo-boost";
import obj2arg from "graphql-obj2arg";

export function GetFilteredHomeAnnouncements(payload) {

  return client.query({
    query: gql`{
      GetFilteredHomeAnnouncements(${obj2arg(payload, {
        noOuterBraces: true
      })}){
              ${gwcAnnouncementMinimal}
            }
          }`
  });
}

export const getCountryAttributes = () => {
  return `
      id
      abbreviation
      name
      eligibleForInternationalApplication
      locale
    `;
};

export const gwcAnnouncementMinimal = `
  id
  heading
  subheading
  body
  ctaLabel
  ctaUrl
  dismissCtaLabel
  permissionLevel
  createdAt
  archivedAt
  countryId
  country {
    ${getCountryAttributes()}
  }
  authorId
  curriculumId
  maxAge
  minAge
  programTypeId
`;
