import React from "react";

import { createMarkup } from "../../../utils/generic";

export default class ContentItem extends React.PureComponent {
  render() {
    const { id, title, content } = this.props;
    return (
      <div className="content-item">
        <div id={id} className="title">{title}</div>
        <div className="rich-text" dangerouslySetInnerHTML={createMarkup(content)}></div>
      </div>
    );
  }
}
