import React, { useState, useEffect, useRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import AnnouncementCard from "./AnnouncementCards/AnnouncementCard";
import { NavLink } from "react-router-dom";
import AddAnnouncementCard from "./AnnouncementCards/AddAnnouncement";
import CustomButton from "../../components/customButton/customButton";
import { Box, Card, CardContent } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { ConfirmationModal } from "../../components/modals/announcement/confirmationModal";
import PageTitle from "../../components/page-title/pageTitle";
import { PageLeaveModal } from '../../components/modals/pageLeaveWarning';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto 10px",
    width: "95%",
    maxWidth: 1200
  },
  headerContainer: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 15
  },
  card: {
    marginTop: 50,
    marginBottom: 50,
  },
  messageForm: {
    height: 0,
    marginBottom: 0,
    minHeight: 0,
    opacity: 0,
    padding: "0 25px",
  },
  submitButton: {
    height: 42,
  },
  redirectLink: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  preloadContainer: {
    maxWidth: 750,
    minWidth: "60%",
    margin: "15px auto 10px",
    color: theme.palette.primary.main,
    borderRadius: "3px",
    boxShadow: "none",
    boxSizing: "border-box",
  },
  preloadFirstLine: {
    float: "left",
    width: "calc(100% - 50px)",
  },
  preloadSecondLine: {
    float: "left",
    marginTop: 5,
    width: "calc(100% - 50px)",
  },
  preloadContent: {
    float: "left",
    marginTop: 15,
    width: "calc(100% - 50px)",
  },
  preloadHeader: {
    marginBottom: 6,
  },
  preloadFooter: {
    float: "left",
    marginTop: 15,
    height: 40,
    marginBottom: 20,
    width: "100%",
  },
  preloadLogo: {
    float: "left",
    marginRight: 10,
    width: 40,
    height: 40,
  }
}));

const AnnouncementContent = ({
  userId,
  posts,
  addPost,
  removeMessage,
  reportMessage,
  editItem,
  addComment,
  isViewMode,
  labels,
  isFacilitator,
  isLoading,
  push,
  studentView,
  userRoleLabels,
  program,
  programTypeDetails,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [postMessageForm, setPostMessageForm] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [confirmationObj, setConfirmationObj] = useState(null);
  const [isEscapeConfirmDialog, setEscapeConfirmDialog] = useState(false);
  const [isDataChanged, setDataChanged] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);

  const funcRef = useRef(null);
  const unblockRef = useRef(null);

  useEffect(() => {
    unblockRef.current = history.block((location) => {
      setNextLocation(location);

      location.pathname === '/messages' && !isDataChanged ?
        setEscapeConfirmDialog(false) :
        setEscapeConfirmDialog(true);

      return !isDataChanged;
    });

    return () => {
      unblockRef.current && unblockRef.current();
  };
  }, [isDataChanged, isEscapeConfirmDialog]);

  const removeMessageConfirmation = (id, isComment) => {
    setConfirmationObj({ id, isComment });
  };

  const confirmRemoving = () => {
    const { id, isComment } = confirmationObj;
    removeMessage(id, isComment);
    setConfirmationObj(null);
  };

  const onDeclineRemoving = () => {
    setConfirmationObj(null);
  };

  const renderWelcomePost = () => {
    return {
      id: -1,
      createdAt: new Date("01/01/2018"),
      modifiedAt: new Date("01/01/2018"),
      comments: [],
      body: labels.welcomePostContent,
      owner: {
        id: -1,
        firstName: labels.welcomePostAuthor,
        lastName: "",
        profilePicture: labels.welcomePostAvatar.src,
      },
      blocked: true,
    };
  };

  const isDefaultExist = posts && posts.find((e) => e.id === -1);
  if (labels && posts && !isDefaultExist && !isViewMode) {
    const defaultPost = renderWelcomePost();
    posts.push(defaultPost);
  }

  useEffect(() => {
    if (showLoader && !isLoading) {
      setShowLoader(false);
    }
  }, [showLoader, setShowLoader, isLoading]);

  return (
    <div className={classes.root}>
      <Box className={classes.headerContainer}>
        <PageTitle title="Messages" isLoading={isLoading} />
        {addPost && (
          <div>
            <CustomButton
              className={classes.submitButton}
              mode={"primary"}
              onClick={() => {
                setPostMessageForm(true);
              }}
              label={"Post Message"}
              sel="post-message"
              disabled={postMessageForm}
            />
          </div>
        )}
        {isViewMode && (
          <div>
            <NavLink className={classes.redirectLink} to={`/messages`}>
              <CustomButton
                mode={"primary"}
                style={{ height: 42 }}
                label={"View All Posts"}
                sel="viewAllPosts-button"
              />
            </NavLink>
          </div>
        )}
      </Box>

      {postMessageForm && addPost && (
        <AddAnnouncementCard
          onSave={(post) => {
            addPost(post);
            setShowLoader(true);
            setPostMessageForm(false) ;
            setDataChanged(false);
          }}
          onClose={() => {
            setNextLocation();
            unblockRef.current = null;
            isDataChanged ? setEscapeConfirmDialog(true) : setPostMessageForm(false) 
          }}
          showEmailAll={isFacilitator && !studentView}
          setDataChanged={setDataChanged}
        />
      )}

      {isLoading && showLoader && (
        <Card className={classes.preloadContainer}>
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.preloadHeader}
          />
          <CardContent>
            <React.Fragment>
              <Skeleton
                animation="wave"
                variant="circle"
                className={classes.preloadLogo}
              />
              <Skeleton
                animation="wave"
                variant="rect"
                className={classes.preloadFirstLine}
              />
              <Skeleton
                animation="wave"
                variant="rect"
                className={classes.preloadSecondLine}
              />
              <Skeleton
                animation="wave"
                variant="rect"
                className={classes.preloadContent}
              />
              <Skeleton
                animation="wave"
                variant="rect"
                className={classes.preloadFooter}
              />
            </React.Fragment>
          </CardContent>
        </Card>
      )}

      {posts &&
        labels &&
        posts.map((el) => (
          <AnnouncementCard
            key={el.id}
            className={classes.card}
            post={el}
            removeMessage={removeMessageConfirmation}
            reportMessage={reportMessage}
            editItem={editItem}
            addComment={addComment}
            userId={userId}
            isViewMode={isViewMode}
            labels={labels}
            isLoading={isLoading}
            isFacilitator={isFacilitator}
            push={push}
            studentView={studentView}
            isDataChanged={isDataChanged}
            setEscapeConfirmDialog={setEscapeConfirmDialog}
            setDataChanged={setDataChanged}
            funcRef={funcRef}
            userRoleLabels={userRoleLabels}
            program={program}
            programTypeDetails={programTypeDetails}
          />
        ))}
      <ConfirmationModal
        isOpen={!!confirmationObj}
        onClose={onDeclineRemoving}
        onSave={confirmRemoving}
      />
      <PageLeaveModal
        onCancel={() => { setEscapeConfirmDialog(false) }}
        onConfirm={()=> { 
          setDataChanged(false);
          typeof funcRef.current === 'function' && funcRef.current();
          setEscapeConfirmDialog(false)
          setPostMessageForm(false);
          if (unblockRef && nextLocation) {
            unblockRef.current();
            history.push(nextLocation);
            return;
          }
        }}
        open={isEscapeConfirmDialog}
      />
    </div>
  );
};

export default AnnouncementContent;
