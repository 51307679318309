import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import { enrollmentTrackingEvent } from "../constants";
import * as colors from "../../../theme/colors";
import { programTypes } from "../constants";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    marginTop: 15,
    marginBottom: 25,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    fontSize: 14,
    fontWeight: 700,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 14,
  },
  titleNote: {
    fontSize: 14,
  },
  titleContainer: {
    textAlign: "center",
  },
  subTitle: {
    color: colors.lightGrey,
    fontSize: 14,
    textTransform: "uppercase",
    marginBottom: 5,
    marginTop: 11,
    fontWeight: 500,
  },
  item: {
    fontSize: 14,
    lineHeight: 1.5,
    wordWrap: "break-word",
    fontWeight: 400,
    color: colors.darkThemeBlueGray,
  },
  divider: {
    marginBottom: "15px",
    marginTop: "15px",
    backgroundColor: "black",
  },
  infoGrid: {
    margin: "auto",
  },
  container: {
    width: "100%",
    display: "flex",
  },
  submitContainer: {
    marginTop: 62,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  grid: {
    display: "flex",
    fontSize: 14,
  },
  submitButton: {
    maxWidth: 350,
    cursor: "pointer",
  },
  clubImage: {
    maxWidth: "100%",
  },
  navLinkBox: {
    marginTop: 10,
    textAlign: "center",
    fontSize: 12,
  },
  textContainer: {
    textAlign: "center",
  },
  navLink: {
    cursor: "pointer",
    fontSize: 12,
    "&:focus": {
      outline: `2px dotted ${colors.grey400}`,
    },
  },
  membersContainer: {
    display: "flex",
    alignItems: "center",
  },
  membersCountLabel: {
    width: "fit-content",
    margin: "auto",
    color: colors.grey600,
    fontSize: 16,
    marginTop: 15,
  },
  submitBtn: {
    width: 250,
    height: 42,
    "&:focus": {
      outline: `2px auto ${colors.grey400}`,
    },
  },
}));

const SecondStep = ({
  program,
  type,
  createTrackingLog,
  isLoading,
  renderChildHeader,
  childID,
}) => {
  const isFacilitatorsExist =
    program && program.facilitators && program.facilitators.length > 0;
  const sortedFacilitators = program && program.facilitators
    ? program.facilitators.sort((a, b) => {
      const aFirstName = a.user && a.user.firstName ? a.user.firstName.toLowerCase(): null;
      const bFirstName = b.user && b.user.firstName ? b.user.firstName.toLowerCase(): null;
      return (aFirstName > bFirstName ? 1 : -1);
    })
    : [];

  React.useEffect(() => {
    return () => {
      if (!window.location.pathname.includes("join") && !isLoading) {
        createTrackingLog({
          event: enrollmentTrackingEvent.ENROLLMENT_SECOND_STEP_LEAVE,
          data: JSON.stringify({
            programCode: program.code,
            programType: program.programType,
          }),
        });
      }
    };
  }, []);

  const classes = useStyles();

  return (
    <React.Fragment>
      <Box className={classes.titleContainer}>
        <Typography className={classes.titleNote}>
          Does this look right?
        </Typography>
      </Box>
      {childID && renderChildHeader()}
      <Container className={classes.container}>
        <Grid content xs={12} className={classes.grid}>
          {type === programTypes.COMMUNITY_PARTNERSHIP
          ? (
            <Grid item xs={12} className={classes.titleContainer}>
              <Typography className={classes.title}>{program?.name}</Typography>
            </Grid>
          ) : (
            <React.Fragment>
              <Grid item xs={12} sm={12} className={classes.infoGrid}>
                <Box className={classes.login}>
                  <Typography className={classes.subTitle}>
                    {type === programTypes.CLUB && "Club"}{" "}
                    {type === programTypes.COLLEGE_LOOP && "College Loop"}
                  </Typography>
                  <Typography className={classes.item} sel="prog-name">
                    {program?.name}
                  </Typography>
                </Box>
                {isFacilitatorsExist && (
                  <Box className={classes.login}>
                    <Typography className={classes.subTitle}>
                      {type === programTypes.CLUB && "Facilitators"}
                      {type === programTypes.COLLEGE_LOOP && "Presidents"}
                    </Typography>
                    {sortedFacilitators.map((e) => (
                      <Typography className={classes.item}>
                        {`${e?.user?.firstName} ${e?.user?.lastName}`}
                      </Typography>
                    ))}
                  </Box>
                )}
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default SecondStep;
