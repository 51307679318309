import React from 'react';
import { gql } from "apollo-boost";

import client from '../apolloClient';
import { getModulesQuery } from '../api/graphql/types/modules';

const withPageLabels = (data, WrappedComponent) => {

  const WithPageLabels = (props) => {
    const { slug } = data;
    const [pageLabels, setPageLabels] = React.useState(null);

    React.useEffect(() => {
      if (!slug) return;
      let mounted = true;

      client
        .query({
          query: gql`{
            PageQuery(
              slug: "${slug}"
            ) {
              id
              slug
              layout
              modules {
                id
                type
                ${getModulesQuery()}
              }
            }
          }`
        })
        .then((result) => {
          if (mounted) {
            const { data: { PageQuery } } = result;
            setPageLabels(PageQuery);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      return () => mounted = false;
    }, [slug]);

    return (<WrappedComponent {...data} pageLabels={pageLabels} {...props} />);
  };

  return WithPageLabels;
};

export default withPageLabels;
