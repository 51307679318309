import React from "react";

import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from '@material-ui/core/FormLabel';
import { StyledFormHelper } from '../../components/loginSignup/customFormHelper';
import { withStyles } from '@material-ui/core/styles';

const GreenRadio = withStyles({
  root: {
    color: '#3DB390',
    '&$checked': {
      color: '#3DB390',
    }
  },
  checked: { }
})((props) => <Radio {...props} color="default" />);

const RadioButton = ({
  field,
  form: { touched, errors },
  name,
  options,
  errorIsHtml,
  className,
  label,
  shrink,
  ref,
  required,
  color,
  ...props
}) => {
  const fieldName = name || field.name;
  
  let error = errors[fieldName];
  let isTouched = touched[fieldName];


  return (
    <React.Fragment>
      <FormControl
        variant="filled"
        fullWidth
        id="radio-button-group"
        aria-required={required}
      >
        {!!label && <FormLabel id={`label-${fieldName}`} required={required} className={className} ref={ref} shrink={shrink}>{label}</FormLabel>}
        <RadioGroup {...field} {...props} name={fieldName} role="radiogroup" aria-required={required} aria-labelledby="radio-button-group">
          {options.map((option, index) => (
            color ? <FormControlLabel aria-required={required} value={option} control={<Radio style={{ color: color }} inputProps={{ "aria-required": required, "aria-invalid": false, required }} color="primary" />} label={option} key={index} /> :
            <FormControlLabel aria-required={required} value={option} control={<GreenRadio inputProps={{ "aria-required": required, "aria-invalid": false, required }} color="primary" />} label={option} key={index} />
          ))}
        </RadioGroup>

        {error && isTouched &&
          (errorIsHtml
            ? <StyledFormHelper helperText={errors[fieldName]} isError isHtml />
            : "")
        }
      </FormControl>
    </React.Fragment>
  );
};

export default RadioButton;
