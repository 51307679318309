import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core/";
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";

import * as colors from '../../theme/colors'
import { createMarkup } from "../../utils/generic";
import { handleKeyPress } from '../../utils/accessibility';

const useStyles = makeStyles({
  card: {
    width: 300,
    cursor: "pointer",
    paddingRight: 10,
    margin: "20px 25px 20px 0px",
  },
  cardTitle: {
    fontWeight: 700,
    fontSize: 18,
    color: colors.dartThemeBlueGray,
  },
  cardHeader: {
    padding: 0,
    marginLeft: 20,
  },
  cardContent: {
    padding: "0px 20px 20px 20px",
    fontSize: 14,
    color: colors.dartThemeBlueGray,
    "& a": {
      color: colors.darkThemeGreen,
      textDecoration: "underline",
    },
    "& li": {
      position: 'relative',
      listStyle: 'none',
      paddingBottom: 5,
    },
    "& ul > li:before": {
      content: "'*'",
      position: 'absolute',
      top: '-1px',
      left: '-30px',
      '-moz-box-sizing': 'border-box',
      '-webkit-box-sizing': 'border-box',
      boxSizing: 'border-box',
      width: '2em',
      'font-size': 20,
      textAlign: 'center',
    },
  },
  imageContainer: {
    width: 100,
    height: 100,
    marginLeft: 15,
    marginTop: 15,
  },
});

const LandingPageCard = ({ section, onClick, icon, name }) => {
  const classes = useStyles();

  return (
    <Card
      tabIndex="0"
      onKeyPress={handleKeyPress(() => onClick())}
      onClick={() => onClick()}
      className={classes.card}
      role="region" aria-labelledby={`section-heading-${name} section-content-${name}`}
      >
        <CardMedia className={classes.imageContainer} image={icon.src} />
        <CardHeader
          title={
            <Typography id={`section-heading-${name}`} className={classes.cardTitle} >{section.heading}</Typography>
          }
          className={classes.cardHeader}
        />
        <CardContent className={classes.cardContent}>
          <Box id={`section-content-${name}`} dangerouslySetInnerHTML={createMarkup(section.content)} />
        </CardContent>
    </Card>
  );
};

export default LandingPageCard;
