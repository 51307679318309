import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import editorStyles from '../styles/editorStyles';

const CustomLink = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} className={props.classes.link} rel="noopener noreferrer" target="_blank">
      {props.children}
    </a>
  );
};

CustomLink.defaultProps = {
  children: null,
};

export default withStyles(editorStyles)(CustomLink);
