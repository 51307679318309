import React from "react";

import { Field } from "formik";
import * as moment from 'moment';

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import DateInput from "../../../components/date-input/DateInput";
import Selector from "../../../components/selector/Selector";
import TextInput from "../../../components/TextInput";
import { StyledFormHelper } from '../../../components/loginSignup/customFormHelper';
import { DOMESTIC_ID } from '../../../utils/countriesDict';
import { validNameRegex } from '../../../utils/userUtils';
import { filterFieldsByKey } from '../../../utils/generic';
import {DATE_FORMAT_TIMEZONE} from "../../../utils/constants";

const useStyles = makeStyles(() => ({
  marginTop20: {
    marginTop: 20,
  },
  childInfoHeader: {
    fontSize: 14,
    marginBottom: 20,
    display: "flex",
    justifyContent: "space-around",
  },
  childFormWrapper: {
    margin: 20,
  }
}));

const ChildForm = ({ childRegisteringContent, parentCountryId }) => {
  const classes = useStyles();
  let parentRelationsContent = childRegisteringContent.parentRelations;

  const validateName = (value) => {
    const validationErrors = filterFieldsByKey(
      childRegisteringContent.firstName
      .validationErrors,
      "messageCode",
      "message"
    );
    if (!value || !value.length) {
      return validationErrors.requiredCreatingChildField;
    }
    if (!validNameRegex.test(value)) {
      return validationErrors.invalidNameCharacters;
    }
  };

  const getGuardians = (content) => {
    return content.options.map((x, index) => {
      return { value: index + 1, label: x.label };
    });
  };

  const validateRelation = (value) => {
    const validationErrors = filterFieldsByKey(
      parentRelationsContent.validationErrors,
      "messageCode",
      "message"
    );
    if (!value || value.length === 0) {
      return validationErrors.requiredCreatingChildField;
    }
  };

  const validateBirthDate = (value) => {
    if (value >= new Date().toISOString()) {
      return 'Date of Birth cannot be the future date';
    }
    const minDate = moment().subtract(100, 'years').format();
    const birthDateFormatted = moment(value).format();
    if (!value || value === '' || moment(minDate).isAfter(birthDateFormatted)) {
      return 'Please check the format of your date of birth';
    }
  }
  return (
    <>
      <Field
        name="firstName"
        component={TextInput}
        label={`${childRegisteringContent.firstName.labelPrompt}`}
        validate={validateName}
        InputLabelProps={{ shrink: true }}
        required
        errorIsHtml={true}
      />
      <Field
        name="lastName"
        component={TextInput}
        label={childRegisteringContent.lastName.labelPrompt}
        className={classes.marginTop20}
        validate={validateName}
        InputLabelProps={{ shrink: true }}
        required
        errorIsHtml={true}
      />
      <Field
        name="birthDate"
        component={DateInput}
        label={childRegisteringContent.birthDate.labelPrompt}
        className={classes.marginTop20}
        domesticId={DOMESTIC_ID}
        countryId={parentCountryId}
        validate={validateBirthDate}
        displayDateFormat={true}
        required
        dateFormat={DATE_FORMAT_TIMEZONE}
      />
      <Field
        name="kinshipId"
        component={Selector}
        label={parentRelationsContent.labelPrompt}
        className={classes.marginTop20}
        options={getGuardians(parentRelationsContent)}
        validate={validateRelation}
        required
        showHelperText={true}
      />
      <StyledFormHelper helperText={parentRelationsContent.accessibleInstructions} />
    </>
  );
};

export const Child = ({ footer, childRegisteringContent, parentCountryId }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.childFormWrapper}>
        <Typography className={classes.childInfoHeader}>
          Enter your child’s user information.
        </Typography>
        <ChildForm
          childRegisteringContent={childRegisteringContent}
          parentCountryId={parentCountryId}
        />
      </Box>
      {footer}
    </>
  );
};
