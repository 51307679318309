import { createAction } from 'redux-act';

export const getMyAnswerGroupsByProgram = createAction();
export const getUserAnswerGroupsByProgram = createAction();
export const getAnswerGroupsError = createAction();
export const recievedUserAnswerGroups = createAction();

export const getAnswerGroupsByProgram = createAction();
export const receivedAllAnswerGroups = createAction();

export const updateMyAnswerGroups = createAction();
export const updateAnswerGroupsStatus = createAction();
export const receivedUpdatedAnswerGroup = createAction();

export const deleteAnswerGroupComment = createAction();
export const receivedDeletedAnswerGroupComment = createAction();
export const deleteAnswerGroupCommentError = createAction();

export const addAnswerGroupComment = createAction();
export const receiveAddedAnswerGroupComment = createAction();
export const addAnswerGroupCommentError = createAction();

export const updateAnswerGroupComment = createAction();
export const receiveUpdatedAnswerGroupComment = createAction();
export const updateAnswerGroupCommentError = createAction();