import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";

import Grid from "@material-ui/core/Grid";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import * as colors from "../../../theme/colors";
import { handleKeyPress } from '../../../utils/accessibility';
import { Typography } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {TooltipWrapper} from "../../../components/tooltip/tooltip";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "170px",
    width: "170px",
    margin: "0 10px 0 5px",
    display: "flex",
    fontFamily: "'Roboto Mono',monospace",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  labelButton: {
    backgroundColor: "transparent",
    color: colors.darkThemeGreen,
    display: "inline-flex",
    fontFamily: "'Roboto',monospace",
    fontSize: 16,
    minWidth: "max-content",
    fontWeight: "bold",
  },
  labelActive: {
    color: colors.darkThemeGreen,
  },
  item: {
    backgroundColor: "white",
    fontFamily: "'Roboto Mono',monospace",
    fontSize: 14,
    fontWeight: 500,
    "& .MuiTypography-body1": {
      color: colors.dartThemeBlueGray,
      fontFamily: "'Roboto Mono',monospace",
      fontSize: 14,
      fontWeight: 500,
    },
  },
  title: {
    backgroundColor: "white",
    fontFamily: "'Roboto Mono',monospace",
    fontSize: 16,
    margin: "0 10px",
    fontWeight: 500,
    "& .MuiTypography-body1": {
      color: colors.dartThemeBlueGray,
      fontFamily: "'Roboto Mono',monospace",
      fontSize: 14,
      fontWeight: 500,
    },
  },
  paperContainer: {
    zIndex: 11,
    backgroundColor: "#fff",
    borderRadius: 16,
    border: "1px solid #bbbbbf",
    alignItems: "center",
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      height: 100,
      maxHeight: 500,
      overflowY: "auto",
    },
    [theme.breakpoints.down('xs')]: {
      height: 150,
      maxHeight: 500,
      overflowY: "auto",
    },
    "& div": {
      borderRadius: "inherit",
      padding: 1,
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: colors.greenA300,
    },
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  tooltipIcon:
    {
      color: colors.greenA300,
      fontSize: 16,
      verticalAlign: "text-top"
    }
}));

const MultipleSectionsSelect = ({
  label,
  filters,
  items,
  onSelectChange,
  className,
  isLoading,
}) => {
  const classes = useStyles();

  const newItems = items.map((e) => {
    return {
      filterName: e.key,
      items: e.values
        .map((el) => {
          if (!!filters[el.filterName] && filters[el.filterName].includes(el.attribute)) {
            return el.attribute;
          }
        })
        .filter((e) => e),
    };
  });

  const [selected, setSelected] = React.useState(newItems || []);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  React.useEffect(() => {
    if (!items || !filters) {
      return;
    }

    const newItems = items.map((e) => {
      return {
        filterName: e.key,
        items: e.values
          .map((el) => {
            if (!!filters[el.filterName] && filters[el.filterName].includes(el.attribute)) {
              return el.attribute;
            }
          })
          .filter((e) => e),
      };
    });

    setSelected(newItems);
  }, [items, filters]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const isFilterHidden = (item, selected) => {
    let result = !!item.hideFilter && item.hideFilter;

    const getFilterField = (list, field) => {
      return list.filter(e => e.filterName === field);
    }

    if (!result) {
      if (!!item.dependsOn && getFilterField(selected, item.dependsOn).length === 1) {
        //Hide dependent fields if master-field is not selected
        result = !getFilterField(selected, item.dependsOn)[0]?.items?.length;
        if (result) return true;
      }

      if (!!item.showWhenCountrySelected && getFilterField(selected, "countries").length === 1) {
        //Hide if shown only for specific country
        result = getFilterField(selected, "countries")[0]?.items?.length > 1 || !getFilterField(selected, "countries")[0].items.includes(item.showWhenCountrySelected);
        if (result) return true;
      }

      if (!!item.hideFilterForClubs && item.hideFilterForClubs && getFilterField(selected, "programTypeIds").length === 1) {
        //Hide if it's hidden for clubs
        result = getFilterField(selected, "programTypeIds")[0].items.includes("-1");
        if (result) return true;
      }
    }

    return result;
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={clsx(classes.container, className)}
    >
      <Grid item xs={12}>
        <Button
          className={
            open
              ? clsx(classes.labelActive, classes.labelButton)
              : classes.labelButton
          }
          size="small"
          ref={anchorRef}
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <FilterListIcon />
          <span>{label}</span>
          {/* {open ? <ExpandLessIcon /> : <ExpandMoreIcon />} */}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          className={classes.paperContainer}
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {items.map((i, index) => {
                      if (isFilterHidden(i, selected)) {
                        return;
                      }

                      return (
                        <>
                          <Typography className={classes.title}>
                            {i.tooltip ? (
                              <TooltipWrapper title={i.tooltip}>
                                <Typography variant="div">
                                  {i.label} <HelpOutlineIcon className={classes.tooltipIcon}
                                />
                                </Typography>
                              </TooltipWrapper>
                            ) : i.label }
                          </Typography>
                          {i.values.map((el) => {
                            const isChecked =
                              selected[index] &&
                              selected[index].items.includes(el.attribute);

                            return (
                              <MenuItem
                                key={el.label}
                                value={el.label}
                                tabIndex={0}
                                aria-label={el.label}
                                className={classes.item}
                                disabled={isLoading || el.disabled}
                                onClick={(event) => {
                                  onSelectChange(
                                    el.filterName,
                                    el.attribute,
                                    i.resetFilters
                                  )(event);
                                }}
                              >
                                <Checkbox
                                  style={{ padding: 0 }}
                                  checked={isChecked}
                                  disabled={isLoading || el.disabled}
                                  onKeyDown={handleKeyPress((event) =>
                                    onSelectChange(
                                      el.filterName,
                                      el.attribute,
                                      i.resetFilters
                                    )(event))}
                                />
                                <ListItemText primary={el.label} />
                              </MenuItem>
                            );
                          })}
                        </>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};

export default MultipleSectionsSelect;
