import React, { useState, useEffect } from "react";

import { push as pushToUrl } from "connected-react-router";
import { useLocation } from "react-router-dom";
import * as calendarActions from "../../actions/calendar";

import * as colors from "../../theme/colors";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

import { connectTo } from "../../utils/generic";
import { createProject } from "../../api/galleryProjects";
import { useTitle } from "../../utils/pageTitles";
import { Roles } from "../../utils/permissions";
import { arrayToObjectNotForcedArrayValues } from "../../utils/generic";

import * as trackingActions from "../../actions/tracking";
import * as notificationActions from "../../actions/notification";
import * as projectGalleryActions from "../../actions/projectGallery";
import Footer from "../../containers/Footer/footer";

import {
  SUBMISSION_FORM_VIEW,
  SUBMISSION_FORM_LEFT_PAGE,
  SUBMISSION_FORM_SUBMIT_ATTEMPT,
  SUBMISSION_FORM_SUBMIT_SUCCESS,
  SUBMISSION_FORM_SUBMIT_ERROR,
} from "../../constants/trackingEvents";

import TextCustomButton from "../../components/text-button/text-button";

import ProjectForm from "./ProjectForm";

const useStyles = makeStyles((theme) => ({
  paperMainBox: {
    boxShadow: "0px 1px 4px 0px rgba(0,0,0,0.2)",
    [theme.breakpoints.up("md")]: {
      width: 500,
    },
  },
  container: {
    paddingTop: 20,
    paddingBottom: 20,
    maxWidth: 600,
    [theme.breakpoints.down("xs")]: {
      maxWidth: 370,
    },
    minHeight: "calc(100vh - 135px)",
  },
  form: {
    padding: 26,
    [theme.breakpoints.up("sm")]: {
      width: 500,
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 0",
    },
  },
  formSection: {
    paddingBottom: 20,
    paddingTop: 20,
  },
  successContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  submitButtonBox: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  backToGallery: {
    padding: "0px",
    color: colors.darkThemeGreen,
    "&:hover": {
      color: colors.grayTheme,
    },
    "&:focus": {
      outline: `2px auto ${colors.grey400}`,
      outlineOffset: 8,
    },
  },
  stepWrapper: {
    [theme.breakpoints.up("sm")]: {
      width: 500,
    },
    margin: "auto",
    "& .MuiStepper-root": {
      padding: "24px 0px",
    },
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  btnWrapper: {
    display: "flex",
  },
  title: {
    color: colors.grayTheme,
    fontWeight: 700,
    fontSize: 32,
    textTransform: "uppercase",
    lineHeight: 1.375,
  },
  backBtn: {
    "&:focus": {
      outline: `2px auto ${colors.grey400}`,
    },
  },
}));

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SubmitProjectGallery = (props) => {
  const {
    user,
    getProgramsAndMembersInfo,
    isLoadingMembersList,
    programs,
    contentful,
    createTrackingLog,
    history,
    push,
    showErrorMessage,
    projectLanguageTags,
    projectTypesTags,
    projectTopicTypeTags,
    getFiltersData,
  } = props;
  useTitle("submit-project");

  const classes = useStyles();

  const query = useQuery();
  const curriculumSet = query.get("curriculumSet");
  const code = query.get("code");
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [defaultProgramId, setDefaultProgramId] = useState(null);

  const labels = props.contentful.GlobalComponentQuery
    ? props.contentful.GlobalComponentQuery.projectGalleryLabels
    : null;

  const [languageTags, setLanguageTags] = useState([]);
  const [projectTypeTags, setProjectTypeTags] = useState([]);
  const [topicTags, setTopicTags] = useState([]);
  const [projectSent, setProjectSent] = useState(false);
  const [showDefaultProgramForStudent, setShowDefaultProgramForStudent] = useState(false);
  const [isAbleToNavigateBack, setIsAbleToNavigateBack] = useState(null);
  const [isWarningModalToOpen, setWarningModalToOpen] = useState(false);
  const [initialProjectValues, setInitialProjectValues] = useState(null);
  const [updatedProjectValues, setUpdatedProjectValues] = useState(null);

  useEffect(() => {
    if (!user) return;
    if (!projectTopicTypeTags.length || !projectTypesTags.length || !projectLanguageTags.length) {
      getFiltersData();
    }

    createTrackingLog({
      event: SUBMISSION_FORM_VIEW,
    });

    return () => {
      createTrackingLog({
        event: SUBMISSION_FORM_LEFT_PAGE,
      });
    };
  }, [user]);

  useEffect(() => {
    if (!projectTopicTypeTags.length || !projectTypesTags.length || !projectLanguageTags.length) return;

    setTopicTags(projectTopicTypeTags);
    setLanguageTags(projectLanguageTags);
    setProjectTypeTags(projectTypesTags);
  }, [projectTopicTypeTags, projectTypesTags, projectLanguageTags]);

  useEffect(() => {
    if (!contentful.GlobalComponentQuery) {
      return;
    }
    const {
      hqConfig: { defaultProgram },
      programTypes,
    } = contentful;
    setDefaultProgramId(defaultProgram);

    const programTypeDetailsById = arrayToObjectNotForcedArrayValues(programTypes, "id");
    const showDefaultProgramForStudent = user && user.roles.filter(role => !!role.program && role.program?.id != defaultProgram).every(role => {
      return role.roleId === Roles.Student && programTypeDetailsById[role.program?.programTypeId]?.showDefaultProgram;
    });
    setShowDefaultProgramForStudent(showDefaultProgramForStudent);

    const userRoles = user ? user.roles.filter(
      (role) =>
        role.programId &&
        role.programId !== parseInt(defaultProgram) &&
        !!role.program
    ) : [];

    const latestOpenProgram = userRoles
      .filter((role) => role.program.active && !role.program.completed)
      .find((e) => e.current);

    if (!!latestOpenProgram) {
      getProgramsAndMembersInfo({
        userProgramIds: [latestOpenProgram.programId],
      });
      setSelectedProgram(latestOpenProgram.programId);
    } else {
       if (!userRoles.length) {
        setSelectedProgram(parseInt(defaultProgram));
       } else {
        setSelectedProgram(userRoles.length === 1 ? userRoles[0].programId : " ");
       }
    }
  }, [contentful]);

  useEffect(() => {
    if (projectSent) window.scrollTo(0, 0);
  }, [projectSent]);

  useEffect(() => {
    if (isAbleToNavigateBack) {
      navigateBack();
    }
  }, [isAbleToNavigateBack]);

  if (!labels) return null;

  const navigateBack = () => {
    if (
      !!history.location.state &&
      !!history.location.state.prevPath
    ) {
      push(history.location.state.prevPath);
    } else {
      push("/project-gallery");
    }
  };

  const updateIsAbleToNavigateBack = (value) => {
    setIsAbleToNavigateBack(value);
    if (!isAbleToNavigateBack) {
      setWarningModalToOpen(false);
    }
  }

  const updateInitialProjectValues = (values) => {
    setInitialProjectValues({ ...values, images: [] });
  }

  const updateProjectValues = (values) => {
    setUpdatedProjectValues(values);
  }

  const hasProjectDataChanged = () => {
    if (!updatedProjectValues) return false;
    return JSON.stringify(initialProjectValues) !== JSON.stringify(updatedProjectValues);
  };

  const onSubmit = (galleryProject, projectImages, tags, teamMembers) => {
    const images = projectImages.map((imgUrl, index) => ({
      url: imgUrl,
      isPrimary: index === 0,
    }));
    const tracking = {
      ...galleryProject,
      tags,
      images,
    };

    createTrackingLog({
      event: SUBMISSION_FORM_SUBMIT_ATTEMPT,
      data: JSON.stringify(tracking),
    });

    createProject({ project: galleryProject, images, tags, teamMembers })
      .then(({ data }) => {
        if (data && !!data.id) {
          createTrackingLog({
            event: SUBMISSION_FORM_SUBMIT_SUCCESS,
            data: JSON.stringify({ id: data.id, ...tracking }),
          });
        } else {
          createTrackingLog({
            event: SUBMISSION_FORM_SUBMIT_ERROR,
            data: JSON.stringify(tracking),
          });
        }
      })
      .catch(() => {
        createTrackingLog({
          event: SUBMISSION_FORM_SUBMIT_ERROR,
          data: JSON.stringify(tracking),
        });
      });
  };

  const prevPath = !!history.location.state?.prevPath ? history.location.state.prevPath : '/project-gallery';
  const previousHistoryLabel = (prevPath) => {
    switch (true) {
      case (/\progress/).test(prevPath):
        return 'progress';
      case (/\profile/).test(prevPath):
        return 'profile';
      case (/\project-gallery/).test(prevPath):
        return 'project gallery';
    };
  };

  return (
    <>
      <Container className={classes.container}>
        {!projectSent && !!selectedProgram && (
          <>
            <TextCustomButton
              className={classes.backToGallery}
              mode="secondary"
              startIcon={<KeyboardArrowLeftIcon />}
              onClick={() => {
                const projectDataChanges = hasProjectDataChanged();
                if (projectDataChanges) {
                  setWarningModalToOpen(true);
                  return;
                }
                navigateBack();
              }}
              label={
                !!history.location.state && !!history.location.state.label
                  ? `Back to ${previousHistoryLabel(prevPath)}`
                  : "Back"
              }
            />
            <Typography className={classes.title}>Submit Your Project</Typography>

            <Paper className={classes.paperMainBox}>
              <ProjectForm
                code={code}
                curriculumSet={curriculumSet}
                user={user}
                getProgramsAndMembersInfo={getProgramsAndMembersInfo}
                push={props.push}
                isLoadingMembersList={isLoadingMembersList}
                programs={programs}
                contentful={contentful}
                createTrackingLog={createTrackingLog}
                projectTypeTags={projectTypeTags}
                languageTags={languageTags}
                topicTags={topicTags}
                setProjectSent={setProjectSent}
                onSubmit={onSubmit}
                defaultProgramId={defaultProgramId}
                selectedProgram={selectedProgram}
                showErrorMessage={showErrorMessage}
                showDefaultProgramForStudent={showDefaultProgramForStudent}
                setInitialProjectValues={updateInitialProjectValues}
                setUpdatedProjectValues={updateProjectValues}
                setIsAbleToNavigateBack={updateIsAbleToNavigateBack}
                isWarningModalToOpen={isWarningModalToOpen}
              />
            </Paper>
          </>
        )}
        {projectSent && (
          <Container className={classes.successContainer}>
            <Typography
              align="center"
              variant="subheading"
              sel="thanks-for-submission"
            >
              Thanks for your submission! You’ll receive an email when your
              project has been reviewed and published to the Project Gallery.
            </Typography>
            <TextCustomButton
              mode="secondary"
              className={classes.backBtn}
              startIcon={<KeyboardArrowLeftIcon />}
              onClick={() => {
                if (
                  !!history.location.state &&
                  !!history.location.state.prevPath
                ) {
                  props.push(history.location.state.prevPath);
                } else {
                  props.push("/project-gallery");
                }
              }}
              label={
                !!history.location.state && !!history.location.state.label
                  ? `Back to ${previousHistoryLabel(prevPath)}`
                  : "Back"
              }
            />
          </Container>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default connectTo(
  (state) => ({
    user: state.auth.user,
    contentful: state.contentful,
    ...state.calendar,
    ...state.projectGallery,
  }),
  {
    ...trackingActions,
    ...calendarActions,
    ...notificationActions,
    ...projectGalleryActions,
    push: pushToUrl,
  },
  SubmitProjectGallery
);
