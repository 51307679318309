import createStyles from '@material-ui/core/styles/createStyles';
import * as colors from "../../../theme/colors";

export default (theme) =>
  createStyles({
    editor: {
      width: '100%',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '0px',
      transition: 'box-shadow 750ms',
      '& .public-DraftStyleDefault-ol': {
        marginLeft: '16px',
      },
      '& .public-DraftEditorPlaceholder-root': {
        paddingLeft: 15,
      },
      '& .public-DraftEditorPlaceholder-hasFocus': {
        display: 'none',
      },
    },
    commentInput: {
      maxWidth: '610px',
      height: '56px',
    },
    zIndex9: {
      zIndex: 9,
    },
    error: {
      boxShadow: '0px 0px 0px 2px #f44336',
    },
    editorIcon: {
      borderRadius: '0%',
      [theme.breakpoints.down('sm')]: {
        padding: '5px 7px',
      },
    },
    alignRight: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    blockIcon: {
      padding: '12px',
      minWidth: 'auto',
      borderRadius: '0%',
      [theme.breakpoints.down('sm')]: {
        padding: '5px 7px',
      },
      color: 'rgba(0, 0, 0, 0.54)',
    },
    blockIconText: {
      display: 'inline-block',
    },
    enabled: {
      borderRadius: '0%',
      backgroundColor: 'rgba(0, 0, 0, 0.17) !important',
      [theme.breakpoints.down('sm')]: {
        padding: '5px 7px',
      },
    },
    hidePlaceHolder: {
      '& .public-DraftEditorPlaceholder-root': {
        display: 'none',
      },
    },
    link: {
      color: colors.darkThemeGreen,
      textDecoration: 'none',
      cursor: 'pointer',
    },
    linkCard: {
      maxWidth: '300px',
    },
    linkCardContent: {
      paddingBottom: '16px !important',
    },
    editorToolbar: {
      minHeight: '48px',
      padding: '0 0 0 5px',
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
    toolbarLink: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      verticalAlign: 'middle',
      maxWidth: '250px',
      overflow: 'hidden',
    },
    inlineBlock: {
      display: 'inline-block',
    },
    removeScroll: {
      '& .DraftEditor-editorContainer > div': {
        maxHeight: 'none !important',
      },
    },
    editorRoot: {
      maxWidth: '720px',
      '& .DraftEditor-root': {
        borderTop: "1px solid #ddd",
      },
      '& .DraftEditor-editorContainer': {
        padding: '0 15px',
      },
      '& .DraftEditor-editorContainer > div': {
        fontSize: 14,
        margin: '10px',
        marginLeft: '0px',
        marginRight: '0px',
        minHeight: '50px',
        maxHeight: '150px',
        color: '#172b4d',
        lineHeight: 'normal',
        fontFamily: "'Roboto', sans-serif",
        overflowY: 'auto',
        border: 'none',
        padding: '0px',
      },
      '& .public-DraftEditorPlaceholder-root': {
        paddingTop: 10,
      },
      '& .public-DraftStyleDefault-block': {
        whiteSpace: 'pre-line !important',
        paddingTop: 5,
        paddingBottom: 5,
      },
      '& .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before': {
        top: 5.5,
      }
    },
    editorCommentRoot: {
      '& .DraftEditor-root': {
        height: '56px',
      },
      '& .DraftEditor-editorContainer': {
        padding: '0 15px',
        height: '56px',
      },
      '& .DraftEditor-editorContainer > div': {
        fontSize: 14,
        marginTop: '20px',
        marginLeft: '0px',
        marginRight: '0px',
        height: '35px',
        width: '100%',
        color: '#172b4d',
        lineHeight: 'normal',
        fontFamily: "'Roboto', sans-serif",
        overflowY: 'auto',
        border: 'none',
        padding: '0px',
      },
    },
    opentTextGreenBorder: {
      border: `1px solid ${colors.lightThemeGreen} !important`,
      '& .DraftEditor-root': {
        borderTop: `1px solid ${colors.lightThemeGreen} !important`,
      },
    },
    opentTextEditor: {
      '& .DraftEditor-root': {
        borderTop: "1px solid #ddd",
      },
      '& .DraftEditor-editorContainer': {
        padding: '0 15px',
      },
      '& .DraftEditor-editorContainer > div': {
        fontSize: 18,
        margin: '10px',
        marginLeft: '0px',
        marginRight: '0px',
        minHeight: '50px',
        color: '#172b4d',
        lineHeight: 'normal',
        fontFamily: "'Roboto', sans-serif",
        overflowY: 'auto',
        border: 'none',
        padding: '0px',
      },
      '& .public-DraftEditorPlaceholder-root': {
        paddingTop: 10,
        fontSize: 16,
      },
      '& .public-DraftStyleDefault-block': {
        whiteSpace: 'pre-line !important',
        paddingTop: 5,
        paddingBottom: 5,
      },
      '& .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before': {
        top: 5.5,
      }
    },
    openTextInput: {
      width: '100%',
      minHeight: 154,
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '0px',
      transition: 'box-shadow 750ms',
      '& .public-DraftStyleDefault-ol': {
        marginLeft: 16,
      },
      '& .public-DraftEditorPlaceholder-root': {
        paddingLeft: 15,
      },
      '& .public-DraftEditorPlaceholder-hasFocus': {
        display: 'none',
      },
      '& .public-DraftStyleDefault-ul': {
        paddingLeft: 0,
      },
    },
});
