import React, { useEffect, useState } from "react";
import { push as pushToRoute } from "connected-react-router";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import * as actions from "../../actions/changePassword";
import * as certificatesApi from "../../api/certificates";

import Footer from "../Footer/footer";
import CustomButton from "../../components/customButton/customButton";

import { connectTo } from "../../utils/generic";
import { useTitle } from "../../utils/pageTitles";

const useStyles = makeStyles(() => ({
  gradientBox: {
    height: "100%",
  },
  content: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0 -85px",
    backgroundSize: "100% 508px",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 123px)",
  },
  card: {
    marginTop: 15,
  },
  cardContent: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: "0px !important",
  },
  imageBox: {
    maxWidth: "95%",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: 20,
  },
  boxWrapper: {
    width: "fit-content",
  }
}));

const VerifyCertificate = (props) => {
  useTitle("verify-certificate");
  const classes = useStyles();

  const {
    match: {
      params: { token },
    },
    push,
    history,
  } = props;

  const [imageUrl, setImageUrl] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const { url, userId } = await getImageUrl(token);
      setImageUrl(url);
      setUserId(userId);
    }
    fetchData();
  }, [token]);

  const getImageUrl = async (token) => {
    const { data } = await certificatesApi.getSignedUrlCerfificate({ title: token, format: "png" });
    return data;
  };

  return (
    <Box className={classes.gradientBox}>
      <Box className={classes.content}>
        <Box className={classes.boxWrapper} >
          {!!userId && <Box className={classes.btnContainer}>
            <CustomButton
              onClick={() => {
                if (
                  !!history.location.state &&
                  !!history.location.state.prevPath
                ) {
                  push(history.location.state.prevPath);
                } else {
                  push(`/profile/${userId}`);
                }
            }}>{
              !!history.location.state && !!history.location.state.label && history.location.state.label !== 'profile'
                ? `Back to ${history.location.state.label}`
                : "View User Profile"}
            </CustomButton>
          </Box>}
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              {!!imageUrl && <img className={classes.imageBox} src={imageUrl} alt="Content box" />}
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default connectTo(
  (state) => state,
  {
    ...actions,
    push: pushToRoute,
  },
  VerifyCertificate
);
