import * as moment from 'moment';
import { PROGRAM_TYPES } from './constants';

export const isFinishedHardClosureProgram = (programTypeDetails, endDate, hardClosureEndDate = null) => {
  if (!programTypeDetails?.hardClosureEnabled) return false;
  let programEndDate;
  if (programTypeDetails?.programType === PROGRAM_TYPES.SPP) {
    programEndDate = hardClosureEndDate || endDate || null;
  } else {
    programEndDate = hardClosureEndDate;
  }
  if (!programEndDate) return false;
  let currentDate = moment().utc();
  let endDateEST = moment(programEndDate).utc();
  return currentDate.isAfter(endDateEST, 'm');
}
