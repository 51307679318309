import React, { useEffect, useState } from 'react';
import { push as pushToRoute } from 'connected-react-router';
import { makeStyles } from '@material-ui/core/styles';
import qs from "query-string";

import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import { connectTo } from '../../utils/generic';
import * as userActivitiesInfoActions from '../../actions/userActivitiesInfo';
import withPageLabels from "../../hocs/withPageLabels";

import NavBar from "../HorizontalNavigation";
import Footer from "../../containers/Footer/footer";
import * as authActions from "../../actions/auth";
import * as ratingsActions from '../../actions/rating';
import * as trackingActions from "../../actions/tracking";
import { getUserProjectsByProgram as getUserProjectsByProgramAction, getIsUserProjectsByProgram as getIsUserProjectsByProgramAction } from '../../actions/projectGallery';
import UserProgressPage from "./Tabs/userProgress";
import UserProjects from './Tabs/userProjects';

import Certificates from './certificates/certificates';
import * as certificatesActions from "../../actions/certificates";

import * as openTextActions from "../../actions/openText";
import * as answerGroupActions from "../../actions/answerGroups"
import Answers from './answers/answers';
import { useTitle } from "../../utils/pageTitles";
import * as helpers from "./helpers";
import Roles from "../../utils/permissions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
  },
  contentBox: {
    width: "95%",
    margin: "auto",
  },
  main: {
    width: "100%",
    margin: "auto auto",
    height: "100%",
    minHeight: 'calc(100vh - 132px)',
    paddingTop: 50,
    [theme.breakpoints.down('md')]: {
      paddingTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 100,
    },
  },
}));

const tabs =  {
  answers: "Answers",
  activities: "Activities",
  certificates: "Certificates",
  projects: "Projects",
};

const UserProgress = ({
  auth: { currentRole, user },
  changeRole,
  curriculums,
  deleteMyActivityProgress,
  getUserActivitiesByProgram,
  match: {
    params: { clubCode, userId, tab, id },
  },
  history: {
    location: { search, state: previosState },
  },
  userProgressByProgram,
  rating: { programRatings },
  getRatingsByProgram,
  createTrackingLog,
  getUserProjectsByProgram,
  getIsUserProjectsByProgram,
  isUserProjects,
  isLoadingProjects,
  isLoadingUserActivitiesInfo,
  galleryProjects,
  push,
  certificates,
  loadUserCertificates,
  getMyAnswersByProgram,
  getCommentsByAnswer,
  createAnswer,
  openTextAnswers,
  pageLabels,
  contentful,
  studentView,
  getMyAnswerGroupsByProgram,
  answerGroups,
  getAnswerGroupsByProgram,
}) => {
  useTitle("progress-member");

  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(previosState?.activeTab || 'Activities');
  const [currentProgram, setCurrentProgram] = useState(null);
  const [userCertificate, setUserCertificate] = useState(null);
  const [showAnswers, setShowAnswers] = useState(false);
  const [editedAnswer, setEditedAnswer] = useState(null);
  const [labels, setLabels] = useState(null);

  useEffect(() => {
    if (!user) return;

    const { roles } = user;
    const role = roles.find((r) => r.program && r.program.code === clubCode); // searching for user role

    if (!role) return push("/home"); // redirecting if user doesn't have access to this program

    setCurrentProgram(role.program);

    const programId = role.program.id;

    if (currentRole.programId !== role.programId) {
      changeRole({ programId: role.programId });
    }

    loadUserCertificates({ userId, programId });
    getIsUserProjectsByProgram({ programId, userId });
    if (role.roleId === Roles.Student) {
      getMyAnswersByProgram({ programId });
      getMyAnswerGroupsByProgram({ programId });
    }
    if (role.roleId === Roles.Facilitator) {      
      getAnswerGroupsByProgram({ programId });
    }
  }, [user, userId]);


  useEffect(() => {
    if (!currentProgram) {
      return;
    }

    const userCertificate = certificates && !!certificates.userCertificates[userId] && !!certificates.userCertificates[userId].length
      ? certificates.userCertificates[userId]
      : null;

    setUserCertificate(userCertificate);
  }, [certificates, currentProgram]);

  useEffect(() => {
    const parsed = qs.parse(search);

    const setTab = !!parsed?.tab
      ? parsed.tab
      : !!tab
        ? tab
        : null;

    const setAnswerId = !!parsed?.editedAnswer
      ? parsed.editedAnswer
      : !!id
        ? id
        : null;

    if (setTab && !!tabs[setTab]) {
      setCurrentTab(tabs[setTab]);

      if (setTab === tabs.answers.toLowerCase()) {
        setEditedAnswer(setAnswerId);
      }
    }
  }, [showAnswers, openTextAnswers]);

  useEffect(() => {
    if (!currentProgram || !openTextAnswers?.userAnswers || !curriculums || !curriculums[currentProgram.curriculum.code]) return;

    const curriculum = curriculums[currentProgram.curriculum.code];
    const { userAnswers } = openTextAnswers;
    const isUserAnswersExists = helpers.getIsUserAnswersExists(curriculum, userAnswers);
    setShowAnswers(isUserAnswersExists);
  }, [curriculums, openTextAnswers]);

  useEffect(() => {
    if (!pageLabels || !pageLabels.modules) {
      return;
    }

    setLabels(pageLabels.modules[0]);
  }, [pageLabels]);

  const certificateRibbonIcon = contentful.GlobalComponentQuery
  ? contentful.GlobalComponentQuery.setPageLabels.certificateRibbonIcon
  : null;

  const setActiveTab = (value) => {
    if (value.toLowerCase() !== tabs.answers.toLowerCase()) {
      setEditedAnswer(null);
    }
    setCurrentTab(value);

    push(`/progress/${clubCode}/${userId}/${value.toLowerCase()}`);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <NavBar />
        <main className={classes.main}>
        <Box className={classes.contentBox}>
          {currentTab === 'Activities' && <UserProgressPage
            user={user}
            clubCode={clubCode}
            userId={userId}
            userProgressByProgram={userProgressByProgram}
            getUserActivitiesByProgram={getUserActivitiesByProgram}
            deleteMyActivityProgress={deleteMyActivityProgress}
            getUserProjectsByProgram={getUserProjectsByProgram}
            programRatings={programRatings}
            currentProgram={currentProgram}
            getRatingsByProgram={getRatingsByProgram}
            createTrackingLog={createTrackingLog}
            curriculums={curriculums}
            isLoading={isLoadingUserActivitiesInfo}
            galleryProjects={galleryProjects}
            push={push}
            isUserProjects={isUserProjects}
            setCurrentTab={setActiveTab}
            currentTab={currentTab}
            showCertificates={!!userCertificate}
            showAnswers={showAnswers && !studentView}
            userAnswers={openTextAnswers.userAnswers}
            programId={currentProgram && currentProgram.id}
            certificateRibbonIcon={certificateRibbonIcon}
          />}
          {currentTab === 'Answers' && (
            <Box className={classes.header}>
              <Answers
                user={user}
                curriculums={curriculums}
                currentProgram={currentProgram}
                setCurrentTab={setActiveTab}
                isUserProjects={isUserProjects}
                currentTab={currentTab}
                showCertificates={!!userCertificate}
                openTextAnswers={openTextAnswers}
                getCommentsByAnswer={getCommentsByAnswer}
                createAnswer={createAnswer}
                editedAnswer={editedAnswer}
                setEditedAnswer={setEditedAnswer}
                push={push}
                answerGroups={answerGroups}
                programTypes={contentful?.programTypes}
              />
            </Box>
          )}
          {currentTab === 'Projects' && <UserProjects
            user={user}
            isLoadingProjects={isLoadingProjects}
            galleryProjects={galleryProjects}
            programId={currentProgram?.id}
            userId={userId}
            getUserProjectsByProgram={getUserProjectsByProgram}
            isUserProjects={isUserProjects}
            push={push}
            setCurrentTab={setActiveTab}
            currentTab={currentTab}
            showCertificates={!!userCertificate}
            showAnswers={showAnswers && !studentView}
          />}
          {currentTab === 'Certificates' && (
            <Box className={classes.header}>
              <Certificates
                user={user}
                certificates={certificates}
                currentProgram={currentProgram}
                setCurrentTab={setActiveTab}
                isUserProjects={isUserProjects}
                currentTab={currentTab}
                labels={labels}
                push={push}
                userId={userId}
                showAnswers={showAnswers && !studentView}
              />
            </Box>
          )}
        </Box>
      </main>
      <Footer />
    </div>
  );
};

export default connectTo(
  (state) => {
    return {
      curriculums: state.contentful.curriculums,
      auth: state.auth,
      userProgressByProgram: state.userActivitiesInfo.userProgressByProgram,
      ...state.userActivitiesInfo,
      rating: state.rating,
      galleryProjects: state.projectGallery.projectsByProgram,
      isUserProjects: state.projectGallery.isUserProjectsByProgram,
      isLoadingProjects: state.projectGallery.isLoadingProjects,
      certificates: state.certificates,
      openTextAnswers: state.openTextAnswers,
      contentful: state.contentful,
      studentView: state.global.studentView,
      answerGroups: state.answerGroups,
    };
  },
  {
    getUserActivitiesByProgram:
      userActivitiesInfoActions.getUserActivitiesByProgram,
    deleteMyActivityProgress:
      userActivitiesInfoActions.deleteMyActivityProgress,
    push: pushToRoute,
    ...authActions,
    ...ratingsActions,
    ...trackingActions,
    ...certificatesActions,
    ...openTextActions,
    ...answerGroupActions,
    getUserProjectsByProgram: getUserProjectsByProgramAction,
    getIsUserProjectsByProgram: getIsUserProjectsByProgramAction,
  },
  withPageLabels({ slug: "/facilitators-progress" }, UserProgress)
);
