import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

const StyledTabs = withStyles({
  indicator: {
    height: 3,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 70,
      width: '100%',
      backgroundColor: "#fff",
    },
  },
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 120,
    fontSize: 16,
    color: "#fff",
    opacity: 1,

    '&$selected': {
      color: "#fff",
      fontWeight: theme.typography.fontWeightBold,
      border: 'none'
    },

    "&:hover": {
      color: "#fff",
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

export default ({ onChange, value, push }) => {
  return (
    <StyledTabs
      color="secondary"
      value={value}
      onChange={onChange}
    >
      <StyledTab value='Dashboard' label='Dashboard' onClick={() =>
        push('/community-partnership')
      }/>
      <StyledTab value='Resources' label='Resources' onClick={() =>
        push('/community-partnership/resources/community-partner-resources')
      }/>
    </StyledTabs>
  );
};