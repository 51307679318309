import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DialogContent, Box, Typography, IconButton } from "@material-ui/core/";
import CloseIcon from "@material-ui/icons/Close";

import { Modal } from "../modal";
import * as colors from "../../../theme/colors";
import { ColorTheme } from '../../../utils/colorTheme';
import { EventViewContent } from "./eventViewContent";
import { EventSchedulerContent } from "./eventContent";
import { SendEmailConfirmationModal } from "./sendEmailConfirmationModal";
import { PROGRAM_TYPES } from '../../../utils/constants';

const useStyles = makeStyles({
  dialogContent: {
    padding: '16px 24px 16px',
  },
  title: {
    color: ColorTheme.blue4.b,
    textTransform: "uppercase",
    fontSize: 22,
    fontWeight: 700,
    margin: "22px 15px 20px 20px",
    width: "100%",
    wordBreak: 'break-word',
  },
  titleBox: {
    alignItems: 'center',
    display: 'flex',
    background: colors.lightGradient,
    justifyContent: "space-between"
  },
  closeBtn: {
    height: 40,
    width: 40,
    marginRight: 10
  },
});

export const EventViewModal = ({
  user,
  event,
  programs,
  isOpen,
  timezone,
  updateEvent,
  deleteEvent,
  onCloseModal,
  isLoadingSingleEventDetails,
  loadProgramMembers,
  programMembers,
  isLoadingProgramMembers,
  push,
  isFacilitator,
  setCloseCreateEventModal,
  isClosedCreateEventModal,
  setEscapeConfirmDialog,
}) => {
  const classes = useStyles();

  const [isEdit, setIsEdit] = React.useState(false);
  const [updateValues, setUpdateValues] = React.useState(false);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [isInProgress, setIsInProgress] = React.useState(false);

  const resetWarningModalValues = () => {
    setEscapeConfirmDialog(false);
    setCloseCreateEventModal(false);
    onCloseModal({ allowClose: true });
  }

  const onDelete = (eventId) => {
    setIsInProgress(true);
    deleteEvent({ eventId });
  };

  const isSPPEvent = !!event.isPrepopulated && !!event.programs && !!event.programs.find((p) => p?.program?.programType === PROGRAM_TYPES.SPP);
  const isEditEnable = !!event.programs && !!event.programs.filter((e) => {
    const roles = user.roles.filter((x) => x.programId === e.programId);
    const role = roles.find((e) => e.roleId === 1 || e.roleId === 3);

    return !!role;
  }).length && !isSPPEvent;
  const isDeleteEnable = event.createdBy === user.id && !event.isPublic && !isSPPEvent;
  const isExpandEnable = isFacilitator && !isSPPEvent;

  const sendUpdateEvent = (values, sendEmail) => {
    setIsInProgress(true);
    updateEvent({ event: values, sendEmail })
    setShowConfirmModal(false);
    setUpdateValues(null);
  }

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal}>
      {isEdit ? (
        <>
          <Box className={classes.titleBox}>
            <Typography variant="h4" className={classes.title}>
              Edit Event
            </Typography>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              className={classes.closeBtn}
              onClick={onCloseModal}
              disabled={isInProgress}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <DialogContent className={classes.dialogContent}>
            <EventSchedulerContent
              event={event}
              programs={programs}
              timezone={timezone}
              updateEvent={(data) => {
                setUpdateValues(data);
                setShowConfirmModal(true);
              }}
              onCloseModal={onCloseModal}
              loadProgramMembers={loadProgramMembers}
              programMembers={programMembers}
              isLoadingProgramMembers={isLoadingProgramMembers}
              setCloseCreateEventModal={setCloseCreateEventModal}
              isClosedCreateEventModal={isClosedCreateEventModal}
              setEscapeConfirmDialog={setEscapeConfirmDialog}
              countryId={user?.countryId}
              isInProgress={isInProgress}
            />
          </DialogContent>
        </>
      ) : (
        <EventViewContent
          event={event}
          programs={programs}
          timezone={timezone}
          onCloseModal={() => {
            resetWarningModalValues();
          }}
          onDelete={onDelete}
          push={push}
          onEdit={() => {
            // load members for event programs.
            const programIds = event.programs && !!event.programs.length
              ? event.programs.map(e => e.programId)
              : [];

            const programIdsToLoadMembers =  !!programIds.length
              ? programIds.filter(id => !programMembers[id])
              : [];

            if (!!programIdsToLoadMembers.length) {
              loadProgramMembers(programIdsToLoadMembers, event.id);
            }
            setIsEdit(true);
          }}
          isEditEnable={isEditEnable}
          isDeleteEnable={isDeleteEnable}
          isExpandEnable={isExpandEnable}
          isLoadingSingleEventDetails={isLoadingSingleEventDetails}
          isInProgress={isInProgress}
        />
      )}
      <SendEmailConfirmationModal
        isOpen={showConfirmModal}
        onSave={() => {
          sendUpdateEvent(updateValues, true);
        }}
        onClose={() => {
          sendUpdateEvent(updateValues, false);
        }}
      />
    </Modal>
  );
};
