import React, { useState, useRef, useEffect } from "react";
import { Formik, Form } from "formik";

import {
  Box,
  Divider,
} from "@material-ui/core/";

import { makeStyles } from "@material-ui/core/styles";

import * as colors from "../../../../theme/colors";
import CustomButton from "../../../../components/customButton/customButton";
import {createEmptyEditor, exportEditor, importEditor} from '../../../../components/textEditor/utils';
import Editor from '../../../../components/textEditor';
import { StyledFormHelper } from '../../../../components/loginSignup/customFormHelper';
import { PageLeaveModal } from '../../../../components/modals/pageLeaveWarning';

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    display: 'flex',
  },
  buttonGroupContainer: {
    marginTop: 20,
    height: 42,
    width: "100%",
    display: "flex",
    boxShadow: "none",
    fontSize: 16,
  },
  textAreaInput: {
    width: "100%",
    resize: "none",
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: () => `2px solid ${colors.lightThemeGreen}`,
      },
    },
    "& .MuiOutlinedInput-root": {
      fontSize: 14,
    },
    "&:focus": {
      outlineColor: colors.lightThemeGreen,
    }
  },
  addCommentContainer: {
    width: "100%",
    marginTop: 15,
  },
  postCommentBtn: {
    marginRight: 10,
  },
  verticalDivider: {
    marginRight: 25,
    width: 3,
    marginLeft: 10,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      marginRight: 10,
    }
  },
  commentInputWrapper: {
    width: '100%',
  },
}));

const maxMsgLength = 1200;

const AddComment = ({ answer, headerContent, onSubmit, onCancel, isAnswerGroup, history, comment }) => {
  const classes = useStyles();
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [isEscapeConfirmDialog, setEscapeConfirmDialog] = useState(false);
  const [isDataChanged, setDataChanged] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);

  const unblockRef = useRef(null);

  useEffect(() => {
    unblockRef.current = history.block((location) => {
      setNextLocation(location);
      setEscapeConfirmDialog(true);

      return !isDataChanged;
    });

    return () => {
      unblockRef.current && unblockRef.current();
  };
  }, [isDataChanged, isEscapeConfirmDialog]);

  const validateContent = (value) => {
    if (!value || value.length == 0) {
      setDisabledSubmit(true);
      return;
    }

    if (value.length > maxMsgLength) {
      setDisabledSubmit(true);
      return `Comment should contain less ${maxMsgLength} characters`;
    }

    setDisabledSubmit(false);
    return null;
  };

  return (
    <Box className={classes.addCommentContainer}>
      <Formik
          initialValues={{
            content: comment ? importEditor(comment.content) : createEmptyEditor(),
          }}

        validateOnChange={true}
        validateOnBlur={false}
        validate={(values) => {
          let errors = {};
          if (values.content.getCurrentContent().getPlainText().length > maxMsgLength) {
            setDisabledSubmit(true);
            setDataChanged(false);
            errors.body = 'Comment should contain less than 1200 characters';
          }
          if (!values.content || !values.content.getCurrentContent().getPlainText().trim().length) {
            setDisabledSubmit(true);
            setDataChanged(false);
          } 
          else {
            setDisabledSubmit(false);
            setDataChanged(true);
          }
          return errors;
        }}
        onSubmit={(values) => {
          const editorToHtml = exportEditor(values.content);
          const body = !isAnswerGroup ? {
                programId: answer.programId,
                id: comment ? comment.id : null,
                answerId: answer.id,
                userId: answer.userId,
                content: editorToHtml,
                itemKey: `${answer.path}-${answer.question}`,
                questionName: headerContent,
              } : {
                id: comment ? comment.id : null,
                programId: answer.programId,
                commentId: comment ? comment.id : null,
                answerGroupId: answer.id,
                contentfulGroupId: answer.contentfulGroupId,
                content: editorToHtml,
              }
          ;
          onSubmit(body);
          onCancel();
        }}
      >
        {({ errors, touched, values }) => (<Form>
          <Box className={classes.formWrapper}>
          <Divider orientation="vertical" flexItem className={classes.verticalDivider} />
            <Box className={classes.commentInputWrapper}>
              <Box>
                <Editor
                  name={`content`}
                  showToolbar={true}
                  placeholder='Type your response here'
                  className={classes.messageArea}
                  maxLength={maxMsgLength}
                  maxLengthStartingFrom={1000}
                  maxLengthInfo="Characters remaining: ${value}"
                  aria-live="polite"
                  validate={validateContent}
                  isOpenText={true}
                />
                {errors && touched && !values.content.getCurrentContent().getPlainText().length &&
                  <StyledFormHelper helperText={errors.body} isError={true} isHtml={true} />
                }
              </Box>
              <Box className={classes.formContainer}>
                <div className={classes.buttonGroupContainer}>
                  <CustomButton
                    mode="primary"
                    className={classes.postCommentBtn}
                    disabled={disabledSubmit}
                    type="submit"
                    label={comment ? "Update Comment" : "Post Comment"}
                  />
                  <CustomButton
                    mode="secondary"
                    onClick={() => {
                      isDataChanged ? setEscapeConfirmDialog(true) : onCancel()
                    }}
                    label="Cancel"
                  />
                </div>
              </Box>
            </Box>
          </Box>
        </Form>)}
      </Formik>
      <PageLeaveModal
        onConfirm={() => {
          if (unblockRef) {
            unblockRef.current();
          }
          setDataChanged(false);
          !!nextLocation ? history.push(nextLocation) : onCancel();
          setEscapeConfirmDialog(false);
        }}
        onCancel={() => { setEscapeConfirmDialog(false); }}
        open={isEscapeConfirmDialog}
      />
    </Box>
  );
}

export default AddComment;