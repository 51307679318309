import { getAuthHeader } from '../tokenStorage';
import { axiosInstance } from './axiosInstance';

export const setFacilitatorsConsentAgreement = async (programId) =>  {
  return axiosInstance.post(
    `/blapi/facilitatorsConsentAgreement/setFacilitatorsConsentAgreement/${programId}`,
    null,
    {
      headers: getAuthHeader(),
    }
  );
};