import React, { useState, useEffect } from "react";

import qs from "query-string";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import Login from "./Login";
import SignUpWizard from "./Register";
import Tabs from "./Tabs";

import withPageLabels from "../../hocs/withPageLabels";
import { GetUserInvitationToken } from "../../api/graphql/query/user.queries";
import * as colors from "../../theme/colors";
import Footer from "../../containers/Footer/footer";

import { useTitle } from "../../utils/pageTitles";
import { checkIsClubsDomain, createMarkup } from "../../utils/generic";

const useStyles = makeStyles({
  gradientBox: {
    height: "100%",
  },
  backImg: {
    maxWidth: "100%",
    left: "50%",
    transform: "translateX(-50%)",
    position: "absolute",
    height: "auto",
  },
  content: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0 -85px",
    backgroundSize: "calc(100% - 85px)",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 123px)",
  },
  card: {
    maxWidth: 445,
    width: "100%",
    marginTop: 15,
    marginBottom: 15,
    overflow: "visible",
    zIndex: 1,
  },
  cardContent: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: "0px !important",
  },
  title: {
    fontSize: 36,
    textTransform: 'uppercase',
    color: colors.dartThemeBlueGray,
    fontWeight: 700,
    marginTop: 34,
    textAlign: 'center'
  },
  subTitle: {
    fontSize: 16,
    width: "70%",
    textAlign: "center",
    lineHeight: 1.75,
  },
  createAccountBox: {
    marginTop: 32,
  },
  smallText: {
    color: "#565656",
    fontSize: 12,
  },
  dontHaveAnAccountStyles: {
    display: "inline-block",
    marginRight: 4,
    margin: 0,
  },
  bottomLinksBox: {
    display: "flex",
    marginTop: 50,
    paddingBottom: 20,
  },
  blockSpacer: {
    color: colors.white,
    margin: "0 11px",
  },
  linkBtn: {
    marginTop: 3,
    backgroundColor: "transparent",
    color: "#20a8ed",
    fontSize: 12,
    textTransform: "initial",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#20a8ed",
    },
  },
  bottomLinksBox: {
    backgroundColor: colors.darkGreenBackground,
    bottom: 0,
    left: 0,
    width: "100%",
    display: "inline-flex",
    justifyContent: "space-between",
    height: 58,
    alignItems: "center",
  },
  bottomLink: {
    color: colors.white,
    marginLeft: 10,
    fontSize: 12,
    "&:hover": {
      color: colors.lightThemeGreen,
      textDecoration: "none",
    },
  },
  gwc: {
    marginLeft: 20,
    color: colors.white,
  },
  buttonContainer: {
    margin: "auto",
  },
  builtBy: {
    color: colors.white,
    fontSize: 12,
    marginLeft: 25,
    marginRight: 20,
    marginTop: 20,
    paddingBottom: 20,
  },
  bottomLinksContainer: {
    display: "inline-flex",
    alignItems: 'center',
  }
});

const tabs = [
  { key: "login", value: 0 },
  { key: "signup", value: 1 },
];

const facilitatorRole = "role_1"
const studentRole = "role_2";

const LoginSignUp = (props) => {
  const classes = useStyles();
  const isClubsDomain = checkIsClubsDomain();
  const [currentTab, setCurrentTab] = useState(0);
  const [email, setEmail] = useState(null);
  const [invitationProgram, setInvitationProgram] = useState(null);
  const [defaultRole, setDefaultRole] = useState(null);
  const [qrCode, setQrCode] = useState()

  useTitle(currentTab === 0 ? "login" : "signup");

  useEffect(() => {
    const {
      location: { search },
    } = props;
    const { showTab, invitationToken, facilitatorInvitationToken, qrClubCode } = qs.parse(search);
    const selectedTab = tabs.find((e) => e.key === showTab);

    if (selectedTab && selectedTab) {
      setCurrentTab(selectedTab.value);
    }

    if (invitationToken) {
      GetUserInvitationToken(invitationToken)
        .then((result) => {
          const {
            data: { getUserNextInvitationByToken: invitation },
          } = result;

          if (invitation && invitation.pending && invitation.email) {
            // TO DO : setting default role for cp also if needed
            setDefaultRole(studentRole);
            setEmail(invitation.email.trim());
            setCurrentTab(invitation.isUserExist ? 0 : 1);
            setInvitationProgram(invitation.program)
            return;
          }
        })
        .catch((err) => console.log(err));
    }

    if (facilitatorInvitationToken) {
      GetUserInvitationToken(facilitatorInvitationToken)
        .then((result) => {
          const {
            data: { getUserNextInvitationByToken: invitation },
          } = result;

          if (invitation && invitation.pending && invitation.email) {
            setDefaultRole(facilitatorRole);
            setEmail(invitation.email.trim());
            setCurrentTab(invitation.isUserExist ? 0 : 1);
            return;
          }
        })
        .catch((err) => console.log(err));
    }

    if (qrClubCode) {
      setQrCode(qrClubCode);
    }
  }, []);

  const { pageLabels } = props;
  const loginContent = pageLabels ? pageLabels.modules[0] : null;
  const subheaderText = currentTab === 0
    ? isClubsDomain
      ? loginContent?.loginSubheaderClubs
      : loginContent?.loginSubheader
    : loginContent?.subheading

  return (
    <Box className={classes.gradientBox}>
      {/* <img className={classes.backImg} src={SignupImage} /> */}
      {loginContent != null && (
        <>
          <Box className={classes.content}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography
                  variant="h4"
                  className={classes.title}
                  color="primary"
                  gutterBottom
                  role="region"
                  aria-label={loginContent.heading}
                >
                  {loginContent.heading}
                </Typography>
                <Typography
                  variant="h6"
                  color="primary"
                  className={classes.subTitle}
                  role="region"
                  aria-label={subheaderText}
                  dangerouslySetInnerHTML={createMarkup(subheaderText)}
                />
                <Tabs
                  value={currentTab}
                  onChange={(evt, newValue) => {
                    setCurrentTab(newValue);
                  }}
                  loginContent={loginContent}
                />
                {currentTab === 0 && (
                  <Login loginContent={loginContent} invitedEmail={email} />
                )}
                {currentTab === 1 && (
                  <SignUpWizard
                    signUpContent={loginContent}
                    invitedEmail={email}
                    defaultRole={defaultRole}
                    invitationProgram={invitationProgram}
                    qrClubCode={qrCode}
                  />
                )}
              </CardContent>
            </Card>
          </Box>
          <Footer />
        </>
      )}
    </Box>
  );
};

export default withPageLabels({ slug: "/login" }, LoginSignUp);
