import React from "react";

import { Field } from "formik";

import { makeStyles } from "@material-ui/core/styles";
import Selector from "../../../components/selector/Selector";
import DateInput from "../../../components/date-input/DateInput";
import { Typography, Box } from "@material-ui/core";

import * as colors from "../../../theme/colors";

import CheckboxGroup from "../../../components/checkboxGroup/checkBoxGroup";
import { createMarkup } from "../../../utils/generic";
import { DOMESTIC_ID } from "../../../utils/countriesDict";

const useStyles = makeStyles({
  stepTitle: {
    color: colors.darkThemeBlueGray,
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 10
  },
  subTitle: {
    color: colors.darkThemeBlueGray,
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 10
  },
  boxWrapper: {
    marginTop: 20,
    '& .MuiTypography-body1': {
      fontSize: 14,
    }
  },
  requiredBox: {
    display: "flex",
  },
  starLabel: {
    color: colors.redA700,
    marginLeft: 1,
  },
  item: {
    display: "flex",
    marginBottom: 20,
    "& .MuiTypography-body1": {
      width: "40%",
      margin: "auto 0",
      fontWeight: "bold",
    },
    "& .MuiTypography-body2": {
      width: "60%",
      margin: "auto 0",
      fontSize: 14,
      color: colors.dartThemeBlueGray,
      "& button": {
        textAlign: "left",
      },
    },
  },
  infoContainer: {
    border: "1px solid #7A7A78",
    padding: "5px 15px",
    lineHeight: 1.5,
    fontSize: 14,
    "& a": {
      textDecoration: "none",
      textTransform: "inherit",
      padding: "0px 5px",
      color: colors.darkThemeGreen,
      fontWeight: "bold",
      fontSize: 14,
      "&:hover": {
        textDecoration: "none",
      },
    }
  },
  infoContainerTextContainer: {
    "& p, strong": {
      color: colors.darkBlue100,
    }
  },
  infoLink: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  linkBtn: {
    textDecoration: "none",
    textTransform: "inherit",
    padding: "0px 5px",
    "&:hover": {
      textDecoration: "none",
    },
  },
  noteBox: {
    fontSize: 14,
    marginBottom: 20,
    "& p": {
      color: colors.darkBlue100,
      margin: 0,
      lineHeight: '20px'
    }
  },
  secondStepWrapper: {
    padding: "0 24px",
  },
  inputStyle: {
    '& .MuiInputBase-input': {
      fontSize: 14,
    }
  },
  ageGroupFieldBox: {
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 10,
    '& .MuiIconButton-label': {
      color: colors.darkThemeGreen,
    },
    "& .Mui-checked": {
      color: colors.darkThemeGreen,
    },
    "& .MuiTypography-root": {
      "& .MuiBox-root": {
        fontSize: 14,
        fontWeight: 400,
        textAlign: "left",
      },
    },
    "& .MuiFormControl-root": {
      "& .MuiFormLabel-root": {
        color: colors.darkThemeBlueGray,
        fontSize: 14,
        fontWeight: 400,
      },
      "& .MuiFormGroup-root": {
        "& .MuiFormControlLabel-root": {
          "& .MuiTypography-body1": {
            fontSize: 14,
            lineHeight: "1.5",
            fontWeight: 400,
            color: colors.darkThemeBlueGray,
          }
        }
      }
    }
  }
});

const ageGroupServedOptions = [
  { value: "Elementary (3-5)", label: "Elementary (3-5)" },
  { value: "Middle (6-8)", label: "Middle (6-8)" },
  { value: "High (9-12)", label: "High (9-12)" },
];

const curriculumOptions = [
  { value: "3rd-5th", label: "3rd-5th Curriculum" },
  { value: "6th-12th", label: "6th-12th Curriculum" },
];

const SecondStep = ({ labels, minDate, maxDate, countryId, values }) => {
  const classes = useStyles();

  return (
    <Box className={classes.secondStepWrapper}>
      <Box>
        <Typography className={classes.stepTitle}>
          {`Step 2: ${labels.secondStepLabel}`}
        </Typography>
      </Box>
      <Box className={classes.boxWrapper}>
        <Typography>
          {labels.secondStepInfoLabel}
        </Typography>
      </Box>
      <Box className={classes.boxWrapper}>
        <Typography className={classes.subTitle}>
          Please remember that:
        </Typography>
      </Box>
      <Box className={classes.infoContainer}>
        <div
          className={classes.infoContainerTextContainer}
          dangerouslySetInnerHTML={createMarkup(labels.secondStepRememberTableData)}
        />
      </Box>

      <Box className={classes.boxWrapper}>
        <Typography className={classes.subTitle}>
          When is your anticipated Launch Date?*
        </Typography>
      </Box>
      <Box className={classes.noteBox}>
        <div dangerouslySetInnerHTML={createMarkup(`<p>
            An estimate is fine but the date must be between <strong>${minDate}</strong> and <strong>${maxDate}</strong>
            </p>`)}>
        </div>
      </Box>
      <Field
        name="launchDate"
        component={DateInput}
        label="Launch Date"
        maxDate={maxDate}
        minDate={minDate}
        domesticId={DOMESTIC_ID}
        countryId={countryId}
        className={classes.inputStyle}
        onValueChange={(value, setFieldValue) => {
          if (!!value) {
            const month = new Date(value).getMonth();
            setTimeout(function () {
              if (month >= 5 && month <= 7) {
                setFieldValue("session", "Summer", true);
              }
              if (month >= 2 && month <= 4) {
                setFieldValue("session", "Spring", true);
              }
            }, 1000);
          }
        }}
      />
      <Box className={classes.boxWrapper}>
        <Typography className={classes.subTitle}>
          Which Grades will your Girls Who Code Club serve? You may choose more than one.*
        </Typography>
      </Box>
      <Box className={classes.ageGroupFieldBox}>
        <Field
          name="ageGroupServed"
          component={CheckboxGroup}
          className={classes.item}
          items={ageGroupServedOptions}
          defaultItems={values.ageGroupServed}
        />
      </Box>
      <Box className={classes.boxWrapper}>
        <Typography className={classes.subTitle}>
          Which curriculum will you use?*
        </Typography>
      </Box>
      <Field
        name={`curriculumType`}
        defaultValue="3rd-5th"
        component={Selector}
        label="Curriculum type"
        className={classes.inputStyle}
        options={curriculumOptions}
      />
    </Box>
  );
};

export default SecondStep;
