import { createReducer } from 'redux-act';

import * as a from '../actions/answerGroups';

const getDefaultState = () => ({
  isLoadingAnswerGroups: false,
  isAddingComment: false,
  isUpdatingComment: false,
  isRemovingComment: false,
  userAnswerGroups: {},
  answerGroups: {},
});

export default () =>
  createReducer(
    {
      [a.recievedUserAnswerGroups]: (state, payload) => ({
        ...state,
        userAnswerGroups: payload.data,
        isLoadingAnswerGroups: false,
      }),
      [a.updateMyAnswerGroups]: (state, payload) => {
        const { answerGroupId, question } = payload;
        const currentGroup = state.userAnswerGroups[answerGroupId];

        if (currentGroup && Array.isArray(currentGroup.openTextAnswers)) {
          const openTextAnswers = [...currentGroup.openTextAnswers];
          const index = openTextAnswers.findLastIndex(answer => answer.question === question);
          if (index + 1 < openTextAnswers.length && index + 1 > 0) {
            openTextAnswers.splice(index + 1, 0, payload);
          } else {
            openTextAnswers.push(payload);
          }

          const updatedGroup = {
            ...currentGroup,
            openTextAnswers,
            status: payload?.answerGroups?.status,
          };

          return {
            ...state,
            userAnswerGroups: {
              ...state.userAnswerGroups,
              [answerGroupId]: updatedGroup,
            },
            isLoadingAnswerGroups: false,
          };
        }

        return state;
      },
      [a.getMyAnswerGroupsByProgram]: state => ({
        ...state,
        isLoadingAnswerGroups: true,
      }),
      [a.getAnswerGroupsError]: state => ({
        ...state,
        isLoadingAnswerGroups: false,
      }),
      [a.receivedAllAnswerGroups]: (state, payload) => ({
        ...state,
        answerGroups: { ...state.answerGroups, ...payload.data },
        isLoadingAnswerGroups: false,
      }),
      [a.getAnswerGroupsByProgram]: state => ({
        ...state,
        isLoadingAnswerGroups: true,
      }),
      [a.receivedUpdatedAnswerGroup]: (state, payload) => {
        const { data } = payload;
        const updatedAnswerGroups = state.answerGroups[data.contentfulGroupId].map(groupAnswer =>
          groupAnswer.id === data.id ? data : groupAnswer
        );

        return {
          ...state,
          answerGroups: {
            ...state.answerGroups,
            [data.contentfulGroupId]: updatedAnswerGroups,
          },
        };
      },
      [a.addAnswerGroupComment]: state => ({
        ...state,
        isAddingComment: true,
      }),
      [a.addAnswerGroupCommentError]: state => ({
        ...state,
        isAddingComment: false,
      }),
      [a.updateAnswerGroupComment]: state => ({
        ...state,
        isUpdatingComment: true,
      }),
      [a.updateAnswerGroupCommentError]: state => ({
        ...state,
        isUpdatingComment: false,
      }),
      [a.receiveUpdatedAnswerGroupComment]: (state, payload) => {
        const { data, answerGroupId, commentId, contentfulGroupId } = payload;
        const answerGroupsByContentfulId = state.answerGroups[contentfulGroupId];
        const currentGroupIndex = answerGroupsByContentfulId?.findIndex(group => answerGroupId === group.id);

        if (currentGroupIndex > -1) {
          const currentGroup = answerGroupsByContentfulId[currentGroupIndex];
          const updatedComments = currentGroup.answerGroupComments.map(comment =>
            comment.id === commentId
              ? { ...comment, content: data.content, updatedAt: new Date() }
              : comment
          );

          return {
            ...state,
            answerGroups: {
              ...state.answerGroups,
              [contentfulGroupId]: answerGroupsByContentfulId.map((group, index) =>
                index === currentGroupIndex
                  ? { ...group, answerGroupComments: updatedComments }
                  : group
              ),
            },
            isUpdatingComment: false,
          };
        }

        return state;
      },
      [a.receiveAddedAnswerGroupComment]: (state, payload) => {
        const { contentfulGroupId, data, answerGroupId } = payload;
        const answerGroupsByContentfulId = state.answerGroups[contentfulGroupId];
        const currentGroupIndex = answerGroupsByContentfulId?.findIndex(group => answerGroupId === group.id);

        if (currentGroupIndex > -1) {
          const currentGroup = answerGroupsByContentfulId[currentGroupIndex];
          const updatedGroup = {
            ...currentGroup,
            answerGroupComments: [...currentGroup.answerGroupComments, data],
            totalComments: currentGroup.totalComments + 1,
          };

          return {
            ...state,
            answerGroups: {
              ...state.answerGroups,
              [contentfulGroupId]: answerGroupsByContentfulId.map((group, index) =>
                index === currentGroupIndex ? updatedGroup : group
              ),
            },
            isAddingComment: false,
          };
        }

        return state;
      },
      [a.deleteAnswerGroupComment]: state => ({
        ...state,
        isRemovingComment: true,
      }),
      [a.deleteAnswerGroupCommentError]: state => ({
        ...state,
        isRemovingComment: false,
      }),
      [a.receivedDeletedAnswerGroupComment]: (state, payload) => {
        const { contentfulGroupId, answerGroupId, id } = payload;
        const answerGroupsByContentfulId = state.answerGroups[contentfulGroupId];
        const currentGroupIndex = answerGroupsByContentfulId?.findIndex(group => answerGroupId === group.id);

        if (currentGroupIndex > -1) {
          const currentGroup = answerGroupsByContentfulId[currentGroupIndex];
          const updatedComments = currentGroup.answerGroupComments.filter(comment => comment.id !== id);

          const updatedGroup = {
            ...currentGroup,
            answerGroupComments: updatedComments,
            totalComments: updatedComments.length,
          };

          return {
            ...state,
            answerGroups: {
              ...state.answerGroups,
              [contentfulGroupId]: answerGroupsByContentfulId.map((group, index) =>
                index === currentGroupIndex ? updatedGroup : group
              ),
            },
            isRemovingComment: false,
          };
        }

        return state;
      },
    },
    getDefaultState()
  );
