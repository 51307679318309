import React from "react";
import * as colors from "../../../theme/colors";
import * as moment from "moment";
import {
  CardContent,
  CardMedia,
  Typography,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "../../../components/customButton/customButton";
import { getCountryFormat } from '../../../utils/generic';

const useStyles = makeStyles(() => ({
  childViewWrapper: {
    width: 435,
    maxWidth: `100%`,
    padding: 20,
  },
  headerWrapper: {
    padding: "0 20px",
    minHeight: 70,
    textAlign: "center",
  },
  header: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: 1,
  },
  subHeader: {
    fontSize: 18,
    marginTop: 10,
  },
  cardContent: {
    padding: 0,
  },
  userDefinition: {
    marginBottom: 22,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  imageContainer: {
    height: 95,
    width: 95,
  },
  field: {
    color: colors.lightGrey,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.4,
    marginTop: 10,
    textTransform: "uppercase",
  },
  fieldValue: {
    color: colors.darkThemeBlueGray,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.4,
    marginTop: 10,
    wordWrap: "break-word"
  },
  childClubContainer: {
    border: `1px solid ${colors.grey93}`,
    borderRadius: 3,
    padding: '0 0 10px 15px'
  },
  initials: {
    paddingTop: 10,
    fontWeight: 700,
  },
  btnContainer: {
    display: "flex",
    flexDirection: "column",
  },
  addAnotherChildBtn: {
    marginTop: 35,
  },
  closeBtn: {
    marginTop: 10,
  },
}));

export const ChildView = ({
  addedChild,
  labels,
  onClose,
  onSubmit,
  domesticCountry,
  user,
}) => {
  const classes = useStyles();
  const { childrenTabDefaultAvatar } = labels;
  let coverImage = childrenTabDefaultAvatar ? childrenTabDefaultAvatar.src : "";

  return (
    <Box className={classes.childViewWrapper}>
      <Box className={classes.headerWrapper}>
        <Typography className={classes.header}>Success!</Typography>
        <Typography className={classes.subHeader}>Your child’s account has been created.</Typography>
      </Box>
      <CardContent className={classes.cardContent}>
        <Box className={classes.userDefinition}>
          <CardMedia className={classes.imageContainer} image={coverImage} />
          <Box color="primary">
            <Typography
              className={classes.initials}
              sel="child-name"
            >{`${addedChild?.firstName} ${addedChild?.lastName}`}</Typography>
          </Box>
        </Box>

        <Box className={classes.childClubContainer}>
          {addedChild?.email && <>
            <Typography className={classes.field}>Email</Typography>
            <Typography className={classes.fieldValue}>{addedChild?.email}</Typography>
          </>}
          {addedChild?.username && <>
            <Typography className={classes.field}>Username</Typography>
            <Typography className={classes.fieldValue}>{addedChild?.username}</Typography>
          </>}
          <Typography className={classes.field}>Birth date</Typography>
          <Typography className={classes.fieldValue}>{moment.parseZone(addedChild?.birthDate).format(getCountryFormat(user?.countryId, domesticCountry).toUpperCase())}</Typography>
          <Typography className={classes.field}>Club</Typography>
          <Typography className={classes.fieldValue}>{addedChild?.program}</Typography>
        </Box>
      </CardContent>
      <Box className={classes.btnContainer}>
        <CustomButton
          mode="secondary"
          type="submit"
          className={classes.addAnotherChildBtn}
          onClick={() => {
            onSubmit();
          }}
          label="+ Add another child"
        />
        <CustomButton
          mode="primary"
          type="submit"
          className={classes.closeBtn}
          onClick={() => {
            onClose();
          }}
          label="Close"
        />
      </Box>
    </Box>
  );
};
