import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { DialogContent, Typography, Box } from "@material-ui/core/";
import CircularProgress from '@material-ui/core/CircularProgress';

import { HQModal } from "../HQModal";
import CustomButton from "../../../components/customButton/customButton";
import * as colors from '../../../theme/colors';
import { createMarkup } from '../../../utils/generic';

const useStyles = makeStyles(() => ({
  dialogContent: {
    padding: 0,
  },
  subTitle: {
    marginBottom: 10,
    '& a': {
      color: colors.darkThemeGreen,
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 35,
    fontSize: 16,
  },
  confirmBtn: {
    padding: '9px 22px',
  },
  progressIcon: {
    width: 20,
    height: 20,
    marginLeft: 5,
  },
  clubName: {
    fontWeight: 'bold',
  },
}));

export const FacilitatorsConsentModal = ({
  onSubmit,
  labels,
  programName,
  isLoading,
  isOpen,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <HQModal title="Consent Agreement" isOpen={isOpen} onClose={onClose} titleOptions={{isUpperCase: true, isBold: true}} withoutAutofocus={true}>
      <DialogContent className={classes.dialogContent}>
        <Typography
          component='div'
          className={classes.subTitle}
        >
          Pending facilitator consent for your Club, <Box component="span" className={classes.clubName}>{programName}</Box>
        </Typography>
        <Typography
          className={classes.subTitle}
          dangerouslySetInnerHTML={createMarkup(
            !!labels ? labels.replace('{{programName}}', programName) : ''
          )}
        ></Typography>
        <Box className={classes.btnContainer}>
          <CustomButton
            className={classes.confirmBtn}
            mode="primary"
            type="submit"
            onClick={onSubmit}
            label="Confirm"
            disabled={isLoading}
          >
            {isLoading && (
              <CircularProgress size={20} className={classes.progressIcon} />
            )}
          </CustomButton>
        </Box>
      </DialogContent>
    </HQModal>
  );
};
