import React from 'react';

import clsx from 'clsx';
import { Alert } from '@material-ui/lab';
import { Box, CardContent, CardHeader, Checkbox, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import 'highlight.js/styles/paraiso-dark.css';  // import your preferred style
import CustomButton from "../../../components/customButton/customButton";
import { teal } from '@material-ui/core/colors';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import * as quizApi from "../../../api/quiz";
import { handleKeyPress } from '../../../utils/accessibility';

import { createMarkup } from "../../../utils/generic";
import { getNodeIds } from "../../../utils/getNodeIds";

const StyledRadio = (props) => {
    const { classes } = props;
  
    return (
      <Radio
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  };
  
const Quiz = ({ 
    item,
    theme,
    chains,
    program,
    updateCurrentStateofQuizesAnswers,
    classes
  }) => {
    const [prevLoadedAnswers, setPrevLoadedAnswers] = React.useState(null);
    const [answer, setAnswer] = React.useState(null);
    const [passed, setPassed] = React.useState(true);
    const [collapse, setCollapse] = React.useState(false);
    const [lockQuiz, setLockQuiz] = React.useState(false);
    const [isAnswered, setIsAnswered] = React.useState(false);
    const [openedModal, setOpenedModal] = React.useState(false);
    const [checkboxes, setCheckboxes] = React.useState({});
    const isMultiSelect = item && item.answers && item.answers.filter(answer => answer.isCorrect).length > 1;
   
    const { activityId, setId, collectionId, url, folderId } = getNodeIds(chains);
  
    React.useEffect(() => {
      item.answers.map((el) => {
        el.isChecked = false;
      });
  
      quizApi.getLastQuizAttempt(item.id, { url, programId: program.id }).then((response) => {
        setPrevLoadedAnswers(response.data.answers);
      });
    }, []);
  
    React.useEffect(() => {
      item.answers.map((el) => {
        const prevAnswers = prevLoadedAnswers && prevLoadedAnswers.split(", ").includes(el.answerTitle);
  
        if (!el.isChecked) {
          el.isChecked = prevAnswers;
          checkboxes[el.answerTitle] = prevAnswers;
        }
  
        if (!checkboxes[el.answerTitle]) {
          checkboxes[el.answerTitle] = prevAnswers;
        }
  
        if (!answer && !isMultiSelect) {
          setAnswer(prevLoadedAnswers);
        }
      });
  
      if (prevLoadedAnswers) {
        let passedTest = true;
  
        item.answers.map((el) => {
          if ((el.isCorrect && !el.isChecked) || (!el.isCorrect && el.isChecked)) {
            passedTest = false;
          }
        });
  
        setPassed(passedTest);
        setOpenedModal(true);
        setIsAnswered(true);
      } else {
        setPassed(false);
      }
    }, [prevLoadedAnswers]);
  
    const handleCollapse = () => {
      setCollapse(!collapse);
    };
  
    return (
      <Box>
        <CardHeader
          className={classes.quizHeader}
          classes={{title: classes.title, content: classes.cardContent, subheader: classes.subheader, action: classes.action}}
          style={{ backgroundColor: theme.b, color: theme.f }}
          title={item.header}
        />
        <CardContent className={classes.quizContent}>
          <div style={{ width: "100%", position: "relative" }}>
            <div className={classes.quizLeft}>
              <div className="rich-text" dangerouslySetInnerHTML={createMarkup(item.description)} />
              <div style={{width: "100%"}}>
                {item.answers && item.answers.map((el, elItemIndex) =>
                  el.answerBody && (
                    <div className={classes.quizContentOption} key={elItemIndex}>
                      <div className={classes.quizContentAnswerHead}>{el.answerTitle}</div>
                        <pre className={classes.quizContentAnswer}>
                          <Box dangerouslySetInnerHTML={createMarkup(el.answerBody)} />
                        </pre>
                    </div>
                  )
                )}
              </div>
            </div>
              <div
                className={collapse ? classes.quizRightClps : classes.quizRight}
                style={{ boxShadow: theme.b + " 0px 0px 0px 2px inset" }}
              >
                <div
                  className={classes.quizRightCollapse}
                  style={{ backgroundColor: theme.b }}
                  tabIndex={0}
                  onClick={handleCollapse}
                  onKeyDown={handleKeyPress(handleCollapse)}
                  role="button"
                >
                  {collapse && <ChevronLeftIcon className={classes.quizCollapseIcon} />}
                  {!collapse && <ChevronRightIcon className={classes.quizCollapseIcon} />}
                </div>
  
                {item.answers && isMultiSelect &&
                  <div>
                    <div className={classes.answersTitle}>Select all that apply</div>
                    {item.answers.map((el) =>
                      <div key={el.answerTitle} style={{ paddingLeft: 10 }}>
                        <FormControlLabel
                          className={classes.checkBoxContainer}
                          control={
                            <Checkbox
                              checked={checkboxes[el.answerTitle] || false}
                              value={el.answerTitle}
                              className={classes.checkboxControl}
                              onChange={(event) => {
                                setCheckboxes({ ...checkboxes, [el.answerTitle]: event.target.checked });
                                checkboxes[el.answerTitle] = event.target.checked;
                                el.isChecked = event.target.checked;
  
                                let foundAnswered = false;
  
                                for (const val of Object.values(checkboxes)) {
                                  if (val && !foundAnswered) {
                                    foundAnswered = true;
                                  }
                                }
  
                                setIsAnswered(foundAnswered);
                              }}
                            />
                          }
                          label={el.answerTitle}
                          key={el.answerTitle}
                        />
                      </div>
                    )}
                  </div>
                }
  
                {item.answers && !isMultiSelect &&
                  <div>
                    <div className={classes.answersTitle}>Select the best answer</div>
                    <RadioGroup
                      name="roleSelector"
                      value={answer || prevLoadedAnswers}
                      style={{ paddingLeft: 10 }}
                    >
                      {item.answers.map((el) =>
                        <FormControlLabel
                          key={el.answerTitle}
                          value={el.answerTitle}
                          classes={{label: classes.checkBoxLabel}}
                          control={
                            <StyledRadio
                              color="primary"
                              className={classes.radio}
                              classes={classes}
                            />
                          }
                          onClick={() => {
                            setAnswer(el.answerTitle);
                            setIsAnswered(true);
                          }}
                          label={<span>{el.answerTitle}</span>}
                        />
                      )}
                    </RadioGroup>
                  </div>
                }
  
                {(!lockQuiz && !passed) &&
                  <div style={{textAlign: "right"}}>
                    <CustomButton
                      mode={isAnswered ? "primary" : "disabled"}
                      className={classes.submitAnswerBtn}
                      label={"Submit"}
                      onClick={() => {
                        if (isAnswered) {
                          let answered = true;
  
                          setOpenedModal(false);
                          setPassed(false);
  
                          if (isMultiSelect) {
                            item.answers.map((el) => {
                              if ((el.isCorrect && !el.isChecked) || (!el.isCorrect && el.isChecked)) {
                                answered = false;
                              }
                            });
                          } else {
                            item.answers.map((el) => {
                              if (!answer || (answer === el.answerTitle && !el.isCorrect)) {
                                answered = false;
                              }
                            });
                          }
  
                          if (answered) {
                            setLockQuiz(true);
                          }
  
                          setPassed(answered);
                          setOpenedModal(true);
  
                          let answers = '';
  
                          if (isMultiSelect && item.answers) {
                            answers = item.answers.filter(el => el.isChecked).map((el) => el.answerTitle).join(', ');
                          }
  
                          if (!isMultiSelect) {
                            answers = answer;
                          }
                          quizApi.storeQuizAttempt({
                            collection: collectionId,
                            question: item.id,
                            set: setId,
                            activity: activityId,
                            path: url,
                            answers: answers,
                            programId: program.id,
                            isCorrect: answered,
                            folderId,
                          }).then((data) => {
                            if (!data.error && data.data) {
                              updateCurrentStateofQuizesAnswers(data.data);
                            }
                          });
                        }
                      }}
                    />
                  </div>
                }
  
                {openedModal && passed && <Alert icon={false} className={classes.alert} style={{ backgroundColor: teal[400], color: "#fff" }} onClose={() => setOpenedModal(false)}>Well done!</Alert>}
                {openedModal && !passed && <Alert icon={false} className={classes.alert} onClose={() => setOpenedModal(false)} variant="filled" severity="error" style={{ backgroundColor: "#F9B88C", color: "#000" }}>Mistakes are a great way to learn. Retry?</Alert>}
              </div>
          </div>
        </CardContent>
      </Box>
    );
  };
  export default Quiz;