import client from "../../../apolloClient";
import { gql } from "apollo-boost";
import obj2arg from "graphql-obj2arg";

export const saveCurrentRole = ({ programId, cpOrganizationId }) => {
  const graphQlArguments = {
    programId: programId ? parseInt(programId) : null,
    cpOrganizationId: cpOrganizationId
      ? parseInt(cpOrganizationId)
      : null,
  };

  return client.mutate({
    mutation: gql`
      mutation {
        setCurrentRole(${obj2arg(graphQlArguments, { noOuterBraces: true })})
      }`
  });
};
