import React from "react";

import Grid from "@material-ui/core/Grid";
import {
  Card,
  CardContent,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(() => ({
  projectSkeletonCard: {
    margin: 12, 
    height: "fit-content", 
    width: "-webkit-fill-available"
  },
  skeletonHeader: {
    marginBottom: 6
  },
  skeletonCircle: {
    width: 100,
    height: 100,
    margin: "auto"
  },
  skeletonBar: {
    marginTop: 10,
  },
  skeletonBarShort: {
    marginTop: 10,
    width: "60%"
  }
}));

const SkeletonCards = (props) => {
  const {count} = props;
  const classes = useStyles();

  return [...Array(count)].map((e, i) => (
    <SkeletonCard key={i} classes={classes}/>
  ));
} 

const SkeletonCard = (props) => {
  const {classes} = props;
  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
      <Card className={classes.projectSkeletonCard}>
        <Skeleton animation="wave" variant="rect" className={classes.skeletonHeader} />
        <Skeleton
          animation="wave"
          variant="circle"
          className={classes.skeletonCircle}
        />
        <CardContent>
          <React.Fragment>
            <Skeleton
              animation="wave"
              variant="rect"
              className={classes.skeletonBar}
            />
            <Skeleton
              animation="wave"
              variant="rect"
              className={classes.skeletonBarShort}
            />
          </React.Fragment>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default SkeletonCards;
