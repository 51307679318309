import React from "react";
import clsx from 'clsx';

import { makeStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import { TASK_STATUSES } from '../../../../utils/constants';
import * as colors from "../../../../theme/colors";

const useStyles = makeStyles(() => ({
  initials: {
    color: colors.darkThemeBlue,
    fontSize: 14,
    fontWeight: "bold",
    padding: "12px 12px 12px 25px",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: colors.lightBlue,
      "& $viewBtn": {
        opacity: 1,
      }
    },
  },
  currentActive: {
    backgroundColor: colors.lightBlue,
  },
  clickableTableRow: {
    cursor: "pointer",
  },
  warningIcon: {
    width: 20,
    color: colors.lightGrey,
    marginLeft: 10,
  },
  iconCell: {
    padding: 0,
  },
}));

const UserTableBody = ({ answers, answersToRender, setViewUserKey, viewUserKey, onShowMembersClose, getCommentsByUser, isAnswerGroup, showWarningIcon }) => {
  const classes = useStyles();

  return (<TableBody>
    {!!answersToRender.length && answersToRender.map((student) => {
      const answer = answers[student.userId] ? answers[student.userId][0] : {};
      const name = [student.firstName, student.lastName].filter(e => !!e).join(" ");
      const markedIncomplete = isAnswerGroup ? answer?.isSubmitted && answer?.status === TASK_STATUSES.incomplete
        : answer?.status === TASK_STATUSES.incomplete;

      return (<TableRow key={student.userId} className={!!answer.userId ?
        clsx(classes.tableRow, classes.clickableTableRow, viewUserKey === answer.userId && classes.currentActive)
        : classes.tableRow}>
        <TableCell component="th" scope="row" className={classes.initials} onClick={() => {
          if (!!answer.userId) {
            if (!answer.comments && !isAnswerGroup) {
              getCommentsByUser({
                programId: answer.programId,
                userId: answer.userId,
                question: answer.question,
                fullPath: `${answer.path}-${answer.question}`,
              });
            }
            setViewUserKey(student.userId);
            onShowMembersClose();
          }
          }}>
          {name}
        </TableCell>
        <TableCell className={classes.iconCell}>
          {showWarningIcon && markedIncomplete && <WarningRoundedIcon className={classes.warningIcon}/>}
        </TableCell>
      </TableRow>);
    })}
  </TableBody>)
}

export default UserTableBody;