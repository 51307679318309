import { call, put } from "redux-saga/effects";

import {
  programRatingsReceived,
  ratingSaved,
  onError
} from "../actions/rating";
import {
  showSuccessMessage,
} from "../actions/notification";
import * as ratingApi from "../api/bl-query/rating";

export function* saveRating({ payload }) {
  try {
    let {
      data: { SavedRating, error }
    } = yield call(ratingApi.createRating, payload);

    if (error) {
      yield put(onError(error));
    }
    else {
      yield put(ratingSaved(SavedRating));
      yield put(showSuccessMessage('Your feedback is saved.'));
    }
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* getRatingsByProgram({ payload }) {
  try {
    let {
      data: { Ratings }
    } = yield call(ratingApi.getRatingsByProgram, payload);

    yield put(programRatingsReceived(Ratings));
  } catch (e) {
    console.log(e);
  }
}
