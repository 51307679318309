import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CardActions,
  Typography
} from "@material-ui/core";
import clsx from 'clsx';

import * as decisionMakerActions from "../../../actions/decisionMakers";

import * as colors from "../../../theme/colors";
import CustomButton from "../../../components/customButton/customButton";
import { TooltipWrapper } from "../../../components/tooltip/tooltip";
import Link from "../../../components/Link";
import { isFinishedHardClosureProgram } from '../../../utils/checkFinishedHardClosureProgram';
import { connectTo } from "../../../utils/generic";
import { getCurriculumDisplayName, getProgramDisplayName } from '../../../utils/generic';
import { PROGRAM_TYPES } from "../../../utils/constants";
import { defaultProgramColors } from "../../../utils/programRoleLabels";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    padding: 20,
    minWidth: 300,
    justifyContent: 'center',

    [theme.breakpoints.down('xs')]: {
      padding: 0,
      marginBottom: 20
    }
  },
  header: {
    padding: 0,
    textTransform: 'uppercase',
    marginBottom: 8
  },
  subheader: {
    fontSize: 14,
  },
  card: {
    width: "100%",
    cursor: "pointer",
    padding: 10,
    display: "inline-grid"
  },
  cardContent: {
    padding: '8px 16px',

    [theme.breakpoints.down('xs')]: {
      padding: 8
    }
  },
  codeContainer: {
    fontWeight: "bold",
    color: colors.indigoA700,
    display: 'flex',
    justifyContent: 'space-between',
    '& h3': {
      fontFamily: "'Roboto Mono',monospace",
    },
    [theme.breakpoints.up('sm')]: {
      padding: '8px 16px',
    }
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  imageContainer: {
    width: 100,
    height: 100,
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    fontSize: '1.25rem',
    alignItems: 'center',
    flexShrink: 0,
    lineHeight: 1,
    borderRadius: '50%',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: 75,
      height: 75
    },
    '&.image-disabled': {
      filter: 'grayscale(100%)',
    },
  },
  programName: {
    '&.programName-disabled': {
      color: colors.grey400,
    },
  },
  programCode: {
    fontWeight: 700,
  },
  renewBtn: {
    height: 42,
    width: 150,
  },
  statusBtn: {
    color: colors.darkThemeBlue,
    fontWeight: 700,
    cursor: 'default',
    "&.statusBtn-disabled": {
      color: colors.grey400,
    }
  },
  inactiveLabel: {
    color: colors.darkThemeBlue,
    fontWeight: "bold",
    "&:hover": {
      color: colors.grey400,
    },
  },
  link: {
    color: colors.darkThemeGreen,
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
      color: colors.lightThemeGreen,
    },
  },
  tooltipText: {
    fontSize: "1em",
  }
}));

const tooltipMsg = (status, decisionMaker, classes) => {
  switch (status) {
    case 'Application In Review':
      return "A member of our team will be reaching out to you to communicate next steps.";
    case 'In Review':
      return "A member of our team will be reaching out to you to communicate next steps.";
    case 'Pending Applicant Verification':
      return "Since you are not employed at the Club Host Site, you must clear a Background Check in order for your Club to be approved. Please check your email for a link from VerifiedFirst.";
    case 'Pending PA':
      return `Please have your Signatory ${decisionMaker} sign off on your Club's Participation Agreement, which was sent to them at the provided email.`;
    case 'Pending Facilitators':
      return (<Typography className={classes.tooltipText}>Your Club needs at least one Facilitator in order to be approved. Please review our Toolkit here <Link className={classes.link} href="https://girlswhocode.zendesk.com/hc/en-us/articles/115006976628-I-want-to-volunteer-as-a-Facilitator-for-a-Club-that-has-already-been-approved-What-are-the-next-steps-" target="_blank">link</Link></Typography>)
    default:
      return '';
  }
};

const identifyInactiveStatus = (status) => {
  if (status === 'Pending Facilitators' || status === 'Pending Final Approval' || status === 'Pending Applicant Verification' || status === 'Pending PA' || status === 'Application In Review' || status === 'Application Incomplete' || status === 'In Review') {
    return 'PENDING';
  } else if (status === 'Rejected') {
    return 'REJECTED';
  } else if (status === 'Withdrawn' || status ==='Withdrawn - COVID') {
    return 'WITHDRAWN';
  } else if (status === 'Dropped') {
    return 'DROPPED';
  }
};

const ProgramCard = ({ program, icon, onClick, subheader, onRenewClick, enableRenewal,
  isCompleted, isRenewed, programTypesById, decisionMakers, loadDecisionMaker }) => {
  const classes = useStyles();
  const programTypeDetails = program.communityPartnershipDetails || programTypesById[program.programTypeId];
  const curriculumName = !!program.curriculum?.displayName && getCurriculumDisplayName(program.curriculum.displayName);
  const programType = subheader || programTypeDetails?.name || programTypeDetails?.programType && getProgramDisplayName(programTypeDetails.programType) || PROGRAM_TYPES.CLUB;

  const programTime = !!program && programTypeDetails?.programTimeEnabled && !!program.time
    ? program.time 
    : null;

  const finishedHardClosureProgram = !!program && !!programTypeDetails && isFinishedHardClosureProgram(programTypeDetails, program.projectEndDate, program.hardClosureEndDate);
  const inactiveLabel = !program.completed ? program.detailedStatus : null;
  const labelStatus = identifyInactiveStatus(program.detailedStatus);
  const decisionMaker = decisionMakers?.[program.decisionMakerId]?.fullName || '';
  const pendingTooltip = tooltipMsg(inactiveLabel, decisionMaker, classes);

  useEffect(() => {
    program.decisionMakerId && loadDecisionMaker(program.decisionMakerId);
  }, [program.decisionMakerId]);

  const renewClick = (e) => {
    e.stopPropagation();
    onRenewClick();
  }
  const stopClick = (e) => {e.stopPropagation();}

  const keyDown = (e) => {
    if ((!inactiveLabel || program.detailedStatus === "Active") && e.key === "Enter") {
      onClick();
    }
    return;
  }

  const programCodeColor = !isCompleted ? programTypeDetails?.programType === PROGRAM_TYPES.COLLEGE_LOOP ? colors.mainThemeBlack : programTypeDetails?.backgroundColor || defaultProgramColors.backgroundColor : colors.grey400;

  return (
    <Grid item xs={12} md={6} className={classes.container}>
      <Card className={classes.card} 
        onClick={!inactiveLabel || program.detailedStatus === "Active" ? onClick : ''} 
        sel={program.name} 
        tabIndex="0" 
        onKeyDown={keyDown}>
        <div className={classes.content}>
          <CardContent className={classes.cardContent}>
            <CardHeader
              className={classes.header}
              subheader={curriculumName ? curriculumName : programType}
              classes={{ subheader: classes.subheader }}
            />
            <Typography className={clsx(classes.programName, `programName-${isCompleted ? 'disabled' : ''}`)} sel="prog-name">{program.name}</Typography>
          </CardContent>
          <CardMedia className={clsx(classes.imageContainer, `image-${isCompleted ? 'disabled' : ''}`)} image={icon} />
        </div>

        <CardActions className={classes.codeContainer}>
          <Typography align="left" component="h3"
            style={{ color: programCodeColor }}
            className={classes.programCode} sel="prog-code">
            {program.code}
          </Typography>
          {enableRenewal && !isRenewed && inactiveLabel !== 'Rejected' && <CustomButton className={classes.renewBtn} onClick={renewClick} label={"RENEW"} sel="renew" />}

          {enableRenewal && isRenewed &&
            <Typography
              className={classes.statusBtn}
              onClick={stopClick}
              sel="renewed"
            >RENEWED</Typography>}

          {inactiveLabel && (inactiveLabel === 'Rejected' || !enableRenewal) &&
            <TooltipWrapper
              title={pendingTooltip}
              interactive
              sel="inactive-tooltip"
              >
              <Typography
                className={classes.inactiveLabel}
                sel="inactive-label"
              >{labelStatus}
              </Typography>
            </TooltipWrapper>}

          {finishedHardClosureProgram &&
            <TooltipWrapper
              title="The end date has passed. You can view the curriculum, but not submit new answers."
              interactive
              sel="spp-finished-tooltip"
            >
              <Typography
                className={classes.statusBtn}
                sel="spp-finished"
                onClick={stopClick}
              >VIEW ONLY</Typography>
            </TooltipWrapper>}

          {!finishedHardClosureProgram && programTime && 
            <Typography
              className={clsx(classes.statusBtn, (isCompleted ? 'statusBtn-disabled' : ''))}
              sel="sip-time"
              onClick={stopClick}
            >{programTime}</Typography>}
        </CardActions>
      </Card>
    </Grid>
  );
};

export default connectTo(
  ({ decisionMakers}) => ({ ...decisionMakers }),
  { ...decisionMakerActions },
  ProgramCard
);
