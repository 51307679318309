import React from 'react';

import MaskedInput from 'react-text-mask';
import TextField from '@material-ui/core/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";

const internationalMask = ['+', /[1-9]/, /[1-9]/, /[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
const domesticMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

const domesticPlaceholder = "(123) 456-7890";
const internationalPlaceholder = "+1 234 456 7890";

const TextMaskCustom = (props) => {
  const { inputRef, isDomestic, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={isDomestic ? domesticMask : internationalMask}
      placeholderChar={'\u2000'}
      placeholder={isDomestic ? domesticPlaceholder : internationalPlaceholder}
    />
  );
}

export default ({ field, form, isDomestic, Icon, ...props }) => {
  let error = form.errors[field.name];

  return (
    <TextField
      {...props}
      fullWidth
      error={error}
      variant="outlined"
      name={field.name}
      value={field.value}
      onChange={field.onChange}
      id="formatted-numberformat-input"
      inputProps={{
        isDomestic: isDomestic,
      }}
      InputProps={{
        inputComponent: TextMaskCustom,
        startAdornment: Icon ? (
          <InputAdornment position="start">
            <Icon />
          </InputAdornment>
        ) : null,
      }}
    />
  );
};
