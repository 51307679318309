import { call, put } from 'redux-saga/effects';

import * as facilitatorsConsentAgreement from '../api/facilitatorsConsentAgreement';
import { setFacilitatorsConsentAgreementSuccess, setFacilitatorsConsentAgreementError } from '../actions/facilitatorsConsentAgreement';
import { recievedFacilitatorsConsent } from '../actions/auth';

import { showErrorMessage, showSuccessMessage } from '../actions/notification';

const successConsentAgreementMsg = 'Consent agreement was successfully updated';
const errorConsentAgreementMsg = "Consent agreement wasn't updated";

export function* setFacilitatorsConsentAgreement({ payload }) {
  const { programId } = payload;

  try {
    const { data } = yield call(
      facilitatorsConsentAgreement.setFacilitatorsConsentAgreement,
      programId
    );

    yield put(setFacilitatorsConsentAgreementSuccess(programId));
    yield put(recievedFacilitatorsConsent(data));
    yield put(showSuccessMessage(successConsentAgreementMsg));
  } catch (e) {
    yield put(setFacilitatorsConsentAgreementError());
    yield put(showErrorMessage(errorConsentAgreementMsg));
    console.log(e);
  }
}
