const whiteFont = "#FFFFFF";
const darkFont = "#003046";

const accentTeal = "#0D9C90";
const accentBlue = "#0D38D3";

export const ColorTheme = {
  black1: {
    b: "#EDEDED",
    f: darkFont,
    a: accentTeal
  },
  black2: {
    b: "#CECECE",
    f: darkFont,
    a: accentTeal
  },
  black3: {
    b: "#7A7A78",
    f: whiteFont,
    a: accentTeal
  },
  black4: {
    b: "#1A1918",
    f: whiteFont,
    a: accentTeal
  },

  teal1: {
    b: "#D7F9F4",
    f: darkFont,
    a: accentTeal
  },
  teal2: {
    b: "#43D6B9",
    f: darkFont,
    a: accentTeal
  },
  teal3: {
    b: "#0D9C90",
    f: whiteFont,
    a: accentTeal
  },
  teal4: {
    b: "#0C6B68",
    f: whiteFont,
    a: accentTeal
  },

	blue1: {
    b: "#E5F8FF",
    f: darkFont,
    a: accentTeal
  },
  blue2: {
    b: "#0169E1",
    f: whiteFont,
    a: accentTeal
  },
  blue3: {
    b: "#0D38D3",
    f: whiteFont,
    a: accentTeal
  },
  blue4: {
    b: "#003046",
    f: whiteFont,
    a: accentTeal
  },

  red1: {
    b: "#FEEFEE",
    f: darkFont,
    a: accentBlue
  },
  red2: {
    b: "#F37C6F",
    f: darkFont,
    a: accentBlue
  },
  red3: {
    b: "#EA3850",
    f: whiteFont,
    a: accentBlue
  },
  red4: {
    b: "#A31746",
    f: whiteFont,
    a: accentBlue
  },

  orange1: {
    b: "#FFF3E8",
    f: darkFont,
    a: accentBlue
  },
  orange2: {
    b: "#F9B88C",
    f: darkFont,
    a: accentBlue
  },
  orange3: {
    b: "#FA7815",
    f: darkFont,
    a: accentBlue
  },

  yellow1: {
    b: "#FFF9E2",
    f: darkFont,
    a: accentBlue
  },
  yellow2: {
    b: "#FCE688",
    f: darkFont,
    a: accentBlue
  },
  yellow3: {
    b: "#FDD946",
    f: darkFont,
    a: accentBlue
  },

  purple1: {
    b: "#6210B7",
    f: whiteFont,
    a: accentTeal
  },
}

export const defaultTheme = ColorTheme.teal3;

export const getTheme = (theme) => {
  return theme && ColorTheme[theme]
   ? ColorTheme[theme]
   : defaultTheme;
}