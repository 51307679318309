import { getAuthHeader } from '../tokenStorage';
import { axiosInstance } from './axiosInstance';

export const getMembersGroupsByProgram = async (programId) => {
  return axiosInstance.get(`/blapi/usersGroups/byProgram/${programId}`, {
    headers: getAuthHeader(),
  });
};

export const createUsersGroups = async (payload) => {
  return axiosInstance.post(
    '/blapi/usersGroups/createUsersGroups',
    payload,
    {
      headers: getAuthHeader(),
    }
  );
};

export const deleteUsersGroup = async (groupId) => {
  return axiosInstance.delete(`/blapi/usersGroups/deleteUsersGroup/${groupId}`, {
    headers: getAuthHeader(),
  });
};

export const updateUsersGroup = async (payload, groupId) => {
  return axiosInstance.put(
    `/blapi/usersGroups/updateUsersGroup/${groupId}`,
    payload,
    {
      headers: getAuthHeader(),
    }
  );
};