import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import * as moment from "moment";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Skeleton from '@material-ui/lab/Skeleton';

import HqAvatar from '../../../components/avatar/avatar';

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import { Typography } from "@material-ui/core";
import PostComment from "./PostComment";
import * as colors from "../../../theme/colors";
import { createMarkup } from "../../../utils/generic";
import AddAnnouncementCard from "./AddAnnouncement";
import linkifyHtml from "linkify-html";
import { ConfirmationReportModal } from "../../../components/modals/messages/confirmationReportModal";
import RoleLabel from '../../../components/roleLabel/roleLabel';

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  backButton: {
    marginRight: theme.spacing(1),
    cursor: "pointer"
  },
  image: {
    animation: "fade-in 1s forwards",
    display: "block",
    margin: "10px auto",
    maxHeight: "400px",
    maxWidth: "90%"
  },
  card: {
    maxWidth: 750,
    minWidth: '60%',
    margin: "15px auto 10px",
    color: theme.palette.primary.main,
    borderRadius: "3px",
    boxShadow: "1px 1px 9px rgba(0, 0, 0, 0.4)",
    boxSizing: "border-box",
    '& a': {
      color: `${colors.darkThemeGreen} !important`,
      textDecoration: 'none',
      cursor: 'pointer',
    },
    '& code': {
      backgroundColor: colors.grey200,
      padding: 2,
    },
    '& pre': {
      backgroundColor: colors.grey200,
      padding: 2,
    },
    '& p': {
      marginTop: 2,
      marginBottom: 2,
    },
    '& ul': {
      marginTop: 2,
      marginBottom: 2,
    },
    '& ol': {
      marginTop: 2,
      marginBottom: 2,
    },
  },
  cardHeaderContainer: {
    display: "flex",
    alignItems: 'center',

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start"
    }
  },
  editCardContentContainer: {
    margin: 0,
    padding: 0
  },
  cardHeader: {
    marginRight: 10,
    fontWeight: "bold"
  },
  programInfoPrep: {
    paddingRight: 5,
    [theme.breakpoints.up("md")]: {
      paddingLeft: 5
    }
  },
  cardContentContainer: {
    margin: "0 20px",
    padding: 0
  },
  childCard: {
    maxWidth: 750,
    margin: "10px auto",
    boxShadow: "none",
    color: theme.palette.primary.main
  },
  uploader: {
    width: "90%",
    marginBottom: 10
  },
  cardUploader: {
    display: "none"
  },
  cardImage: {
    cursor: "pointer",
    color: "#565656",
    opacity: "0.8",
    "&:hover": {
      opacity: "1"
    }
  },
  comments: {
    marginTop: 25
  },
  divider: {
    borderTop: "1px solid " + colors.blueGrey800,
    marginTop: 15,
  },
  loadMoreBtn: {
    marginTop: 15,
    color: "#20a8ed",
    fontSize: '1em',
    fontWeight: 700
  },
  bodyText: {
    color: theme.palette.primary.main,
    marginLeft: 56,

    [theme.breakpoints.down("sm")]: {
      marginLeft: 0
    },

    [theme.breakpoints.down("xs")]: {
      wordBreak: 'break-all',
    },
  },
  link: {
    color: colors.darkThemeGreen,
    textTransform: "uppercase",
    textDecoration: 'none',
    fontWeight: "bold",
    "&:hover": {
      textDecoration: "none"
    }
  },
  preloadContainer: {
    margin: "20px auto 0px",
    maxWidth: "750px",
    boxShadow: "none"
  },
  preloadFirstLine: {
    float: "left",
    width: "calc(100% - 50px)"
  },
  preloadSecondLine: {
    float: "left",
    marginTop: 5,
    width: "calc(100% - 50px)"
  },
  preloadContent: {
    float: "left",
    marginTop: 15,
    marginBottom: 35,
    width: "calc(100% - 50px)"
  },
  preloadLogo: {
    float: "left",
    marginRight: 10,
    width: 40,
    height: 40
  },
  generalPost: {
    paddingBottom: 16
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: 'block',
    },
  },
}));

const AnnouncementCard = ({
  post,
  isComment,
  removeMessage,
  reportMessage,
  editItem,
  addComment,
  userId,
  isViewMode,
  labels,
  isLoading,
  isFacilitator,
  push,
  studentView,
  isDataChanged,
  setEscapeConfirmDialog,
  setDataChanged,
  funcRef,
  userRoleLabels,
  program,
  programTypeDetails,
}) => {
  const classes = useStyles();

  const isShowMore = post.comments && post.comments.length > 2;
  const programName = post.room && post.room.program ? post.room.program.name : "";
  const generalPost = post.blocked ? true : false;
  const isOwner = post.owner.id === userId;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showAllComments, setShowAllComments] = React.useState(isShowMore);
  const [isEditMode, setIsEditMode] = React.useState(null);
  const [showLoader, setShowLoader] = useState(false);

  post.owner.firstName = !!post.owner.firstName && post.owner.firstName.length > 0
    ? post.owner.firstName
    : "";
  post.owner.lastName = !!post.owner.lastName && post.owner.lastName.length > 0
    ? post.owner.lastName
    : "";

  const [confirmationModal, setConfirmationModal] = useState(false);

  const userRoleLabel = userRoleLabels?.length ? userRoleLabels.find(label => label.programId === program?.id && label.userId === post.owner.id) : null;;

  const showConfirmation = (value) => {
    setConfirmationModal(value);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hadleEditItem = value => {
    handleClose();
    setIsEditMode(value);
  };

  const friendlyElapsedTime = date => {
    const newDate = moment(date);

    return newDate.format("MM/DD/YYYY h:mm A");
  };

  useEffect(() => {
    if (showLoader && !isLoading) {
      setShowLoader(false);
    }
  }, [showLoader, setShowLoader, isLoading]);
  return (
    <Card
      className={
        isComment 
          ? classes.childCard 
          : generalPost 
            ? clsx(classes.card, classes.generalPost)
            : classes.card}
      key={post.id}
    >
      <CardHeader
        avatar={
          <HqAvatar
            user={post.owner}
            isFacilitator={post.byFacilitator}
            src={generalPost ? post.owner.profilePicture : post.owner.avatarName && post.owner.avatarUrl}
          />
        }
        action={
          !generalPost && <IconButton aria-label="settings" onClick={handleClick} sel="message-actions">
            <MoreVertIcon />
          </IconButton>
        }
        title={
          <Box className={classes.cardHeaderContainer}>
            <Box className={classes.wrapper} component="span">
              <Typography className={classes.cardHeader}
              >{generalPost
                ? labels.welcomePostAuthor
                : `${post.owner.firstName} ${post.owner.lastName}`}
              </Typography>
              {userRoleLabel && <RoleLabel
                programTypeDetails={programTypeDetails}
                userRoleLabel={userRoleLabel}
              />}
            </Box>
            {isViewMode && (
              <Box>
              <span className={classes.programInfoPrep}>in</span>
              <Link href="/curriculum">{`${programName}`}</Link>
              </Box>
            )}
          </Box>
        }
        subheader={friendlyElapsedTime(post.modifiedAt)}
      />

      <CardContent className={!isEditMode ? classes.cardContentContainer : classes.editCardContentContainer}>
        {!isEditMode && (
          <Box>
            <Typography className={classes.bodyText} sel="message-body"
              dangerouslySetInnerHTML={createMarkup(linkifyHtml(post.body, { target: "_blank" }))}
            />
            {!!post.fileName && <img className={classes.image} src={post.fileName} sel="message-image" alt="message" />}
            {!isComment && !generalPost && <Divider className={classes.divider} />}
            {!isComment && !generalPost && <PostComment parentMessage={post} onSave={(comment) => {
              addComment(comment);
              setShowLoader(true);
            }} />}
            <Box>
              {post.comments && post.comments.length > 0 && (
                <Typography>{`${post.comments.length} comment${post.comments.length === 1 ? "" :"s"}`}</Typography>
              )}
              {isShowMore && (
                <Link
                  component="button"
                  variant="body2"
                  className={classes.loadMoreBtn}
                  onClick={() => {
                    setShowAllComments(!showAllComments);
                  }}
                >
                  {showAllComments ? "Load More Comments" : "Less Comments"}
                </Link>
              )}
            </Box>
          </Box>
        )}
        {isEditMode && (
          <AddAnnouncementCard
            onClose={() => hadleEditItem(false)}
            message={post}
            onSave={(data) => {
              editItem(data);
              setIsEditMode(false);
              setDataChanged(false);
            }}
            isComment={isComment}
            showEmailAll={isFacilitator}
            isEditMode={isEditMode}
            isDataChanged={isDataChanged}
            setEscapeConfirmDialog={setEscapeConfirmDialog}
            setDataChanged={setDataChanged}
            funcRef={funcRef}
          />
        )}
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {isOwner && (
            <MenuItem
              className={classes.link}
              onClick={() => hadleEditItem(true)}
              sel="edit"
            >
              Edit
            </MenuItem>
          )}
          {(isOwner || (isFacilitator && !studentView)) && (
            <MenuItem
              className={classes.link}
              onClick={() => removeMessage(post.id, isComment)}
              sel="delete"
            >
              Delete
            </MenuItem>
          )}
          {!isComment && !isViewMode && (
            <MenuItem
              className={classes.link}
              sel="view-this-post"
              onClick={() => push(`/post/${post.id}`)}>
                View this post
            </MenuItem>
          )}

          <MenuItem className={classes.link} onClick={() => showConfirmation(true)} sel="report">
            {isComment ? "Report this comment" : "Report this post"}
          </MenuItem>
        </Menu>
        {post.comments &&
          post.comments.length > 0 &&
          post.comments.map((el, index) => {
            if (showAllComments && index > 1) {
              return;
            }

            return (
              <AnnouncementCard
                key={el.id}
                post={el}
                isComment={true}
                removeMessage={removeMessage}
                editItem={editItem}
                userId={userId}
                reportMessage={reportMessage}
                isLoading={isLoading}
                isFacilitator={isFacilitator}
                studentView={studentView}
                isDataChanged={isDataChanged}
                setEscapeConfirmDialog={setEscapeConfirmDialog}
                setDataChanged={setDataChanged}
                funcRef={funcRef}
                userRoleLabels={userRoleLabels}
                program={program}
                programTypeDetails={programTypeDetails}
              />
            );
          })}

          {!isComment && isLoading && showLoader &&
            <Card className={classes.preloadContainer}>
              <CardContent>
                <React.Fragment>
                  <Skeleton animation="wave" variant="circle" className={classes.preloadLogo} />
                  <Skeleton animation="wave" variant="rect" className={classes.preloadFirstLine} />
                  <Skeleton animation="wave" variant="rect" className={classes.preloadSecondLine} />
                  <Skeleton animation="wave" variant="rect" className={classes.preloadContent} />
                </React.Fragment>
              </CardContent>
            </Card>
          }
      </CardContent>

      <ConfirmationReportModal
        isOpen={confirmationModal}
        onClose={() => showConfirmation(false)}
        onSave={() => {
          reportMessage(post.id, isComment);
          showConfirmation(false);
        }}
      />
    </Card>
  );
};

export default AnnouncementCard;
