import { createReducer } from "redux-act";

import * as a from "../actions/quizesAnswers";

const getDefaultState = () => ({
  isLoadingQuizesAnswers: false,
  quizesAnswers: {},
});

export default () =>
  createReducer(
    {
      [a.getQuizesAnswers]: (state) => {
        return {
          ...state,
          isLoadingQuizesAnswers: true,
        };
      },
      [a.receivedQuizesAnswers]: (state, payload) => {
        return {
          ...state,
          quizesAnswers: payload,
          isLoadingQuizesAnswers: false,
      }},
      [a.quizesAnswersError]: (state) => ({
          ...state,
          isLoadingQuizesAnswers: false,
          quizesAnswers: {},
      }),
      [a.updateCurrentStateofQuizesAnswers]: (state, payload) => {
        const quizesAnswers = state.quizesAnswers;
        if (payload?.question) {
          quizesAnswers[payload.question] = [payload];
        }
        return {
          ...state,
          quizesAnswers,
          isLoadingQuizesAnswers: false,
      }},
    },
    getDefaultState()
  );
