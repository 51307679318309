import { createAction } from 'redux-act';

export const getProgramByCode = createAction();
export const receivedProgramInfo = createAction();

export const getEnrollmentData = createAction();
export const receivedEnrollmentData = createAction();

export const setCurrentStep = createAction();
export const receiveCurrentStep = createAction();

export const enrollUserInClub = createAction();
export const enrollUserInCommunityPartnership = createAction();

export const getEnrollmentFormData = createAction();
