import * as Yup from "yup";
import { programTypes } from "../containers/JoinProgram/constants";

export const programValidationMsgs = {
  doesntExist: "Please check that you have typed your code correctly.",
  alreadyJoined: "Already joined this program",
  inActive: "This program is no longer active.",
  countryNotValid: "You may only join a program that is in your country.",
  childOrgError:
    "You must be at least 18 years old to join a partner organization.",
  childProgramError:
    "No Users Can Join a SIP Program, Self Paced Program or Campus Program using a code.",
  anonymousUsersClub: "Members of this Club must be added by the Facilitator.",
  defaultProgram: "Code belongs to default program",
};

export const programAddressValidationObj = {
  shippingCity: Yup.string().max(30, "Must be 30 characters or less"),
  shippingStreet: Yup.string().max(255, "Must be 255 characters or less"),
  shippingState: Yup.string().max(20, "Must be 20 characters or less"),
  shippingZipCode: Yup.number()
    .positive()
    .nullable()
    .typeError("shippingZipCode must be a positive number"),
}

const adultAge = 18;

const excludedProgramTypesForChildren = [programTypes.COMMUNITY_PARTNERSHIP];

export const validateProgram = (selfMinAge, age, program, hqDefaultProgram, validateAge, isEnrollingChild = false, allProgramTypes) => {
  if (!program) {
    return programValidationMsgs.doesntExist;
  }

  if (program.id == hqDefaultProgram) {
    return programValidationMsgs.defaultProgram;
  }

  if (program.anonymousUser) {
    return programValidationMsgs.anonymousUsersClub;
  }

  if (!program.active || program.completed) {
    return programValidationMsgs.inActive;
  }

  const allowedProgramTypes = allProgramTypes.length ? allProgramTypes.filter(type => type.addMembersEnabled).map(({ programType }) => programType) : [];
  allowedProgramTypes.push(programTypes.COMMUNITY_PARTNERSHIP);

  const { programType } = program;

  if (isEnrollingChild || (validateAge && (isNaN(age) || (!!age && (age < selfMinAge || age < adultAge))))) {
    const orgTypeExist = excludedProgramTypesForChildren.find((e) => e === programType);
    if (orgTypeExist && program) {
      return isEnrollingChild ? programValidationMsgs.doesntExist : programValidationMsgs.childOrgError;
    }
  }

  const programTypeAllowed = allowedProgramTypes.find((e) => e === programType);
  if (!programTypeAllowed && program) {
    return programValidationMsgs.childProgramError;
  }
};

// add event and styles to dynamic render checkbox
export const renderCheckboxes = () => {
  // add event and styles to dynamic render checkbox
  const inputsWithDataAttr = document.querySelectorAll("[data-attr]");
  for (let input of inputsWithDataAttr) {
    const attr = input.getAttribute("data-attr");
    const parent = input.parentNode;
    input.setAttribute('checkbox', 'true');
    const checkbox = document.createElement("input");
    checkbox.setAttribute('id', attr);
    checkbox.type = "checkbox";
    const label = document.createElement('label');
    label.setAttribute("for", attr);
    label.innerHTML = input.parentNode.innerHTML.split('checkbox="true">')[1];
    parent.setAttribute("class", 'md-checkbox');

    // Adding attributes for acessibility to checkmark and label;
    label.setAttribute("id", `id=${attr}`);
    checkbox.setAttribute('tabindex', '0');
    checkbox.setAttribute('aria-labelledby', `id=${attr}`);

    checkbox.setAttribute('listener', 'true');
    parent.textContent = '';
    parent.appendChild(checkbox);
    parent.appendChild(label);
  }
};