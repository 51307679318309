import image from './Image';

const cta = `
  name
  text
  url
  icon {
    ${image}
  }
  alternateText`;

export default cta;
