import { call, put } from "redux-saga/effects";

import {
  receivedCalendarData,
  receivedProgramsAndMembersInfo,
  receivedUpdateEventData,
  receivedDeletedEvent,
  receivedCreatedEvent,
  receivedEventDetailsInfo,
  onError
} from "../actions/calendar";
import { showErrorMessage, showSuccessMessage } from "../actions/notification";

import * as calendarsApi from "../api/calendars";
import { getProgramMembers } from "../api/programs";
import * as programsApi from "../api/programs";
import * as calendarsQueriesApi from "../api/graphql/query/calendarPage.queries";

export function* loadCalendarsData({ payload }) {
  try {
    const { pageNumber } = payload;
    const { data } = yield call(calendarsApi.getUserEvents, payload);
    const {
      data: { CalendarPageQuery },
    } = yield call(calendarsQueriesApi.GetCalendarPageLabels);

    yield put(
      receivedCalendarData({ events: data.events, totalEvents: data.eventsCount, calendarLabels: CalendarPageQuery, pageNumber })
    );
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* updateEventData({ payload }) {
  try {
    let { data } = yield call(calendarsApi.updateEvent, payload);
    if (data?.error) {
      throw Error(data.error);
    }
    yield put(showSuccessMessage("Calendar event was updated successfully"));
    yield put(receivedUpdateEventData(data));
  } catch (e) {
    yield put(showErrorMessage("Calendar event was not updated"));
    yield put(onError());
  }
}

export function* createEventData({ payload }) {
  try {
    let { data } = yield call(calendarsApi.createEvent, payload);
    if (data?.error) {
      throw Error(data.error);
    }
    yield put(showSuccessMessage("Calendar event was created successfully"));
    yield put(receivedCreatedEvent(data));
  } catch (e) {
    yield put(showErrorMessage("Calendar event was not created"));
    yield put(onError());
  }
}

export function* deleteEvent({ payload: { eventId } }) {
  try {
    yield call(calendarsApi.deleteEvent, eventId);
    yield put(showSuccessMessage("Calendar event was deleted successfully"));
    yield put(receivedDeletedEvent(eventId));
  } catch (e) {
    yield put(showErrorMessage("Calendar event was not deleted"));
    yield put(onError());
  }
}

export function* getProgramsAndMembersInfo({ payload }) {
  try {
    const { userProgramIds: ids, loadProgramsOnly } = payload;
    let { data } = yield call(programsApi.getProgramsByIds, { ids });

    if (!loadProgramsOnly) {
      for (const p of data) {
        try {
          let { data: members } = yield call(getProgramMembers, {
            id: parseInt(p.id, 10),
          });
        p.members = members;
        } catch (e) {
          console.log(e);
        }
      }
    }

    yield put(receivedProgramsAndMembersInfo(data));
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* getEventDetailsInfo({ payload }) {
  try {
    const { id } = payload;
    let { data } = yield call(calendarsApi.getEventProgramsAndAttendees, id);

    yield put(receivedEventDetailsInfo({id, ...data}));
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}
