import React from "react";
import { push as pushToRoute } from "connected-react-router";

import { connectTo } from "../utils/generic";

import * as authActions from "../actions/auth";
import * as contentfulActions from "../actions/contentful";
import * as globalActions from "../actions/global";
import * as communityPartnershipActions from "../actions/communityPartnerships";

import * as trackingActions from '../actions/tracking';
import {
  CURRICULUM_VIEW
} from "../constants/trackingEvents";
import * as userProfileActions from "../actions/userProfile";

const CurriculumLoader = props => {
  const {
    children,
    global: { studentView },
    auth: { currentRole, user },
    contentful: { hqConfig },
    loadCurriculum,
    getCommunityPartnership,
    communityPartnership,
    createUsageLog,
  } = props;

  React.useEffect(() => {
    if (!user) return;
    if (!hqConfig) return;

    if (currentRole && currentRole.program && currentRole.program.curriculum) {
      // Load curriculum in case it's Student or Facilitator
      loadCurriculum({
        programId: currentRole.program.id,
        studentView,
      });
    }

    if (
      currentRole &&
      currentRole.cpOrganizationId &&
      currentRole.partnership &&
      (!communityPartnership ||
        communityPartnership.id !== currentRole.cpOrganizationId) &&
      hqConfig && hqConfig.communityPartnershipResourcesCurriculum
    ) {
      loadCurriculum({
        partnershipId: currentRole.cpOrganizationId,
        studentView: false,
      });

      getCommunityPartnership({ id: currentRole.cpOrganizationId });
    }

  }, [user, hqConfig, studentView]);

  React.useEffect(() => {
    if (!user) return;

    if (currentRole && currentRole.program && currentRole.program.curriculum) {
      const { href, pathname } = window.location;
      if (pathname.includes('/gwc')) {
        createUsageLog({
          action: CURRICULUM_VIEW,
          url: href,
          collection: pathname,
        });
      }
    }
  }, [user]);

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
};

export default connectTo(
  state => ({
    auth: state.auth,
    global: state.global,
    contentful: state.contentful,
    userProfile: state.userProfile,
    ...state.communityPartnerships
  }),
  {
    ...authActions,
    ...contentfulActions,
    ...globalActions,
    ...communityPartnershipActions,
    ...trackingActions,
    ...userProfileActions,
    push: pushToRoute,
  },
  CurriculumLoader,
);
