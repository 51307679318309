import React from "react";

import {
  makeStyles,
} from "@material-ui/core/styles";
import CheckCircle from '@material-ui/icons/CheckCircle';
import MoodIcon from '@material-ui/icons/Mood';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';

import { ColorTheme } from '../../../utils/colorTheme';

const useStyles = makeStyles(() => ({
  icon: {
    color: ColorTheme.blue2.b,
    fontSize: '1.25rem',
  },
}));

export const RatingIconProgress = (props) => {
  const classes = useStyles();
  const { rate } = props;
  
  if (!rate || [-1, 0, 1].indexOf(rate.rating) == -1) {
    return (<CheckCircle aria-hidden="false" aria-label="check icon" className={classes.icon} />)
  }

  switch (rate.rating) {
    case -1:
      return (<MoodBadIcon aria-hidden="false" aria-label="sad face icon" className={classes.icon} sel="sad-face"/>);
    case 0:
      return (<SentimentSatisfiedIcon aria-hidden="false" aria-label="neutral face icon" className={classes.icon} sel="neutral-face"/>);
    default:
      return (<MoodIcon aria-hidden="false" aria-label="happy face icon" className={classes.icon} sel="happy-face"/>);
  }
};