import React, { useState } from "react";

import {
  Box,
  CardContent,
  CardHeader,
  Typography,
  Divider,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";

import linkifyHtml from "linkify-html";

import { createMarkup } from "../../../../utils/generic";
import * as colors from "../../../../theme/colors";
import HqAvatar from '../../../../components/avatar/avatar';
import { getDateLabel } from "../../../../utils/generic";
import DeleteConfirmationDialog from '../../../../components/DeleteConfirmationDialog';
import AddComment from "./addComment";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(() => ({
  formWrapper: {
    display: 'flex',
  },
  commentContainer: {
    width: '96%',
  },
  cardHeaderContainer: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  cardHeader: {
    marginRight: 5,
  },
  createdAtLabel: {
    fontSize: 12,
    color: colors.lightGrey,
  },
  contentContainer: {
    display: 'flex',
    paddingLeft: 8,
    paddingRight: 0,
    justifyContent: 'space-between',
  },
  contentBox: {
    width: "90%",
    color: colors.dartThemeBlueGray,
    '& ul': {
      "padding-left": 40,
    },
    '& li': {
      lineHeight: 1.8,
      paddingLeft: 10,
      "&::marker": {
        color: colors.darkThemeGreen,
      }
    },
    '& a': {
      color: colors.darkThemeGreen,
      fontWeight: "medium",
      textTransform: "initial",
    },
  },
  removeIcon: {
    cursor: "pointer",
    color: colors.lightGrey,
  },
  verticalDivider: {
    marginRight: 25,
    width: 3,
    marginLeft: 10,
    fontWeight: 'bold',
  },
  commentCardHeader: {
    padding: 0,
  },
  iconBtn: {
    maxHeight: 45,
  },
}));

const CommentCard = ({ comment, answer, userId, deleteComment, isAnswerGroup, deleteAnswerGroupComment, history, updateComment, updateAnswerGroupComment, isLoading }) => {
  const classes = useStyles();
  const [dialogOpened, setDialogOpened] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const canUpdate = userId === comment.userId || userId === comment.createdBy;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeDialog = () => {
    setDialogOpened(false);
    handleClose();
  };

  const openDialog = () => {
    setDialogOpened(true);
  }

  const openEditing = () => {
    setIsEditingMode(true);
    handleClose();
  }

  const handleEditing = (body) => {
    if (isLoading) {
      return;
    }
    !isAnswerGroup ? updateComment(body) : updateAnswerGroupComment(body);
    setIsEditingMode(false);
    return false;
  }

  const handleDelete = () => {
    if (isRemoving) {
      return;
    }
    setIsRemoving(true);
    !isAnswerGroup ? deleteComment(
      {
        id: comment.id,
        programId: answer.programId,
        answerId: answer.id,
        userId: answer.userId,
        itemKey: `${answer.path}-${answer.question}`
      }) :
      deleteAnswerGroupComment(
      {
        id: comment.id,
        answerGroupId: answer.id,
        programId: answer.programId,
        answerId: answer.id,
        contentfulGroupId: answer.contentfulGroupId,
      });
  }

  const commentDate = comment.updatedAt ? comment.updatedAt : comment.createdAt;

  return (
    <Box className={classes.formWrapper}>
      <Divider orientation="vertical" flexItem className={classes.verticalDivider} />
    <Box key={comment.id} className={classes.commentContainer}>
      <CardHeader className={classes.commentCardHeader}
        avatar={
          <HqAvatar
            user={comment.owner}
            isFacilitator={true}
            src={comment.owner.avatarName && comment.owner.avatarUrl}
          />
        }
        title={
          <Box className={classes.cardHeaderContainer}>
            <Typography className={classes.cardHeader}
            >{`${comment.owner.firstName} ${!!comment.owner.lastName ? comment.owner.lastName : ''}`}
            </Typography>
            <Typography className={classes.createdAtLabel}>{getDateLabel(commentDate)}</Typography>
          </Box>
        }
      />
      <CardContent className={classes.contentContainer}>
        {!isEditingMode
            ? <Box className={classes.contentBox} dangerouslySetInnerHTML={createMarkup(linkifyHtml(comment.content, { target: "_blank" }))} />
            : <Box className={classes.contentBox}>
              <AddComment
                  answer={answer}
                  comment={comment}
                  onCancel={() => {setIsEditingMode(false)}}
                  isAnswerGroup={isAnswerGroup}
                  onSubmit={handleEditing}
                  history={history}
                  headerContent={answer.content}
              />
            </Box>
        }
        {canUpdate && !isEditingMode && <IconButton className={classes.iconBtn} aria-label="settings" onClick={handleClick} sel="message-actions">
          <MoreVertIcon />
        </IconButton> }

        {canUpdate && <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
          <MenuItem
              className={classes.link}
              onClick={openEditing}
              sel="edit"
          >
            Edit
          </MenuItem>
          <MenuItem
              className={classes.link}
              onClick={openDialog}
              sel="delete"
          >
            Delete
          </MenuItem>
        </Menu> }

      </CardContent>
    </Box>
    <DeleteConfirmationDialog
      open={dialogOpened}
      onClose={closeDialog}
      onConfirm={handleDelete}
      title="Are you sure you want to remove this comment?"
    />
  </Box>);
}

export default CommentCard;
