import { call, put } from 'redux-saga/effects';

import { receivedUserProgram } from '../actions/auth';
import { createTrackingLog } from "../actions/tracking";
import * as programApi from "../api/programs";

import {
  CLUB_RENEWAL_UPDATE_ERROR,
  CLUB_RENEWAL_UPDATE_SUCCESS,
} from "../constants/trackingEvents";

import {
  showErrorMessage,
  showSuccessMessage,
} from "../actions/notification";

export function* renewProgram({ payload }) {
  try {
    const { programId } = payload;
    const { data: isRenewStarted } = yield call(programApi.startProgramRenew, payload);

    if (isRenewStarted) {
      // TO DO Add Program API Call for start renewal.
      yield put(receivedUserProgram({ programId }));
      yield put(showSuccessMessage('Program renew process has been successfully registered'));
      yield put(
        createTrackingLog({
          event: CLUB_RENEWAL_UPDATE_SUCCESS,
          data: JSON.stringify(payload),
        })
      );
    } else {
      yield put(showErrorMessage('Error during program renew process appears'));
      yield put(
        createTrackingLog({
          event: CLUB_RENEWAL_UPDATE_ERROR,
          data: JSON.stringify(payload),
        })
      );
    }

  } catch (e) {
    yield put(showErrorMessage('Error during program renew process appears'));
    // TODO: throw generic error
    console.log('TODO: handle error here');
    console.log(e);
  }
}

