import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import PersonAddIcon from "@material-ui/icons/PersonAddRounded";

import * as colors from "../../../theme/colors";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import CustomButton from "../../../components/text-button/text-button";

import { sortLastThenFirst } from '../../../utils/generic';
import FacilitatorCardItem from './FacilitatorCardItem';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "8px 8px 12px 8px",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  cardHeader: {
    padding: 0,
    marginBottom: 12,
  },
  content: {
    padding: "0 0 0 4px",
    "&:last-child": {
      paddingBottom: 0,

      [theme.breakpoints.down("xs")]: {
        wordBreak: "break-all",
      },
    },
  },
  addFacilitatorLabel: {
    '&:hover': {
      color: colors.lightThemeGreen,
    },
  },
  boxButtonContent: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cardHeaderWrapper: {
    display: "flex",
  },
  addButton: {
    color: colors.white,
    background: colors.darkThemeGreen,
    marginLeft: "auto",
    '&:hover': {
      color: colors.white,
      background: colors.darkThemeGreen,
    },
  },
}));

const FacilitatorCard = ({ club, push, rolePermissions, isClub, isCollegeLoop, onInviteFacilitatorsClick, onAddLeadershipClick, roleLabels, setEditedUserRoleLabel, userRoleLabels, programTypeDetails }) => {
  const classes = useStyles();

  const data = club && club.facilitators
    ? sortLastThenFirst(club.facilitators)
    : [];

  const hasFacilitatorPermissions = rolePermissions.name === 'Facilitator';

  return (
    <Card className={classes.root}>
      <Box className={classes.cardHeaderWrapper}>
        <CardHeader
          title={<Typography className={classes.title}>{programTypeDetails?.facilitatorHeader || 'Facilitators'}</Typography>}
          className={classes.cardHeader}
        />
          {isCollegeLoop && hasFacilitatorPermissions &&
            <IconButton
              className={classes.addButton}
              onClick={() => onAddLeadershipClick()}
              aria-label="Add leadership button"
            >
              <PersonAddIcon fontSize="medium" />
            </IconButton>
          }
      </Box>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          {data && !!data.length ? (
            data.map((e, index) => {
              return <FacilitatorCardItem
                item={e}
                index={index}
                push={push}
                roleLabels={roleLabels}
                hasFacilitatorPermissions={hasFacilitatorPermissions}
                setEditedUserRoleLabel={setEditedUserRoleLabel}
                club={club}
                userRoleLabels={userRoleLabels}
                programTypeDetails={programTypeDetails}
              />;
            })
          ) : (
            <Typography>Program has no facilitators yet</Typography>
          )}
          {isClub && hasFacilitatorPermissions && <Box className={classes.boxButtonContent}>
            <CustomButton
              className={classes.addFacilitatorLabel}
              onClick={() => onInviteFacilitatorsClick()}
            >
              Add Facilitators
            </CustomButton>
          </Box>}
        </CardContent>
      </div>
    </Card>
  );
};

export default FacilitatorCard;
