import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ClockIcon from '@material-ui/icons/Lock';
import clsx from 'clsx';

import * as colors from '../../theme/colors';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    textAlign: 'center',
    padding: theme.spacing(3, 0),
    backgroundColor: colors.grey200,
  },
  text: {
    fontSize: 16,
    fontWeight: 'bold',
  },
}));

const ProgramEndedSection = ({className}) => {
  const classes = useStyles();
  return (
    <Box sel="spp-finished-section" className={clsx(classes.wrapper, className)}>
      <Box>
        <ClockIcon fontSize="large" />
      </Box>
      <Typography variant="h4" className={classes.text}>
        This program has ended. Answers are no longer accepted but activities can
        still be viewed.
      </Typography>
    </Box>
  );
};

export default ProgramEndedSection;
