import React, { useState } from "react";
import { makeStyles,  } from "@material-ui/core/styles";
import { OutlinedInput  } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import {
  InputAdornment,
} from "@material-ui/core/";

const useStyles = makeStyles({
  searchReset: {
    cursor: "pointer",
  },
});

const SearchBar = ({ value, labels, onChange, onReset }) => {
  const classes = useStyles();
  const [searchKey, setSearchKey] = useState(value);

  React.useEffect(() => {
    // this block need to reset value from parent component on clear
    setSearchKey(value);
  }, [value]);

  return (
    <OutlinedInput
      placeholder={labels && labels.projectListSearchLabel}
      className={classes.search}
      onChange={(event) => {
        setSearchKey(event.target.value);
        onChange(event.target.value);
      }}
      value={searchKey}
      inputProps={{
        style: {
          padding: "8px 5px 8px 0px"
        }
      }}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon color="action" />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment
          onClick={() => {
            setSearchKey("");
            onReset("search")
          }}
          position="end"
        >
          <ClearIcon className={classes.searchReset} color="action" sel="clear"/>
        </InputAdornment>
      }
    />
  );
};

export default SearchBar;
