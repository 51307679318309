import React, { useCallback, useRef } from "react";

import { makeStyles } from '@material-ui/core/styles';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import { StyledFormHelper } from '../../components/loginSignup/customFormHelper';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import * as colors from "../../theme/colors";

const useStyles = makeStyles({
  root: {
    borderRadius: 2,
  },
  errorLabel: {
    color: colors.redA400,
  },
  label: {
    zIndex: 2,
    padding: '0 2px',
  },
  adortmentBorder: {
    marginRight: 10,
  },
});

const Selector = ({
  className,
  field: { name, onChange, value },
  label,
  form: { errors, touched, setFieldValue },
  options,
  helperText,
  onValueChange,
  required = false,
  Icon,
  errorIsHtml,
  shrink = true,
  dropDownclassName,
  showHelperText,
  setShowProgramMembers,
  defaultProgramId,
  ariaLabel
}) => {
  const labelRef = useRef();
  const [width, setWidth] = React.useState(0);

  const useHookWithRefCallback = () => {
    const setRef = useCallback(node => {
      labelRef.current = node
    }, []);

    return [setRef];
  }

  const [ref] = useHookWithRefCallback();

  React.useEffect(() => {
    if(!labelRef){
      return;
    }

    setWidth(labelRef.current && labelRef.current.clientWidth ? labelRef.current.clientWidth : 0);
  }, [labelRef]);

  const classes = useStyles();
  if (!options) return null;
  let error = errors[name];
  let isTouched = touched[name];
  const selectedValue = options.find((op) => op.value == value);
  const _ariaLabel = `Choose ${ariaLabel || label}. ${selectedValue?.label ? `${selectedValue?.label} selected` : ""}`;

  return (
    <React.Fragment>
    <FormControl
      variant="outlined"
      fullWidth
      className={className}
      helperText={helperText}
      aria-label={label}
      required={required}
    >
      { label && (shrink || !value?.length) && <InputLabel id={`label-${name}`} ref={shrink ? ref : null} shrink={shrink} htmlFor={name} className={classes.label}>{label}</InputLabel> }
      <Select
        labelId={`label-${name}`}
        SelectDisplayProps={{role: 'listbox', "aria-required": required, "aria-label": _ariaLabel, "aria-labelledby": ""}}
        labelWidth={width}
        required={required}
        id={name}
        name={name}
        label={helperText}
        error={!!error && isTouched}
        helperText={error && isTouched ? (errorIsHtml ? "" : error) : helperText}
        value={value}
        MenuProps={{
          classes: { paper: dropDownclassName ? dropDownclassName : '' },
          // props to position dropdown under the input https://github.com/mui/material-ui/issues/5634#issuecomment-393299409
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
        InputProps={{
          placeholder: label,
          startAdornment: Icon ? (
            <InputAdornment position="start">
              <Icon />
            </InputAdornment>
          ) : null,
        }}
        startAdornment={Icon ? <div className={classes.adortmentBorder}> <Icon/></div> : null}
        displayEmpty
        onChange={(event) => {
          if(onValueChange) {
            onValueChange(event.target.value, setFieldValue);
          }

          if (defaultProgramId) {
            setShowProgramMembers(
              event.target.value !== parseInt(defaultProgramId)
            );
          }

          onChange(event);
        }}
      >
        {options.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    {error && isTouched &&
        (errorIsHtml
        ? <StyledFormHelper helperText={error} isError={true} isHtml={true} />
        : <FormHelperText className={classes.errorLabel}>{error}</FormHelperText>)}
     {!error && helperText && showHelperText && <StyledFormHelper helperText={helperText} />}
    </React.Fragment>
  );
};

export default Selector;
