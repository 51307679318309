import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Modal } from "../modal";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";

import CloseIcon from "@material-ui/icons/Close";
import MoodIcon from '@material-ui/icons/Mood';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';

import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import * as colors from "../../../theme/colors";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: 30,
  },
  closeWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    background: colors.lightGradient
  },
  closeBtn: {
    width: 50,
    marginRight: 10,
  },
  contentWrapper: {
    justifyContent: "space-around",
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    marginTop: 20
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 22,
    fontWeight: "bold",
    margin: "10px 0 10px 50px",
    width: "100%",
    textAlign: "center",
  },
  contentTitle: {
    color: theme.palette.primary.main,
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "center",
  },
  fieldWrapper: {
    color: theme.palette.primary.main,
    cursor: "pointer"
  },
  moodIcon: {
    width: 40,
    height: 40,
  }
}));

export const RatingModal = ({ programId, activitySlug, parentNode, isOpen, onSave, onCloseModal }) => {
  const classes = useStyles();

  const ratingSystem = {
    negative: -1,
    neutral: 0,
    positive: 1
  }

  const saveRating = (rating) => {
    //send request to save rating
    let ratingData = {
      activity: activitySlug,
      set: null,
      collection: null,
      path: window.location.pathname,
      programId,
      rating,
      locale: window ? window.location.host.replace('.girlswhocode.com', '') : '',
    };
    if (parentNode) {
      ratingData[parentNode.type === 'set' || parentNode.nodeType === 'set'
        ? "set"
        : "collection"] = parentNode.slug;
    }

    onSave(ratingData);

    //close modal
    onCloseModal();
  }

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal}>
      <div className={classes.closeWrapper}>
        <Typography variant="h4" className={classes.title}>
          Rate this activity
        </Typography>
        <IconButton
          edge="end"
          className={classes.closeBtn}
          color="inherit"
          onClick={onCloseModal}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </div>

      <DialogContent className={classes.dialogContent}>
        <Box>
          <Typography variant="h4" className={classes.contentTitle}>
            How much did you enjoy this activity?
          </Typography>
          <div className={classes.contentWrapper}>
            <IconButton
              className={classes.fieldWrapper}
              onClick={() => saveRating(ratingSystem.negative)}
              aria-label="sad face"
            ><MoodBadIcon className={classes.moodIcon} sel="sad-face"/></IconButton>

            <IconButton
              className={classes.fieldWrapper}
              onClick={() => saveRating(ratingSystem.neutral)}
              aria-label="neutral face"
            ><SentimentSatisfiedIcon className={classes.moodIcon} sel="neutral-face"/></IconButton>

            <IconButton
              className={classes.fieldWrapper}
              onClick={() => saveRating(ratingSystem.positive)}
              aria-label="happy face"
            ><MoodIcon className={classes.moodIcon} sel="happy-face"/></IconButton>
          </div>
        </Box>
      </DialogContent>
    </Modal>
  );
};
