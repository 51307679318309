import { CompositeDecorator, ContentBlock, ContentState } from 'draft-js';
import CustomLink from './Components/CustomLink';
import { ENTITY_TYPES } from './constants';

const findEntities = (type) => (
  contentBlock: ContentBlock,
  callback: (start, end) => void,
  contentState: ContentState
) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === type;
  }, callback);
};

export default new CompositeDecorator([
  {
    strategy: findEntities(ENTITY_TYPES.LINK),
    component: CustomLink,
  },
]);
