import { get } from 'lodash';

export const getIsAllSectionsAnswered = (contentSection, openTextAnswers, userActivitiesInfo, url, activity, isDone) => {
  const itemKey = activity ? `${url}/${activity}-${contentSection.id}` : `${url}-${contentSection.id}`;
  const currentAnswers = get(openTextAnswers.userAnswers, [`${itemKey}`], []);
  if (currentAnswers.length) {
    if (!contentSection.isOptionalTextAnswer && contentSection.requiredGrading && currentAnswers[0].status === 1 ||
      currentAnswers[0].status === null ||
      contentSection.isOpenTextAnswer && !contentSection.requiredGrading) {
      return true;
    }
  }

  if ((!contentSection.isOpenTextAnswer || contentSection.isOptionalTextAnswer) && (isDone || (
    Array.isArray(userActivitiesInfo) &&
    userActivitiesInfo.some(item => item?.contentSection === contentSection.id)))) {
    return true;
  }

  return false;
}

export const calcNodeProgress = ({ node, communityPartnershipLink, userActivitiesInfo, isFacilitator, removeDuplicates, studentView, openTextAnswers, programId }) => {
    let progress = 0;
  
    if (node && node.activities && node.type === 'set') {
      const fiteredOptionalActivities = node.activities.filter(a => !a.isOptional);
      const studentActivities = fiteredOptionalActivities
      .filter(activity => activity.permissionLevel !== 'Facilitators/Teachers only');
      const activitiesWithStudentContentSections = studentActivities.filter(r => {
        const studentsSectionsExist = r.contentSections 
          && r.contentSections.filter(e => !e.onlyVisibleToFacilitators).length > 0; 
        return studentsSectionsExist;
      })
        .map(a => communityPartnershipLink ? `${communityPartnershipLink}${node.path}/${a.slug}` : `/gwc${node.path}/${a.slug}`);
      const activitiesWithoutContentSections = studentActivities
      .map(a => communityPartnershipLink ? `${communityPartnershipLink}${node.path}/${a.slug}` : `/gwc${node.path}/${a.slug}`)
        .filter(s => activitiesWithStudentContentSections.indexOf(s) === -1);
      const allActivities = [...activitiesWithStudentContentSections, ...activitiesWithoutContentSections];
  
      // user activities info within current set
      const uActivitiesIntersection = userActivitiesInfo.filter(uai => allActivities.indexOf(uai.path) !== -1);
  
      // done activities
      const done = uActivitiesIntersection.filter(r => r.isDone === true).map(r => r.path).filter(onlyUnique);
      // activities that are in progress excluding done
      let inProgress = uActivitiesIntersection.filter(r => !r.isDone && done.indexOf(r.path) === -1);
  
      // excluding inProgress userActivities that don't have contentSection field but activity does have content sections
      inProgress = inProgress.filter((r) => {
        const activity = fiteredOptionalActivities.find(a => communityPartnershipLink ? `${communityPartnershipLink}${node.path}/${a.slug}` === r.path : `/gwc${node.path}/${a.slug}` === r.path);
  
        if (!activity.contentSections || activity.contentSections.length === 0) {
          return true;
        }
  
        return !!r.contentSection;
      }).map(r => r.path).filter(onlyUnique);

      const activitiesDone = studentActivities.map(activity => {
        const isDone = uActivitiesIntersection.some(activityInfo => activityInfo.title === activity.slug && !!activityInfo.isDone);
        return !!activity.contentSections.length && isDone && activity.contentSections.map(cs => {
          return getIsAllSectionsAnswered(cs, openTextAnswers, userActivitiesInfo, node.path, activity.slug, isDone)
        }).every(activity => !!activity)
      });

      const numberOfDoneActivities = flattenArray(activitiesDone).filter(e => Boolean(e)).length;
      // calculating progress
      progress = ((inProgress.length + numberOfDoneActivities * 2) / (allActivities.length * 2)) * 100;
    } else if (node && node.nodeType && (node.nodeType === 'activity' || node.nodeType === 'set')) {
      const completedActivity = userActivitiesInfo.find(r => (communityPartnershipLink ? r.path === `${communityPartnershipLink}${node.path}` : r.path === `/gwc${node.path}`) && r.isDone);
      let allSectionsAnswered;
      if (programId) {
        const doneSections = !!node.contentSections?.length && node.contentSections.map(cs => {
          return getIsAllSectionsAnswered(cs, openTextAnswers, userActivitiesInfo, node.path, null, !!completedActivity)
        }).filter(e => Boolean(e)).length;
        allSectionsAnswered = doneSections === node.contentSections?.length;
      }
      // for CP to not check answered sections
      if (!!completedActivity && (!programId || allSectionsAnswered)) {
        progress = 100;
      } else if (!node.contentSections) {
        const isViewed = userActivitiesInfo.find(r => (r.path === `/path${node.slug}`));
  
        if (isViewed) {
          progress = 99;
        }
      } else {
        const contentSectionsViewed = userActivitiesInfo.filter(r => communityPartnershipLink ? r.path === `${communityPartnershipLink}${node.path}` : r.path === `/gwc${node.path}`).map(r => r.contentSection);
        const allContentSections = isFacilitator && !studentView ? node.contentSections.map(r => r.id)
          : node.contentSections.filter(r => !r.onlyVisibleToFacilitators).map(r => r.id);
  
        const intersection = removeDuplicates(contentSectionsViewed.filter(c => allContentSections.includes(c)));
  
        progress = (intersection.length / allContentSections.length) * 100;
  
        if (progress === 100) {
          progress = 99;
        }
      }
    }
    return progress;
  };
  
  export const onlyUnique = (value, index, self) => self.indexOf(value) === index;
  
  export const getRouteInfo = (path, nodes, parentNode) => {
    const plainNodes = Object.values(nodes);
    const current = plainNodes.find((e) => e.slug === path);
    const currentIndex = plainNodes.indexOf(current);
    const isLast = currentIndex + 1 === plainNodes.length;
    const nextItem = isLast ? null : plainNodes[currentIndex + 1];
    return { plainNodes, current, parentNode, path, isLast, nextItem };
  };
  
  export const getNextItem = (curriculum, currentNode, parts) => {
    if (!curriculum || !currentNode || !parts) {
      return;
    }
    let current = curriculum;
    let nodeArray = [];
    for (var p of parts) {
      const node = getRouteInfo(p, current.nodes, current);
      current = node.current;
      nodeArray.push(node);
    }
    nodeArray = nodeArray.reverse();
    const parentNode = nodeArray.find((e) => !e.isLast);
  
    if (parentNode) {
      const { nextItem } = parentNode;
      return nextItem;
      // getDeepActivity(nextItem); -- temporary disabled due toHQ-2457
    } else {
      return nodeArray[nodeArray.length - 1].current;
    }
  };
  
  export const getCurrentNode = (curriculum, path) => {
    let next = null;
    let previous = null;
  
    if (!curriculum) return { current: null, next, previous };
  
    const parts = path
      .replace("/gwc", "")
      .split("/")
      .filter((p) => p.length > 0);
  
    let current = curriculum;
    let parentNode = null;
  
    const chain = [];
  
    for (const p of parts) {
      if (!current) {
        continue;
      }
  
      const plainNodes = Object.values(current.nodes);
      for (let i = 0; i < plainNodes.length; i++) {
        const n = plainNodes[i];
  
        if (n.slug === p) {
          chain.push(n);
  
          if (i !== 0) {
            previous = plainNodes[i - 1];
          } else {
            previous = null;
          }
  
          next = getNextItem(curriculum, current, parts);
        }
      }
  
      parentNode = {
        ...current,
      };
      current = current.nodes[p];
    }
  
    return {
      chain,
      current,
      next,
      parentNode,
      previous,
      parts,
    };
  };
  
  export const getStudentsSections = (activity) => {
    if (!activity || !activity.contentSections) {
      return null;
    }
  
    const items = activity.contentSections.filter(
      (item) => !item.onlyVisibleToFacilitators
    );
    return items.length > 0 ? items : null;
  };


export const applyFilters = (node, filters, searchProperties) => {
  const nodes = Array.isArray(node) ? node : Object.values(node ? node.nodes : []);
  const keys = Object.keys(filters);
  const filtersSelected = keys.filter(e => !!filters[e].length);
  if (!filtersSelected.length > 0) {
    return nodes;
  }

  const filteredNodes = nodes.filter(node => {
    const matchedFilters = filtersSelected.filter(x => {
      const filterValue = filters[x];
      return filterCondition(filterValue, node, x, searchProperties);
    });
    return matchedFilters.length === filtersSelected.length;
  });

  return filteredNodes;
};

const filterCondition = (value, node, key, searchProperties) => {
  switch (key) {
    case 'searchKey':
      return !!searchProperties.find(x => searchFilter(node[x], value));
    default:
      return !!value.find(y => !!node[key] && (y === node[key] || y === node[key].name));
  }
}

const searchFilter = (item, value) => {
  if (!item || !value) {
    return null;
  }

  if (Array.isArray(item)) {
    return item.find(e => e?.toLowerCase().includes(value?.toLowerCase()));
  }

  return item?.toLowerCase().includes(value?.toLowerCase());
} 

export const sortArray = (array, property, isNumber, skipProperty) => {
  return array.sort((a, b) => {
    const aValue = skipProperty
      ? a
      : isNumber && a[property]
        ? parseInt(a[property], 10) : a[property]
          ? a[property].toLowerCase() : null;
    const bValue = skipProperty
      ? b
      : isNumber && b[property]
        ? parseInt(b[property], 10) : b[property]
          ? b[property].toLowerCase() : null;
    return (aValue > bValue ? 1 : -1);
  });
}

export const getFiltersData = (nodes, searchProperties, isFacilitator, formatEstimatedMinutes, studentView) => {
  const mergedSetTypes = nodes.map(e => e.setType ? e.setType.name : '')
    .filter(e => e)
    .filter(onlyUnique)
    .map(item => { return { filterName: 'setType', attribute: item, label: item } });

  const allFacilitatorMinutes = nodes
    .map(e => e.estimatedTimeInMinutes)
    .filter(e => e)
    .filter(onlyUnique)
    .map(item => { return {
      filterName: 'estimatedTimeInMinutes',
      attribute: item, label: formatEstimatedMinutes(item)
    }});

  const allEaseOfFacilitation = isFacilitator && !studentView ? nodes
    .map(e => e.easeOfFacilitation)
    .filter(e => e)
    .filter(onlyUnique)
    .map(item => {
      return {
        filterName: 'easeOfFacilitation',
        attribute: item, label: item,
      }
    }) : [];

  const allSearchKeys = [].concat.apply([], searchProperties.map(e => {
    let items = [];
    nodes.forEach((node) => {
      const item = node[e];
      if(Array.isArray(item)) {
        items.push(...item);
      } else {
        items.push(item);
      }
    });
    return items;
  })).filter(e => e)
    .filter(onlyUnique);
    
  return {
    searchKeys: sortArray(allSearchKeys, null, false, true),
    setType: sortArray(mergedSetTypes, 'label'),
    estimatedTimeInMinutes: sortArray(allFacilitatorMinutes, 'attribute', true),
    easeOfFacilitation: sortArray(allEaseOfFacilitation, 'label'),
  };
};

export const flattenArray = (arr, depth = Infinity) => {
  if (!Array.isArray(arr)) {
    return [arr];
  }
  return depth > 0
    ? arr.reduce(
        (acc, curr) =>
          acc.concat(
            Array.isArray(curr) ? flattenArray(curr, depth - 1) : curr
          ),
        []
      )
    : arr.slice();
}
