import React, { useState } from "react";
import * as moment from "moment";

import { Formik, Field, Form } from "formik";
import { makeStyles } from "@material-ui/core/styles";

import { Modal } from "../../components/modals/modal";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import TextInput from "../../components/TextInput";

import { Typography } from "@material-ui/core/";
import * as themeColors from "../../theme/colors";
import CustomButton from "../../components/customButton/customButton";
import { getUserName } from "../../utils/generic";

const useStyles = makeStyles((theme) => ({
  addButtonExplanation: {
    color: theme.palette.primary.main,
    fontSize: 12,
  },
  dialogContent: {
    padding: 35,
    paddingTop: 0,
  },
  marginTop30: {
    marginTop: 30,
  },
  parentFormTitle: {
    fontSize: 12,
    color: theme.palette.primary.main,
    marginBottom: 10,
    marginTop: 30,
  },
  subTitle: {
    color: theme.palette.primary.main,
    fontSize: 16,
    lineHeight: 1.75,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 22,
    fontWeight: "bold",
    margin: "25px 17px 5px 0px",
    marginLeft: 32,
    maxWidth: 330,
  },
  buttonGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none",
    justifyContent: "flex-end",
  },
  button: {
    borderRadius: 5,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: themeColors.grey400,
    color: "white",
    "&:hover": {
      backgroundColor: themeColors.grey500,
    },
  },
  submitButton: {
    borderRadius: 5,
    backgroundColor: themeColors.tealA700,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    marginLeft: 12,
    "&:hover": {
      backgroundColor: themeColors.teal500,
    },
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: 350,
  },
  statusBtn: {
    marginRight: 20,
    marginTop: 15,
  },
  checkboxItem: {
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: themeColors.themeGreen,
    },
  },
}));

const customStyles = makeStyles({
  statusContainer: {
    backgroundColor: (props) => props.statusColor,
    marginRight: 20,
    marginTop: 15,
    color: "white",
    fontWeight: "bold",
    width: 80,
    "&:hover": {
      backgroundColor: (props) => props.statusColor,
    },
  },
  calendarContainer: {
    color: (props) => props.calendarColor,
  },
});

const statuses = [
  {
    value: "PRESENT",
    order: 0,
    color: "#3b7360",
  },
  {
    value: "ABSENT",
    order: 1,
    color: "#d46565",
  },
];

export const AttendanceModal = ({ isOpen, onClose, onSave, row }) => {
  const classes = useStyles();

  const [data, setData] = useState(row);
  const currentStatus = statuses.find((e) => e.value === data.status);
  const [colors, setColors] = useState({
    statusColor: currentStatus.color,
    calendarColor: themeColors.themeGreen,
  });

  const [status, setStatus] = useState(null);

  const setupColorsByStatus = (status) => {
    if (!status) {
      return;
    }

    const { color } = status;

    setColors({
      calendarColor: themeColors.themeGreen,
      statusColor: color,
    });
  };

  const statusChange = (status) => {
    let newStatus;
    const currentStatus = statuses.find((e) => e.value === status);
    if (!currentStatus || currentStatus.order === 1) {
      newStatus = statuses.find((e) => e.order === 0);
    } else {
      newStatus = statuses.find((e) => e.order === currentStatus.order + 1);
    }

    setupColorsByStatus(newStatus);

    const newData = {
      ...data,
      status: newStatus.value,
    };
    setData(newData);
    setStatus(newStatus.value);
  };

  if (data && data.status && status === null) {
    const currentStatus = statuses.find((e) => e.value === data.status);
    setupColorsByStatus(currentStatus);
    setStatus(data.status);
  }

  const rowClasses = customStyles({ ...colors });
  const { commentMode } = data;
  const title =
    data && data.user ? `${getUserName(data.user)}` : "";
  const attendanceDate = data
    ? moment.parseZone(data.date).format("MMMM Do YYYY")
    : new Date();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={classes.headerContainer}>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
        {!commentMode && (
          <Button
            onClick={() => statusChange(status)}
            className={rowClasses.statusContainer}
            sel="modal-statusButton"
          >
            {status ? status : "?"}
          </Button>
        )}
      </div>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h4" className={classes.subTitle}>
          {attendanceDate}
        </Typography>
        <Formik
          enableReinitialize={true}
          initialValues={{
            comment: data && data.comment !== "NULL" ? data.comment : "",
          }}
          onSubmit={(values) => {
            const newValues = {
              ...data,
              ...values,
              status,
            };
            onSave(newValues);
            onClose();
          }}
        >
          <Form>
            <Field
              className={classes.textField}
              name="comment"
              component={TextInput}
              label="Add a comment"
              variant="standard"
              rows="10"
              multiline
              maxLength={1000}
            />
            <div className={classes.buttonGroup}>
              <CustomButton
                mode="secondary"
                onClick={onClose}
                label={"Cancel"}
              />
              <CustomButton
                style={{ marginLeft: 12 }}
                mode="primary"
                type="submit"
                label={"Submit"}
              />
            </div>
          </Form>
        </Formik>
      </DialogContent>
    </Modal>
  );
};
