import React, { useEffect, useState } from "react";
import { push as pushToRoute } from "connected-react-router";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core/";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import * as contentfulActions from "../../actions/contentful";

import LandingPageCard from "./landingPageCard";
import Footer from "../../containers/Footer/footer";
import { connectTo } from "../../utils/generic";
import * as colors from '../../theme/colors'
import { useTitle } from "../../utils/pageTitles";
import { createMarkup, checkIsClubsDomain } from "../../utils/generic";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  content: {
    maxWidth: 1000,
    paddingLeft: 10,
    margin: "auto",
    height: "100%",
    minHeight: 'calc(100vh - 135px)',
    display: "flex",
    [theme.breakpoints.down('xs')]: {
      display: "block",
    },
  },
  agePolicyCard: {
    padding: 20,
    marginTop: 15,
  },
  cardWrapper: {
    paddingLeft: 10,
    display: "flex",
    [theme.breakpoints.down('xs')]: {
      flexWrap: "wrap",
    },
  },
  landingText: {
    color: colors.dartThemeBlueGray,
    fontWeight: 700,
    fontSize: 24,
  },
  landingTextHeader: {
    color: colors.darkThemeGreen,
    fontWeight: 700,
    fontSize: 24,
    paddingTop: 20,
  },
  ctaText: {
    color: colors.darkThemeGreen,
    fontSize: 18,
    fontWeight: 700,
    maxWidth: 280,
  },
  arrowIcon: {
    marginBottom: "-6px",
  }
}));

const mainPath = '.girlswhocode.com';

const LandingPage = ({ contentful, loadHomePageContent, push, languages }) => {
  useTitle("welcome");

  const classes = useStyles();

  const [landingContent, setLandingContent] = useState(null);
  const [landingClubSection, setLandingClubSection] = useState(null);
  const [landingSipAndSppSection, setLandingSipAndSppSection] = useState(null);
  const [landingCollegeLoopSection, setLandingCollegeLoopSection] = useState(null);
  const [landingVisitorSection, setLandingVisitorSection] = useState(null);
  const [currentLocale, setCurrentLocale] = useState(null);

  const isClubsDomain = checkIsClubsDomain();
  const { pathname, search } = window.location;
  const redirectPath = !!pathname && typeof pathname === 'string' && !pathname.includes('/home');
  const loginUrl = redirectPath ? `/login${search}`: '/login';

  const onClubClick = () => {
    if (isClubsDomain) {
      push(loginUrl)
    } else {
      window.open(`${getRedirectLink(currentLocale)}`,'_self');
    }
  }

  const onHqCardClick = () => {
    if (!isClubsDomain) {
      push(loginUrl)
    } else {
      window.open(`${getRedirectLink(currentLocale)}`,'_self');
    }
  }

  useEffect(() => {
    if (!contentful.HomePageQuery) {
      loadHomePageContent();
      return;
    }

    const {
      HomePageQuery: {
        landingClubSection, landingSipAndSppSection, landingCollegeLoopSection, landingVisitorSection, landingContent,
        clubIcon, sipIcon, collegeLoopIcon, visitorSectionIcon,
      },
    } = contentful;

    setLandingClubSection({ section: landingClubSection, icon: clubIcon });
    setLandingSipAndSppSection({ section: landingSipAndSppSection, icon: sipIcon });
    setLandingCollegeLoopSection({ section: landingCollegeLoopSection, icon: collegeLoopIcon });
    setLandingVisitorSection({ section: landingVisitorSection, icon: visitorSectionIcon });
    setLandingContent(landingContent);
    setCurrentLocale(getCurrentLocale(languages));
  }, [contentful]);

  const getRedirectLink = (locale) => {
    if (!locale) {
      return `${window.location.origin}${loginUrl}`;
    }
    const currentDomain = locale.domain;
    const resultDomain = isClubsDomain ? currentDomain.replace('clubs-', '') : `clubs-${currentDomain}`;
    return `https://${resultDomain}${mainPath}${loginUrl}`;
  }

  const getCurrentLocale = (locales) => {
    if (!locales?.length) {
      return;
    }
    const actualSubdomain = window.location.host.replace(mainPath, '');
    const filteredLocales = locales.filter(l => l.domain === actualSubdomain);
    if (filteredLocales.length) {
      return filteredLocales[0];
    }
    return locales.find(l => l.IsDefault);
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        {landingContent && <Box>
          <Typography variant="h5" role="region" aria-label={landingContent.heading} className={classes.landingTextHeader}>
            {landingContent.heading}
          </Typography>
          <Box className={classes.landingText} dangerouslySetInnerHTML={createMarkup(landingContent.content)}  role="region" aria-label={landingContent.content}/>
          <Typography className={classes.ctaText} role="region" aria-label={landingContent.subheading}>
            {landingContent.subheading} <ArrowForwardIcon className={classes.arrowIcon} />
          </Typography>
        </Box>}
        <Box className={classes.wrapper}>
          <Box>
            <Box className={classes.cardWrapper}>
              {landingClubSection && <LandingPageCard
                onClick={() => onClubClick()}
                section={landingClubSection.section}
                icon={landingClubSection.icon}
                name="landingClubSection"
               />}
              {landingSipAndSppSection && <LandingPageCard
                onClick={() => onHqCardClick()}
                section={landingSipAndSppSection.section}
                icon={landingSipAndSppSection.icon}
                name="landingSipAndSppSection"
               />}
            </Box>
            <Box className={classes.cardWrapper}>
              {landingCollegeLoopSection && <LandingPageCard
                onClick={() => onHqCardClick()}
                section={landingCollegeLoopSection.section}
                icon={landingCollegeLoopSection.icon}
                name="landingCollegeLoopSection"
               />}
              {landingVisitorSection && <LandingPageCard
                onClick={() => onHqCardClick()}
                section={landingVisitorSection.section}
                icon={landingVisitorSection.icon}
                name="landingVisitorSection"
               />}
            </Box>
          </Box>
      </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default connectTo(
  (state) => {
    return {
      contentful: state.contentful,
      languages: state.contentful.languages,
    };
  },
  { push: pushToRoute, ...contentfulActions },
  LandingPage
);
