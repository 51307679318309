import client from "../../../apolloClient";
import { gql } from "apollo-boost";
import obj2arg from "graphql-obj2arg";

export function GetTranslatedStatesProvinces({countryId, langId}) {
  const graphQlArguments = {
    countryId: parseInt(countryId, 10),
    langId: parseInt(langId, 10),
  };

  return client.query({
    query: gql`
          {
            GetTranslatedStatesProvinces(${obj2arg(graphQlArguments, {noOuterBraces: true})}) {
              id
              name
          }
        }
        `
  });
}
