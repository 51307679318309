import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import SkeletonCard from "./SkeletonCard";
import { ACTIVITY_SET_VIEW } from "../../constants/trackingEvents";
import { WelcomeModal } from "../../components/modals/curriculum/welcomeModal";
import { takeIfExists } from "../../utils/local-storage";

import { useTitle } from "../../utils/pageTitles";
import { onlyUnique } from "./curriculumHelper";

import Folder from "./Card/Folder";
import Curriculum from "./Card/Curriculum";
import Set from "./Card/Set";

import Collection from "./Card/Collections";

const useStyles = makeStyles(theme => ({
  headerTitleBox: {
    marginTop: 15,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  facilitatorsOnly: {
    backgroundColor: '#ff475b',
    borderRadius: 4,
    minHeight: 40,
    marginTop: 30,
    display: 'flex',
    alignItems: 'center',
  },
  facilitatorsOnlyText: {
    color: 'white',
    fontSize: 16,
    fontStyle: 'italic',
    fontWeight: 'bold',
    paddingLeft: 10,
  },
  grid: {
    marginTop: -10,
  },
  skeletonGrid: {
    marginTop: 15,
  },
  cardHeader: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: 60,
    justifyContent: 'space-between',
    width: '100%',
  },
  cardHeaderWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  setName: {
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: 5,
  },
  cardName: {
    fontSize: 22,
    fontWeight: 700,
    textAlign: 'center'
  },
  cardDescr: {
    fontSize: 14,
    textAlign: 'center',
    overflow: 'hidden',
    boxOrient: 'vertical',
    lineClamp: 3,
    display: '-webkit-box'
  },
  timeBox: {
    display: 'flex',
    flexDirection: 'row',
  },
  height300: {
    height: 300,
  },
  height500: {
    height: 500,
  },
  onlyFacilitatorIcon: {
    marginLeft: 5,
    height: 30,
    width: 30,
  },
  cardContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 120,
    marginTop: 40,
    '&.cardWithoutHeader': {
      justifyContent: 'space-evenly',
      height: 190,
      marginTop: 0
    },
    '&.cardFolderNoIcon': {
      height: '100%'
    }
  },
  folderIconWrapper: {
    marginTop: 50
  },
  label: {
    height: 14,
    fontSize: 10,
    fontFamily: "'Roboto Mono',monospace",
    fontWeight: 500,
    cursor: "text",
    "&:hover": {
      cursor: "text",
    },
    marginLeft: 15,
    marginTop: 10,
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    minWidth: 130,
    [theme.breakpoints.down("md")]: {
      marginLeft: 5,
      marginTop: 0,
    },
  },
}));

const CardsGrid = (props) => {
  const classes = useStyles();

  const {
    clearProgress,
    isFacilitator,
    item,
    pageLabels,
    defaultActivityIcon,
    createUsageLog,
    userActivitiesInfo,
    resourcesTitle,
    communityPartnershipLink,
    program,
    createModalUserLog,
    modalUserLogs,
    labelBackground,
    labelFontColor,
    programRoleLabel,
    push,
    curriculumLabels,
    studentView,
    currentRole,
    deleteMyActivityProgress,
    deleteMyActivityProgressInPartnership,
    isPartnership,
    certificateRibbonIcon,
    openTextAnswers,
    userQuizesAnswers,
  } = props;

  const [displayModalItem, setDisplayModalItem] = React.useState(null);

  const title = resourcesTitle || (!!item ? item.name : "");
  useTitle(title, !!title);

  const [itemId, setItemId] = React.useState(null);
  const skeletonsNumber = 6;

  React.useEffect(() => {
    if (!item) {
      return;
    }

    setItemId(item.id);
    if ((item.type === 'set' || item.nodeType === 'set') && item.id !== itemId) {
      createUsageLog({
        action: ACTIVITY_SET_VIEW,
      });
    }
  }, [item]);

  React.useEffect(() => {
    if (!item) {
      return;
    }

    if (item.welcomeModal && !!item.welcomeModal.modalContentSections.length && !!modalUserLogs) {
      const modalPath = item.path ? item.path : '/gwc';
      const key = `${modalPath}-${item.welcomeModal.id}`;
      const log = modalUserLogs.find(e => e.path === modalPath && e.modalId === item.welcomeModal.id);

      let welcomeModals = takeIfExists("welcomeModals", Object);
      const value = welcomeModals && welcomeModals[program.id] && welcomeModals[program.id][key];
      if (!value && !log) {
        setDisplayModalItem(item.welcomeModal);
      }
    }
  }, [item, modalUserLogs]);

  const storeWelcomeModalData = (isLastPage) => {
    let welcomeModals = takeIfExists("welcomeModals", Object);
    const modalPath = item.path ? item.path : '/gwc';

    if (!welcomeModals) {
      localStorage.setItem("welcomeModals", JSON.stringify({
        [program.id]: {
          [`${modalPath}-${displayModalItem.id}`]: {
            date: new Date(),
          }
        }
      }));
    } else {
      welcomeModals = {
        ...welcomeModals,
        [program.id]: {
          ...welcomeModals[program.id],
          [`${modalPath}-${displayModalItem.id}`]: {
            date: new Date(),
          }
        },
      }
      localStorage.setItem("welcomeModals", JSON.stringify(welcomeModals));
    }
    setDisplayModalItem(null);

    if (isLastPage) {
      createModalUserLog({
        programId: program.id,
        path: modalPath,
        modalId: displayModalItem.id,
      });
    }
  }

  React.useEffect(() => {
    setItemId(item ? item.id : null);
    return () => {
      setItemId(null);
    };
  }, []);

  const getNodePath = (node) => {
    const root = isPartnership ? communityPartnershipLink : '/gwc';
    return `${root}${node.path}`;
  }

  const onStartClick = (node) => {
    const nodePath = getNodePath(node);
    if (node.externalUrl) {
      window.open(node.externalUrl);
    } else if (node.contentSections || node.nodeType === 'activity') {
      push(nodePath, { enforcePresenter: true });
    } else if (node.activities) {
      if (node.activities && node.activities.length > 0) {
        const activityToOpen = node.activities[0];

        if (activityToOpen.externalUrl) {
          window.open(node.externalUrl)
        } else {
          push(`${nodePath}/${activityToOpen.slug}`, { enforcePresenter: true });
        }
      }
    }
  };

  const onResumeClick = (node) => {
    const nodePath = getNodePath(node);
    if (node.externalUrl) {
      window.open(node.externalUrl);
    } else if (node.contentSections || node.nodeType === 'activity') {
      if (!node.contentSections) {
        push(nodePath, { enforcePresenter: true });
        return;
      }

      const uaInfoContentSections = userActivitiesInfo.filter(item => item.title === node.slug && nodePath === item.path)
        .map((r) => r.contentSection)
        .filter(onlyUnique);

      if (uaInfoContentSections.length === 0) {
        push(nodePath, { enforcePresenter: true });
        return;
      }

      const visitedContentSections = node.contentSections.filter((r) => uaInfoContentSections.indexOf(r.id) !== -1);

      const hash = visitedContentSections.length > 0 ?
        `#${visitedContentSections[visitedContentSections.length - 1].id}` : '';
      push(`${nodePath}${hash}`, { enforcePresenter: true });
    } else {
      push(nodePath);
    }
  };

  const onRedoClick = (node) => {
    const nodePath = getNodePath(node);

    const itemsToDelete =
      node.activities && node.activities.length > 0
        ? node.activities.map((r) => {
          return {
            slug: r.slug,
            activitySetSlug: node.slug,
          };
        })
        : [{ slug: node.slug, path: nodePath }];

    if (isPartnership) {
      deleteMyActivityProgressInPartnership({ items: itemsToDelete, partnershipId: currentRole.partnership ? currentRole.partnership.id : null});
    } else {
      deleteMyActivityProgress({ items: itemsToDelete, programId: currentRole.program ? currentRole.programId : null });
    }
    
    if (!!node.externalUrl) {
      window.open(node.externalUrl);
    } else if (node.activities && node.activities.length > 0) {
      const studentActivities = node.activities.filter(e => e.permissionLevel !== 'Facilitators/Teachers only');

      push(`${nodePath}/${studentActivities[0].slug}`, { enforcePresenter: true });
    } else {
      push(`${nodePath}`, { enforcePresenter: true });
    }
  };

  const onNodeClick = (node, preview) => {
    const nodePath = getNodePath(node);
    if (!!node.externalUrl) {
      window.open(node.externalUrl);
    } else {
      push(nodePath, { enforcePreview: preview || false });
    }
  };

  const isNodesExist = !!item && !!item.nodes;
  const skeletonCards = [...Array(skeletonsNumber)].map((e, i) => <SkeletonCard key={i} classes={classes} style={{ height: "100%", display: "flex" }} />);

  const renderItemContent = () => {
    if (!item) {
      return (
        <Grid container spacing={3} className={classes.skeletonGrid}>
          {skeletonCards}
        </Grid>);
    }

    const isFolder = item.__typename === 'CurriculumFolder';
    const isCurriculum = !item.nodeType && !item.__typename;
    const isSet = item.type === 'set' || item.nodeType === 'set';
    const nodes = Object.values(item ? item.nodes : []);
    const isAllNodesFacilitatorsOnly = !!nodes.length
      && nodes.length === nodes.filter(e => e.permissionLevel === 'Facilitators/Teachers only').length;

    switch (true) {
      case (isSet):
        return (
          <Set
            classes={classes}
            item={item}
            defaultActivityIcon={defaultActivityIcon}
            onRedoClick={onRedoClick}
            onResumeClick={onResumeClick}
            onStartClick={onStartClick}
            userActivitiesInfo={userActivitiesInfo}
            isFacilitator={isFacilitator}
            communityPartnershipLink={communityPartnershipLink}
            labelBackground={labelBackground}
            labelFontColor={labelFontColor}
            programRoleLabel={programRoleLabel}
            clearProgress={clearProgress}
            push={push}
            isAllNodesFacilitatorsOnly={isAllNodesFacilitatorsOnly}
            studentView={studentView}
            openTextAnswers={openTextAnswers}
            userQuizesAnswers={userQuizesAnswers}
          />
        );
      case (isFolder):
        return (
          <Folder
            classes={classes}
            item={item}
            defaultActivityIcon={defaultActivityIcon}
            onNodeClick={onNodeClick}
            onRedoClick={onRedoClick}
            onResumeClick={onResumeClick}
            onStartClick={onStartClick}
            userActivitiesInfo={userActivitiesInfo}
            isFacilitator={isFacilitator}
            communityPartnershipLink={communityPartnershipLink}
            labelBackground={labelBackground}
            labelFontColor={labelFontColor}
            programRoleLabel={programRoleLabel}
            clearProgress={clearProgress}
            isAllNodesFacilitatorsOnly={isAllNodesFacilitatorsOnly}
            curriculumLabels={curriculumLabels}
            studentView={studentView}
            certificateRibbonIcon={certificateRibbonIcon}
            openTextAnswers={openTextAnswers}
            programId={program.id}
          />
        )
      case (isCurriculum):
        return (
          <Grid container spacing={3} className={classes.grid}>
            {!!item && isNodesExist && nodes.map((node, index) => {
              return (<Curriculum
                node={node}
                key={`card-${index}`}
                defaultActivityIcon={defaultActivityIcon}
                onNodeClick={onNodeClick}
                userActivitiesInfo={userActivitiesInfo}
                isFacilitator={isFacilitator}
                communityPartnershipLink={communityPartnershipLink}
                labelBackground={labelBackground}
                labelFontColor={labelFontColor}
                programRoleLabel={programRoleLabel}
                studentView={studentView}
                isAllNodesFacilitatorsOnly={isAllNodesFacilitatorsOnly}
                certificateRibbonIcon={certificateRibbonIcon}
                openTextAnswers={openTextAnswers}
                programId={program.id}
              />)
            })}
          </Grid>
        );
      default:
        return (
          <Collection
            item={item}
            isNodesExist={isNodesExist}
            classes={classes}
            clearProgress={clearProgress}
            isFacilitator={isFacilitator}
            onNodeClick={onNodeClick}
            onRedoClick={onRedoClick}
            onResumeClick={onResumeClick}
            onStartClick={onStartClick}
            defaultActivityIcon={defaultActivityIcon}
            userActivitiesInfo={userActivitiesInfo}
            communityPartnershipLink={communityPartnershipLink}
            labelBackground={labelBackground}
            labelFontColor={labelFontColor}
            programRoleLabel={programRoleLabel}
            isAllNodesFacilitatorsOnly={isAllNodesFacilitatorsOnly}
            curriculumLabels={curriculumLabels}
            studentView={studentView}
            openTextAnswers={openTextAnswers}
            programId={program?.id}
          />
        );
    }
  }

  return (
    <Container>
      {!!item && item.permissionLevel &&
        (item.permissionLevel.indexOf('Facilitators only') !== -1 || item.permissionLevel.indexOf('Clubs') !== -1) &&
        <Box className={classes.facilitatorsOnly}>
          <img className={classes.onlyFacilitatorIcon}
            src={pageLabels.onlyFacilitatorsCta.icon.src}
            alt={pageLabels.onlyFacilitatorsCta.icon.alt} />
          <Typography className={classes.facilitatorsOnlyText}>{pageLabels.onlyFacilitatorsCta.text}</Typography>
        </Box>
      }
      {renderItemContent()}
      {!!displayModalItem && <WelcomeModal
        isOpen={!!displayModalItem}
        item={displayModalItem}
        onClose={({ isLastPage }) => {
          storeWelcomeModalData(isLastPage);
        }}
      />}
    </Container>
  );
};

export default CardsGrid;
