import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import { Form, Formik } from "formik";
import * as Yup from "yup";

import * as colors from "../../theme/colors";
import { PROGRAM_TYPES } from "../../utils/constants";

import TextCustomButton from '../../components/text-button/text-button';
import CustomButton from "../../components/customButton/customButton";
import { PageLeaveModal } from '../../components/modals/pageLeaveWarning';

import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import Stepper from "@material-ui/core/Stepper";
import Box from "@material-ui/core/Box";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import FirstStep from "./steps/firstStep";
import SecondStep from "./steps/secondStep";
import ThirdStep from "./steps/thirdStep";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: 10,
    padding: "20px 0 26px 0",
    [theme.breakpoints.up("sm")]: {
      width: 500,
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 0",
    },
  },
  submitButtonBox: {
    marginLeft: 10,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  backToGallery: {
    padding: "0px",
    color: colors.darkThemeGreen,
    '&:hover': {
      color: colors.grayTheme,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  stepWrapper: {
    "& .MuiStepIcon-active": {
      color: colors.darkThemeGreen,
    },
    "& .MuiStepLabel-active": {
      color: colors.darkThemeGreen,
    },
    '& .MuiStepLabel-label': {
      fontSize: 14,
    }
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0 24px"
  },
  btnWrapper: {
    display: "flex",
  },
  title: {
    fontSize: 25,
    marginBottom: 15,
    color: colors.darkThemeBlue,
    fontWeight: "bold",
  },
  btnOutline: {
    '& .MuiButton-startIcon': {
      margin: "0 0 2px 0",
    }
  },
  submitBtn: {
    backgroundColor: colors.lightThemeGreen,
  }
}));

const validationSchema = Yup.object({
  images: Yup.array().min(1, "At least one image should be added"),
});

const steps = ["Project Info", "Program & Team", "Project Tags"];

const getValues = (user, project, projectTags, selectedProgram, code) => {
  const values = {
    title: project ? project.name : "",
    description: project ? project.description : "",
    projectLink: project ? project.projectUrl : "",
    gitHubLink: project ? project.repositoryUrl : "",
    languages: project ? projectTags.languageTags.map((e) => e.id) : [],
    projectTypes: project ? projectTags.projectTypeTags.map((e) => e.id) : [],
    topics: project ? projectTags.topicTags.map((e) => e.id) : [],
    images: project ? project.images.map((e) => e.url) : [],
    teamMembers:
      project && !!project.teamMembers.length
        ? project.teamMembers.map((e) => e.id)
        : [user.id],
    selectedProgram: selectedProgram,
    clubChallenge: project ? project.clubChallenge : "",
    reflection: project ? project.reflection : "",
  };

  if (code) {
    const role = user.roles
      .filter((role) => role.programId && !!role.program)
      .find((e) => e.program.code === code);

    if (role && role.programId) {
      // set preselected program id and owner by default;
      values.selectedProgram = role.programId;
      values.teamMembers = [user.id];
    }
  }

  return values;
};


const ProjectForm = ({
  user,
  getProgramsAndMembersInfo,
  isLoadingMembersList,
  programs,
  contentful,
  code,
  curriculumSet,
  projectTypeTags,
  languageTags,
  topicTags,
  setProjectSent,
  onSubmit,
  defaultProgramId,
  selectedProgram,
  project,
  projectTags,
  showErrorMessage,
  showDefaultProgramForStudent,
  programTypesById,
  setIsAbleToNavigateBack,
  isWarningModalToOpen,
  setInitialProjectValues,
  setUpdatedProjectValues
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [isEscapeConfirmDialog, setEscapeConfirmDialog] = useState(false);

  const values = getValues(user, project, projectTags, selectedProgram, code);

  useEffect(() => {
    setInitialProjectValues(values);
  }, []);

  useEffect(() => {
    if (isWarningModalToOpen) {
      setEscapeConfirmDialog(true);
    }
  }, [isWarningModalToOpen]);

  const labels = contentful.GlobalComponentQuery
    ? contentful.GlobalComponentQuery.projectGalleryLabels
    : null;

  const [disabledSubmit, setDisabledSubmit] = React.useState(true);

  if (!labels) return null;

  const validateTeamMembers = (values) => {
    return values.length > 0 ? null : "At least one team member should be added";
  };

  const validateProjectTags = (value) =>
    value.length === 0 ? "At least one tag should be selected" : null;

  const validateTitle = (value) => {
    return !value ? "Title is required" : null;
  };

  const validateProgram = (value) => {
    return !!value ? null : "Program is required";
  };

  const userActivePrograms = user.roles.filter(
    (role) => !!role.program && (role.program.active && (!role.program.completed || role.programId === selectedProgram))
  );

  const userPrograms = userActivePrograms
    .filter(
      (e) => showDefaultProgramForStudent || e.programId !== parseInt(defaultProgramId)
    );

  const programsList = userPrograms.map((role) => {
    return {
      value: role.program.id,
      label: `${role.program.code} - ${role.program.name}`,
      isCompleted: role.program.completed,
      isClub: role.program.programType === PROGRAM_TYPES.CLUB,
      isCollegeLoop: role.program.programType === PROGRAM_TYPES.COLLEGE_LOOP,
    };
  });

  const getProgramMembers = (programId, setFieldValue) => {
    if (defaultProgramId != programId) {
      getProgramsAndMembersInfo({
        userProgramIds: [programId],
      });
    }
    // reset field values for program members dropdown and include owner id by default;
    setFieldValue("teamMembers", [user.id]);
  };

  const getCurrentPage = (
    page,
    labels,
    projectTypeTags,
    validateProjectTags,
    validateTitle,
    languageTags,
    topicTags,
    userPrograms,
    validateProgram,
    programsList,
    getProgramMembers,
    isLoadingMembersList,
    defaultProgramId,
    showErrorMessage,
    formValues,
  ) => {
    switch (page) {
      case 0:
        return <FirstStep
          validateTitle={validateTitle}
          labels={labels}
          showErrorMessage={showErrorMessage}
        />;
      case 1:
        return (
          <SecondStep
            userPrograms={userPrograms}
            programsList={programsList}
            validateProgram={validateProgram}
            getProgramMembers={getProgramMembers}
            isLoadingMembersList={isLoadingMembersList}
            defaultProgramId={defaultProgramId}
            labels={labels}
            validateTeamMembers={validateTeamMembers}
            formValues={formValues}
            programTypesById={programTypesById}
          />
        );
      case 2:
        return (
          <ThirdStep
            projectTypeTags={projectTypeTags}
            validateProjectTags={validateProjectTags}
            languageTags={languageTags}
            topicTags={topicTags}
            labels={labels}
            userPrograms={userPrograms}
            defaultProgramId={defaultProgramId}
            values={values}
          />
        );
      default:
        return <FirstStep validateTeamMembers={validateTeamMembers} />;
    }
  };

  const verifyStepData = (values, page) => {
    if (page === 0) {
      const { images, title } = values;
      images.length > 0 && !!title.trim().length
        ? setDisabledSubmit(false)
        : setDisabledSubmit(true);
    }

    if (page === 1) {
      const { selectedProgram } = values;
      !isNaN(parseInt(selectedProgram))
        ? setDisabledSubmit(false)
        : setDisabledSubmit(true);
    }

    if (page === 2) {
      const { projectTypes } = values;
      !projectTypes.length ? setDisabledSubmit(true) : setDisabledSubmit(false);
    }
  };

  return (
    <>
    <Formik
      enableReinitialize={false}
      validateOnChange={true}
      validateOnMount={!!project}
      initialValues={values}
      validationSchema={validationSchema}
      validate={(values) => {
        let errors = {};

        verifyStepData(values, page);

        setUpdatedProjectValues(values);

        return errors;
      }}
      onSubmit={(values) => {
        const {
          title,
          description,
          projectLink,
          gitHubLink,
          selectedProgram,
          teamMembers,
          clubChallenge,
          reflection,
        } = values;

        if (page < 2) {
          setPage(page + 1);
          verifyStepData(values, page + 1);
          return;
        }

        const galleryProject = {
          name: title,
          description,
          reflection,
          projectUrl: projectLink,
          repositoryUrl: gitHubLink,
          programId:
            selectedProgram != "" && !!selectedProgram
              ? selectedProgram.toString()
              : defaultProgramId.toString(),
          submitterId: user.id.toString(),
          curriculumSet,
          clubChallenge,
        };

        const tags = [
          ...values.projectTypes,
          ...values.languages,
          ...values.topics,
        ].map((e) => e.toString());

        onSubmit(galleryProject, values.images, tags, teamMembers);
        setProjectSent(true);
      }}
    >
      {({ values: formValues }) => (<Form className={classes.form}>
        <Box className={classes.stepWrapper}>
          <Stepper activeStep={page} alternativeLabel={true}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        {getCurrentPage(
          page,
          labels,
          projectTypeTags,
          validateProjectTags,
          validateTitle,
          languageTags,
          topicTags,
          programsList,
          validateProgram,
          programs,
          getProgramMembers,
          isLoadingMembersList,
          defaultProgramId,
          showErrorMessage,
          formValues,
        )}

        <Box className={classes.btnContainer}>
          <Box className={classes.btnWrapper}>
            <Box>
              {page > 0 && (
                <TextCustomButton
                  mode="menuItem"
                  startIcon={<KeyboardArrowLeftIcon />}
                  className={classes.btnOutline}
                  onClick={() => {
                    if (page === 0) {
                      return;
                    }
                    setDisabledSubmit(false);
                    setPage(page - 1);
                  }}
                  label={"Back"}
                />
              )}
            </Box>

            <Box className={classes.submitButtonBox}>
              {page <= 2 && (
                <CustomButton
                  mode="primary"
                  className={disabledSubmit ? classes.btnOutline : clsx(classes.btnOutline, classes.submitBtn)}
                  disabled={disabledSubmit}
                  type="submit"
                  label={page < 2 ? "Next" : "Submit"}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Form>)}
    </Formik>
    <PageLeaveModal
      onConfirm={() => { setEscapeConfirmDialog(false); setIsAbleToNavigateBack(true); }}
      onCancel={() => { setEscapeConfirmDialog(false); setIsAbleToNavigateBack(false); }} 
      open={isEscapeConfirmDialog}
    />
    </>
  );
};

export default ProjectForm;
