import React from 'react';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createMarkup } from '../../../utils/generic';
import { StyledFormHelper } from '../../../components/loginSignup/customFormHelper';

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 12,
  },
  error: {
    color: theme.palette.error.main,
  },
  cb: {
    color: '#3DB390',
  },
}));

const GreenCheckbox = withStyles({
  root: {
    color: '#3DB390',
    '&$checked': {
      color: '#3DB390',
    },
  },
  checked: {},
})((props) => <Checkbox {...props} />);

const CustomLabel = (props) => (
  <span className={props.className} dangerouslySetInnerHTML={createMarkup(props.termsContent)}></span>
);

const TermsInput = ({ field: { name, onChange, value }, form, termsContent, ...props }) => {
  const classes = useStyles();
  let isTouched = form.touched[name];
  const { errors } = form;

  return (
    <React.Fragment>
      <FormControlLabel
        className={clsx(props.className, classes.root)}
        control={
          <GreenCheckbox
            name={name}
            checked={value}
            onChange={onChange}
          />
        }
        label={<CustomLabel className={classes.label} termsContent={termsContent} />}
      />
      {errors[name] && isTouched && <StyledFormHelper isError={true} isHtml={true} helperText={errors[name]} />}
    </React.Fragment>
  );
};

export default TermsInput;
