import React, { useState } from 'react';
import { Field } from 'formik';
import * as moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { filterFieldWithReturnKey, checkIsClubsDomain, createMarkup } from '../../../utils/generic';
import DateInput from './DateInput';
import CountrySelector from './CountrySelector';
import Switcher from './Switcher';
import TermsInput from './TermsInput';
import { DOMESTIC_ID, UK_ID } from '../../../utils/countriesDict';
import * as colors from '../../../theme/colors';
import * as countryQueriesApi from "../../../api/graphql/query/countries.queries";

const useStyles = makeStyles((theme) => ({
  formControlLabel: {
    fontSize: 16,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  formError: {
    border: '1px solid ' + theme.palette.primary.main,
    borderRadius: 3,
    color: theme.palette.primary.main,
    marginBottom: 20,
    textAlign: 'center',
    '& p': {
      margin: '10px 20px',
    },
    '& div': {
      width: 250,
      height: 40,
      textTransform: 'uppercase',
      padding: 10,
      borderRadius: 3,
      backgroundColor: colors.lightThemeGreen,
      margin: '15px auto',
      '& a': {
        fontWeight: 500,
        color: theme.palette.primary.main,
        textDecoration: "none",
      }
    }
  },
}));

const Step0 = ({ signUpContent }) => {
  const classes = useStyles();

  const [error, setError] = useState(null);
  const [showChildren, setShowChildren] = useState(false);
  const [countries, setCountries] = useState(null);

  const toggleId = "toggle";
  const toggleLabel = "Are you registering a child?";

  const loadCountries = async () => {
    const { data: { GetAllCountries } } = await countryQueriesApi.GetCountries();
    const countriesList = !!GetAllCountries ? GetAllCountries : [];
    setCountries(countriesList);
  }
  if (!countries?.length) {
    loadCountries();
  }

  const checkCountryAge = (countryId, dob) => {
    let now = moment();
    let dobDate = moment.parseZone(dob).format();
    let isValid = moment(dob);

    if (!countryId || !dob || !isValid) {
      return;
    }
    const isClubsDomain = checkIsClubsDomain();

    const age = now.diff(dobDate, 'year');

    setShowChildren(age >= 18);
    if (countryId !== DOMESTIC_ID && age < 10 || countryId === UK_ID && age < 13) {
      setError(filterFieldWithReturnKey(signUpContent.formMessages, "messageCode", "internationalUserUnder13", "message"));
      return;
    }

    if (countryId === DOMESTIC_ID && age < 13 && !isClubsDomain) {
      setError(filterFieldWithReturnKey(signUpContent.formMessages, "messageCode", "invalidPath", "message"));
      return;
    }
    setError(null);
  }

  return (
    <>
      {error && <Box role='alert' aria-label={`alert ${error.replaceAll(/<\/?p[^>]*>/g, '')}`} className={classes.formError} dangerouslySetInnerHTML={createMarkup(error)}></Box>}
      <span aria-label={signUpContent.signupNationalityStepHeader} role="region" className={classes.formControlLabel}>{signUpContent.signupNationalityStepHeader}</span>
      {countries && 
      <Field required name="country" component={CountrySelector} label={signUpContent.signupNationalityStepCountrySelection.labelPrompt} countries={countries} checkCountryAge={checkCountryAge} elementRole={"combobox"} />}
      <Box style={{ marginBottom: 15, marginTop: 20 }} className={classes.formControlLabel} aria-label={signUpContent.signupBirthStepHeader} role="region" >{signUpContent.signupBirthStepHeader}</Box>
      <Field required name="birthDate" component={DateInput} label={signUpContent.signupBirthStepDateInput.labelPrompt} domesticId={DOMESTIC_ID} checkCountryAge={checkCountryAge} />
      <Field
        required
        name="termsAndConditions"
        component={TermsInput}
        termsContent={true ? signUpContent.termsAndConditions : signUpContent.termsAndConditionsInternational}
      />
      {showChildren && <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <label aria-label={toggleLabel} role="region" className={classes.formControlLabel} htmlFor={toggleId}>{toggleLabel}</label>
        <Field
          style={{ width: 'auto' }}
          name="isParent"
          component={Switcher}
          label={toggleLabel}
          toggleId={toggleId}
        />
      </Box>}
    </>
  );
};

export default Step0;
