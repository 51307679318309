import React from "react";

import ConfirmDialog from '../../ConfirmDialog';

export const PageLeaveModal = ({
  onConfirm,
  onCancel,
  open,
}) => {

  return (
    <ConfirmDialog
      content="Unsaved changes will be lost if you leave this page. Do you want to continue?"
      onCancel={onCancel}
      onConfirm={onConfirm}
      open={open}
      title="Unsaved Changes"
      okTitle="Yes"
      leftAlignContent={true}
    />
  );
};
