import React, { useEffect, useState } from "react";
import { Form, Formik } from 'formik';
import { push as pushToRoute } from "connected-react-router";
import withPageLabels from "../../hocs/withPageLabels";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import * as moment from "moment";

import { GetUserInvitationToken } from "../../api/graphql/query/user.queries";

import { connectTo } from "../../utils/generic";
import * as actions from "../../actions/joinClub";
import * as userProfileActions from "../../actions/userProfile";

import FirstStep from "./steps/FIrstStep";
import SecondStep from "./steps/SecondStep";
import ThirdStep from "./steps/ThirdStep";

import { programTypes } from "./constants";

import * as trackingActions from "../../actions/tracking";
import { SIGNUP_INVITATION_APPLIED } from "../../constants/trackingEvents";

import * as colors from "../../theme/colors";
import PageTitle from "../../components/page-title/pageTitle";
import Footer from "../../containers/Footer/footer";
import { useTitle } from "../../utils/pageTitles";
import { PageLeaveModal } from '../../components/modals/pageLeaveWarning';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import TextCustomButton from '../../components/text-button/text-button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CustomButton from '../../components/customButton/customButton';
import { CircularProgress } from "@material-ui/core/";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { DOMESTIC_ID } from "../../utils/countriesDict";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  main: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    height: "100%",
    minHeight: 'calc(100vh - 135px)',
  },
  pageContainer: {
    display: "flex",
    paddingTop: 10,
    marginBottom: 50,
    maxWidth: "100%",
    justifyContent: "space-around",
  },
  childContainer: {
    display: "flex",
    alignItems: "center",
    margin: "20px 0"
  },
  childBox: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
  },
  imageContainer: {
    marginRight: 5,
    color: colors.darkThemeBlueGray,
    width: 32,
    height: 32,
  },
  initials: {
    color: colors.darkThemeBlueGray,
    fontSize: 14,
  },
  form: {
    paddingTop: 20,
  },
  formGroup: {
    height: '90%',
  },
  stepWrapper: {
    '& .MuiPaper-root': {
      marginBottom: 19,
      backgroundColor: 'inherit',
    },
    '& .MuiStepLabel-label.MuiStepLabel-active': {
      color: colors.darkThemeGreen,
    },
    '& .MuiStepIcon-active': {
      color: colors.darkThemeGreen,
    },
    '& .MuiStepLabel-label': {
      fontSize: 14,
    }
  },
  btnContainer: {
    marginTop: 20,
    padding: "0 24px 0 24px",
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  submitButtonBox: {
    margin: "auto",
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: "100%",
    borderRadius: 2,
    paddingBottom: 26,
    background: colors.white,
    boxShadow: "0px 1px 4px 0px rgba(0,0,0,0.2)",
    width: 400,
    marginTop: 10,
  },
  backBtn: {
    height: 42,
    marginRight: 10,
    color: colors.darkBlueGrey,
    fontSize: 16,
    '& .MuiButton-startIcon': {
      margin: 0,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 14,
    }
  },
  joinBtn: {
    height: 42,
    width: 120,
  },
  btnWrapperOnLastStep: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  confirmBtn: {
    fontSize: 16,
  }
}));

// TO DO : Add default role from db;
const studentId = 2;
const defaultStep = 0;

const JoinClub = ({
  user,
  program,
  currentStep,
  changeUserInfo,
  isLoading,
  setCurrentStep,
  receiveCurrentStep,
  getProgramByCode,
  getEnrollmentData,
  enrollUserInClub,
  enrollmentForm,
  collegeMajors,
  enrollUserInCommunityPartnership,
  contentful,
  createUsageTrackingEvent,
  createTrackingLog,
  enrollmentData,
  match: {
    params: { childId, invitationToken, clubCode, qrClubCode },
  },
  pageLabels,
  push,
}) => {
  useTitle("join-program");
  const classes = useStyles();
  const history = useHistory();

  const [activeStep, setActiveStep] = useState(defaultStep);
  const [programCode, setProgramCode] = useState(null);
  const [programType, setProgramType] = useState(null);
  const [enrollmentFormData, setEnrollmentFormData] = useState(null);
  const [childID, setChildID] = useState(null);
  const [child, setChild] = useState(null);
  const [childrenLabels, setChildrenLabels] = useState(null);
  const [isInternational, setIsInternational] = useState(null);
  const [locale, setLocale] = useState(null);
  const [invitation, setInvitation] = useState(null);
  const [domesticCountryId, setIsDomesticCountryId] = useState(null);
  const [needToUpdateFields, setNeedToUpdateFields] = useState(null);
  const [isEscapeConfirmDialog, setEscapeConfirmDialog] = useState(false);
  const [isDataChanged, setDataChanged] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);
  const [isBackBtn, setBackBtn] = useState(false);

  const [isLoadingStepTwo, setIsLoadingStepTwo] = React.useState(false);
  const [isLoadingStepThree, setIsLoadingStepThree] = React.useState(false);
  const [isRequestedToJoin, setRequestedToJoin] = React.useState(false);
  const [hqDefaultProgram, setHQDefaultProgram] = React.useState();
  const [allProgramTypes, setAllProgramTypes] = useState([]);
  const [userAlreadyInProgram, setUserAlreadyInProgram] = useState(false);
  const [programInactive, setProgramInactive] = useState(false);

  const steps = programType === programTypes.COMMUNITY_PARTNERSHIP
  ? ["Search", "Confirm"]
  : ["Search", "Confirm", "Enroll"]

  const joinContent = pageLabels ? pageLabels.modules[0] : null;

  useEffect(() => {
    if (!user) return;

    getUserRole(user);
  }, [user]);

  useEffect(() => {
    if (!user) return;

    if (childId) {
      setChildID(parseInt(childId, 10));
      const child = user.children.find((e) => e.id === parseInt(childId, 10));
      setChild(child);
    }

    const needBirthdate = user && (!user.birthDate || !moment(user.birthDate).isValid());
    const needLastName = user && (!user.lastName || !user.lastName.trim().length);
    setNeedToUpdateFields({
      needBirthdate,
      needLastName,
    })

    setActiveStep(currentStep ?? defaultStep);
    if (enrollmentForm) {
      setEnrollmentFormData(enrollmentForm);
    }
  }, [currentStep, enrollmentForm]);

  useEffect(() => {
    if (!program || !contentful) return;

    if (program.completed || !program.active) {
      setProgramInactive(true);
      return;
    }

    if (program) {
      setIsInternational(!!program.countryId && program.countryId != +DOMESTIC_ID);
    }

    if (!programType) {
      setProgramType(program.programType);
    }
  }, [program]);

  useState(() => {
    if (!user) {
      return;
    }

    if (clubCode) {
      setProgramCode(clubCode);
      getProgramByCode({programCode: clubCode, withFacilitators: true})
      return;
    }

    if (qrClubCode) {
      setProgramCode(qrClubCode);
      const isRoleExists = user && user.roles 
            ? user.roles.find((role) => role.programId && role.program.code === qrClubCode)
            : false;
      if (!isRoleExists) {
        getProgramByCode({programCode: qrClubCode, withFacilitators: true});
        return;
      } else {
        setUserAlreadyInProgram(true);
      }
    }

    if (invitationToken) {
      return GetUserInvitationToken(invitationToken).then((result) => {
        const {
          data: { getUserNextInvitationByToken: invitation },
        } = result;

        if (
          user.email &&
          (!invitation || user.email.trim() !== invitation.email.trim())
        ) {
          receiveCurrentStep(defaultStep);
          return;
        }

        const { program } = invitation;

        if (program) {
          const isRoleExists = user && user.roles
            ? user.roles.find((role) => role.programId && role.programId === parseInt(program.id, 10))
            : false;

          createUsageTrackingEvent({
            action: SIGNUP_INVITATION_APPLIED,
            eventTime: 0,
            data: { programType: program.code },
          });
          setProgramCode(program.code);

          if (program.completed || !program.active) {
            setProgramInactive(true);
            return;
          }
          
          if (!isRoleExists) {
            setInvitation(invitation);
            onCodeSubmit({
              programCode: program.code,
              programType: program.programType,
            });
            return;
          } else {
            setUserAlreadyInProgram(true);
          }
        } 
        receiveCurrentStep(defaultStep);
      });
    }
    else {
      receiveCurrentStep(defaultStep);
    }
  }, []);

  useEffect(() => {
    return () => {
      setCurrentStep(defaultStep);
      setActiveStep(defaultStep);
    };
  }, []);

  const getUserRole = (user) => {
    let mainRole;
    if (childId) {
      mainRole = studentId;
    } else {
      const role = user.roles.find((e) => !e.cpOrganizationId && !e.programId);
      mainRole = role ? role.roleId : user.roles[0].roleId;
    }
    switch (mainRole) {
      case 4: {
        setProgramType(programTypes.SPONSOR);
        return;
      }
      case 6: {
        setProgramType(programTypes.COMMUNITY_PARTNERSHIP);
        return;
      }
      default:
        return;
    }
  };

  useEffect(() => {
    if (!contentful.GlobalComponentQuery) {
      return;
    }

    const { childrenManagementLabels } = contentful.GlobalComponentQuery;
    const {
      locales,
      hqConfig: { domesticCountryId, defaultProgram},
      programTypes,
    } = contentful;
    let currentLocale = locales.find(
      (e) => parseInt(e.countryId, 10) === user.countryId
    );
    setHQDefaultProgram(defaultProgram);
    setIsDomesticCountryId(parseInt(domesticCountryId, 10));

    if (!currentLocale) {
      currentLocale = locales.find((e) => e.countryId === domesticCountryId);
    }

    setLocale(currentLocale);
    setChildrenLabels(childrenManagementLabels);
    setAllProgramTypes(programTypes);
  }, [contentful]);

  const unblockRef = React.useRef(null);

  useEffect(() => {
    unblockRef.current = history.block((location) => {
      setNextLocation(location);
      setEscapeConfirmDialog(!isRequestedToJoin);
      return activeStep === 0 && !isDataChanged || isRequestedToJoin;
    });
    
    return () => {
      unblockRef.current && unblockRef.current();
  };
  }, [isRequestedToJoin, activeStep, isDataChanged, isEscapeConfirmDialog]);

  const renderChildHeader = () => {
    if (childID && child) {
      if (child) {
        return (
          <Box className={classes.childContainer}>
            <div className={classes.childBox}>
              <img
                className={classes.imageContainer}
                src={childrenLabels.childrenTabDefaultAvatar.src}
                alt="Child's avatar"
              />
              <Typography
                className={classes.initials}
              >{`${child.firstName} ${child.lastName}`}</Typography>
            </div>
          </Box>
        );
      }
    }
  };

  const getStepContent = (stepIndex, thirdStepRef, childID, renderChildHeader, program) => {
    switch (stepIndex) {
      case 0:
        return (
          <FirstStep
            user={user}
            type={programType}
            programCode={programCode}
            onSubmit={onCodeSubmit}
            pageLabels={joinContent}
            locale={locale}
            child={child}
            createTrackingLog={createTrackingLog}
            setDataChanged={setDataChanged}
            renderChildHeader={renderChildHeader}
            childID={childID}
            hqDefaultProgram={hqDefaultProgram}
            allProgramTypes={allProgramTypes}
            userAlreadyInProgram={userAlreadyInProgram}
            setUserAlreadyInProgram={setUserAlreadyInProgram}
            setProgramInactive={setProgramInactive}
            programInactive={programInactive}
          />
        );
      case 1:
        return (
          <SecondStep
            program={program}
            type={programType}
            onClick={onConfirmClub}
            onBack={onBackClick}
            createTrackingLog={createTrackingLog}
            isLoading={isLoadingStepTwo}
            renderChildHeader={renderChildHeader}
            childID={childID}
          />
        );
      case 2:
        return (
          <ThirdStep
            onSubmitEnrollment={onSubmitEnrollment}
            user={user}
            type={programType}
            locale={locale}
            enrollmentForm={enrollmentFormData}
            collegeMajors={collegeMajors}
            isInternational={isInternational}
            enrollmentData={enrollmentData}
            domesticCountryId={domesticCountryId}
            createTrackingLog={createTrackingLog}
            onChangeUserInfo={onChangeUserInfo}
            needToUpdateFields={needToUpdateFields}
            setDataChanged={setDataChanged}
            isLoadingStepThree={isLoadingStepThree}
            setIsLoadingStepThree={setIsLoadingStepThree}
            thirdStepRef={thirdStepRef}
            renderChildHeader={renderChildHeader}
            childID={childID}
          />
        );
      default:
        window.location.href = "/home";
        break;
    }
  };

  const onCodeSubmit = (data) => {
    const { programCode } = data;
    if (programType === programTypes.COMMUNITY_PARTNERSHIP) {
      getProgramByCode({ ...data, programType });
      setProgramCode(programCode);
    } else {
      getProgramByCode({ ...data, withFacilitators: true });
      setProgramCode(programCode);
    }
  };

  const onConfirmClub = () => {
    // TO DO
    if (programType === programTypes.COMMUNITY_PARTNERSHIP) {
      setRequestedToJoin(true);
      enrollUserInCommunityPartnership({ program });
    } else {
      setIsLoadingStepTwo(false);
      getEnrollmentData({
        step: 2,
        childId: child ? child.id : null,
      });
    }
  };

  const onSubmitEnrollment = (data) => {
    if (data.graduationYear) {
      const graduationYear = getGraduationYearEstimate(
        data.graduationYear
      ).toString();
      data = { ...data, graduationYear };
    }

    const payload = {
      user,
      childId: childID,
      program,
      enrollmentData: data,
      programType,
      invitation,
    };
    setDataChanged(false);
    setRequestedToJoin(true);
    enrollUserInClub(payload);
  };

  const onChangeUserInfo = (data) => {
    changeUserInfo({ userId: child ? child.id : user.id, data });
  }

  const onBackClick = () => {
    if (!activeStep) {
      history.goBack();
      return;
    }

    setCurrentStep(activeStep - 1);
  };

  function getGraduationYearEstimate(currentGrade) {
    // the considered current grade should be 12 or less
    currentGrade = Math.min(12, currentGrade);

    const july1st = moment().date(1).month(6);
    const today = moment();
    const currentYear = today.year();

    if (today.isBefore(july1st)) {
      return currentYear + (12 - currentGrade);
    }

    return currentYear + 1 + (12 - currentGrade);
  }

  const ref = React.useRef(null);
  const thirdStepRef = React.useRef(null);
  const btnLabel =
  programType === programTypes.COMMUNITY_PARTNERSHIP
    ? "Join"
    : "This looks right";
  return (
    <React.Fragment>
      <main className={classes.main}>
        <Grid
          item
          xs={12}
          md={7}
          className={classes.pageContainer}
        >
          <Box>
            <Box>
              <TextCustomButton
                mode="primary"
                startIcon={<KeyboardArrowLeftIcon />}
                onClick={() => push("/home")}
                label='Back to My Programs'
              />
            </Box>
            <PageTitle title="Join Your Program" />
            <Box className={classes.wrapper}>
              <Formik
                enableReinitialize={true}
                validateOnMount={true}
              >{() => (
                <Form className={classes.form} ref={ref}>
                  <Box className={classes.formGroup}>
                    <Box className={classes.stepWrapper}>
                      <Stepper activeStep={activeStep} alternativeLabel={true}>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel> {label} </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Box>
                    {getStepContent(activeStep, thirdStepRef, childID, renderChildHeader, program)}
                  </Box>
                  <Box className={classes.btnContainer}>
                    <Box className={`${activeStep !== 2 ? classes.btnWrapper : classes.btnWrapperOnLastStep}`}>
                      <Box>
                        {!!activeStep && activeStep < 3 && (
                          <TextCustomButton
                            className={classes.backBtn}
                            mode="primary"
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => {
                              if (activeStep === 1) {
                                setProgramType('');
                              }
                              if (isDataChanged && activeStep !== 1) {
                              setEscapeConfirmDialog(true);
                              setBackBtn(true);
                              } else {
                                onBackClick();
                            }}}
                            label={'Back'}
                          />
                        )}
                        {!isLoading && !!activeStep && activeStep < 2 && (
                          <CustomButton
                          mode={"primary"}
                          className={classes.confirmBtn}
                          disabled={isLoadingStepTwo}
                          onClick={() => {
                            setDataChanged(false);
                            setIsLoadingStepTwo(true);
                            onConfirmClub();
                          }}
                          label={btnLabel}
                          endIcon={isLoadingStepTwo && <CircularProgress size={20} />}
                        />
                        )}
                        {activeStep === 2 && (
                          <CustomButton
                          className={classes.joinBtn}
                          disabled={isLoading}
                          onClick={() => {
                            thirdStepRef.current.submitForm.call(thirdStepRef.current)
                          }}
                          label={
                            enrollmentForm.confirmCta
                            ? enrollmentForm.confirmCta.text
                            : ""
                          }
                          endIcon={isLoading && <CircularProgress size={20} />}
                        />
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Form>
              )}
              </Formik>
            </Box>
          </Box>
        </Grid>
      </main>
      <Footer />
      <PageLeaveModal
        onConfirm={() => {
          if (typeof unblockRef.current === 'function') {
            unblockRef.current();
          }
          setDataChanged(false);
          if (!isBackBtn && nextLocation) {
            history.push(nextLocation);
          }
          if (isBackBtn){
            onBackClick();
          }
          setEscapeConfirmDialog(false);
          setBackBtn(false);
        }}
        onCancel={() => { setEscapeConfirmDialog(false); }}
        open={isEscapeConfirmDialog}
      />
    </React.Fragment>
  );
};

export default connectTo(
  (state) => {
    return {
      user: state.auth.user,
      ...state.joinClub,
      contentful: state.contentful,
    };
  },
  {
    push: pushToRoute,
    ...actions,
    ...trackingActions,
    ...userProfileActions,
  },
  withPageLabels({ slug: "/gwc/join-club" }, JoinClub)
);
