import { createReducer } from "redux-act";

import * as a from "../actions/messages";

const getDefaultState = () => ({
  isLoading: false,
  posts: null,
  singlePost: null,
  actionError: null
});

const updateMessageModel = (currentState, updated) => {
  const model = {
    ...currentState,
    body: updated.body,
    fileName: updated.fileName,
    modifiedAt: updated.modifiedAt,
  };
  if (updated.comments) model.comments = updated.comments;

  return model;
}

export default () =>
  createReducer(
    {
      [a.getPosts]: state => ({
        ...state,
        posts: null,
        isLoading: true
      }),
      [a.getSinglePost]: state => ({
        ...state,
        isLoading: true
      }),
      [a.createPost]: state => ({
        ...state,
        isLoading: true,
        actionError: null
      }),
      [a.createComment]: state => ({
        ...state,
        isLoading: true
      }),
      [a.updatePost]: state => ({
        ...state,
        isLoading: true
      }),
      [a.updateComment]: state => ({
        ...state,
        isLoading: true
      }),
      [a.deleteMessage]: state => ({
        ...state,
        isLoading: true
      }),
      [a.receivedDeletedPost]: (state, payload) => {
        const { id } = payload;

        if (!state.posts && (!state.singlePost || state.singlePost.id !== id)) { 
          return {
            ...state,
            isLoading: false
          };
        }

        if (state.posts) {
          const newPosts = state.posts.filter(e => e.id !== id);
          return {
            ...state,
            isLoading: false,
            posts: newPosts
          };
        }
        else {
          return {
            ...state,
            isLoading: false,
            singlePost: {
              ...state.singlePost,
              deleted: true
            }
          };
        }
      },
      [a.receivedDeletedComment]: (state, payload) => {
        if (!state.posts && !state.singlePost) { 
          return {
            ...state,
            isLoading: false
          };
        }

        const { id } = payload;
        if (state.posts) {
          const newPosts = state.posts.map(p => {
            p.comments = p.comments.filter(e => e.id !== id);
            return p;
          });
          return {
            ...state,
            isLoading: false,
            posts: newPosts
          };
        }
        else {
          let newPost = {...state.singlePost};
          newPost.comments = newPost.comments.filter(e => e.id !== id);

          return {
            ...state,
            isLoading: false,
            singlePost: newPost
          };
        }
      },
      [a.receivedUpdatedPost]: (state, post) => {
        if (!state.posts && (!state.singlePost || state.singlePost.id !== post.id)) { 
          return {
            ...state,
            isLoading: false
          };
        }
        if (state.posts) {
          let newPosts = state.posts.map(el => {
            if(el.id === post.id) {
              return updateMessageModel(el, post);
            }
            return el;
          });

          return {
            ...state,
            isLoading: false,
            posts: [...newPosts]
          };
        }
        else {
          return {
            ...state,
            isLoading: false,
            singlePost: updateMessageModel(state.singlePost, post)
          };
        }
      },
      [a.receivedPost]: (state, post) => {
        let newPosts = [post];
        if (state.posts && state.posts.length !== 0) {
          newPosts.push(...state.posts);
        }
        return {
          ...state,
          isLoading: false,
          posts: [...newPosts]
        };
      },
      [a.receivedPosts]: (state, {posts}) => {
        return {
          ...state,
          isLoading: false,
          singlePost: null,
          posts
        };
      },
      [a.receivedSinglePost]: (state, {singlePost}) => {
        return {
          ...state,
          isLoading: false,
          singlePost,
          posts: null
        };
      },
      [a.onError]: (state, error) => {
        return {
          ...state,
          isLoading: false,
          actionError: error || 'Error'
        };
      }
    },
    getDefaultState()
  );
