import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(() => ({
  root: {
    color: '#20a8ed',
    fontSize: 12,
  },
}));

const StyledLink = (props) => {
  const classes = useStyles();
  // const preventDefault = event => event.preventDefault();

  return (
    <Link
      className={classes.root}
      // onClick={preventDefault}
      {...props}
    />
  );
};

export default StyledLink;
