import { gql } from "apollo-boost";

import client from "../../../apolloClient";

export const userNextAttributes = `
      id
      sfUserId
      firstName
      lastName
      countryId
      desiredClubCode
      currentProgramId
      email
      birthDate
      city
      state
      zipcode
      username
      streetAddress
      phoneNumber
      currentSchool
      graduationYear
      roles {
        id
        userId
        roleId
        programId
        partnershipId
        current
        program {
          id
          name
          code
          address
          city
          state
          year
          programType
          programTypeId
          isInternational
          launchDate
          renewalFormSubmitted
          completed
          curriculum {
            id
            code
            name
            usageCount
          }
        }
        partnership {
          id
          name
          code
          isInternational
          parentPartnershipId
          sfOrgId
          year
          membersCount
        }
      }
      parents {
        id
        sfUserId
        firstName
        lastName
        countryId
        desiredClubCode
        currentProgramId
        email
        birthDate
        city
        state
        zipcode
        streetAddress
        phoneNumber
        currentSchool
        graduationYear
        kinshipId
        kindshipName
        relationshipId
        passwordNotSet
      }
      children {
        id
        sfUserId
        firstName
        lastName
        countryId
        desiredClubCode
        currentProgramId
        email
        birthDate
        city
        state
        zipcode
        streetAddress
        phoneNumber
        currentSchool
        graduationYear
        kinshipId
        kindshipName
        relationshipId
        roles {
          id
          userId
          roleId
          programId
          partnershipId
          current
          program {
            id
            code
          }
        }
      }`;

export const SetUserLastLoginActive = (value) => {
  return client.query({
    query: gql`
      {
        UserLastLoginActive (sfUserId: "${value.trim()}"  )
      }`
  });
};

export const GetUserInvitationToken = invitationToken => {
  return client.query({
    query: gql`
  {
    getUserNextInvitationByToken(token:"${invitationToken}"){
      id
      programId
      partnershipId
      pending
      invitationToken
      invitedFirstName
      email
      isUserExist
      facilitatorInvitation
      program {
        id
        code
        programType
        programTypeId
        active
        completed
      }
    }
  }
  `
  });
};
