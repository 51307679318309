import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  DialogContent,
  Box,
  Typography,
  IconButton,
  Button,
  TextField,
} from "@material-ui/core/";
import CloseIcon from "@material-ui/icons/Close";

import { Modal } from "../modal";
import * as colors from "../../../theme/colors";

import { createMarkup } from "../../../utils/generic";

const useStyles = makeStyles((theme) => ({
  dialogContent: { },
  title: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    fontSize: 22,
    fontWeight: "bold",
    margin: "22px 15px 15px 15px",
    textAlign: "center",
  },
  titleBox: {
    background: colors.lightGradient,
    display: "flex",
    justifyContent: 'space-evenly'
  },
  closeBtn: {
    height: 40,
    marginTop: 15,
    width: 40,
  },
  copyBtn: {
    marginLeft: 5,
    color: colors.darkThemeBlueGray,
    fontWeight: "bold",
  },
  textLink: {
    width: "90%",
  },
  contentWrapper: {
    display: 'flex'
  },
  textWrapper: {
    color: colors.darkThemeBlueGray,
    fontSize: 16,
    "& a": {
      color: colors.darkThemeGreen,
      fontSize: 16,
      fontWeight: "bold",
      textTransform: "initial",
      lineHeight: 1.75,
      textDecoration: "none",
      marginTop: 10,
      "&:hover": {
        textDecoration: "none",
      },
    },
  }
}));

export const SubscriptionModal = ({ isOpen, onCloseModal, link, calendarLabels }) => {
  const classes = useStyles();
  let textAreaRef = useRef(null);
  const [isCopied, setIsCopied] = useState(false);

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal}>
      <Box className={classes.titleBox}>
        <Typography variant="h4" className={classes.title}>
          Calendar: Girls Who Code Events
        </Typography>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          className={classes.closeBtn}
          onClick={onCloseModal}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <DialogContent className={classes.dialogContent}>
      {calendarLabels && <Box className={classes.textWrapper} dangerouslySetInnerHTML={createMarkup(calendarLabels.subscriptionInfo)} />}
        <Box className={classes.contentWrapper}>
          <TextField
            className={classes.textLink}
            inputRef={textAreaRef}
            value={link}
          ></TextField>
          <Button
            className={classes.copyBtn}
            onClick={(e) => {
              textAreaRef.current.select();
              navigator.clipboard.writeText(link);
              e.target.focus();
              setIsCopied(true);
            }}
            aria-live="polite"
          >
            {isCopied ? "Copied" : "Copy"}
          </Button>
        </Box>
      </DialogContent>
    </Modal>
  );
};
