import React from "react";
import { Formik, Field, Form } from "formik";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import { HQModal } from "../HQModal";
import TextInput from "../../../components/TextInput";
import CustomButton from "../../../components/customButton/customButton";
import Checkbox from "../../CheckBox";
import { PageLeaveModal } from "../pageLeaveWarning";

const useStyles = makeStyles(() => ({
  form: {
    paddingTop: 15
  },
  updateAllCheckbox: {
    marginTop: 15,
    '& .MuiFormControlLabel-root': {
      margin: 0,
      '& .MuiButtonBase-root': {
        padding: 5,
      }
    }
  },
  buttonGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none",
    fontSize: 16,
  }
}));

const urlExpression =
  /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

export const ClubVirtualLinkModal = ({
  program,
  isOpen,
  onSave,
  onCloseModal,
}) => {
  const classes = useStyles();

  const [isEscapeConfirmDialog, setEscapeConfirmDialog] = React.useState(false);
  const [isDataChanged, setDataChanged] = React.useState(false);

  const validateVirtualLink = (value) => {
    if (!value || !value.trim()) {
      return;
    }

    const isValid = urlExpression.test(value);
    if (!isValid) {
      return "Please enter a valid url";
    }
  };

  return (
    <HQModal title={<>Virtual <span>Conference </span>Link</>} isOpen={isOpen} 
    onClose={() => isDataChanged ? setEscapeConfirmDialog(true) : onCloseModal()}
    >
      <>
        <Formik
          initialValues={{
            launchDate: program.launchDate || "",
            projectEndDate: program.projectEndDate || "",
            virtualConferenceLink: program.virtualConferenceLink || "",
          }}
          validate={() => {
            setDataChanged(true);
          }}
          onSubmit={(values) => {
            const newValues = {
              ...values,
              virtualConferenceLink: values.virtualConferenceLink.trim(),
            };
            onSave(newValues);
            setDataChanged(false);
            onCloseModal();
          }}
        >
          <Form className={classes.form}>
            <Field
              type="text"
              name="virtualConferenceLink"
              component={TextInput}
              label="Virtual Conference Link"
              validate={validateVirtualLink}
              errorIsHtml={true}
            />
            <Box className={classes.updateAllCheckbox}>
              <Field
                name="updateEventsUrl"
                component={Checkbox}
                label={
                  "Update existing and future Calendar Events with this link."
                }
              />
            </Box>
            <div className={classes.buttonGroup}>
              <CustomButton
                mode="textButton"
                onClick={() => isDataChanged ? setEscapeConfirmDialog(true) : onCloseModal()}
                label={"Cancel"}
              />
              <CustomButton mode="primary" type="submit" label={"Save"} />
            </div>
          </Form>
        </Formik>
      </>
      <PageLeaveModal
        onConfirm={() => { setDataChanged(false); setEscapeConfirmDialog(false); onCloseModal(); }}
        onCancel={() => { setEscapeConfirmDialog(false); }}
        open={isEscapeConfirmDialog}
      />
    </HQModal>
  );
};
