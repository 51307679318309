import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import HdrStrongIcon from '@material-ui/icons/HdrStrong';
import { StyledFormHelper } from '../../../components/loginSignup/customFormHelper';
import TextField from '@material-ui/core/TextField';
import * as colors from '../../../theme/colors';

const useStyles = makeStyles({
  error: {
    color: colors.redA400
  }
});

const FlippedIcon = withStyles({
  root: {
    transform: "scale(-1, 1)"
  }
})(props => <HdrStrongIcon {...props}/>);

const UserNameInput = ({ field, form: { errors, touched }, label, isFirstName, ...props }) => {
  const classes = useStyles();
  
  let error = errors[field.name];
  let isTouched = touched[field.name];
  const errorMessageId = `error-message-${field.name}`;

  return (
    <React.Fragment>
      <TextField
        {...props}
        name={field.name}
        error={!!error && isTouched}
        onChange={field.onChange}
        fullWidth
        label={label}
        variant="outlined"
        value={field.value}
        InputLabelProps={{
          "aria-label": label,
          role: "region"
        }}
        InputProps={{
          classes,
          placeholder: label,
          startAdornment: (
            <InputAdornment position="start">
              {isFirstName 
                ? (<FlippedIcon />)
                : (<HdrStrongIcon />)}
            </InputAdornment>
          ),
          "aria-describedby": error ? errorMessageId : null,
        }}
      />
      {error && isTouched && (<StyledFormHelper errorMessageId={errorMessageId} isError={true} isHtml={true} helperText={error} />)}
    </React.Fragment>
  );
};

export default UserNameInput;