import React, { useEffect, useState } from 'react'

import * as moment from "moment";
import objectPath from 'object-path';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import clsx from 'clsx';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import CalendarIcon from '@material-ui/icons/CalendarTodayOutlined';
import { StyledFormHelper } from '../../../components/loginSignup/customFormHelper';
import CustomToolbar from '../../../components/date-input/CustomDateToolbar';
import {DATE_FORMAT, DATE_FORMAT_TIMEZONE} from '../../../utils/constants';
import { getCountryFormat } from '../../../utils/generic';
import {format} from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  icon: {
    margin: 0,
    padding: 0,
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
  },
  datePickerButton: {
    color: theme.palette.primary.dark,
    padding: 0,
  },
  yearButton: {
    '& .MuiPickersYear-root': {
      '&:focus': {
        outline: 'thin solid black',
        outlineOffset: -2,
        borderRadius: 2,
      },
    }
  },
}));

const DateInput = ({ field: { name, value }, form, label, domesticId, checkCountryAge, ...props }) => {
  const classes = useStyles();
  const [selectedDate, handleDateChange] = useState(null);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    value = moment(value).isValid() ? moment(value).format(DATE_FORMAT_TIMEZONE) : '';
    handleDateChange(value);
  }, []);

  const errorMessageId = `error-message-${name}`;
  let error = form.errors[name];
  
  if (name.indexOf('[') !== -1 || name.indexOf('.') !== -1) {
    const path = name.replace('[', '.').replace(']', '').split('.');
    error = objectPath.get(form.errors, path);
  }

  let countryFormat = getCountryFormat(form.values.country, domesticId);
  if (checkCountryAge) {
    checkCountryAge(form.values.country, form.values.birthDate);
  }
  
  return (
    <React.Fragment>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        id="register-bday"
        sel="date"
        disableFuture
        minDate={moment().subtract(100, 'years')}
        autoOk
        required
        error={!!error && showError}
        helperText={null}
        placeholder={countryFormat.toUpperCase()}
        className={clsx(classes.root, props.className)}
        variant="inline"
        inputVariant="outlined"
        label={label}
        format={countryFormat}
        value={selectedDate}
        role={props.elementRole || "region"}
        aria-label={label}
        ToolbarComponent={(props) => <CustomToolbar {...props} />}
        KeyboardButtonProps={{
          className: classes.datePickerButton,
          "aria-label": "Date Picker"
        }}
        keyboardIcon={<CalendarIcon />}
        InputAdornmentProps={{ position: "start", classes: { expandIcon: classes.icon } }}
        onChange={(date, dateStr) => {
          // show error only when date fullfilled
          setShowError(
            !!dateStr && !dateStr.includes("_") && dateStr.length === 10
          );

          handleDateChange(date);
          const val = moment(date).isValid() ? date : '';
          form.setFieldValue(name, val);
          if (checkCountryAge) {
            checkCountryAge(form.values.country, val);
          }
        }}
        onAccept={(date, dateStr) => {
          // show error only when date fullfilled
          setShowError(
            !!dateStr && !dateStr.includes("_") && dateStr.length === 10
          );

          form.setFieldValue(name, moment.parseZone(date).format(DATE_FORMAT));
        }}
        inputProps={{
          autoComplete: 'off',
          "aria-label": 'Check selected date or add it in a proper format'
        }}
        InputProps={{
          "aria-describedby": error ? errorMessageId : null,
          "aria-label": 'Check selected date or add it in a proper format'
        }}
        leftArrowButtonProps = {{"aria-label": "Show previous month"}}
        rightArrowButtonProps = {{"aria-label": "Show next month"}}
        renderDay={renderDay}
        PopoverProps={{
          PaperProps: {
            classes: {
              root: classes.yearButton,
            },
          },
        }}
      />
    </MuiPickersUtilsProvider>
    {error && showError &&
      (<StyledFormHelper errorMessageId={errorMessageId} helperText={error} isError={true} isHtml={true} />)}
    </React.Fragment>
  );
};

const renderDay = (day, selectedDate, dayInCurrentMonth, dayComponent) => {
  const ariaLabel = `${format(day, 'd, EEEE, MMMM yyyy')}`;

  return React.cloneElement(dayComponent, {
    'aria-label': ariaLabel
  });
}

export default DateInput;
