import { createReducer } from "redux-act";

import * as a from "../actions/auth";
import { takeIfExists } from "../utils/local-storage";
import { permissionsByRole } from "../utils/permissions";

const getDefaultState = () => ({
  currentRole: null,
  token: takeIfExists("token"),
  refreshToken: takeIfExists("refreshToken"),
  loginError: false,
  loginErrorType: null,
  user: null,
  isUserLoading: false,
  programs: [],
  currentProgram: null,
  isHome: true,
  isCreatingChild: false,
  isSettingParentConsent: false,
  parentConsentError: false,
  enrolledChildError: false,
  childReceived: {}
});

export default () =>
  createReducer(
    {
      [a.authorize]: (state) => ({
        ...state,
        loginError: false,
        loginErrorType: null,
      }),
      [a.authorizeParent]: (state) => ({
        ...state,
        loginError: false,
        loginErrorType: null,
        isUserLoading: true,
      }),
      [a.receiveAuthData]: (
        state,
        { token, refreshToken }
      ) => ({
        ...state,
        loginError: false,
        loginErrorType: null,
        refreshToken,
        token,
      }),
      [a.invalidCredentials]: (state, loginErrorType) => ({
        ...state,
        loginError: true,
        loginErrorType: loginErrorType || null,
        isUserLoading: false,
      }),
      [a.logoutSuccess]: () => {
        return getDefaultState();
      },
      [a.currentUserLoaded]: (state, user) => {
        let currentRole = null;
        if (!!user.roles && user.roles.length > 0) {
          for (const role of user.roles) {
            if (role.current === true) {
              currentRole = role;
              break;
            }
          }

          if (!currentRole) {
            currentRole = user.roles[0];
          }
        }

        if (currentRole) {
          currentRole.permissions = currentRole && currentRole.roleId
            ? permissionsByRole[currentRole.roleId]
            : {};
        }

        user.roles = user.roles ?? state.user.roles;

        return {
          ...state,
          currentRole,
          user,
          isUserLoading: false,
        };
      },
      [a.currentUserUpdated]: (state, dataToUpdate) => (
        {
          ...state,
          user: {
            ...state.user,
            ...dataToUpdate
          }
        }
      ),
      [a.changeRole]: (state, role) => {
        const roles = [];
        let currentRole = null;

        const { programId, cpOrganizationId } = role;

        for (const uRole of state.user.roles) {
          const item = {
            ...uRole,
            current:
              uRole.programId === parseInt(programId) ||
              uRole.cpOrganizationId === parseInt(cpOrganizationId)
          };

          roles.push(item);
          if (item.current === true) {
            currentRole = item;
          }
        }

        currentRole.permissions = currentRole && currentRole.roleId
          ? permissionsByRole[currentRole.roleId]
          : {};

        return {
          ...state,
          currentRole,
          user: {
            ...state.user,
            roles,
          },
        };
      },
      [a.receivedHome]: (state, isHome) => {
        return {
          ...state,
          isHome,
        };
      },
      [a.receivedChild]: (state, payload) => {
        const { child } = payload;
        const children = [];

        for (const c of state.user.children) {
          if (c.id === child.id) {
            children.push(child);
          } else {
            children.push(c);
          }
        }
        const isNew = children.find((e) => e.id === child.id);

        if (!isNew) {
          children.push(child);
        }

        const newUser = { ...state.user, children };
        return {
          ...state,
          user: newUser,
          isCreatingChild: false
        };
      },
      [a.receiveConferenceLink]: (state, data) => {
        const { programId, virtualConferenceLink } = data;
        const programRole = state.user.roles
          ? state.user.roles.find(e => e.programId === parseInt(programId))
          : null;

        if (!!programRole && !!programRole.program) {
          programRole.program.virtualConferenceLink = virtualConferenceLink;
        }

        return {
          ...state,
          user: {...state.user},
        };
      },
      [a.receivedUserProgram]: (state, data) => {
        const { programId } = data;
        const programRole = state.user.roles
          ? state.user.roles.find(e => e.programId === parseInt(programId))
          : null;

        if (!!programRole && !!programRole.program) {
          programRole.program.renewalFormSubmitted = true;
        }

        return {
          ...state,
          user: {...state.user},
        };
      },
      [a.recievedFacilitatorsConsent]: (state, data) => {
        const currentRole = {
          ...state.currentRole,
          program: {
            ...state.currentRole.program,
            facilitatorsConsent: [data],
          },
        };
        
        return {
          ...state,
          currentRole,
        };
      },
      [a.createChild]: (state) => {
        return {
          ...state,
          isCreatingChild: true,
        };
      },
      [a.enrolledChild]: (state, data) => {
        return {
          ...state,
          isCreatingChild: false,
          enrolledChildError: data?.enrolledChildError
        };
      },
      [a.setParentConsent]: (state) => {
        return {
          ...state,
          isSettingParentConsent: true,
        };
      },
      [a.receivedParentConsent]: (state, data) => {
        const { acceptedParentConsentAt, error } = data;
        
        const receivedConsentState = {
          ...state,
          isSettingParentConsent: false,
          parentConsentError: error
        };

        if (!state.user?.acceptedParentConsentAt && !!acceptedParentConsentAt) {
          receivedConsentState.user.acceptedParentConsentAt = acceptedParentConsentAt;
        }

        return receivedConsentState;
      },
      [a.setChildReceived]: (state, data) => {
        return {
          ...state,
          childReceived: data
        };
      },
    },
    getDefaultState()
  );
