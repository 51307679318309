import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createMarkup } from '../../utils/generic';
import { StyledFormHelper } from '../loginSignup/customFormHelper';
import * as colors from "../../theme/colors";

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: colors.darkThemeGreen,
    },
  },
  label: {
    fontSize: 12,
  },
  error: {
    color: theme.palette.error.main,
  },
}));

const CustomLabel = (props) => (
  <span className={props.className} dangerouslySetInnerHTML={createMarkup(props.termsContent)}></span>
);

const TermsInput = ({ field: { name, onChange, value }, form: { errors }, termsContent, onValueChange, ...props }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <FormControlLabel
        sel="terms-policy"
        className={clsx(props.className, classes.root)}
        control={
          <Checkbox
            className={classes.label}
            name={name}
            checked={value}
            onChange={(event) => {
              if (onValueChange) {
                onValueChange(event.target.checked);
              }
              onChange(event);
            }}
          />
        }
        label={<CustomLabel className={classes.label} termsContent={termsContent} />}
      />
      {errors[name] && <StyledFormHelper isError={true} isHtml={true} helperText={errors[name]} />}
    </React.Fragment>
  );
};

export default TermsInput;
