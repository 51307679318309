import client from "../../../apolloClient";
import { gql } from "apollo-boost";
import obj2arg from "graphql-obj2arg";

export function addTrackingLog({
  fingerprint,
  event,
  duration,
  data,
  userNextId
}) {
  const graphQlArguments = {
    fingerprint,
    event,
    duration: duration ? duration.toString() : null,
    data,
    userNextId
  };

  return client.mutate({
    mutation: gql`mutation {
      createTrackingLog( tracking: ${obj2arg(graphQlArguments)}) {
        id
        }
      }`
  });
}

export function addUsageLogs({
  action,
  userNextId,
  programId,
  collection,
  set,
  activity,
  url,
  data,
  fingerprint,
}) {
  const graphQlArguments = {
    action,
    userNextId,
    programId,
    curriculumCollection: collection,
    curriculumSet: set,
    curriculumActivity: activity,
    url,
    metadata: data,
    fingerprint,
  };

  if (programId) {
    graphQlArguments.programId = programId;
  }

  return client.mutate({
    mutation: gql`  mutation {
      createUsageLogEntry(${obj2arg(graphQlArguments, { noOuterBraces: true })})
    }`
  });
}

export function addUsageTrackingEntry({ feature }) {
  const graphQlArguments = { feature };

  return client.mutate({
    mutation: gql`mutation {
    createUsageTrackingEntry(${obj2arg(graphQlArguments, {
      noOuterBraces: true
    })})
  }`
  });
}

export function addUsageTrackingEvents({ usageTrackingId, event }) {
  const graphQlArguments = {
    usageTrackingId: usageTrackingId,
    event,
  };

  return client.mutate({
    mutation: gql`mutation {
      registerUsageTrackingEvents(${obj2arg(graphQlArguments, {
        noOuterBraces: true
      })})
    }`
  });
}

export const createFingerprint = ({ hash, data }) => {
  const graphQlArguments = {
    fingerprint: {
      hash,
      data,
    },
  };

  return client.mutate({
    mutation: gql`mutation {
      createFingerprint(${obj2arg(graphQlArguments, { noOuterBraces: true, keepNulls: true })}) {
        hash
      }
    }`
  });
};
