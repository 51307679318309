import React, { useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';

import {
  Box,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Divider,
} from '@material-ui/core/';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';

import HqAvatar from '../../../components/avatar/avatar';
import * as colors from '../../../theme/colors';

import CustomButton from '../../../components/customButton/customButton';
import TextCustomButton from '../../../components/text-button/text-button';
import { getDateLabel } from '../../../utils/generic';
import AnswerGroupCard from './answerCards/answerGroupCard';
import { TASK_STATUSES } from '../../../utils/constants';
import CommentCard from './answerCards/commentCard';
import AddComment from './answerCards/addComment';
import Collapse from "@material-ui/core/Collapse";
import { createMarkup } from "../../../utils/generic";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: '0px 24px 16px',
    minWidth: 480,
    [theme.breakpoints.down('sm')]: {
      minWidth: 350,
      marginBottom: 20,
    },
  },
  content: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  formWrapper: {
    display: 'flex',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 24,
  },
  addCommentBox: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    marginTop: 5,
  },
  question: {
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  titleLabel: {
    fontSize: 18,
    color: colors.darkThemeBlueGray,
    fontWeight: 'bold',
    wordBreak: 'break-word',
  },
  buttonGroup: {
    marginTop: 30,
    height: 42,
    width: '100%',
    justifyContent: 'right',
    display: 'flex',
    boxShadow: 'none',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginTop: 10,
    },
  },
  cancelBtn: {
    marginRight: 10,
  },
  completeBtn: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 8,
    },
  },
  cardHeaderContainerWrapper: {
    alignItems: 'normal',
  },
  divider: {
    marginBottom: 20,
  },
  lowerDivider: {
    marginTop: 20,
  },
  cardHeaderContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  createdAtLabel: {
    fontSize: 12,
    color: colors.lightGrey,
  },
  createdAtLabelAnswer: {
    paddingTop: 10,
  },
  progressIcon: {
    marginLeft: 10,
  },
  gradingNotRequiredText: {
    fontWeight: 'bold',
  },
  viewOlderBtn: {
    marginLeft: 'auto',
  },
  chatBubbleIcon: {
    marginRight: 3,
    height: 20,
  },
  itemDivider: {
    marginBottom: 40,
  },
  questionContent: {
    color: colors.darkThemeBlueGray,
    fontSize: 14,
    width: "100%",
  },
  completedBox: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    color: "#083861",
  },
}));

const TeacherViewAnswerGroupCard = ({
  user,
  students,
  answerGroup,
  headerContent,
  isLoading,
  updateAnswerGroupsStatus,
  deleteAnswerGroupComment,
  requiredGrading,
  setViewUserKey,
  answersToReviewToRender,
  answerGroupQuestions,
  isAnswerGroup,
  addAnswerGroupComment,
  updateComment,
  updateAnswerGroupComment,
  history,
}) => {
  const classes = useStyles();

  const {
    id,
    programId,
    openTextAnswers,
    contentfulGroupId,
    userId,
    createdAt,
    submittedAt,
  } = answerGroup;

  const [addCommentMode, setAddCommentMode] = useState(false);
  const [expandedOlderAnswers, setExpandedOlderAnswers] = useState([]);
  const [currentGroupQuestionDescription, setCurrentGroupQuestionDescription] = React.useState({});
  const student = students.find((e) => e.userId === userId);
  const questions = answerGroupQuestions[contentfulGroupId];

  const questionsWithAnswers = useMemo(() => {
    const result = {};
    openTextAnswers.map((answer) => {
      const question = questions.find(({ id }) => id === answer.question);
      // if we dont have this question - return
      if (!question) return;
      // then - merge this question and answers to one object
      if (result[question.id]) {
        result[question.id].answers.push(answer);
      } else {
        result[question.id] = { answers: [answer], question };
      }
    });
    // if no answer submitted - add "No Response" to question
    questions.map((question) => {
      const answer = openTextAnswers.find((answer) => question.id === answer.question);
      if (!answer) {
        result[question.id] = { answers: ["No Response"], question };
      }
    })
    // sort questions in order they placed in contentful
    return Object.values(result).sort((a,b) => Number(a.question.contentSectionGroup.current) - Number(b.question.contentSectionGroup.current));
  }, [answerGroupQuestions, openTextAnswers]);

  const index = answersToReviewToRender.findIndex((e) => e.userId === userId);
  const nextUserId =
    answersToReviewToRender.length - 1 > index
      ? answersToReviewToRender[index + 1].userId
      : null;
  const showActionButtons = Object.values(addCommentMode).filter(
    (e) => !!e
  ).length;
  const name = student
    ? [student.firstName, student.lastName].filter((e) => !!e).join(' ')
    : '';

  const toggleViewOlderAnswers = (id) => {
    if (expandedOlderAnswers.includes(id)) {
      setExpandedOlderAnswers(expandedOlderAnswers.filter((answerId) => answerId !== id));
    } else {
      setExpandedOlderAnswers([...expandedOlderAnswers, id]);
    }
  };

  useEffect(() => {
    setCurrentGroupQuestionDescription({});
    setExpandedOlderAnswers([]);
  }, [answerGroup]);

  return (
    <Box>
      <CardHeader
        className={classes.cardHeaderContainerWrapper}
        avatar={
          <HqAvatar
            user={student}
            src={student.avatarName && student.avatarUrl}
          />
        }
        title={
          <Box className={classes.cardHeaderContainer}>
            <Box className={classes.formWrapper}>
              <Typography variant="h2" className={classes.title}>
                {name}
              </Typography>
              {isLoading && (
                <CircularProgress className={classes.progressIcon} size={24} />
              )}
            </Box>
            {submittedAt && (
              <Box className={classes.formWrapper}>
                <Typography
                  className={clsx(
                    classes.createdAtLabel,
                    classes.createdAtLabelAnswer
                  )}
                >
                  Submitted: {getDateLabel(createdAt)}
                </Typography>
              </Box>
            )}
          </Box>
        }
      />

      <CardContent className={classes.dialogContent}>
        <Divider className={classes.divider} />
        <Box className={classes.content}>
          <Grid container>
            {!!questionsWithAnswers.length &&
              questionsWithAnswers.map(({ question, answers }, i) => {
                const isLast = i === questionsWithAnswers.length - 1;
                const isShowingOlderAnswers = expandedOlderAnswers.includes(question.id);

                return (
                  <Box className={classes.question}>
                    <Box className={classes.titleContainer}>
                      <Box className={classes.titleLabel}>
                        {question.heading}
                      </Box>
                      <Box className={classes.completedBox}>
                        <TextCustomButton
                          onClick={() => {
                            currentGroupQuestionDescription[question?.id] = currentGroupQuestionDescription[question?.id] === undefined ||
                            !currentGroupQuestionDescription[question?.id];

                            setCurrentGroupQuestionDescription({ ...currentGroupQuestionDescription})
                          }}
                          label={`${currentGroupQuestionDescription[question?.id] ? "Hide Question Description" : "View Question Description"}`.toUpperCase()}
                          endIcon={currentGroupQuestionDescription[question?.id] ? <ExpandLess /> : <ExpandMore />}
                          sel="view-completed"
                        />
                       </Box>
                      {answers.length > 1 && (
                        <TextCustomButton
                          className={classes.viewOlderBtn}
                          label={isShowingOlderAnswers ? 'Hide older' : 'View older'}
                          onClick={() => toggleViewOlderAnswers(question.id)}
                        />
                      )}
                    </Box>
                    <Collapse 
                      in={currentGroupQuestionDescription[question?.id]} 
                      timeout="auto" unmountOnExit>
                      <Box
                        className={classes.questionContent}
                        dangerouslySetInnerHTML={createMarkup(question?.content)}
                      />
                    </Collapse>
                    {isShowingOlderAnswers ? (
                      answers.map((answer) => {
                        return (
                          <AnswerGroupCard
                            key={answer.id}
                            user={user}
                            answer={answer}
                            headerContent={headerContent}
                            showDate={true}
                          />
                        );
                      })
                    ) : (
                      <AnswerGroupCard
                        key={answers[0].id}
                        user={user}
                        answer={answers[0]}
                        headerContent={headerContent}
                        showDate={true}
                      />
                    )}
                    {!isLast && <Divider className={classes.itemDivider} />}
                  </Box>
                );
              })}
          </Grid>
          <TextCustomButton
            onClick={() => {
              setAddCommentMode(true);
            }}
            label={
              <Box className={classes.addCommentBox}>
                <ChatBubbleOutlineIcon className={classes.chatBubbleIcon} />
                Add comments
              </Box>
            }
          />
          <Box className={classes.commentsBox}>

          {!!addCommentMode
            ? <AddComment
              answer={answerGroup}
              onCancel={() => setAddCommentMode(false)}
              onSubmit={addAnswerGroupComment}
              headerContent={headerContent}
              isAnswerGroup={isAnswerGroup}
              history={history}
            />
            : <>{!!answerGroup.answerGroupComments?.length && answerGroup.answerGroupComments
              .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
              .map((comment) => {
              return <CommentCard
                key={comment.id}
                classes={classes}
                answer={answerGroup}
                comment={comment}
                userId={user.id}
                deleteAnswerGroupComment={deleteAnswerGroupComment}
                isAnswerGroup={isAnswerGroup}
                updateComment={updateComment}
                updateAnswerGroupComment={updateAnswerGroupComment}
                history={history}
                isLoading={isLoading}
              />
            })
            }</>}
            </Box>
        </Box>

        {!showActionButtons && (
          <>
            <Divider className={clsx(classes.divider, classes.lowerDivider)} />
            <div className={classes.buttonGroup}>
              {requiredGrading ? (
                <Box>
                  <CustomButton
                    className={classes.cancelBtn}
                    mode="textButton"
                    onClick={() => {
                      updateAnswerGroupsStatus({
                        id,
                        programId,
                        status: TASK_STATUSES.incomplete,
                      });
                      setViewUserKey(nextUserId);
                    }}
                    label="Mark Incomplete"
                  />
                  <CustomButton
                    mode="primary"
                    className={classes.completeBtn}
                    type="submit"
                    onClick={() => {
                      updateAnswerGroupsStatus({
                        id,
                        programId,
                        status: TASK_STATUSES.complete,
                      });
                      setViewUserKey(nextUserId);
                    }}
                    label="Mark Complete"
                  />
                </Box>
              ) : (
                <Typography
                  className={classes.gradingNotRequiredText}
                  variant="body1"
                >
                  Grading not required
                </Typography>
              )}
            </div>
          </>
        )}
      </CardContent>
    </Box>
  );
};

export default TeacherViewAnswerGroupCard;
