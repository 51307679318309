import client from '../../apolloClient';
import { gql } from 'apollo-boost';
import ContentSectionGroup from './types/ContentSectionGroup';

export const getCurriculum = (programId, partnershipId, studentView) => {
  const id = programId || "";
  const cpId = partnershipId || "";
  
  return client
    .query({
      fetchPolicy: "no-cache",
      query: gql`
        {
          CurriculumQuery(
            programId: "${id}",
            partnershipId: "${cpId}",
            studentView: ${studentView},
            ) {
            code
            welcomeModal {
              id
              name
              modalContentSections {
                name
                heading
                content
                buttonLabel
              }
            }
            content {
              ... on Collection {
                id
                name
                invisibleCollection
                certificateType {
                  name
                  certificateCode
                }
                endsInCertificate
                program
                permissionLevel
                slug
                icon {
                  name
                  src
                  alt
                }
                collectionType {
                  name
                  colorTheme
                }
                welcomeModal {
                  id
                  name
                  modalContentSections {
                    name
                    heading
                    content
                    buttonLabel
                  }
                }
                sets {
                  id
                  type
                  name
                  slug
                  shortDescription
                  introduction
                  goals
                  estimatedTimeInMinutes
                  tags
                  externalUrl
                  permissionLevel
                  easeOfFacilitation
                  welcomeModal {
                  id
                  name
                  modalContentSections {
                    name
                    heading
                    content
                    buttonLabel
                  }
                }
                  contentSections {
                    id
                    type
                    ... on ContentSection {
                      heading
                      subheading
                      content
                      facilitatorNote
                      visibleInPageNavigation
                      onlyVisibleToFacilitators
                      isOpenTextAnswer
                      requiredGrading
                      isOptionalTextAnswer
                      contentSectionGroup {
                        ${ContentSectionGroup}
                      }
                    }
                    ... on QuizQuestion {
                      header
                      isOptionalQuiz
                    }
                  }
                  activities {
                    id
                    name
                    slug
                    shortDescription
                    estimatedTimeInMinutes
                    tags
                    isOptional
                    permissionLevel
                    easeOfFacilitation
                    activityType {
                      name
                      colorTheme
                    }
                    icon {
                      name
                      src
                      alt
                    }
                    contentSections {
                      id
                      type
                      ... on ContentSection {
                        heading
                        subheading
                        content
                        facilitatorNote
                        visibleInPageNavigation
                        onlyVisibleToFacilitators
                        isOpenTextAnswer
                        requiredGrading
                        isOptionalTextAnswer
                        contentSectionGroup {
                          ${ContentSectionGroup}
                        }
                      }
                      ... on QuizQuestion {
                        name
                        header
                        description
                        isOptionalQuiz
                        answers {
                          ... on QuizAnswer {
                            name
                            answerTitle
                            answerBody
                            hint
                            isCorrect
                          }
                        }
                      }
                    }
                  }
                  setType {
                    name
                    colorTheme
                  }
                  icon {
                    name
                    src
                    alt
                  }
                }
              }
              ... on CurriculumFolder {
                id
                name
                slug
                permissionLevel
                folderType {
                  name
                  colorTheme
                }
                coverImage {
                  name
                  src
                  alt
                }
                welcomeModal {
                  id
                  name
                  modalContentSections {
                    name
                    heading
                    content
                    buttonLabel
                  }
                }
                content {
                  id
                  name
                  invisibleCollection
                  certificateType {
                    name
                    certificateCode
                  }
                  endsInCertificate
                  program
                  permissionLevel
                  slug
                  icon {
                    name
                    src
                    alt
                  }
                  collectionType {
                    name
                    colorTheme
                  }
                  welcomeModal {
                    id
                    name
                    modalContentSections {
                      name
                      heading
                      content
                      buttonLabel
                    }
                  }
                  sets {
                    id
                    type
                    name
                    slug
                    shortDescription
                    introduction
                    goals
                    estimatedTimeInMinutes
                    tags
                    externalUrl
                    permissionLevel
                    easeOfFacilitation
                    welcomeModal {
                      id
                      name
                      modalContentSections {
                        name
                        heading
                        content
                        buttonLabel
                      }
                    }
                    activities {
                      id
                      name
                      slug
                      estimatedTimeInMinutes
                      tags
                      shortDescription
                      isOptional
                      permissionLevel
                      easeOfFacilitation
                      activityType {
                        name
                        colorTheme
                      }
                      contentSections {
                        id
                        type
                        ... on ContentSection {
                          heading
                          subheading
                          content
                          facilitatorNote
                          visibleInPageNavigation
                          onlyVisibleToFacilitators
                          isOpenTextAnswer
                          requiredGrading
                          isOptionalTextAnswer
                          contentSectionGroup {
                            ${ContentSectionGroup}
                          }
                        }
                        ... on QuizQuestion {
                          name
                          header
                          description
                          isOptionalQuiz
                        }
                      }
                      icon {
                        name
                        src
                        alt
                      }
                      contentSections {
                        id
                        type
                        ... on ContentSection {
                          heading
                          subheading
                          content
                          facilitatorNote
                          visibleInPageNavigation
                          onlyVisibleToFacilitators
                          isOpenTextAnswer
                          requiredGrading
                          isOptionalTextAnswer
                          contentSectionGroup {
                            ${ContentSectionGroup}
                          }
                        }
                        ... on QuizQuestion {
                          header
                          isOptionalQuiz
                        }
                      }
                    }
                    contentSections {
                      id
                      type
                      ... on ContentSection {
                        heading
                        subheading
                        content
                        facilitatorNote
                        visibleInPageNavigation
                        onlyVisibleToFacilitators
                        isOpenTextAnswer
                        requiredGrading
                        isOptionalTextAnswer
                        contentSectionGroup {
                          ${ContentSectionGroup}
                        }
                      }
                      ... on QuizQuestion {
                        header
                        isOptionalQuiz
                      }
                    }
                    setType {
                      name
                      colorTheme
                    }
                    icon {
                      name
                      src
                      alt
                    }
                  }
                }
              }
            }
          }
        }
      `
    });
}