import { createReducer } from "redux-act";

import * as a from "../actions/communityPartnerships";

const getDefaultState = () => ({
  isLoading: true,
  communityPartnership: null,
  partnershipStats: null,
  emailTemplate: null,
});

export default () =>
  createReducer(
    {
      [a.getCommunityPartnership]: state => ({
        ...state,
        isLoading: true
      }),
      [a.receivedCommunityPartnership]: (state, {communityPartnership, partnershipStats}) => ({
        ...state,
        isLoading: false,
        communityPartnership: {
          ...state.communityPartnership,
          ...communityPartnership,
          communityPartners: communityPartnership.communityPartners || state.communityPartnership.communityPartners,
        },
        partnershipStats,
      }),
      [a.receivedCommunityPartnershipStatistic]: (state, {partnershipStats}) => ({
        ...state,
        isLoading: false,
        partnershipStats,
      }),
      [a.receiveEmailPreviewTemplate] : (state, emailTemplate) => {
        return {
        ...state,
        emailTemplate,
      }}
    },
    getDefaultState()
  );
