import React from "react";
import { Formik, Field, Form } from "formik";

import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";

import { HQModal } from "../HQModal";
import TextInput from "../../../components/TextInput";
import * as colors from "../../../theme/colors";
import CustomButton from "../../../components/customButton/customButton";
import { PageLeaveModal } from "../pageLeaveWarning";

const useStyles = makeStyles({
  buttonGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none",
    fontSize: 16,
  },
  button: {
    borderRadius: 5,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: colors.grey400,
    color: "white",
    "&:hover": {
      backgroundColor: colors.grey500,
    },
  },
  submitButton: {
    borderRadius: 5,
    backgroundColor: colors.tealA700,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: colors.teal500,
    },
  },
  updateAllCheckbox: {
    '& .MuiFormControlLabel-root': {
      margin: "0px",
      '& .MuiButtonBase-root': {
        padding: '9px 0px',
      }
    }
  },
});

export const СlubSocialMediaModal = ({
  program,
  isOpen,
  onSave,
  onCloseModal,
}) => {
  const classes = useStyles();

  const [isEscapeConfirmDialog, setEscapeConfirmDialog] = React.useState(false);
  const [isDataChanged, setDataChanged] = React.useState(false);

  return (
    <HQModal title="Social Media" isOpen={isOpen} onClose={() => isDataChanged ? setEscapeConfirmDialog(true) : onCloseModal()}>
      <DialogContent className={classes.dialogContent}>
        <Formik
          initialValues={{
            socialMedia: program.socialMedia || "",
          }}
          validate={() => {
            setDataChanged(true);
          }}
          onSubmit={(values) => {
            onSave({
              socialMedia: values.socialMedia.trim(),
            });
            onCloseModal();
          }}
        >
          <Form>
            <Field
              type="text"
              name="socialMedia"
              component={TextInput}
              label="Social Media"
              errorIsHtml={true}
            />
            <div className={classes.buttonGroup}>
              <CustomButton
                mode="textButton"
                onClick={() => isDataChanged ? setEscapeConfirmDialog(true) : onCloseModal()}
                label={"Cancel"}
              />
              <CustomButton mode="primary" type="submit" label={"Save"} />
            </div>
          </Form>
        </Formik>
      </DialogContent>
      <PageLeaveModal
        onConfirm={() => { setDataChanged(false); setEscapeConfirmDialog(false); onCloseModal(); }}
        onCancel={() => { setEscapeConfirmDialog(false); }}
        open={isEscapeConfirmDialog}
      />
    </HQModal>
  );
};
