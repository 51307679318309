import React from "react";

import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "../../../components/customButton/customButton";

const useStyles = makeStyles(() => ({
  introductionWrapper: {
    overflow: "hidden",
    height: "100%",
    padding: 30,
  },
  introductionMsg: {
    paddingBottom: 20, 
  },
  continueBtn: {
    width: '100%',
  } 
}));

export const Introduction = () => {
  const classes = useStyles();
  return (
    <Box className={classes.introductionWrapper}>
      <Typography className={classes.introductionMsg}>
        Register your child in 4 steps! Make sure that you have the Program Code provided by your child’s Facilitator handy before getting started.
      </Typography>
      <Typography className={classes.introductionMsg}>
        If you are adding multiple children, select ‘ADD ANOTHER CHILD’ after completing the registration for your first child.
      </Typography>
      <CustomButton
        mode="primary"
        disabled={false}
        type="submit"
        className={classes.continueBtn}
        label="Get Started"
      />
    </Box>
  );
};
