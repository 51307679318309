import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import * as colors from "../../theme/colors";
import * as tokenStorage from '../../tokenStorage';
import CustomButton from "../../components/customButton/customButton";

const useStyles = makeStyles({
  frameContainer: {
    height: "calc(100vh - 145px)",
    maxHeight: "calc(100vh - 145px)",
    position: "relative",
    maxWidth: "940px",
  },
  frame: {
    border: "0",
    height: "100%",
    width: "100%"
  },
  buttonGroup: {
    margin: "30px 0 30px",
    paddingLeft: "30px",
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none"
  },
  button: {
    borderRadius: 5,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: colors.grey400,
    color: "white",
    margin: "15px",
    "&:hover": {
      backgroundColor: colors.grey500
    }
  }
});

const stage = 'next';

const createSurveyUrl = (currentRole, global) => {

  // only for college loops;
  if (!currentRole.program) {
    return;
  }
  const domain = window.location.host.split('.')[0]
  
  const url = domain === stage ?
    "https://www.surveygizmo.com/s3/5465014/GWCFULL-NEXTHQ-Renewal-Welcome-to-the-Girls-Who-Code-2020-21-College-Loops-Application?clubCode={clubCode}&successCode={successCode}&env=STAGE&token={token}"
    : global.collegeLoopRenewalNextFormUrl;

  const token = tokenStorage.getToken();

  return url
    .replace(/{clubCode}/gi, currentRole.program.code)
    .replace(/{successCode}/gi, currentRole.program.code)
    .replace(/{token}/gi, token);
};

const RenewalModal = ({ labels, currentRole, isOpen, onClose }) => {
  const classes = useStyles();
  let url = "";
  const { GlobalComponentQuery: global } = labels;
  if (global && currentRole) {
    url = createSurveyUrl(currentRole, global);
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={"md"}>
      <DialogContent className={classes.frameContainer}>
        <iframe className={classes.frame} src={url} title="Renewal modal"></iframe>
      </DialogContent>
      <div className={classes.buttonGroup}>
        <CustomButton onClick={onClose} mode="secondary" label="Cancel" />
      </div>
    </Dialog>
  );
};

export default RenewalModal;
