import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { HQModal } from "./modals/HQModal";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CustomButton from "../components/customButton/customButton";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: 10
  },
  content: {
    color: theme.palette.primary.main,
    fontSize: 17,
    textAlign: "center",
    maxWidth: 330
  },
  buttonGroup: {
    padding: "20px 15px",
    justifyContent: "space-between",
    display: "flex",
    fontSize: 16,
    boxShadow: "none"
  },
  leftAlignContent: {
    textAlign: "left",
  }
}));

const ConfirmDialog = (props) => {
  const { content, onCancel, onConfirm, open, title, cancelTitle, okTitle, leftAlignContent } = props;
  const classes = useStyles();

  return (
    <HQModal title={title} isOpen={open} onClose={onCancel}>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={leftAlignContent ? clsx(classes.content, classes.leftAlignContent) : classes.content}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.buttonGroup}>
        <CustomButton
          mode="textButton"
          label={cancelTitle ?? "Cancel"}
          onClick={onCancel}
          sel="confirmCancel"
        />
        <CustomButton
          mode="primary"
          label={okTitle ?? "OK"}
          onClick={onConfirm}
          sel="confirmOk"
        />
      </DialogActions>
    </HQModal>
  );
};

export default ConfirmDialog;
