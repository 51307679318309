import { createReducer } from 'redux-act'

import * as a from '../actions/contentful';

const getDefaultState = () => ({
  content: {},
  curriculums: {},
  studentCurriculums: {},
  languages: [],
  hqConfig: {},
  parentConsent: {},
  programTypes: [],
})

export default () =>
  createReducer(
    {
      [a.globalContentLoaded]: (state, data) => {
        return {
          ...state,
          ...data,
        };
      },
      [a.homePageContentLoaded]: (state, data) => {
        return {
          ...state,
          ...data,
        };
      },
      [a.curriculumLoaded]: (state, { code, welcomeModal, nodes }) => {
        return {
          ...state,
          curriculums: {
            ...state.curriculums,
            [code]: {
              code,
              welcomeModal,
              nodes: nodes.curriculums
            },
          },
          studentCurriculums: {
            ...state.studentCurriculums,
            [code]: {
              code,
              welcomeModal,
              nodes: nodes.studentCurriculums
            },
          },
        };
      },
    },
    getDefaultState()
  )
