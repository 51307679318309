import * as moment from "moment";

import { getToken, clear } from "./tokenStorage";

// one day by default
const inactivityDays = window._env_.REACT_APP_ACTIVITY_DAYS || 1;

export function logout() {
  clear();
  window.location.href = '/';
}

export default function checkActivity() {
  const lastActivity = localStorage.getItem('lastActivity');
  const now = moment();

  if (getToken()) {
    if (lastActivity && now.diff(moment(lastActivity), "days") >= inactivityDays) {
      logout();
      return;
    }

    localStorage.setItem('lastActivity', now);
  } else if (lastActivity) {
    localStorage.removeItem('lastActivity');
  }
}
