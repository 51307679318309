import React from 'react';
import objectPath from 'object-path';

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { StyledFormHelper } from '../../../../components/loginSignup/customFormHelper';
import * as colors from '../../../../theme/colors';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  error: {
    "&.MuiFormHelperText-root.Mui-error" :{
      color: colors.red500,
    },
  },
}));

const TextInput = ({ field, form: { errors, touched }, label, helperText, Icon, errorIsHtml, maxLength, maxValue, minValue, maxLengthInfo, maxLengthStartingFrom, stepValue, ...props }) => {
  const classes = useStyles();
  let error = errors[field.name];
  let isTouched = touched[field.name];

  if (field.name.indexOf('[') !== -1 || field.name.indexOf('.') !== -1) {
    const path = field.name.replace('[', '.').replace(']', '').split('.');
    error = objectPath.get(errors, path);
    isTouched = objectPath.get(touched, path);
  }

  const remainingLength = field.value && maxLength && maxLengthInfo ? maxLength - field.value.length : 0;
  const showRemainingLength = maxLengthStartingFrom ? maxLengthStartingFrom > remainingLength : true;

  return (
    <React.Fragment>
    <TextField
      {...props}
      name={field.name}
      onChange={field.onChange}
      error={!!helperText}
      onBlur={() => {
        if (field.name.indexOf('[') === -1 || field.name.indexOf('.') === -1) {
          touched[field.name] = true;
        }
      }}
      fullWidth
      label={label}
      helperText={error && isTouched ? (errorIsHtml ? "" : error) : helperText}
      variant={props.variant || "outlined"}
      value={field.value}
      FormHelperTextProps={{ classes: classes }}
      inputProps={{
        step: stepValue ? parseInt(stepValue): null,
        maxLength: maxLength,
        max: maxValue,
        min: minValue,
      }}
      InputProps={{
        classes: classes,
        placeholder: label,
        startAdornment: Icon ? (
          <InputAdornment position="start">
            <Icon />
          </InputAdornment>
        ) : null,
      }}
    />
    {maxLength && field.value && maxLengthInfo && showRemainingLength
    && <StyledFormHelper helperText={`${maxLengthInfo.replace("${value}", remainingLength)}`} isHtml={true} />}
    {error && isTouched &&
      (errorIsHtml
        ? <StyledFormHelper helperText={helperText} isError={true} isHtml={true} />
        : "")}
    </React.Fragment>
  );
}

export default TextInput;
