import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import * as colors from '../../theme/colors';

const StyledTabs = withStyles({
  indicator: {
    height: 3,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 70,
      width: '100%',
      backgroundColor: colors.lightThemeGreen,
    },
  },
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    marginTop: 10,
    textTransform: 'none',
    minWidth: 120,
    fontSize: 16,
    fontWeight: 600,
    marginRight: theme.spacing(4),

    '&$selected': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      border: 'none'
    },
    "&:focus": {
      outline: `2px dotted ${colors.grey400}`,
      outlineOffset: -5,
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props}/>);

export default ({ onChange, value }) => {
  return (
    <StyledTabs
      color="secondary"
      value={value}
      onChange={onChange}
    >
      <StyledTab label={<span aria-hidden="true">Log in</span>} aria-label="Log in" tabIndex={0} />
      <StyledTab label={<span aria-hidden="true">Sign up</span>} aria-label="Sign up" id="signUpTab" tabIndex={0} />
    </StyledTabs>
  );
};
