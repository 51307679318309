import axios from 'axios';

import { getAuthHeader } from '../tokenStorage';
import { axiosInstance } from './axiosInstance';


export const createUserActivity = async (payload) => {
  return axiosInstance.post('/blapi/userActivitiesInfo/create',
    payload,
    {
      headers: getAuthHeader()
    }
  );
};

export const getUserActivityInfoByProgramId = async (payload) => {
  const { programId, pageNumber, pageSize } = payload;
  return axiosInstance.post(`/blapi/userActivitiesInfo/getByProgram/${programId}`,
    { pageNumber, pageSize },
    {
      headers: getAuthHeader(),
    }
  );
};

export const getMyProgress = async (programId) => {
  return axiosInstance.get(`/blapi/userActivitiesInfo/myProgress/${programId}`, {
    headers: { ...getAuthHeader(), 'Cache-Control': 'no-cache' },
  });
};

export const getMyPartnershipProgress = async (cpOrganizationId) => {
  return axiosInstance.get(`/blapi/userActivitiesInfo/myPartnershipProgress/${cpOrganizationId}`, {
    headers: getAuthHeader(),
  });
};

export const getUserProgress = async (programId, userId) => {
  return axiosInstance.get(`/blapi/userActivitiesInfo/userProgress/${programId}/${userId}`, {
    headers: getAuthHeader(),
  });
};

export const removeMyActivityProgress = async (payload) => axios({
  method: 'DELETE',
  url: `${window._env_.REACT_APP_API_URL}/blapi/userActivitiesInfo/clearByActivity`,
  data: payload,
  headers: getAuthHeader()
});

export const getModalUserLogs = async ({programId}) => {
  return axiosInstance.get(`/blapi/userActivitiesInfo/modalUserLogs/${programId}`, {
    headers: getAuthHeader(),
  });
};

export const createModalUserLog = async (payload) => {
  return axiosInstance.post(`/blapi/userActivitiesInfo/createModalUserLog/${payload.programId}`,
    payload,
    {
      headers: getAuthHeader()
    }
  );
};