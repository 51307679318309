import { getAuthHeader } from '../tokenStorage';
import { axiosInstance } from './axiosInstance';

export const applyToFacilitate = async ({ programCode, data }) => {
  return axiosInstance.put(`/blapi/facilitatorApplication/applyToFacilitate/${programCode}`,
    data,
    {
      headers: getAuthHeader()
    }
  );
};
