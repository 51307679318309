import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { getTheme } from "../../../utils/colorTheme";
import { Container } from '@material-ui/core';
import './contentSection.scss';
import * as colors from '../../../theme/colors';
import quotesIcon from "../../../images/quotes-icon.svg";
import hljs from "highlight.js";
import 'highlight.js/styles/paraiso-dark.css';  // import your preferred style
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import TextCustomButton from "../../../components/text-button/text-button";
import * as tokenStorage from '../../../tokenStorage';

import { teal } from '@material-ui/core/colors';
import OpenTextAnswer from "../OpenTextAnswer/OpenTextAnswer";
import Quiz from './Quiz';
import FacilitatorNotes from './FacilitatorNotes';

import { createMarkup, checkIsClubsDomain } from "../../../utils/generic";

const useStyles = makeStyles(theme => ({
    alert: {
        marginTop: 28
    },
  card: {
    width: '100%',
    marginBottom: 30,
    marginTop: 15,
    fontSize: 18,
    color: colors.darkThemeBlueGray,
    "& p": {
      fontSize: 18,
      color: colors.darkThemeBlueGray,
    }
  },
  quizCard: {
    boxShadow: "none",
    backgroundColor: 'transparent',
    width: '100%',
    marginBottom: 30,
    marginTop: 15,
    fontSize: 18,
    color: colors.darkThemeBlueGray,
    "& p": {
      fontSize: 18,
      color: colors.darkThemeBlueGray,
    }
  },
  content: {
    display: 'flex',
    maxWidth: '100%',
    borderRadius: '0 0 4px 4px',
    padding: '20px 80px',

    [theme.breakpoints.down('md')]: {
      padding: '20px 50px'
    },

    [theme.breakpoints.down('xs')]: {
      padding: 20,
      flexDirection: 'column-reverse'
    },

    "& img": {
      maxWidth: '100%',
    },
    "& *": {
      maxWidth: '100%',
    },
    "& code": {
      backgroundColor: colors.white,
      border: "solid 1px",
      borderColor: colors.greyLight2,
      borderRadius: 4,
      fontFamily: 'monospace',
      fontWeight: 700,
      padding: '2px 3px 1px',
      "&.code-span": {
        overflowWrap: 'break-word',
        wordBreak: 'break-all',
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        color: colors.green950,
        backgroundColor: 'unset',
        padding: '2px 3px 1px',
      },
    }
  },
  quizContent: {
    display: 'flex',
    maxWidth: '100%',
    borderRadius: '0 0 4px 4px',
    padding: 0,

    [theme.breakpoints.down('xs')]: {
      padding: 20,
      flexDirection: 'column-reverse'
    },

    "& img": {
      maxWidth: '100%',
    },
    "& *": {
      maxWidth: '100%',
    },
    "& code": {
      backgroundColor: colors.white,
      border: "solid 1px",
      borderColor: colors.greyLight2,
      borderRadius: 4,
      fontFamily: 'monospace',
      fontWeight: 700,
      padding: '2px 3px 1px',
      "&.code-span": {
        overflowWrap: 'break-word',
        wordBreak: 'break-all',
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        color: colors.green950,
        backgroundColor: 'unset',
        padding: '2px 3px 1px',
      },
    }
  },
  contentSectionHtml: {
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: '100%',
    width: '100%',
    lineHeight: 1.8,
    '& ul': {
      "padding-left": 40,
    },
    '& li': {
      lineHeight: 1.8,
      paddingLeft: 10,
      "&::marker": {
        color: colors.darkThemeGreen,
      }
    },
    '& h1': {
      margin: 0,
    },
    '& h2': {
      margin: 0,
    },
    '& a': {
        color: props => props.accentColor,
        lineHeight: 1.8,
        fontWeight: 700
    },
    '& .figure-markdown': {
      display: 'table',
      margin: '7px 0 7px 10px',
    },
    '& .img-markdown': {
        cursor: 'pointer',
        maxHeight: '100%',
    },
    '& table': {
      border: '1px solid',
      borderColor: colors.lightThemeGreen,
      borderCollapse: 'collapse',
      color: 'grey',
      fontSize: '14px',
      '& th': {
        border: '1px solid',
        borderColor: colors.lightThemeGreen,
        backgroundColor: props => props.backColor,
        color: props => props.fontColor,
        fontSize: '18px',
        padding: 10,
        textAlign: 'center',
      },
      '& td': {
        border: '1px solid',
        borderColor: colors.lightThemeGreen,
        color: colors.dartThemeBlueGray,
        fontSize: '16px',
        lineHeight: 1.6,
        padding: 5,
        textAlign: 'center',
      }
    },
    '& figcaption': {
      textAlign: 'right',
    },
    '& >p': {
      color: colors.darkThemeBlueGray,
      fontSize: 18,
      lineHeight: 1.8,
    },
    '& h3': {
      fontSize: '20px',
      fontWeight: 'bold',
      margin: 0,
    },
    '& .blockquote-container': {
      position: 'relative',
      '& .quotes': {
        borderRadius: '50%',
        display: 'block',
        height: '40px',
        left: '17px',
        position: 'absolute',
        top: '-19px',
        width: '40px',
        zIndex: 1,
        backgroundColor: props => props.accentColor,
        "&::before": {
          background: `url(${quotesIcon}) no-repeat`,
          backgroundImage: `url(${quotesIcon}) no-repeat`,
          color: colors.white,
          content: "",
          height: 15,
          left: 'calc(50% - 7.5px)',
          position: 'absolute',
          top: 'calc(50% - 7.5px)',
          width: '15px'
        }
      }
    },
    '& pre > code': {
      overflowX: 'auto',
      display: 'flow-root !important'
    },
    '& details': {
      backgroundColor: '#ffffff',
      borderRadius: 5,
      border: props => `1px solid ${props.accentColor}`,
      margin: '10px 0',
      padding: 10,
      
      '&:focus-within': {
        border: '2px solid #000000',
        padding: 9
      },

      '& > summary': {
        outline: 'none',
        cursor: 'pointer',
        color: props => props.accentColor,
        fontWeight: 700
      }
    },
  },
  header: {
    minHeight: 80,
    color: props => props.fontColor,
    fontSize: 32,
    paddingLeft: 20,
    flex: 7,

    [theme.breakpoints.down('xs')]: {
      minHeight: 65
    }
  },
  quizHeader: {
    minHeight: 80,
    color: props => props.fontColor,
    fontSize: 32,
    paddingLeft: 20,
    flex: 7,
    maxWidth: "64%",

    [theme.breakpoints.down('xs')]: {
      minHeight: 65
    },

    [theme.breakpoints.down('md')]: {
        maxWidth: "100%",
    }
  },
  cardContent: {
    alignItems: 'center'
  },
  title: {
    fontSize: 28,
    flex: 7,
    [theme.breakpoints.down('xs')]: {
      fontSize: 22
    }
  },
  subheader: {
    color: props => props.fontColor,
    fontSize: 18,
    lineHeight: 1,
    padding: '5px 0'
  },
  action: {
    margin: 0
  },
  surveyContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px 100px'
  },
  survey: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: 20,
  },
  linkContainer: {
    marginTop: 20,
  },
  iframe: {
    minWidth: '100%',
  },
  facilitatorNoteBlock: {
    fontSize: 16,
    lineHeight: 1.5,
    textAlign: 'left',
    display: 'flex',
    borderTop: '2px solid #ffffff',
    padding: '10px 20px',
    color: props => props.fontColor,

    "& p": {
      lineHeight: 1.5,
      fontSize: 16,
      color: props => props.fontColor
    },
  },
  facilitatorOnly: {
    fontSize: 14,
    display: 'flex',

    "& svg": {
      fontSize: 16,
      marginRight: 5
    }
  },
  checkboxControl: {
    padding: '5px 9px',
  },
  checkBoxContainer: {
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
        color: colors.lightThemeGreen,
    }
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: colors.lightThemeGreen,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: colors.lightThemeGreen,
    },
    checkBoxLabel: {
      fontSize: 12,
    },
    radio: {
      fontSize: 12,
    },
  },
  answersTitle: {
    fontWeight: "bold",
    paddingLeft: 10,
    marginBottom: 10
  },
  quizContentOption: {
    fontWeight: "bold",
    fontSize: 14,
    marginBottom: 10
  },
  quizContentAnswerHead: {
    fontWeight: "bold",
    fontSize: 18,
  },
  quizContentAnswer: {
    border: "2px #e0e0e0 solid",
    padding: 5,
    fontSize: 16,
    marginTop: 10,
    marginBottom: 15,
    whiteSpace: "break-spaces",
    '& p': {
      margin: 0,
      padding: 0,
      lineHeight: "16px",
      color: teal[700],
      fontSize: 16,
      whiteSpace: "normal",
    },
    '& code': {
      border: "none",
    },
    '& pre': {
      margin: 0,
    }
  },
  quizLeft: {
    display: "inline-block",
    width: "64%",
    verticalAlign: "top",
    backgroundColor: "#fff",
    paddingLeft: 50,
    paddingBottom: 25,
    paddingRight: 50,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  quizRight: {
    display: "inline-block",
    width: "33%",
    verticalAlign: "top",
    backgroundColor: "#fff",
    marginLeft: "20px",
    paddingLeft: 10,
    paddingTop: 15,
    paddingBottom: 10,
    paddingRight: 10,

    [theme.breakpoints.down("md")]: {
      marginTop: 20,
      marginLeft: 0,
      width: "45%",
      position: "absolute",
      bottom: 0,
      right: 0,
      boxShadow: "rgb(67 214 185) 0px 0px 0px 2px inset",
      paddingLeft: 60,
    },

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      float: "none",
      position: "relative",
      boxShadow: "none",
      paddingLeft: 20,
    },
  },
  quizRightClps: {
    display: "inline-block",
    width: "33%",
    verticalAlign: "top",
    backgroundColor: "#fff",
    marginLeft: "20px",
    paddingLeft: 10,
    paddingTop: 15,
    paddingBottom: 10,
    paddingRight: 10,

    [theme.breakpoints.down("md")]: {
      marginTop: 20,
      marginLeft: 0,
      width: "45%",
      position: "absolute",
      bottom: 0,
      right: 0,
      boxShadow: "rgb(67 214 185) 0px 0px 0px 2px inset",
      paddingLeft: 60,
      marginRight: "-40%"
    },

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      float: "none",
      position: "relative",
      boxShadow: "none",
      paddingLeft: 20
    },
  },
  quizRightCollapse: {
    display: "none",

    [theme.breakpoints.down("md")]: {
      display: "block",
      position: "absolute",
      bottom: 0,
      left: 0,
      top: 0,
      backgroundColor: "blue",
      width: 50
    },

    [theme.breakpoints.down("sm")]: {
      display: "none",
    }
  },
  quizCollapseIcon: {
    fontSize: "50px",
    color: "#fff",
    height: "100%"
  },
  submitAnswerBtn: {
    minWidth: '150px',
    margin: 2,
    height: 42,
    fontSize: "16px",
    "& span": {
      color: "#003046",
    }
  }
}));

const manageSurveyUrl = (item, programCode) => {
  let url = item.surveyUrl;

  if (item.programId) {
    url = url.replace(/{clubCode}/gi, programCode);
  }
  const token = tokenStorage.getToken();

  const isClubInstance = checkIsClubsDomain();

  url = url.replace(/{isClubInstance}/gi, isClubInstance);

  return url.replace(/{token}/gi, token);
}

const Survey = ({ item, programCode }) => {
  const classes = useStyles();
  const surveyUrl = manageSurveyUrl(item, programCode);

  return (
    <Box className={classes.surveyContainer}>
      <Container className={classes.survey} xs={6} md={6}>
        <iframe src={surveyUrl} title={item.surveyName} height={item.height || 800} className={classes.iframe} />
        {item.ctaText && <Box className={classes.linkContainer}>
          <TextCustomButton
            mode={"primary"}
            isArrowWave={true}
            onClick={() => {
              window.open(surveyUrl, '_blank');
            }}
            label={item.ctaText}
            sel="new-window"
          />
          </Box>}
      </Container>
    </Box>
  );
};

const ContentSection = (
  {
    colorTheme,
    item,
    id,
    onVisibilityChange,
    sendActivityLog,
    chains,
    programCode,
    program,
    csItems,
    createOrUpdateCSItem,
    openTextAnswers,
    receivedAnswer,
    onNextClick,
    getMyAnswersByProgram,
    getAnswersForActivity,
    isFacilitator,
    push,
    user,
    setDisabledNext,
    studentView,
    showInfoMessage,
    isFinishedProject,
    setOpenQuestionAnswerUpdated,
    isLastContentSection,
    answeredQuestionsState,
    presentationMode,
    updateCurrentStateofQuizesAnswers,
    roleId,
    history,
  }) => {
  if (!colorTheme) colorTheme = getTheme(null);

  const classes = useStyles({ backColor: colorTheme.b, fontColor: colorTheme.f, accentColor: colorTheme.a });

  const selector = React.createRef();
  const [visible, setVisible] = React.useState(false);

  const { content, heading, subheading, facilitatorNote, onlyVisibleToFacilitators } = item;
  const hasFacilitatorNotes = !!facilitatorNote && facilitatorNote.length > 0;
  const isQuiz = item.__typename === "QuizQuestion";
  const isOpenTextAnswer = item.isOpenTextAnswer;

  const location = useLocation();

  React.useEffect(() => {
    updateCodeSyntaxHighlighting();

    // add event and styles to dynamic render checkbox
    const allInputs = document.querySelectorAll('input[type="checkbox"]');
    for (let input of allInputs) {
       const attr = input.getAttribute("data-attr");
       const id = input.getAttribute("id");
       const value = input.getAttribute("value");
       const parent = input.parentNode;
       const hasLabel = parent.querySelector("label") != null;
       if(!attr && !id && !value && !hasLabel) {
         input.setAttribute('checkbox', 'true');
         const inputContent = parent.innerHTML.split('checkbox="true">')[1]
         const checkbox = document.createElement("input");
         checkbox.type = "checkbox";
         const label = document.createElement('label');
         label.innerHTML = inputContent;
         checkbox.setAttribute('id', inputContent);
         checkbox.checked = input.checked;
         checkbox.disabled = input.disabled;
         checkbox.setAttribute('tabindex', '0');
         label.setAttribute("for", inputContent);
         parent.setAttribute("class", 'md-checkbox');

         parent.textContent = '';
         parent.appendChild(checkbox);
         parent.appendChild(label);
       }
    }

    const inputsWithDataAttr = document.querySelectorAll("[data-attr]");
    for (let input of inputsWithDataAttr) {
      const attr = input.getAttribute("data-attr");
      const parent = input.parentNode;
      const href = window.location.href;
      const attrSubstr = attr.substring(0,512);
      const item = csItems.filter(e => !!e.programId ? e.programId === program.id : true)
        .findLast(e => href.startsWith(e.path) && e.attribute === attrSubstr);
        input.setAttribute('checkbox', 'true');
        const checkbox = document.createElement("input");
        checkbox.setAttribute('id', attr);
        checkbox.type = "checkbox";
        const label = document.createElement('label');
        label.setAttribute("for", attr);
        label.innerHTML = input.parentNode.innerHTML.split('checkbox="true">')[1];
        parent.setAttribute("class", 'md-checkbox');

        // Adding attributes for acessibility to checkmark and label;
        label.setAttribute("id", `id=${attr}`);
        checkbox.setAttribute('tabindex', '0');
        checkbox.setAttribute('aria-labelledby', `id=${attr}`);

        if (item) {
          const event = new Event('input', { bubbles: false })
          checkbox.checked = item.isChecked;
          checkbox.dispatchEvent(event);
        }

        if (checkbox.getAttribute('listener') !== 'true') {
          checkbox.onkeypress = function() {
            // Acessibility: force click event on enter key event;
            checkbox.click();
          }
          checkbox.addEventListener("change", function (event) {
            const value = event.currentTarget.getAttribute("id");
            createOrUpdateCSItem({
              isChecked: event.currentTarget.checked,
              attribute: value,
              path: window.location.href,
              programId: program.id,
            })
          }, false)
        }
        checkbox.setAttribute('listener', 'true');
        parent.textContent = '';
        parent.appendChild(checkbox);
        parent.appendChild(label);
    }

    if (window.location.hash) {
      const csHash = item.heading ? item.heading.toLowerCase().split(' ').join('-') : "";
      if (`#${id}` === window.location.hash || `#${csHash}` === window.location.hash) {

        setTimeout(() => {
          var element = document.getElementById(id);
          var headerOffset = 140;
          var elementPosition = element?.getBoundingClientRect().top;
          var offsetPosition = elementPosition - headerOffset;

          window.scrollTo({
              top: offsetPosition,
              behavior: "smooth"
          });
        }, 1000);

      }
    }

    const iframes = document.querySelectorAll('iframe');
    iframes.forEach(el => {
      el.setAttribute('aria-label', program?.name);
    })
  }, [csItems]);

  React.useEffect(() => {
    if (sendActivityLog) {
      sendActivityLog();
    }
  }, [item])

  const updateCodeSyntaxHighlighting = () => {
    document.querySelectorAll("pre code").forEach(block => {
      hljs.highlightBlock(block);
    });
  };

  const [showNotes, setShowNotes] = React.useState(false);

  if (!item) return null;

  if (onlyVisibleToFacilitators && studentView) return null;

  window.addEventListener('scroll', function() {
    if (!selector || !selector.current || !onVisibilityChange) return;

    const visibility = selector.current.getBoundingClientRect().y - 145 > 0 ||
      selector.current.getBoundingClientRect().y - 250 + selector.current.clientHeight > 0;

    if (visibility !== visible) {
      setVisible(visibility);
      // onVisibilityChange(hash, visibility);
      onVisibilityChange(id, visibility);
    }
  });

  return (
    <Card
      className={isQuiz ? classes.quizCard : classes.card}
      id={id}
    >
    {isOpenTextAnswer 
        ? <OpenTextAnswer
          key={location.key}
          item={item}
          isFacilitator={isFacilitator}
          chains={chains}
          theme={colorTheme}
          program={program}
          openTextAnswers={openTextAnswers}
          receivedAnswer={receivedAnswer}
          getMyAnswersByProgram={getMyAnswersByProgram}
          getAnswersForActivity={getAnswersForActivity}
          push={push}
          user={user}
          onNextClick={onNextClick}
          setDisabledNext={setDisabledNext}
          studentView={studentView}
          showInfoMessage={showInfoMessage}
          isFinishedProject={isFinishedProject}
          setOpenQuestionAnswerUpdated={setOpenQuestionAnswerUpdated}
          isLastContentSection={isLastContentSection}
          answeredQuestionsState={answeredQuestionsState}
          presentationMode={presentationMode}
          roleId={roleId}
          history={history}
        />
          : <>
            {isQuiz &&
              <Quiz 
                key={location.key}
                item={item}
                chains={chains}
                theme={colorTheme}
                program={program}
                updateCurrentStateofQuizesAnswers={updateCurrentStateofQuizesAnswers}
                classes={classes}
              />}

            {(item.type.toLowerCase().indexOf('survey') !== -1 || item.surveyUrl) && <Survey item={item} programCode={programCode} />}
            {heading &&
              <CardHeader
                className={classes.header}
                classes={{ title: classes.title, content: classes.cardContent, subheader: classes.subheader, action: classes.action }}
                title={heading}
                style={{ backgroundColor: colorTheme.b }}
                subheader={(subheading || onlyVisibleToFacilitators) &&
                  <>
                    {subheading && <Box>{subheading}</Box>}
                    {onlyVisibleToFacilitators && <Box className={classes.facilitatorOnly}>
                      <VisibilityOffIcon /> Only visible to Facilitators/Teachers
                    </Box>}
                  </>
                }
                action={hasFacilitatorNotes && !studentView && isFacilitator &&
                  <FacilitatorNotes
                    toggleFunction={() => { setShowNotes(!showNotes) }}
                    showNotes={showNotes}
                    colorTheme={colorTheme} />
                }
              />}
            {hasFacilitatorNotes && showNotes && !studentView && isFacilitator &&
              <Box className={classes.facilitatorNoteBlock} style={{ backgroundColor: colorTheme.b }}>
                <Box dangerouslySetInnerHTML={createMarkup(facilitatorNote)} />
              </Box>}

            <CardContent className={classes.content} ref={selector}>
              <Box className={`content-section ${classes.contentSectionHtml}`} dangerouslySetInnerHTML={createMarkup(content)} />
            </CardContent>
          </>}
    </Card>
  );
};

export default ContentSection;
