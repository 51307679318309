import React, { useEffect, useState } from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { Box, Typography } from "@material-ui/core";
import * as colors from "../../../theme/colors";
import TextCustomButton from "../../../components/text-button/text-button";
import { SubscriptionModal } from "../../../components/modals/eventScheduler/subscriptionModal";
import { getCalendarUrl } from "../../../api/calendars";

const useStyles = makeStyles({
  root: {
    border: "1px solid #7A7A78",
    marginTop: 30,
  },
  title: {
    paddingLeft: 10,
    marginTop: 5,
    color: colors.darkThemeBlueGray,
    fontSize: 18,
    fontWeight: "bold",
  },
  itemWrapper: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: 270,
    lineHeight: 1.3,
    whiteSpace: "nowrap",
  },
  textWrapper: {
    margin: 10,
  },
  btnWrapper: {
    margin: "auto",
    display: "flex",
    justifyContent: "center",
  },
  checkPrograms: {
    fontSize: 12,
    textDecoration: 'underline'
  }
});

const GreenCheckbox = withStyles({
  root: {
    color: (props) => props.color,
    "&$checked": {
      color: (props) => props.color,
    },
  },
  checked: {},
})((props) => <Checkbox {...props} />);

const CalendarPicker = ({
  filters,
  programs,
  programColors,
  onChange,
  onCheckAllFilters,
  calendarLabels,
  isLoading,
}) => {
  const classes = useStyles();

  const [openSubscription, setOpenSubscription] = useState(false);
  const [allChecked, setAllChecked] = useState(null);
  const [link, setLink] = useState("");
  const multiIcon =
    calendarLabels && calendarLabels.multipleProgramEventIcon
      ? calendarLabels.multipleProgramEventIcon
      : {};

  useEffect(() => {
    if (!programs || !filters) return;
    setAllChecked(filters["showPublic"] || filters["programIds"].length > 0);
  }, [filters, programs]);

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>Calendars
      <TextCustomButton
        className={classes.checkPrograms}
        onClick={() => onCheckAllFilters(!allChecked)}
        label={allChecked ? "Uncheck all" : "Check all"}
      /></Typography>
      <Box>
        <Box className={classes.itemWrapper}>
          <GreenCheckbox
            checked={filters["showPublic"]}
            color={colors.black}
            disabled={isLoading}
            onChange={(event) => {
              onChange("showPublic", event.target.checked);
            }}
          />
          <Typography className={classes.label}>Public Events</Typography>
        </Box>
        {programs.map((e, index) => {
          const color =
            index >= programColors.length
              ? programColors[index % programColors.length]
              : programColors[index];
          const isChecked = filters["programIds"].includes(e.id);
          return (
            <Box className={classes.itemWrapper} key={index}>
              <GreenCheckbox
                checked={isChecked}
                color={color}
                disabled={isLoading}
                onChange={() => {
                  onChange("programIds", e.id);
                }}
              />
              <Typography className={classes.label}>{e.name}</Typography>
            </Box>
          );
        })}
        <Box>
          <Box className={classes.textWrapper}>
            Sync your HQ calendar Feed with your preferred Calendar App to
            receive notifications
          </Box>
          <Box className={classes.btnWrapper}>
            <TextCustomButton
              onClick={() => {
                setOpenSubscription(true);
                getCalendarUrl()
                  .then((data) => {
                    setLink(
                      `${window.origin}/blapi/calendar/getCalendar/${data.data}`
                    );
                  })
                  .catch(() => {
                    console.log("error getting calendar url");
                  });
              }}
              label={"Subscribe"}
            />
            <SubscriptionModal
              link={link}
              isOpen={openSubscription}
              onCloseModal={() => setOpenSubscription(false)}
              calendarLabels={calendarLabels}
            />
          </Box>
          <Box className={classes.textWrapper}>
            Note: Events with the{" "}
            <img
              className={classes.multiIcon}
              alt={multiIcon.alt}
              src={multiIcon.src}
            />{" "}
            indicator are for multiple Programs.
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CalendarPicker;
