import React, { useState } from 'react';
import debounce from "debounce";

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { getTheme } from "../../../utils/colorTheme";
import * as colors from '../../../theme/colors';

import { formatEstimatedMinutes } from "../../../utils/generic";

import CardNode from "./index";
import { Filters, EmptyFilterSection } from "../Filters";

import { applyFilters, getFiltersData } from "../curriculumHelper";
import SkeletonCard from "../SkeletonCard";

const styles = makeStyles({
  root: {
    display: 'grid',
  },
  grid: {
    marginTop: 15,
  },
  headerBox: {
    width: '100%',
    padding: '12px 12px 0px 12px',
    margin: '15px 0px -20px 0px',
    justifyContent: 'space-between',
    display: 'flex',
  },
  viewMoreBtn: {
    height: 42,
    color: colors.darkThemeGreen,
  },
  labelText: {
    fontSize: 24,
    fontWeight: 'bold',
    color: colors.darkThemeBlueGray,
    lineHeight: '32px',
    flexGrow: 1,
    verticalAlign: 'middle',
    textTransform: 'uppercase',
  },
  filters: {
    margin: 10,
  }
});

const searchProperties = ['shortDescription', 'name', 'tags'];
const skeletonsNumber = 6;

const Collection = (props) => {
  const {
    item,
    isNodesExist,
    defaultActivityIcon,
    onNodeClick,
    onRedoClick,
    onResumeClick,
    onStartClick,
    userActivitiesInfo,
    isFacilitator,
    communityPartnershipLink,
    labelBackground,
    labelFontColor,
    programRoleLabel,
    clearProgress,
    classes,
    isAllNodesFacilitatorsOnly,
    curriculumLabels,
    studentView,
    openTextAnswers,
    programId,
  } = props;

  const nodeTheme = null;
  const defaultFilter = {
    setType: [], estimatedTimeInMinutes: [], easeOfFacilitation: [], searchKey: "",
  };
  const [filters, setFilters] = useState(defaultFilter);
  const [items, setItems] = useState(defaultFilter);
  const [currentNodes, setCurrentNodes] = useState([]);
  const [anyFilterSelected, setAnyFilterSelected] = useState(false);

  const cardTheme = getTheme(nodeTheme);
  const cardClasses = styles({ backColor: cardTheme.b, fontColor: cardTheme.f, accentColor: cardTheme.a });

  const searchInputChangeDebounced = debounce((searchKey) => {
    searchKey = !!searchKey.trim ? searchKey.trim() : "";
    setFilters({ ...filters, searchKey });
  }, 1000);

  const clearAllFilters = () => {
    setFilters(defaultFilter);
  };

  React.useEffect(() => {
    const keys = Object.getOwnPropertyNames(filters);
    const isAnyFilterSelected = !!keys.filter(key => !!filters[key].length).length;

    const filteredChildNodes = applyFilters(item, filters, searchProperties);
    setCurrentNodes(filteredChildNodes);

    if (anyFilterSelected !== isAnyFilterSelected) {
      setAnyFilterSelected(isAnyFilterSelected);
    }

    const filtersData = getFiltersData(filteredChildNodes, searchProperties, isFacilitator, formatEstimatedMinutes, studentView);

    let newFilters = { ...filtersData };
    if (isAnyFilterSelected) {
      keys.forEach(key => {
        if (typeof items[key] !== 'object') {
          newFilters[key] = items[key];
          return;
        }
        newFilters[key] = items[key].map(item => {
          let disabled = !filtersData[key].find(e => e.label === item.label);
          return { ...item, disabled };
        })
      });
    }

    setItems({
      ...defaultFilter,
      ...newFilters,
    });
  }, [filters, item]);

  const handleFilterChange = (name, value, resetFilters) => () => {
    const newFilters = { ...filters };
    const isItemExist = newFilters[name].includes(value);

    if (isItemExist) {
      newFilters[name] = newFilters[name].filter((e) => e != value);
    } else {
      newFilters[name].push(value);
    }

    if (resetFilters && !newFilters[name].length) {
      resetFilters.map((e) => {
        newFilters[e] = defaultFilter[e];
      });
    }

    setFilters(newFilters);
  };

  const skeletonCards = [...Array(skeletonsNumber)].map((e, i) => <SkeletonCard key={i} classes={classes} style={{ height: "100%", display: "flex" }} />);

  return (
    <Box className={cardClasses.root}>
      <Filters
        className={cardClasses.filters}
        items={items}
        filters={filters}
        handleFilterChange={handleFilterChange}
        onSearchInput={searchInputChangeDebounced}
        onReset={() => searchInputChangeDebounced("")}
        studentView={studentView}
        clearAllFilters={clearAllFilters}
      />
      {anyFilterSelected && !currentNodes.length 
        ? <EmptyFilterSection curriculumLabels={curriculumLabels} />
        : <Grid container spacing={3} className={classes.grid}>
          {!!item && isNodesExist && currentNodes.map((node) => {
            return (
              <CardNode
                key={`key-${node.id}`}
                classes={classes}
                clearProgress={clearProgress}
                isFacilitator={isFacilitator}
                item={item}
                node={node}
                onNodeClick={onNodeClick}
                onRedoClick={onRedoClick}
                onResumeClick={onResumeClick}
                onStartClick={onStartClick}
                defaultActivityIcon={defaultActivityIcon}
                userActivitiesInfo={userActivitiesInfo}
                communityPartnershipLink={communityPartnershipLink}
                labelBackground={labelBackground}
                labelFontColor={labelFontColor}
                programRoleLabel={programRoleLabel}
                isAllNodesFacilitatorsOnly={isAllNodesFacilitatorsOnly}
                openTextAnswers={openTextAnswers}
                programId={programId}
              />
            );
          })}
        </Grid>}
      {!isNodesExist && skeletonCards}
    </Box>
  );
};

export default Collection;