import React, { useState, useEffect } from "react";
import { Modal } from "../../components/modals/modal";

import { ChildRegistering } from "./childRegistering";
import { PageLeaveModal } from '../../components/modals/pageLeaveWarning';

export const ChildRegisteringModal = ({
  setRegisterChildModal,
  currentUserUpdated,
  user,
  getEnrollmentFormData,
  joinClub,
  createChild,
  createTrackingLog,
  isCreatingChild,
  getEnrollmentData,
  enrolledChildError,
  childRegisteringContent,
  defaultProgramId,
  allProgramTypes,
}) => {
  const [openConfirm, setOpenConfirm] = useState(null);
  
  useEffect(() => {
    getEnrollmentFormData();
    getEnrollmentData({});
  }, [])

  const onClose = () => {
    setOpenConfirm(true);
    currentUserUpdated({ isParent: false })
  };

  return (
    <>
      <Modal isOpen={true} onClose={onClose}>
        <ChildRegistering
          childRegisteringContent={childRegisteringContent}
          user={user}
          joinClub={joinClub}
          createTrackingLog={createTrackingLog}
          createChild={createChild}
          isCreatingChild={isCreatingChild}
          enrolledChildError={enrolledChildError}
          defaultProgramId={defaultProgramId}
          allProgramTypes={allProgramTypes}
        />
      </Modal>
      <PageLeaveModal
        open={!!openConfirm}
        onCancel={() => {
          setOpenConfirm(false);
        }}
        onConfirm={() => {
          setOpenConfirm(false);
          setRegisterChildModal(false);
          currentUserUpdated({ isParent: false })
        }}
      />
    </>
  );
};
