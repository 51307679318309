import { createReducer } from "redux-act";

import * as a from "../actions/userActivitiesInfo";

const getDefaultState = () => ({
  isLoadingProgress: true,
  userActivitiesInfo: [],
  myProgress: [],
  isLoadingUserActivitiesInfo: false,
  userProgressByProgram: null,
  csItems: [],
  modalUserLogs: null,
  isLoadingAdditionalProgress: false,
});

export default () =>
  createReducer(
    {
      [a.getUserActivitesByProgramCode]: (state) => ({
        ...state,
        userActivitiesInfo: [],
        isLoadingProgress: true,
      }),
      [a.getUserProgramLogs]: (state) => ({
        ...state,
        userActivitiesInfo: [],
        isLoadingProgress: true,
      }),
      [a.receivedUserActivityInfo]: (state, payload) => {
        const { code, userActivitiesInfo } = payload;

        const newUserActivitiesInfo = {
          ...state.userActivitiesInfo,
          [code]: userActivitiesInfo,
        };

        return {
          ...state,
          userActivitiesInfo: newUserActivitiesInfo,
          isLoadingProgress: false,
        };
      },
      [a.getAdditionalUserActivitesByProgramCode]: (state) => ({
        ...state,
        isLoadingAdditionalProgress: true,
      }),
      [a.receivedAdditionalUserActivityInfo]: (state, payload) => {
        const { code, userActivitiesInfo } = payload;

        const newUserActivitiesInfo = {
          ...state.userActivitiesInfo,
          [code]: [...state.userActivitiesInfo[code], ...userActivitiesInfo],
        };

        return {
          ...state,
          userActivitiesInfo: newUserActivitiesInfo,
          isLoadingAdditionalProgress: false,
        };
      },
      [a.receivedUserProgramLogs]: (state, payload) => {
        const { modalLogs, csStatuses, data } = payload;
        
        const progressItems = !!data && Array.isArray(data)
          ? data
          : [];
        return {
          ...state,
          myProgress: progressItems,
          modalUserLogs: modalLogs,
          csItems: csStatuses,
        }
      },
      [a.receivedMyActivitiesInfo]: (state, payload) => {
        const progressItems = !!payload.data && Array.isArray(payload.data)
          ? payload.data
          : [];
        return {
          ...state,
          myProgress: progressItems,
        }
      },
      [a.addUserActivitiesInfoItem]: (state, payload) => {
        return {
          ...state,
          myProgress: [...state.myProgress, payload],
        };
      },
      [a.getUserActivitiesByProgram]: (state) => ({
        ...state,
        userProgressByProgram: null,
        isLoadingUserActivitiesInfo: true,
      }),
      [a.receivedUserActivitiesByProgram]: (state, payload) => {
        const { data: userProgressByProgram } = payload;

        if (userProgressByProgram && Array.isArray(userProgressByProgram)) {
          return {
            ...state,
            userProgressByProgram,
            isLoadingUserActivitiesInfo: false,
          };
        }

        return state;
      },
      [a.receivedCSItemStatuses]: (state, payload) => {
        return {
          ...state,
          csItems: payload,
        };
      },
      [a.receivedCSItem]: (state, payload) => {
        const newCsItems = state.csItems;
        const index = newCsItems.findIndex((x) => x.id === payload.id);

        if (index === -1) {
          newCsItems.push(payload);
        } else {
          newCsItems[index] = payload;
        }

        return {
          ...state,
          csItems: newCsItems,
        };
      },
      [a.receivedModalUserLogs]: (state, payload) => {
        return {
          ...state,
          modalUserLogs: payload,
        };
      },
      [a.receivedCreatedModaUserLog]: (state, payload) => {
        return {
          ...state,
          modalUserLogs: [...state.modalUserLogs, payload],
        };
      }
    },
    getDefaultState()
  );
