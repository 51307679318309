import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import * as colors from "../../../theme/colors";

import CustomButton from "../../../components/customButton/customButton";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  root: {
    padding: "25px 25px 10px 25px",
    border: `1px solid ${colors.lightThemeGreen}`,
    marginTop: 10,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 10,
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "center",
    padding: 10,
  },
  primaryButton: {
    minWidth: 150,
  },
}));

const IncentivesReqs = ({ labels }) => {
  const classes = useStyles();

  const { clubIncentiveCardsContent } = labels;
  if (!clubIncentiveCardsContent) return;

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{clubIncentiveCardsContent.membersCardIncentivesTitle}</Typography>
      <Typography className={classes.text}>{clubIncentiveCardsContent.membersCardIncentivesText}</Typography>
        <Box className={classes.buttonGroup}>
          <CustomButton
            mode="primary"
            label={clubIncentiveCardsContent.membersCardLeanMoreButtonText}
            onClick={() => {
              window.open(clubIncentiveCardsContent.membersCardLeanMoreButtonLink,  "_blank");
            }}
            className={classes.primaryButton}
          />
        </Box>
    </Box>
  );
};

export default IncentivesReqs;
