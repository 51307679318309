import { ColorTheme } from "./colorTheme";

export const defaultProgramRoleNames = {
  1: "Facilitator",
  2: "Member",
  3: "Viewer"
};

export const defaultProgramColors = {
  fontColor: ColorTheme.blue2.f,
  backgroundColor: ColorTheme.blue2.b,
};

export const programTypes = {
  CLUB: {
    fontColor: ColorTheme.blue2.f,
    backgroundColor: ColorTheme.blue2.b,
    programRoles: {
      1: "Facilitator",
      2: "Member",
      3: "Viewer"
    },
  },
  SIP: {
    fontColor: ColorTheme.orange3.f,
    backgroundColor: ColorTheme.orange3.b,
    programRoles: {
      1: "Staff",
      2: "Student",
      3: "Partner"
    },
  },
  COLLEGE_LOOP: {
    fontColor: ColorTheme.yellow3.f,
    backgroundColor: ColorTheme.yellow3.b,
    programRoles: {
      1: "President",
      2: "Member",
      3: "Viewer"
    },
  },
  SPP: {
    fontColor: ColorTheme.purple1.f,
    backgroundColor: ColorTheme.purple1.b,
    programRoles: {
      1: "Advisor",
      2: "Member",
      3: "Viewer"
    },
  }
};
