import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import * as colors from '../../../theme/colors';
import { ColorTheme } from '../../../utils/colorTheme';

const StyledTabs = withStyles(theme => ({
  indicator: {
    height: 3,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 70,
      width: '100%',
      backgroundColor: colors.lightThemeGreen,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 3,
    },
  },
}))(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    marginTop: 10,
    textTransform: 'none',
    minWidth: 120,
    fontSize: 16,
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      minWidth: 70,
      paddingRight: 5,
    },

    '&$selected': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      border: 'none'
    },

    "&:hover": {
      color: colors.darkThemeGreen,
    },

    "&:focus": {
      outline: `2px dotted ${ColorTheme.black2.b}`,
      outlineOffset: -2
    }
  },
  selected: {},
}))(props => <Tab disableRipple tabIndex="0" {...props} />);

export default ({ onChange, value, showProjectTab, showCertificateTab, showAnswers }) => {
  return (
    <StyledTabs
      color="secondary"
      value={value}
      onChange={onChange}
    >
      <StyledTab value='Activities' label='Activities' />
      {showAnswers && <StyledTab value="Answers" label='Answers'/>}
      {showProjectTab && <StyledTab value='Projects' label='Projects' />}
      {showCertificateTab && <StyledTab value='Certificates' label='Certificates' />}
    </StyledTabs>
  );
};