import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

// import { changePassword } from '../actions/changePassword';
// import { authorize } from '../actions/auth';
import { changeResetPassword } from '../api/index';

export function* changePassword({ payload }) {
  try {
    // const { data } = yield call(changeResetPassword, payload);
    // yield put(authorize({ login: data.user.email, password: payload.password, accessToken: payload.accessToken }));
    yield call(changeResetPassword, payload);
    yield put(push('/login'));
  } catch (e) {
    // TODO: throw generic error
    console.log('TODO: handle error here');
    console.log(e);
  }
}
