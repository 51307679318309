import React, { useEffect } from 'react';
import { Field } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
} from "@material-ui/core/";
import TextInput from '../../../components/TextInput';
import * as colors from "../../../theme/colors";
import { alphanumericRegex } from '../../../utils/generic';
import { ProgramInfoDetails } from './programInfoDetails';
import { enrollmentCreatingChildTrackingEvent } from "../../../containers/JoinProgram/constants";
import { programValidationMsgs, validateProgram } from "../../../utils/programHelper";
import { getProgramByCode } from '../../../api/programs';

const useStyles = makeStyles((theme) => ({
  title: {
    textWrap: "nowrap",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.4,
    color: colors.darkBlue100,
    [theme.breakpoints.up("md")]: {
      width: 360,
    },
    textWrap: "wrap",
    textAlign: "center",
    margin: "0 auto",
  },
  programInfoWrapper: {
    minHeight: 140,
    margin: "20px 20px 0 20px",
  },
  programCode: {
    marginTop: 20,
  }
}));

export const ProgramCode = ({
  setPage,
  programCode,
  type,
  footer,
  createTrackingLog,
  defaultProgramId,
  setProgramToEnroll,
  programToEnroll,
  parentCountryId,
  showResultStep,
  allProgramTypes,
}) => {
  const classes = useStyles();
  
  useEffect(() => {
    if (programCode && showResultStep && programToEnroll && Object.keys(programToEnroll).length) {
      setPage(2);
    }
  }, [programToEnroll]);

  useEffect(() => {
    return () => {
      createTrackingLog({
        event: enrollmentCreatingChildTrackingEvent.CHILD_ENROLLMENT_STEP_1_LEAVE,
        data: JSON.stringify(programCode),
      });
    };
  }, []);

  const validateCode = (value) => {
    setProgramToEnroll(null);

    if (!value) {
      return;
    }

    if (!alphanumericRegex.test(value)) {
      return programValidationMsgs.doesntExist;
    }

    const result = getProgramByCode({ programCode: value, withFacilitators: true }).then((e) => {
      const { data: program } = e;

      const errorMsg = validateProgram(null, null, program, defaultProgramId, false, true, allProgramTypes);
      if (errorMsg) {
        return errorMsg;
      }

      if (!!program && !!program.countryId &&
          parseInt(program.countryId, 10) !== parentCountryId) {
        return programValidationMsgs.countryNotValid;
      }

      setProgramToEnroll(program);
    });
    return result;
  };

  return (
    <>
      {(programToEnroll && showResultStep) ? (
        <ProgramInfoDetails
          program={programToEnroll}
          type={type}
          footer={footer}
          createTrackingLog={createTrackingLog}
        />
      ) : (
        <>
          <Box className={classes.programInfoWrapper}>
            <Typography className={classes.title}>Add your child to their program by using the code provided by their facilitator.</Typography>
            <Field
              className={classes.programCode}
              name="programCode"
              component={TextInput}
              label="Program Code"
              validate={validateCode}
              InputLabelProps={{ shrink: true }}
              required
            />
          </Box>
          {footer}
        </>
      )}
    </>
  );
};
