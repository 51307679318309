import cta from './Cta';
import * as FormFields from './FormFields';
import messaging from './Messaging';

export const ParentsInfo = `
  name,
  heading,
  subheading,
  oneParentsInfoLabel {
    ${messaging}
  }
  oneParentFields {
    ${FormFields.anyFormField}
  }
  otherParentFields {
    ${FormFields.anyFormField}
  }
  addAnotherParentCta {
    ${cta}
  },
  instructionalText {
    ${messaging}
  }
  cancelCta {
    ${cta}
  }
  continueCta {
    ${cta}
  }
  deleteCta {
    ${cta}
  }`;

export default ParentsInfo;
