import React, { useEffect, useState } from "react";
import {
  Box,
  CardContent,
  Card,
  Grid,
  CardActions,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";

import * as colors from "../../../theme/colors";
// import SkeletonCards from "../../pages/project-gallery/skeletonCards/index";
import PageTitle from "../../../components/page-title/pageTitle";
import * as certificatesApi from "../../../api/certificates";
import Tabs from "./../Tabs/index";
import Button from "@material-ui/core/Button";
import GetAppIcon from '@material-ui/icons/GetApp';
import AddCertificateLinkedinButton from "../../../components/add-certificate-linkedin/AddCertificateLinkedinButton";
import { downloadFile } from "../../../utils/downloadFile";
import { CertificateCard } from "../../../pages/public-profile/certificateCard/certificateCard";
import { userIs13orOlder } from "../../../utils/userUtils";

const useStyles = makeStyles((theme) => ({
  card: {
    borderBottom: "6px solid $grey-dark",
    boxSizing: "border-box",
    cursor: "pointer",
    margin: 12,
    padding: 24,
    // width: "80%",
    // height: "60%",
    transition: "transform 150ms linear",
    height: "fit-content",
    width: "-webkit-fill-available",
    "&:hover": {
      transform: "scale(1.05, 1.05)",
    },
    "&:focus": {
      outline: "none",
      transform: "scale(1.05, 1.05)",
    },
  },
  cardСover: {
    height: 200,
  },
  img: {
    width: "80%",
    height: "60%",
  },
  cardTitle: {
    overflow: "hidden",
    fontWeight: 700,
    fontSize: 16,
    height: 48,
    boxOrient: 'vertical',
    lineClamp: 2,
    display: '-webkit-box'
  },
  cardContent: {
    padding: "16px 0 0 !important",
    display: "flex",
    justifyContent: 'center'
  },
  projectLink: {
    textDecoration: "none",
  },
  contentBox: {
    width: "95%",
    margin: "auto",
  },
  header: {
    margin: "15px auto",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  tabsSection: {
    margin: '-10px auto 15px -25px',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: "block",
      marginBottom: '0px',
      marginLeft: '-10px',
    },
  },
  viewAllBtn: {
    display: "flex",
    justifyContent: "flex-end",
    fontWeight: 700,
    fontSize: 14,
    color: colors.teal2,
    marginLeft: 'auto'
  },
  tag: {
    paddingLeft: 10,
  },
  chip: {
    fontSize: 12,
    fontWeight: 500,
    color: colors.grayTheme,
    fontFamily: "'Roboto Mono',monospace",
  },
  cardActions: {
    justifyContent: "flex-end",
  },
}));


const SingleCertificateCard = ({ user, item, classes, labels }) => {
  const [imgUrl, setImgUrl] = useState(null);

  useEffect(() => {
    fetchData();
  }, [item]);

  async function fetchData() {
    const url = await getImageUrl(item);
    setImgUrl(url);
  }

  const getImageUrl = async (item) => {
    const { data: { url } } = await certificatesApi.getSignedUrlCerfificate({ title: item.title, format: "png" });
    return url;
  };

  const download = async (item) => {
    await certificatesApi.downloadCertificate(item)
        .then((response) => {
          downloadFile(response.data, item.title, "application/pdf");
        });
  };

  const programRole = user?.roles.find(e => e.programId === item.programId);
  const getCertificateName = () => {
    if (!!item.collection) {
      return item.courseName;
    } else {
      return !!programRole ? programRole.program?.name : "";
    }
  }

  return (
    <Grid key={item.id} item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Card
        className={classes.card}
        tabIndex="0"
      >
        <CardContent className={classes.cardContent}>
          <img src={imgUrl} className={classes.img} alt={!!labels && labels.certificateImageDescription} />
        </CardContent>
        <CardActions className={classes.cardActions} disableSpacing>
          {user && userIs13orOlder(user) && <AddCertificateLinkedinButton item={item} name={getCertificateName()}/>}
          <Button onClick={() => download(item)} sel="download-certificate"><GetAppIcon /></Button>
        </CardActions>
      </Card>
    </Grid>
  );
}

const Certificates = ({
  user,
  certificates,
  userId,
  currentTab,
  setCurrentTab,
  isLoading,
  push,
  isUserProjects,
  showAnswers,
  labels,
}) => {
  const classes = useStyles();
  const userCertificates = certificates
    && !!certificates.userCertificates[userId]
    ? certificates.userCertificates[userId]
    : [];

  return (
    <Box className={classes.contentBox}>
      <Box className={classes.header}>
        <PageTitle title="Progress" isLoading={isLoading} />
      </Box>
      <div className={classes.tabsSection}>
        <Tabs
          value={currentTab}
          showCertificateTab={!!userCertificates.length}
          showProjectTab={isUserProjects}
          showAnswers={showAnswers}
          onChange={(evt, newValue) => {
            setCurrentTab(newValue);
          }}
        />
        <Button
          className={classes.viewAllBtn}
          onClick={() => {
            push(`/profile/${userId}`);
          }}
        >
          VIEW ALL CERTIFICATES
        </Button>
      </div>
      <Grid container className={classes.projectsTable}>
        {userCertificates.length === 1
          ? <SingleCertificateCard item={userCertificates[0]} user={user} classes={classes} labels={labels} />
          :  userCertificates.map(e => {
              return (<CertificateCard className="certificate-item" userId={userId} item={e} push={push} currentUser={user} />)
            })
        }
      </Grid>
    </Box>
  );
};

export default Certificates;
