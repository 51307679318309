import React from "react";
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Formik, Form } from "formik";
import Editor from '../../../components/textEditor';
import { importEditor, createEmptyEditor, exportEditor } from '../../../components/textEditor/utils';
import ImageIcon from "@material-ui/icons/Image";
import * as colors from "../../../theme/colors";
import CustomButton from "../../../components/customButton/customButton";
import { StyledFormHelper } from '../../../components/loginSignup/customFormHelper';

import MessageImage from "./MessageImage";
import FileUpload from "./FileUpload";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  card: {
    maxWidth: 755,
    margin: "15px auto 10px",
  },
  buttonGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none",

    [theme.breakpoints.down("xs")]: {
      display: "inline-block",
    },
  },
  imagePreview: {
    display: "block",
    position: "relative",
    marginTop: "15px",
    "&:hover": {
      opacity: "1",
    },
  },
  cardImage: {
    cursor: "pointer",
    color: colors.darkThemeBlue,
    fontWeight: "bold",
    fontSize: 14,
    textTransform: "uppercase",
    display: "flex",
  },
  emailAllBtn: {
    marginLeft: 30,
    "& .MuiTypography-body1": {
      fontWeight: "bold",
      fontSize: 14,
      textTransform: "uppercase",
      color: colors.darkThemeBlue,
    },
  },
  messageArea: {
    'resize': 'none',
    width: '100%',
    fontSize: 16,
  },
  showImage: {
    display: "block",
    margin: "0 auto",
  },
  removeImage: {
    backgroundColor: "#f0f0f0",
    borderRadius: 20,
    bottom: 20,
    boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
    color: "black",
    cursor: "pointer",
    fontWeight: "bold",
    height: 36,
    left: "30%",
    lineHeight: "36px",
    opacity: 0,
    padding: "0 20px",
    position: "absolute",
    right: "30%",
    textAlign: "center",
    transition: "opacity 130ms",
    "&:hover": {
      opacity: "1",
    },
  },
  imageLabel: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      "& svg": {
        marginRight: 5
      }
    }
  },
  button: {
    height: 42,
    border: "none",
    boxShadow: "none",
  },
  submitButton: {
    height: 42,
    width: 100,
    marginLeft: 5,
  },
  supportLabels: {
    display: "flex",
    justifyContent: "space-between",
    width: "calc(100% - 200px)",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      flexDirection: "column",
      marginBottom: 10
    },
  },
  checkboxContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: "11px 5px 11px 11px"
    },
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  editCard: {
    boxShadow: "none",
    marginTop: "-20px",
  },
  fileError: {
    left: 0,
    right: 'unset',
    bottom: '-15px'
  },
  uploadImageLabel: {
    color: '#003046'
  }
}));
const maxMsgLength = 2000;

const AddAnnouncementCard = ({
  message,
  onClose,
  onSave,
  isComment,
  showEmailAll,
  isEditMode,
  setDataChanged,
  isDataChanged,
  setEscapeConfirmDialog,
  funcRef,
}) => {
  const classes = useStyles();

  const [uploadedFile, setUploadedFile] = React.useState(
    message ? message.fileName : null
  );
  const [emailToAll, setEmailToAll] = React.useState(false);
  const [emailToFacilitators, setEmailToFacilitators] = React.useState(false);

  const removeImage = () => {
    setUploadedFile(null);
  };

  const validateMessage = (value) => {
    if (!value || value.length == 0) {
      return;
    }

    if (value.length > maxMsgLength) {
      return 'Message should contain less 2000 characters';
    }

    return null;
  };


  return (
    <Card className={isEditMode ? clsx(classes.card, classes.editCard) : classes.card}>
      <CardContent>
        <Formik
          initialValues={{
            body: message ? importEditor(message.body) : createEmptyEditor(),
            fileName: "",
          }}
          validateOnChange={true}
          validateOnBlur={false}
          validate={(values) => {
            let errors = {};
            if (!values.body || !values.body.getCurrentContent().getPlainText().trim().length && !uploadedFile) {
              errors.body = "Post should have at least text or image";
            }
            const updatedBody = values?.body?.getCurrentContent().getPlainText();
            const pureOriginBody = message?.body?.replace(/<\/?[^>]+(>|$)/g, "");
            setDataChanged((!!updatedBody && !!pureOriginBody && updatedBody !== pureOriginBody) ||
            (!!updatedBody && !pureOriginBody )  ||
            (!!updatedBody?.length && typeof pureOriginBody === 'undefined' ) ||
            (!!message?.body && !!values.body && message?.body !== exportEditor(values.body)));
            return errors;
          }}
          onSubmit={(values) => {
            const editorToHtml = exportEditor(values.body);
            const post = {
              ...message,
              body: editorToHtml,
              fileName: uploadedFile,
              isComment,
              emailToAll,
              emailToFacilitators,
            };

            onSave(post);
          }}
        >
          {({ errors, touched, values }) => (<Form>
            <Editor
              name={`body`}
              showToolbar={true}
              placeholder={`${isComment ? "Comment" : "Message"} text`}
              className={classes.messageArea}
              maxLength={maxMsgLength}
              maxLengthStartingFrom={1800}
              maxLengthInfo={"Characters remaining: ${value}"}
              aria-live="polite"
              rowsMin={"3"}
              validate={validateMessage}
              errorIsHtml={true}
            />
            {errors && touched?.body && !values.body.getCurrentContent().getPlainText().trim().length && !uploadedFile &&
              <StyledFormHelper helperText={errors.body} isError={true} isHtml={true} />
            }
            <MessageImage value={uploadedFile} onRemove={removeImage} />
            <div className={classes.buttonGroup}>
              <div className={classes.supportLabels}>
                <div className={classes.cardImage}>
                  <FileUpload
                    hasFile={!!uploadedFile}
                    labelClass={classes.imageLabel}
                    onChange={(data) => {
                      setDataChanged(!!data);
                      setUploadedFile(data);
                    }}
                    errorClass={classes.fileError}
                  >
                    <ImageIcon />
                    <div className={classes.uploadImageLabel}>Add Image</div>
                  </FileUpload>
                </div>
                {isComment ? null : showEmailAll ? (
                  <FormControlLabel
                    color="secondary"
                    label={<span>Email to all</span>}
                    control={
                      <Checkbox
                        className={classes.checkboxContainer}
                        color="primary"
                        onChange={(event) => {
                          setEmailToAll(event.target.checked);
                        }}
                      />
                    }
                  />
                ) : (
                  <FormControlLabel
                  color="secondary"
                  label={<span>Email Facilitators</span>}
                  control={
                    <Checkbox
                      className={classes.checkboxContainer}
                      color="primary"
                      onChange={(event) => {
                        setEmailToFacilitators(event.target.checked);
                      }}
                    />
                  }
                />
                )}
              </div>
              <div className={classes.buttonWrapper}>
                <CustomButton
                  className={classes.button}
                  mode={"secondary"}
                  onClick={() => {
                    if (isDataChanged) {
                      funcRef.current = onClose;
                    }
                    isDataChanged ? setEscapeConfirmDialog(true) : onClose()
                  }}
                  label={"Cancel"}
                />
                <CustomButton
                  className={classes.submitButton}
                  type="submit"
                  mode={"primary"}
                  label={"Submit"}
                />
              </div>
            </div>
          </Form>)}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default AddAnnouncementCard;
