import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { DialogContent, Typography } from "@material-ui/core/";

import { Modal } from "../modal";
import * as colors from "../../../theme/colors";
import CustomButton from "../../customButton/customButton";

const useStyles = makeStyles({
  dialogContent: {
    padding: 15,
    maxWidth: 350,
  },
  marginTop30: {
    marginTop: 30,
  },
  title: {
    background: colors.lightGradient,
    color: colors.darkThemeBlue,
    fontSize: 22,
    fontWeight: "bold",
    padding: "25px 10px 15px 15px",
    maxWidth: 350,
    textAlign: "center",
    textTransform: "uppercase",
  },
  buttonGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none",
    fontSize: 16,
  },
  cancelBtn: {
    marginLeft: 10,
  },
  saveBtn: {
    width: "30%",
  },
});

export const SendEmailConfirmationModal = ({ isOpen, onSave, onClose }) => {
  const classes = useStyles();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Typography variant="h4" className={classes.title}>
        Send Email Update
      </Typography>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
          {`Would you like to send update emails to all invitees about these changes ?`}
        </Typography>

        <div className={classes.buttonGroup}>
          <CustomButton
            mode="secondary"
            onClick={onClose}
            label={"Do not send"}
          />

          <CustomButton
            className={classes.saveBtn}
            mode="primary"
            type="submit"
            onClick={onSave}
            label={"Send"}
          />
        </div>
      </DialogContent>
    </Modal>
  );
};
