import { createReducer } from "redux-act";

import * as a from "../actions/attendance";

const getDefaultState = () => ({
  isLoading: false,
  attendances: [],
  students: [],
  updatingItems: [],
});

export default () =>
  createReducer(
    {
      [a.getAttendances]: (state) => ({
        ...state,
        isLoading: true,
        attendances: [],
        updatingItems: [],
      }),
      [a.updateAttendance]: (state, payload) => {
        const { attendance } = payload;
        const { updatingItems } = state;

        updatingItems.push({
          date: attendance.date,
          nextUserId: attendance.nextUserId,
        });

        return {
          ...state,
          updatingItems: [...updatingItems],
          isLoading: true,
        };
      },
      [a.receivedAttendances]: (state, payload) => {
        const { attendances, students } = payload;
        return {
          ...state,
          attendances,
          students,
          isLoading: false,
        };
      },
      [a.receivedAttendance]: (state, payload) => {
        const { attendances, updatingItems } = state;
        const { attendance } = payload;

        const index = updatingItems.findIndex(
          (x) =>
            x.userNextId === attendance.userNextId &&
            x.date === attendance.date.split("T")[0]
        );
        if (index > -1) {
          updatingItems.splice(index, 1);
        }

        const indexAttendance = attendances.findIndex(
          (x) =>
            x.nextUserId === attendance.nextUserId && x.date === attendance.date
        );

        if (indexAttendance === -1) {
          attendances.push(attendance);
        } else {
          var foundIndex = attendances.findIndex((x) => x.id == attendance.id);
          attendances[foundIndex] = attendance;
        }

        return {
          ...state,
          attendances: [...attendances],
          updatingItems: [...updatingItems],
          isLoading: false,
        };
      },
    },
    getDefaultState()
  );
