import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import * as moment from "moment";
import * as Yup from "yup";

import * as colors from "../../theme/colors";

import CustomButton from "../../components/customButton/customButton";
import DateInput from "../../components/date-input/DateInput";
import TextInput from "../../components/TextInput";
import Selector from "../../components/selector/Selector";
import Checkbox from "../../components/CheckBox";
import SearchSelector from "../../components/search-select/searchSelect";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import CopyLinkButton from '../../components/copyLinkButton/copyLinkButton';
import { PageLeaveModal } from '../../components/modals/pageLeaveWarning';
import { DOMESTIC_ID } from "../../utils/countriesDict";
import { PROGRAM_TYPES } from '../../utils/constants';
import { programAddressValidationObj } from "../../utils/programHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    [theme.breakpoints.up("md")]: {
      maxWidth: 800,
    },
  },
  dialogContent: {
    padding: "35px",
  },
  marginTop30: {
    marginTop: 15,
    marginBottom: 15,
  },
  mainSection: {
    backgroundColor: colors.white,
    margin: "auto",
    marginTop: 20,
    padding: 20,
    marginBottom: 20,
    [theme.breakpoints.up("md")]: {
      maxWidth: "80%"
    },
  },
  headerBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "baseline"
    },
  },
  subTitle: {
    color: colors.darkThemeBlueGray,
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
  },
  label: {
    color: colors.darkThemeBlueGray,
    fontSize: 12,
    fontWeight: "bold",
  },
  buttonGroup: {
    height: 42,
    display: "flex",
    boxShadow: "none",
  },
  button: {
    borderRadius: 5,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: colors.grey400,
    color: "white",
    "&:hover": {
      backgroundColor: colors.grey500,
    },
  },
  title: {
    textTransform: "uppercase",
    fontWeight: "bold",
    color: colors.darkThemeBlueGray,
    fontSize: 32,
  },
  submitBtn: {
    marginLeft: 10,
  },
  emailCopy: {
    width: 1,
    height: 1,
    opacity: 0,
  },
  updateAllCheckbox: {
    margin: "0px 0px 15px",
  },
  virtualConferenceLink: {
    marginTop: 15,
  },
  socialMediaItem: {
    margin: "15px 0px",
  },
}));

const memberOptions = [
  { value: "1-5", label: "1-5" },
  { value: "5-10", label: "5-10" },
  { value: "10-20", label: "10-20" },
  { value: "Over 20", label: "Over 20" },
];

const typeOptions = [
  { value: "Virtual sessions", label: "Virtual" },
  { value: "In-person sessions", label: "In-Person" },
  { value: "A combination of in-person and virtual sessions", label: "Hybrid" },
];

const getDate = (value) => {
  if (!value || isNaN(new Date(value).getDate())) {
    return null;
  }

  return moment.parseZone(value).format("MM/DD/YYYY");
}

const validationMessages = {
  general: "not valid!",
  launchDate: "Launch Date can't be updated to the date in the past",
  maxLaunchDate: "Please choose a launch date in this Club Year. The Last day to launch a Club for the",
  required: "Required",
};

const currentDate = moment(new Date()).format("MM/DD/YYYY");
const urlExpression =
  /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

const getInitValues = (program, isSip) => {
  if (isSip) {
    return {
      virtualConferenceLink: program.virtualConferenceLink || "",
    };
  }

  const launchDate = program.launchDate ? moment(program.launchDate).format("MM/DD/YYYY") : null;

  const values = {
    launchDate,
    virtualConferenceLink: program.virtualConferenceLink || "",
    projectedNumberOfStudents: program.projectedNumberOfStudents || " ",
    clubMeetingType: program.clubMeetingType || " ",
    shippingCity: program.shippingCity || "",
    shippingStreet: program.shippingStreet || "",
    shippingState: program.shippingState || "",
    shippingZipCode: program.shippingZipCode || "",
    socialMedia: program.socialMedia || "",
    partnershipId: !!program.communityPartnership ? parseInt(program.communityPartnership.id) : "",
  };

  return values;
};

const getValidationSchema = (programTypeDetails, isCollegeLoop, isClub) => {
  let obj = {};
  if (programTypeDetails?.scheduleEnabled && (isCollegeLoop || isClub)) {
    obj.launchDate = Yup.date().required("Required").typeError("Required");
  }
  if (programTypeDetails?.shippingAddressEnabled) {
    obj = { ...obj, ...programAddressValidationObj };
  }
  return Yup.object(obj);
};

export const EditClubInfo = ({ program, onSave, onCloseModal, communityPartners, countryId, programTypeDetails }) => {
  const classes = useStyles();

  const maxDate = `${program.year + 1}-04-30`;

  const validateVirtualLink = (value) => {
    if (!value || !value.trim()) {
      return;
    }

    const isValid = urlExpression.test(value);
    if (!isValid) {
      return "Please enter a valid url";
    }
  };

  const isSIPClub = programTypeDetails?.programType === PROGRAM_TYPES.SIP;
  const isClub = programTypeDetails?.programType === PROGRAM_TYPES.CLUB;
  const isCollegeLoop = programTypeDetails?.programType === PROGRAM_TYPES.COLLEGE_LOOP;

  const currentCp = program.communityPartnership;
  const cpToAdd = !!currentCp && !communityPartners.find(e => e.id === parseInt(currentCp.id, 10))
    ? { ...currentCp, id: parseInt(currentCp.id) }
    : null;

  const items = !!cpToAdd ? [cpToAdd, ...communityPartners] : communityPartners;

  const options = items.map(e => {
    return {
      id: parseInt(e.id),
      label: e.name
    }
  });
  const [isEscapeConfirmDialog, setEscapeConfirmDialog] = useState(false);
  const [isDataChanged, setDataChanged] = useState(false);

  return (
    <Container className={classes.root}>
      <Formik
        initialValues={getInitValues(program, isSIPClub)}
        validationSchema={getValidationSchema(programTypeDetails, isCollegeLoop, isClub)}
        validate={(values) => {
          let errors = {};
          setDataChanged(true);

          const launchDateChanged = getDate(program.launchDate) != getDate(values.launchDate);

          if (launchDateChanged && values.launchDate &&
            moment(values.launchDate).isBefore(currentDate)) {
            errors.launchDate = validationMessages.launchDate;
          }

          if (launchDateChanged && values.launchDate &&
            moment(values.launchDate).format("YYYY-MM-DD") > maxDate) {
            errors.launchDate = `${validationMessages.maxLaunchDate} ${program.year.toString().substring(2)}-${program.year + 1} is April 30`;
          }

          return errors;
        }}
        onSubmit={(values) => {
          const { launchDate, virtualConferenceLink, partnershipId } = values;
          const newValues = {
            ...values,
            launchDate: launchDate
              ? moment(launchDate).format("YYYY-MM-DD")
              : "",
            virtualConferenceLink: virtualConferenceLink.trim(),
          };

          if (!programTypeDetails?.scheduleEnabled && !isCollegeLoop && !isClub) {
            delete newValues.launchDate;
          }

          if (partnershipId) {
            const item = items.find(e => e.id === values.partnershipId);
            newValues.communityPartnership = item;
            newValues.cpOrganizationId = item.id;
          }

          onSave(newValues);
          onCloseModal();
        }}
      >
        <Form>
          <Box className={classes.headerBox}>
            <Box>
              <Typography className={classes.title}>{program.name}</Typography>
            </Box>
            <Box className={classes.buttonGroup}>
              <CustomButton
                mode="secondary"
                onClick={() => isDataChanged ? setEscapeConfirmDialog(true) : onCloseModal()}
                label={"Cancel"}
              />
              <CustomButton
                className={classes.submitBtn}
                mode="primary"
                type="submit"
                label={"Save"}
              />
            </Box>
          </Box>
          <Box className={classes.mainSection}>
            {programTypeDetails?.scheduleEnabled && (isCollegeLoop || isClub) && <>
              <Typography className={classes.subTitle}>Schedule</Typography>
                <Field
                  name="launchDate"
                  component={DateInput}
                  minDate={currentDate}
                  maxDate={maxDate}
                  label="Launch Date"
                  className={classes.marginTop30}
                  domesticId={DOMESTIC_ID}
                  countryId={countryId}
                />
            </>}

            {programTypeDetails?.membersCountEnabled && <>
              <Typography className={classes.subTitle}>Members</Typography>
              <Field
                name={`projectedNumberOfStudents`}
                component={Selector}
                label="Projected Number"
                className={classes.marginTop30}
                options={memberOptions}
              />
            </>}

            {programTypeDetails?.basicInfoCardEnabled && <Typography className={classes.subTitle}>Basic Info</Typography>}

            {programTypeDetails?.meetingTypeEnabled && <>
              <Typography className={classes.label}>Meeting Type</Typography>
              <Field
                name={`clubMeetingType`}
                component={Selector}
                label="Meeting type"
                className={classes.marginTop30}
                options={typeOptions}
              />
            </>}

            {programTypeDetails?.virtualConferenceLinkEnabled && <>
              <Typography className={classes.label}>
                Virtual Conference Link
              </Typography>
              <Field
                type="text"
                name="virtualConferenceLink"
                component={TextInput}
                label="Virtual Conference Link"
                className={classes.virtualConferenceLink}
                validate={validateVirtualLink}
                errorIsHtml={true}
              />
              <Box className={classes.updateAllCheckbox}>
                <Field
                  name="updateEventsUrl"
                  component={Checkbox}
                  label={
                    "Update existing and future Calendar Events with this link."
                  }
                />
              </Box>
            </>}

            {programTypeDetails?.socialMediaEnabled && <>
              <Typography className={classes.label}>
                Social Media
              </Typography>
              <Field
                type="text"
                name="socialMedia"
                component={TextInput}
                label="Social Media"
                className={classes.socialMediaItem}
                errorIsHtml={true}
              />
            </>}

            {programTypeDetails?.communityPartnerAffiliationEnabled && <>
              <Typography className={classes.label}>
                Community Partner Affiliation
              </Typography>
              <Field
                name="partnershipId"
                component={SearchSelector}
                label="Community Partner Affiliation"
                options={options}
                className={classes.marginTop30}
                errorIsHtml={true}
              />
            </>}

            {programTypeDetails?.shippingAddressEnabled && <>
              <Typography className={classes.label}>
                Shipping Address
              </Typography>
              <Field
                name="shippingStreet"
                type="text"
                component={TextInput}
                label="Shipping Street"
                className={classes.marginTop30}
              />
              <Field
                name="shippingCity"
                type="text"
                component={TextInput}
                label="Shipping City"
                className={classes.marginTop30}
              />
              <Field
                name="shippingZipCode"
                type="text"
                component={TextInput}
                label="Shipping Zip Code"
                className={classes.marginTop30}
              />
              <Field
                name="shippingState"
                type="text"
                component={TextInput}
                label="Shipping State"
                className={classes.marginTop30}
              />
            </>}

            {isClub &&
              <Typography>
                Please email {" "}
                <CopyLinkButton link="clubs@girlswhocode.com" />
                {" "}
                to make any edits to your Host Site or Decision Maker details.
              </Typography>
            }
          </Box>
        </Form>
      </Formik>
      <PageLeaveModal
        onConfirm={onCloseModal}
        onCancel={() => { setEscapeConfirmDialog(false); }}
        open={isEscapeConfirmDialog}
      />
    </Container>
  );
};
