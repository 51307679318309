import React from "react";

import { DatePicker } from "@material-ui/pickers";

import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import * as colors from "../../../theme/colors";

const useStyles = makeStyles({
  root: {
    display: "flex",
    border: "1px solid #7A7A78",
    "& .MuiPickersToolbar-toolbar": {
      display: "none",
    },
    "& .MuiPickersStaticWrapper-staticWrapperRoot": {
      backgroundColor: "transparent",
    },
    "& .MuiPickersCalendarHeader-iconButton": {
      backgroundColor: "transparent",
    },
    "& .MuiPickersCalendarHeader-transitionContainer": {
      "& p": {
        fontWeight: "bold",
      },
    },
    "& .MuiPickersDay-daySelected": {
      backgroundColor: colors.green950,
    },
  },
  dayWithDotContainer: {
    position: "relative",
    "& button": {
      backgroundColor: colors.lightThemeGreen,
    },
  },
});

const DatetimePicker = ({ date, setSelectedDate }) => {
  const classes = useStyles();

  const renderDayInPicker = (
    date,
    selectedDate,
    dayInCurrentMonth,
    dayComponent
  ) => {
    if (date.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) {
      return <div className={classes.dayWithDotContainer}>{dayComponent}</div>;
    }

    return dayComponent;
  };

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          autoOk
          variant="static"
          orientation="portrait"
          openTo="date"
          renderDay={renderDayInPicker}
          value={date}
          showTodayButton={true}
          onChange={setSelectedDate}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default DatetimePicker;
