import React from 'react';
import * as reactDetectDevice from "react-device-detect";

export const userAgentCheck = () => {
  const userAgent =
    typeof window.navigator === "undefined" ? "" : navigator.userAgent;
  const isMobile = Boolean(
    userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  );
  return isMobile;
}

export default function useDeviceDetect() {
  // save current window width in the state object
  const [isMobile, setIsMobile] = React.useState(reactDetectDevice.isMobile || false);

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  React.useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 5 milliseconds
      timeoutId = setTimeout(() => setIsMobile(userAgentCheck()), 5);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    }
  }, [])

  return isMobile;
}