import { createAction } from 'redux-act';

export const loadGlobalContent = createAction();
export const globalContentLoaded = createAction();

export const loadCurriculum = createAction();

export const curriculumLoaded = createAction();

export const loadHomePageContent = createAction();
export const homePageContentLoaded = createAction();

export const loadStudentsGroupImage = createAction();
export const loadStudentsGroupImageLoaded = createAction();