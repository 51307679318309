import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import { StyledFormHelper } from "../loginSignup/customFormHelper";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import * as colors from "../../theme/colors";

const useStyles = makeStyles({
  checkBoxItem: {
    color: colors.greenA300,
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: colors.greenA300,
    },
    backgroundColor: "white !important",

    "&.Mui-focusVisible": {
      outline: `2px dotted ${colors.grey400}`,
      outlineOffset: -2,
    },
    "&:focus": {
      outline: `2px dotted ${colors.grey400}`,
      outlineOffset: -2,
    },
  },
  errorLabel: {
    color: colors.redA400,
  },
  label: {
    zIndex: 2,
    padding: "0 2px",
  },
  adortmentBorder: {
    marginRight: 10,
  },
});

const MultipleSelector = ({
  className,
  field: { name, value },
  label,
  form: { errors, touched, setFieldValue, values },
  options,
  helperText,
  onValueChange,
  Icon,
  errorIsHtml,
}) => {
  const classes = useStyles();
  if (!options) return null;
  let error = errors[name];
  let isTouched = touched[name];

  const selectedAdd = value.length === options.length;

  return (
    <React.Fragment>
      <FormControl
        variant="outlined"
        fullWidth
        className={className}
        helperText={helperText}
      >
        <Select
          id={name}
          name={name}
          SelectDisplayProps={{role: 'listbox'}}
          multiple
          label={helperText}
          error={!!error && isTouched}
          MenuProps={{
            getContentAnchorEl: () => null,
            MenuListProps: {
              variant: "selectedMenu",
              role: 'menu',
              autoFocus: true,
            }
          }}
          helperText={
            error && isTouched ? (errorIsHtml ? "" : error) : helperText
          }
          value={value}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return "Select Program";
            }

            return selected.length > 1
              ? `${selected.length} Programs`
              : options
                  .filter((e) => selected.includes(e.id))
                  .map((e) => e.label)
                  .join("");
          }}
          InputProps={{
            placeholder: label,
            startAdornment: Icon ? (
              <InputAdornment position="start">
                <Icon />
              </InputAdornment>
            ) : null,
          }}
          startAdornment={
            Icon ? (
              <div className={classes.adortmentBorder}>
                {" "}
                <Icon />
              </div>
            ) : null
          }
          displayEmpty
          onChange={(event) => {
            if (event.target.value.includes(-1)) {
              const items = selectedAdd ? [] : options.map((e) => e.id);
              setFieldValue(name, items);
              if (onValueChange) {
                onValueChange(items, setFieldValue, values, touched);
              }
              return;
            }
            const items = event.target.value;
            if (onValueChange) {
              onValueChange(event.target.value, setFieldValue, values, touched);
            }
            setFieldValue(name, items);
          }}
        >
          {options.length && (
            <MenuItem aria-label={label} className={classes.checkBoxItem} key={-1} value={-1} role="option">
              <Checkbox inputProps={{ 'aria-labelledby':"select-all" }} checked={selectedAdd} />
              <ListItemText primaryTypographyProps={{ id:"select-all" }} primary="Select All" />
            </MenuItem>
          )}
          {options.sort((a, b) => a.label.localeCompare(b.label)).map(({ id, label }) => (
            <MenuItem aria-label={label} className={classes.checkBoxItem} key={id} value={id} role="option">
              <Checkbox inputProps={{ 'aria-labelledby':id }} checked={value.includes(id)} />
              <ListItemText primaryTypographyProps={{ id:id }} primary={label} />
            </MenuItem>
          ))}
        </Select>
        {error &&
          isTouched &&
          (errorIsHtml ? (
            <StyledFormHelper helperText={error} isError={true} isHtml={true} />
          ) : (
            <FormHelperText className={classes.errorLabel}>
              {error}
            </FormHelperText>
          ))}
      </FormControl>
    </React.Fragment>
  );
};

export default MultipleSelector;
