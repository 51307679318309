import cta from './Cta';
import * as FormFields from './FormFields';

const enrollmentForm = `
  name
  subheading
  cityField {
    ${FormFields.commonFieldAttributes}
    ${FormFields.formField}
  }
  stateField {
    ${FormFields.commonFieldAttributes}
    ${FormFields.selectField}
  }
  stateProvinceField {
    ${FormFields.commonFieldAttributes}
    ${FormFields.selectField}
  }
  zipCodeField {
    ${FormFields.commonFieldAttributes}
    ${FormFields.formField}
  }
  phoneField {
    ${FormFields.commonFieldAttributes}
    ${FormFields.formField}
  }
  gradeField {
    ${FormFields.commonFieldAttributes}
    ${FormFields.selectField}
  }
  ethnicityField {
    ${FormFields.commonFieldAttributes}
    ${FormFields.selectField}
  }
  additionalAsianEthnicityField {
    ${FormFields.commonFieldAttributes}
    ${FormFields.selectField}
  }
  additionalMultiRacialEthnicityField {
    ${FormFields.commonFieldAttributes}
    ${FormFields.selectField}
  }
  socialLinkedinField {
    ${FormFields.commonFieldAttributes}
    ${FormFields.formField}
  }
  collegeGraduationYearField {
    ${FormFields.commonFieldAttributes}
    ${FormFields.selectField}
  }
  participatedInGwcProgramCompleteField {
    ${FormFields.commonFieldAttributes}
    ${FormFields.selectField}
  }
  previousEmailField {
    ${FormFields.commonFieldAttributes}
    ${FormFields.formField}
  }
  collegeMajorField {
    ${FormFields.commonFieldAttributes}
    ${FormFields.formField}
  }
  collegeAdditionalMajorField {
    ${FormFields.commonFieldAttributes}
    ${FormFields.formField}
  }
  addAnotherMajorLabel
  removeMajorLabel
  collegeMinorField {
    ${FormFields.commonFieldAttributes}
    ${FormFields.formField}
  }
  collegeAdditionalMinorField {
    ${FormFields.commonFieldAttributes}
    ${FormFields.formField}
  }
  addAnotherMinorLabel
  removeMinorLabel
  confirmCta {
    ${cta}
  }
  confirmJoinLoopCta {
    ${cta}
  }
  `;

export default enrollmentForm;
