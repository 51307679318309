import React, { useState, useEffect } from "react";
import qs from "query-string";

import * as projectGalleryActions from "../../actions/projectGallery";
import { makeStyles } from "@material-ui/core/styles";

import { Link } from "react-router-dom";
import debounce from "debounce";
import { push as pushToUrl } from "connected-react-router";

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import PublishIcon from "@material-ui/icons/Publish";

import { connectTo, useFocus } from "../../utils/generic";
import { useTitle } from "../../utils/pageTitles";
import * as tokenStorage from '../../tokenStorage';
import PageTitle from "../../components/page-title/pageTitle";

import * as trackingActions from "../../actions/tracking";
import {
  PROJECT_GALLERY_VIEW,
  PROJECT_GALLERY_LEFT_PAGE,
  PROJECT_GALLERY_FILTERS_CHANGED,
  PROJECT_GALLERY_SEE_MORE_PROJECTS_CLICKED,
  PROJECT_GALLERY_UPLOAD_CLICKED,
} from "../../constants/trackingEvents";

import {
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
} from "@material-ui/core/";

import * as colors from "../../theme/colors";
import { checkIsClubsDomain } from '../../utils/generic';
import CustomButton from "../../components/customButton/customButton";
import GalleryFilters from "./gallery-filters/galleryFilters";
import SkeletonCards from "./skeletonCards/index";

const useStyles = makeStyles((theme) => ({
  filtersContainer: {
    marginBottom: 15,
    marginTop: 15,
  },
  galleryCard: {
    borderBottom: "6px solid $grey-dark",
    boxSizing: "border-box",
    cursor: "pointer",
    margin: 12,
    padding: 24,
    transition: "transform 150ms linear",
    height: "fit-content",
    width: "-webkit-fill-available",
    "&:hover": {
      transform: "scale(1.05, 1.05)",
    },
    "&:focus": {
      outline: "none",
      transform: "scale(1.05, 1.05)",
    },
  },
  cardСover: {
    height: 140,
  },
  cardTitle: {
    overflow: "hidden",
    fontWeight: 600,
    fontSize: 16,
    height: 48,
    boxOrient: 'vertical',
    lineClamp: 2,
    display: '-webkit-box'
  },
  cardContent: {
    padding: "16px 0 0 !important",
  },
  projectsTable: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  topPanel: {
    color: colors.dartThemeBlueGray,
    lineHeight: "1.4em",
    margin: "0 12px 12px",
    textAlign: "left",
    whiteSpace: "pre-wrap",
  },
  rootPage: {
    margin: "auto",
    marginBottom: 20,
    maxWidth: 1200,
    display: "grid",
    padding: "0 16px",
  },
  projectGalleryContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 20,
    padding: "20px 0 0 0",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  titleBox: {
    width: "100%"
  },
  uploadButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 0,
    [theme.breakpoints.up("sm")]: {
      width: 450,
    },
    [theme.breakpoints.down("xs")]: {
      margin: "15px 0 0 10px",
      display: "block",
      justifyContent: "normal",
    },
  },
  projectLink: {
    textDecoration: "none",
  }
}));

const DEFAULT_PAGE_NUMBER = 0;
let startViewTime = new Date();
const isClubsDomain = checkIsClubsDomain();

const gradeItems = [
  {
    label: "3rd5th",
    filterName: "grades",
    attribute: "3rd-5th",
  },
  {
    label: "6th12th",
    filterName: "grades",
    attribute: "6th-12th",
  },
];

const flagsItem = [
  {
    label: "GWC Challenge",
    filterName: "flags",
    attribute: "onlyChallenge",
  },
]

const renderYearOptions = () => {
  const yearsToRender = [];
  let currentYear = new Date().getFullYear();

  for (let i = currentYear; i >= 2016; --i) {
    yearsToRender.push({
      id: `${i}`,
      name: `${i}`,
      filterName: "years",
      attribute: `${i}`,
      label: `${i}`,
    });
  }

  return yearsToRender;
};

const renderProgramTypeIdsOptions = (programTypes) => {
  const typeOptions = programTypes.map(type => {
    return { label: type.name, filterName: "programTypeIds", attribute: type.id.toString() };
  });
  !isClubsDomain && typeOptions.push({ label: "INDEPENDENT", filterName: "programTypeIds", attribute: "-1" });
  return typeOptions;
};

const convertFilterParam = (item, property) => {
  if (!item || !item[property]) {
    return [];
  }
  const values =
    item[property].indexOf(",") == -1
      ? item[property]
      : item[property].split(",");
  return Array.isArray(values) ? values : [values];
};

const ProjectGallery = ({
  projectGalleries,
  totalScore,
  filtersData,
  actualFiltersData,
  getProjectGalleries,
  isLoading,
  isLoadingFilters,
  isLoadingMore,
  history: {
    location: { search },
  },
  getFiltersData,
  createTrackingLog,
  push,
  contentful,
  auth: { user },
}) => {
  useTitle("project-gallery");
  const url = qs.parse(search);

  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);

  const [lastCardRef, setCardFocus] = useFocus();
  const [initialLoad, setInitialLoad] = useState(false);

  const defaultFilter = {
    years: [],
    projectTypes: [],
    topicProjectTypes: [],
    langProjectTypes: [],
    programTypeIds: [],
    grades: [],
    countries: [],
    flags: [],
    submitterId: "",
  };

  const [filters, setFilters] = useState({
    years: convertFilterParam(url, "years"),
    projectTypes: convertFilterParam(url, "projectTypes"),
    topicProjectTypes: convertFilterParam(url, "topicProjectTypes"),
    langProjectTypes: convertFilterParam(url, "langProjectTypes"),
    programTypeIds: convertFilterParam(url, "programTypeIds"),
    grades: convertFilterParam(url, "grades"),
    countries: convertFilterParam(url, "countries"),
    flags: convertFilterParam(url, "flags"),
    submitterId: url["submitterId"] || "",
  });
  const [items, setItems] = useState({
    projectTypes: [],
    langProjectTypes: [],
    topicProjectTypes: [],
    programTypeIds: renderProgramTypeIdsOptions(contentful?.programTypes),
    grades: gradeItems,
    flags: flagsItem,
    years: renderYearOptions(),
    countries: [],
  });

  const [searchKey, setSearchKey] = useState(
    url && url.searchKey ? url.searchKey : ""
  );
  const [labels, setLabels] = useState(null);

  const classes = useStyles();

  const getFilteredData = async (
    pageNumberInput,
    searchKeyInput,
    customFilters
  ) => {
    const newPageNumber = !isNaN(pageNumberInput) ? pageNumberInput : pageNumber;
    const newSearchKey =
      searchKeyInput !== undefined ? searchKeyInput : searchKey;

    const compiledFilters = customFilters || filters;

    createTrackingLog({
      event: PROJECT_GALLERY_FILTERS_CHANGED,
      data: JSON.stringify(compiledFilters),
    });

    const payload = {
      pageNumber: newPageNumber,
      filter: compiledFilters,
      searchKey: newSearchKey,
      projectGalleries,
    };

    const filtersUrl = { ...compiledFilters, searchKey: newSearchKey };

    const keys = Object.getOwnPropertyNames(filters);
    const isAnySelected =
      keys.filter((e) => filters[e].length).length > 0 || !!newSearchKey;

    if (!newSearchKey) {
      delete filtersUrl.searchKey;
    }

    if (!compiledFilters.submitterId) {
      delete filtersUrl.submitterId;
    }

    const params = isAnySelected ? `?${qs.stringify(filtersUrl)}` : "?";

    window.history.pushState({}, "project-gallery", params);

    getProjectGalleries(payload);
  };

  useEffect(() => {
    const hasAccount = !!tokenStorage.getToken();
    if (isClubsDomain && !hasAccount) {
      window.location.href = "/";
    }
  }, [user]);

  useEffect(() => {
    if (!contentful.GlobalComponentQuery) {
      return;
    }
    const {
      GlobalComponentQuery: { projectGalleryLabels },
    } = contentful;
    setInitialLoad(true);
    setLabels(projectGalleryLabels);
  }, [contentful]);

  useEffect(() => {
    if (!filtersData) {
      return;
    }
    // TO DO: need to map actual filters and fullfill data. if not. use general filters data
    const { projectTypes, languageTags, topicProjectTypes, countries } =
      filtersData;

    const { programTypeIds, years, grades, flags } = items;

    setItems({
      ...items,
      countries: calcActualData(
        countries,
        actualFiltersData.countries
      ),
      projectTypes: calcActualData(projectTypes, actualFiltersData.tags),
      topicProjectTypes: calcActualData(
        topicProjectTypes,
        actualFiltersData.tags
      ),
      programTypeIds: calcActualData(
        programTypeIds,
        actualFiltersData.programTypeIds
      ),
      langProjectTypes: calcActualData(languageTags, actualFiltersData.tags),
      grades: grades,
      years: calcActualData(years, years.map(a => a.attribute)),
      flags: calcActualData(flags, actualFiltersData.flags),
    });
  }, [filtersData, actualFiltersData]);

  const calcActualData = (items, actualItems) => {
    const filteredItems = items.map((e) => {
      return {
        ...e,
        disabled: !actualItems.find((x) => x.toString() === e.attribute.toString()),
      };
    });
    return filteredItems;
  };

  const loadNextPage = () => {
    const newPageNumber= pageNumber + 1;

    setPageNumber(newPageNumber);

    createTrackingLog({
      event: PROJECT_GALLERY_SEE_MORE_PROJECTS_CLICKED,
    });
    getFilteredData(newPageNumber);
  };

  const searchInputChangeDebounced = debounce((searchKey) => {
    searchKey = !!searchKey.trim ? searchKey.trim() : "";
    setSearchKey(searchKey);
    setPageNumber(DEFAULT_PAGE_NUMBER);

    getFilteredData(DEFAULT_PAGE_NUMBER, searchKey);
  }, 1000);

  useEffect(() => {
    if (!initialLoad) return;
    startViewTime = new Date();
    getFiltersData();
    getFilteredData();

    createTrackingLog({ event: PROJECT_GALLERY_VIEW });
    return () => {
      createTrackingLog({
        event: PROJECT_GALLERY_LEFT_PAGE,
        duration: (new Date() - startViewTime) * 0.001,
      });
    };
  }, [initialLoad]);

  const handleAttributeChange = (name, value, resetFilters) => {
    const newFilters = { ...filters };
    const isItemExist = !!newFilters[name] && newFilters[name].includes(value);
    if (isItemExist) {
      newFilters[name] = newFilters[name].filter((e) => e != value);
    } else {
      if (!newFilters[name]) {
        newFilters[name] = [];
      }
      newFilters[name].push(value);
    }

    if (resetFilters && !!newFilters[name]) {
      resetFilters.map((e) => {
        newFilters[e] = defaultFilter[e];
      });
    }

    setFilters(newFilters);
    getFilteredData(DEFAULT_PAGE_NUMBER, searchKey, newFilters);
  };

  const clearSearchFilter = () => {
    setSearchKey("");
    getFilteredData(DEFAULT_PAGE_NUMBER, "");
  };

  const clearAllFilters = () => {
    const newFilters = { ...filters };
    const keys = Object.getOwnPropertyNames(newFilters);
    keys.map((e) => {
      newFilters[e] = defaultFilter[e];
    });

    setSearchKey("");
    setFilters(newFilters);
    getFilteredData(DEFAULT_PAGE_NUMBER, "", newFilters);
  };

  const renderProject = (project) => {
    const cImage = project.images.find((x) => x.isPrimary === true);
    const coverImage = cImage ? cImage.url : "";
    const isCardRef = project.id === projectGalleries[projectGalleries.length - 1].id ? lastCardRef : null;

    return (
      <Grid key={project.id} item xs={12} sm={6} md={4} lg={3} xl={3}>
        <Link
          to={{
            pathname: `/project-gallery/${project.id}`,
            prevUrl: window.location.search,
            state: {
              prevPath: window.location.pathname,
              label: "project gallery",
            },
          }}
          className={classes.projectLink}
          ref={isCardRef}
        >
          <Card
            title={project.name}
            className={classes.galleryCard}
          >
            <CardMedia component="img" loading="lazy" image={coverImage} className={classes.cardСover} />
            <CardContent className={classes.cardContent}>
              <Typography
                align="left"
                component="h3"
                className={classes.cardTitle}
              >
                {project.name}
              </Typography>
            </CardContent>
          </Card>
        </Link>
      </Grid>
    );
  };

  const loadMore = projectGalleries && totalScore > projectGalleries.length;

  return (
    <div className={classes.rootPage}>
      <Container className={classes.projectGalleryContainer}>
        <Box className={classes.titleBox}>
          <PageTitle title={labels && labels.projectListTitle} />
        </Box>
        <Container className={classes.uploadButtonContainer}>
          {!!user && labels && (
            <div>
              <CustomButton
                mode={"primary"}
                endIcon={<PublishIcon />}
                onClick={() => {
                  createTrackingLog({
                    event: PROJECT_GALLERY_UPLOAD_CLICKED,
                  });
                  push("/new-project", {
                    prevPath: window.location.pathname,
                    label: "project gallery",
                  });
                }}
                label={labels.uploadButton}
                sel="upload-project"
              />
            </div>
          )}
        </Container>
      </Container>
      <Box className={classes.filtersContainer}>
        <GalleryFilters
          filters={filters}
          labels={labels}
          items={items}
          isLoading={isLoadingFilters}
          clearAllFilters={clearAllFilters}
          handleAttributeChange={handleAttributeChange}
          searchKey={searchKey}
          onSearchInput={searchInputChangeDebounced}
          onReset={() => clearSearchFilter("search")}
        />
      </Box>
      <Typography className={classes.topPanel} component="span">
        {labels && labels.projectsListIntroduction}
      </Typography>

      <Grid className={classes.projectsTable} container>
        {isLoading
          ? <SkeletonCards count={8}/>
          : <>
              {projectGalleries.map((project) => renderProject(project))}
              {isLoadingMore && <SkeletonCards count={8}/>}
            </>
        }
      </Grid>

      {!isLoading && projectGalleries.length !== 0 && labels && (
        <CustomButton
          mode={"primary"}
          style={{ height: 42 }}
          disabled={!loadMore}
          onClick={() => {
            loadNextPage();
            setCardFocus();
          }}
          label={
            loadMore
              ? labels.projectsListLoadMoreLabel
              : labels.projectsListThatsAllLabel
          }
        />
      )}
    </div>
  );
};

export default connectTo(
  (state) => ({
    auth: state.auth,
    ...state.projectGallery,
    contentful: state.contentful,
  }),
  {
    ...trackingActions,
    ...projectGalleryActions,
    push: pushToUrl,
  },
  ProjectGallery
);
