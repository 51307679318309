import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import * as communityPartnershipsActions from "../../actions/communityPartnerships";
import { connectTo } from "../../utils/generic";
import CommunityPartnershipContainer from "./CommunityPartnershipContainer/communityPartnershipContainer";
import * as userActivitiesInfoActions from '../../actions/userActivitiesInfo';

import { Grid, Box } from "@material-ui/core";
import NavBar from "./HorizontalNavigation"
import Footer from "../../containers/Footer/footer";
import SkeletonCards from "./CommunityPartnershipContainer/components/skeletonCards";
import PageTitle from "../../components/page-title/pageTitle";

import { useTitle } from "../../utils/pageTitles";
import { getAcademicYear } from "../../utils/generic";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
    cursor: "pointer"
  },
  content: {
    maxWidth: "1100px",
    width: "100%",
    margin: "auto"
  },
  generalContent: {
    flexGrow: 1,
    width: '100%',
    height: "100%",
    minHeight: 'calc(100vh - 135px)',
  },
  item: {
    margin: "auto",
    marginTop: "15px",
    marginBottom: "15px"
  },
  loadingItem: {
    marginTop: "15px",
    marginLeft: "50px"
  }
}));

const getPartnershipYears = (partnerships, years) => {
  var parthershipYears = years || [];

  partnerships.map(p => {
    let isYearExist = parthershipYears.find(e => e.name === p.year);
    if (!isYearExist) {
      parthershipYears.push({ name: p.year, label: getAcademicYear(p.year) });
    }

    // if (p.childPartnerships) {
    //   parthershipYears.concat(
    //     p.childPartnerships.map(child =>
    //       getPartnershipYears(child, parthershipYears)
    //     )
    //   );
    // }
  });

  return parthershipYears.sort((a, b) => (parseInt(a.name, 10) > parseInt(b.name, 10)) ? 1 : -1);
};

const getLatestYear = (years, hqConfigClubYear) => {
  if (!years || !years.length) {
    return "";
  }
  const clubsForActiveYearExists = years.some(year => year.name === hqConfigClubYear);
  const latestYear = clubsForActiveYearExists ? hqConfigClubYear : Math.max(...years.map(e => e.name));
  return latestYear.toString();
};

const updateCounters = clubsList => {
  return {
    clubs: clubsList.length,
    facilitators: clubsList.reduce(
      (total, club) => total + (club.facilitatorsInProgram.length || 0),
      0
    ),
    participants: clubsList.reduce(
      (total, club) => total + (club.participantsCount || 0),
      0
    ),
    clubsWith3OrMore: clubsList.reduce(
      (total, club) => (club.participantsCount >= 3 ? total + 1 : total),
      0
    ),
    clubsByCurriculum: clubsList.reduce((counter, club) => {
      switch (club.sfCurriculumType) {
        case "3rd-5th":
          counter.thirdToFifth = (counter.thirdToFifth || 0) + 1;
          break;
        case "6th-12th":
          counter.sixthToTwelveth = (counter.sixthToTwelveth || 0) + 1;
          break;
        default:
          counter.unknown = (counter.unknown || 0) + 1;
      }
      return counter;
    }, {})
  };
};

const extractClubsData = allData => {
  const clubsCatalog = {};
  allData.forEach(clubData => {
    let club = clubsCatalog[clubData.code];
    if (!club) {
      club = clubsCatalog[clubData.code] = clubData;
      club.participantsCount = clubData.countParticipants;
      club.facilitators = [];
    }
    club.facilitators.push({
      firstName: clubData.facilitatorFirstName,
      lastName: clubData.facilitatorLastName,
      email: clubData.facilitatorEmail
    });
  });

  return Object.keys(clubsCatalog).map(clubCode => clubsCatalog[clubCode]);
};
const getPartnershipsCodesByYears = (partnerships, year) => {
  const selectedYearPartnerships = partnerships.filter(e => e.year === year);
  const codes = selectedYearPartnerships.map(e => e.code);

  return codes || [];
};

const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

const CommunityPartnerhip = props => {
  useTitle("cp-dashboard");

  const classes = useStyles();

  const {
    auth: {
      currentRole,
      user
    },
    history: { push },
    contentful,
    isLoading,
    communityPartnership,
    partnershipStats,
    getCommunityPartnership,
    previewEmail,
    sendPartnerInvitationEmail,
    partnerships,
    userActivitiesInfo,
    getMyUserActivitiesByPartnership,
  } = props;

  const [active, setActive] = React.useState(null);
  const [partnership, setPartnership] = React.useState(null);
  const [currentTab, setCurrentTab] = React.useState('Dashboard');
  const [stats, setStats] = React.useState(null);
  const [selectedYear, setSelectedYear] = React.useState(null);
  const [partnershipYears, setPartnershipYears] = React.useState(null);
  const [clubs, setClubs] = React.useState(null);
  const [counters, setCounters] = React.useState(null);
  const [organizationLabels, setOrganizationLabels] = React.useState(null);
  const [isInternational, setIsInternational] = React.useState(false);
  const [filters, setFilters] = React.useState({
    curriculum: {
      // "3rd5th": true,
      // "6th12th": true,
      // other: true
    },
    renewal: {
      // renewed: true,
      // new: true
    },
    fundReceived: {
      // yes: true,
      // no: true
    }
  });
  const [enableCurriculums, setEnableCurriculums] = React.useState(false);
  const [enableRenewals, setEnableRenewals] = React.useState(false);
  const [hqConfigClubYear, setHqConfigClubYear] = React.useState(null);

  useEffect(() => {
    if (!contentful) return;
    const {
      hqConfig: {
        activeClubYear,
      }
    } = contentful;
    if(activeClubYear) {
      setHqConfigClubYear(activeClubYear.toString().substring(0, 4));
    }
  }, [contentful]);

  useEffect(() => {
    if (!currentRole && !contentful) return;
    if (isLoading || !currentRole?.cpOrganizationId) {
      setPartnership(null);
      setStats(null);

      return;
    }

    if (!communityPartnership && !partnership) {
      return;
    }

    if (!organizationLabels && contentful) {
      const {
        GlobalComponentQuery: { organizationManagementLabels },
        hqConfig: {
          domesticCountryId,
        }
      } = contentful;
      setOrganizationLabels(organizationManagementLabels);

      setIsInternational(!!communityPartnership.countryId &&
        parseInt(domesticCountryId, 10) !== communityPartnership.countryId);
    }

    let currentPartnership = communityPartnership;
    if (active && communityPartnership.id != active && communityPartnership.childPartnerships){
      currentPartnership = communityPartnership.childPartnerships.filter(el => el.id == active)[0];
    }
    setPartnership(currentPartnership);

    const partnershipYears = getPartnershipYears(partnershipStats);
    const latestYear = selectedYear ? selectedYear : getLatestYear(partnershipYears, hqConfigClubYear);

    setClubData(partnershipStats, latestYear);
    setPartnershipYears(partnershipYears);

    setSelectedYear(latestYear);
    setStats(partnershipStats);
  }, [isLoading, user, active, communityPartnership, partnershipStats, selectedYear]);

  useEffect(() => {
    if (!contentful) return;
    const {
      hqConfig: {
        activeClubYear,
      }
    } = contentful;
    setFilters({
      curriculum: { },
      renewal: { },
      fundReceived: { },
    });
    if (!partnershipStats) return;
    const partnershipYears = getPartnershipYears(partnershipStats);
    const latestYear = getLatestYear(partnershipYears, activeClubYear.toString().substring(0, 4));
    setSelectedYear(latestYear);
  }, [partnershipStats]);

  const onChangeYear = value => {
    setFilters({
      curriculum: { },
      renewal: { },
      fundReceived: { },
    });
    setSelectedYear(value);
  };

  const setClubData = (stats, year) => {
    const partnershipCodes = getPartnershipsCodesByYears(stats, year);
    const allData = stats.filter(e => {
      return partnershipCodes.find(c => c === e.code && e.year === year);
    });
    const { curriculum, renewal, fundReceived } = filters;
    const filteredClubs = allData.filter(club => {
      const curriculumIncluded =
        (club.sfCurriculumType === "3rd-5th" && (!Object.keys(curriculum).length || curriculum["3rd5th"])) ||
        (club.sfCurriculumType === "6th-12th" &&
        (!Object.keys(curriculum).length || curriculum["6th12th"])) ||
        (club.sfCurriculumType !== "3rd-5th" &&
          club.sfCurriculumType !== "6th-12th" &&
          (!Object.keys(curriculum).length || curriculum["other"]));
      const renewalStatusIncluded =
        (club.renewal && (!Object.keys(renewal).length || renewal.renewed)) ||
        (!club.renewal && (!Object.keys(renewal).length || renewal.new));
      const fundStatusIncluded =
        ((club.fundSpent || club.fundRemaining) && (!Object.keys(fundReceived).length || fundReceived.yes)) ||
        (!club.fundSpent && !club.fundRemaining && (!Object.keys(fundReceived).length || fundReceived.no));

      return curriculumIncluded && renewalStatusIncluded && fundStatusIncluded;
    });

    var enableRenewals = allData.map(e => e.renewal).filter( onlyUnique ).length > 1;
    var enableCurriculums = allData.map(e => e.sfCurriculumType).filter( onlyUnique ).length > 1;

    const clubs = extractClubsData(filteredClubs);
    const counters = updateCounters(clubs);
    setClubs(clubs);
    setCounters(counters);
    setEnableCurriculums(enableCurriculums);
    setEnableRenewals(enableRenewals);
  };

  const narrowFilter = (oldFilters, filterName, selectedAttribute) => {
    const filters = { ...oldFilters };
    Object.keys(filters[filterName]).forEach(filterAttribute => {
      filters[filterName][filterAttribute] =
        filterAttribute === selectedAttribute;
    });

    applyFilters(filters);
  };

  const applyFilters = filters => {
    setFilters({ ...filters });
    setClubData(stats, selectedYear);
  };

  const handlePartnershipChange = (node) => {
    if (partnership.id === node) return;
    setActive(node);
    getCommunityPartnership({id: parseInt(node), loadOnlyStat: true});
  }

  return (
    <div className={classes.root}>
      <main className={classes.generalContent}>
        <NavBar
          partnership={partnership}
          onSelectNode={handlePartnershipChange}
          setCurrentTab={setCurrentTab}
          currentTab={currentTab}
        />
        {isLoading && (
          <Box className={classes.item}>
            <Box className={classes.loadingItem}>
              <PageTitle title="DASHBOARD" isLoading={isLoading} />
            </Box>
            <Grid container className={classes.item}>
              <SkeletonCards count={3}/>
            </Grid>
          </Box>
        )}
        {partnership && stats && currentTab === "Dashboard" && (
          <Grid container className={classes.item}>
            <CommunityPartnershipContainer
              partnership={partnership}
              isInternational={isInternational}
              selectedYear={selectedYear}
              partnershipYears={partnershipYears}
              onChangeYear={onChangeYear}
              counters={counters}
              stats={stats}
              clubs={clubs}
              filters={filters}
              applyFilters={applyFilters}
              narrowFilter={narrowFilter}
              labels={organizationLabels}
              enableRenewals={enableRenewals}
              enableCurriculums={enableCurriculums}
              contentful={contentful}
              previewEmail={previewEmail}
              sendPartnerInvitationEmail={sendPartnerInvitationEmail}
              user={user}
              partnerships={partnerships}
              push={push}
              userActivitiesInfo={userActivitiesInfo}
              getMyUserActivitiesByPartnership={getMyUserActivitiesByPartnership}
              currentRole={currentRole}
              isLoading={isLoading}
            />
          </Grid>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default connectTo(
  state => {
    return {
      auth: state.auth,
      contentful: state.contentful,
      partnerships: state.communityPartnerships,
      userActivitiesInfo: state.userActivitiesInfo.myProgress,
      ...state.communityPartnerships
    };
  },
  {
    ...communityPartnershipsActions,
    ...userActivitiesInfoActions,
  },
  CommunityPartnerhip
);
