import * as FormFields from "./FormFields";

const CreatingChild = `
  firstName {
    ${FormFields.commonFieldAttributes}
    ${FormFields.formField}
  }
  lastName {
    ${FormFields.commonFieldAttributes}
    ${FormFields.formField}
  }
  programCode {
    ${FormFields.commonFieldAttributes}
    ${FormFields.formField}
  }
  birthDate {
    ${FormFields.commonFieldAttributes}
    ${FormFields.formField}
  }
  parentRelations {
    ${FormFields.commonFieldAttributes}
    ${FormFields.selectField}
  }
  password {
    ${FormFields.commonFieldAttributes}
    ${FormFields.passwordField}
  }
  parentConsentObject {
    title
    subtitle
    parentConsentFileLink
    parentConsentDescription
    parentConsentFile {
      content
      richTextContent
    } 
  }
`;

export default CreatingChild;
