import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import ChildCard from "./childCard";
import { Grid, Box, Container } from "@material-ui/core";
import * as colors from "../../../theme/colors";
import TextButton from "../../../components/text-button/text-button";
import PageTitle from "../../../components/page-title/pageTitle";

const useStyles = makeStyles({
  root: {
    padding: 10
  },
  titleContainer: {
    justifyContent: "space-between",
    display: "flex",
    padding: '0 30px',
    marginTop: 10
  },
  submitButton: {
    height: 40,
    borderRadius: 5,
    backgroundColor: colors.tealA700,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: colors.teal500
    }
  }
});

const ChildrenList = ({ children, onJoin, title, labels, onAddChild, onEdit, loginChild, showAddChild }) => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Box className={classes.titleContainer}>
        <PageTitle title={title} />
        {showAddChild && <TextButton
          label={"+ Add child"}
          size={'small'}
          mode={"primary"}
          onClick={onAddChild}
          isArrowWave={true}
          />}
      </Box>
      <Grid xs={12} md={12} container className={classes.root}>
        {children &&
          children.map((c, ind) => {
            return (
              <ChildCard key={ind} child={c} labels={labels} onJoin={onJoin} onEdit={onEdit} loginChild={loginChild} />
            );
          })}
      </Grid>
    </Container>
  );
};

export default ChildrenList;
