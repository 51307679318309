import { getAuthHeader } from '../tokenStorage';
import { axiosInstance } from './axiosInstance';

export const getProgramsByIds = async ({ ids }) => {
  return axiosInstance.get(`/blapi/programs/getByIds?ids=${ids.join(',')}`, {
    headers: getAuthHeader(),
  });
};

export const getProgramMembers = async ({ id }) => {
  return axiosInstance.get(`/blapi/programs/members/${id}`, {
    headers: getAuthHeader(),
  });
};

export const updateProgram = async (program) =>  {
  return axiosInstance.put(`/blapi/programs/updateProgram/${program.id}`,
  program,
    {
      headers: getAuthHeader()
    }
  );
};

export const updateProgramShipping = async (program) => {
  return axiosInstance.put(`/blapi/programs/updateShipping/${program.id}`,
  program,
    {
      headers: getAuthHeader()
    }
  );
};

export const startProgramRenew = async ({ programId, data }) => {
  return axiosInstance.put(`/blapi/programs/startRenewal/${programId}`,
    data,
    {
      headers: getAuthHeader()
    }
  );
};

export const updatePreferences = async (program) => {
  return axiosInstance.put(`/blapi/programs/updatePreferences/${program.id}`,
    program,
    {
      headers: getAuthHeader()
    }
  );
};

export const updateSocialMedia = async ({ id, socialMedia }) => {
  return axiosInstance.put(`/blapi/programs/updateSocialMedia/${id}`,
    { socialMedia },
    {
      headers: getAuthHeader()
    }
  );
};

export const updateVirtualLink = async (program) => {
  return axiosInstance.put(`/blapi/programs/updateVirtualLink/${program.id}`,
    program,
    {
      headers: getAuthHeader()
    }
  );
};

export const updatePartnerAffiliation = async (program) => {
  return axiosInstance.put(`/blapi/programs/updatePartnerAffiliation/${program.id}`,
    program,
    {
      headers: getAuthHeader()
    }
  );
};

export const getRecruitmentResourceLink = async (programId) => {
  return axiosInstance.get(`/blapi/programs/getRecruitmentResourceLink/${programId}`, {
    headers: getAuthHeader(),
  });
};

export const checkProgramByCode = async ({programCode}) => {
  return axiosInstance.get(`/blapi/programs/checkProgramByCode?code=${programCode}`);
};

export const getProgramByCode = async ({programCode, programTypeId, withFacilitators = false, isSurvey = false}) => {
  return axiosInstance.get(`/blapi/programs/getProgramByCode?code=${programCode}
    ${programTypeId ? `&programTypeId=${programTypeId}` : ''}
    ${withFacilitators ? `&withFacilitators=${withFacilitators}` : ''}
    ${isSurvey ? `&isSurvey=${isSurvey}` : ''}`,
    {
      headers: getAuthHeader()    
    }
  );
};

export const getProgramCertificateId = async (programId) => {
  return axiosInstance.get(`/blapi/programs/getProgramCertificateId/${programId}`, {
    headers: getAuthHeader(),
  });
};
