import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  CardMedia,
} from "@material-ui/core";
import CustomButton from "../../components/customButton/customButton";
import { GroupMemberModal } from "../../components/modals/members/groupMemberModal";
import GroupCard from "./GroupCard";
import Roles from "../../utils/permissions";
import * as colors from "../../theme/colors";
import { getUserName } from '../../utils/generic';
import { TooltipWrapper } from "../../components/tooltip/tooltip";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "flex",
    marginBottom: "20px",
  },
  defaultContent: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: 'flex-start',
    gap: "20px",
  },
  emptyContent: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    padding: "50px",
    justifyContent: 'space-around',
    alignContent: 'space-around',
    backgroundColor: colors.white,
  },
  groupButton: {
    display: "flex",
    justifyContent: 'space-around',
  },
  imageWrapper: {
    display: "flex",
    justifyContent: 'space-around',
    alignContent: 'space-around',
  },
  imageContainer: {
    width: 100,
    height: 100,
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    fontSize: '1.25rem',
    alignItems: 'center',
    flexShrink: 0,
    lineHeight: 1,
    borderRadius: '50%',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: 75,
      height: 75
    },
  },
  addGroup: {
    width: "100%",
    height: "100%",
    padding: "50px 0 50px 0",
    left: "50%",
    transform: "translate(-50%, 0)",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    }
  }
}));

const defaultGroupDescription = 'You can create a student group to filter your view on the Progress page.';

const Groups = ({
  members,
  user,
  createUsersGroups,
  updateUsersGroup,
  deleteUsersGroup,
  isCreatingMembersGroup,
  getUsersGroups,
  usersGroups,
  programId,
  currentMembersGroups = {},
  membersLabels,
  isLoading,
  loadingMembersGroup,
}) => {
  const classes = useStyles();
  const { roles } = user;

  const hasCreatePermission = roles.find((r) =>  r.programId === programId && r.roleId === Roles.Facilitator);
  const [memberGroupModalOpened, setMemberGroupModalOpened] = useState(null);
  const [editingGroup,setEditingGroup] = useState(null);

  useEffect(() => {
    if (!isCreatingMembersGroup) {
      setMemberGroupModalOpened(false);
      getUsersGroups({ programId });
    }
  }, [isCreatingMembersGroup]);

  const onDelete = (groupId, groupName) => {
    deleteUsersGroup({ groupId, groupName });
  };

  const onEdit = (groupName, groupMembers, id) => {
    setEditingGroup({
      groupName,
      groupMembers: groupMembers.map(({ userId }) => userId),
      id,
      programId
    });
    setMemberGroupModalOpened(true);
  };

  const onModalClose = () => {
    setMemberGroupModalOpened(false);
    setEditingGroup(null);
  }

  const membersList = members?.filter(member => member.roleId === Roles.Student).map((item) => ({
    id: item?.userId,
    label: getUserName(item?.user)
  }));
  const numberOfCurrentMembersGroups = Object.keys(currentMembersGroups).length;
  const hasProgramMembers = !!membersList.length;
  return (
    <Box className={numberOfCurrentMembersGroups ? classes.defaultContent :
      classes.emptyContent}>
      {!!numberOfCurrentMembersGroups &&
        <Grid container spacing={1}>
          {(Object.keys(currentMembersGroups)).map((groupName, index) =>
            <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
              <GroupCard
                members={currentMembersGroups[groupName]}
                groupName={groupName}
                onEdit={() => onEdit(groupName, currentMembersGroups[groupName], usersGroups[groupName].id)}
                onDelete={() => onDelete(usersGroups[groupName].id, groupName)}
                hasContextMenu={hasCreatePermission}
                cardIndex={index}
              />
            </Grid>
          )}
          { hasCreatePermission && <Grid item xs={12} sm={6} md={4} lg={4} key={numberOfCurrentMembersGroups}>
            <CustomButton
              label="+ CREATE A GROUP"
              size="small"
              mode="primary"
              sel="createGroup-btn"
              onClick={() => setMemberGroupModalOpened(true)}
              className={classes.addGroup}
            />
          </Grid>}
        </Grid>
      }
      {!isLoading && !loadingMembersGroup && !numberOfCurrentMembersGroups && (
        <>
          <Box className={classes.imageWrapper}>
            <CardMedia className={classes.imageContainer} image={membersLabels?.groupOfStudents?.src} />
          </Box>
          <Box>
            <Typography className={classes.title}>{defaultGroupDescription}</Typography>
          </Box>
          <Box className={classes.groupButton}>
            <TooltipWrapper title={hasProgramMembers ? '' : 'Please add members first'}>
              <Box>
                <CustomButton
                  disabled={!hasProgramMembers || !hasCreatePermission}
                  label="+ CREATE A GROUP"
                  size="large"
                  mode="primary"
                  onClick={() => setMemberGroupModalOpened(true)}
                />
              </Box>
            </TooltipWrapper>
          </Box>
        </>
      )}
      {memberGroupModalOpened && (
        <GroupMemberModal
          isOpen={memberGroupModalOpened}
          onCloseModal={onModalClose}
          membersList={membersList}
          createUsersGroups={createUsersGroups}
          updateUsersGroup={updateUsersGroup}
          isCreatingMembersGroup={isCreatingMembersGroup}
          membersLabels={membersLabels}
          programId={programId}
          currentMembersGroups={currentMembersGroups}
          isLoading={isLoading}
          editingGroup={editingGroup}
          isEdit={!!editingGroup}
        />
      )}
    </Box>
  );
};

export default Groups;
