import { axiosInstance } from '../axiosInstance';

import { getAuthHeader } from '../../tokenStorage';

export const getEnrollmentFormData = async ({ childId }) => {
  return axiosInstance.get(`/blapi/enrollment/get${childId ? `/${childId}` : ""}`,
    {
      headers: getAuthHeader(),
    });
};

export const enrollInProgram = async (payload) => {
  return axiosInstance.post(
    `/blapi/enrollment/enroll`,
    payload,
    {
      headers: getAuthHeader(),
    }
  );
};

export const enrollInCP = async ({ code }) => {
  return axiosInstance.post(
    `/blapi/enrollment/enrollCP/${code}`,
    null,
    {
      headers: getAuthHeader(),
    }
  );
};