import React from "react";

import HoorayLogo from '../../../images/gwcHoorayLogo.svg';
import { makeStyles } from "@material-ui/core/styles";
import { DialogContent, Box, Typography } from "@material-ui/core/";

import { HQModal } from "../HQModal";
import * as colors from "../../../theme/colors";
import CustomButton from "../../customButton/customButton";

const useStyles = makeStyles((theme) => ({
  marginTop30: {
    marginTop: 30,
  },
  contentTitle: {
    color: colors.darkThemeBlueGray,
  },
  content: {
    width: 490,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: "90%",
    },
  },
  contentBox: {
    display: 'block',
  },
  subContentBox: {
    width: 290,
    marginTop: 20,
    justifyContent: 'center',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      width: "100%",
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: 30,
  },
  subTitle: {
    fontWeight: "bold",
    fontSize: 18,
  },
  titleLabel: {
    fontWeight: "bold",
    fontSize: 13,
  },
  buttonGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none",
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      height: 60,
    },
  },
  cancelBtn: {
    marginLeft: 10,
  },
}));

export const ConfirmationModal = ({ isOpen, onClose, onViewClick, onNextClick }) => {
  const classes = useStyles();

  return (
    <HQModal isOpen={isOpen} onClose={onClose}>
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.content}>
          <Box className={classes.contentBox}>
            <Box className={classes.subContentBox}>
              <img
                src={HoorayLogo}
                alt="hooray-logo"
              />
            </Box>
            <Box className={classes.subContentBox}>
              <Typography className={classes.title}>Hooray!</Typography>
            </Box>
            <Box className={classes.subContentBox}>
              <Typography className={classes.subTitle}>Your answer has been submitted.</Typography>
            </Box>
            <Box className={classes.subContentBox}>
              <Typography className={classes.titleLabel}>To make any changes or view other answers please navigate to 'Progress' in 'Tools' section</Typography>
            </Box>
          </Box>
        </Box>
        <div className={classes.buttonGroup}>
          <CustomButton
            className={classes.cancelBtn}
            mode="textButton"
            onClick={onViewClick}
            label={"View All Answers"}
          />
          <CustomButton
            mode="primary"
            type="submit"
            onClick={onNextClick}
            label={"I'm done! Next"}
          />
        </div>
      </DialogContent>
    </HQModal>
  );
};
