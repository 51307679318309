import { createReducer } from "redux-act";

import * as a from "../actions/announcements";

const getDefaultState = () => ({
  announcements: null,
});

export default () =>
  createReducer(
    {
      [a.receivedAnnouncements]: (state, announcements) => {
        return {
          ...state,
          isLoading: false,
          announcements,
        };
      }
    },
    getDefaultState()
  );
