import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles({
  main: {
    maxWidth: (props) => !!props.maxWidth ? "auto" : "650px",
    margin: "auto",
  },
});

export const Modal = props => {
  const { isOpen, ...restProps } = props;
  const { maxWidth } = restProps;
  const classes = useStyles({maxWidth});

  return (
    <div>
      <Dialog 
        className={classes.main} 
        open={isOpen} 
        onClose={restProps.onClose} 
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        aria-modal
        role="dialog"
        {...restProps}>
        {restProps.children}
      </Dialog>
    </div>
  );
}
