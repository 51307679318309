import { createReducer } from 'redux-act'

import * as a from '../actions/changePassword'

const getDefaultState = () => ({
  error: null,
  loading: true,
  tokenIsValid: true,
})

export default () =>
  createReducer(
    {
      [a.changePassword]: (state) => ({
        ...state,
        error: null,
        loading: true,
      }),
      [a.passwordChanged]: (state) => {
        return {
          ...state,
          error: null,
          loading: false,
        };
      },
      [a.passwordChangeError]: (state) => ({
        ...state,
        loading: false,
        error: 'This link is no longer valid for resetting your password.',
      }),
      [a.tokenVerified]: (state, payload) => ({
        ...state,
        loading: false,
        tokenIsValid: payload,
      }),
    },
    getDefaultState()
  )
