import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';

import EmptyProgress from '../EmptyProgress';
import { uniqueFilter } from "../helpers";
import { TASK_STATUSES } from '../../../utils/constants';
import Row from './Row';

const useStyles = makeStyles({
  tableWrapper: {
    marginBottom: 10
  },
  table: {
    tableLayout: 'fixed'
  },
});

export const isActivityNotIncomplete = (answer) => {
  return [TASK_STATUSES.review, TASK_STATUSES.complete].includes(answer.status);
}

export const isActivityPendingGrading = ({ contentSection, answer }) => {
  return !contentSection.isOptionalTextAnswer && !!contentSection.requiredGrading && answer.status === TASK_STATUSES.review;
 }

const UserProgressTable = ({ data, onPush, isMyProgress, clearProgress, ratings, galleryProjects, isFacilitator, clubCode, userAnswers, certificateRibbonIcon, userId }) => {
  const classes = useStyles();

  const calculateRowProgress = (row) => {
    let completedContentCounter = 0;
    let pendingGradingCounter = 0;
    let activitiesTotalCounter = 0;

    row.sets.forEach((set) => {
      if (set.type === 'set') {
        completedContentCounter += set.activities
          .filter((a) => !a.isOptional && a.logs.isDone === true)
          .map((a) => a.id).length;
        activitiesTotalCounter += set.activities
          .filter(uniqueFilter)
          .filter((a) => !a.isOptional).length;

        set.activities.forEach((activity) => {
          if (!activity.logs.isDone) return;

          activity.contentSections.some((section) => {
            const path = `${set.pathWithFolder || set.path}/${activity.slug}-${
              section.id
            }`;
            const answers = userAnswers?.[path];

            if (answers) {
              const isSectionPendingGrade = answers.some((answer) =>
                isActivityPendingGrading({
                  contentSection: section,
                  answer,
                })
              );

              if (isSectionPendingGrade) {
                section.isPendingGrade = true;
                activity.isPendingGrade = true;
                set.isPendingGrade = true;
                pendingGradingCounter += 1;
                return true;
              }

              const isSectionIncomplete = !section.isOptionalTextAnswer && !answers.some((answer) => isActivityNotIncomplete(answer));

              if (isSectionIncomplete) {
                section.isIncomplete = true;
                activity.isIncomplete = true;
                set.isIncomplete = true;
                completedContentCounter -= 1;
                return true;
              }
            }
          });
        });
      } else {
        completedContentCounter += Number(set.logs.isDone);
        activitiesTotalCounter += 1;

        if (!set.logs.isDone) return;

        set.contentSections.some((item) => {
          const path = `${set.pathWithFolder || set.path}-${item.id}`
          const answers = userAnswers?.[path];
          if (answers) {
            const isSectionPendingGrade = answers.some((answer) =>
              isActivityPendingGrading({
                contentSection: item,
                answer: answer,
              })
            );

            if (isSectionPendingGrade) {
              set.isPendingGrade = true;
              pendingGradingCounter += 1;
              return true;
            }

            const isSectionIncomplete = !item.isOptionalTextAnswer && !answers.some((answer) => isActivityNotIncomplete(answer));

            if (isSectionIncomplete) {
              set.isIncomplete = true;
              completedContentCounter -= 1;
              return true;
            }
          }
        });
      }
    });

    row.completedContent = completedContentCounter;
    row.pendingGradingTotal = pendingGradingCounter;
    row.activitiesTotal = activitiesTotalCounter;
    return row;
  }

  if (!data) return null;

  const rowsToRender = data.filter(r => r.sets.find(s => s.shouldBeRendered || (s.logs && s.logs.shouldBeRendered))).map((row) => calculateRowProgress(row));

  return (
    <>
      {(rowsToRender.length === 0) && <EmptyProgress />}
      {rowsToRender.map((row, index) => (
        <div className={classes.tableWrapper}>
          <TableContainer component={Paper} elevation={0}>
            <Table className={classes.table} aria-label="spanning table" role="presentation">
              <TableBody>
                <Row
                  key={index}
                  isOpened={index === 0}
                  row={row}
                  onPush={onPush}
                  isMyProgress={isMyProgress}
                  clearProgress={clearProgress}
                  ratings={ratings}
                  galleryProjects={galleryProjects}
                  isFacilitator={isFacilitator}
                  clubCode={clubCode}
                  userAnswers={userAnswers}
                  certificateRibbonIcon={certificateRibbonIcon}
                  userId={userId}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ))}
    </>
  );
};

export default UserProgressTable;
