import client from "../../../apolloClient";
import { gql } from "apollo-boost";
import obj2arg from "graphql-obj2arg";
import {axiosInstance} from "../../axiosInstance";
import {getAuthHeader} from "../../../tokenStorage";

export const getCountryAttributes = () => {
  return `
    id
    abbreviation
    name
    eligibleForInternationalApplication
    locale
  `;
};

const getStateProvinceAttributes = () => {
  return `
    id
    name
    countryId
  `;
};

export function SearchTranslatedCountry({ searchkey, langId }) {
  const graphQlArguments = {
    langId,
    searchkey
  };

  if (!searchkey) {
    delete graphQlArguments.searchkey;
  }

  return client.query({
    query: gql`{
      SearchTranslatedCountry(${obj2arg(graphQlArguments, {
      noOuterBraces: true
    })}) {
            ${getCountryAttributes()}
          }
        }`
  });
}

export function GetTranslatedCountryForProjectGalleryFilter({ langId }) {
  return axiosInstance.get(`/blapi/galleryProjects/getTranslatedCountryForProjectGalleryFilter/${langId}`, {
    headers: getAuthHeader(),
  });
}

export function GetTranslatedStatesProvinces(countryId, langId) {
  const graphQlArguments = {
    countryId: countryId ? parseInt(countryId, 10) : 225,
    langId: parseInt(langId, 10)
  };

  if (!langId || isNaN(graphQlArguments.langId)) {
    delete graphQlArguments.langId;
  }

  return client.query({
    query: gql`{
      GetTranslatedStatesProvinces(${obj2arg(graphQlArguments, {
      noOuterBraces: true
    })}) {
            ${getStateProvinceAttributes()}
          }
        }`
  });
}

export function GetCountries() {
  return client.query({
    query: gql`{
      GetAllCountries {
        id
        name
        abbreviation
      }
    }`
  });
}
