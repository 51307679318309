import { createReducer } from "redux-act";

import * as a from "../actions/members";

const getDefaultState = () => ({
  invitations: [],
  loadingInvitations: false,
  loadingMembers: false,
  members: [],
  recruitmentResourceLink: null,
  loadingRecruitmentResourceLink: false,
  isCreatingMembersGroup: false,
  usersGroups: [],
  loadingCertificateId: false,
  certificateId: null,
  membersCount: {},
  isLoadingMembersCount: false,
});

export default () =>
  createReducer(
    {
      [a.getMembers]: state => ({
        ...state,
        loadingMembers: true,
        members: [],
      }),
      [a.receivedMembers]: (state, members) => ({
        ...state,
        loadingMembers: false,
        members,
      }),
      [a.userRoleDeleted]: (state, userId) => ({
        ...state,
        members: state.members.filter(u => u.userId !== userId)
      }),
      [a.getInvitations]: state => ({
        ...state,
        invitations: [],
        loadingInvitations: true,
      }),
      [a.receivedInvitations]: (state, invitations) => ({
        ...state,
        invitations,
        loadingInvitations: false,
      }),
      [a.getRecruitmentResourceLink]: state => ({
        ...state,
        loadingRecruitmentResourceLink: true,
        recruitmentResourceLink: null
      }),
      [a.receivedRecruitmentResourceLink]: (state, recruitmentResourceLink) => ({
        ...state,
        recruitmentResourceLink,
        loadingRecruitmentResourceLink: false,
      }),
      [a.createUsersGroups]: (state ) => ({
        ...state,
        createdUsersGroups: {},
        isCreatingMembersGroup: true,
      }),
      [a.createdUsersGroups]: (state, createdUsersGroups) => ({
        ...state,
        createdUsersGroups,
        isCreatingMembersGroup: false,
      }),
      [a.getUsersGroups]: state => ({
        ...state,
        loadingMembersGroup: true,
        usersGroups: [],
      }),
      [a.receivedUsersGroups]: (state, usersGroups = []) => ({
        ...state,
        loadingMembersGroup: false,
        usersGroups
      }),
      [a.usersGroupDeleted]: (state, groupName) => {
        const usersGroups = { ...state.usersGroups };
        delete usersGroups[groupName];

        return {
          ...state,
          usersGroups,
        };
      },
      [a.updateUsersGroup]: (state) => {
        return {
          ...state,
          loadingMembersGroup: true,
        };
      },
      [a.updatedUsersGroup]: (state, payload) => {
        const { oldGroupName, group } = payload;
        const usersGroups = { ...state.usersGroups };
        delete usersGroups[oldGroupName];

        usersGroups[group.groupName] = group;

        return {
          ...state,
          usersGroups,
          loadingMembersGroup: false,
        };
      },
      [a.onError]: (state) => {
        return {
          ...state,
          loadingMembersGroup: false,
          loadingMembers: false,
          loadingRecruitmentResourceLink: false,
        };
      },
      [a.getProgramCertificateId]: state => ({
        ...state,
        loadingCertificateId: true,
        certificateId: null
      }),
      [a.receivedProgramCertificateId]: (state, certificateId) => ({
        ...state,
        certificateId,
        loadingCertificateId: false,
      }),
      [a.receivedMembersCount]: (state, payload) => {
        const { programId, totalScore } = payload;
        const newMembersCount = { ...state.membersCount, [programId]: totalScore };

        return {
          ...state,
          membersCount: newMembersCount,
          isLoadingMembersCount: false,
        };
      }
    },
    getDefaultState(),
  );
