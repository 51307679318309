import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import withPageLabels from "../../hocs/withPageLabels";

import Alert from "@material-ui/lab/Alert";
import {
  Box,
  Container,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";

import { connectTo } from "../../utils/generic";
import * as actions from "../../actions/changePassword";
import * as authActions from "../../actions/auth";

import Link from "../../components/Link";
import { PasswordInput } from "../../components/PasswordInput";
import TermsInput from "../../components/termsInput/TermsInput";
import * as colors from "../../theme/colors";
import CustomButton from "../../components/customButton/customButton";
import Footer from "../../containers/Footer/footer";

import GoogleButton from "../../components/GoogleButton";

import DividerWithText from "../../components/DividerWithText";
import EmailInput from "../../components/EmailInput";

import {
  filterField,
  validatePasswordRules,
  filterFieldsByKey,
} from "../../utils/generic";

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: 28,
  },
  gradientBox: {
    height: "100%",
  },
  container: {
    width: "98%",
    zIndex: 1,
  },
  content: {
    //backgroundImage: `url(${PatternImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0 -85px",
    backgroundSize: "100% 508px",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 123px)",
    [theme.breakpoints.down("xs")]: {
      minHeight: "calc(100vh - 197px)",
    },
  },
  card: {
    maxWidth: 445,
    width: 445,
    marginTop: 15,
  },
  cardContent: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: "0px !important",
  },
  form: {
    width: "100%",
  },
  boxContainer: {
    margin: "20px auto 0px auto",
  },
  title: {
    margin: "55px auto 15px",
    fontSize: 22,
    fontWeight: "bold",
    maxWidth: 330,
    textAlign: "center",
  },
  subTitle: {
    fontSize: 16,
    width: "70%",
    textAlign: "center",
    marginTop: 5,
    lineHeight: 1.75,
  },
  invalidToken: {
    fontSize: 16,
    width: "70%",
    textAlign: "center",
    marginTop: 5,
    marginBottom: 55,
    lineHeight: 1.75,
  },
  cantFindIt: {
    fontSize: 16,
    width: "70%",
    textAlign: "center",
    marginTop: 60,
    lineHeight: 1.75,
  },
  createAccountBox: {
    marginTop: 32,
  },
  smallText: {
    color: "#565656",
    fontSize: 12,
  },
  submitContainer: {
    width: "90%",
    marginTop: 20,
    display: "flex",
    margin: "auto",
  },
  blockSpacer: {
    color: "#20a8ed",
    margin: "0 11px",
  },
  submitButton: {
    marginBottom: 20,
    marginTop: 30,
    width: "100%",
    height: 42,
  },
  resetLink: {
    fontSize: 16,
    textDecoration: "underline",
  },
  termsContainer: {
    margin: 20,
  },
  termsAndConditions: {
    margin: "auto",
  },
  bottomLink: {
    color: colors.white,
    marginLeft: 10,
    fontSize: 12,
    "&:hover": {
      color: colors.lightThemeGreen,
      textDecoration: "none",
    },
  },
  buttonContainer: {
    margin: "auto",
  },
  errorMsg: {
    color: colors.redA400,
    fontSize: 12,
    marginBottom: 12,
  },
}));

const validationSchema = Yup.object({
  termsAndConditions: Yup.boolean().test(
    "is-true",
    "You must accept the terms and conditions to continue.",
    (value) => value === true
  ),
});

const SetPassword = (props) => {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [userData, setUserData] = useState({});
  const [termAgreed, setTermAgreed] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  const {
    history: { push },
    match: {
      params: { token },
    },
    changePassword,
    error,
    tokenIsValid,
    tokenVerified,
    pageLabels,
  } = props;

  const loginContent = pageLabels ? pageLabels.modules[0] : {};

  let passwordContent = loginContent
    ? filterField(loginContent.signupFields, "id", "newPassword")
    : {};

  React.useEffect(() => {
    axios({
      method: "GET",
      url: `${window._env_.REACT_APP_API_URL}/auth/verifyResetToken?token=${token}`
    })
      .then(({ data }) => {
        if (!data.firstTimeSetPassword) {
          push(`/change-password?token=${token}`);
        }
        setEmail(data.email);
        setUserData(data);
        tokenVerified(true);
      })
      .catch(() => {
        push("/forgot-password", { showNote: true });
        tokenVerified(false);
      });
  }, []);

  const responseGoogle = (response) => {
    const { accessToken, profileObj } = response;

    if (!profileObj) return;

    const {
      email: gmail,
    } = profileObj;

    if (email !== gmail) {
      setErrorMsg(`Google email does not match ${email}`);
      return;
    }
    setErrorMsg(null);

    changePassword({
      token, // user reset pswrd token
      accessToken, // google sso token
    });
    // authorize({ login: gmail, accessToken });
  };

  // const responseGoogleError = (response) => {
    // TODO: next line was commented out because qa-automation gets alert for test.hq.girlswhocode.com
    // This should be handled properly
    // alert('Please, make sure cookies are enabled for current application');
  // };

  const validatePassword = (value) => {
    const validationErrors = filterFieldsByKey(
      passwordContent.validationErrors,
      "messageCode",
      "message"
    );
    if (!value || value.length == 0) {
      return validationErrors.requiredField;
    }

    const validateResult = validatePasswordRules(value, email, userData.username, userData.firstName);
    if (Object.keys(validateResult).length > 0) {
      setDisableBtn(true);
      return validationErrors.weakPassword;
    } else {
      setDisableBtn(false);
    }
  };

  return (
    <Box className={classes.gradientBox}>
      <Box className={classes.content}>
        <Card className={classes.card}>
          {!tokenIsValid && (
            <CardContent className={classes.cardContent}>
              <Typography
                variant="h4"
                className={classes.title}
                color="primary"
                gutterBottom
              >
                Invalid Link
              </Typography>
              <Typography
                variant="h6"
                color="primary"
                className={classes.invalidToken}
              >
                This link is no longer valid for setting your password. Please
                fill the &nbsp;
                <Link
                  className={classes.resetLink}
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    push("/forgot-password");
                  }}
                >
                  Recovery Password Form
                </Link>{" "}
                again to receive a new one via email.
              </Typography>
            </CardContent>
          )}
          {tokenIsValid && (
            <CardContent className={classes.cardContent}>
              <Typography
                variant="h4"
                className={classes.title}
                color="primary"
                gutterBottom
              >
                Activate Your New HQ Account
              </Typography>
              <Typography
                variant="h6"
                color="primary"
                className={classes.subTitle}
              >
                Please review our policies, then choose how you will log in.
              </Typography>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  password: "",
                  email: email,
                  termsAndConditions: false,
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  changePassword({
                    password: values.password,
                    token,
                  });
                }}
              >
                <Form className={classes.form}>
                  <Container className={classes.container}>
                    {!!error && (
                      <Alert
                        className={classes.alert}
                        severity="error"
                        variant="outlined"
                      >
                        {error}
                      </Alert>
                    )}
                    <Box className={classes.boxContainer}>
                      <Field
                        name="email"
                        component={EmailInput}
                        label="Email"
                        className={classes.marginTop30}
                        disabled={true}
                      />
                    </Box>
                    <Box className={classes.termsContainer}>
                      <Field
                        name="termsAndConditions"
                        className={classes.termsAndConditions}
                        component={TermsInput}
                        termsContent={
                          loginContent.termsAndConditionsInternational
                        }
                        onValueChange={(value) => {
                          setTermAgreed(value);
                          setErrorMsg(null);
                        }}
                      />
                    </Box>
                    {termAgreed && (
                      <Box className={classes.boxContainer}>
                        <GoogleButton
                          variant="outlined"
                          labelText="Continue with Google"
                          onSuccess={responseGoogle}
                          onError={responseGoogle}
                        />
                      </Box>
                    )}
                    {termAgreed && !!errorMsg && (
                      <Typography className={classes.errorMsg}>
                        {errorMsg}
                      </Typography>
                    )}
                    {termAgreed && (
                      <DividerWithText className={classes.ssoDivider}>
                        OR
                      </DividerWithText>
                    )}
                    {termAgreed && (
                      <Box className={classes.boxContainer}>
                        <Field
                          name="password"
                          component={PasswordInput}
                          label={passwordContent.labelPrompt}
                          className={classes.marginTop20}
                          helperText={passwordContent.helpText}
                          fromModal={true}
                          validate={validatePassword}
                          content={passwordContent}
                          username={userData.username}
                          firstName={userData.firstName}
                        />
                      </Box>
                    )}
                  </Container>
                  {termAgreed && (
                    <Box className={classes.submitContainer}>
                      <CustomButton
                        className={classes.submitButton}
                        disabled={disableBtn}
                        mode={"primary"}
                        type="submit"
                        label={"Continue"}
                      />
                    </Box>
                  )}
                </Form>
              </Formik>
            </CardContent>
          )}
        </Card>
      </Box>
      <Footer />
    </Box>
  );
};

export default withPageLabels(
  { slug: "/login" },
  connectTo(
    (state) => state.changePassword,
    { ...actions, ...authActions },
    SetPassword
  )
);
