import React from "react";
import { Button } from "@material-ui/core";
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const path = "https://www.linkedin.com/profile/add";
const task = "CERTIFICATION_NAME";

const orgId = 3978634; //gwc linkedin organization id;

const AddCertificateLinkedinButton = props => {
  const { item, name } = props;

  const certId = `${window.location.origin}/verify/${item.title}`;
  const date = new Date(item.createdAt);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  const url =
    `${path}?startTask=${task}&name=${escape(name)}&organizationId=${orgId}&issueYear=${year}&issueMonth=${month}&certUrl=${certId}&certId=${item.title}`;
  
    return (
    <React.Fragment>
      <Button onClick={() => {
        window.open(url,  "_blank");
      }} {...props}><LinkedInIcon /></Button>
    </React.Fragment>
  );
};

export default AddCertificateLinkedinButton;
