import React from "react";

import {
  Box,
  CardContent,
  Grid,
} from "@material-ui/core/";

import { makeStyles } from "@material-ui/core/styles";
import * as colors from "../../../theme/colors";

import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import CheckCircle from '@material-ui/icons/CheckCircle';
import TextCustomButton from "../../../components/text-button/text-button";
import { createMarkup } from "../../../utils/generic";
import { ReSubmitAnswer } from './resubmit';
import {AnswerCard} from './answerCard';

const useStyles = makeStyles(() => ({
  viewBox: {
    display: 'block',
    padding: 25,
    "& *": {
      maxWidth: '100%',
    },
    '& ul': {
      "padding-left": 40,
    },
    '& li': {
      lineHeight: 1.8,
      paddingLeft: 10,
      "&::marker": {
        color: colors.darkThemeGreen,
      }
    },
    '& details': {
      backgroundColor: colors.white,
      borderRadius: 5,
      border: '1px solid',
      borderColor: colors.darkThemeGreen,
      margin: '10px 0',
      padding: 10,

      '&:focus-within': {
        border: '2px solid',
        borderColor: colors.black,
        padding: 9
      },

      '& > summary': {
        outline: 'none',
        cursor: 'pointer',
        color: colors.darkThemeGreen,
        fontWeight: 700
      }
    },
    '& a': {
      color: colors.darkThemeGreen,
      lineHeight: 1.8,
      fontWeight: 700,
      textTransform: "initial",
    },
  },
    answerLabel: {
    color: colors.lightThemeGrey,
    fontSize: 18,
    fontWeight: "bold",
    textTransform: "uppercase",
    display: "flex",
  },
    answerContent: {
    color: colors.darkThemeBlueGray,
    fontSize: 18,
    width: "100%",
  },
    completedLabel: {
    fontSize: 18,
    color: colors.darkThemeBlueGray,
    display: 'flex',
    marginTop: 30,
  },
    checkmarkIcon: {
    color: colors.lightThemeGreen,
    height: 22,
    marginLeft: 5,
  },
    divider: {
    marginTop: 20,
    marginBottom: 20,
  },
  backBtn: {
    paddingLeft: 0,
  },
}));

const SimpleQuestion = ({
  currentNode,
  isReSubmit,
  isLoadingComments,
  items,
  createAnswer,
  setIsReSubmit,
  isCompleted,
  disableResubmit,
  history,
  onBack,
  showBackBtn,
}) => {
  const classes = useStyles();
  const {
    contentSection: {
      heading,
      content,
    } } = currentNode;


  React.useEffect(() => {
    // add event and styles to dynamic render checkbox
    const inputsWithDataAttr = document.querySelectorAll("[data-attr]");
    for (let input of inputsWithDataAttr) {
      const attr = input.getAttribute("data-attr");
      const parent = input.parentNode;
      input.setAttribute('checkbox', 'true');
      const checkbox = document.createElement("input");
      checkbox.setAttribute('id', attr);
      checkbox.type = "checkbox";
      const label = document.createElement('label');
      label.setAttribute("for", attr);
      label.innerHTML = input.parentNode.innerHTML.split('checkbox="true">')[1];
      parent.setAttribute("class", 'md-checkbox');

      // Adding attributes for acessibility to checkmark and label;
      label.setAttribute("id", `id=${attr}`);
      checkbox.setAttribute('tabindex', '0');
      checkbox.setAttribute('aria-labelledby', `id=${attr}`);

      checkbox.setAttribute('listener', 'true');
      parent.textContent = '';
      parent.appendChild(checkbox);
      parent.appendChild(label);
    }
  }, [content]);

  return (<CardContent className={classes.viewBox}>
    <Box className={classes.answerLabel}>
      {heading} {isLoadingComments && <CircularProgress size={15} />}
    </Box>
    <Box
      className={classes.answerContent}
      dangerouslySetInnerHTML={createMarkup(content)}
    />
    {isReSubmit
      ? <Box>
        <ReSubmitAnswer
          item={items[0]}
          questionName={heading}
          onCancel={() => setIsReSubmit(false)}
          onSubmit={createAnswer}
          history={history}
        />
      </Box>
      : <Box>
        {isCompleted
          ?
          <Box className={classes.completedLabel}>
            Complete
            <CheckCircle className={classes.checkmarkIcon} />
          </Box>
          : !disableResubmit && !showBackBtn && <TextCustomButton
            label={"Resubmit"}
            onClick={() => setIsReSubmit(true)}
          />}
          {showBackBtn && <TextCustomButton className={classes.backBtn} label="Back to curriculum" onClick={onBack} />}
      </Box>}

    <Divider className={classes.divider} />
    <Grid container>
      {Array.isArray(items) && items.map((item, index) => <AnswerCard
        item={item}
        isLast={index + 1 === items.length}
      />)}
    </Grid>
  </CardContent>);
}

export default SimpleQuestion;