import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Fade from "@material-ui/core/Fade";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";

import { ACTIVITY_VIEW } from "../../constants/trackingEvents";
import { getTheme } from "../../utils/colorTheme";

import ContentSection from "./ContentSection/ContentSection";

import navBackground from "../../images/navBackground.png";
import { Typography, Container } from "@material-ui/core";

import ScrollMenu from "./ScrollMenu";

import BackIcon from "../../images/arrow-back-icon.svg";
import NextIcon from "../../images/arrow-forward-icon.svg";
import CustomButton from "../../components/customButton/customButton";
import clsx from "clsx";
import Roles from "../../utils/permissions";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
  },
  wrapper: {
    display: "grid",
    margin: "auto",
    maxWidth: 1200,

    [theme.breakpoints.up("sm")]: {
      padding: "0 15px",
    },
  },
  header: {
    maxHeight: 100,
    justifyContent: 'left',
    backgroundColor: "#0D9C90",
    backgroundImage: `url(${navBackground})`,
    backgroundRepeat: "repeat-x",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headerContainer: {
    maxWidth: 1160,
    display: "flex",
    height: 100,
    maxHeight: 100,
    alignItems: "center",
    width: "100%",
  },
  linkBox: {
    marginRight: 70,
  },
  headerText: {
    fontSize: 36,
    color: "white",
    margin: "0 15px",
  },
  infoHeader: {
    height: 120,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    maxWidth: 1160,
    width: "100%",
  },
  infoHeaderWrapper: {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
  },
  infoBox: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  facilitatorsOnly: {
    backgroundColor: "#ff475b",
    borderRadius: 4,
    minHeight: 40,
    marginTop: 15,
    display: "flex",
    alignItems: "center",
  },
  facilitatorsOnlyText: {
    color: "white",
    fontSize: 16,
    fontStyle: "italic",
    fontWeight: "bold",
    paddingLeft: 10,
  },
  onlyFacilitatorIcon: {
    marginLeft: 5,
    height: 30,
    width: 30,
  },
  bottomNavigation: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: 1160,
    width: "100%",
  },
  bottomNavigationWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  previousIconButton: {
    backgroundImage: `url("${BackIcon}")`,
    backgroundPosition: "20px center",
    borderRadius: "40px 0 0 40px",
    backgroundColor: "#6eceb2",
    backgroundRepeat: "no-repeat",
    color: "rgba(255, 255, 255, 0)",
    display: "inline-block",
    height: "68px",
    minWidth: "52px",
    transition: "transform 60ms",
    width: "52px",
  },
  nextIconButton: {
    backgroundImage: `url("${NextIcon}")`,
    backgroundPosition: "right 20px center",
    borderRadius: "0 40px 40px 0",
    backgroundColor: "#6eceb2",
    backgroundRepeat: "no-repeat",
    color: "rgba(255, 255, 255, 0)",
    display: "inline-block",
    height: "68px",
    minWidth: "52px",
    transition: "transform 60ms",
    width: "52px",
  },
  iconButton: {
    display: "flex",
    alignItems: "center",
    color: "#6eceb2",
  },
  activityNotFoundBox: {
    display: "flex",
    marginTop: 30,
    minHeight: 40,
    alignItems: "center",
    margin: "auto",
    maxWidth: 1200,
    height: "100%",
    flexDirection: "column",
    padding: 10,
    "& > div": {
      color: "#4f607d",
      fontWeight: "bold",
      fontSize: 22,
      "& a": {
        color: "inherit",
      },
    },
  },
  iconText: {
    margin: "0 10px",
  },
  iframeBox: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px 100px',
    background: '#ffffff',
    marginTop: 15,
    marginBottom: 30,

    [theme.breakpoints.down("sm")]: {
      padding: '20px 15px',
    },
  },
  iframeContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: 20,
  },
  iframe: {
    minWidth: '100%',
    minHeight: 500,
    height: '100%',

    [theme.breakpoints.up("md")]: {
      minHeight: 800,
    },
  },
  controlButtonPanel:
  {
    textAlign: "center",
  },
  controlButton: {
    marginBottom: 40,
    width: 100
  },
  controlButtonMargin: {
    marginRight: 50
  }
}));

const Fader = (props) => {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 250,
  });

  return (
    <Fade in={trigger}>
      <div role="presentation" className={classes.root}>
        {children}
      </div>
    </Fade>
  );
};

const Activity = ({
  item,
  next,
  pageLabels,
  previous,
  setDone,
  createUsageLog,
  activityStep,
  presentationMode,
  leftMenuVisible,
  isMobileView,
  sendActivityLog,
  roleId,
  programCode,
  program,
  onExit,
  chain,
  csItems,
  createOrUpdateCSItem,
  communityPartnershipLink,
  openTextAnswers,
  receivedAnswer,
  onNextClick,
  getMyAnswersByProgram,
  getAnswersForActivity,
  isFacilitator,
  push,
  user,
  setDisabledNext,
  studentView,
  showInfoMessage,
  isFinishedProject,
  setOpenQuestionAnswerUpdated,
  isSelectedActivityFinished,
  answeredQuestionsState,
  updateCurrentStateofQuizesAnswers,
  history,
  shouldDisableSubmit,
}) => {
  const classes = useStyles();

  const [csVisibility, setCsVisibility] = React.useState({});
  const [currentContentSection, setCurrentContentSection] = React.useState(
    null
  );
  const [itemId, setItemId] = React.useState(null);

  // React.useEffect(() => {
  //   // hashHandle("withMenu");  -- disabled due to release
  // }, [location]);

  React.useEffect(() => {
    if (!item) return;
    const csV = {};

    if (item.contentSections) {
      for (const cs of item.contentSections) {
        csV[cs.id] = true;
      }
    }

    setItemId(item.id);
    if (item.id !== itemId) {
      createUsageLog({
        action: ACTIVITY_VIEW,
        url: window.location.href
      });
    }

    setCsVisibility(csV);
  }, [item]);


  React.useEffect(() => {
    setItemId(item ? item.id : null);
    return () => {
      setItemId(null);
    };
}, []);

  if (!item) return null;

  if (item && !item.contentSections && !item.externalUrl) {
    return (
      <div className={classes.activityNotFoundBox}>
        <div>
          <div>Hmmm... the current activity doesn't have any content yet.</div>
          <br/>
          <div>
            If you think there is a problem, please reach out to {" "}
            <Link href="mailto:hq@girlswhocode.com">hq@girlswhocode.com</Link>.
          </div>
        </div>
      </div>
    );
  }
  const { contentSections, activityType } = item;
  const studentsSections = contentSections
    && contentSections.filter(e => !e.onlyVisibleToFacilitators);

  const colorTheme = getTheme(activityType ? activityType.colorTheme : null);

  const onVisibilityChange = (id, visible) => {
    const csVisibilityObj = {
      ...csVisibility,
      [id]: visible,
    };

    setCsVisibility(csVisibilityObj);

    for (const key in csVisibilityObj) {
      if (csVisibilityObj[key] === true) {
        setCurrentContentSection(key);
        break;
      }
    }
  };

  const contentSectionsNavigation =
    contentSections && contentSections.length > 0
      ? contentSections.filter(
          (r) => r.type !== "surveys" && r.visibleInPageNavigation && r.heading != null
        )
      : null;

  const isFacilitatorsOnly = item.permissionLevel === "Facilitators/Teachers only";
  const isLastStep = activityStep === studentsSections.length - 1;
  const allContentDone = (answeredQuestionsState?.numberOfAnswered?.length + answeredQuestionsState?.optionalSectionsTotal) === answeredQuestionsState?.totalQuestions;
  return (
    <Box className={classes.root}>
      {!presentationMode && contentSectionsNavigation && contentSectionsNavigation.length > 0 && (
        <Fader>
          <ScrollMenu
            items={contentSectionsNavigation}
            current={currentContentSection}
            colorTheme={colorTheme}
            leftMenuVisible={leftMenuVisible}
            isMobileView={isMobileView}
          />
        </Fader>
      )}

      <Box className={classes.wrapper} id="content-sections-wrapper">
        {(roleId == 1 && !studentView && (item.permissionLevel.indexOf("Facilitators only") !== -1 ||
          item.permissionLevel.indexOf("Clubs") !== -1)) && !presentationMode && (
          <Box className={classes.facilitatorsOnly}>
            <img
              className={classes.onlyFacilitatorIcon}
              src={pageLabels.onlyFacilitatorsCta.icon.src}
              alt={pageLabels.onlyFacilitatorsCta.icon.alt}
            />
            <Typography className={classes.facilitatorsOnlyText}>
              {pageLabels.onlyFacilitatorsCta.text}
            </Typography>
          </Box>
        )}
        {presentationMode && studentsSections && studentsSections[activityStep] && (<ContentSection
            colorTheme={colorTheme}
            key={studentsSections[activityStep].id}
            id={studentsSections[activityStep].id}
            item={studentsSections[activityStep]}
            facilitatorNoteIcon={pageLabels.facilitatorNoteIcon}
            sendActivityLog={sendActivityLog}
            onVisibilityChange={onVisibilityChange}
            programCode={programCode}
            program={program}
            chains={chain || []}
            csItems={csItems}
            createOrUpdateCSItem={createOrUpdateCSItem}
            openTextAnswers={openTextAnswers}
            receivedAnswer={receivedAnswer}
            onNextClick={onNextClick}
            getMyAnswersByProgram={getMyAnswersByProgram}
            getAnswersForActivity={getAnswersForActivity}
            isFacilitator={isFacilitator}
            push={push}
            user={user}
            setDisabledNext={setDisabledNext}
            studentView={studentView}
            showInfoMessage={showInfoMessage}
            isFinishedProject={isFinishedProject}
            setOpenQuestionAnswerUpdated={setOpenQuestionAnswerUpdated}
            isLastContentSection={isLastStep}
            isSelectedActivityFinished={isSelectedActivityFinished}
            answeredQuestionsState={answeredQuestionsState}
            presentationMode={presentationMode}
            updateCurrentStateofQuizesAnswers={updateCurrentStateofQuizesAnswers}
            roleId={roleId}
            history={history}
          />)}
        {!presentationMode && contentSections && contentSections.map((cs, contentSectionIndex) => (
          <ContentSection
            colorTheme={colorTheme}
            key={cs.id}
            id={cs.id}
            hash={cs.hash}
            item={cs}
            onVisibilityChange={onVisibilityChange}
            programCode={programCode}
            program={program}
            chains={chain || []}
            csItems={csItems}
            createOrUpdateCSItem={createOrUpdateCSItem}
            openTextAnswers={openTextAnswers}
            receivedAnswer={receivedAnswer}
            onNextClick={onNextClick}
            getMyAnswersByProgram={getMyAnswersByProgram}
            getAnswersForActivity={getAnswersForActivity}
            isFacilitator={isFacilitator}
            push={push}
            user={user}
            setDisabledNext={setDisabledNext}
            studentView={studentView}
            showInfoMessage={showInfoMessage}
            setOpenQuestionAnswerUpdated={setOpenQuestionAnswerUpdated}
            isLastContentSection={contentSectionIndex === contentSections.length -1 }
            isSelectedActivityFinished={isSelectedActivityFinished}
            answeredQuestionsState={answeredQuestionsState}
            presentationMode={presentationMode}
            updateCurrentStateofQuizesAnswers={updateCurrentStateofQuizesAnswers}
            history={history}
            isFinishedProject={isFinishedProject}
            roleId={roleId}
          />
        ))}
        {(!contentSections && item.externalUrl) &&
        (<Box className={classes.iframeBox}>
            <Container className={classes.iframeContainer}>
              <iframe src={item.externalUrl} title={item.name} height="500" className={classes.iframe} />
            </Container>
          </Box>
        )}
      </Box>
      {(!presentationMode || (isFacilitatorsOnly && !studentView)) && <Box className={classes.bottomNavigationWrapper}>
        <Box className={classes.bottomNavigation}>
          <Box>
            {previous && (
              <NavLink
                className={classes.iconButton}
                to={communityPartnershipLink ? `${communityPartnershipLink}/${previous.path}` : `/gwc${previous.path}`}
              >
                <span className={classes.previousIconButton}></span>
                <span className={classes.iconText}>{previous.name}</span>
              </NavLink>
            )}
          </Box>
          <Box>
            {next && (
              <NavLink className={classes.iconButton} to={communityPartnershipLink ? `${communityPartnershipLink}/${next.path}` : `/gwc${next.path}`}>
                <span className={classes.iconText}>{next.name}</span>
                <span className={classes.nextIconButton}></span>
              </NavLink>
            )}
          </Box>
        </Box>
      </Box> &&

      <Box className={classes.controlButtonPanel}>
        <CustomButton
          className={clsx(classes.controlButton, classes.controlButtonMargin)}
          mode="secondary"
          label={"Exit"}
          onClick={onExit}
          disabled={false}
        />

        {![Roles.Facilitator, Roles.Viewer].includes(roleId) && (
        <CustomButton
          className={classes.controlButton}
          label={pageLabels.doneLabel}
          onClick={setDone}
          disabled={!allContentDone || shouldDisableSubmit}
        />)}
      </Box>}
    </Box>
  );
};

export default Activity;
