import { getAuthHeader } from '../tokenStorage';
import { axiosInstance } from './axiosInstance';

export const getMyInfo = async () => {
  return axiosInstance.get("/blapi/users/currentUserInfo", {
    headers: getAuthHeader(),
  });
};

export const updateMyInfo = async (user) => {
  return axiosInstance.put('/blapi/users/updateMyInfo',
    user,
    {
      headers: getAuthHeader()
    }
  );
};

export const updateChildInfo = async (user) => {
  return axiosInstance.put('/blapi/users/updateChildInfo',
    user,
    {
      headers: getAuthHeader()
    }
  );
};

export const updateMyAddressInfo = async (user) => {
  return axiosInstance.put(
    '/blapi/users/updateMyAddress',
    user,
    {
      headers: getAuthHeader(),
    }
  );
};

export const updateMyAvatarImage = async (avatarUrl) => {
  return axiosInstance.put(
    '/blapi/users/updateMyAvatarImage',
    { url: avatarUrl },
    {
      headers: getAuthHeader(),
    }
  );
};

export const updateMyUserBio = async (bio) => {
  return axiosInstance.put(
    '/blapi/users/updateBio',
    { bio },
    {
      headers: getAuthHeader(),
    }
  );
}

export const getUserProfile = async (id) => {
  return axiosInstance.get(
    `/blapi/users/userProfile/${id}`,
    { headers: getAuthHeader() },
  );
};

export const updateMySmsConsent = async (isSmsConsent) => {
  return axiosInstance.put(
    '/blapi/users/updateMySmsConsent',
    { isSmsConsent },
    { headers: getAuthHeader() },
  );
};

export const updatePrivacyPolicyAgreementDate = async (acceptedPolicyAt) => {
  return axiosInstance.put(
    '/blapi/users/setPrivacyPolicy',
    { acceptedPolicyAt },
    {
      headers: getAuthHeader(),
    }
  );
};

export const setParentConsent = async () => {
  return axiosInstance.post(
    '/blapi/users/setParentConsent',
    { },
    {
      headers: getAuthHeader(),
    }
  );
};

export const getIsFormInvalid = async (form) => {
  return axiosInstance.post(`/blapi/users/isFormInvalid`,
  form,
  {
    headers: getAuthHeader(),
  });
};

export const verifyForm = async (form) => {
  return axiosInstance.post('/blapi/users/verifyForm',
  form,
  {
    headers: getAuthHeader(),
  });
};
