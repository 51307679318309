import React, { useState, useEffect } from "react";
import { push as pushToRoute } from "connected-react-router";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import clsx from 'clsx';

import * as colors from "../../theme/colors";
import { connectTo } from "../../utils/generic";
import * as authActions from "../../actions/auth";
import * as globalActions from "../../actions/global";
import * as notificationActions from "../../actions/notification";
import { defaultProgramRoleNames, defaultProgramColors } from "../../utils/programRoleLabels";
import { Roles } from "../../utils/permissions";
import * as trackingActions from "../../actions/tracking";
import * as clubProfileActions from "../../actions/clubProfile";
import TextButton from "../../components/text-button/text-button";
import { MobileTooltip } from "./mobileToolTip";
import { TooltipWrapper } from "../../components/tooltip/tooltip";
import {
  NAVIGATION_HORIZONTAL_NAVIGATION_MENU
} from "../../constants/trackingEvents";
import Tabs from "./Tabs";

const useStyles = makeStyles(theme => ({
  tabsSection: {
    alignItems: "center",
    display: "flex",
    minWidth: "max-content",
  },
  navContainer: {
    position: "fixed",
    zIndex: 10,
    backgroundColor: "#0D9C90",
    display: "flex",
    alignItems: 'center',
    width: "100%",
    minHeight: 48,
    justifyContent: "space-between",
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      minHeight: 48,
      display: 'block',
    },
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down('xs')]: {
      flexWrap: "wrap",
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 10,
    },
  },
  tabContainer: {
    [theme.breakpoints.down('xs')]: {
      margin: 'auto',
    },
  },
  programCode: {
    marginRight: 15,
    fontWeight: 500,
    fontSize: "16px",
    color: colors.white,
    textTransform: "uppercase",
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
  programNameWrapper: {
    display: "flex",
    marginLeft: 20,
    marginRight: 15,
    fontWeight: 500,
    color: colors.white,
    fontSize: "16px",
    textTransform: "uppercase",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down('md')]: {
      marginLeft: 12,
      marginTop: 15,
    },
    [theme.breakpoints.down('xs')]: {
      display: "block",
      marginTop: 15,
    },
  },
  programName: {
    fontWeight: 500,
    color: colors.white,
    fontSize: "16px",
    textTransform: "uppercase",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down('xs')]: {
      maxHeight: 25,
    },
  },
  nameAndCodeWrapper: {
    display: "flex",
    maxWidth: "100%",
    [theme.breakpoints.up('sm')]: {
      maxWidth: "calc(100% - 100px)",
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: "100%",
    },
  }, 
  label: {
    height: 14,
    fontSize: 10,
    fontFamily: "'Roboto Mono',monospace",
    fontWeight: 500,
    marginLeft: 15,
    marginRight: 5,
    marginBottom: 1,
    textTransform: "uppercase",
    [theme.breakpoints.down('xs')]: {
      marginBottom: "4px",
      marginLeft: 0,
    },
    "&:focus-visible": {
      outline: `2px dotted ${colors.grey400}`,
      outlineOffset: -2,
    },
  },
  textLabel: {
    cursor: "text",
    "&:hover": {
      cursor: "text",
    },
  },
}));

const NavBar = (props) => {
  const { 
    auth: { user, currentRole },
    push,
    router,
    createTrackingLog,
    studentView,
    setStudentView,
    showInfoMessage,
    clubProfile: { userRoleLabels },
    getUserRoleLabels,
  } = props;
  const classes = useStyles();
  const [currentTab, setCurrentTab] = React.useState(null);
  const [attendanceEnabled, setAttendanceEnabled] = useState(false);
  const [messagesEnabled, setMessagesEnabled] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [showTooltip, setShowTooltip] = useState(false);
  const [programTypeDetails, setProgramTypeDetails] = React.useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const identifyCurrentTab = (pathname) => {
    switch(true) {
      case (/\gwc/).test(pathname):
        return 'Curriculum';
      case (/\messages/).test(pathname):
        return 'Messages';
      case (/\progress/).test(pathname):
        return 'Progress';
      case (/club-profile/g).test(pathname):
        return 'Program Info';
      case (/\attendance/).test(pathname):
        return 'Attendance';
      case (/\members/).test(pathname):
        return 'Members';
      default:
        return 'Curriculum';
    }
  }

  useEffect(() => {
    if (!currentRole) return;

    createTrackingLog({
      event: NAVIGATION_HORIZONTAL_NAVIGATION_MENU,
      data: JSON.stringify({ fullUrl: window.location.href }),
    });

    const currentProgramTypeDetails = currentRole?.program?.programTypeDetails;
    setProgramTypeDetails(currentProgramTypeDetails);

    if (user) {
      setPermissions(currentRole.permissions);

      if (currentProgramTypeDetails?.attendanceEnabled) {
        setAttendanceEnabled(currentRole.permissions.attendancePage);
      }

      if (currentProgramTypeDetails?.messagesEnabled && currentRole.program?.hasMessages) {
        setMessagesEnabled(currentRole.permissions.messagesPage);
      }
    }
  }, [currentRole]);

  useEffect(() => {
    if (!currentRole) return;
    getUserRoleLabels(currentRole.program.id);
  }, [currentRole]);

  useEffect(() => {
    const tab = identifyCurrentTab(router.location.pathname);
    setCurrentTab(tab);
  }, [router.location.pathname]);

  let programRoleLabel;
  if (currentRole) {
    switch (currentRole.roleId) {
      case Roles.Facilitator:
        programRoleLabel = programTypeDetails?.facilitatorRoleName || defaultProgramRoleNames[currentRole.roleId];
        break;
      case Roles.Student:
        programRoleLabel = programTypeDetails?.memberRoleName || defaultProgramRoleNames[currentRole.roleId];
        break;
      case Roles.Viewer:
        programRoleLabel = programTypeDetails?.viewerRoleName || defaultProgramRoleNames[currentRole.roleId];
        break;
      default:
        programRoleLabel = '';
    }
  }

  const labelBackground = programTypeDetails?.backgroundColor || defaultProgramColors.backgroundColor;
  const labelFontColor = programTypeDetails?.fontColor || defaultProgramColors.fontColor;

  const studentLabel = programTypeDetails?.memberRoleName || defaultProgramRoleNames[Roles.Student];
  const userRoleLabel = userRoleLabels?.length && userRoleLabels.find(label => label.programId === currentRole?.program?.id && label.userId === user.id);
  const userRoleLabelText = userRoleLabel?.roleLabel?.roleLabel;

  const facilitatorLabel = userRoleLabelText ? userRoleLabelText : (programTypeDetails?.facilitatorRoleName || defaultProgramRoleNames[Roles.Facilitator]);
  const currentRoleLabel = studentView ? studentLabel : facilitatorLabel;
  const switchToRoleLabel = studentView ? facilitatorLabel : studentLabel;
  const tooltipMsg = programTypeDetails && `Click to view as a ${switchToRoleLabel}`;
  const notificationMessage = programTypeDetails && `You are now viewing your Program as a ${switchToRoleLabel}. Click the "${switchToRoleLabel}" label to switch back to "${currentRoleLabel} View"`;

  const onStudentViewClick = () => {
    setStudentView(!studentView);
    showInfoMessage(notificationMessage);
    if (!studentView && (currentTab === 'Members' || currentTab === 'Attendance')) {
      push('/gwc');
    }
    if (!studentView && currentTab === 'Progress') {
      push(`/progress/${currentRole.program.code}/${user.id}`);
    }
    if (studentView && currentTab === 'Progress') {
      push(`/progress/${currentRole.program.code}`);
    }
  }

  const isDefaultProgram = currentRole?.program?.isDefaultProgram;

  return (
    <Box>
      <Box className={classes.navContainer}>
          <Typography component='div' className={classes.programNameWrapper}>
              <Box component="span" className={classes.programCode}>
                {currentRole && currentRole.program && currentRole.program.code}
              </Box>
              {!isMobile ?
                <Typography noWrap className={classes.programName}>
                  {currentRole && currentRole.program && currentRole.program.name}
                </Typography> :
                <Box component="span" className={classes.programName} onClick={() => setShowTooltip(!showTooltip)}>
                  <MobileTooltip
                    title={currentRole && currentRole.program && currentRole.program.name}
                    open={showTooltip}
                    onOpen={() => setShowTooltip(true)}
                    onClose={() => setShowTooltip(false)}
                  >
                    <Typography noWrap component="span">
                      {currentRole && currentRole.program && currentRole.program.name}
                    </Typography>
                  </MobileTooltip>
                </Box>
              }
            {currentRole && currentRole.roleId === Roles.Facilitator && <Box component="span">
              <TooltipWrapper title={tooltipMsg}>
                <Box component="span">
                  <TextButton
                    onClick={onStudentViewClick}
                    className={classes.label}
                    style={{ color: labelFontColor, backgroundColor: labelBackground }}
                    label={!isMobile || userRoleLabelText ? currentRoleLabel : currentRoleLabel.split(" ")[0]}
                  />
                </Box>
              </TooltipWrapper>
            </Box>}
            {currentRole && currentRole.roleId !== Roles.Facilitator && <Box component="span">
              <TextButton
                className={clsx(classes.label, classes.textLabel)}
                style={{ color: labelFontColor, backgroundColor: labelBackground }}
                label={!isMobile ? programRoleLabel : programRoleLabel.split(" ")[0]}
                tabIndex="-1"
              />
            </Box>}
          </Typography>
        {currentRole && !isDefaultProgram && <Box className={classes.tabsSection}>
          <Tabs
            value={currentTab}
            push={push}
            user={user}
            currentRole={currentRole}
            permissions={permissions}
            attendanceEnabled={attendanceEnabled}
            messagesEnabled={messagesEnabled}
            studentView={studentView}
            isMobile={isMobile}
            onChange={(evt, newValue) => {
              setCurrentTab(newValue);
            }}
          />
        </Box>}
      </Box>
    </Box>
  );
};

export default connectTo(
  (state) => {
    return {
      auth: state.auth,
      router: state.router,
      curActivities: state.curriculum,
      studentView: state.global.studentView,
      clubProfile: state.clubProfile,
    };
  },
  {
    push: pushToRoute,
    ...authActions,
    ...globalActions,
    ...trackingActions,
    ...notificationActions,
    ...clubProfileActions,
  },
  NavBar
);
