import cta from './Cta';
import * as FormFields from './FormFields';
import Messaging from './Messaging';

const organizationMessaging = `
  name
  joinOrganizationDialogHeading
  joinOrganizationDialogSubheading
  joinOrganizationDialogConfirmation
  joinOrganizationDialogNextLabel
  joinOrganizationDialogBackLabel
  joinOrganizationDialogConfirmLabel
  joinOrganizationDialogCancelLabel
  joinOrganizationDialogMembersCount
  joinOrganizationDialogMembersCountPlural
  joinOrganizationDialogCodeInput {
    ${FormFields.commonFieldAttributes}
    ${FormFields.formField}
  }
  joinPartnerDialogHeading
  joinPartnerDialogSubheading
  joinPartnerDialogConfirmation
  joinPartnerDialogCancelLabel
  joinPartnerDialogNextLabel
  joinPartnerDialogBackLabel
  joinPartnerDialogConfirmLabel
  joinPartnerDialogCodeInput {
    ${FormFields.commonFieldAttributes}
    ${FormFields.formField}
  }
  formMessaging {
    ${Messaging}
  }
  partnerDashboardClubsTableConfig
  partnerDashboardPeopleTableConfig
  partnerDashboardCounterFacilitators
  partnerDashboardCounterClubs
  partnerDashboardChartClubsAbove3
  partnerDashboardChartClubsByCurriculum
  partnerDashboardFilter3rd5th
  partnerDashboardFilter6th12th
  partnerDashboardFilterRenewed
  partnerDashboardFilterNew
  partnerDashboardFilterFundReceived
  partnerDashboardFilterFundNotReceived
  partnerDashboardEmptyFilterResults
  partnerDashboardNavigationBarHeader
  partnerDashboardInternationalBarHeader
  partnerDashboardEmptyDashboardText
  partnerDashboardInternationalEmptyText
  partnerDashboardSelectYear
  partnerDashboardFaqCta {
    ${cta}
  }
  partnerDashboardInternationalClubsTableConfig
  partnerDashboardInternationalPeopleTableConfig
  partnerDashboardOutreachToolkit {
    ${cta}
  }
  partnerDashboardWelcomeMessage {
    ${cta}
  }
  spreadTheWordText
  tooltipMessagesPendingFacilitators
  tooltipMessagesPendingPa
  tooltipMessagesPendingApplicantVerification
  tooltipMessagesInReview
`;

export default organizationMessaging;
