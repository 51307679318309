import React from "react";
import * as moment from "moment";
import clsx from "clsx";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography, Box, Link } from "@material-ui/core/";

import * as colors from "../../../theme/colors";
import { ColorTheme } from "../../../utils/colorTheme";

import { days } from "../../../utils/generic";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginBottom: 8,
    padding: 10,
    boxShadow: 'none',
    color: colors.darkThemeBlueGray,
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  details: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      display: 'block'
    }
  },
  content: {
    flex: "1 0 auto",
    padding: 0,
    "&:last-child": {
      padding: 0
    }
  },
  gradientBox: {
    border: `1px solid ${ColorTheme.black3.b}`,
  },
  cover: {
    minWidth: 170,
    textAlign: "left",
    display: "block",
    padding: 10,
    boxShadow: `1px 0 0 0 ${ColorTheme.black1.b}`,
    [theme.breakpoints.down("md")]: {
      display: "inline-flex",
      width: "100%",
      borderBottom: `1px solid ${ColorTheme.black2.b}`,
      boxShadow: 'none'
    },
  },
  timeWrapper: {
    alignItems: "baseline",
    minWidth: 75,
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: '100%'
    }
  },
  contentBox: {
    display: "flex",
    cursor: "pointer",
    marginLeft: 10,
    padding: 10,
    "&:hover": {
      backgroundColor: ColorTheme.black1.b,
    },
    [theme.breakpoints.down("md")]: {
      display: "block",
      marginLeft: 0
    },
  },
  contentNoTagsBox: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      alignItems: "start",
      textAlign: "center",
      marginLeft: 0
    },
  },
  timeContainer: {
    marginRight: 30,
    width: 140,
    fontSize: 14,
    lineHeight: 1.43,
    textAlign: 'left'
  },
  timeContainerUpMd: {
    [theme.breakpoints.down("md")]: {
      display: 'none'
    }
  },
  timeContainerDownMd: {
    marginTop: 8,
    [theme.breakpoints.up("lg")]: {
      display: 'none'
    }
  },
  dayOfWeekLabel: {
    fontSize: 18,
    lineHeight: 1.34,
    fontWeight: 700,
  },
  fullDateLabel: {
    fontSize: 14,
    lineHeight: 1.43,
    width: "fit-content",
  },
  contentLabel: {
    fontSize: 16,
    fontWeight: 700,
    textAlign: "initial",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("md")]: {
      width: 'auto!important'
    },
  },
  timerBox: {
    display: "flex",
    alignItems: "start",
  },
  multiIcon: {
    marginRight: 10,
    marginTop: 2
  },
  contentWrapper: {
    display: 'block',
    justifyContent: "space-between",
    width: "100%",
    textAlign: 'left',
    [theme.breakpoints.down("md")]: {
      display: 'grid',
      maxWidth: 'fit-content'
    }
  },
  link: {
    display: "inline-block",
    verticalAlign: "middle",
    maxWidth: 315,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: colors.darkThemeGreen,
    fontSize: 14,
    textTransform: "initial",
    lineHeight: 1.43,
    marginTop: 5,
    "&:hover": {
      textDecoration: "none",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: 'max-content'
    }
  },
  tagsBox: {
    display: "flex",
    height: "fit-content",
    [theme.breakpoints.down("md")]: {
      justifyContent: 'flex-start',
      margin: '10px 0 0 25px'
    },
  },
  eventTag: {
    fontFamily: "'Roboto Mono', monospace",
    width: 70,
    textAlign: "center",
    marginLeft: 5,
    borderRadius: 11,
    fontSize: 12,
    padding: 4,
    fontWeight: 500
  },
  optionalTag: {
    background: colors.greyLight2,
  },
  publicTag: {
    background: ColorTheme.teal1.b,
  },
  partnerTag: {
    background: ColorTheme.orange1.b,
  },
  emptyStateItem: {
    margin: "10px 15px",
    display: "block",
    alignItems: "start",
    textAlign: "inherit",
    maxWidth: 625,
    [theme.breakpoints.down("md")]: {
      maxWidth: 'fit-content'
    }
  },
  emptyStateTitle: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: 1.5,
    color: ColorTheme.blue4.b
  },
  emptyStateMessage: {
    fontSize: 14,
    lineHeight: 1.43,
    color: ColorTheme.black3.b
  },
  todayBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: '10px 0',
    paddingLeft: 5
  },
  todayTitle: {
    fontSize: 14,
    lineHeight: 1.7,
    marginRight: 10,
    textTransform: 'uppercase'
  },
  todayLine: {
    background: ColorTheme.teal4.b,
    height: 3,
    width: '100%'    
  },
  todayCircle: {
    background: ColorTheme.teal4.b,
    height: 10,
    width: 10,
    borderRadius: 20
  }
}));

const ColorLabel = withStyles({
  root: {
    height: 18,
    width: 18,
    borderRadius: 2,
    marginRight: 10,
    background: (props) => props.color,
    backgroundColor: (props) => props.color,
  },
})((props) => <Typography {...props} />);

const DaySection = ({
  item,
  programs,
  programColors,
  onClickView,
  selectedDate,
  isEmptyState,
  labels,
  isToday,
}) => {
  const classes = useStyles();

  const renderCardHeader = (item) => {
    const calendarDay = item
      ? moment(`${item.date}T00:00:00`)
      : moment(selectedDate);
    
    return (
      <Box className={classes.cover}>
        <Box className={classes.timeWrapper}>
          <Typography className={classes.dayOfWeekLabel}>{days[calendarDay.day()]}</Typography>
          <Typography className={classes.fullDateLabel}>{calendarDay.format("MMMM D, YYYY")}</Typography>
        </Box>
      </Box>
    );
  };

  const getItemColor = (item, programs, programColors) => {
    if (!!item.isPublic) {
      return "#000000";
    }
    if (!programs || !programs.length) {
      return;
    }

    const currentProgram = programs.find(
      (e) => e.id === item.programs[0].programId
    );
    const programIndex = programs.indexOf(currentProgram);
    const color =
      programIndex >= programColors.length
        ? programColors[programIndex % programColors.length]
        : programColors[programIndex];
    return color;
  };

  const renderCardContent = (item, index, programs, programColors) => {
    const eventTime = `${item.localStartTime} - ${item.localEndTime}`;
    const isLinear = item.prepopulatedByProgramType || !!item.programs && item.programs.length > 1;
    const itemColor = isLinear
      ? null
      : getItemColor(item, programs, programColors);
    const multiIcon = labels && labels.multipleProgramEventIcon ? labels.multipleProgramEventIcon : null;

    const tagsCount = !item ? 0 : [!!item.isOptional, !!item.isPartner, !!item.isPublic].filter(x => x).length;

    return (
      <Box
        key={index}
        className={classes.contentBox}
        onClick={() => onClickView(item)}
      >
        <Box className={classes.contentNoTagsBox}>
        <Box className={classes.timerBox}>
          {isLinear && !!multiIcon && (
            <img
              className={classes.multiIcon}
              alt={multiIcon.alt}
              src={multiIcon.src}
            />
          )}
          {!isLinear && (
            <ColorLabel
              color={itemColor}
              className={isLinear ? classes.gradientBox : null}
            />
          )}
          <Typography
            className={clsx(classes.timeContainer, classes.timeContainerUpMd)}
            component="h5"
            variant="h5"
          >
            {eventTime}
          </Typography>
        </Box>
        <Box className={classes.contentWrapper}>
          <Typography
            className={classes.contentLabel}
            component="h5"
            variant="h5"
            style={{width: 500 - (tagsCount * 75)}}
          >
            {item.name}
          </Typography>
          <Typography
            className={clsx(classes.timeContainer, classes.timeContainerDownMd)}
            component="h5"
            variant="h5"
          >
            {eventTime}
          </Typography>
          {item && item.url && (
            <Link
              className={classes.link}
              target="_blank"
              color="secondary"
              href={item.url}
            >
              {item.url}
            </Link>
          )}
        </Box>
        </Box>

        {tagsCount > 0 && (
          <Box className={classes.tagsBox}>
            {item && item.isOptional && (
              <div className={clsx(classes.eventTag, classes.optionalTag)}>Optional</div>
            )}
            {item && item.isPartner && (
              <div className={clsx(classes.eventTag, classes.partnerTag)}>Partner</div>
            )}
            {item && item.isPublic && (
              <div className={clsx(classes.eventTag, classes.publicTag)}>Public</div>
            )}
          </Box>
        )}
      </Box>
    );
  };

  const renderEmptyStateContent = () => {
    return (
      <Box className={classes.emptyStateItem}>
        <Typography component="h5" variant="h5" className={classes.emptyStateTitle}>
          No upcoming events scheduled.
        </Typography>
        <Typography className={classes.emptyStateMessage}>
          Use the date selector on the left to review events for previous
          dates. You can also double check that all of your available
          Calendars are selected.
        </Typography>
      </Box>
    );
  };

  const sortedItems =
    item && item.items.length
      ? item.items.sort(
          (a, b) =>
            a.startDate.localeCompare(b.startDate) ||
            a.startDate.localeCompare(b.startDate)
        )
      : [];

  return (
    <>
      {isToday && (
        <Box className={classes.todayBox}>
          <Typography className={classes.todayTitle}>Today</Typography>
          <div className={classes.todayCircle}></div>
          <div className={classes.todayLine}></div>
        </Box>
      )}
      <Card className={classes.root}>
        {renderCardHeader(item)}
        <div className={classes.details}>
          <CardContent className={classes.content}>
            {item &&
              sortedItems.map((e, index) => {
                return renderCardContent(e, index, programs, programColors);
              })}
            {isEmptyState && renderEmptyStateContent()}
          </CardContent>
        </div>
      </Card>
    </>
  );
};

export default DaySection;
