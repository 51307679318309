import { getAuthHeader } from '../tokenStorage';
import { axiosInstance } from './axiosInstance';
import moment from "moment";
import {DATE_FORMAT} from "../utils/constants";

export const login = async ({ accessToken, login, password, childId, childToken }) => {
  return axiosInstance.post(
    '/auth/login',
    {
      accessToken,
      login,
      password,
      childId,
      childToken,
    },
  );
};

export const loginChild = async ({ childId, parentId }) => {
  return axiosInstance.post(
    '/auth/loginChild',
    {
      childId,
      parentId,
    },
    {
      headers: getAuthHeader(),
    }
  );
};

export const logout = async (body) => {
  return axiosInstance.post(
    '/auth/logout',
    body,
  );
};

// export const signup = async (payload) => {
//   return axios({
//     method: 'POST',
//     url: `${process.env.REACT_APP_API_URL}/auth/signup`,
//     data: {
//       ...payload,
//     },
//   })
// };

export const signup = async (payload) => {
  if (!!payload.birthDate) {
    payload.birthDate = moment.parseZone(payload.birthDate).format(DATE_FORMAT)
  }

  return axiosInstance.post(
    '/auth/register',
    { ...payload, },
  );
};

export const changePassword = async (payload) => {
  return axiosInstance.post(
    '/auth/changePassword',
    { ...payload, },
    {
      headers: getAuthHeader(),
    }
  );
};

export const setPassword = async (payload) => {
  return axiosInstance.post(
    '/auth/setPasswordFirstTime',
    { ...payload, },
    {
      headers: getAuthHeader(),
    }
  );
};

export const resetPassword = async ({ email }) => {
  return axiosInstance.post(
    '/auth/resetPassword',
    { email: email.trim() },
  );
};

export const changeResetPassword = async (payload) => {
  return axiosInstance.post(
    '/auth/changeResetPassword',
    payload,
  );
};


export const changeUserPasswordAsFacilitator = async (payload) => {
  return axiosInstance.post(
    '/auth/changeUserPasswordAsFacilitator',
    payload,
    {
      headers: getAuthHeader(),
    }
  );
};

export const registerChild = async (payload) => {
  return axiosInstance.post(
    '/auth/registerChild',
    { ...payload, },
    {
      headers: getAuthHeader(),
    }
  );
};