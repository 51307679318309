import { createAction } from 'redux-act';

export const getClubInfo = createAction();
export const updateClubInfo = createAction();
export const updateClubShipping = createAction();
export const updateVirtualLinkOnly = createAction();
export const updatePreferences = createAction();
export const updateSocialMedia = createAction();
export const updateCommunityPartnershipAffilation = createAction();

export const receivedClubInfo = createAction();
export const receivedSocialMedia = createAction();
export const receivedCommunityPartnership = createAction();
export const receivedPreferences = createAction();

export const getClubInfoByCode = createAction();
export const receivedClubInfoByCode = createAction();
export const cleanClubInfoByCode = createAction();
export const cleanedClubInfoByCode = createAction();

export const onError = createAction();

export const getRoleLabels = createAction();
export const receivedRoleLabels = createAction();

export const receivedChangedUserRoleLabel = createAction();
export const createUserRoleLabel = createAction();
export const deleteUserRoleLabel = createAction();
export const receivedDeletedUserRoleLabel = createAction();
export const getUserRoleLabels = createAction();
export const receivedUserRoleLabels = createAction();
