import image from '../../graphql/types/Image';
import projectGalleryFilters from './projectGalleryFilters';

const projectGalleryMessaging = `
  name
  navbarProjectGalleryLabel
  uploadButton
  projectsListLoadMoreLabel
  projectsListThatsAllLabel
  projectsListIntroduction
  projectListTitle
  singleProjectPageOpenProjectLabel
  singleProjectPageOpenRepositoryLabel
  singleProjectPageBackToGalleryLabel
  singleProjectPageLanguageTagsLabel
  singleProjectPageTopicTagsLabel
  submissionFormGroupMembersLabel
  submissionFormGroupMembersPlaceholder
  submissionFormTitleLabel
  submissionFormDescriptionLabel
  submissionFormDescriptionPlaceholder
  submissionFormImageUploadLabel
  submissionFormProjectLinkLabel
  submissionFormGitHubLinkLabel
  submissionFormProjectTypeLabel
  submissionFormLanguageLabel
  submissionFormTopicLabel
  submissionFormGitHubLinkPlaceholder
  submissionFormProjectLinkPlaceholder
  submissionFormEditMessage,
  submissionFormProjectTitlePlaceholder
  submissionFormReflectionLabel
  submissionFormReflectionPlaceholder
  projectsListFilterFilterLabel
  projectsListFilterPrograms
  projectsListFilterLocation
  projectsListFilterInternational
  projectsListFilterCountry
  projectsListFilterStateProvince
  projectsListFilterYear
  projectsListFilterProjectType
  projectsListFilterEmptyResults
  projectListFilters
  {
    ${projectGalleryFilters}
  }
  shareImage {
    ${image}
  }
  projectListFacebookImage {
    ${image}
  }
  projectListTwitterImage {
    ${image}
  }
  projectListLinkedinImage {
    ${image}
  }
  projectListCopyContentImage {
    ${image}
  }
  projectListLinkCopied
  projectListShareLabel
  projectListSearchLabel
  projectListSubmissionMessage
  shareProjectMessage
  projectListBackToProjectGalleryLabel
`;

export default projectGalleryMessaging;
